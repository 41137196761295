import React from 'react';
import clsx from 'clsx';
import { formatMoney } from '../../../../../../common/utils/format';
import s from './TransactionAmount.module.scss';

export interface TransactionAmountProps {
  className?: string;
  value: number;
  colorize?: boolean;
}

export const TransactionAmount = ({ className, value, colorize = true }: TransactionAmountProps) => {
  return (
    <span
      className={clsx(s.TransactionAmount, className, {
        [s.TransactionAmount_positive]: colorize && value && value > 0,
        [s.TransactionAmount_negative]: colorize && value && value < 0
      })}
    >
      {formatMoney(value || 0)}
    </span>
  );
};
