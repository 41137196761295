import { InferType, object, string } from 'yup';
export const maxFeedbackMessageLength = 600;
export const maxFeedbackNameLength = 100;

const validateObject = {
  name: string().required().max(maxFeedbackNameLength, 'Name must be maximum 100 characters long'),
  email: string().required().email(),
  message: string().required().max(maxFeedbackMessageLength, 'Message must be maximum 600 characters long')
};

export const FeedbackModalSchema = object(validateObject);

export type TFeedbackModalValues = InferType<typeof FeedbackModalSchema>;

export const FeedbackModalKeys = Object.fromEntries(Object.keys(validateObject).map((key) => [key, key])) as Record<
  keyof TFeedbackModalValues,
  string
>;
