import React from 'react';
import clsx from 'clsx';
import { ReactFCC } from 'common/utils/helperTypes';

import s from './Tooltip.module.scss';

export type TTooltipContainer = {
  className?: string;
  innerRef?: React.RefObject<HTMLSpanElement>;
};

export const TooltipContainer: ReactFCC<TTooltipContainer> = (props) => {
  const { children, className, innerRef } = props;

  return (
    <span className={clsx(s.Tooltip__container, className)} ref={innerRef}>
      {children}
    </span>
  );
};
