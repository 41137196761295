import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Heading, HeadingSize } from '../../../common/components/Heading';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import { ImageWithFallback } from '../../../common/components/ImageWithFallback';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { ABOUT_ROUTE } from '../../../common/utils/routes';
import logo from './assets/logo512.png';
import s from './AboutPage.module.scss';

export const AboutPage = () => {
  const isMobile = useIsMobile();
  const location = useLocation();

  if (isMobile && location.pathname === ABOUT_ROUTE) {
    return (
      <>
        <Space size={ESpaceSize.PIXEL_24} />
      </>
    );
  }
  return (
    <>
      <div className={s.AboutPage__content}>
        <ImageWithFallback src={logo} className={s.AboutPage__logo}></ImageWithFallback>
        <Heading size={HeadingSize.H3} className={s.AboutPage__heading}>
          <FormattedMessage id={'static.aboutCompany.mission'} />
        </Heading>
        <Space size={ESpaceSize.PIXEL_24} />
        <div className={s.AboutPage__contentItem}>
          <Heading size={HeadingSize.H5} className={s.AboutPage__heading}>
            <FormattedMessage id={'static.aboutCompany.missionHook'} />
          </Heading>
          <Space size={ESpaceSize.PIXEL_24} />
          <FormattedMessage id={'static.aboutCompany.missionContent'} />
        </div>
        <Space size={ESpaceSize.PIXEL_32} />
        <div className={s.AboutPage__contentItem}>
          <Heading size={HeadingSize.H5} className={s.AboutPage__heading}>
            <FormattedMessage id={'static.aboutCompany.secondTitle'} />
          </Heading>
          <Space size={ESpaceSize.PIXEL_24} />
          <FormattedMessage id={'static.aboutCompany.secondContent'} />
        </div>
        <Space size={ESpaceSize.PIXEL_32} />
        <div className={s.AboutPage__contentItem}>
          <Heading size={HeadingSize.H5} className={s.AboutPage__heading}>
            <FormattedMessage id={'static.aboutCompany.thirdTitle'} />
          </Heading>
          <Space size={ESpaceSize.PIXEL_24} />
          <FormattedMessage id={'static.aboutCompany.thirdContent'} />
        </div>
        <Space size={ESpaceSize.PIXEL_32} />
        <div className={s.AboutPage__contentItem}>
          <Heading size={HeadingSize.H5} className={s.AboutPage__heading}>
            <FormattedMessage id={'static.aboutCompany.fourthTitle'} />
          </Heading>
          <Space size={ESpaceSize.PIXEL_24} />
          <FormattedMessage id={'static.aboutCompany.fourthContent'} />
        </div>
      </div>
    </>
  );
};
