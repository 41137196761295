// general
import { LOCAL_STORAGE_COOKIE, LOCAL_STORAGE_LANGUAGE } from './storageKeys';

export const URL_KEY_LANGUAGE = LOCAL_STORAGE_LANGUAGE;
export const URL_KEY_COOKIE = LOCAL_STORAGE_COOKIE;

export const URL_KEY_OFFSET = 'offset' as const;
export const URL_KEY_TAB = 'tab';

// product
export const URL_KEY_PRODUCT_PATH = 'path' as const;

// catalog
export const URL_KEY_MIN_PRICE = 'pf';
export const URL_KEY_MAX_PRICE = 'pt';

export const URL_KEY_BRAND = 'b_id';

export const URL_KEY_COLORS = 'c_ids';

export const URL_KEY_CONDITIONS = 'cond';

export const URL_KEY_STATUS = 'st';

export const URL_KEY_SPECIFIC_ATTRS = 'spcf';

// search
export const URL_KEY_SEARCH_QUERY = 'q';

// notifications
export const URL_KEY_NOTIFICATION_QUERY = 'n_id';

// mobile app
export const URL_KEY_IS_MOBILE_APP = 'mobile-app';
