import { array, InferType, object, string } from 'yup';
import { Validator } from 'common/utils/validate';

export const nameMaxLength = 30; // todo уточнить
export const maxFilesAmount = 4;

export const isFullWidthKatakana = (value: string | undefined) => (value ? /^[\u30A0-\u30FF]+$/g.test(value) : false);
export const katakanaValidation = {
  name: 'is-full-width',
  error: 'Enter full-width katakana',
  validator: isFullWidthKatakana
};

const validateObject = {
  gender: string().required(Validator.errors.required),
  name: string().max(nameMaxLength, Validator.errors.maxLength(nameMaxLength)).required(Validator.errors.required),
  secondName: string()
    .max(nameMaxLength, Validator.errors.maxLength(nameMaxLength))
    .required(Validator.errors.required),
  nameHiragana: string()
    .max(nameMaxLength, Validator.errors.maxLength(nameMaxLength))
    .test(katakanaValidation.name, katakanaValidation.error, katakanaValidation.validator)
    .required(Validator.errors.required),
  secondNameHiragana: string()
    .max(nameMaxLength, Validator.errors.maxLength(nameMaxLength))
    .test(katakanaValidation.name, katakanaValidation.error, katakanaValidation.validator)
    .required(Validator.errors.required),
  birthdate: string().required(Validator.errors.required),
  contactNumber: string()
    // лимиты из головы для правдоподобности
    .min(7, 'Enter the correct phone number')
    .max(20, 'Enter the correct phone number')
    .required(Validator.errors.required),
  addressId: string().required(Validator.errors.required),
  attachments: array()
    .length(maxFilesAmount, 'You must attach exactly 4 files mentioned in the description')
    .required(Validator.errors.required),
  smsCode: string().max(6, Validator.errors.maxLength(6)).nullable(true)
};

export const VerificationSchema = object(validateObject);

export type VerificationValues = InferType<typeof VerificationSchema>;

export const VerificationSchemaKeys = Object.fromEntries(
  Object.keys(validateObject).map((key) => [key, key])
) as Record<keyof VerificationValues, string>;
