import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Text } from '../../../../common/components/Text';
import { Rating } from '../../../../common/components/Rating/Rating';
import { User } from '../../hooks/useUser';
import { Link } from '../../../../common/components/Link';
import { PathBuilder } from '../../../../common/utils/routes';
import { ConditionalWrapper } from '../../../../common/components/ConditionalWrapper/ConditionalWrapper';
import s from './UserInfo.module.scss';

export interface UserInfoProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Объект пользователя из API
   */
  user?: User | null;
  publicUser?: {
    nickname: string;
    ratings: {
      positive: number;
      neutral: number;
      negative: number;
    };
  };
  /**
   * Адаптация компонента на мобильных устройствах (по умолчанию true)
   */
  adaptive?: boolean;
}

export const UserInfo: ReactFCC<UserInfoProps> = (props) => {
  const { className, user, publicUser, adaptive = true } = props;

  return (
    <div
      className={clsx(s.UserInfo, className, {
        [s.UserInfo_adaptive]: adaptive
      })}
    >
      <Text className={s.UserInfo__name}>{user?.nickname || publicUser?.nickname}</Text>

      <ConditionalWrapper
        condition={!publicUser}
        wrapper={(children) => (
          <Link
            to={
              user?.sellerId
                ? PathBuilder.getSellerPublicPath(user?.sellerId)
                : PathBuilder.getCustomerPublicPath(user?.id || '')
            }
          >
            {children}
          </Link>
        )}
      >
        <Rating
          positive={
            (user?.seller
              ? (user?.customerRating?.positive || 0) + (user?.seller.sellerRating?.positive! || 0)
              : user?.customerRating?.positive || 0) ||
            publicUser?.ratings.positive ||
            0
          }
          neutral={
            (user?.seller
              ? (user?.customerRating?.neutral! || 0) + (user?.seller.sellerRating?.neutral! || 0)
              : user?.customerRating?.neutral || 0) ||
            publicUser?.ratings.neutral ||
            0
          }
          negative={
            (user?.seller
              ? (user?.customerRating?.negative! || 0) + (user?.seller.sellerRating?.negative! || 0)
              : user?.customerRating?.negative || 0) ||
            publicUser?.ratings.negative ||
            0
          }
        />
      </ConditionalWrapper>
      {user?.seller && (
        <FormattedMessage id={'profile.trustPointsCardAmount'} values={{ amount: user.seller.totalTrustPoints || 0 }} />
      )}
    </div>
  );
};
