import { FormattedMessage } from 'react-intl';
import { Heading, HeadingSize } from '../../../common/components/Heading';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import { ImageWithFallback } from '../../../common/components/ImageWithFallback';
import { Divider } from '../../../common/components/Divider/Divider';
import explosives from './assets/explosives.png';
import toxic from './assets/toxic.png';
import cash from './assets/cash.png';
import guns from './assets/guns.png';
import drugs from './assets/drugs.png';
import animals from './assets/animals.png';
import food from './assets/food.png';
import patent from './assets/patent.png';
import alcohol from './assets/alcohol.png';
import casino from './assets/casino.png';
import adult from './assets/adult.jpg';
import s from './ProhibitedItemsPage.module.scss';

export const ProhibitedItemsPage = () => {
  return (
    <>
      <div className={s.ProhibitedItemsPage__content}>
        <Heading size={HeadingSize.H4} className={s.ProhibitedItemsPage__header}>
          <FormattedMessage id={'static.prohibitedItems.header'} />
        </Heading>
        <Space size={ESpaceSize.PIXEL_24} />
        <div className={s.ProhibitedItemsPage__contentItem}>
          <ImageWithFallback src={explosives} size={60} />
          <div className={s.ProhibitedItemsPage__textCols}>
            <span>
              <FormattedMessage id={'static.prohibitedItems.explosivesPt1'} />
            </span>
            <span>
              <FormattedMessage id={'static.prohibitedItems.explosivesPt2'} />
            </span>
            <span>
              <FormattedMessage id={'static.prohibitedItems.explosivesPt3'} />
            </span>
          </div>
        </div>
        <Divider space={ESpaceSize.PIXEL_24} />
        <div className={s.ProhibitedItemsPage__contentItem}>
          <ImageWithFallback src={toxic} size={60} />
          <div className={s.ProhibitedItemsPage__textCols}>
            <span>
              <FormattedMessage id={'static.prohibitedItems.toxicPt1'} />
            </span>
            <span>
              <FormattedMessage id={'static.prohibitedItems.toxicPt2'} />
            </span>
            <span>
              <FormattedMessage id={'static.prohibitedItems.toxicPt3'} />
            </span>
            <span>
              <FormattedMessage id={'static.prohibitedItems.toxicPt4'} />
            </span>
          </div>
        </div>
        <Divider space={ESpaceSize.PIXEL_24} />
        <div className={s.ProhibitedItemsPage__contentItem}>
          <ImageWithFallback src={cash} size={60} />
          <div className={s.ProhibitedItemsPage__textCols}>
            <span>
              <FormattedMessage id={'static.prohibitedItems.cashPt1'} />
            </span>
            <span>
              <FormattedMessage id={'static.prohibitedItems.cashPt2'} />
            </span>
            <span>
              <FormattedMessage id={'static.prohibitedItems.cashPt3'} />
            </span>
          </div>
        </div>
        <Divider space={ESpaceSize.PIXEL_24} />
        <div className={s.ProhibitedItemsPage__contentItem}>
          <ImageWithFallback src={guns} size={60} />
          <div className={s.ProhibitedItemsPage__textCols}>
            <span>
              <FormattedMessage id={'static.prohibitedItems.guns'} />
            </span>
          </div>
        </div>
        <Divider space={ESpaceSize.PIXEL_24} />
        <div className={s.ProhibitedItemsPage__contentItem}>
          <ImageWithFallback src={drugs} size={60} />
          <div className={s.ProhibitedItemsPage__textCols}>
            <span>
              <FormattedMessage id={'static.prohibitedItems.drugsPt1'} />
            </span>
            <span>
              <FormattedMessage id={'static.prohibitedItems.drugsPt2'} />
            </span>
          </div>
        </div>
        <Divider space={ESpaceSize.PIXEL_24} />
        <div className={s.ProhibitedItemsPage__contentItem}>
          <ImageWithFallback src={animals} size={60} />
          <div className={s.ProhibitedItemsPage__textCols}>
            <span>
              <FormattedMessage id={'static.prohibitedItems.animalsPt1'} />
            </span>
            <span>
              <FormattedMessage id={'static.prohibitedItems.animalsPt2'} />
            </span>
            <span>
              <FormattedMessage id={'static.prohibitedItems.animalsPt3'} />
            </span>
            <span>
              <FormattedMessage id={'static.prohibitedItems.animalsPt4'} />
            </span>
            <span>
              <FormattedMessage id={'static.prohibitedItems.animalsPt5'} />
            </span>
          </div>
        </div>
        <Divider space={ESpaceSize.PIXEL_24} />
        <div className={s.ProhibitedItemsPage__contentItem}>
          <ImageWithFallback src={food} size={60} />
          <div className={s.ProhibitedItemsPage__textCols}>
            <span>
              <FormattedMessage id={'static.prohibitedItems.food'} />
            </span>
          </div>
        </div>
        <Divider space={ESpaceSize.PIXEL_24} />
        <div className={s.ProhibitedItemsPage__contentItem}>
          <ImageWithFallback src={patent} size={60} />
          <div className={s.ProhibitedItemsPage__textCols}>
            <span>
              <FormattedMessage id={'static.prohibitedItems.patentPt1'} />
            </span>
            <span>
              <FormattedMessage id={'static.prohibitedItems.patentPt2'} />
            </span>
          </div>
        </div>
        <Divider space={ESpaceSize.PIXEL_24} />
        <div className={s.ProhibitedItemsPage__contentItem}>
          <ImageWithFallback src={alcohol} size={60} />
          <div className={s.ProhibitedItemsPage__textCols}>
            <span>
              <FormattedMessage id={'static.prohibitedItems.alcoholPt1'} />
            </span>
            <span>
              <FormattedMessage id={'static.prohibitedItems.alcoholPt2'} />
            </span>
          </div>
        </div>
        <Divider space={ESpaceSize.PIXEL_24} />
        <div className={s.ProhibitedItemsPage__contentItem}>
          <ImageWithFallback src={casino} size={60} />
          <div className={s.ProhibitedItemsPage__textCols}>
            <span>
              <FormattedMessage id={'static.prohibitedItems.casino'} />
            </span>
          </div>
        </div>
        <Divider space={ESpaceSize.PIXEL_24} />
        <div className={s.ProhibitedItemsPage__contentItem}>
          <ImageWithFallback src={adult} size={60} />
          <div className={s.ProhibitedItemsPage__textCols}>
            <span>
              <FormattedMessage id={'static.prohibitedItems.adult'} />
            </span>
          </div>
        </div>
        <Divider space={ESpaceSize.PIXEL_24} />
      </div>
    </>
  );
};
