import React, { useMemo } from 'react';
import clsx from 'clsx';
import { PartialRecord, ReactFCC } from '../../utils/helperTypes';
import { ESize } from '../../utils/ui';
import { ColType, getColsObject } from './Grid';
import s from './Grid.module.scss';

export interface GridItemProps {
  className?: string;
  cols?: ColType | PartialRecord<ESize, ColType>;
  start?: ColType | PartialRecord<ESize, ColType>;
  full?: boolean;
}

export const GridItem: ReactFCC<GridItemProps> = (props) => {
  const { children, className, cols, start, full } = props;

  const classes = useMemo(() => {
    if ((!cols && !start) || full) {
      return s.GridItem_full;
    }

    const colsDefault = 1;

    const colObject = getColsObject(cols || colsDefault, 1);

    const classes = Object.entries(colObject).map(([key, value]) => s[`GridItem_${key}_${value}`]);

    if (start) {
      const startObject = getColsObject(start, 1);
      classes.push(...Object.entries(startObject).map(([key, value]) => s[`GridItem_start_${key}_${value}`]));
    }

    return classes;
  }, [cols, start, full]);

  return <div className={clsx(s.GridItem, className, classes)}>{children}</div>;
};
