import React from 'react';
import clsx from 'clsx';
import { Modal as ModalBootstrap } from 'react-bootstrap';
import { useWindowSize } from 'common/hooks/useWindowSize';
import { ReactFCC } from 'common/utils/helperTypes';
import s from '../Modal.module.scss';

export interface ModalBodyProps {
  className?: string;
}

export const ModalBody: ReactFCC<ModalBodyProps> = ({ children, className }) => {
  const { isMobile } = useWindowSize();

  return isMobile ? (
    <div className={clsx(s.ModalMobile__body, className)}>{children}</div>
  ) : (
    <ModalBootstrap.Body className={clsx(className)}>{children}</ModalBootstrap.Body>
  );
};
