import clsx from 'clsx';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Head } from '../../../common/components/Head';
import { Heading, HeadingSize } from '../../../common/components/Heading';
import { ProductsGrid } from '../../../widgets/product';
import { useFavoritesQuery } from '../../../store/graphql';
import { Pagination, usePagination } from '../../../common/components/Pagination';
import { DEFAULT_PAGE_LIMIT } from '../../../common/components/Pagination';
import { LoaderBox } from '../../../common/components/Loader';
import { RemoveAllFavorites } from '../../../features/product/favorite';
import s from './FavoritesPage.module.scss';

export const FavoritesPage = () => {
  const { offset, setOffset } = usePagination();

  const { data, loading } = useFavoritesQuery({
    variables: {
      limit: DEFAULT_PAGE_LIMIT,
      offset
    },
    fetchPolicy: 'cache-and-network'
  });

  const products = data?.products.entries || [];
  const pagination = data?.products.pagination;

  const isEmpty = !loading && pagination && pagination.totalCount === 0;

  return (
    <Fragment>
      <Head title={'Favorites'} />

      <div className={clsx(s.FavoritesPage__header)}>
        <Heading size={HeadingSize.H3}>
          <FormattedMessage id={'product.favorites'} />
        </Heading>

        <RemoveAllFavorites
          className={clsx(s.FavoritesPage__removeAll, !isEmpty && s.FavoritesPage__removeAll_visible)}
        />
      </div>

      {loading && <LoaderBox />}

      {isEmpty && (
        <span className={s.FavoritesPage__empty}>
          <FormattedMessage id={'product.emptyFavorites'} />
        </span>
      )}

      {!isEmpty && !loading && <ProductsGrid className={s.FavoritesPage__products} products={products} cols={3} />}

      {!isEmpty && pagination && (
        <Pagination offset={offset} setOffset={setOffset} limit={pagination.limit} totalCount={pagination.totalCount} />
      )}
    </Fragment>
  );
};
