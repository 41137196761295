import { addDays, format, isThisYear, isToday, Locale } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';
import { AvailableLanguagesEnum, DeliverySpeedDaysEnum } from '../../store/graphql';

export const getShippingDate = (
  days: DeliverySpeedDaysEnum,
  locale: AvailableLanguagesEnum = AvailableLanguagesEnum.Japanese
) => {
  const deliverySpeedDaysValues: Record<DeliverySpeedDaysEnum, [number, number]> = {
    [DeliverySpeedDaysEnum.OneOrTwo]: [1, 2],
    [DeliverySpeedDaysEnum.ThreeOrFour]: [3, 4],
    [DeliverySpeedDaysEnum.FromFiveToSeven]: [5, 7]
  };

  const locales = {
    [AvailableLanguagesEnum.English]: enUS,
    [AvailableLanguagesEnum.Japanese]: ja
  };

  let values = deliverySpeedDaysValues[days];

  const leftDate = format(addDays(new Date(), values[0]), 'MMMM do', {
    locale: locales[locale]
  });

  const rightDate = format(addDays(new Date(), values[1]), 'MMMM do', {
    locale: locales[locale]
  });

  return `${leftDate} – ${rightDate}`;
};

export const getDeliveryDate = (
  daysMin: number,
  daysMax: number,
  locale: AvailableLanguagesEnum = AvailableLanguagesEnum.Japanese
) => {
  const locales = {
    [AvailableLanguagesEnum.English]: enUS,
    [AvailableLanguagesEnum.Japanese]: ja
  };

  if (daysMin === daysMax) {
    return format(addDays(new Date(), daysMin), 'MMMM do', {
      locale: locales[locale]
    });
  } else {
    const leftDate = format(addDays(new Date(), daysMin), 'MMMM do', {
      locale: locales[locale]
    });

    const rightDate = format(addDays(new Date(), daysMax), 'MMMM do', {
      locale: locales[locale]
    });

    return `${leftDate} – ${rightDate}`;
  }
};

export const getDefaultDate = (date: Date | number) => format(date, 'dd.MM.yyyy');

export const isCurrentDay = (date: Date) => {
  const currentDate = new Date();
  return (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getDate() === currentDate.getDate()
  );
};

export const getYamatoWeekDates = () => {
  let dates = [];
  let currentDate = new Date();
  if (currentDate.getHours() >= 17) {
    currentDate.setDate(currentDate.getDate() + 1);
  }
  for (let i = 0; i < 7; i++) {
    dates.push(new Date(currentDate)); // Add a copy of the current date to the array
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }
  return dates;
};

export const isAheadCurrentTime = (date: Date) => {
  const currentDate = new Date();
  const time1 = currentDate.getHours() * 3600 + currentDate.getMinutes() * 60 + currentDate.getSeconds();
  const time2 = date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
  return time1 > time2;
};

export const getDateFromHoursString = (hours: number) => {
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const formatDateDependent = (date: Date, isMobile?: boolean) => {
  if (isToday(date)) {
    return format(date, 'HH:mm');
  } else if (isThisYear(date)) {
    return format(date, isMobile ? 'MMM d' : 'MMMM d');
  } else {
    return format(date, 'yyyy-MM-dd');
  }
};

const getTodayLocalized = (locale: Locale) => {
  switch (locale.code) {
    case 'en':
      return 'Today';
    case 'ja':
      return '今日';
    default:
      return 'Today';
  }
};
export const formatDateHeader = (date: Date, locale: Locale) => {
  if (isToday(date)) {
    return getTodayLocalized(locale);
  } else if (isThisYear(date)) {
    return format(date, 'd MMMM', { locale });
  } else {
    return format(date, 'yyyy-MM-dd', { locale });
  }
};
