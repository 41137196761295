import { ReactFCC } from 'common/utils/helperTypes';
import { inRange } from 'lodash-es';
import { Progress } from '../../../../../common/components/Progress/Progress';
import { ProgressItem } from '../../../../../common/components/Progress/ProgressItem/ProgressItem';

export interface TrustPointsProgressProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Дополнительные css-классы для дочерних элементов
   * * item - айтем прогресса
   * * icon - дефолтная иконка прогресса
   * * text - текст айтема прогресса
   */
  classes?: {
    item?: string;
    icon?: string;
    text?: string;
  };
  /**
   * Текущее количество трастовых поинтов
   */
  currentAmount?: number;
}

const progressValues = [
  {
    value: 0,
    starting: true
  },
  {
    value: 20
  },
  {
    value: 40
  },
  {
    value: 60
  },
  {
    value: 80
  },
  {
    value: 100,
    ending: true
  }
];

export const TrustPointsProgress: ReactFCC<TrustPointsProgressProps> = (props) => {
  const { className, currentAmount, classes } = props;

  /**
   * Не отображаем прогресс если не получено текущее значение
   */
  const disabled = typeof currentAmount !== 'number';

  return (
    <Progress className={className}>
      {progressValues.map((item, index) => (
        <ProgressItem
          className={classes?.item}
          active={
            !disabled &&
            inRange(currentAmount, item.starting ? item.value + 1 : progressValues[index - 1].value + 1, item.value)
          }
          completed={!disabled && currentAmount >= item.value && currentAmount !== 0}
          growing={!disabled && currentAmount > item.value}
          starting={item.starting}
          ending={item.ending}
          classes={classes}
          key={index}
        >
          {item.value}
        </ProgressItem>
      ))}
    </Progress>
  );
};
