import React, { ComponentProps, ComponentPropsWithoutRef, ElementType, useMemo, JSX } from 'react';
import clsx from 'clsx';
import { PolyExtends } from 'common/utils/helperTypes';
import { Loader, LoaderVariant } from '../../Loader';
import s from './Button.module.scss';

export enum ButtonVariant {
  PRIMARY = 'primary',
  PRIMARY_OUTLINE = 'primary_outline',
  SECONDARY = 'secondary',
  SECONDARY_OUTLINE = 'secondary_outline',
  INPUT = 'input',
  SUCCESS = 'success',
  SUCCESS_OUTLINE = 'success_outline',
  ERROR = 'error',
  ERROR_OUTLINE = 'error_outline'
}

export enum ButtonSize {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg'
}

export enum ButtonFit {
  FIT = 'fit',
  FILL = 'fill'
}

export interface ButtonIconOptions {
  size?: number;
}

export const ButtonDefaultComponent = 'button' as const;
export type ButtonDefaultComponentType = typeof ButtonDefaultComponent;

export interface ButtonPropsSelf<ComponentType extends ElementType = ButtonDefaultComponentType> {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Дополнительные css-классы элементов:
   * * root – рутовый элемент
   * * leftIcon – класс иконки слева
   * * rightIcon – класс иконки справа
   */
  classes?: {
    root?: string;
    leftIcon?: string;
    rightIcon?: string;
  };
  /**
   * Вариант кнопки
   */
  variant?: ButtonVariant;
  /**
   * Размер кнопки (small, medium (default), large)
   */
  size?: ButtonSize;
  /**
   * Растягивание кнопки (fit, fill)
   */
  fit?: ButtonFit;
  /**
   * Заблокированное состояние Button
   */
  disabled?: boolean;
  /**
   * Проп для контролируемого включения состояния ховера
   */
  hovered?: boolean;
  /**
   * Проп для контролируемого включения состояния нажатия
   */
  active?: boolean;
  /**
   * Состояние загрузки
   */
  loading?: boolean;
  /**
   * Иконка слева
   */
  leftIcon?: React.ElementType;
  /**
   * Параметры иконки слева
   */
  leftIconOptions?: ButtonIconOptions;
  /**
   * Иконка слева
   */
  rightIcon?: React.ElementType;
  /**
   * Параметры иконки справа
   */
  rightIconOptions?: ButtonIconOptions;
  /**
   * Реф на корневой элемент
   */
  innerRef?: ComponentProps<ComponentType>['ref'];
  /**
   * Слот для содержимого
   */
  children?: React.ReactNode;
}

export type ButtonProps<ComponentType extends ElementType = ButtonDefaultComponentType> = PolyExtends<
  ComponentType,
  ButtonPropsSelf<ComponentType>,
  ComponentPropsWithoutRef<ComponentType>
>;

const interactiveElements = ['button', 'input'];

export function Button(props: ButtonProps<'button'>): JSX.Element;
export function Button<ComponentType extends ElementType>(props: ButtonProps<ComponentType>): JSX.Element;
export function Button<ComponentType extends ElementType = ButtonDefaultComponentType>(
  props: ButtonProps<ComponentType>
) {
  const {
    children,
    component,
    className,
    classes,
    variant = ButtonVariant.PRIMARY,
    size = ButtonSize.MEDIUM,
    fit = ButtonFit.FIT,
    leftIcon: LeftIcon,
    leftIconOptions,
    rightIcon: RightIcon,
    rightIconOptions,
    loading,
    disabled,
    innerRef,
    ...restProps
  } = props;

  const Component = component || ButtonDefaultComponent;

  const loaderVariant = useMemo(() => {
    switch (variant) {
      case ButtonVariant.PRIMARY_OUTLINE:
      case ButtonVariant.INPUT:
        return LoaderVariant.primary;
      case ButtonVariant.PRIMARY:
      default:
        return LoaderVariant.light;
    }
  }, [variant]);

  return (
    <Component
      ref={innerRef}
      className={clsx(
        'btn',
        size !== ButtonSize.MEDIUM && `btn-${size}`,
        s.Button,
        className,
        classes?.root,
        s[`Button_variant_${variant}`],
        s[`Button_size_${size}`],
        s[`Button_fit_${fit}`],
        {
          [s.Button_loading]: loading,
          [s.Button_disabled]: !interactiveElements.includes(component as string) && (loading || disabled)
        }
      )}
      disabled={interactiveElements.includes(component as string) && (loading || disabled)}
      {...restProps}
    >
      {loading ? (
        <Loader variant={loaderVariant} small />
      ) : (
        <>
          {LeftIcon && (
            <LeftIcon
              className={clsx(s.Button__icon, classes?.leftIcon)}
              style={{
                width: leftIconOptions?.size && `${leftIconOptions.size}px`,
                height: leftIconOptions?.size && `${leftIconOptions.size}px`
              }}
            />
          )}

          {children}

          {RightIcon && (
            <RightIcon
              className={clsx(s.Button__icon, classes?.rightIcon)}
              style={{
                width: rightIconOptions?.size && `${rightIconOptions.size}px`,
                height: rightIconOptions?.size && `${rightIconOptions.size}px`
              }}
            />
          )}
        </>
      )}
    </Component>
  );
}
