import React from 'react';
import clsx from 'clsx';
import { CustomIcon } from '../../CustomIcon/CustomIcon';
import { customIcons } from '../../CustomIcon/icons';
import s from './PaymentCardNumber.module.scss';

export interface PaymentCardNumberProps {
  className?: string;
  cardLastNumbers: string;
}

export const PaymentCardNumber = ({ className, cardLastNumbers }: PaymentCardNumberProps) => {
  return (
    <div className={clsx(s.PaymentCardNumber, className)}>
      <CustomIcon className={s.PaymentCardNumber__icon} icon={customIcons.CardsStack} />
      **** **** **** {cardLastNumbers || '****'}
    </div>
  );
};
