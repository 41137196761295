import React from 'react';
import { useIntl } from 'react-intl';
import { ReactFCC } from '../../../../../../common/utils/helperTypes';
import { FormInput } from '../../../../../../common/components/inputs';
import { ESpaceSize } from '../../../../../../common/components/Space/Space';
import { AddressFormFieldsProps } from '../types';
import { AddressSchemaKeys } from '../../schema';

export const AddressFormFieldsNoZip: ReactFCC<AddressFormFieldsProps> = (props) => {
  const { formik, disabledSellerAddress } = props;

  const intl = useIntl();

  return (
    <>
      <FormInput
        name={AddressSchemaKeys.region}
        label={intl.formatMessage({ id: 'address.form.region' })}
        placeholder={intl.formatMessage({ id: 'address.form.region.placeholder' })}
        onChange={formik.handleChange}
        value={formik.values.region}
        error={formik.errors.region}
        space={ESpaceSize.PIXEL_12}
        required
        disabled={disabledSellerAddress}
      />

      <FormInput
        name={AddressSchemaKeys.city}
        label={intl.formatMessage({ id: 'address.form.city' })}
        placeholder={intl.formatMessage({ id: 'address.form.city.placeholder' })}
        onChange={formik.handleChange}
        value={formik.values.city}
        error={formik.errors.city}
        space={ESpaceSize.PIXEL_12}
        required
        disabled={disabledSellerAddress}
      />
    </>
  );
};
