import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Anchor } from '../../../common/components/Anchor';
import { cdnFilenames, useUi } from '../../../store/ui';
import { useUser } from '../../../entities/user';
import { SellerVerificationEnum } from '../../../store/graphql';
import { AddressForm, getAddressInformationFromUser, UserAddress } from '../../../widgets/address';
import { useToggle } from '../../../common/hooks/useToggle';
import { LoaderBox } from '../../../common/components/Loader';
import { Heading, HeadingSize } from '../../../common/components/Heading';
import { Head } from '../../../common/components/Head';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import { VerificationBanner } from './components';
import { VerificationForm } from './components';
import s from './VerificationPage.module.scss';

export const VerificationPage = () => {
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [sellerAddressModalOpen, { set: openSellerAddressModal, unset: closeSellerAddressModal }] = useToggle(false);
  const [editedSellerAddress, setEditedSellerAddress] = useState<UserAddress | null>(null);
  const { createCDNFileLink } = useUi();
  const { user } = useUser();
  if (!user) {
    return <LoaderBox />;
  }
  return (
    <>
      <Head title={'Verification'} />

      <Heading size={HeadingSize.H3}>
        <FormattedMessage id={'profile.verificationPageTitle'} />
      </Heading>

      <Space size={ESpaceSize.PIXEL_32} />

      <VerificationBanner onClickVerify={() => setVerificationModalOpen(true)} />
      {user.sellerVerification === SellerVerificationEnum.Verified && (
        <div className={s.VerificationPage__sellerLinks}>
          <Anchor
            component={'a'}
            href={createCDNFileLink(cdnFilenames.termsOfUse)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of use
          </Anchor>
          <Anchor
            component={'a'}
            href={createCDNFileLink(cdnFilenames.prohibitedItems)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Prohibited items
          </Anchor>
          <Anchor component={'a'} href="">
            Seller guide
          </Anchor>
          <Anchor
            component={'a'}
            href={createCDNFileLink(cdnFilenames.sizeAndWeightLimit)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Size and weight limit
          </Anchor>
          <Anchor
            component={'a'}
            href={createCDNFileLink(cdnFilenames.systemCommissions)}
            target="_blank"
            rel="noopener noreferrer"
          >
            System commissions
          </Anchor>
          <Anchor
            component={'a'}
            href={createCDNFileLink(cdnFilenames.withdrawalOfFundsByTheSeller)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Withdrawal of funds by the seller
          </Anchor>
          <Anchor
            component={'a'}
            href={createCDNFileLink(cdnFilenames.methodsAndTermsOfDelivery)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Methods and terms of delivery
          </Anchor>
        </div>
      )}

      <VerificationForm
        isOpen={verificationModalOpen}
        onClose={() => setVerificationModalOpen(false)}
        onClickOpenForm={(address) => {
          setEditedSellerAddress(address);
          setVerificationModalOpen(false);
          openSellerAddressModal();
        }}
      />

      {/* Форма адреса, вызываемая из модалки верификации */}
      <AddressForm
        isOpen={sellerAddressModalOpen}
        address={editedSellerAddress}
        defaultsSelected={user.addresses.length === 0}
        personalInformation={getAddressInformationFromUser(user)}
        onClose={() => {
          closeSellerAddressModal();
          setVerificationModalOpen(true);
          setEditedSellerAddress(null);
        }}
      />
      <Space size={ESpaceSize.PIXEL_16} />
    </>
  );
};
