import React, { forwardRef } from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import { ReactFCC } from 'common/utils/helperTypes';
import { TooltipContainer } from './TooltipContainer';

export interface TooltipProps extends TippyProps {
  text: string | React.ReactElement;
  children: React.ReactElement<any>;
}

export type TooltipType = ReactFCC<TooltipProps> & {
  Container: typeof TooltipContainer;
};

export const Tooltip: TooltipType = (props) => {
  const { text, placement = 'bottom', className, children, ...rest } = props;

  const ReferencedComponent = forwardRef((props, ref) => {
    const refProps = typeof children.type === 'string' ? { ref } : { innerRef: ref };
    return React.cloneElement(children, { ...refProps, ...props });
  });

  return (
    <Tippy content={text} placement={placement} {...rest}>
      <ReferencedComponent />
    </Tippy>
  );
};

Tooltip.Container = TooltipContainer;
