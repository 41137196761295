import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactFCC } from '../../../../../common/utils/helperTypes';
import { Modal, ModalProps } from '../../../../../common/components/Modal';
import { AddressLayout, AddressLayoutProps } from '../../AddressLayout';
import s from './AddressSelectModal.module.scss';

export type AddressSelectModalProps = {
  /**
   * Обработчик нажатия на кнопку сохранения
   */
  onSubmit?: () => void;
} & AddressLayoutProps &
  Pick<ModalProps, 'isOpen' | 'onClose' | 'title'>;

export const AddressSelectModal: ReactFCC<AddressSelectModalProps> = (props) => {
  const { isOpen, onClose, title, onSubmit, ...layoutProps } = props;

  return (
    <Modal className={s.AddressSelectModal} title={title} isOpen={isOpen} onClose={onClose}>
      <Modal.Body>
        <AddressLayout interactive={true} {...layoutProps} />
      </Modal.Body>

      <Modal.Footer>
        <Modal.Button onClick={onSubmit}>
          <FormattedMessage id={'address.select.save'} />
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};
