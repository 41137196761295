import React, { useCallback, useState } from 'react';
import { ReactFCC } from '../../common/utils/helperTypes';
import { useCdnQuery } from '../graphql';
import { usePreventWindowScroll } from '../../common/hooks/usePreventWindowScroll';
import { UiContext } from './UiContext';
import { CDNFilenameType, createCDNFileUrl } from './files';

export const UiProvider: ReactFCC = ({ children }) => {
  const [showTopNav, setShowTopNav] = useState(true);
  const [showMobileNav, setShowMobileNav] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const [authModalActive, setAuthModalActive] = useState(false);
  const [userDataModalActive, setUserDataModalActive] = useState(false);
  const [catalogOpen, setCatalogOpen] = useState(false);
  const [bodyScrollPrevented, preventBodyScroll] = useState(false);

  usePreventWindowScroll(bodyScrollPrevented);

  const { data } = useCdnQuery();
  const cdnUrl = data?.cdn || '';

  const createCDNFileLink = useCallback(
    (filename: CDNFilenameType) => {
      return cdnUrl && filename ? createCDNFileUrl(cdnUrl, filename) : '';
    },
    [cdnUrl]
  );

  const toggleAuthModal = useCallback(
    (value?: boolean) => {
      setAuthModalActive((currentValue) => (value !== undefined ? value : !currentValue));
    },
    [setAuthModalActive]
  );

  const toggleUserDataModal = useCallback(
    (value?: boolean) => {
      setUserDataModalActive((currentValue) => (value !== undefined ? value : !currentValue));
    },
    [setUserDataModalActive]
  );

  return (
    <UiContext.Provider
      value={{
        cdnUrl,
        createCDNFileLink,
        authModalActive,
        userDataModalActive,
        toggleAuthModal,
        toggleUserDataModal,

        showMobileNav,
        setShowMobileNav,
        showTopNav,
        setShowTopNav,
        showFooter,
        setShowFooter,
        catalogOpen,
        setCatalogOpen,
        bodyScrollPrevented,
        preventBodyScroll
      }}
    >
      {children}
    </UiContext.Provider>
  );
};
