import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { IconType } from 'react-icons';
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Tooltip } from '../../../../common/components/Tooltip';
import s from './HeaderNavLink.module.scss';

export interface HeaderNavLinkProps extends Omit<NavLinkProps, 'className' | 'to' | 'style' | 'onClick'> {
  /**
   * css-класс корневого элемента
   */
  className?: string;
  /**
   * Иконка
   */
  icon?: IconType;
  /**
   * Ссылка на внутренний переход
   */
  to?: NavLinkProps['to'];
  /**
   * Ссылка на внешний переход
   */
  href?: string;
  /**
   * Обработчик нажатия на ссылку
   */
  onClick?: (e: React.MouseEventHandler) => void;
  /**
   * Флаг всплывающего сообщения
   */
  tooltipEnabled?: boolean;
  /**
   * Текст всплывающего сообщения
   */
  tooltipText?: string;
}

export const HeaderNavLink: ReactFCC<HeaderNavLinkProps> = (props) => {
  const { children, className, icon, to, href, onClick, tooltipText, tooltipEnabled, ...rest } = props;

  const IconComponent = icon || undefined;

  const content = (
    <>
      {IconComponent && tooltipEnabled && tooltipText && (
        <Tooltip text={tooltipText} delay={500}>
          <div className={s.HeaderNavLink__icon}>
            <IconComponent size={20} />
          </div>
        </Tooltip>
      )}
      {IconComponent && !tooltipEnabled && (
        <div className={s.HeaderNavLink__icon}>
          <IconComponent size={20} />
        </div>
      )}
      {children}
    </>
  );

  if (to) {
    return (
      <NavLink
        className={({ isActive }) =>
          clsx(s.HeaderNavLink, className, {
            [s.HeaderNavLink_active]: isActive
          })
        }
        to={to}
        {...rest}
      >
        {content}
      </NavLink>
    );
  }

  if (href) {
    return (
      <a className={clsx(s.HeaderNavLink, className)} href={href} target="_blank" rel="noopener noreferrer" {...rest}>
        {content}
      </a>
    );
  }

  return (
    <div className={clsx(s.HeaderNavLink, className)} onClick={onClick as any}>
      {content}
    </div>
  );
};
