import { MutationResult } from '@apollo/client';
import { UpdateUserMutation, UserDocument, useUpdateUserMutation } from '../../../../store/graphql';

type UseDeleteUserAvatarReturn = [() => any, MutationResult<UpdateUserMutation>];

export const useDeleteUserAvatar = (): UseDeleteUserAvatarReturn => {
  const [fillUserMetadata, result] = useUpdateUserMutation({
    refetchQueries: [UserDocument]
  });

  const deleteAvatar = async () => {
    return await fillUserMetadata({
      variables: {
        input: {
          metadata: {
            avatarAttachmentUid: null
          }
        }
      }
    });
  };

  return [deleteAvatar, result];
};
