import { ReactFCC } from 'common/utils/helperTypes';
import { ArisoraOrderStatus } from 'store/graphql';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { Progress } from '../../../../../common/components/Progress/Progress';
import { OrderProgressItem } from './OrderProgressItem';
import s from './OrderProgress.module.scss';

export interface OrderProgressProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Дополнительные css-классы для дочерних элементов
   * * item - айтем прогресса
   * * icon - дефолтная иконка прогресса
   * * text - текст айтема прогресса
   */
  classes?: {
    item?: string;
    icon?: string;
    text?: string;
  };
  /**
   * Статус заказа из API
   */
  status: ArisoraOrderStatus;
  /**
   * Статус ошибки из API
   */
  isProblem: boolean;
}

const statuses = [
  {
    status: ArisoraOrderStatus.Preparing,
    titleIntl: 'order.progressStatusPreparing',
    starting: true
  },
  {
    status: ArisoraOrderStatus.DeliveringWithinJapan,
    titleIntl: 'order.progressStatusDeliveringWithinJapan'
  },
  {
    status: ArisoraOrderStatus.CustomsClearance,
    titleIntl: 'order.progressStatusCustomsClearance'
  },
  {
    status: ArisoraOrderStatus.InternationalDelivery,
    titleIntl: 'order.progressStatusInternationalDelivery'
  },
  {
    status: ArisoraOrderStatus.Delivered,
    titleIntl: 'order.progressStatusDelivered',
    ending: true
  }
];

const statusOrder: Record<ArisoraOrderStatus, number> = {
  [ArisoraOrderStatus.Canceled]: -1,
  [ArisoraOrderStatus.Destroyed]: -1,
  [ArisoraOrderStatus.Preparing]: 0,
  [ArisoraOrderStatus.ReadyForShipment]: 0,
  [ArisoraOrderStatus.OnPickup]: 0,
  [ArisoraOrderStatus.DeliveringWithinJapan]: 1,
  [ArisoraOrderStatus.CustomsClearance]: 2,
  [ArisoraOrderStatus.InternationalDelivery]: 3,
  [ArisoraOrderStatus.Delivered]: 4
};

const statusErrorMessageIntlId: Partial<Record<ArisoraOrderStatus, string>> = {
  [ArisoraOrderStatus.Preparing]: 'order.progressErrorPreparing',
  [ArisoraOrderStatus.DeliveringWithinJapan]: 'order.progressErrorDeliveringWithinJapan',
  [ArisoraOrderStatus.CustomsClearance]: 'order.progressErrorCustomsClearance',
  [ArisoraOrderStatus.InternationalDelivery]: 'order.progressErrorInternationalDelivery'
};

export const OrderProgress: ReactFCC<OrderProgressProps> = (props) => {
  const { className, status, isProblem, classes, ...rest } = props;

  const intl = useIntl();

  return (
    <Progress className={clsx(s.OrderProgress, className)}>
      {statuses.map((item, index) => (
        <OrderProgressItem
          key={index}
          className={classes?.item}
          status={status}
          ending={item.ending}
          starting={item.starting}
          growing={statusOrder[status] > index}
          active={statusOrder[status] === index}
          completed={statusOrder[status] > index || (item.ending && statusOrder[status] === index)}
          /**
           * Если есть ошибка при "рабочих" статусах, выводим ошибку с текстом, если текста нет,
           * то просто ошибку без текста
           **/
          error={
            isProblem && statusOrder[status] === index
              ? statusErrorMessageIntlId[status]
                ? intl.formatMessage({ id: statusErrorMessageIntlId[status] })
                : true
              : false
          }
          nextError={isProblem && statusOrder[status] === index + 1}
          titleIntl={item.titleIntl}
          classes={classes}
          {...rest}
        />
      ))}
    </Progress>
  );
};
