import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { Loader, LoaderSize } from '../../../../common/components/Loader';
import { useIsMobile } from '../../../../common/hooks/useIsMobile';
import { AvailableCurrencyEnum } from '../../../../store/currency/CurrencyContext';
import { useCurrency } from '../../../../store/currency';
import { getCurrencySymbol } from '../../../../common/utils/format';
import s from './HeaderNavCurrency.module.scss';

export interface HeaderNavCurrencyProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
}

const currencyTitle = {
  [AvailableCurrencyEnum.JPY]: 'JPY',
  [AvailableCurrencyEnum.USD]: 'USD',
  [AvailableCurrencyEnum.EUR]: 'EUR',
  [AvailableCurrencyEnum.CAD]: 'CAD',
  [AvailableCurrencyEnum.GBP]: 'GBP',
  [AvailableCurrencyEnum.AUD]: 'AUD',
  [AvailableCurrencyEnum.MYR]: 'MYR'
};

export const HeaderNavCurrency: ReactFCC<HeaderNavCurrencyProps> = (props) => {
  const { className } = props;

  const isMobile = useIsMobile();
  const { currency, currencyLoading, changeUserCurrency } = useCurrency();

  return currencyLoading ? (
    <div className={clsx(s.HeaderNavLang, className)}>
      <Loader size={LoaderSize.SMALL} />
    </div>
  ) : (
    <NavDropdown
      className={clsx(s.HeaderNavCurrency, className)}
      title={`${getCurrencySymbol(currency)} - ${currencyTitle[currency]} `}
      align={isMobile ? 'end' : 'start'}
    >
      {Object.entries(AvailableCurrencyEnum).map(([key, value], index) => (
        <NavDropdown.Item role="button" onClick={() => changeUserCurrency(key as AvailableCurrencyEnum)} key={index}>
          <div className={s.HeaderNavCurrency__value}>
            <span>{value} </span>
            <span>{getCurrencySymbol(value)}</span>
          </div>
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};
