import { PropsWithChildren, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { URL_KEY_IS_MOBILE_APP, URL_KEY_LANGUAGE } from '../urlKeys';
import { useQueryParam } from '../../common/hooks/queryParam/useQueryParam';
import { AvailableLanguagesEnum } from '../graphql';
import { useWebview } from '../webview/useWebview';
import { OPEN_MOBILE_APP_ROUTE } from '../../common/utils/routes';
import { useBatchedQueryParam } from '../../common/hooks/queryParam/useBatchedQueryParam';

export const StripeCallbackRedirect = ({ children }: PropsWithChildren) => {
  const { isWebview } = useWebview();
  const [fromMobileApp, setFromMobileApp] = useBatchedQueryParam<boolean>(URL_KEY_IS_MOBILE_APP, {
    defaultValue: false,
    getter: (value) => value === 'true'
  });
  const [language] = useQueryParam<AvailableLanguagesEnum>(URL_KEY_LANGUAGE);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isWebview && fromMobileApp) {
      setFromMobileApp(false);
    }
  }, [fromMobileApp, isWebview, setFromMobileApp]);

  return !isWebview && fromMobileApp ? (
    <Navigate
      to={OPEN_MOBILE_APP_ROUTE}
      state={{
        pathname,
        language
      }}
      replace
    />
  ) : (
    <>{children}</>
  );
};
