import React from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router';
import { createSearchParams, URLSearchParamsInit } from 'react-router-dom';

export const useGeneratePathWithQuery = () => {
  let { navigator } = React.useContext(NavigationContext);

  return (path: string, params: URLSearchParamsInit) =>
    navigator.createHref({
      pathname: path,
      search: createSearchParams(params).toString()
    });
};
