import React, { FunctionComponent, useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import clsx from 'clsx';
import { Heading, HeadingSize } from '../../../../../common/components/Heading';
import { CustomIcon } from '../../../../../common/components/CustomIcon';
import { LinksList, LinksListItem } from '../../../../../common/components/LinkList';
import s from './SupportItem.module.scss';

export interface SupportLinksItem {
  /**
   * Назавние ссылки
   **/
  name: string;
  /**
   * url ссылки
   **/
  path: string;
  /**
   * Является ли ссылка внешней от фронтенда
   **/
  external?: boolean;
}

export type SupportItemProps = {
  /**
   * Название айтема
   **/
  title: string;
  /**
   * Дополнительный класс для айтема
   **/
  className?: string;
  /**
   * Дополнительный классы
   **/
  classes?: {
    /**
     * Дополнительный класс иконки айтема
     **/
    chevron?: string;
  };
  /**
   * Массив ссылок относящийся к айтему
   **/
  items: SupportLinksItem[];
  /**
   * Опциональная иконка айтема
   **/
  headerIcon?: FunctionComponent<React.SVGProps<SVGSVGElement>>;
};
export const SupportItem = ({ title, className, classes, items, headerIcon }: SupportItemProps) => {
  const [expanded, setExpanded] = useState(false);
  const IconComponent = expanded ? BsChevronUp : BsChevronDown;

  const toggleExpand = () => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <>
      <div className={clsx(s.SupportItem, className)} onClick={() => toggleExpand()}>
        <div className={s.SupportItem__heading}>
          {headerIcon && <CustomIcon icon={headerIcon} className={s.SupportItem__headerIcon} />}
          <Heading size={HeadingSize.H6}>{title}</Heading>
        </div>
        <IconComponent className={classes?.chevron} />
      </div>
      <LinksList
        className={s.SupportItem__items}
        classes={{ expanded: s.SupportItem__items_expanded }}
        expanded={expanded}
      >
        {items.map(({ name, path, external }: SupportLinksItem, index) => (
          <LinksListItem key={index} name={name} path={path} external={external} className={s.SupportItem__link} />
        ))}
      </LinksList>
    </>
  );
};
