import {
  BankAccountType,
  BankAccountTypeEnum,
  CreateBankAccountPresetInput,
  useCreateBankAccountMutation,
  useUpdateBankAccountMutation
} from 'store/graphql';
import { useLayoutEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { UseFormikType } from 'common/utils/helperTypes';
import { toast } from 'react-toastify';
import { handleDefaultError } from 'common/utils/handleDefaultError';
import { clearCache } from 'app/providers/auth-apollo';
import { BankAccountSchema, BankAccountValues } from './schema';

export interface UseBankAccountFormProps {
  /**
   * Объект адреса для редактирования
   */
  bankAccount?: BankAccountType | null;
  /**
   * Колбэк на создание/обновление аккаунта
   */
  onAccountSaved?: (id: BankAccountType['id']) => void;
  /**
   * Очищать форму после отправки
   */
  resetOnSubmit?: boolean;
  /**
   * Выполнять рефетч банковских аккаунтов после создания
   */
  refetchWhenCreated?: boolean;
}

export interface UseBankAccountFormReturn {
  initialBankAccount?: BankAccountType;
  initialValues: BankAccountValues;
  formik: UseFormikType<BankAccountValues>;
  loading?: boolean;
}

const mutationOptions = {
  refetchQueries: ['BankAccounts'],
  update: clearCache(['getMyBankAccountPresets'])
};

export const useBankAccountForm = (props: UseBankAccountFormProps): UseBankAccountFormReturn => {
  const { bankAccount: initialBankAccount, onAccountSaved, resetOnSubmit, refetchWhenCreated } = props;

  const [createBankAccountMutation, { loading: createLoading }] = useCreateBankAccountMutation(
    refetchWhenCreated ? mutationOptions : undefined
  );
  const [updateBankAccountMutation, { loading: updateLoading }] = useUpdateBankAccountMutation(mutationOptions);

  const initialValues = useMemo(
    () => ({
      title: initialBankAccount?.title ?? '',
      account_type: initialBankAccount?.account_type ?? BankAccountTypeEnum.CurrentAccount,
      account_number: initialBankAccount?.account_number ?? '',
      organization_name: initialBankAccount?.organization_name ?? '',
      organization_code: initialBankAccount?.organization_code ?? '',
      organization_hiragana: initialBankAccount?.organization_hiragana ?? '',
      branch_name: initialBankAccount?.branch_name ?? '',
      branch_code: initialBankAccount?.branch_code ?? '',
      branch_hiragana: initialBankAccount?.branch_hiragana ?? ''
    }),
    [initialBankAccount]
  );

  const formik = useFormik<BankAccountValues>({
    initialValues: initialValues as BankAccountValues,
    validationSchema: BankAccountSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, helpers) => {
      const input: CreateBankAccountPresetInput = {
        account_type: values.account_type as BankAccountTypeEnum,
        organization_name: values.organization_name,
        organization_code: values.organization_code,
        organization_hiragana: values.organization_hiragana,
        branch_name: values.branch_name,
        branch_code: values.branch_code,
        branch_hiragana: values.branch_hiragana,
        account_number: values.account_number
      };

      if (!initialBankAccount) {
        try {
          const response = await createBankAccountMutation({
            variables: {
              input
            }
          });

          toast.success('The bank preset has been successfully created');

          if (resetOnSubmit) {
            helpers.resetForm();
          }

          const id = response.data?.createBankAccountPreset.id as BankAccountType['id'];
          onAccountSaved?.(id);
        } catch (e) {
          handleDefaultError('An error occurred while creating a bank details preset', e);
        }
      } else {
        try {
          const response = await updateBankAccountMutation({
            variables: {
              data: input,
              where: {
                id: initialBankAccount.id
              }
            }
          });

          toast.success('The bank preset has been successfully updated');

          if (resetOnSubmit) {
            helpers.resetForm();
          }

          const id = response.data?.updateBankAccountPreset.id as BankAccountType['id'];
          onAccountSaved?.(id);
        } catch (e) {
          handleDefaultError('An error occurred while updating a bank details preset', e);
        }
      }
    }
  });

  const formSetValues = formik.setValues;

  useLayoutEffect(() => {
    if (initialValues) {
      formSetValues(initialValues);
    }
  }, [formSetValues, initialValues]);

  return {
    initialBankAccount: initialBankAccount || undefined,
    initialValues,
    formik,
    loading: createLoading || updateLoading
  };
};
