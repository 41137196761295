import { ReactNativeMessenger } from '@proscom/rn-webview-messenger-web';

export const rnMessenger = new ReactNativeMessenger({
  // Обязательно:
  // Ссылка на window
  window,
  // По этому имени будет вызываться функция приема сообщения из натива
  callbackName: 'window.rnMessenger.handleMessage'
  // Версия веб-приложения на случай если понадобится определять
  // доступность тех или иных функций
  // webVersion: appVersion,

  // Опционально:
  // webInfo: {}, // объект в неизменном виде передается в натив
  // debug: true, // можно передать для отладки
  // log: console.log // можно передать для отладки
});

// @ts-ignore: rnMessenger
window.rnMessenger = rnMessenger;
