import { useCallback, useEffect, useState } from 'react';
import { WEBVIEW_METHOD_SCROLL_ON_TOP } from '../constants/webview';
import { useUi } from '../../store/ui/useUi';
import { useWebview } from '../../store/webview/useWebview';

export function useWebviewScrollOnTop() {
  const { catalogOpen, bodyScrollPrevented } = useUi();
  const { callWebviewMethod } = useWebview();
  const [isOnTop, setIsOnTop] = useState((document.documentElement.scrollTop || document.body.scrollTop || 0) === 0);

  const readyToSwipe = !bodyScrollPrevented && !catalogOpen && isOnTop;

  const handleScroll = useCallback(() => {
    setIsOnTop((document.documentElement.scrollTop || document.body.scrollTop || 0) === 0);
  }, []);

  useEffect(() => {
    callWebviewMethod(WEBVIEW_METHOD_SCROLL_ON_TOP, readyToSwipe);
  }, [callWebviewMethod, readyToSwipe]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
}
