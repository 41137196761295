import React, { useEffect } from 'react';
import { ReactFCC } from 'common/utils/helperTypes';
import { Brand, BrandSearchInput, useCatalogBrandsQuery } from 'store/graphql';
import { useLayoutEffect, useMemo, useState } from 'react';
import { DEFAULT_INPUT_DELAY, FormInputSelect, FormInputSelectProps } from 'common/components/inputs';

export interface BrandInputProps extends Omit<FormInputSelectProps, 'value' | 'onChange' | 'options' | 'onItemSelect'> {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Массов ID выбранных опций
   */
  active: Brand['id'] | null;
  /**
   * Сеттер массова ID выбранных опций
   */
  setActive: React.Dispatch<Brand['id'] | null>;
}

const PAGE_LIMIT = 20;

/**
 * todo Не очень хорошо, что он находится здесь, временное решение. Необходимо вынести куда-то в features/widgets
 */
export const BrandInput: ReactFCC<BrandInputProps> = (props) => {
  const { active, setActive, ...inputProps } = props;

  const [inputValue, setInputValue] = useState('');

  const queryInput: BrandSearchInput = useMemo(
    () => ({
      nameContains: inputValue
    }),
    [inputValue]
  );

  const queryVars = useMemo(
    () => ({
      input: {
        ...queryInput,
        pagination: {
          offset: 0,
          limit: PAGE_LIMIT
        }
      }
    }),
    [queryInput]
  );

  const { data, loading, fetchMore, refetch } = useCatalogBrandsQuery({
    variables: queryVars
  });

  const items = useMemo(() => data?.brands.entries || [], [data?.brands.entries]);
  const options = useMemo(() => items.map((i) => ({ title: i.name, value: i.id })), [items]);

  const totalCount = data?.brands.pagination.totalCount || 0;
  const offset = items.length;
  const hasMore = totalCount > offset;

  useLayoutEffect(() => {
    refetch(queryVars);
  }, [inputValue, queryVars, refetch]);

  useEffect(() => {
    if (active && inputValue === '') {
      refetch({
        input: {
          ids: [active],
          nameContains: ''
        }
      }).then((data) => {
        const brand = data.data.brands.entries[0];

        if (brand) {
          setInputValue(brand.name);
        }
      });
    }
  }, [active, inputValue, refetch]);

  return (
    <FormInputSelect
      options={options}
      value={inputValue}
      onChange={(e) => {
        setInputValue(e.target.value);
        setActive(null);
      }}
      onItemSelect={(option) => option.value && setActive(option.value as string)}
      inputDelayMs={DEFAULT_INPUT_DELAY}
      hasMore={hasMore}
      fetchMore={() =>
        fetchMore({
          variables: {
            input: {
              ...queryInput,
              pagination: {
                limit: PAGE_LIMIT,
                offset
              }
            }
          }
        })
      }
      loading={loading}
      {...inputProps}
    />
  );
};
