import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useUpdateEffect } from 'react-use';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal } from '../../../../common/components/Modal';
import { useUpdateUser } from '../../../../features/user/updateUser';
import { cdnFilenames, useUi } from '../../../../store/ui';
import { FormInput } from '../../../../common/components/inputs';
import { ESpaceSize, Space } from '../../../../common/components/Space/Space';
import { ReactFCC } from '../../../../common/utils/helperTypes';
import {
  SellerVerificationEnum,
  useRegisterCustomerMutation,
  useRemoveProfileMutation
} from '../../../../store/graphql';
import { handleDefaultError } from '../../../../common/utils/handleDefaultError';
import { getGraphqlErrorMessage } from '../../../../common/utils/graphqlErrors';
import { clearCache, useAuth } from '../../../../app/providers/auth-apollo';
import { GoogleTagEvents, googleTagSendDefaultEvent } from '../../../../features/analytics';
import { Anchor } from '../../../../common/components/Anchor';
import { useUser } from '../../../../entities/user';
import { VERIFICATION_ROUTE } from '../../../../common/utils/routes';
import { Link } from '../../../../common/components/Link';
import { LinkBack } from '../../../../common/components/LinkBack';
import { Loader, LoaderSize } from '../../../../common/components/Loader';
import { maxNameLength } from './constants';
import { TUserDataModalValues, UserDataModalKeys, UserDataModalSchema } from './userDataModalSchema';
import s from './UserDataModal.module.scss';

export interface TUserDataModal {
  isOpen: boolean;
  onClose: () => void;
  email?: string | null;
  isSignUp?: boolean;
  initialValues?: TUserDataModalValues;
}

export const UserDataModal: ReactFCC<TUserDataModal> = (props) => {
  const { isOpen, onClose, email, isSignUp, initialValues } = props;

  const intl = useIntl();

  const { logout } = useAuth();
  const { user, client } = useUser({
    fetchPolicy: 'network-only'
  });

  const { toggleAuthModal, toggleUserDataModal, createCDNFileLink } = useUi();
  const [removeProfile, { loading: removeProfileLoading }] = useRemoveProfileMutation({
    refetchQueries: ['User'],
    update: clearCache(['getUser'])
  });
  const { mutate: updateUser } = useUpdateUser();

  const [registerCustomerMutationRun] = useRegisterCustomerMutation({
    refetchQueries: ['User'],
    update: clearCache(['getUser'])
  });

  const [agreement, setAgreement] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const isSeller = user && user.sellerVerification === 'Verified';

  const formik = useFormik<TUserDataModalValues>({
    initialValues: {
      name: initialValues?.name || '',
      secondName: initialValues?.secondName || '',
      nickname: initialValues?.nickname || ''
    },
    validationSchema: UserDataModalSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, helpers) => {
      if (isSignUp && !agreement) {
        return;
      }

      setError(null);

      try {
        const user = await updateUser({
          metadata: {
            name: !isSeller ? values.name : undefined,
            secondName: !isSeller ? values.secondName : undefined,
            nickname: values.nickname
          }
        });

        if (isSignUp) {
          await registerCustomerMutationRun();
        }

        googleTagSendDefaultEvent(GoogleTagEvents.new_reg);

        // todo нужно ли это?
        // if (user) {
        //   updateProfile(user as User);
        // }

        toggleUserDataModal(false);

        helpers.setErrors({});
        helpers.resetForm();

        toast.success('Information saved');
      } catch (e: any) {
        const errorText = getGraphqlErrorMessage(e);

        if (errorText) {
          setError(errorText);
        }

        console.error(e);
      }
    }
  });

  useUpdateEffect(() => {
    formik.setErrors({});
  }, [isOpen]);

  useUpdateEffect(() => {
    if (initialValues && !isOpen && !isSignUp) {
      formik.setValues(initialValues);
    }
  }, [initialValues]);

  useUpdateEffect(() => {
    if (!user?.registered) {
      formik.setValues({
        name: '',
        secondName: '',
        nickname: user?.nickname || ''
      });
    }
  }, [user]);

  const onClickSignIn = async () => {
    try {
      // updateProfile(null);
      const { data, errors } = await removeProfile();
      const removed = !!data?.response;

      if (errors) {
        handleDefaultError('Something went wrong', errors);
        return;
      }

      if (!removed) {
        handleDefaultError('Removal denied! You have active orders probably.');
        return;
      }
      await logout(true);
      toggleAuthModal(true);
    } catch (e: any) {
      handleDefaultError('Something went wrong', e);
    }
  };

  return (
    <Modal
      title={isSignUp ? intl.formatMessage({ id: 'auth.signUpTitle' }) : intl.formatMessage({ id: 'auth.editTitle' })}
      className={s.FillUserData}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Body>
        {isSignUp && (
          <p>
            <FormattedMessage
              id="auth.signUpText"
              values={{
                email,
                b: (chunks) => <b>{chunks}</b>
              }}
            />
          </p>
        )}

        {user?.sellerVerification !== SellerVerificationEnum.Verified && (
          <>
            <FormInput
              type="text"
              name={UserDataModalKeys.name}
              label={intl.formatMessage({ id: 'auth.nameLabel' })}
              placeholder={intl.formatMessage({ id: 'auth.namePlaceholder' })}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.errors.name}
              space={{ bottom: ESpaceSize.PIXEL_24 }}
              required
              maxLength={maxNameLength}
            />

            <FormInput
              type="text"
              name={UserDataModalKeys.secondName}
              label={intl.formatMessage({ id: 'auth.secondNameLabel' })}
              placeholder={intl.formatMessage({ id: 'auth.secondNamePlaceholder' })}
              value={formik.values.secondName}
              onChange={formik.handleChange}
              error={formik.errors.secondName}
              space={{ bottom: ESpaceSize.PIXEL_24 }}
              required
              maxLength={maxNameLength}
            />
          </>
        )}

        <FormInput
          type="text"
          name={UserDataModalKeys.nickname}
          label={intl.formatMessage({ id: 'auth.nicknameLabel' })}
          placeholder={intl.formatMessage({ id: 'auth.nicknamePlaceholder' })}
          value={formik.values.nickname}
          onChange={formik.handleChange}
          error={formik.errors.nickname}
          space={{ bottom: ESpaceSize.PIXEL_16 }}
          required
          maxLength={maxNameLength}
        />

        {!isSignUp && user?.sellerVerification === SellerVerificationEnum.Verified && (
          <>
            <FormattedMessage
              id={'auth.verificationWarning'}
              values={{
                a: (chunks) => (
                  <Link to={VERIFICATION_ROUTE} onClick={onClose}>
                    {chunks}
                  </Link>
                )
              }}
            />
          </>
        )}

        {isSignUp && (
          <>
            <Space size={ESpaceSize.PIXEL_16} />

            <Form.Check type="checkbox" id="authForm.userDataAgreement">
              <Form.Check.Input
                type="checkbox"
                name="agreement"
                checked={agreement}
                onChange={(e) => setAgreement(e.target.checked)}
              />

              <Form.Check.Label>
                <FormattedMessage
                  id="auth.agreement"
                  values={{
                    terms: (chunks) => (
                      <Anchor
                        href={createCDNFileLink(cdnFilenames.termsOfUse)}
                        target="_blank"
                        rel="noopener noreferrer"
                        inline
                      >
                        {chunks}
                      </Anchor>
                    ),
                    policy: (chunks) => (
                      <Anchor
                        href={createCDNFileLink(cdnFilenames.privacyPolicy)}
                        target="_blank"
                        rel="noopener noreferrer"
                        inline
                      >
                        {chunks}
                      </Anchor>
                    )
                  }}
                />
              </Form.Check.Label>
            </Form.Check>
          </>
        )}

        <div className="text-danger">{error}</div>
      </Modal.Body>

      <Modal.Footer className={s.UserDataModal__footer}>
        <Modal.Button onClick={formik.submitForm} loading={formik.isSubmitting} disabled={isSignUp && !agreement}>
          {isSignUp
            ? intl.formatMessage({ id: 'auth.signUpSubmitButton' })
            : intl.formatMessage({ id: 'auth.editSubmitButton' })}
        </Modal.Button>

        {isSignUp &&
          (removeProfileLoading ? (
            <Loader size={LoaderSize.SMALL} />
          ) : (
            <LinkBack className={s.UserDataModal__footer_linkBack} onClick={onClickSignIn}>
              <FormattedMessage id="general.back" />
            </LinkBack>
          ))}
      </Modal.Footer>
    </Modal>
  );
};
