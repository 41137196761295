import { useCallback, useMemo, useState } from 'react';
import {
  FileActionsEnum,
  GetAttachmentsResult,
  LocalAttachmentType,
  PaginationInput,
  SortAttachmentsInput,
  SortEnum,
  useAttachmentsQuery,
  useCreateUploadUrl_V2Mutation
} from '../../../store/graphql';
import {
  FileAction,
  FileUploadParams,
  ImageUploadParams,
  MFUploadMutationProps,
  useMFUpload,
  UseMFUploadProps,
  VideoUploadParams
} from '../../msfiles-client';
import { extname } from '../utils/extname';
import { DEFAULT_UPLOAD_MAX_SIZE } from '../constants';
import { UploadStage } from './types';

export interface UseUploadProps {
  multiple?: boolean;
  initialUids?: string[];
  pagination?: PaginationInput;
  sort?: SortAttachmentsInput;
  mutationOptions?: UseMFUploadProps;
  /**
   * Max file size in bytes. 5 MB by default.
   */
  maxSize?: number;
  /**
   * A list of the allowed upload actions (UploadFile, UploadImage, UploadVideo)
   */
  allowedActions?: FileAction[];
  /**
   * Upload params for each file type
   */
  params?: {
    [FileAction.UploadFile]?: FileUploadParams;
    [FileAction.UploadImage]?: ImageUploadParams;
    [FileAction.UploadVideo]?: VideoUploadParams;
  };
}

const actionExtensions = {
  [FileAction.UploadFile]: ['txt', 'pdf'],
  [FileAction.UploadImage]: ['png', 'jpg', 'jpeg', 'webp', 'heic'],
  [FileAction.UploadVideo]: ['mp4', 'hls', 'avi', 'mkv', 'webm']
};

const extensionActionMap: Record<string, FileAction> = {};
for (const key in actionExtensions) {
  const extensions = actionExtensions[key as FileAction];
  extensions.forEach((ext) => {
    extensionActionMap[ext] = key as FileAction;
  });
}

export type UseUploadMutationProps = Omit<MFUploadMutationProps, 'key' | 'action'>;

export type RemovableAttachmentType = Pick<LocalAttachmentType, 'msfiles_uid'>;

export interface UseUploadData {
  attachments: GetAttachmentsResult;
}

export type UseUploadReturn = [
  mutation: (props: UseUploadMutationProps) => Promise<LocalAttachmentType | null>,
  options: {
    data?: UseUploadData;
    reset: () => void;
    refetch: () => Promise<void>;
    remove: (attachment: RemovableAttachmentType) => void;
    loading: boolean;
    stage: UploadStage;
  }
];

const defaultPagination = {
  limit: 10,
  offset: 0
};

const defaultSort = {
  created_at: SortEnum.Asc
};

export const useUpload = (props: UseUploadProps = {}): UseUploadReturn => {
  const {
    multiple,
    initialUids = [],
    pagination = defaultPagination,
    sort = defaultSort,
    mutationOptions = {},
    maxSize = DEFAULT_UPLOAD_MAX_SIZE,
    params = {},
    allowedActions = [FileAction.UploadFile, FileAction.UploadImage, FileAction.UploadVideo]
  } = props;

  const [currentUid, setCurrentUid] = useState<string[]>(initialUids);

  const {
    data,
    previousData,
    loading: fetchLoading,
    error,
    refetch: getAttachments
  } = useAttachmentsQuery({
    fetchPolicy: 'network-only',
    skip: currentUid.length === 0,
    variables: {
      pagination,
      sort,
      filter: {
        msfilesUidList: currentUid
      }
    }
  });

  const [createUploadUrl, { loading: createUploadUrlLoading }] = useCreateUploadUrl_V2Mutation();
  const { mutate: uploadImage, isMutating } = useMFUpload(mutationOptions);

  const upload = useCallback(
    async (props: UseUploadMutationProps) => {
      if (props.file.size > maxSize) {
        throw new Error('Maximum file size exceeded');
      }

      const ext = extname(props.file.name);

      if (!ext || !(ext in extensionActionMap)) {
        throw new Error('Invalid file extension');
      }

      const action = extensionActionMap[ext];

      if (!allowedActions.includes(action)) {
        throw new Error('This type of file is not allowed');
      }

      const createUploadUrlData = await createUploadUrl({
        variables: {
          input: {
            action: action as unknown as FileActionsEnum
          }
        }
      });
      const key = createUploadUrlData.data?.createUploadUrlV2.code;

      if (!key) {
        throw new Error('There is no upload key');
      }

      const response = await uploadImage({
        key,
        action,
        ...params[action],
        ...props
      });

      if (!response) {
        return null;
      }

      const nextUid = multiple && currentUid ? [...currentUid, response.uid] : [response.uid];

      setCurrentUid(nextUid);

      const { data } = await getAttachments({
        pagination,
        sort,
        filter: {
          msfilesUidList: nextUid
        }
      });
      const attachment = data?.attachments.entries[0];

      if (!attachment) {
        return null;
      }

      return attachment as LocalAttachmentType;
    },
    [
      allowedActions,
      createUploadUrl,
      currentUid,
      getAttachments,
      maxSize,
      multiple,
      pagination,
      params,
      sort,
      uploadImage
    ]
  );

  const reset = useCallback(() => {
    setCurrentUid([]);
  }, []);

  const refetch = useCallback(async () => {
    await getAttachments();
  }, [getAttachments]);

  const remove = useCallback((attachment: RemovableAttachmentType) => {
    setCurrentUid((uid) => {
      return uid.filter((i) => i !== attachment.msfiles_uid);
    });
  }, []);

  const stage = useMemo(() => {
    if (fetchLoading) {
      return UploadStage.fetching;
    } else if (createUploadUrlLoading) {
      return UploadStage.preparing;
    } else if (isMutating) {
      return UploadStage.mutating;
    } else {
      return UploadStage.idle;
    }
  }, [createUploadUrlLoading, fetchLoading, isMutating]);

  return [
    upload,
    {
      data: (fetchLoading || error ? previousData : data) as UseUploadData,
      loading: fetchLoading || createUploadUrlLoading || isMutating,
      stage,
      reset,
      refetch,
      remove
    }
  ];
};
