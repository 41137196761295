import { AnyObject, CommonObject } from '../../common/utils/helperTypes';

export enum GoogleTagEvents {
  send_code = 'send_code',
  new_reg = 'new_reg',
  login = 'login',
  search = 'search',
  view_product = 'view_product',
  add_to_cart = 'add_to_cart',
  view_cart = 'view_cart',
  remove_from_cart = 'remove_from_cart',
  order = 'order',
  purchase = 'purchase',
  cancel_order = 'cancel_order'
}

export type GoogleTagDefaultEventFnType = (event: string, eventName: GoogleTagEvents, props?: CommonObject) => void;
export type GoogleTagCustomEventFnType = (props: { event: string } & AnyObject) => void;

export type GoogleTagSendDefaultEventFnType = (eventName: GoogleTagEvents, props?: any) => void;
export type GoogleTagSendCustomEventFnType = (eventName: GoogleTagEvents, props?: AnyObject) => void;
