import React, { useCallback, useLayoutEffect, useState } from 'react';
import clsx from 'clsx';
import { FormikErrors, useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal } from 'common/components/Modal/Modal';
import {
  AttachmentUploadStatus,
  LocalAttachmentType,
  SellerAttachment,
  useRegisterSellerMutation
} from 'store/graphql';
import { clearCache } from 'app/providers/auth-apollo';
import { getGraphqlErrorMessage } from 'common/utils/graphqlErrors';
import { handleDefaultError } from 'common/utils/handleDefaultError';
import { FormCheckbox, FormInput, InputLayout, InputUpload } from 'common/components/inputs';
import { Text, TextVariant } from 'common/components/Text';
import { Colors } from 'common/utils/colors';
import { Button, ButtonVariant } from 'common/components/Button';
import { differenceInYears } from 'date-fns';
import { AddressLayout, AddressLayoutProps } from '../../../../../widgets/address';
import { useUser } from '../../../../../entities/user';
import { useUpload } from '../../../../../lib/file-uploader';
import { FileAction, ImageUploadExt } from '../../../../../lib/msfiles-client';
import { maxNameLength } from '../../../../../widgets/auth';
import { Anchor } from '../../../../../common/components/Anchor';
import { VerificationFormAttachment } from '../VerificationFormAttachment';
import { DEFAULT_UPLOAD_MAX_SIZE_MB } from '../../../../../lib/file-uploader/constants';
import { PhoneVerification } from '../PhoneVerification';
import { LoaderBox } from '../../../../../common/components/Loader';
import { maxFilesAmount, VerificationSchema, VerificationSchemaKeys, VerificationValues } from './schema';
import s from './VerificationForm.module.scss';

export interface SellerVerificationModalProps extends Pick<AddressLayoutProps, 'onClickOpenForm'> {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
}

export enum VerificationGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export const DEFAULT_SMS_TIMEOUT_IN_SECONDS = 60;

export const VerificationForm: React.FC<SellerVerificationModalProps> = ({
  className,
  isOpen,
  onClose,
  onClickOpenForm
}) => {
  const intl = useIntl();
  const { user } = useUser();

  const [registerSeller, { loading: registerSellerLoading }] = useRegisterSellerMutation({
    refetchQueries: ['User'],
    update: clearCache(['getUser'])
  });

  const [verificationCode, setVerificationCode] = useState(user?.phoneVerified ? null : '');
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');
  const [isPhoneVerified, setPhoneVerified] = useState(user?.phoneVerified ?? false);

  const formik = useFormik<VerificationValues>({
    initialValues: {
      gender: VerificationGender.MALE,
      name: '',
      secondName: '',
      nameHiragana: '',
      secondNameHiragana: '',
      birthdate: '',
      contactNumber: '',
      addressId: '',
      attachments: [],
      smsCode: verificationCode
    },
    validationSchema: VerificationSchema,
    validate: (values) => {
      const errors: FormikErrors<VerificationValues> = {};

      if (differenceInYears(new Date(), new Date(values.birthdate)) < 18) {
        errors.birthdate = 'You must be at least 18 years old';
      }

      if (values.attachments.some((i) => i.error)) {
        errors.attachments = 'Please make sure your files are valid';
      }

      return errors;
    },
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, helpers) => {
      try {
        const address = user?.addresses.find((address) => address.id === values.addressId);

        if (!address) {
          handleDefaultError('Error occurred while submitting verification');
          return;
        }

        const inputAttachments = formik.values.attachments.map((i) => ({
          url: i.main_file?.url || ''
        })) as SellerAttachment[];

        await registerSeller({
          variables: {
            input: {
              name: values.name,
              secondName: values.secondName,
              nameHiragana: values.nameHiragana,
              secondNameHiragana: values.secondNameHiragana,
              gender: values.gender,
              dateOfBirth: values.birthdate,
              contactNumber: values.contactNumber,
              attachments: inputAttachments,
              addressId: values.addressId,
              sms_code: verificationCode
            }
          }
        });

        helpers.resetForm();
        onClose();
      } catch (e) {
        const graphqlErrorMessage = getGraphqlErrorMessage(e);
        switch (graphqlErrorMessage) {
          case 'NOT_JAPAN':
            handleDefaultError('Sorry, only Japanese residents eligible to pass verification');
            break;
          default:
            handleDefaultError(graphqlErrorMessage || 'Error occurred while submitting verification', e);
            break;
        }
      }
    }
  });

  const formSetFieldValue = formik.setFieldValue;
  const formSetFieldError = formik.setFieldError;

  const [uploadMutation, { data, remove, loading: uploadLoading }] = useUpload({
    multiple: true,
    allowedActions: [FileAction.UploadFile, FileAction.UploadImage]
  });

  const onUploadChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const files = Array.from(e.target.files);

        const promises = files.map(async (fileSource) => {
          try {
            formSetFieldError('attachments', undefined);
            await uploadMutation({
              file: fileSource,
              params: {
                synchronously: true,
                ext: ImageUploadExt.jpg
              }
            });
          } catch (e: any) {
            formSetFieldError('attachments', e.message);
            const graphqlErrorMessage = getGraphqlErrorMessage(e);
            handleDefaultError(graphqlErrorMessage || e.message || 'Error occurred while uploading image', e);
          }
        });

        Promise.all(promises).then(() => (e.target.value = ''));
      }
    },
    [formSetFieldError, uploadMutation]
  );

  useLayoutEffect(() => {
    const attachments = data?.attachments.entries || [];
    formSetFieldValue(VerificationSchemaKeys.attachments, attachments);
  }, [data, formSetFieldValue]);

  useLayoutEffect(() => {
    if (user?.addresses.length) {
      formSetFieldValue('addressId', user.addresses[0].id);
    }
  }, [formSetFieldValue, user?.addresses]);

  useLayoutEffect(() => {
    if (verificationCode) {
      formSetFieldValue(VerificationSchemaKeys.smsCode, verificationCode);
    }
  }, [formSetFieldValue, verificationCode]);

  useLayoutEffect(() => {
    if (phoneNumber) {
      formSetFieldValue(VerificationSchemaKeys.contactNumber, phoneNumber);
    }
  }, [formSetFieldValue, phoneNumber]);

  const pullCode = (code: string) => {
    setVerificationCode(code);
  };

  const pullPhone = (phone: string) => {
    setPhoneNumber(phone);
  };

  const pullNumberVerified = (isVerified: boolean) => {
    setPhoneVerified(isVerified);
  };

  if (!user) {
    return <LoaderBox />;
  }

  return (
    <Modal
      title={intl.formatMessage({ id: 'profile.verificationTitle' })}
      className={clsx(s.VerificationForm, className)}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Body>
        <PhoneVerification
          pullCode={pullCode}
          pullPhone={pullPhone}
          pullNumberVerified={pullNumberVerified}
          resendTimeout={60}
          initialData={{ phone: phoneNumber, numberVerified: isPhoneVerified }}
          isSeller={true}
        />
        <InputLayout
          className={s.VerificationForm__input}
          label={intl.formatMessage({ id: 'profile.verificationGender' })}
          error={formik.errors.gender}
          required
        >
          <div className={clsx(s.VerificationForm__group, s.VerificationForm__group_radio)}>
            <FormCheckbox
              type={'radio'}
              name={'Name'}
              label={intl.formatMessage({ id: 'profile.verificationGenderMale' })}
              checked={formik.values.gender === VerificationGender.MALE}
              value={VerificationGender.MALE}
              disabled={!isPhoneVerified}
              onChange={() => formik.setFieldValue(VerificationSchemaKeys.gender, VerificationGender.MALE)}
            />

            <FormCheckbox
              type={'radio'}
              name={'Name'}
              label={intl.formatMessage({ id: 'profile.verificationGenderFemale' })}
              checked={formik.values.gender === VerificationGender.FEMALE}
              value={VerificationGender.FEMALE}
              disabled={!isPhoneVerified}
              onChange={() => formik.setFieldValue(VerificationSchemaKeys.gender, VerificationGender.FEMALE)}
            />
          </div>
        </InputLayout>

        <InputLayout className={s.VerificationForm__input} label={'Name (Kanji or Latin)'} required>
          <div className={s.VerificationForm__group}>
            <FormInput
              name={VerificationSchemaKeys.name}
              placeholder={'Name'}
              value={formik.values.name}
              onChange={formik.handleChange}
              maxLength={maxNameLength}
              error={formik.errors.name}
              disabled={!isPhoneVerified}
              required
            />
            <FormInput
              name={VerificationSchemaKeys.secondName}
              placeholder={'Second name'}
              value={formik.values.secondName}
              onChange={formik.handleChange}
              maxLength={maxNameLength}
              error={formik.errors.secondName}
              disabled={!isPhoneVerified}
              required
            />
          </div>
        </InputLayout>

        <InputLayout className={s.VerificationForm__input} label={'Name (Katakana - Zenkaku)'} required>
          <div className={s.VerificationForm__group}>
            <FormInput
              name={VerificationSchemaKeys.nameHiragana}
              placeholder={'Name'}
              value={formik.values.nameHiragana}
              onChange={formik.handleChange}
              maxLength={maxNameLength}
              error={formik.errors.nameHiragana}
              disabled={!isPhoneVerified}
              required
            />
            <FormInput
              name={VerificationSchemaKeys.secondNameHiragana}
              placeholder={'Second name'}
              value={formik.values.secondNameHiragana}
              onChange={formik.handleChange}
              maxLength={maxNameLength}
              error={formik.errors.secondNameHiragana}
              disabled={!isPhoneVerified}
              required
            />
          </div>
        </InputLayout>

        <FormInput
          className={s.VerificationForm__input}
          type="date"
          name={VerificationSchemaKeys.birthdate}
          label={intl.formatMessage({ id: 'profile.verificationBirthday' })}
          placeholder={'Enter date of birth'}
          value={formik.values.birthdate}
          onChange={formik.handleChange}
          error={formik.errors.birthdate}
          disabled={!isPhoneVerified}
          required
        />

        <InputLayout
          className={s.VerificationForm__input}
          label={intl.formatMessage({ id: 'profile.verificationAddress' })}
          error={formik.errors.addressId}
          required
        >
          {user?.addresses && (
            <AddressLayout
              addresses={user.addresses}
              onClickOpenForm={onClickOpenForm}
              selectedAddressId={formik.values.addressId}
              setSelectedAddressId={(id) => formik.setFieldValue(VerificationSchemaKeys.addressId, id)}
              buttonDisabled={!isPhoneVerified}
              interactive
            />
          )}
        </InputLayout>

        <InputLayout
          classes={{
            label: s.VerificationForm__attachmentsLabel
          }}
          label={intl.formatMessage({ id: 'profile.verificationDocuments' })}
          description={
            <FormattedMessage
              id="profile.verificationDocumentsText"
              values={{
                a: (chunks) => (
                  <Anchor href="/" inline>
                    {chunks}
                  </Anchor>
                )
              }}
            />
          }
          error={formik.errors.attachments as string}
          required
        >
          <div
            className={clsx(s.VerificationForm__attachmentsList, {
              [s.VerificationForm__attachmentsList_filled]: formik.values.attachments.length !== 0
            })}
          >
            {(formik.values.attachments as LocalAttachmentType[]).map((attachment, index) => (
              <VerificationFormAttachment
                name={attachment.title ?? ''}
                url={attachment.thumbnails?.S?.url || attachment.main_file?.url || ''}
                error={attachment.upload_status === AttachmentUploadStatus.Error ? 'upload error' : undefined}
                onDelete={() => remove(attachment)}
                key={index}
              />
            ))}
          </div>

          <InputUpload onChange={onUploadChange} disabled={!isPhoneVerified}>
            <Button
              component={'div'}
              className={s.VerificationForm__attachmentsButton}
              variant={ButtonVariant.PRIMARY_OUTLINE}
              loading={uploadLoading}
              disabled={!isPhoneVerified}
            >
              <FormattedMessage id="profile.verificationAttachButton" />
            </Button>
          </InputUpload>

          <Text variant={TextVariant.BODY_S_NORMAL} color={Colors.GRAY_600}>
            <FormattedMessage
              id="profile.verificationDocumentsSubtext"
              values={{ amount: maxFilesAmount, size: DEFAULT_UPLOAD_MAX_SIZE_MB }}
            />
          </Text>
        </InputLayout>
      </Modal.Body>

      <Modal.Footer>
        <Modal.Button onClick={formik.submitForm} disabled={!isPhoneVerified} loading={registerSellerLoading}>
          <FormattedMessage id="profile.verificationSubmitButton" />
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};
