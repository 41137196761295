import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { BsChevronRight } from 'react-icons/bs';
import { CustomIcon } from '../../../../../../common/components/CustomIcon';
import s from './TransactionToggle.module.scss';

export interface TransactionToggleProps {
  className?: string;
  active?: boolean;
  onClick?: MouseEventHandler;
}

export const TransactionToggle = ({ className, active, onClick }: TransactionToggleProps) => {
  const handleClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    onClick?.(e);
  };

  return onClick ? (
    <button
      className={clsx(s.TransactionToggle, className, {
        [s.TransactionToggle_active]: active
      })}
      onClick={handleClick}
    >
      <CustomIcon className={clsx(s.TransactionToggle__icon)} icon={BsChevronRight} />
    </button>
  ) : (
    <div className={clsx(s.TransactionToggle, className)} />
  );
};
