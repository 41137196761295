import { AddressPersonalInformation } from '../types';
import { User } from '../../../entities/user';

export const getAddressInformationFromUser = (user: User): AddressPersonalInformation => {
  return {
    fullName: `${user.name} ${user.secondName}`,
    email: user.email || undefined,
    phone: user.seller ? user.seller.contactNumber : undefined
  };
};
