import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { BsDoorOpen, BsTrash } from 'react-icons/bs';
import clsx from 'clsx';
import { SellerVerificationEnum, useProfileLayoutQuery, useRemoveProfileMutation } from '../../../store/graphql';
import { Grid } from '../../../common/components/Grid';
import { LinkBack } from '../../../common/components/LinkBack';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import { LoaderBox } from '../../../common/components/Loader';
import {
  CUSTOMER_ORDERS_ROUTE,
  DRAFTS_ROUTE,
  FAVORITES_ROUTE,
  HOME_ROUTE,
  PERSONAL_INFORMATION_ROUTE,
  PROFILE_ROUTE,
  SHOWCASE_ROUTE,
  SUPPORT_ROUTE,
  TRUST_POINTS_ROUTE,
  WALLET_ROUTE,
  REVIEWS_ROUTE,
  PUBLIC_PROFILE_ROUTE,
  PICKUP_ROUTE,
  TICKETS_ROUTE, VERIFICATION_ROUTE
} from '../../../common/utils/routes';
import { useIsDesktop } from '../../../common/hooks/useIsDesktop';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { getGraphQLErrorInfo, STATUS_CODE_AUTH } from '../../../common/utils/graphqlErrors';
import { handleDefaultError } from '../../../common/utils/handleDefaultError';
import { Anchor, AnchorVariant } from '../../../common/components/Anchor';
import { Modal } from '../../../common/components/Modal';
import { useToggle } from '../../../common/hooks/useToggle';
import { useAuth } from '../../../app/providers/auth-apollo';
import { useWebview } from '../../../store/webview/useWebview';
import { useUser } from '../../../entities/user';
import { useTickets } from '../../../store/tickets/useTickets';
import { TicketMessagesCount } from '../../../widgets/tickets/components/TicketMessagesCount/TicketMessagesCount';
import { ProfileNavLink } from './ProfileNavLink';
import s from './ProfileLayout.module.scss';

export const ProfileLayout: React.FC = () => {
  const location = useLocation();

  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const { isWebview } = useWebview();

  const { data, loading } = useProfileLayoutQuery();
  const { loading: userLoading } = useUser();

  const isSeller = data?.user?.sellerVerification === SellerVerificationEnum.Verified;
  const user = data?.user;

  const { logout, loading: authLoading } = useAuth();
  const navigate = useNavigate();
  const intl = useIntl();
  const [removeProfile, { loading: removeProfileLoading }] = useRemoveProfileMutation();
  const [deleteModalOpen, { set: deleteModalSet, unset: deleteModalUnset }] = useToggle(false);
  const { unreadCount: ticketMessagesUnreadCount } = useTickets();

  const onClickLogout = async () => {
    try {
      navigate(HOME_ROUTE, { replace: true });
      await logout();
      // TODO correct cache reset on logout, potential cause, ApolloProvide has outdated state
      window.location.href = '/';
    } catch (e) {
      const graphqlError = getGraphQLErrorInfo(e);
      if (!graphqlError || graphqlError?.statusCode !== STATUS_CODE_AUTH) {
        console.error(e);
      }
    }
  };

  const onClickRemoveProfile = async () => {
    try {
      const { data, errors } = await removeProfile();
      const removed = !!data?.response;

      if (errors) {
        handleDefaultError('Something went wrong', errors);
        return;
      }

      if (!removed) {
        handleDefaultError('Removal denied! You have active orders probably.');
        return;
      }
      await logout(true);
      navigate(HOME_ROUTE, { replace: true });
    } catch (e) {
      handleDefaultError('Something went wrong', e);
    }
  };

  return loading || authLoading || userLoading ? (
    <LoaderBox />
  ) : (
    <Grid
      className={location.pathname === PROFILE_ROUTE && isMobile ? s.ProfileLayout__mobile : s.ProfileLayout}
      cols={12}
    >
      <Grid.GridItem
        cols={{ xs: 2, lg: 3, xl: 2 }}
        className={location.pathname === PROFILE_ROUTE || !isMobile ? s.ProfileLayout__content : ''}
      >
        {location.pathname === PROFILE_ROUTE || !isMobile ? (
          <>
            {isDesktop && (
              <ProfileNavLink to={PROFILE_ROUTE} end>
                <FormattedMessage id="profile.personalInformationTitle" />
              </ProfileNavLink>
            )}

            {isMobile && (
              <ProfileNavLink to={PERSONAL_INFORMATION_ROUTE}>
                <FormattedMessage id="profile.personalInformationTitle" />
              </ProfileNavLink>
            )}

            {isSeller && (
              <ProfileNavLink to={SHOWCASE_ROUTE}>
                <FormattedMessage id="profile.showcaseTitle" />
              </ProfileNavLink>
            )}

            {isSeller && (
              <ProfileNavLink to={DRAFTS_ROUTE}>
                <FormattedMessage id={'profile.draftsTitle'} />
              </ProfileNavLink>
            )}

            <ProfileNavLink to={CUSTOMER_ORDERS_ROUTE}>
              <FormattedMessage id="profile.ordersTitle" />
            </ProfileNavLink>

            {isSeller && (
              <ProfileNavLink to={PICKUP_ROUTE}>
                <FormattedMessage id={'pickupRequest.title'} />
              </ProfileNavLink>
            )}

            <ProfileNavLink to={FAVORITES_ROUTE}>
              <FormattedMessage id={'product.favorites'} />
            </ProfileNavLink>

            {isSeller && (
              <ProfileNavLink to={WALLET_ROUTE}>
                <FormattedMessage id="profile.walletTitle" />
              </ProfileNavLink>
            )}

            <ProfileNavLink to={REVIEWS_ROUTE}>
              <FormattedMessage id={'reviews.profileTitle'} />
            </ProfileNavLink>

            {user && (
              <ProfileNavLink to={PUBLIC_PROFILE_ROUTE}>
                <FormattedMessage id={'profile.publicPage'} />
              </ProfileNavLink>
            )}

            <ProfileNavLink to={TICKETS_ROUTE}>
              <div className={s.ProfileLayout__tickets}>
                <FormattedMessage id="profile.tickets" />
                <TicketMessagesCount count={ticketMessagesUnreadCount} />
              </div>
            </ProfileNavLink>

            <ProfileNavLink to={VERIFICATION_ROUTE}>
              <FormattedMessage id={'profile.verificationPageTitle'} />
            </ProfileNavLink>

            {isSeller && (
              <ProfileNavLink to={TRUST_POINTS_ROUTE}>
                <FormattedMessage id="profile.trustPoints" />
              </ProfileNavLink>
            )}

            {isWebview && (
              <ProfileNavLink to={SUPPORT_ROUTE}>
                <FormattedMessage id={'profile.guides'} />
              </ProfileNavLink>
            )}

            <div className={s.ProfileLayout__actionButtons}>
              <Anchor
                component={'button'}
                variant={AnchorVariant.BODY}
                className={s.ProfileLayout__button}
                onClick={onClickLogout}
              >
                <BsDoorOpen />
                <FormattedMessage id="profile.logOut" />
              </Anchor>

              <Anchor
                component={'button'}
                variant={AnchorVariant.DANGER}
                className={clsx(s.ProfileLayout__button, s.ProfileLayout__button_red)}
                onClick={deleteModalSet}
              >
                <BsTrash />
                <FormattedMessage id="profile.delete" />
              </Anchor>
            </div>
          </>
        ) : location.pathname.includes('ticket') ? (
          <></>
        ) : (
          <>
            <LinkBack>
              <FormattedMessage id="general.back" />
            </LinkBack>

            <Space size={ESpaceSize.PIXEL_24} />
          </>
        )}
      </Grid.GridItem>

      <Grid.GridItem cols={{ xs: 2, lg: 9 }} start={{ xs: 1, lg: 4 }}>
        <Outlet />
      </Grid.GridItem>
      <Modal title={intl.formatMessage({ id: 'profile.delete' })} isOpen={deleteModalOpen} onClose={deleteModalUnset}>
        <Modal.Body>
          <FormattedMessage id="profile.deleteText" />
        </Modal.Body>

        <Modal.Footer>
          <Modal.Button onClick={onClickRemoveProfile} loading={removeProfileLoading}>
            <FormattedMessage id="general.delete" />
          </Modal.Button>
        </Modal.Footer>
      </Modal>
    </Grid>
  );
};
