import clsx from 'clsx';
import { BsTrash } from 'react-icons/bs';
import { CustomIcon } from 'common/components/CustomIcon/CustomIcon';
import { customIcons } from 'common/components/CustomIcon/icons';
import { ReactFCC } from 'common/utils/helperTypes';
import s from './VerificationFormAttachment.module.scss';

export interface VerificationFormAttachmentProps {
  className?: string;
  name: string;
  type?: string;
  url?: string;
  error?: string;
  onDelete?: () => void;
}

export const VerificationFormAttachment: ReactFCC<VerificationFormAttachmentProps> = (props) => {
  const { className, name, url, error, onDelete } = props;

  return (
    <div className={clsx(s.VerificationFormAttachment, className)}>
      <div className={clsx(s.VerificationFormAttachment__card, error && s.VerificationFormAttachment__card_error)}>
        {checkIsImage(name) ? (
          <img src={url} alt="" className={s.VerificationFormAttachment__image} />
        ) : (
          <CustomIcon className={s.VerificationFormAttachment__image} icon={customIcons.ImagePlaceholder} />
        )}

        <div className={s.VerificationFormAttachment__name}>{name}</div>

        {onDelete && (
          <BsTrash
            className={clsx(s.VerificationFormAttachment__delete, error && s.VerificationFormAttachment__delete_error)}
            onClick={onDelete}
          />
        )}
      </div>

      {error && <div className={s.VerificationFormAttachment__error}>{error}</div>}
    </div>
  );
};

function checkIsImage(filename: string) {
  let regex = new RegExp(/[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/);

  return regex.test(filename);
}
