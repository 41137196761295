import { clearApolloQueryCache, QueryMatcher } from './clearApolloQueryCache';

const checkQuery = (queryKey: string) => (query: QueryMatcher) =>
  query instanceof RegExp
    ? queryKey.match(query)
    : typeof query === 'string'
    ? queryKey.indexOf(query) === 0
    : typeof query === 'function'
    ? query(queryKey)
    : false;

export const clearCache =
  (queries: QueryMatcher[], fragments: QueryMatcher[] = []) =>
  (store: any) => {
    clearApolloQueryCache(store, queries);

    Object.keys(store.data.data).forEach((key) => {
      const matches = key.match(/^\$ROOT_QUERY\.(.+)$/);
      if (matches) {
        return;
      }

      if (fragments.some(checkQuery(key))) {
        store.data.delete(key);
      }
    });
  };
