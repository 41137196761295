import React from 'react';
import clsx from 'clsx';
import { ReactFCC } from '../../../../../common/utils/helperTypes';

import s from './OrderPageBanner.module.scss';

export type TOrderPageBannerActions = {
  className?: string;
};

export const OrderPageBannerActions: ReactFCC<TOrderPageBannerActions> = (props) => {
  const { children, className } = props;

  return <div className={clsx(s.OrderPageBanner__actions, className)}>{children}</div>;
};
