import { useCallback, useState } from 'react';
import { useRemoveProductDraftAttachmentMutation, useRemoveProductDraftMutation } from 'store/graphql';

export type TManageDraftsImage = {
  attachmentUid?: string | null;
};

export type TManageDraftsOptions = {
  initialItems?: TManageDraftsImage[];
  draftId?: number;
};

export type TUploadFilesReturn = [
  files: TManageDraftsImage[],
  actions: {
    removeDraftImage: (attachmentUid: string) => void;
    removeProductDraft: (draftId: number) => Promise<void>;
    pushToDraftImages: (image: TManageDraftsImage) => void;
  },
  loading: boolean
];

export const maxFileSize = 10;

export const useUploadDraftPhotos = (options: TManageDraftsOptions = {}): TUploadFilesReturn => {
  const { initialItems = [] } = options;

  const [files, setFiles] = useState<TManageDraftsImage[]>(initialItems);

  const [removeProductDraftAttachment, { loading: removeAttachmentLoading }] =
    useRemoveProductDraftAttachmentMutation();

  const [removeDraft, { loading: removeDraftLoading }] = useRemoveProductDraftMutation();

  const loading = removeAttachmentLoading || removeDraftLoading || removeDraftLoading;

  const pushToDraftImages = useCallback(
    (file: TManageDraftsImage) => {
      setFiles((items) => [
        ...items,
        {
          ...file,
          loading: true
        }
      ]);
    },
    [setFiles]
  );

  const removeDraftImage = useCallback(
    async (attachmentUid: string) => {
      if (attachmentUid) {
        await removeProductDraftAttachment({
          variables: {
            input: {
              attachmentUid
            }
          }
        });
        setFiles((items) => items.filter((item) => item.attachmentUid !== attachmentUid));
        return true;
      }
      return;
    },
    [removeProductDraftAttachment]
  );

  const removeProductDraft = useCallback(
    async (draftId: number) => {
      try {
        await removeDraft({
          variables: {
            input: {
              id: draftId
            }
          }
        });
      } catch (e) {}
    },
    [removeDraft]
  );

  return [
    files,
    {
      removeDraftImage,
      removeProductDraft,
      pushToDraftImages
    },
    loading
  ];
};
