import { URL_KEY_SEARCH_QUERY } from '../../../store/urlKeys';
import {
  CATALOG_ROUTE,
  CATEGORY_PAGE_PARAM,
  CUSTOMER_ORDER_ROUTE,
  CUSTOMER_PUBLIC_ROUTE,
  EDIT_PRODUCT_DRAFT_PAGE_PARAM,
  EDIT_PRODUCT_DRAFT_ROUTE,
  EDIT_PRODUCT_PAGE_PARAM,
  EDIT_PRODUCT_ROUTE,
  ORDER_PAGE_PARAM,
  PICKUP_REQUEST_PAGE_PARAM,
  PICKUP_REQUEST_ROUTE,
  PRODUCT_PAGE_PARAM,
  PRODUCT_ROUTE,
  PUBLIC_PAGE_PARAM,
  RELIST_PRODUCT_PAGE_PARAM,
  RELIST_PRODUCT_ROUTE,
  SELLER_ORDER_ROUTE,
  SELLER_PUBLIC_ROUTE,
  SINGLE_TICKET_ROUTE,
  SUB_CATEGORY_PAGE_PARAM,
  SUB_SUB_CATEGORY_PAGE_PARAM,
  TICKET_PAGE_PARAM
} from './routes';

const defaultCatalogId = '_';

type CatalogPathProps = {
  categoryId?: string;
  subCategoryId?: string;
  subSubCategoryId?: string;
  searchQuery?: string;
  restParams?: string;
};

export class PathBuilder {
  static getProductPath = (productId: string) => PRODUCT_ROUTE.replace(`:${PRODUCT_PAGE_PARAM}`, String(productId));

  static getCatalogPath = ({
    categoryId = defaultCatalogId,
    subCategoryId = defaultCatalogId,
    subSubCategoryId = defaultCatalogId,
    searchQuery,
    restParams
  }: CatalogPathProps = {}) => {
    const path = CATALOG_ROUTE.replace(`:${CATEGORY_PAGE_PARAM}`, String(categoryId))
      .replace(`:${SUB_CATEGORY_PAGE_PARAM}`, String(subCategoryId))
      .replace(`:${SUB_SUB_CATEGORY_PAGE_PARAM}`, String(subSubCategoryId));

    const params = new URLSearchParams();

    if (searchQuery) {
      params.append(URL_KEY_SEARCH_QUERY, searchQuery);
    }

    if (restParams && searchQuery) {
      return `${path}?${params}&${restParams}`;
    }
    if (restParams && !searchQuery) {
      return `${path}?${restParams}`;
    }

    if (searchQuery && !restParams) {
      return `${path}?${params}`;
    }

    return `${path}`;
  };

  static getOrderPath = (orderId: string, isSeller = false) =>
    (isSeller ? SELLER_ORDER_ROUTE : CUSTOMER_ORDER_ROUTE).replace(`:${ORDER_PAGE_PARAM}`, String(orderId));

  static getCustomerPublicPath = (userId: string) =>
    CUSTOMER_PUBLIC_ROUTE.replace(`:${PUBLIC_PAGE_PARAM}`, String(userId));

  static getSellerPublicPath = (sellerId: string) =>
    SELLER_PUBLIC_ROUTE.replace(`:${PUBLIC_PAGE_PARAM}`, String(sellerId));

  static getPublicPath = (id: string, isSeller = false) =>
    (isSeller ? SELLER_PUBLIC_ROUTE : CUSTOMER_PUBLIC_ROUTE).replace(`:${PUBLIC_PAGE_PARAM}`, String(id));

  static getEditProductPath = (productId: string) =>
    EDIT_PRODUCT_ROUTE.replace(`:${EDIT_PRODUCT_PAGE_PARAM}`, String(productId));

  static getRelistProductPath = (relistId: string) =>
    RELIST_PRODUCT_ROUTE.replace(`:${RELIST_PRODUCT_PAGE_PARAM}`, String(relistId));

  static getEditProductDraftPath = (draftId: number) =>
    EDIT_PRODUCT_DRAFT_ROUTE.replace(`:${EDIT_PRODUCT_DRAFT_PAGE_PARAM}`, String(draftId));

  static getPickupRequestPath = (pickupUuid: string) =>
    PICKUP_REQUEST_ROUTE.replace(`:${PICKUP_REQUEST_PAGE_PARAM}`, String(pickupUuid));

  static getTicketRequestPath = (ticketId: number) =>
    SINGLE_TICKET_ROUTE.replace(`:${TICKET_PAGE_PARAM}`, String(ticketId));
}
