import React from 'react';
import {
  NavLinkProps as NavLinkPropsRR,
  useHref,
  useLinkClickHandler,
  useLocation,
  useResolvedPath
} from 'react-router-dom';
import clsx from 'clsx';
import { ReactFCC } from 'common/utils/helperTypes';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router';
import { Anchor, AnchorProps } from '../Anchor';

export type NavLinkProps = Omit<AnchorProps<'a'>, 'className'> & NavLinkPropsRR;

export const NavLink: ReactFCC<NavLinkProps> = (props) => {
  const {
    className: classNameProp,
    children,
    onClick,
    replace = false,
    state,
    target,
    to,
    relative,
    end,
    ...rest
  } = props;

  let { navigator } = React.useContext(NavigationContext);

  let href = useHref(to, { relative });
  let handleClick = useLinkClickHandler(to, {
    replace,
    state,
    target,
    relative
  });

  let path = useResolvedPath(to, { relative });
  let location = useLocation();

  let toPathname = navigator.encodeLocation ? navigator.encodeLocation(path).pathname : path.pathname;

  let locationPathname = location.pathname;

  let isActive =
    locationPathname === toPathname ||
    (!end && locationPathname.startsWith(toPathname) && locationPathname.charAt(toPathname.length) === '/');

  let className: string | undefined;
  if (typeof classNameProp === 'function') {
    className = classNameProp({ isActive, isPending: false });
  } else {
    className = classNameProp;
  }

  return (
    <Anchor
      {...rest}
      component={'a'}
      className={clsx(className)}
      href={href}
      onClick={(event) => {
        onClick?.(event);
        if (!event.defaultPrevented) {
          handleClick(event as React.MouseEvent<HTMLAnchorElement, MouseEvent>);
        }
      }}
    >
      {children}
    </Anchor>
  );
};
