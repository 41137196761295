import React from 'react';

export interface ITicketsContext {
  unreadCount: number;
  countLoading: boolean;
  recountMessages: () => void;
}

export const TicketsContext = React.createContext<ITicketsContext>({
  unreadCount: 0,
  countLoading: false,
  recountMessages: () => true
});
