import { ReactComponent as IconCardAdd } from './icons/i-card-add.svg';
import { ReactComponent as IconCardChange } from './icons/i-card-change.svg';
import { ReactComponent as IconCardRemove } from './icons/i-card-remove.svg';
import { ReactComponent as IconCardsStack } from './icons/i-cards-stack.svg';
import { ReactComponent as IconLocation } from './icons/i-location.svg';
import { ReactComponent as IconImagePlaceholder } from './icons/i-image-placeholder.svg';
import { ReactComponent as IconDelivery } from './icons/i-delivery.svg';
import { ReactComponent as IconDeliveryActive } from './icons/i-delivery-active.svg';
import { ReactComponent as IconDeliveryCompleted } from './icons/i-delivery-completed.svg';
import { ReactComponent as IconDeliveryError } from './icons/i-delivery-error.svg';

export const customIcons = {
  CardAdd: IconCardAdd,
  CardChange: IconCardChange,
  CardRemove: IconCardRemove,
  CardsStack: IconCardsStack,
  Location: IconLocation,
  ImagePlaceholder: IconImagePlaceholder,
  Delivery: IconDelivery,
  DeliveryActive: IconDeliveryActive,
  DeliveryCompleted: IconDeliveryCompleted,
  DeliveryError: IconDeliveryError
};
