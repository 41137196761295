import { BsPaperclip, BsSendFill } from 'react-icons/bs';
import clsx from 'clsx';
import React from 'react';
import { InputUpload } from '../../../../../common/components/inputs';
import { ChatInput } from '../TicketChat/components/ChatInput/ChatInput';
import s from '../TicketChatSkeleton/TicketChatSkeleton.module.scss';

export const TicketChatSkeletonSkeleton = () => {
  return (
    <div className={s.TicketChatSkeleton}>
      <div className={s.TicketChatSkeleton__header}>
        <div className={s.TicketChatSkeleton__header_left}></div>
      </div>
      <div className={s.TicketChatSkeleton__container}>
        <div className={s.TicketChatSkeleton__messagesGroup}>
          <div className={clsx(s.TicketChatSkeleton__message, s.TicketChatSkeleton__message_self)} />
          <div className={clsx(s.TicketChatSkeleton__message, s.TicketChatSkeleton__message_self)} />
          <div className={clsx(s.TicketChatSkeleton__message)} />
          <div className={clsx(s.TicketChatSkeleton__message)} />
          <div className={clsx(s.TicketChatSkeleton__message, s.TicketChatSkeleton__message_self)} />
          <div className={clsx(s.TicketChatSkeleton__message, s.TicketChatSkeleton__message_self)} />
          <div className={clsx(s.TicketChatSkeleton__message)} />
          <div className={clsx(s.TicketChatSkeleton__message, s.TicketChatSkeleton__message_self)} />
          <div className={clsx(s.TicketChatSkeleton__message)} />
          <div className={clsx(s.TicketChatSkeleton__message)} />
        </div>
      </div>
      <div className={s.TicketChatSkeleton__inputContainer}>
        <InputUpload className={s.TicketChatSkeleton__inputContainer_imageUpload} disabled={true}>
          <BsPaperclip size={20} />
        </InputUpload>
        <ChatInput type={'text'} disabled={true} />
        <div className={s.TicketChatSkeleton__sendButton}>
          <BsSendFill
            className={clsx(s.TicketChatSkeleton__sendButton_icon, s.TicketChatSkeleton__sendButton_icon_disabled)}
            size={20}
          />
        </div>
      </div>
    </div>
  );
};
