import React from 'react';

export type WebviewContextType = {
  isWebview: boolean;
  callWebviewMethod: (methodName: string, ...args: any[]) => Promise<any>;
};

export const WebviewContext = React.createContext<WebviewContextType>({
  isWebview: false,
  callWebviewMethod: () => new Promise((res) => res(null))
});
