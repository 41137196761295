import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { InfiniteScroll, InfiniteScrollProps } from 'common/components/InfiniteScroll';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { FormCheckbox, FormInput, FormLabel } from 'common/components/inputs';
import { useVirtualList } from 'common/components/VirtualList';
import { useLiveInput } from '@proscom/ui-react';
import { useIntl } from 'react-intl';
import { pull } from 'lodash-es';
import { BsX } from 'react-icons/bs';
import { allOption, INPUT_DELAY, OPTION_HEIGHT, OPTION_HEIGHT_MOBILE } from '../constants';
import { BaseCatalogFilterProps, CatalogFilterItem } from '../BaseCatalogFilter';
import { Loader, LoaderSize } from '../../../../../common/components/Loader';
import { EBreakpoints, useBreakpoint } from '../../../../../common/hooks/useBreakpoint';
import { Anchor, AnchorVariant } from '../../../../../common/components/Anchor';
import s from '../BaseCatalogFilter/BaseCatalogFilter.module.scss';

export enum CatalogFilterActiveMode {
  INCLUDE = 'include',
  EXCLUDE = 'exclude'
}

export type RichCatalogFilterProps = Pick<InfiniteScrollProps, 'fetchMore' | 'hasMore'> &
  BaseCatalogFilterProps & {
    /**
     * Значение поискового запроса
     */
    inputValue?: string;
    /**
     * Сеттер значения поискового запроса
     */
    setInputValue?: React.Dispatch<string>;
    /**
     * Массов ID выбранных опций
     */
    exclude: CatalogFilterItem['id'][];
    /**
     * Сеттер массова ID выбранных опций
     */
    setExclude: React.Dispatch<CatalogFilterItem['id'][]>;
    /**
     * Массов ID выбранных опций
     */
    allActive: boolean;
    /**
     * Сеттер массова ID выбранных опций
     */
    setAllActive: React.Dispatch<boolean>;
    /**
     * Количество всех опций. Используется для вывода подсказки при флажке All
     */
    totalCount: number;
  };

export const RichCatalogFilter: ReactFCC<RichCatalogFilterProps> = (props) => {
  const {
    className,
    classes,
    title,
    items,
    fetchMore,
    hasMore,
    inputValue: propInputValue,
    setInputValue: propSetInputValue,
    active,
    setActive,
    exclude,
    setExclude,
    allActive,
    setAllActive,
    totalCount,
    isLoading
  } = props;

  const intl = useIntl();

  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);

  const [inputValue, setInputValue] = useState(propInputValue || '');
  const [_, submit] = useLiveInput<string>((v) => propSetInputValue?.(v), INPUT_DELAY);

  const [activeMode, setActiveMode] = useState(CatalogFilterActiveMode.INCLUDE);

  const { listVirtualizer, getTopShift, scrollableNodeRef } = useVirtualList({
    count: items.length + 1, // добавляем опцию All
    hasMore,
    size: {
      itemHeight: isMobile ? OPTION_HEIGHT_MOBILE : OPTION_HEIGHT
    }
  });

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      setInputValue(val);
      submit(val, !!INPUT_DELAY);
    },
    [submit]
  );

  const onChange = useCallback(
    (item: CatalogFilterItem, value: boolean) => {
      if (value) {
        if (activeMode === CatalogFilterActiveMode.INCLUDE) {
          setActive([...active, item.id]);
        } else {
          setExclude(pull(exclude.slice(), item.id));
        }
      } else {
        if (allActive) {
          setAllActive(false);
          setActive([]);
          setExclude([item.id]);
          setActiveMode(CatalogFilterActiveMode.EXCLUDE);
        } else {
          if (activeMode === CatalogFilterActiveMode.INCLUDE) {
            setActive(pull(active.slice(), item.id));
          } else {
            setExclude([...exclude, item.id]);
          }
        }
      }
    },
    [active, activeMode, allActive, exclude, setActive, setAllActive, setExclude]
  );

  const getChecked = useCallback(
    (item: CatalogFilterItem) => {
      if (allActive) {
        return true;
      }

      if (activeMode === CatalogFilterActiveMode.INCLUDE) {
        return active.includes(item.id);
      } else {
        return !exclude.includes(item.id);
      }
    },
    [active, activeMode, allActive, exclude]
  );

  /** для второго варианта отображения */
  // const hasSelected = allActive || active.length !== 0 || exclude.length !== 0;
  const nothingWasFound = !!inputValue && items.length === 0 && !isLoading;

  const selectedCount = useMemo(() => {
    if (allActive) {
      return totalCount;
    }

    let count: number;

    if (activeMode === CatalogFilterActiveMode.INCLUDE) {
      count = active.length;
    } else {
      count = totalCount - exclude.length;
    }

    return Math.max(0, count);
  }, [active.length, activeMode, allActive, exclude.length, totalCount]);

  return (
    <div className={clsx(s.CatalogFilter, className)}>
      <FormLabel>{title}</FormLabel>

      {propSetInputValue && (
        <FormInput
          className={s.CatalogFilter__input}
          placeholder={intl.formatMessage({ id: 'general.search' })}
          value={inputValue}
          onChange={handleInputChange}
        />
      )}

      {selectedCount !== 0 && (
        <div className={s.CatalogFilter__helper}>
          Selected: {selectedCount}
          <Anchor
            component={'button'}
            leftIcon={BsX}
            variant={AnchorVariant.SECONDARY}
            onClick={() => {
              setActiveMode(CatalogFilterActiveMode.INCLUDE);
              setAllActive(false);
              setActive([]);
              setExclude([]);
            }}
          />
        </div>
      )}

      {/** альтернативный вариант вместо флага All */}
      {/*<div className={s.CatalogFilter__helper}>*/}
      {/*  <Anchor*/}
      {/*    component={'button'}*/}
      {/*    onClick={() => {*/}
      {/*      if (hasSelected) {*/}
      {/*        setActiveMode(CatalogFilterActiveMode.INCLUDE);*/}
      {/*        setAllActive(false);*/}
      {/*        setActive([]);*/}
      {/*        setExclude([]);*/}
      {/*      } else {*/}
      {/*        setActiveMode(CatalogFilterActiveMode.INCLUDE);*/}
      {/*        setAllActive(true);*/}
      {/*        setActive([]);*/}
      {/*        setExclude([]);*/}
      {/*      }*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {hasSelected ? 'Remove all' : 'Select all'}*/}
      {/*  </Anchor>*/}
      {/*</div>*/}

      <InfiniteScroll
        fetchMore={fetchMore}
        hasMore={hasMore}
        className={clsx(s.CatalogFilter__options, classes?.items)}
        innerRef={scrollableNodeRef}
      >
        <div style={{ height: listVirtualizer.getTotalSize() }}>
          {listVirtualizer.getVirtualItems().map((virtualItem) => {
            if (virtualItem.index === 0) {
              return (
                <FormCheckbox
                  className={clsx(s.CatalogFilter__virtualOption, classes?.item)}
                  classes={{
                    label: s.CatalogFilter__optionLabel
                  }}
                  style={{
                    top: getTopShift(virtualItem.index)
                  }}
                  label={allOption.name}
                  checked={allActive}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setAllActive(checked);
                    setActive([]);
                    setExclude([]);
                    setActiveMode(checked ? CatalogFilterActiveMode.EXCLUDE : CatalogFilterActiveMode.INCLUDE);
                  }}
                  key={allOption.id}
                />
              );
            }

            const item = items[virtualItem.index - 1]; // смещаем индексы на единицу влево из-за опции All

            return (
              <FormCheckbox
                className={clsx(s.CatalogFilter__virtualOption, classes?.item)}
                classes={{
                  label: s.CatalogFilter__optionLabel
                }}
                style={{
                  top: getTopShift(virtualItem.index)
                }}
                label={
                  <>
                    {item.colorCode && (
                      <div className={s.CatalogFilter__optionColor} style={{ backgroundColor: item.colorCode }} />
                    )}
                    <span className={s.CatalogFilter__optionLabelText}>{item.name}</span>
                  </>
                }
                checked={getChecked(item)}
                onChange={(e) => onChange(item, e.target.checked)}
                key={item.id}
              />
            );
          })}
        </div>
      </InfiniteScroll>

      {nothingWasFound && <div className={s.CatalogFilter__helper}>Nothing was found</div>}

      {isLoading && (
        <div className={s.CatalogFilter__helper}>
          Loading <Loader size={LoaderSize.SMALL} />
        </div>
      )}
    </div>
  );
};
