import { AvailableLanguagesEnum } from '../../graphql';

export const messages = {
  [AvailableLanguagesEnum.English]: {
    // General
    'general.pagination': 'Total {count, plural, one {# item} other {# items}}',
    'general.search': 'Search',
    'general.back': 'Back',
    'general.seller': 'Seller',
    'general.buyer': 'Buyer',
    'general.delete': 'Delete',
    'general.order': 'Order',
    'general.yes': 'Yes',
    'general.upload': 'Upload',
    'general.download': 'Download',
    'general.select': 'Select',
    'general.edit': 'Edit',
    'general.send': 'Send',

    //Customer service
    'customerService.title': 'Customer service',

    // Pages
    'pages.home': 'homepage',

    // Not found
    'notFound.title': "Sorry! We couldn't find that page",
    'notFound.text': 'Go to <a>homepage</a>',

    // Header
    'header.catalog': 'Catalog',
    'header.searchPlaceholder': 'Search',
    'header.searchInCategoryPlaceholder': 'Search in selected category',

    // Footer
    'footer.about':
      "Possibly the best shopping service in the world. We know you've been waiting for this, so don't be shy!",
    'footer.copyright': 'Copyright © {year} Sendico. All rights reserved',

    'footer.aboutUs': 'About us',
    'footer.linkAboutCompany': 'About company',
    'footer.linkTermsOfUse': 'Terms of use',
    'footer.linkPrivacyPolicy': 'Privacy policy',
    'footer.linkLaw': 'Commercial transactions law',
    'footer.linkCookies': 'Use of cookies',
    'footer.linkReviews': 'Testimonials',
    'footer.linkContacts': 'Contact us',
    'footer.linkFeedback': 'Feedback form',
    'footer.feedback': 'Feedback',
    'footer.feedbackPlaceholder': 'Share your message here',
    'footer.feedbackMessage': 'Message',
    'footer.feedbackSuccess': 'Your message was sent successfully',

    'footer.userManual': 'User manual',
    'footer.linkPaymentMethods': 'Payment methods',
    'footer.linkPaymentCurrency': 'Payment currency',
    'footer.linkPaymentProcessing': 'Payment processing time',
    'footer.linkCommissions': 'Commission',
    'footer.linkRefunds': 'Refunds',
    'footer.linkDelivery': 'Methods and terms of delivery',
    'footer.linkShipping': 'Shipping calculator',
    'footer.linkProhibited': 'Prohibited items',
    'footer.linkLimit': 'Size and weight limit',

    'footer.help': 'Let us help',
    'footer.linkFaq': 'FAQ',
    'footer.linkSupport': 'Support',
    'footer.linkAdditionalServices': 'Additional services',
    'footer.linkDeliveryProblems': 'Problems with delivery',
    'footer.linkProductProblems': 'Issues with purchased items',
    'footer.linkSellerIdentification': 'Seller identity authentication',
    'footer.linkWithdrawal': 'How to withdraw funds as a seller',

    'footer.mobileApp': 'Download our app',
    'footer.socialNetworks': 'Stay in the loop',

    // Catalog
    'catalog.price': 'Price',
    'catalog.priceFrom': 'Lower limit',
    'catalog.priceTo': 'Upper limit',
    'catalog.category': 'Category',
    'catalog.subCategory': 'Subcategory',
    'catalog.subSubCategory': 'Sub-subcategory',
    'catalog.brand': 'Brand',
    'catalog.color': 'Color',
    'catalog.size': 'Size',
    'catalog.condition': 'Condition',
    'catalog.deliverySpeedDays': 'Will be shipped by seller in',

    'catalog.conditionNew': 'New, or unused',
    'catalog.conditionBad': 'Bad',
    'catalog.conditionAlmostUnused': 'Like new',
    'catalog.conditionLightDamage': 'Good',
    'catalog.conditionSomeDamage': 'Fair',
    'catalog.conditionDamaged': 'Poor',

    'catalog.itemStatus': 'Item status',
    'catalog.statusSold': 'Sold',
    'catalog.statusForSale': 'Not Sold',

    'catalog.showMoreButton': 'Show more',
    'catalog.hideMoreButton': 'Hide',

    'catalog.filters': 'Filters',
    'catalog.filtersDoneButton': 'Done',
    'catalog.filtersShowResultButton': 'Show results',
    'catalog.filtersClearButton': 'Clear',

    'catalog.noResultsTitle': 'No results found',

    // Search
    'search.result': '{count, plural, one {# Item} other {# Items}} found for',
    'search.noResultsTitle': 'No search results for',
    'search.noResultsText': 'Please try again using a different description, or value on the <a>homepage</a>',
    'search.empty': 'Empty search query',
    'search.back': 'Back to search',
    'search.hideSoldItems': 'Do not show sold items',

    // Product
    'product.notFound': "This product doesn't exist",
    'product.notFoundText': 'Go to <a>homepage</a>',

    'product.shippingDate': 'Estimated shipping date:',
    'product.deliveryDate': 'Estimated delivery date:',
    'product.deliveryPrice': 'Delivery',
    'product.addToCart': 'Add to cart',
    'product.addedToCart': 'This item is already in your cart',
    'product.buyNow': 'Buy now',
    'product.sold': 'Sold',
    'product.isMyProduct': 'Your items',
    'product.description': 'Description',
    'product.characteristics': 'Characteristics',
    'product.weight': 'Weight',
    'product.packageDimensions': 'Package dimensions',

    'product.comments': 'Comments',
    'product.commentsEmpty': 'No comments yet',
    'product.allCommentsButton': 'All comments',
    'product.leaveComment': 'Leave a comment',
    'product.commentReply': 'Leave a reply',
    'product.addReplyButton': 'Add reply',
    'product.leaveCommentPlaceholder': 'Leave a comment',
    'product.commentReplyPlaceholder': 'Leave a reply',
    'product.viewReplies': 'View replies',
    'product.hideReplies': 'Hide replies',
    'product.replyButton': 'Reply',
    'product.leaveCommentLogInError': 'Please sign in to leave a comment',
    'product.addCommentButton': 'Add comment',

    'product.similarProduct': 'Similar products',
    'product.sellersOtherProducts': "Seller's other items",
    'product.productsTitle': 'Products',

    'product.deliveryOneOrTwo': '1-2 days',
    'product.deliveryThreeOrFour': '3-4 days',
    'product.deliveryFromFiveToSeven': '5-7 days',

    'product.statusSold': 'SOLD',

    'product.complaintModalTitle': 'Complaint about the product',
    'product.complaintModalReason': 'Reason for the complaint',
    'product.complaintModalDefaultOption': 'Choose the reason for the complaint',
    'product.complaintModalCommentLabel': 'Comment',
    'product.complaintModalCommentPlaceholder': 'Here you can describe the essence of the complaint in more detail.',
    'product.complaintModalSuccess': 'Complaint sending was successful!',
    'product.complaintModalSendButton': 'Leave a complaint',
    'product.complaintModalSignInError': 'Please sign in to leave a complaint!',
    'product.complaintContextMenuItem': 'Leave a complaint',

    'product.favorites': 'Favorites',
    'product.emptyFavorites': 'There are no favorites products',
    'product.favoriteRemoveAll': 'Remove all',

    // Cart
    'cart.title': 'Cart',
    'cart.totalCost': 'Total item`s cost',
    'cart.processOrder': 'Proceed to order page',

    // Auth
    'auth.title': 'Sign in/Create a new profile',
    'auth.emailText': 'Enter your e-mail to login/Create a new profile',
    'auth.emailLabel': 'E-mail',
    'auth.emailSubmitButton': 'Send authentication code',

    'auth.codeText':
      'A confirmation code has been sent to <b>{email}</b>, enter it in the field below. <a>Send the new code</a>',
    'auth.codeLabel': 'Code',
    'auth.codePlaceholder': 'Code',
    'auth.codeBackButton': 'Change e-mail',
    'auth.codeSubmitButton': 'Confirm',

    'auth.signUpTitle': 'Create a new profile',
    'auth.editTitle': 'Edit the profile',
    'auth.signUpText': 'User with mail <b>{email}</b> not found, create a new account',
    'auth.nameLabel': 'Name',
    'auth.namePlaceholder': 'Enter your first name',
    'auth.secondNameLabel': 'Second name',
    'auth.secondNamePlaceholder': 'Enter your second name',
    'auth.nicknameLabel': 'Nickname',
    'auth.nicknamePlaceholder': 'Enter a nickname',
    'auth.agreement':
      'I agree to the <terms>terms of use of Sendico</terms> and <policy>the data processing policy</policy>',
    'auth.signUpBackButton': 'Back',
    'auth.signUpSubmitButton': 'Create an account',
    'auth.signInButton': 'Sign in',
    'auth.editSubmitButton': 'Save changes',
    'auth.verificationWarning':
      'Unfortunately we cannot directly change your first name and last name. You should pass <a>verification</a> again in order to update it.',

    // Profile
    'profile.personalInformationTitle': 'Personal information',
    'profile.ordersTitle': 'My orders',
    'profile.showcaseTitle': 'My showcase',
    'profile.walletTitle': 'Wallet',

    // Profile main
    'profile.buyerRating': 'Buyer rating',
    'profile.sellerRating': 'Seller rating',
    'profile.uploadAvatar': 'Upload',
    'profile.edit': 'Edit',
    'profile.delete': 'Delete account',
    'profile.logOut': 'Log out',
    'profile.deleteText':
      'Are you sure you want to delete your account? Once deleted, all data relevant to your account will be erased without any possibility of recovery',

    'profile.verificationTitle': 'Identity verification',
    'profile.verificationPageTitle': 'Verification',
    'profile.verificationDefaultText':
      'To sell goods on the site, you must pass an identity verification. After submitting an application, its status will appear on your profile where you can track its progress. All applications are verified by our staff, and usually take between one, and seven days to process. <br></br> At this moment, we are getting applications only from residents of Japan.',
    'profile.verificationReviewText':
      'Your application for seller identity verification has been sent, please wait for approval. Your application may take several working days to review',
    'profile.verificationVerifyText':
      'Congratulations, your seller identity verification request was approved, and you may now sell goods on our site! If any of the personal data in your previously reviewed documents changes, you must <a>verify your identity again</a>. For more information on the terms and conditions of sale on this site, please follow the links below:',
    'profile.verificationRejectText':
      'We regret to inform you that your seller identity verification was denied. This could happen for several reasons including: the information you entered does not match the ID which you submitted; only residents of Japan with Japanese documents can become sellers; your documents have expired; we suspect that your documents may be fake. If you believe that this rejection was in error, please contact support, or re-apply for verification.',
    'profile.verificationStatusReview': 'Under review',
    'profile.verificationStatusReject': 'Application rejected',
    'profile.verificationStatusVerify': 'Application accepted',

    'profile.passVerificationButton': 'Pass verification',

    'profile.verificationGender': 'Gender',
    'profile.verificationGenderMale': 'Male',
    'profile.verificationGenderFemale': 'Female',
    'profile.verificationName': 'First name',
    'profile.verificationSecondName': 'Middle name',
    'profile.verificationThirdName': 'Surname',
    'profile.verificationBirthday': 'Date of birth',
    'profile.verificationNumber': 'Contact number',
    'profile.verificationAddress': 'Registration address',
    'profile.verificationDocuments': 'Copy of ID documents',
    'profile.verificationDocumentsText':
      "Please attach copies of the front and back of 2 different forms of ID, one of which needs to contain a photo. Acceptable documents include: my number card, driver's license, Japanese passport, medical insurance, pension book, residency card (for foreigners), foreign passport (for foreign residents of Japan). Additionally, one photo of you holding one of your previously submitted ID documents is required. Further information can be found on the <a>Seller Identification</a> page",
    'profile.verificationDocumentsSubtext': 'Maximum of {amount} files (.jpg, .png, .pdf), no more than {size} MB each',
    'profile.verificationAttachButton': 'Attach files',
    'profile.verificationSubmitButton': 'Submit for review',

    //Profile personal information
    'profile.personalInfoHiragana': 'Hiragana',
    'profile.personalInfoKanjiLatin': 'Kanji/Latin',

    // Profile orders
    'profile.orderTotalCost': 'Total cost',
    'profile.orderDate': 'Order date',
    'profile.orderCost': `Items cost`,
    'profile.orderEstimatedShipping': 'Shipping cost*',
    'profile.orderEstimatedShippingHint': '*This is just an estimate',
    'profile.viewOrder': 'View order',
    'profile.sellerOrders': 'My sales',
    'profile.customerOrders': 'My purchases',

    'profile.orderCombineAlertTitle': 'Sending an order',
    'profile.orderCombineAlertText':
      'Several of your products were purchased by a single customer. They have requested that you combine all purchases into one parcel',
    'profile.orderCombineAlertButton': 'Combine products',

    'profile.orderCombineWarehouseAlertTitle': 'Sending an order',
    'profile.orderCombineWarehouseAlertText': `Please send the order by the most suitable <a>shipping method</a> for you and specify the Name of delivery company, tracking number and number of boxes`,
    'profile.orderCombineWarehouseAlertButton': 'Combine products',
    'profile.orderCombineWarehouseShippingText': 'Shipping Information',
    'profile.orderCombineWarehouseParcelsText': 'Parcels',

    // Profile showcase
    'profile.showcaseAddItemButton': 'Add Item',
    'profile.showcaseProductList': 'Product list',
    'profile.showcaseSellersOrders': 'Seller orders',
    'profile.showcaseSellersOrdersAwaiting': 'Orders that are awaiting action',
    'profile.showcaseBack': 'Back to showcase',

    // Profile wallet
    'profile.walletBalance': 'Your balance',
    'profile.walletWithdrawButton': 'Withdraw funds',
    'profile.walletTransactions': 'Transactions',
    'profile.walletRequests': 'Requests',
    'profile.walletOpDate': 'Date',
    'profile.walletOpTime': 'Time',
    'profile.walletOpTransactionAmount': 'Transaction amount',
    'profile.walletOpStatus': 'Status',
    'profile.walletOpText': 'Comment',
    'profile.walletOpBalance': 'Current Balance',
    'profile.walletOpType': 'Operation type',
    'profile.walletTransactionBonusPayment': 'Bonus',
    'profile.walletTransactionRevenueFromOrder': 'Revenue from order',
    'profile.walletTransactionFine': 'Additional expenses',
    'profile.walletTransactionPayoutFromWr': 'Withdrawal',
    'profile.walletTransactionArisoraTax': 'Bank transfer fee',
    'profile.walletOrderCost': 'Cost of goods',
    'profile.walletOrderCommission': 'Commission',
    'profile.withdrawAmount': 'Withdrawal amount',
    'profile.withdrawStatusReview': 'Review',
    'profile.withdrawStatusSent': 'Sent',
    'profile.withdrawStatusDenied': 'Denied',
    'profile.walletTotalTransactionsCount': 'Total {count, plural, one {# transaction} other {# transactions}}',
    'profile.walletTotalRequestsCount': 'Total {count, plural, one {# request} other {# requests}}',

    // Profile trust points
    'profile.trustPoints': 'Trust points',
    'profile.trustPointsFilled': 'You have 100 Trust Points',
    'profile.trustPointsDecreased': 'You have below 100 Trust Points',
    'profile.trustedSellerReceived': `Congratulations! You have earned 100 <a>trust points</a>. Now a simplified system for sending orders is available to you. You can learn more about it on <a>Trust points guide page.</a>`,
    'profile.trustedSellerRemoved': `Unfortunately, the number of your trust points has decreased, and we are forced to withdraw the privilege of using simplified order sending system. We will return the simplified system when the number of your trust points increases to 100.<br></br>You can learn more about it on <a>Trust points guide page.</a>`,
    'profile.trustPointsTable.date': 'Date',
    'profile.trustPointsTable.time': 'Time',
    'profile.trustPointsTable.order': 'Order',
    'profile.trustPointsTable.comment': 'Comment',
    'profile.trustPointsTable.amount': 'Amount',
    'profile.trustPointsTable.total': 'Total',
    'profile.trustPointsCurrent': 'Current points amount:',
    'profile.trustPointsHistory': 'Points history',
    'profile.trustPointsGuide': 'Trust points guide',
    'profile.trustPointsPaginationText': 'Total {count} records',
    'profile.trustPointsEmpty': `You don't have points history yet. You will get trust points after successful purchase.`,
    'profile.trustPointsCardAmount': 'Trust points: {amount}',

    // Profile withdraw
    'profile.withdrawTitle': 'Withdraw funds',
    'profile.withdrawText':
      'First and Last name fields are automatically embedded. Please ensure that they match those registered with your bank: ',
    'profile.withdrawAccountName': 'Account holder name',
    'profile.withdrawBankName': 'Bank name',
    'profile.withdrawBranchName': 'Branch name',
    'profile.withdrawAccountType': 'Account type',
    'profile.withdrawAccountNumber': 'Account number',
    'profile.withdrawSendRequest': 'Send request',
    'profile.withdrawBackToWallet': 'Back to wallet',
    'profile.withdrawBackToWalletText': 'Return to your wallet without saving changes?',

    'profile.withdrawTypePersonalChecking': 'Current account',

    // Profile create/edit product
    'profile.createProductTitle': 'List a new item',
    'profile.editProductTitle': 'Edit an existing item',
    'profile.createProductBack': 'Return to product list',
    'profile.createProductBackText': 'Return to your product list without saving changes?',
    'profile.createProductPhotos': 'Add photos',
    'profile.createProductPhotosText':
      'The first photo will be displayed on the search results page. Select the best one to generate interest in your product! <br></br> A maximum of 10 files (.jpg, .png), no more than 10 Mb each, can be submitted.',
    'profile.createProductBasicParams': 'Basic parameters',
    'profile.createProductName': 'Product name',
    'profile.createProductDescription': 'Description',
    'profile.createProductBrand': 'Start typing brand',
    'profile.createProductPrice': 'Sold price',
    'profile.createProductCommission': 'Commission',
    'profile.createProductYouWillGot': 'You will receive',
    'profile.createProductDeliveryCost': 'Delivery cost',
    'profile.createProductDeliverySpeed': 'Approximate arrival date',
    'profile.createProductWeight': 'Weight',
    'profile.createProductHeight': 'Height',
    'profile.createProductWidth': 'Width',
    'profile.createProductLength': 'Length',
    'profile.createProductAdditionalParams': 'Additional parameters',
    'profile.createProductDelete': 'Delete product',
    'profile.createProductSubmitButton': 'Publish item',
    'profile.createProductSaveButton': 'Save changes',
    'profile.createProductDeleteText': 'Are you sure you wish to delete this item? Deleted items cannot be recovered',

    // Profile Drafts
    'profile.draftsTitle': 'Drafts',
    'profile.draftsUnpublished': 'You have unpublished drafts',
    'profile.draftsPublish': 'Do you want to publish any of your drafts?',
    'profile.draftsModalProceedCreation': 'Create new product',
    'profile.draftsModalOpenPage': 'Go to Drafts page',
    'profile.draftsEmpty': `You don't have any drafts yet`,
    'profile.draftsCreate': 'Create product from draft',
    'profile.draftsDelete': 'Are you sure you want to delete this draft?',
    'profile.draftsRemoveAll': 'Are you sure you want to delete all of your drafts?',
    'profile.draftsRemoveAllButton': 'Remove All',
    'profile.draftsSave': 'Save as draft',
    'profile.noDrafts': `You don't have any drafts yet`,
    'profile.editDraftBack': 'Return to draft list',

    //Profile Information and Guides
    'profile.guides': 'Information & Guides',

    //Public page
    'profile.publicPage': 'My public page',

    //Pickup Requests
    'profile.pickupRequests': 'My pick-up requests',
    'profile.readyToSend': 'Ready to send parcels',

    //Reviews
    'reviews.title': 'Reviews',
    'reviews.publicPage': 'My public page',
    'reviews.profileTitle': 'My reviews',
    'reviews.customer': 'From Customers',
    'reviews.seller': 'From Sellers',
    'reviews.toCustomer': 'To Customer',
    'reviews.toSeller': 'To Seller',
    'reviews.showMore': 'Show more',
    'reviews.showLess': 'Show less',
    'reviews.showMoreProducts': 'Show more products',
    'reviews.showMoreReviews': 'Show more reviews',
    'reviews.empty': 'No reviews were found',
    'reviews.positive': 'Positive',
    'reviews.negative': 'Negative',
    'reviews.neutral': 'Neutral',

    // Cart
    'cart.date.signIn': '<a>Sign in</a> to see the expected delivery date.',
    'cart.date.noAddress': 'Add the address in your <a>personal information</a> to see the expected delivery date.',
    'cart.date.invalid': "Delivery date can't be calculated to the specified address.",
    'cart.price.signIn': '<a>Sign in</a> to see the expected delivery cost.',
    'cart.price.noAddress': 'Add the address in your <a>personal information</a> to see the expected delivery cost.',
    'cart.price.calculated': 'Delivery is calculated to the address',
    'cart.price.invalid':
      "Delivery cost can't be calculated to the specified address. Please check if the specified address is correct. Also, you can choose or add another address in your <a>personal information</a>.",

    // Payment
    'payment.method': 'Payment method',
    'payment.addCard': 'Add card',
    'payment.deleteMethod': 'Delete',
    'payment.bankCard': 'Bank card',

    // Orders
    'order.statusPreparing': 'Your package is being prepared for shipment',
    'order.statusReadyToSend': 'Ready for shipment',
    'order.statusOnPickup': 'On pick-up',
    'order.statusCustomsClearance': 'Your package is being reviewed by customs',
    'order.statusDeliveringWithinJapan': 'Your package is in domestic transit',
    'order.statusInternationalDelivery': 'Your package is in international transit',
    'order.statusDelivered': 'Your package has been delivered',
    'order.statusProblem': 'There is a problem with your package',
    'order.statusCanceled': 'Your package shipment was canceled',
    'order.statusDestroyed': 'Your package was disposed of',

    'order.progressStatusPreparing': 'Preparing for shipment',
    'order.progressStatusDeliveringWithinJapan': 'Shipping within Japan',
    'order.progressStatusCustomsClearance': 'Customs clearance',
    'order.progressStatusInternationalDelivery': 'International shipping',
    'order.progressStatusDelivered': 'Delivered',

    'order.progressErrorPreparing': 'There is a problem with the order. Our customer service will contact you soon.',
    'order.progressErrorDeliveringWithinJapan':
      'There is a problem with the order. Our customer service will contact you soon.',
    'order.progressErrorCustomsClearance':
      'There is a problem with the order. Our customer service will contact you soon.',
    'order.progressErrorInternationalDelivery':
      'There is a problem with the order. Our customer service will contact you soon.',
    'order.progressErrorDelivered': 'There is a problem with the order. Our customer service will contact you soon.',

    'orders.alertPaymentDebt': 'Requires payment per order {orderId}',
    'orders.alertActionDebt': 'You have received an order: {orderId}. Please send the items requested',
    'order.alertPaymentDebtTitle': 'Additional payment is required',
    'order.alertPaymentDebtText':
      'The order requires an additional payment of: {amount}. Click to pay, or ask a question',
    'order.alertPaymentDebtAction': 'Payment',
    'order.historyTitle': 'Order history',
    'order.deliveryCalcErrorMessage': 'Shipping cost calculation error. Please try another address.',

    'order.confirmDelivery': 'Parcel received',
    'order.invoice': 'Invoice',
    'order.totalItems': 'Total {count} items',
    'order.productsLabel': 'Products in the order',
    'order.showAll': 'Show All',
    'order.hide': 'Hide',
    'order.summary': 'Payment summary',
    'order.details': 'Order details',
    'order.contactInformation': 'Contact Information',
    'order.shippingCost': 'Shipping cost',

    'order.contextMenu.customerService': 'Customer service',
    'order.customerServicePlaceholder': 'Describe your issue',
    'order.customerServiceSuccess': 'Your message was sent!',
    'order.customerServiceSignInError': 'Please sign in!',
    'order.total': 'Total:',
    'order.totalProducts': 'products',

    //Order combine
    'orderCombine.addBox': 'Add box',
    'orderCombine.weight': 'Weight',
    'orderCombine.enterWeight': 'Enter box weight',
    'orderCombine.height': 'Box Height',
    'orderCombine.enterHeight': 'Enter box height',
    'orderCombine.width': 'Box Width',
    'orderCombine.enterWidth': 'Enter box width',
    'orderCombine.length': 'Box Length',
    'orderCombine.enterLength': 'Enter box length',
    'orderCombine.priceRecalculated': 'Delivery price was recalculated',
    'orderCombine.save': 'Save',
    'orderCombine.print': 'Print',
    'orderCombine.createPickup': 'Create Pickup request',
    'orderCombine.createdPickup': 'You have created pick-up request for this order!',
    'orderCombine.openPickupPage': 'Open pick-up request page',
    'orderCombine.pickupGuide': `To send the products, create pick-up request. Choose suitable date and hours to request courier. On pick-up date please give the boxes to the
                  courier and show QR-code generated above.`,
    'orderCombine.pickupLimit':
      'You can do only 1 pickup request per day. Do you want only one order to be send or several?',
    'orderCombine.pickupSeveral': 'Several orders',
    'orderCombine.pickupOne': 'Only this order',
    'orderCombine.chooseAddress': 'Choose the address',
    'orderCombine.noSellerAddress': `You have no seller address. Verify your account again and specify the address in your <a>personal information</a>.`,
    'orderCombine.today': 'Today',
    'orderCombine.tomorrow': 'Tomorrow',
    'orderCombine.chooseDay': 'Choose pickup day',
    'orderCombine.day': 'Day',
    'orderCombine.readyTime': 'Ready time',
    'orderCombine.from': 'From',
    'orderCombine.latestTime': 'Latest time',
    'orderCombine.to': 'To',
    'orderCombine.createPickupButton': 'Create a pick-up request',
    'orderCombine.confirmSending': 'Confirm order sending',
    'orderCombine.sendingConfirmed': 'Order sending confirmed.',
    'orderCombine.sendingGuide': 'After the courier has taken the products, it is necessary to confirm the shipment.',
    'orderCombine.orderSent': 'The order is sent',
    'orderCombine.additionalPayment':
      'Additional payment required from the buyer, you will be able to proceed with next steps after successful payment (no longer than 3 days)',
    'orderCombine.instructions': `Your product has been purchased. Pack the products tightly according to our <a>instructions</a> and prepare its for international shipment. Then specify the number of boxes which you got`,
    'orderCombine.recalculate': 'Recalculate delivery estimate',
    'orderCombine.sendingOrder': 'Sending the order',
    'orderCombine.parcelSpecification': 'You need to specify every order parcel dimensions to verify price delivery',
    'orderCombine.boxPriority': 'Prioritize less amount of boxes for products',
    'orderCombine.qrGenerate': 'Generate shipping QR-code',
    'orderCombine.showToCourier': 'Show this QR to courier on pickup date.',
    'orderCombine.afterQR': 'After request you will get QR-code below',
    'orderCombine.qrGenerateButton': 'Generate QR-code',
    'orderCombine.shipmentReference': 'Shipment Reference Number',
    'orderCombine.box': 'Box',
    'orderCombine.thanks': 'Thank you for sending your order',
    'orderCombine.thanksSent': 'Your order was sent. Buyer will acquire it soon!',

    //Order combine PDF
    'orderCombine.pdf.order': 'Order',

    //Order combine QR
    'orderCombine.qr.shipmentCode': 'Shipment QR code',

    //Order combine calculation modal
    'orderCombine.modal.checking': 'We are checking international delivery price for the buyer with new box sizes',
    'orderCombine.modal.title': 'We are checking delivery price',
    'orderCombine.modal.wait': 'Please wait...',
    'orderCombine.modal.verified': 'Price was verified!',
    'orderCombine.modal.priceHigher': `New delivery price is higher than previous. Please check box sizes again. If you are sure that it's correct press correct button below`,
    'orderCombine.modal.sure': `I'm sure that's correct`,
    'orderCombine.modal.changeSizes': 'Change sizes',
    'orderCombine.modal.defaultError': 'Something went wrong. Try again later',

    //Order warehouse combine
    'orderCombineWarehouse.order': 'Order',
    'orderCombineWarehouse.from': 'From',
    'orderCombineWarehouse.to': 'To',
    'orderCombineWarehouse.name': 'Name:',
    'orderCombineWarehouse.address': 'Address:',
    'orderCombineWarehouse.zip': 'Zip:',
    'orderCombineWarehouse.phone': 'Phone:',
    'orderCombineWarehouse.arisora': 'Arisora Warehouse',
    'orderCombineWarehouse.thanks': 'Thank you for sending your order',
    'orderCombineWarehouse.thanksSent': 'Your order was sent. We will acquire it on our warehouse soon!',

    //Order warehouse inputs
    'orderCombineWarehouse.input.deliveryCompany': 'Delivery company',
    'orderCombineWarehouse.input.customDelivery': 'Custom delivery name',
    'orderCombineWarehouse.input.enterDeliveryCompany': 'Enter custom company',
    'orderCombineWarehouse.input.selectDeliveryCompany': 'Choose delivery company',
    'orderCombineWarehouse.input.track': 'Track number',
    'orderCombineWarehouse.input.enterTrack': 'Track number',
    'orderCombineWarehouse.input.boxes': 'Number of boxes',
    'orderCombineWarehouse.input.addParcel': 'Add parcel',
    'orderCombineWarehouse.input.sentPackages': 'You have successfully sent your packages',

    //Pickup requests
    'pickupRequest.title': 'Pick-up requests',
    'pickupRequest.myRequestsTitle': 'My pick-up requests',
    'pickupRequest.readyToSendTitle': 'Ready to send orders',
    'pickupRequestExtended.modal.allIsSent': 'Did you send all orders with this pick-up request?',
    'pickupRequestExtended.modal.yes': 'Yes',
    'pickupRequestExtended.modal.no': 'No, some orders were not sent',
    'pickupRequestExtended.modal.select': 'Please select orders that were successfully sent:',
    'pickupRequest.card.boxQuantities': 'Total quantities of boxes:',
    'pickupRequest.card.box': 'Box:',
    'pickupRequest.card.weight': 'Weight:',
    'pickupRequest.card.size': 'Size:',
    'pickupRequest.card.date': 'Date:',
    'pickupRequest.card.totalOrders': 'Total orders:',
    'pickupRequest.card.time': 'Time:',
    'pickupRequest.card.address': 'Address:',
    'pickupRequest.card.viewButton': 'View',
    'pickupRequestExtended.details': 'Request details',
    'pickupRequestExtended.parcels': 'Parcels in pick-up',
    'pickupRequestExtended.summary': 'Summary',
    'pickupRequestExtended.totalParcels': 'TOTAL QUANTITIES OF PARCELS:',
    'pickupRequestExtended.totalBoxes': 'TOTAL QUANTITIES OF BOXES:',
    'pickupRequestExtended.totalWeight': 'TOTAL WEIGHT:',
    'pickupRequestExtended.cancelPickup': 'Cancel pick-up request',
    'pickupRequest.noOrders': 'You have no orders to send yet',
    'pickupRequest.noPickups': 'You have no pick-up requests yet',
    'pickupRequest.readyOrders': `Here you can check all your parcels which are ready to be send. Ready to be send parcel is parcel in status "Ready for
shipment". To get such status you need to go to your sales list and start procedure of sending process to Step 2 "Generate
QR code". After successful generation of the QR code your parcel become "Ready for shipment". And you will see it in this page and can accumulate parcels for calling a courier.`,
    'pickupRequest.readyNextStep': 'Next step',
    // Notifications
    'notifications.pageTitle': 'Notifications',
    'notification.viewDetailsLink': 'View details',
    'notification.hasNotificationsAlertText':
      'You have {count} important notifications. Go to the notifications page for more details.',
    'notification.goToNotificationsButton': 'Go to notifications',
    'notification.readAll': 'Mark all as read',

    // Rating
    'rating.positiveLabel': 'Good',
    'rating.neutralLabel': 'Normal',
    'rating.negativeLabel': 'Bad',

    // Application
    'app.returnToApp': 'Return',
    'app.operationComplete': 'Operation complete',

    // Address
    'address.title': 'Address',

    'address.addButton': 'Add address',
    'address.addButtonShort': 'Add',
    'address.editButton': 'Edit address',
    'address.saveButton': 'Save address',
    'address.badge.seller': 'Seller',
    'address.badge.billing': 'Billing',
    'address.badge.shipping': 'Shipping',

    'address.setDefaultShipping': 'Set as default shipping address',
    'address.setDefaultBilling': 'Set as default billing address',

    // Address form
    'address.form.title': 'Title',
    'address.form.title.placeholder': 'Title',
    'address.form.fullName': 'Full name',
    'address.form.fullName.placeholder': 'Full name',
    'address.form.email': 'Email',
    'address.form.email.placeholder': 'Email',
    'address.form.phone': 'Phone',
    'address.form.phone.placeholder': 'Phone',
    'address.form.country': 'Country',
    'address.form.country.placeholder': 'Country',
    'address.form.zipCode': 'Zip code',
    'address.form.zipCode.placeholder': 'Zip code',
    'address.form.zipCodeError.placeholder': "This country doesn't support zip codes",
    'address.form.address1': 'Shipping address',
    'address.form.address1.placeholder': 'Shipping address',
    'address.form.address2': 'Additional shipping address',
    'address.form.address2.placeholder': 'Additional shipping address',
    'address.form.useAddressAs': 'Use address as:',
    'address.form.defaultBilling': 'Default billing address',
    'address.form.defaultShipping': 'Default shipping address',
    'address.form.address': 'Address',
    'address.form.address.placeholder': 'Address',
    'address.form.region': 'State / Province / Region',
    'address.form.region.placeholder': 'State / Province / Region',
    'address.form.province': 'Province',
    'address.form.province.placeholder': 'Province',
    'address.form.city': 'City',
    'address.form.city.placeholder': 'City',

    // Address select
    'address.select.titleShipping': 'Shipping address',
    'address.select.buttonGeneral': 'Change address',
    'address.select.modalTitle': 'Please select address',
    'address.select.titleBilling': 'Billing address',
    'address.select.buttonShipping': 'Change the shipping address',
    'address.select.buttonBilling': 'Change the billing address',
    'address.select.chooseShipping': 'Choose the shipping address',
    'address.select.chooseSBilling': 'Choose the billing address',
    'address.select.save': 'Save changes',

    // Address error
    'address.error.calculate': 'There may be an error in your address. Please check the data you entered.',

    // Withdraw / Bank account
    'withdraw.bankAccountTypeCurrentAccount': 'Current account',
    'withdraw.bankAccountTypeOrdinaryDeposit': 'Ordinary deposit',
    'withdraw.bankAccountTypeSavingsDeposit': 'Savings deposit',

    //Phone verification
    'phoneVerification.title': 'Phone verification',
    'phoneVerification.modalText': 'Please enter your phone number to receive a verification code',
    'phoneVerification.phonePlaceholder': 'eg. +15555551234',
    'phoneVerification.smsPlaceholder': 'Code from SMS',
    'phoneVerification.helpText': `If you didn't receive the code, please try again.`,
    'phoneVerification.invalidCode': 'Invalid code',
    'phoneVerification.verifyButton': 'Verify number',
    'phoneVerification.sendAgainButton': 'Send code again',
    'phoneVerification.phoneVerified': 'Number verified',
    'phoneVerification.modalSuccess': 'You have successfully verified your number!',
    'phoneVerification.codeNotSent': 'Code was not sent. Try again later!',
    'phoneVerification.tooShort': 'Your number is too short. Check it carefully',
    'phoneVerification.notValid': 'Your number is not valid. Check it carefully',
    'phoneVerification.notPossible': 'Your number is not possible number. Check it carefully',
    'phoneVerification.checkAgain': 'Check your number again',

    //Delivery companies
    'deliveryCompany.japanPost': 'Japan Post',
    'deliveryCompany.kuronekoyamato': 'Kuronekoyamato',
    'deliveryCompany.sagawa': 'Sagawa',
    'deliveryCompany.seino': 'Seino',
    'deliveryCompany.nipponExpress': 'Nippon Express',
    'deliveryCompany.fukuyama': 'Fukuyama',
    'deliveryCompany.other': 'Other'
  },
  [AvailableLanguagesEnum.Japanese]: {
    // General
    'general.pagination': '{count, plural, one {# 個数} other {# 個数}}',
    'general.search': '検索',
    'general.back': '戻る',
    'general.seller': '出品者',
    'general.buyer': '購入者',
    'general.delete': '削除',
    'general.order': '注文する',
    'general.yes': '合意する',
    'general.upload': 'アップロード',
    'general.download': 'ダウンロード',
    'general.select': '選んでください',
    'general.edit': '編集',
    'general.send': '送信',

    //Customer service
    'customerService.title': '顧客サービス',

    // Pages
    'pages.home': 'ホーム',

    // Not found
    'notFound.title': '恐れ入りますが、このページが見つかりません',
    'notFound.text': '<a>ホーム</a>に戻る',

    // Header
    'header.catalog': 'カタログ',
    'header.searchPlaceholder': '検索',
    'header.searchInCategoryPlaceholder': 'Search in selected category',

    // Footer
    'footer.about': 'Arisora　日本から世界へ',
    'footer.copyright': '© {year} 株式会社Arisora',

    'footer.aboutUs': 'Arisoraについて',
    'footer.linkAboutCompany': '会社情報',
    'footer.linkTermsOfUse': '利用規約',
    'footer.linkPrivacyPolicy': 'プライバシーポリシー',
    'footer.linkLaw': '特定商取引法に関する表記',
    'footer.linkCookies': 'クッキーについて',
    'footer.linkReviews': '購入者評価',
    'footer.linkContacts': 'お問い合わせ',
    'footer.linkFeedback': 'フィードバック',
    'footer.feedback': 'フィードバック',
    'footer.feedbackPlaceholder': 'ここでメッセージを共有してください',
    'footer.feedbackSuccess': 'メッセージは正常に送信されました',
    'footer.feedbackMessage': 'メッセージ',

    'footer.userManual': 'ガイド',
    'footer.linkPaymentMethods': '支払方法',
    'footer.linkPaymentCurrency': '決済通貨',
    'footer.linkPaymentProcessing': '決済処理時間',
    'footer.linkCommissions': '委託料金',
    'footer.linkRefunds': 'キャンセル・返金',
    'footer.linkDelivery': '放送法・放送条件',
    'footer.linkShipping': '送料計算',
    'footer.linkProhibited': '出品禁止物',
    'footer.linkLimit': '大きさと重さの制限',

    'footer.help': 'ヘルプセンター',
    'footer.linkFaq': 'よくある質問',
    'footer.linkSupport': 'サポート',
    'footer.linkAdditionalServices': 'その他のサービス',
    'footer.linkDeliveryProblems': '配送について',
    'footer.linkProductProblems': '出品について',
    'footer.linkSellerIdentification': '本人確認について',
    'footer.linkWithdrawal': '出品者の金引き出しについて',

    'footer.mobileApp': 'モバイルアプリ',
    'footer.socialNetworks': 'ソーシャル',

    // Catalog
    'catalog.price': '価格帯',
    'catalog.priceFrom': '下限',
    'catalog.priceTo': '上限',
    'catalog.category': 'カテゴリ',
    'catalog.subCategory': 'スビカテゴリ',
    'catalog.subSubCategory': 'スビスビカテゴリ',
    'catalog.brand': 'ブランド',
    'catalog.color': '色',
    'catalog.size': 'サイズ',
    'catalog.condition': '商品状態',
    'catalog.deliverySpeedDays': '配送予定日',

    'catalog.conditionNew': '未使用',
    'catalog.conditionBad': '全体的に状態が悪い',
    'catalog.conditionAlmostUnused': '未使用に近い',
    'catalog.conditionLightDamage': '目立った傷や汚れなし',
    'catalog.conditionSomeDamage': 'やや傷や汚れあり',
    'catalog.conditionDamaged': '傷や汚れあり',

    'catalog.itemStatus': '販売状況',
    'catalog.statusSold': '売り切れ',
    'catalog.statusForSale': '販売中',

    'catalog.showMoreButton': '更に',
    'catalog.hideMoreButton': '隠す',

    'catalog.filters': '絞り込み',
    'catalog.filtersDoneButton': '完了',
    'catalog.filtersShowResultButton': '結果を表示',
    'catalog.filtersClearButton': 'クリア',

    'catalog.noResultsTitle': 'その条件を一致する商品はありません',

    // Search
    'search.result': '{count, plural, one {# 件} other {# 件}}',
    'search.noResultsTitle': '一致する商品はありません',
    'search.noResultsText': '別の説明文、または<a>ホームページ</a>のバリューを使用して再試行してください', // далее идет pages.home
    'search.empty': '空',
    'search.hideSoldItems': '販売中のみ表示',

    // Product
    'product.notFound': '一致する商品はありません',
    'product.notFoundText': '<a>ホーム</a>へ',
    'product.back': 'カタログに戻る',

    'product.shippingDate': 'にお届け',
    'product.deliveryDate': '予定配送',
    'product.deliveryPrice': '送料',
    'product.addToCart': 'カートに追加',
    'product.addedToCart': '既にカートに追加されました',
    'product.buyNow': '今すぐ購入',
    'product.sold': '売却',
    'product.isMyProduct': 'マイ商品',
    'product.description': '詳細情報',
    'product.characteristics': '内容',
    'product.weight': '大量',
    'product.packageDimensions': '外寸',

    'product.comments': 'コメント',
    'product.commentsEmpty': 'コメントはまだありません',
    'product.allCommentsButton': 'すべてのコメント',
    'product.leaveComment': 'コメントする',
    'product.leaveCommentPlaceholder': 'コメントする',
    'product.commentReply': '返信を残す',
    'product.commentReplyPlaceholder': '返信を残す',
    'product.viewReplies': '返信を見る',
    'product.hideReplies': '返信を非表示にする',
    'product.replyButton': '返事',
    'product.leaveCommentLogInError': 'コメントするには、サインインしてください',
    'product.addCommentButton': 'コメントする',

    'product.similarProduct': 'お探しの商品からのおすすめ',
    'product.sellersOtherProducts': 'この出品者の他の商品を見る',
    'product.productsTitle': '製品',

    'product.deliveryOneOrTwo': '1〜2日で発送',
    'product.deliveryThreeOrFour': '3〜4日で発送',
    'product.deliveryFromFiveToSeven': '5〜7日で発送',

    'product.statusSold': '売約済み',

    'product.complaintModalTitle': '商品の報告',
    'product.complaintModalReason': '報告理由',
    'product.complaintModalDefaultOption': '報告理由を選択してください',
    'product.complaintModalCommentLabel': '報告理由の詳細',
    'product.complaintModalCommentPlaceholder': '報告理由の詳細を入力してください。',
    'product.complaintModalSuccess': '商品報告の送信は成功しました。',
    'product.complaintModalSendButton': ' 報告する',
    'product.complaintModalSignInError': '商品の報告するにはサインインしてください。',
    'product.complaintContextMenuItem': '報告する',

    'product.favorites': 'お気に入り',
    'product.emptyFavorites': '現在お気に入りに登録された商品はありません。',
    'product.favoriteRemoveAll': 'すべて削除',

    // Cart
    'cart.title': 'カート',
    'cart.totalCost': '全額',
    'cart.processOrder': '注文ページへ進む',

    // Auth
    'auth.title': 'サインインまたは新規プロファイルの作成',
    'auth.emailText': 'サインインするには、メールを入力してください。または、新規プロフィールを作成してください',
    'auth.emailLabel': 'メール',
    'auth.emailSubmitButton': '認証コードを送信',

    'auth.codeText': '<b>{email}</b>に送られた認証コードを以下に入力してください。 <a>認証コードを送信</a>',
    'auth.codeLabel': '認証コード',
    'auth.codePlaceholder': '認証コード',
    'auth.codeBackButton': '別のメールを使用',
    'auth.codeSubmitButton': '次へ',

    'auth.signUpTitle': 'プロフィールを新しく取得する',
    'auth.editTitle': 'プロフィール編集',
    'auth.signUpText':
      '<b>{email}</b>というメールでサインアップされたユーザーが見つかりません。メールを書き直し、または新しいプロフィールを作成してください',
    'auth.nameLabel': '名前',
    'auth.namePlaceholder': '名前入力',
    'auth.secondNameLabel': '姓',
    'auth.secondNamePlaceholder': '姓入力',
    'auth.nicknameLabel': 'ニックネーム',
    'auth.nicknamePlaceholder': 'ニックネーム入力',
    'auth.agreement': 'Sendicoの<terms>利用規約</terms>と<policy>プライバシーポリシー</policy>に同意します',
    'auth.signUpBackButton': 'サインイン',
    'auth.signUpSubmitButton': 'アカウント作成',
    'auth.signInButton': 'サインイン',
    'auth.editSubmitButton': '変更を保存',
    'auth.verificationWarning':
      '残念ながら、あなたの姓名を直接変更することはできません。 <a>更新するには、再度検証に合格する必要があります。</a>',

    // Profile
    'profile.personalInformationTitle': '個人情報',
    'profile.ordersTitle': 'マイオーダー',
    'profile.showcaseTitle': 'マイショーケース',
    'profile.walletTitle': 'ウォレット',

    // Profile main
    'profile.buyerRating': '購入者評価',
    'profile.sellerRating': '出品者評価',
    'profile.uploadAvatar': 'アップロード',
    'profile.edit': '編集',
    'profile.delete': 'アカウントを削除',
    'profile.logOut': 'ログアウト',
    'profile.deleteText':
      'このアカウントを削除しますか？削除したが最後、すべてのデータはもとに戻すことはできなくなります',

    'profile.verificationTitle': '出品者本人確認',
    'profile.verificationPageTitle': '検証',
    'profile.verificationDefaultText':
      'このサイトで商品を販売するには、まず本人確認が必要です。申請後、プロフィールページで申請状況を確認することができます。申請書の審査には数日かかる場合がありますのでしばらくお待ちください <br></br> 現在、お申込みは日本在住の方のみとさせていただいております。',
    'profile.verificationReviewText':
      '本人確認申請書を送信しました。申請書の審査には数日かかる場合がありますので、しばらくお待ちください',
    'profile.verificationVerifyText':
      'おめでとうございます！ 出品者確認リクエストが承認され、このサイトで商品を販売することができるようになりました。もし、本人確認書類に記載されている個人情報に変更があった場合は、<a>再度本人確認手続きを行ってください</a>。本サイトの販売条件の詳細については、以下のリンクをご参照ください',
    'profile.verificationRejectText':
      '申し訳ございませんが、貴方の出品者認証リクエストは拒否されました。これは、いくつかの理由が考えられます。例え、本人確認書類が一致しないか、日本在住でないか、本人確認書類の有効期限が切れているか、本人確認書類が偽造の疑われている場合もあります。恐れ入りますが再度申請してください',
    'profile.verificationStatusReview': '検討中',
    'profile.verificationStatusReject': '申請却下',
    'profile.verificationStatusVerify': '検討済み',

    'profile.passVerificationButton': '合格確認',

    'profile.verificationGender': '性別',
    'profile.verificationGenderMale': '男性',
    'profile.verificationGenderFemale': '女性',
    'profile.verificationName': '名前',
    'profile.verificationSecondName': '姓',
    'profile.verificationThirdName': 'ミドルネーム',
    'profile.verificationBirthday': '生年月日',
    'profile.verificationNumber': '電話番号',
    'profile.verificationAddress': '登録住所',
    'profile.verificationDocuments': '本人確認書類',
    'profile.verificationDocumentsText':
      '身分証明書（2種類）の両面のコピーを添付してください。本人確認のため、少なくとも1種類は顔写真が必要です。提出可能な書類：マイナンバーカード、運転免許証、日本国パスポート、医療保険、年金手帳、在留カード（外国人のみ）、日本国以外のパスポート（外国人のみ）。また、提出された本人確認書類のいずれかを持っている写真が必要です。詳しくは、<a>本⼈確認について</a>をご覧ください。',
    'profile.verificationDocumentsSubtext': '最大{amount}ファイル(.jpg, .png, .pdf)各{size}MB以下',
    'profile.verificationAttachButton': 'ファイルを添付',
    'profile.verificationSubmitButton': '検討に出す',

    //Profile personal information
    'profile.personalInfoHiragana': 'ひらがな',
    'profile.personalInfoKanjiLatin': '日本における漢字',

    // Profile orders
    'profile.orderTotalCost': '全格',
    'profile.orderDate': '注文日',
    'profile.orderCost': '購入品価格',
    'profile.orderEstimatedShipping': '送料⊛',
    'profile.orderEstimatedShippingHint': '⊛送料は概算です',
    'profile.viewOrder': '注文を見る',
    'profile.sellerOrders': '私の売上',
    'profile.customerOrders': '購入品',

    'profile.orderCombineAlertTitle': '商品配送',
    'profile.orderCombineAlertText': 'お客様が複数の商品を購入し、1つの荷物にまとめるよう依頼されました。',
    'profile.orderCombineAlertButton': '商品をまとめる',

    // Profile showcase
    'profile.showcaseAddItemButton': '商品をショーケースする',
    'profile.showcaseProductList': '商品リスト',
    'profile.showcaseSellersOrders': '出品者の荷物持ち',
    'profile.showcaseSellersOrdersAwaiting': '対応待ちの注文',

    // Profile wallet
    'profile.walletBalance': 'バランス',
    'profile.walletWithdrawButton': 'バランスを引き出す',
    'profile.walletTransactions': 'トランザクション',
    'profile.walletRequests': '要求',
    'profile.walletOpDate': '日にち',
    'profile.walletOpTime': '時間',
    'profile.walletOpTransactionAmount': '取引金額',
    'profile.walletOpStatus': 'スターテス',
    'profile.walletOpText': 'コメント',
    'profile.walletOpBalance': '経常収支',
    'profile.walletOpType': '操作の種類',
    'profile.walletTransactionBonusPayment': 'ボーナス',
    'profile.walletTransactionRevenueFromOrder': '売上',
    'profile.walletTransactionFine': '追加費用',
    'profile.walletTransactionPayoutFromWr': '振込申請',
    'profile.walletTransactionArisoraTax': '振込手数料',
    'profile.walletOrderCost': '商品価格',
    'profile.walletOrderCommission': '手数料',
    'profile.withdrawAmount': '検討中',
    'profile.withdrawStatusReview': '検討中',
    'profile.withdrawStatusSent': '送金済み',
    'profile.withdrawStatusDenied': '却下',
    'profile.walletTotalTransactionsCount': 'Total {count, plural, one {# transaction} other {# transactions}}', // todo
    'profile.walletTotalRequestsCount': 'Total {count, plural, one {# request} other {# requests}}', // todo

    // Profile trust points
    'profile.trustPoints': 'トラストポイント',
    'profile.trustPointsFilled': '100トラストポイントを獲得しました',
    'profile.trustPointsDecreased': 'トラストポイントは100点以下になりました',
    'profile.trustedSellerReceived': `おめでとうございます！ トラストポイントは１００点になりました。簡単商品発送システムを利用いただけるようになりました。<br></br><a>詳しくはこちら</a>`,
    'profile.trustedSellerRemoved': `トラストポイントは１００点以下になりました。簡単商品発送システムを利用中止になりました。<br></br><a>詳しくはこちら</a>`,
    'profile.trustPointsTable.date': '日にち',
    'profile.trustPointsTable.time': '時間',
    'profile.trustPointsTable.order': '注文する',
    'profile.trustPointsTable.comment': 'コメント',
    'profile.trustPointsTable.amount': '額',
    'profile.trustPointsTable.total': '合計点',
    'profile.trustPointsCurrent': '保有トラストポイント:',
    'profile.trustPointsHistory': 'トラストポイント履歴',
    'profile.trustPointsGuide': 'トラストポイントガイド',
    'profile.trustPointsPaginationText': 'レコード数 {count}',
    'profile.trustPointsEmpty': 'トラストポイントの履歴がありません。 取引は成功するとトラストポイントを獲得できます。',
    'profile.trustPointsCardAmount': '合計点 {amount}',

    // Profile withdraw
    'profile.withdrawTitle': 'バランスを引き出す',
    'profile.withdrawText': '姓と名の欄は自動的に埋め込まれています。以下のように一致することを確認してください。 ',
    'profile.withdrawAccountName': '口座名義人',
    'profile.withdrawBankName': '銀行名',
    'profile.withdrawBranchName': '支店名',
    'profile.withdrawAccountType': 'アカウントタイプ',
    'profile.withdrawAccountNumber': '口座番号',
    'profile.withdrawSendRequest': '要求送信',
    'profile.withdrawBackToWallet': 'ウォレットに戻る',
    'profile.withdrawBackToWalletText': '変更を保存せずにウォレットに戻ってもいいですか?',

    'profile.withdrawTypePersonalChecking': '普通預金',

    // Profile create/edit product
    'profile.createProductTitle': '商品を作成',
    'profile.createProductBack': '商品リストへ戻る',
    'profile.createProductBackText': '変更を保存せずに商品リストへ戻りますか？',
    'profile.createProductPhotos': '写真',
    'profile.createProductPhotosText':
      '検索結果には1枚目の写真が表示されますので、最適な写真を選択してください。<br></br>最大10ファイル（.jpg, .png）、各10Mb以下とします',
    'profile.createProductBasicParams': '基本パラメター',
    'profile.createProductName': '商品名',
    'profile.createProductDescription': '商品説明',
    'profile.createProductBrand': 'ブランドを入力してください',
    'profile.createProductPrice': '売却価格',
    'profile.createProductCommission': '手数料',
    'profile.createProductYouWillGot': '貰えるのは',
    'profile.createProductDeliveryCost': '送料',
    'profile.createProductDeliverySpeed': '配送予定日',
    'profile.createProductWeight': '大量',
    'profile.createProductHeight': '高さ',
    'profile.createProductWidth': '幅',
    'profile.createProductLength': '長さ',
    'profile.createProductAdditionalParams': 'その他のパラメター',
    'profile.createProductDelete': '商品を削除',
    'profile.createProductSubmitButton': '商品を発売',
    'profile.createProductSaveButton': '変更を保存',
    'profile.createProductDeleteText': 'この商品を削除しますか？削除したが最後、返す事は出来くなります',

    // Profile Drafts
    'profile.draftsTitle': '下書き一覧',
    'profile.draftsUnpublished': '下書きがあります。',
    'profile.draftsPublish': '下書きから始まりますか。',
    'profile.draftsModalProceedCreation': '出品する',
    'profile.draftsModalOpenPage': '下書き一覧',
    'profile.draftsEmpty': '下書きがありません',
    'profile.draftsCreate': '出品する',
    'profile.draftsDelete': 'この下書きを削除してもよろしいですか。',
    'profile.draftsRemoveAll': 'すべての下書きを削除してもよろしいですか。',
    'profile.draftsRemoveAllButton': 'すべて削除',
    'profile.draftsSave': '下書き保存する',
    'profile.noDrafts': `まだ下書きがありません`,
    'profile.editDraftBack': '作成済みの下書きページに戻る',

    // Profile Information and Guides
    'profile.guides': '情報と案内',

    //Public page
    'profile.publicPage': '私の公開ページ',

    //Reviews
    'reviews.title': 'レビュ',
    'reviews.publicPage': '私の公開ページ',
    'reviews.profileTitle': '私のレビュー',
    'reviews.customer': 'お客様からの',
    'reviews.seller': '売り手から',
    'reviews.toCustomer': 'お客様へ',
    'reviews.toSeller': '売り手に',
    'reviews.showMore': 'もっと見せる',
    'reviews.showLess': '表示を少なくする',
    'reviews.showMoreProducts': 'もっと製品を表示する',
    'reviews.showMoreReviews': 'さらにレビューを表示',
    'reviews.empty': 'レビューが見つかりませんでした',
    'reviews.positive': '良い',
    'reviews.negative': '悪い',
    'reviews.neutral': '中性',

    // Cart
    'cart.date.signIn': '<a>サインイン</a>するとお届け予定日が表示されます。',
    'cart.date.noAddress': '<a>個人情報</a>に住所を登録すると、お届け予定日が表示されます。',
    'cart.date.invalid': "Delivery date can't be calculated to the specified address.",
    'cart.price.signIn': '<a>サインイン</a>すると、配送料の予想が表示されます。',
    'cart.price.noAddress': '<a>個人情報</a>に住所を登録すると、配送料の予想が表示されます。',
    'cart.price.calculated': '配送料は、選択された住所に基づき計算されます。',
    'cart.price.invalid':
      "Delivery cost can't be calculated to the specified address. Please check if the specified address is correct. Also, you can choose or add another address in your <a>personal information</a>.",

    // Payment
    'payment.method': '支払方法',
    'payment.addCard': 'カードを追加',
    'payment.deleteMethod': '支払方法を消去',
    'payment.bankCard': 'バンクカード',

    // Orders
    'order.statusPreparing': '出品準備中',
    'order.statusReadyToSend': '送信する準備ができました',
    'order.statusOnPickup': '受け取り時に注文する',
    'order.statusCustomsClearance': '通関中',
    'order.statusDeliveringWithinJapan': '国内配達中',
    'order.statusInternationalDelivery': '国際配達中',
    'order.statusDelivered': '配達完了',
    'order.statusProblem': '申し訳ございません。何かの問題がございました',
    'order.statusCanceled': 'キャンセルされました',
    'order.statusDestroyed': '処分されました',

    'order.progressStatusPreparing': '発送準備中',
    'order.progressStatusDeliveringWithinJapan': '国内配達中',
    'order.progressStatusCustomsClearance': '通関',
    'order.progressStatusInternationalDelivery': '国際配達中',
    'order.progressStatusDelivered': '配達完了',

    'order.progressErrorPreparing':
      '注文は、問題が発生しました。 こちらからご連絡いたします。 もうしばらくお待ちくださいませ。',
    'order.progressErrorDeliveringWithinJapan':
      '注文は、問題が発生しました。 こちらからご連絡いたします。 もうしばらくお待ちくださいませ。',
    'order.progressErrorCustomsClearance':
      '注文は、問題が発生しました。 こちらからご連絡いたします。 もうしばらくお待ちくださいませ。',
    'order.progressErrorInternationalDelivery':
      '注文は、問題が発生しました。 こちらからご連絡いたします。 もうしばらくお待ちくださいませ。',
    'order.progressErrorDelivered':
      '注文は、問題が発生しました。 こちらからご連絡いたします。 もうしばらくお待ちくださいませ。',

    'orders.alertPaymentDebt': '注文ごとに支払いが必要 {orderId}',
    'orders.alertActionDebt': '注文は {orderId} です. 商品を送ってください',
    'order.alertPaymentDebtTitle': '料金が必要となります',
    'order.alertPaymentDebtText':
      '注文には追加の支払いが必要です {amount}. 支払いをするために、または質問ございましたらクリックしてください',
    'order.alertPaymentDebtAction': '支払いがまだ必要です',
    'order.historyTitle': '注文履歴',
    'order.deliveryCalcErrorMessage': '住所入力エラーが発生しました。他の住所を入力してください',

    'order.confirmDelivery': '受け取り連絡',
    'order.invoice': '請求書',
    'order.totalItems': '総項目数 {count}',
    'order.productsLabel': 'ご注文中の商品',
    'order.showAll': 'すべて表示する',
    'order.hide': '隠れる',
    'order.summary': 'お支払い概要',
    'order.details': '注文詳細',
    'order.contactInformation': '連絡先',
    'order.shippingCost': '輸送費',

    'order.contextMenu.customerService': '顧客サービス',
    'order.customerServiceSuccess': 'メッセージは正常に送信されました',
    'order.customerServicePlaceholder': 'あなたの問題を説明してください',
    'order.customerServiceSignInError': 'サインインしてください！',
    'order.total': 'トータル',
    'order.totalProducts': 'プロダクツ',

    //Order combine
    'orderCombine.addBox': 'ボックスを追加',
    'orderCombine.weight': '重量',
    'orderCombine.enterWeight': '箱の重量を入力してください',
    'orderCombine.height': 'ボックスの高さ',
    'orderCombine.enterHeight': 'ボックスの高さを入力してください',
    'orderCombine.width': 'ボックスの幅',
    'orderCombine.enterWidth': 'ボックスの幅を入力してください',
    'orderCombine.length': 'ボックスの長さ',
    'orderCombine.enterLength': 'ボックスの長さを入力してください',
    'orderCombine.priceRecalculated': '配送価格が再計算されました',
    'orderCombine.save': 'セーブ',
    'orderCombine.print': '印画',
    'orderCombine.createPickup': '集荷リクエストの作成',
    'orderCombine.createdPickup': 'この注文の集荷リクエストを作成しました。',
    'orderCombine.openPickupPage': '集荷依頼ページを開く',
    'orderCombine.pickupGuide': `商品を発送するには、集荷依頼を作成します。宅配便を依頼するには適切な日付と時間を選択してください。受け取り日に箱を次の担当者に渡してください。宅配便を選択し、上記で生成された QR コードを表示します。`,
    'orderCombine.pickupLimit':
      '1 日に実行できる集荷リクエストは 1 件のみです。注文を 1 つだけ送信しますか? それとも複数の注文を送信しますか?',
    'orderCombine.pickupSeveral': '複数の注文',
    'orderCombine.pickupOne': 'この注文のみ',
    'orderCombine.chooseAddress': '住所を選択してください',
    'orderCombine.noSellerAddress': `販売者の住所がありません。アカウントを再度確認し、<a>個人情報</a>に住所を指定してください。`,
    'orderCombine.today': '今日',
    'orderCombine.tomorrow': '明日',
    'orderCombine.chooseDay': '受け取り日を選択してください',
    'orderCombine.day': '日',
    'orderCombine.readyTime': '準備時間',
    'orderCombine.from': 'から',
    'orderCombine.latestTime': '最新の時刻',
    'orderCombine.to': 'に',
    'orderCombine.createPickupButton': '集荷リクエストを作成する',
    'orderCombine.confirmSending': '注文送信の確認',
    'orderCombine.sendingConfirmed': '注文の発送が確認されました。',
    'orderCombine.sendingGuide': '宅配業者が商品を受け取った後、出荷を確認する必要があります。',
    'orderCombine.orderSent': '注文が送信されます',
    'orderCombine.additionalPayment':
      '購入者からの追加の支払いが必要です。支払いが成功した後、次のステップに進むことができます（3日以内）。',
    'orderCombine.instructions': `あなたの製品は購入されました。弊社の<a>指示</a>に従って製品をしっかりと梱包し、国際発送の準備をしてください。次に、取得したボックスの数を指定します`,
    'orderCombine.recalculate': '配送予定を再計算する',
    'orderCombine.sendingOrder': '注文の送信',
    'orderCombine.parcelSpecification': '価格配達を確認するには、すべての注文小包の寸法を指定する必要があります',
    'orderCombine.boxPriority': '製品の箱数が少ないことを優先する',
    'orderCombine.qrGenerate': '配送用 QR コードを生成する',
    'orderCombine.showToCourier': '集荷日にこの QR を宅配業者に提示してください。',
    'orderCombine.afterQR': 'リクエスト後、以下のQRコードを取得します',
    'orderCombine.qrGenerateButton': 'QRコードの生成',
    'orderCombine.shipmentReference': '出荷参照番号',
    'orderCombine.box': 'ボックス',
    'orderCombine.thanks': 'ご注文をお送りいただきありがとうございます',
    'orderCombine.thanksSent': 'ご注文の商品が発送されました。買い手はすぐにそれを取得します!',

    //Order combine PDF
    'orderCombine.pdf.order': '注文',

    //Order combine QR
    'orderCombine.qr.shipmentCode': '発送用QRコード',

    //Order combine calculation modal
    'orderCombine.modal.checking': '新しい箱サイズでの購入者の国際配送価格を確認しています。',
    'orderCombine.modal.title': '配送価格を確認中です',
    'orderCombine.modal.wait': 'お待ちください...',
    'orderCombine.modal.verified': '価格を確認しました！',
    'orderCombine.modal.priceHigher': `新規の配送価格は以前よりも高くなります。ボックスのサイズをもう一度ご確認ください。正しいと確信できる場合は、下の正しいボタンを押してください`,
    'orderCombine.modal.sure': `きっとそれが正しい`,
    'orderCombine.modal.changeSizes': 'サイズを変更する',
    'orderCombine.modal.defaultError': '何か問題が発生しました。あとでもう一度試してみてください',

    //Order warehouse combine
    'orderCombineWarehouse.order': '注文',
    'orderCombineWarehouse.from': 'から',
    'orderCombineWarehouse.to': 'に',
    'orderCombineWarehouse.name': '名',
    'orderCombineWarehouse.address': 'アドレス',
    'orderCombineWarehouse.zip': 'ジッパー',
    'orderCombineWarehouse.phone': '電話',
    'orderCombineWarehouse.arisora': '有空倉庫',
    'orderCombineWarehouse.thanks': 'ご注文をお送りいただきありがとうございます',
    'orderCombineWarehouse.thanksSent': 'ご注文は送信されました。すぐに当社の倉庫に入荷します！',

    //Order warehouse inputs
    'orderCombineWarehouse.input.deliveryCompany': '運送会社',
    'orderCombineWarehouse.input.customDelivery': 'カスタム配信名',
    'orderCombineWarehouse.input.enterDeliveryCompany': 'カスタム会社を入力',
    'orderCombineWarehouse.input.selectDeliveryCompany': '配送業者を選ぶ',
    'orderCombineWarehouse.input.track': '番線',
    'orderCombineWarehouse.input.enterTrack': 'トラック番号を入力してください',
    'orderCombineWarehouse.input.boxes': '箱数',
    'orderCombineWarehouse.input.addParcel': '小包を追加する',
    'orderCombineWarehouse.input.sentPackages': '荷物は正常に送信されました',

    //Pickup requests
    'pickupRequest.title': '送迎リクエスト',
    'pickupRequest.myRequestsTitle': '集荷依頼',
    'pickupRequest.readyToSendTitle': '注文を送信する準備ができました',
    'pickupRequestExtended.modal.allIsSent': 'この集荷リクエストを使用してすべての注文を送信しましたか?',
    'pickupRequestExtended.modal.yes': 'はい',
    'pickupRequestExtended.modal.no': 'いいえ、一部の注文は送信されませんでした',
    'pickupRequestExtended.modal.select': '正常に送信された注文を選択してください:',
    'pickupRequest.card.boxQuantities': 'ボックスの合計数量:',
    'pickupRequest.card.box': 'ボックス',
    'pickupRequest.card.weight': '重量',
    'pickupRequest.card.size': 'サイズ',
    'pickupRequest.card.date': '日付',
    'pickupRequest.card.totalOrders': '合計注文数:',
    'pickupRequest.card.time': '時間',
    'pickupRequest.card.address': 'アドレス',
    'pickupRequest.card.viewButton': '意見',
    'pickupRequestExtended.details': 'リクエストの詳細',
    'pickupRequestExtended.parcels': '集荷中の荷物',
    'pickupRequestExtended.summary': '概要',
    'pickupRequestExtended.totalParcels': '小包の合計数量:',
    'pickupRequestExtended.totalBoxes': 'ボックスの合計数量:',
    'pickupRequestExtended.totalWeight': '総重量：',
    'pickupRequestExtended.cancelPickup': '集荷依頼をキャンセルする',
    'pickupRequest.noOrders': 'まだ送信する注文がありません',
    'pickupRequest.noPickups': '集荷依頼はまだありません',
    'pickupRequest.readyOrders': `ここでは、発送準備が整っているすべての荷物を確認できます。発送準備完了状態の小包は、「発送準備完了」ステータスの小包です。
このようなステータスを取得するには、販売リストに移動し、ステップ 2「生成」までの送信プロセスの手順を開始する必要があります。
QR コード」。QR コードが正常に生成されると、荷物は「発送準備完了」になります。このページにそれが表示され、宅配業者に電話するために荷物を蓄積することができます。`,
    'pickupRequest.readyNextStep': '次のステップ',

    // Notifications
    'notifications.pageTitle': '通知',
    'notification.viewDetailsLink': '詳細を見る',
    'notification.hasNotificationsAlertText':
      '{count} 件の重要な通知があります。 詳しくはお知らせのページをご覧ください。',
    'notification.goToNotificationsButton': '通知に移動',
    'notification.readAll': 'すべての通知を読む',

    // Rating
    'rating.positiveLabel': '良い',
    'rating.neutralLabel': '普通',
    'rating.negativeLabel': '悪い',

    // Application
    'app.returnToApp': '戻る',
    'app.operationComplete': '確認する',

    // Address
    'address.title': '住所',

    'address.addButton': '住所を追加',
    'address.addButtonShort': '追加',
    'address.editButton': '住所を編集',
    'address.saveButton': '住所を保存',
    'address.badge.seller': '出品者',
    'address.badge.billing': '請求',
    'address.badge.shipping': '配送',

    'address.setDefaultShipping': '配送先住所として使用する',
    'address.setDefaultBilling': '請求先住所として使用する',

    // Address form
    'address.form.title': '肩書き',
    'address.form.title.placeholder': '肩書き',
    'address.form.fullName': '氏名',
    'address.form.fullName.placeholder': '氏名',
    'address.form.email': 'メール',
    'address.form.email.placeholder': 'メール',
    'address.form.phone': '電話番号',
    'address.form.phone.placeholder': '電話番号',
    'address.form.country': '国',
    'address.form.country.placeholder': '国',
    'address.form.zipCode': '郵便番号',
    'address.form.zipCode.placeholder': '郵便番号',
    'address.form.zipCodeError.placeholder': '選択された国は郵便番号不要です',
    'address.form.address1': '配送先住所',
    'address.form.address1.placeholder': '配送先住所',
    'address.form.address2': '配送先住所を追加',
    'address.form.address2.placeholder': '配送先住所を追加',
    'address.form.useAddressAs': 'この住所を:',
    'address.form.defaultBilling': '請求先住所として使用する',
    'address.form.defaultShipping': '配送先住所として使用する',
    'address.form.address': '住所',
    'address.form.address.placeholder': '住所',
    'address.form.region': '都道府県',
    'address.form.region.placeholder': '都道府県',
    'address.form.province': '区',
    'address.form.province.placeholder': '区',
    'address.form.city': '市町村',
    'address.form.city.placeholder': '市町村',

    // Address select
    'address.select.titleShipping': '配送先住所',
    'address.select.buttonGeneral': '住所を変える',
    'address.select.modalTitle': '住所を選択してください',
    'address.select.titleBilling': '請求先住所',
    'address.select.buttonShipping': '配送先住所変更',
    'address.select.buttonBilling': '請求先住所変更',
    'address.select.chooseShipping': '配送先住所を選択する',
    'address.select.chooseSBilling': '請求先住所を選択する',
    'address.select.save': '確認する',

    // Address error
    'address.error.calculate': '入力した住所に誤りがある可能性があります。 入力したデータを再確認してください。',

    //Phone verification
    'phoneVerification.title': '電話認証',
    'phoneVerification.modalText': '確認コードを受け取るには、電話番号を入力してください',
    'phoneVerification.phonePlaceholder': '例えば 000-0000-0000',
    'phoneVerification.smsPlaceholder': 'SMS からのコード',
    'phoneVerification.helpText': `コードを受け取らなかった場合は、もう一度お試しください。`,
    'phoneVerification.invalidCode': '無効なコード',
    'phoneVerification.verifyButton': '確認する',
    'phoneVerification.sendAgainButton': 'コードを再度送ります',
    'phoneVerification.phoneVerified': '確認された番号',
    'phoneVerification.modalSuccess': '番号が正常に認証されました。',
    'phoneVerification.codeNotSent': 'コードは送信されませんでした。 あとでもう一度試してみてください！',
    'phoneVerification.tooShort': 'あなたの番号は短すぎます。 注意深く確認してください',
    'phoneVerification.notValid': 'あなたの番号は無効です。 注意深く確認してください',
    'phoneVerification.notPossible': 'あなたの番号はありえない番号です。 注意深く確認してください',
    'phoneVerification.checkAgain': 'もう一度番号を確認してください',

    //Delivery companies
    'deliveryCompany.japanPost': '日本郵便',
    'deliveryCompany.kuronekoyamato': 'ヤマト運輸',
    'deliveryCompany.sagawa': '佐川急便',
    'deliveryCompany.seino': '西濃運輸',
    'deliveryCompany.nipponExpress': '日本通運',
    'deliveryCompany.fukuyama': '福山通運',
    'deliveryCompany.other': 'その他'
  }
};
