import {
  BsEmojiFrown,
  BsEmojiFrownFill,
  BsEmojiNeutral,
  BsEmojiNeutralFill,
  BsEmojiSmile,
  BsEmojiSmileFill
} from 'react-icons/bs';
import { IconType } from 'react-icons';
import { RatingValueEnum } from '../../store/graphql';

export type TRating = {
  variant: RatingValueEnum;
  defaultIcon: IconType;
  activeIcon: IconType;
};

export const ratings: TRating[] = [
  {
    variant: RatingValueEnum.Positive,
    defaultIcon: BsEmojiSmile,
    activeIcon: BsEmojiSmileFill
  },
  {
    variant: RatingValueEnum.Neutral,
    defaultIcon: BsEmojiNeutral,
    activeIcon: BsEmojiNeutralFill
  },
  {
    variant: RatingValueEnum.Negative,
    defaultIcon: BsEmojiFrown,
    activeIcon: BsEmojiFrownFill
  }
];

export const ratingLabelsIntlNames: Record<RatingValueEnum, string> = {
  [RatingValueEnum.Positive]: 'rating.positiveLabel',
  [RatingValueEnum.Neutral]: 'rating.neutralLabel',
  [RatingValueEnum.Negative]: 'rating.negativeLabel'
};
