import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { BsCheckLg } from 'react-icons/bs';
import { Text, TextVariant } from 'common/components/Text';
import { FunctionComponent, SVGProps } from 'react';
import { CustomIcon } from '../../CustomIcon';
import { useIsMobile } from '../../../hooks/useIsMobile';
import s from './ProgressItem.module.scss';

export interface ProgressItemProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Дополнительные css-классы для дочерних элементов
   * * icon - дефолтная иконка прогресса
   * * text - текст айтема прогресса
   */
  classes?: {
    icon?: string;
    text?: string;
  };
  /**
   * Состояние активного шага
   */
  active?: boolean;
  /**
   * Состояние завершенного шага
   */
  completed?: boolean;
  /**
   * Состояние при котором увеличивается значение к следующему шагу
   */
  growing?: boolean;
  /**
   * Шаг является первым
   */
  starting?: boolean;
  /**
   * Шаг является последним
   */
  ending?: boolean;
  /**
   * Состояние шага с ошибкой (может содержать текст ошибки, либо true/false)
   */
  error?: boolean | string;
  /**
   * Флаг, что следующий шаг имеет ошибку, для корректного отображения цвета полоски
   */
  nextError?: boolean;
  /**
   * Кастомная икона, если нужно заменить дефолтный элемент прогресса
   */
  customIcon?: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
}

export const ProgressItem: ReactFCC<ProgressItemProps> = (props) => {
  const { children, className, active, completed, starting, ending, growing, error, customIcon, nextError, classes } =
    props;

  const isMobile = useIsMobile();

  return (
    <div
      className={clsx(s.ProgressItem, className, {
        [s.ProgressItem_active]: active,
        [s.ProgressItem_completed]: completed,
        [s.ProgressItem_growing]: growing,
        [s.ProgressItem_error]: error,
        [s.ProgressItem_nextError]: nextError,
        [s.ProgressItem_starting]: starting,
        [s.ProgressItem_ending]: ending
      })}
    >
      {customIcon ? (
        <CustomIcon className={s.ProgressItem__customIcon} icon={customIcon} />
      ) : (
        <div className={clsx(s.ProgressItem__iconContainer, classes?.icon)}>
          {completed ? <BsCheckLg className={s.ProgressItem__icon} /> : <div className={s.ProgressItem__circle} />}
        </div>
      )}

      <div className={clsx(s.ProgressItem__textContainer, classes?.text)}>
        <Text
          className={clsx(s.ProgressItem__text, completed)}
          /**
           * Выводим иконку с ошибкой только на десктопе, в противном случае выводим текст ошибки снизу
           */
          tooltip={!isMobile && customIcon ? (typeof error === 'string' ? error : undefined) : undefined}
        >
          {children}
        </Text>

        {isMobile && customIcon && typeof error === 'string' && (
          <Text component={'span'} className={s.ProgressItem__textTooltip} variant={TextVariant.BODY_S_NORMAL}>
            {error}
          </Text>
        )}
      </div>
    </div>
  );
};
