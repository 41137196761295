import React, { useState, useEffect } from 'react';

const calculateDimensions = (naturalWidth: number, naturalHeight: number, maxWidth: number, maxHeight: number) => {
  const aspectRatio = naturalWidth / naturalHeight;

  let width = naturalWidth;
  let height = naturalHeight;

  if (width > maxWidth) {
    width = maxWidth;
    height = width / aspectRatio;
  }

  if (height > maxHeight) {
    height = maxHeight;
    width = height * aspectRatio;
  }

  return { width, height };
};

type ImageLoaderProps = {
  src: string;
  alt?: string;
  naturalWidth: number;
  naturalHeight: number;
  maxWidth: number;
  maxHeight: number;
};
const ImageLoader = ({ src, alt, naturalWidth, naturalHeight, maxWidth = 400, maxHeight = 300 }: ImageLoaderProps) => {
  const [loaded, setLoaded] = useState(false);
  const [dimensions, setDimensions] = useState<{ width: number | string; height: number | string }>({
    width: 'auto',
    height: 'auto'
  });

  useEffect(() => {
    const { width, height } = calculateDimensions(naturalWidth, naturalHeight, maxWidth, maxHeight);
    setDimensions({ width, height });
  }, [naturalWidth, naturalHeight, maxWidth, maxHeight]);

  return (
    <div
      style={{
        width: dimensions.width,
        height: dimensions.height,
        overflow: 'hidden',
        display: 'inline-block',
        position: 'relative',
        boxSizing: 'border-box'
      }}
    >
      <img
        src={src}
        alt={alt}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          opacity: loaded ? 1 : 0,
          transition: 'opacity 0.5s ease',
          borderRadius: '12px'
        }}
        onLoad={() => setLoaded(true)}
      />
    </div>
  );
};

export default ImageLoader;
