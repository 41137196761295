import {
  FileUploadParams,
  ImageUploadParams,
  uploadFile,
  uploadImage,
  UploadResponse,
  uploadVideo,
  VideoUploadParams
} from '../api';

export interface IMsFilesClient {
  uploadFile: (key: string, file: File, params?: FileUploadParams) => Promise<UploadResponse>;
  uploadImage: (key: string, file: File, params?: ImageUploadParams) => Promise<UploadResponse>;
  uploadVideo: (key: string, file: File, params?: VideoUploadParams) => Promise<UploadResponse>;
}

export type MsFilesClientOptions = {
  /**
   * Базовый url сервиса пакетной обработки файлов
   */
  apiUrl: string;
};

/**
 * Класс-обертка над api-методами с более высокой абстракцией.
 * В данный момент просто маппит вызовы api.
 * В дальнейшем сюда можно добавлять кастомизацию параметров, обработку ошибок,
 * утилитарные методы и т.д.
 */
export class MsFilesClient implements IMsFilesClient {
  private readonly apiUrl: string;

  constructor(props: MsFilesClientOptions) {
    this.apiUrl = props.apiUrl;
  }

  public uploadFile(key: string, file: File, params?: FileUploadParams) {
    return uploadFile(this.apiUrl, key, file, params);
  }

  public uploadImage(key: string, file: File, params?: ImageUploadParams) {
    return uploadImage(this.apiUrl, key, file, params);
  }

  public uploadVideo(key: string, file: File, params?: VideoUploadParams) {
    return uploadVideo(this.apiUrl, key, file, params);
  }
}
