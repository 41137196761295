import { sanitizeUrl } from '@braintree/sanitize-url';

export const validateInternalLink = (url: string): boolean => {
  return url.startsWith('/');
};

export const validateExternalURL = (url: string): string => {
  if (validateInternalLink(url)) {
    return '';
  }

  const sanitizedUrl = sanitizeUrl(url);

  if (sanitizedUrl === 'about:blank') {
    return '';
  }

  const isMailTo = sanitizedUrl.startsWith('mailto');
  const isValidHttp = sanitizedUrl.startsWith('http:');
  const isValidHttps = sanitizedUrl.startsWith('https:');

  if (isMailTo || isValidHttp || isValidHttps) {
    return sanitizedUrl;
  }

  return '';
};

type TCreateNewTabUrlWithPath = {
  path: string;
};

export const openPathInSameTab = (props: TCreateNewTabUrlWithPath) => {
  const { path } = props;

  if (!path || typeof window !== 'object' || !window.location?.origin) {
    return;
  }

  window.open(window.location.origin + path, '_self');
};

export const openPathInNewTab = (props: TCreateNewTabUrlWithPath) => {
  const { path } = props;

  if (!path || typeof window !== 'object' || !window.location?.origin) {
    return;
  }

  window.open(path, '_blank');
};
