import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { SellerVerificationEnum } from 'store/graphql';
import { useUi } from 'store/ui/useUi';
import { LoaderBox } from 'common/components/Loader/LoaderBox';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { Heading, HeadingSize } from 'common/components/Heading/Heading';
import { ReactFCC } from 'common/utils/helperTypes';
import { usePayment } from 'store/payment/usePayment';
import { Loader, LoaderSize } from 'common/components/Loader/Loader';
import { PaymentAddButton } from 'common/components/payment/PaymentAddButton/PaymentAddButton';
import { PaymentCardNumber } from 'common/components/payment/PaymentCardNumber/PaymentCardNumber';
import { Anchor, AnchorVariant } from 'common/components/Anchor/Anchor';
import { BsPencil, BsPhone, BsTrash } from 'react-icons/bs';
import { AddressLayoutWithForm, getAddressInformationFromUser } from '../../../widgets/address';
import { Head } from '../../../common/components/Head';
import { UserCard } from '../../../widgets/user/UserCard';
import { TrustPointsNotification } from '../trustPoints/components/TrustPointsNotification';
import { useAuth } from '../../../app/providers/auth-apollo';
import { useUser } from '../../../entities/user';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { useIsDesktop } from '../../../common/hooks/useIsDesktop';
import { PERSONAL_INFORMATION_ROUTE, PROFILE_ROUTE } from '../../../common/utils/routes';
import { useToggle } from '../../../common/hooks/useToggle';
import { PhoneVerificationModal } from '../../../features/user/phoneVerification';
import { Grid } from '../../../common/components/Grid';
import { LinkBack } from '../../../common/components/LinkBack';
import s from './ProfilePage.module.scss';

const fieldNotFilled = '—';

export const ProfilePage: ReactFCC = () => {
  const { loading } = useAuth();
  const { user } = useUser();

  const { paymentCardLastNumbers, addPaymentMethod, addPaymentLoading, detachPaymentMethod, detachPaymentLoading } =
    usePayment();
  const paymentLoading = addPaymentLoading || detachPaymentLoading;
  const location = useLocation();

  const isMobile = useIsMobile();

  const isDesktop = useIsDesktop();

  const { toggleUserDataModal } = useUi();

  const [phoneVerificationModalOpen, { set: openPhoneVerificationModal, unset: closePhoneVerificationModal }] =
    useToggle(false);

  const seller = user?.sellerVerification === SellerVerificationEnum.Verified ? user.seller : undefined;

  const handleAddPaymentMethod = () => {
    return addPaymentMethod({
      successPath: location.pathname,
      cancelPath: location.pathname
    });
  };

  const editButton = (
    <Anchor component={'button'} className={s.ProfilePage__sideAction} onClick={() => toggleUserDataModal(true)}>
      <BsPencil size={16} />
      <FormattedMessage id="profile.edit" />
    </Anchor>
  );

  const editNumberButton = (
    <Anchor component={'button'} className={s.ProfilePage__sideAction} onClick={() => openPhoneVerificationModal()}>
      {user?.phoneNumber ? (
        <>
          <BsPhone size={16} />
          <FormattedMessage id="profile.edit" />
        </>
      ) : (
        <>
          <BsPhone size={16} />
          Add
        </>
      )}
    </Anchor>
  );

  const deletePaymentButton = (
    <Anchor
      component={'button'}
      variant={AnchorVariant.DANGER}
      className={clsx(s.ProfilePage__sideAction)}
      onClick={paymentLoading ? undefined : detachPaymentMethod}
    >
      {paymentLoading ? (
        <Loader size={LoaderSize.SMALL} />
      ) : (
        <>
          <BsTrash size={16} />
          <FormattedMessage id="payment.deleteMethod" />
        </>
      )}
    </Anchor>
  );

  const addPaymentButton = (
    <PaymentAddButton loading={paymentLoading} onClick={handleAddPaymentMethod} variant={AnchorVariant.SECONDARY} />
  );

  const paymentMethodComp = <PaymentCardNumber cardLastNumbers={paymentCardLastNumbers} />;

  const userName = user && user.name && user.secondName ? `${user.name} ${user.secondName}` : undefined;
  const userNameHiragana =
    seller && seller.nameHiragana && seller.secondNameHiragana
      ? `${seller.nameHiragana} ${seller.secondNameHiragana}`
      : undefined;

  if (loading || !user) {
    return <LoaderBox />;
  }

  return (
    <>
      <Head title={'My profile'} />

      {isMobile && location.pathname === PROFILE_ROUTE && (
        <div className={s.ProfilePage__mobileAvatarContent}>
          <div className={s.ProfilePage__mobileFiller}></div>
          <UserCard user={user} isOwnPage={true} isAvatarUpdated />
        </div>
      )}

      {!isMobile && (
        <div className={s.ProfilePage}>
          <>
            {isDesktop && location.pathname !== PERSONAL_INFORMATION_ROUTE && (
              <>
                <Heading size={HeadingSize.H3}>
                  <FormattedMessage id="profile.personalInformationTitle" />
                </Heading>
                <Space size={ESpaceSize.PIXEL_32} />
                <div className={s.ProfilePage__badge}>
                  <span className={s.ProfilePage__badgeValue}>
                    <FormattedMessage id="profile.personalInformationTitle" />
                  </span>
                </div>

                <div className={s.ProfilePage__noHeading}>{editButton}</div>

                <div className={s.ProfilePage__details}>
                  <UserCard user={user} isOwnPage={true} isAvatarUpdated />
                  <>
                    <Grid className={s.ProfilePage__information}>
                      <Grid.GridItem cols={{ xs: 1, lg: 1 }} className={s.OrderPage__option}>
                        <div className={s.ProfilePage__details_item}>
                          <div className={s.ProfilePage__details_item_extended}>
                            <span className={s.ProfilePage__details_item_title}>
                              <FormattedMessage id={'auth.nameLabel'} />
                            </span>
                            <span>{userNameHiragana}</span>
                            <span>{userName}</span>
                          </div>
                        </div>
                      </Grid.GridItem>
                      <Grid.GridItem cols={{ xs: 1, lg: 1 }} className={s.OrderPage__option}>
                        <div className={s.ProfilePage__details_item}>
                          <div className={s.ProfilePage__details_item_extended}>
                            <span className={s.ProfilePage__details_item_title}>
                              <FormattedMessage id={'address.form.email'} />
                            </span>
                            <span>{user?.email}</span>
                          </div>
                        </div>
                      </Grid.GridItem>

                      <Grid.GridItem cols={{ xs: 1, lg: 1 }} className={s.OrderPage__option}>
                        <div className={s.ProfilePage__details_item}>
                          <div className={s.ProfilePage__details_item_extended}>
                            <span className={s.ProfilePage__details_item_title}>
                              <FormattedMessage id={'auth.nicknameLabel'} />
                            </span>
                            <span>{user?.nickname}</span>
                          </div>
                        </div>
                      </Grid.GridItem>

                      <Grid.GridItem cols={{ xs: 1, lg: 1 }} className={s.OrderPage__option}>
                        <div className={s.ProfilePage__details_item}>
                          <div className={s.ProfilePage__details_item_extended}>
                            <span className={s.ProfilePage__details_item_title}>
                              <FormattedMessage id={'profile.verificationNumber'} />
                            </span>
                            <span>{user?.phoneNumber}</span>
                          </div>
                          <span className={s.ProfilePage__editButton}> {editNumberButton}</span>
                        </div>
                      </Grid.GridItem>
                    </Grid>
                  </>
                </div>
                {user?.seller?.isTrusted !== undefined && (
                  <>
                    <Space size={ESpaceSize.PIXEL_32} />
                    <TrustPointsNotification trustedSeller={user.seller.isTrusted} />
                  </>
                )}
                <Space size={ESpaceSize.PIXEL_32} />
                <div className={s.ProfilePage__badge}>
                  <span className={s.ProfilePage__badgeValue}>
                    <FormattedMessage id={'address.title'} />
                  </span>
                </div>
                <div className={s.ProfilePage__addressRow}>
                  <AddressLayoutWithForm
                    className={s.ProfilePage__addresses}
                    noHeading={true}
                    classes={{
                      item: s.ProfilePage__address
                    }}
                    addresses={user.addresses}
                    personalInformation={getAddressInformationFromUser(user)}
                  />
                </div>
                <Space size={ESpaceSize.PIXEL_32} />
                <div className={s.ProfilePage__badge}>
                  <span className={s.ProfilePage__badgeValue}>
                    <FormattedMessage id={'footer.linkPaymentMethods'} />
                  </span>
                </div>
                <div className={!paymentCardLastNumbers ? s.ProfilePage__noHeading : s.ProfilePage__heading}>
                  {paymentCardLastNumbers && (
                    <span className={s.ProfilePage__details_item_title}>
                      <FormattedMessage id={'payment.bankCard'} />
                    </span>
                  )}
                  {paymentCardLastNumbers ? deletePaymentButton : addPaymentButton}
                </div>
                <Space size={ESpaceSize.PIXEL_12} />
                <div
                  className={clsx({
                    [s.ProfilePage__paymentRow_hasMethod]: !!paymentCardLastNumbers
                  })}
                >
                  <div className={s.ProfilePage__infoValue}>{paymentCardLastNumbers && paymentMethodComp}</div>
                </div>
              </>
            )}
          </>
        </div>
      )}

      {(isMobile || isDesktop) && location.pathname === PERSONAL_INFORMATION_ROUTE && (
        <>
          <>
            <Space size={ESpaceSize.PIXEL_32} />
            <LinkBack to={PROFILE_ROUTE}>Back to user profile</LinkBack>
            <Space size={ESpaceSize.PIXEL_32} />
            <Heading size={HeadingSize.H3}>
              <FormattedMessage id="profile.personalInformationTitle" />
            </Heading>
          </>
          <div className={s.ProfilePage__info}>
            <div className={s.ProfilePage__heading}>
              <Heading size={isMobile ? HeadingSize.H6 : HeadingSize.H5}>
                <FormattedMessage id="auth.nameLabel" />
              </Heading>
              {editButton}
            </div>
            <div className={s.ProfilePage__row}>
              <span className={s.ProfilePage__infoName}>
                <FormattedMessage id={'profile.personalInfoHiragana'} />
              </span>

              <span className={s.ProfilePage__infoValue}>{userNameHiragana || fieldNotFilled}</span>
            </div>
            <div className={s.ProfilePage__row}>
              <span className={s.ProfilePage__infoName}>
                <FormattedMessage id={'profile.personalInfoKanjiLatin'} />
              </span>
              <span className={s.ProfilePage__infoValue}>{userName || fieldNotFilled}</span>
            </div>
            <div className={s.ProfilePage__row}>
              <span className={s.ProfilePage__infoName}>
                <FormattedMessage id="auth.nicknameLabel" />
              </span>

              <span className={s.ProfilePage__infoValue}>{user.nickname || fieldNotFilled}</span>
            </div>
          </div>

          <div className={s.ProfilePage__info}>
            <div className={s.ProfilePage__heading}>
              <Heading size={isMobile ? HeadingSize.H6 : HeadingSize.H5}>
                <FormattedMessage id="auth.emailLabel" />
              </Heading>
            </div>

            <div className={s.ProfilePage__row}>
              <span className={s.ProfilePage__infoName}>
                <FormattedMessage id="auth.emailLabel" />
              </span>

              <span className={s.ProfilePage__infoValue}>{user.email}</span>
            </div>
          </div>

          <>
            <div className={s.ProfilePage__info}>
              <div className={s.ProfilePage__heading}>
                <Heading size={isMobile ? HeadingSize.H6 : HeadingSize.H5}>
                  <FormattedMessage id={'profile.verificationNumber'} />
                </Heading>
                {editNumberButton}
              </div>

              <div className={s.ProfilePage__row}>
                <span className={s.ProfilePage__infoName}>
                  <FormattedMessage id={'profile.verificationNumber'} />
                </span>

                <span className={s.ProfilePage__infoValue}>{user.phoneNumber || ''}</span>
              </div>
            </div>
          </>
          <div className={s.ProfilePage__info}>
            <div className={s.ProfilePage__addressRow}>
              <AddressLayoutWithForm
                className={s.ProfilePage__addresses}
                classes={{
                  item: s.ProfilePage__address
                }}
                addresses={user.addresses}
                personalInformation={getAddressInformationFromUser(user)}
              />
            </div>
          </div>

          <div className={s.ProfilePage__info}>
            <div className={s.ProfilePage__heading}>
              <Heading size={isMobile ? HeadingSize.H6 : HeadingSize.H5}>
                <FormattedMessage id={'payment.method'} />
              </Heading>
              {paymentCardLastNumbers ? deletePaymentButton : addPaymentButton}
            </div>
            <div
              className={clsx({
                [s.ProfilePage__paymentRow_hasMethod]: !!paymentCardLastNumbers
              })}
            >
              <div className={s.ProfilePage__infoValue}>{paymentCardLastNumbers && paymentMethodComp}</div>
            </div>
          </div>

          <Space size={ESpaceSize.PIXEL_48} />
        </>
      )}
      {/* Модальное окно, открывающееся в случае редактирования номера */}
      <PhoneVerificationModal isOpen={phoneVerificationModalOpen} onClose={closePhoneVerificationModal} />
    </>
  );
};
