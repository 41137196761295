import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Anchor } from '../../../../common/components/Anchor';
import { customIcons } from '../../../../common/components/CustomIcon';
import { Loader } from '../../../../common/components/Loader';
import s from './AddressAddButton.module.scss';

export enum AddressAddButtonVariant {
  DEFAULT = 'default',
  SIMPLE = 'simple'
}

export interface AddressAddButtonProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Состояния загрузки
   */
  loading?: boolean;
  /**
   * Обработчик нажатия
   */
  onClick?: (e: React.MouseEvent) => void;
  /**
   * Вариант отображения
   * * default - полный текст с иконкой (Add address)
   * * simple – сокращенный текст без иконки (Add)
   */
  variant?: AddressAddButtonVariant;
  disabled?: boolean;
}

export const AddressAddButton = ({
  className,
  loading,
  onClick,
  disabled,
  variant = AddressAddButtonVariant.DEFAULT
}: AddressAddButtonProps) => {
  return (
    <Anchor
      component={'button'}
      className={clsx(s.AddressAddButton, className)}
      leftIcon={variant === AddressAddButtonVariant.DEFAULT ? customIcons.Location : undefined}
      onClick={loading || disabled ? undefined : onClick}
    >
      {loading ? (
        <Loader small />
      ) : variant === AddressAddButtonVariant.DEFAULT ? (
        <FormattedMessage id={'address.addButton'} />
      ) : (
        <FormattedMessage id={'address.addButtonShort'} />
      )}
    </Anchor>
  );
};
