import { useAsync } from 'react-use';
import { useLocalStorage } from './useLocalStorage';

export function useLocalStorageChange<T>(key: string, callback: () => T | Promise<T>) {
  const [lsData] = useLocalStorage<T>(key);

  const result = useAsync(async () => {
    return (await callback()) as Promise<T>;
  }, [lsData, callback]);

  return [lsData, result];
}
