import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Navigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactFCC } from 'common/utils/helperTypes';
import { Grid } from 'common/components/Grid/Grid';
import { LinkBack } from 'common/components/LinkBack/LinkBack';
import { Modal } from 'common/components/Modal';
import {
  ProductConditionEnum,
  ProductStatusEnum,
  SpecificValueInput,
  useCreateProductDataQuery,
  useSellerProductDraftsQuery
} from 'store/graphql';
import { LoaderBox } from 'common/components/Loader';
import { Head } from '../../../common/components/Head';
import { useUrlParam } from '../../../common/hooks/useUrlParam';
import {
  DRAFTS_ROUTE,
  EDIT_PRODUCT_DRAFT_PAGE_PARAM,
  EDIT_PRODUCT_PAGE_PARAM,
  NOT_FOUND_ROUTE,
  RELIST_PRODUCT_PAGE_PARAM
} from '../../../common/utils/routes';
import { isEnterKey } from '../../../common/utils/isKey';
import { useAuth } from '../../../app/providers/auth-apollo';
import { useUser } from '../../../entities/user';
import { CreateProductPageContent } from './content/CreateProductPageContent';
import s from './CreateProductPage.module.scss';

export const CreateProductPage: ReactFCC = () => {
  const draftId = useUrlParam(EDIT_PRODUCT_DRAFT_PAGE_PARAM);
  const id = useUrlParam(EDIT_PRODUCT_PAGE_PARAM);
  const relistId = useUrlParam(RELIST_PRODUCT_PAGE_PARAM);
  const { loading: userLoading } = useAuth();
  const { user } = useUser();

  const intl = useIntl();

  const { data: productQuery, loading: productLoading } = useCreateProductDataQuery({
    skip: !id && !relistId,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id || relistId || ''
    }
  });

  const { data: draftEditQuery, loading: draftEditLoading } = useSellerProductDraftsQuery({
    skip: !draftId,
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        filters: {
          id: draftId ? +draftId : undefined
        }
      }
    }
  });

  const product = productQuery?.products?.entries[0];
  const productDraft = draftEditQuery?.user.seller?.drafts?.entries[0];

  const navigate = useNavigate();

  const [warningModalOpen, setWarningModalOpen] = useState(false);

  const canEdit = useMemo(() => {
    return user && product && product?.sellerId === user?.seller?.id;
  }, [product, user]);

  const canRecoverDraft = useMemo(() => {
    return user && productDraft && productDraft?.sellerId === user?.seller?.id;
  }, [productDraft, user]);

  const loading = productLoading || userLoading || draftEditLoading;

  return (
    <>
      <Head
        title={
          id ? 'Edit the product' : draftId ? intl.formatMessage({ id: 'profile.draftsCreate' }) : 'Create new product'
        }
      />

      <Grid className={s.CreateProductPage}>
        <Grid.GridItem cols={{ xs: 2, lg: 3 }}>
          <LinkBack
            onClick={draftId ? () => navigate(DRAFTS_ROUTE) : () => setWarningModalOpen(true)}
            onKeyDownCapture={(e: React.KeyboardEvent) => {
              if (isEnterKey(e)) {
                setWarningModalOpen(true);
              }
            }}
          >
            {draftId ? (
              <FormattedMessage id="profile.editDraftBack" />
            ) : (
              <FormattedMessage id="profile.createProductBack" />
            )}
          </LinkBack>
        </Grid.GridItem>

        <Grid.GridItem cols={{ xs: 2, lg: 9, xl: 6 }}>
          {loading ? (
            <LoaderBox />
          ) : (
            <>
              {!!draftId || !!id || !!relistId ? (
                !!draftId ? (
                  canRecoverDraft ? (
                    productDraft && (
                      <CreateProductPageContent
                        draftId={draftId}
                        initialDraftImages={
                          productDraft.photos?.map((photo) => ({
                            msfiles_uid: photo.msfiles_uid,
                            url: photo.main_file?.url,
                            main: productDraft?.mainPhoto?.msfiles_uid === photo.msfiles_uid
                          })) || []
                        }
                        initialImages={productDraft.photos?.map((attachment) => {
                          return (
                            {
                              ...attachment,
                              main: productDraft.mainPhoto?.msfiles_uid === attachment.msfiles_uid,
                              url: attachment.thumbnails?.S?.url
                            } || []
                          );
                        })}
                        initialValues={{
                          categoryId: productDraft.categoryId || undefined,
                          subCategoryId: productDraft.subCategoryId || undefined,
                          subSubCategoryId: productDraft.subSubCategoryId || undefined,
                          name: productDraft.name || undefined,
                          description: productDraft.description || undefined,
                          brandId: productDraft.brandId || undefined,
                          colorId: productDraft.colorId || undefined,
                          condition:
                            productDraft.condition === null ? ProductConditionEnum.New : productDraft.condition,
                          specificValues: (productDraft.specificValues as SpecificValueInput[]) || [],
                          mainPhotoIndex: productDraft.mainPhotoIndex || 0,
                          price: productDraft.price!,
                          deliverySpeedDays: productDraft.deliverySpeedDays || undefined,
                          boxWidth: productDraft.boxWidth || undefined,
                          boxHeight: productDraft.boxHeight || undefined,
                          boxLength: productDraft.boxLength || undefined,
                          weight: productDraft.weight || undefined
                        }}
                      />
                    )
                  ) : (
                    <Navigate to={NOT_FOUND_ROUTE} />
                  )
                ) : canEdit ? (
                  product && (
                    <CreateProductPageContent
                      id={id}
                      relistId={relistId}
                      initialImages={
                        relistId
                          ? undefined
                          : product.photos?.map((attachment) => ({
                              ...attachment,
                              main: product.mainPhoto?.msfiles_uid === attachment.msfiles_uid,
                              url: attachment.thumbnails?.S?.url
                            })) || []
                      }
                      initialValues={{
                        categoryId: product.categoryId,
                        subCategoryId: product.subCategoryId || undefined,
                        subSubCategoryId: product.subSubCategoryId || undefined,
                        name: product.name,
                        description: product.description || undefined,
                        brandId: product.brandId || undefined,
                        colorId: product.colorId || undefined,
                        condition: product.condition,
                        status: relistId ? ProductStatusEnum.ForSale : product.status,
                        specificValues: (product.specificValues as SpecificValueInput[]) || [],
                        price: product.price,
                        deliverySpeedDays: product.deliverySpeedDays,
                        boxWidth: product.boxWidth || undefined,
                        boxHeight: product.boxHeight || undefined,
                        boxLength: product.boxLength || undefined,
                        weight: product.weight || undefined,
                        kuroneko: product.kuroneko || undefined,
                        warehouseNumber: product.warehouseNumber || undefined,
                        mainPhotoIndex: relistId
                          ? 0
                          : product.photos?.findIndex((photo) => photo.id === product.mainPhoto?.id) || 0
                      }}
                    />
                  )
                ) : (
                  <Navigate to={NOT_FOUND_ROUTE} />
                )
              ) : (
                <CreateProductPageContent />
              )}
            </>
          )}
        </Grid.GridItem>

        <Modal
          isOpen={warningModalOpen}
          onClose={() => setWarningModalOpen(false)}
          title={intl.formatMessage({ id: 'profile.createProductBack' })}
        >
          <Modal.Body>
            <FormattedMessage id="profile.createProductBack" />
          </Modal.Body>

          <Modal.Footer>
            <Modal.Button onClick={() => navigate(-1)}>
              <FormattedMessage id="general.yes" />
            </Modal.Button>
          </Modal.Footer>
        </Modal>
      </Grid>
    </>
  );
};
