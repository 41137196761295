export type QueryMatcher = string | RegExp | ((key: string) => boolean);

/**
 * Evicts provided queries from Apollo InMemoryCache (Works for Apollo Client v2)
 *
 * @param store - Apollo InMemoryCache instance
 * @param queries - Array of strings, RegExps or functions, determining which queries to evict.
 *  Strings used as prefixes, removing all queries starting with the provided string.
 *  RegExps are tested against stored query names.
 *  Functions accept stored query name and should return true if the query should be evicted
 */
export function clearApolloQueryCache(store: any, queries: QueryMatcher[]) {
  const checkQuery = (queryKey: string) => (query: QueryMatcher) =>
    query instanceof RegExp
      ? queryKey.match(query)
      : typeof query === 'string'
      ? queryKey.indexOf(query) === 0
      : typeof query === 'function'
      ? query(queryKey)
      : false;

  Object.keys(store.data.data).forEach((key) => {
    const matches = key.match(/^\$ROOT_QUERY\.(.+)$/);
    if (!matches) {
      return;
    }

    const queryKey = matches[1];
    if (queries.some(checkQuery(queryKey))) {
      store.data.delete(key);
    }
  });

  if (store.data.data.ROOT_QUERY) {
    const ROOT_QUERY = store.data.data.ROOT_QUERY;
    Object.keys(ROOT_QUERY).forEach((key) => {
      if (queries.some(checkQuery(key))) {
        delete ROOT_QUERY[key];
      }
    });
  }

  return store;
}
