import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ExtractArray, ReactFCC } from '../../../common/utils/helperTypes';
import { ProductQuery, SellerPublicInfoType, useSellerQuery } from '../../../store/graphql';
import { ImageViewer } from '../../../common/components/ImageViewer/ImageViewer';
import { LinkBack } from '../../../common/components/LinkBack';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import { EBreakpoints, useBreakpoint } from '../../../common/hooks/useBreakpoint';
import { Grid } from '../../../common/components/Grid';
import { ConditionalWrapper } from '../../../common/components/ConditionalWrapper/ConditionalWrapper';
import { Divider } from '../../../common/components/Divider/Divider';
import { LoaderBox } from '../../../common/components/Loader';
import { GoogleTagEvents, googleTagSendDefaultEvent } from '../../../features/analytics';
import { ToggleFavoriteButton } from '../../../features/product/favorite';
import { useUser } from '../../../entities/user';
import { useAuth } from '../../../app/providers/auth-apollo';
import { ProductInformation } from './information/ProductInformation';
import { ProductCharacteristics } from './characteristics/ProductCharacteristics';
import { ProductComments } from './comments/ProductComments';
import { ProductProducts, TProductProductsType } from './products/ProductProducts';
import s from './ProductPageContent.module.scss';

export type TProductPageContent = {
  product: ExtractArray<ProductQuery['products']['entries']>;
};

export const ProductPageContent: ReactFCC<TProductPageContent> = ({ product }) => {
  const similarProducts = product?.similarProducts;
  const sellerOtherProducts = product?.sellerOtherProducts;

  const { isAuthenticated } = useAuth();
  const { user } = useUser();

  const shippingAddress = user?.addresses.find((address) => address.defaultShipping);

  const { data: sellerQueryData, loading: sellerLoading } = useSellerQuery({
    variables: {
      id: product.sellerId
    },
    skip: !product.sellerId
  });
  const seller = sellerQueryData?.seller?.sellers.entries[0] as SellerPublicInfoType;

  const loading = sellerLoading;

  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);

  const intl = useIntl();

  useEffect(() => {
    googleTagSendDefaultEvent(GoogleTagEvents.view_product, {
      product_id: product.id
    });
  }, [product.id]);

  return loading ? (
    <LoaderBox />
  ) : (
    <Grid className={s.ProductPageContent}>
      <Grid.GridItem cols={{ xs: 2, lg: 6 }}>
        <ConditionalWrapper condition={!isMobile} wrapper={(children) => <>{children}</>}>
          <LinkBack>
            <FormattedMessage id="general.back" />
          </LinkBack>

          <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />

          <div className={s.ProductPageContent__image}>
            <ImageViewer images={product.photos} mainPhoto={product.mainPhoto} />

            {product.sellerId !== user?.sellerId && (
              <ToggleFavoriteButton
                className={s.ProductPageContent__toggleFavoriteButton}
                productId={product.id}
                active={!!product.favorite}
              />
            )}
          </div>
        </ConditionalWrapper>

        {isMobile && <Space size={ESpaceSize.PIXEL_16} />}
      </Grid.GridItem>

      <Grid.GridItem cols={{ xs: 2, lg: 6 }}>
        {!isMobile && <Space size={ESpaceSize.PIXEL_56} />}

        <ProductInformation
          product={product}
          seller={seller}
          shippingAddress={shippingAddress}
          isLoggedIn={isAuthenticated}
        />

        <Divider space={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />

        <ProductCharacteristics product={product} />

        <Divider space={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />

        <ProductComments
          productId={product.id}
          productStatus={product.status}
          comments={product.comments}
          sellerId={product.sellerId}
        />
      </Grid.GridItem>

      <Grid.GridItem full>
        {isMobile ? (
          <Divider space={{ top: ESpaceSize.PIXEL_40, bottom: ESpaceSize.PIXEL_32 }} />
        ) : (
          <Space size={ESpaceSize.PIXEL_64} />
        )}
      </Grid.GridItem>

      <Grid.GridItem full>
        {!!similarProducts?.length && (
          <>
            <ProductProducts
              title={intl.formatMessage({ id: 'product.similarProduct' })}
              products={similarProducts as TProductProductsType[]}
            />

            <Space size={isMobile ? ESpaceSize.PIXEL_32 : ESpaceSize.PIXEL_40} />
          </>
        )}

        {!!sellerOtherProducts?.length && (
          <ProductProducts
            title={intl.formatMessage({ id: 'product.sellersOtherProducts' })}
            products={sellerOtherProducts as TProductProductsType[]}
          />
        )}
      </Grid.GridItem>
    </Grid>
  );
};
