import { Col, Container, Row } from 'react-bootstrap';
import React from 'react';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  ABOUT_ROUTE,
  ADDITIONAL_SERVICE_ROUTE,
  CALCULATOR_ROUTE,
  COMMISSION_ROUTE,
  CONTACT_ROUTE,
  COOKIES_ROUTE,
  DELIVERY_PROBLEMS_ROUTE,
  DELIVERY_ROUTE,
  HOME_ROUTE,
  LAW_ROUTE,
  PAYMENT_ROUTE,
  PRIVACY_ROUTE,
  PROHIBITED_ITEMS_ROUTE,
  PURCHASE_ISSUES_ROUTE,
  REFUNDS_ROUTE,
  SELLER_IDENTITY_ROUTE,
  SIZE_LIMIT_ROUTE,
  SUPPORT_HELP_ROUTE,
  SUPPORT_HUB_ROUTE,
  TERMS_ROUTE,
  TESTIMONIALS_ROUTE,
  WITHDRAW_HELP_ROUTE
} from '../../../../common/utils/routes';
import { FooterLinks } from '../FooterLinks/FooterLinks';
import { useToggle } from '../../../../common/hooks/useToggle';
import { FeedbackModal } from '../FeedbackModal/FeedbackModal';
import logoSrc from './assets/logo.svg';
import { ReactComponent as IconGoogle } from './assets/i-google-play.svg';
import { ReactComponent as IconAppStore } from './assets/i-app-store.svg';
import { ReactComponent as IconFacebook } from './assets/i-facebook.svg';
import { ReactComponent as IconInstagram } from './assets/i-instagram.svg';
import { ReactComponent as IconTwitter } from './assets/i-twitter.svg';
import { ReactComponent as IconLinkedin } from './assets/i-linkedin.svg';
import s from './Footer.module.scss';

export const Footer = () => {
  const intl = useIntl();
  const [feedbackModalOpen, { set: openFeedbackModal, unset: closeFeedbackModal }] = useToggle(false);

  return (
    <div className={s.Footer}>
      <Container>
        <Row>
          <Col xs={12} lg={3} className={s.Footer__info}>
            <Link className={s.Footer__logo} to={HOME_ROUTE}>
              <img src={logoSrc} alt="" />
            </Link>
            <span className={s.Footer__text}>
              <FormattedMessage id="footer.about" />
            </span>
          </Col>
          <FooterLinks
            title={intl.formatMessage({ id: 'footer.aboutUs' })}
            items={[
              {
                name: intl.formatMessage({ id: 'footer.linkAboutCompany' }),
                path: ABOUT_ROUTE,
                external: false
              },
              {
                name: intl.formatMessage({ id: 'footer.linkTermsOfUse' }),
                path: TERMS_ROUTE,
                external: false
              },
              {
                name: intl.formatMessage({ id: 'footer.linkPrivacyPolicy' }),
                path: PRIVACY_ROUTE,
                external: false
              },
              {
                name: intl.formatMessage({ id: 'footer.linkLaw' }),
                path: LAW_ROUTE,
                external: false
              },
              {
                name: intl.formatMessage({ id: 'footer.linkCookies' }),
                path: COOKIES_ROUTE,
                external: false
              },
              {
                name: intl.formatMessage({ id: 'footer.linkReviews' }),
                path: TESTIMONIALS_ROUTE
              },
              {
                name: intl.formatMessage({ id: 'footer.linkContacts' }),
                path: CONTACT_ROUTE,
                external: false
              },
              {
                name: intl.formatMessage({ id: 'footer.linkFeedback' }),
                path: '#',
                onClick: () => openFeedbackModal()
              }
            ]}
            xs={12}
            lg={2}
          />
          <FooterLinks
            title={intl.formatMessage({ id: 'footer.userManual' })}
            items={[
              {
                name: intl.formatMessage({ id: 'footer.linkPaymentMethods' }),
                path: PAYMENT_ROUTE
              },
              {
                name: intl.formatMessage({ id: 'footer.linkPaymentCurrency' }),
                path: PAYMENT_ROUTE
              },
              {
                name: intl.formatMessage({ id: 'footer.linkPaymentProcessing' }),
                path: PAYMENT_ROUTE
              },
              {
                name: intl.formatMessage({ id: 'footer.linkCommissions' }),
                path: COMMISSION_ROUTE
              },
              {
                name: intl.formatMessage({ id: 'footer.linkRefunds' }),
                path: REFUNDS_ROUTE
              },
              {
                name: intl.formatMessage({ id: 'footer.linkDelivery' }),
                path: DELIVERY_ROUTE
              },
              {
                name: intl.formatMessage({ id: 'footer.linkShipping' }),
                path: CALCULATOR_ROUTE
              },
              {
                name: intl.formatMessage({ id: 'footer.linkProhibited' }),
                path: PROHIBITED_ITEMS_ROUTE
              },
              {
                name: intl.formatMessage({ id: 'footer.linkLimit' }),
                path: SIZE_LIMIT_ROUTE
              }
            ]}
            xs={12}
            lg={2}
          />
          <FooterLinks
            title={intl.formatMessage({ id: 'footer.help' })}
            items={[
              {
                name: intl.formatMessage({ id: 'footer.linkFaq' }),
                path: SUPPORT_HUB_ROUTE
              },
              {
                name: intl.formatMessage({ id: 'footer.linkSupport' }),
                path: SUPPORT_HELP_ROUTE
              },
              {
                name: intl.formatMessage({ id: 'footer.linkAdditionalServices' }),
                path: ADDITIONAL_SERVICE_ROUTE
              },
              {
                name: intl.formatMessage({ id: 'footer.linkDeliveryProblems' }),
                path: DELIVERY_PROBLEMS_ROUTE
              },
              {
                name: intl.formatMessage({ id: 'footer.linkProductProblems' }),
                path: PURCHASE_ISSUES_ROUTE
              },
              {
                name: intl.formatMessage({ id: 'footer.linkSellerIdentification' }),
                path: SELLER_IDENTITY_ROUTE
              },
              {
                name: intl.formatMessage({ id: 'footer.linkWithdrawal' }),
                path: WITHDRAW_HELP_ROUTE
              }
            ]}
            xs={12}
            lg={2}
          />
          <Col xs={12} lg={{ span: 2, offset: 1 }} className="d-flex flex-column">
            <h6 className={clsx(s.Footer__header, s.Footer__header_mobileAppsHeader)}>
              <FormattedMessage id="footer.mobileApp" />
            </h6>
            <div className={s.Footer__mobileAppLinks}>
              <a href="/src/pages" className={s.Footer__mobileAppLink}>
                <IconGoogle />
              </a>
              <a href="/src/pages" className={s.Footer__mobileAppLink}>
                <IconAppStore />
              </a>
            </div>
            <h6 className={s.Footer__header}>
              <FormattedMessage id="footer.socialNetworks" />
            </h6>
            <div className="d-flex flex-row gap-3">
              <a href="/src/pages" className={s.Footer__socialLink}>
                <IconFacebook />
              </a>
              <a href="/src/pages" className={s.Footer__socialLink}>
                <IconInstagram />
              </a>
              <a href="/src/pages" className={s.Footer__socialLink}>
                <IconTwitter />
              </a>
              <a href="/src/pages" className={s.Footer__socialLink}>
                <IconLinkedin />
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <span className={clsx(s.Footer__copyright, 'text-center text-lg-start')}>
            <FormattedMessage id="footer.copyright" values={{ year: new Date().getFullYear() }} />
          </span>
        </Row>
      </Container>
      <FeedbackModal onClose={() => closeFeedbackModal()} isOpen={feedbackModalOpen} />
    </div>
  );
};
