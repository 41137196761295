import React from 'react';
import clsx from 'clsx';
import { BsInfoCircle } from 'react-icons/bs';
import { TippyProps } from '@tippyjs/react';
import { Tooltip, TooltipContainer } from '../Tooltip';
import s from './Text.module.scss';

export enum TextVariant {
  BODY_L_BOLD = 'bodyLBold',
  BODY_L_NORMAL = 'bodyLNormal',
  BODY_R_BOLD = 'bodyRBold',
  BODY_R_NORMAL = 'bodyRNormal',
  BODY_S_BOLD = 'bodySBold',
  BODY_S_NORMAL = 'bodySNormal'
}

export interface TextProps<ComponentType extends React.ElementType = 'p'> {
  /**
   * Тэг компонента
   */
  component?: ComponentType;
  /**
   * Дополнительный css-класс корневого элемента
   */
  className?: string;
  /**
   * Дополнительный css-классы дочерних элементов
   */
  classes?: {
    tooltipContainer?: string;
  };
  /**
   * Цвет текста
   */
  color?: string;
  /**
   * Не добавлять стили шрифтов
   */
  noStyle?: boolean;
  /**
   * Выводимый текст, если не передан children
   */
  text?: React.ReactNode;
  /**
   * Вариант шрифта
   */
  variant?: TextVariant;
  /**
   * Обработчик нажатия на Text
   */
  onClick?: (e: React.MouseEvent) => void;
  /**
   * Значок с подсказкой
   */
  tooltip?: string;
  /**
   * Параметры тултипа
   */
  tooltipProps?: TippyProps;
}

const tooltipOffset: Record<string, [number, number]> = {
  right: [5, 8]
};

export const Text = <T extends React.ElementType = 'p'>(props: TextProps<T> & React.ComponentPropsWithRef<T>) => {
  const {
    component = 'p',
    children,
    noStyle,
    className,
    classes,
    color,
    style,
    onClick,
    text,
    variant = TextVariant.BODY_R_NORMAL,
    tooltip,
    tooltipProps,
    ...otherProps
  } = props;

  const textStyle: React.CSSProperties = style || {};
  if (color) {
    textStyle.color = color;
  }

  const TextTag = component;

  return (
    <TextTag
      className={clsx(
        !noStyle && {
          [s.Text_pointer]: onClick,
          [s.Text_bodyLBold]: variant === TextVariant.BODY_L_BOLD,
          [s.Text_bodyLNormal]: variant === TextVariant.BODY_L_NORMAL,
          [s.Text_bodyRBold]: variant === TextVariant.BODY_R_BOLD,
          [s.Text_bodyRNormal]: variant === TextVariant.BODY_R_NORMAL,
          [s.Text_bodySBold]: variant === TextVariant.BODY_S_BOLD,
          [s.Text_bodySNormal]: variant === TextVariant.BODY_S_NORMAL
        },
        s.Text,
        className
      )}
      style={textStyle}
      onClick={onClick}
      {...otherProps}
    >
      {children ?? text}

      {!!tooltip && (
        <Tooltip
          text={tooltip}
          offset={(!!tooltipProps?.placement && tooltipOffset[tooltipProps.placement as string]) || undefined}
          {...tooltipProps}
        >
          <TooltipContainer className={clsx(s.Text__tooltipContainer, classes?.tooltipContainer)}>
            <BsInfoCircle />
          </TooltipContainer>
        </Tooltip>
      )}
    </TextTag>
  );
};
