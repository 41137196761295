import { useUser } from '../../entities/user';
import { useAuth } from '../../app/providers/auth-apollo';

// todo надо проверить, что это работает нормально после обновления авторизации
export const useFetchWithTokenRefresh = () => {
  const { getToken } = useAuth();
  const { user } = useUser();

  const fetchWithTokenRefresh = async (url: string, body: any): Promise<Response> => {
    const token = await getToken();

    if (!token) {
      throw new Error('Unauthorized');
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Sendico-Language': `${user?.selectedLanguage}`,
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(body)
    };

    return await fetch(url, options);
  };

  return { fetchWithTokenRefresh };
};
