import { Link } from 'react-router-dom';
import React from 'react';
import clsx from 'clsx';
import s from './LinksListItem.module.scss';

export type LinksListItemProps = {
  /**
   * Дополнительный класс для ссылки
   **/
  className?: string;
  /**
   * Название ссылки
   **/
  name: string;
  /**
   * url ссылки
   **/
  path: string;
  /**
   * Является ли ссылка внешней от фронтенда
   **/
  external?: boolean;
  /**
   * Опциональная функция, пробрасываемая в ссылку
   **/
  onClick?: () => void;
};

export const LinksListItem = ({ className, name, path, external, onClick }: LinksListItemProps) => {
  return (
    <>
      {external ? (
        <a href={path} target="_blank" rel="noopener noreferrer" className={clsx(s.LinksListItem, className)}>
          {name}
        </a>
      ) : (
        <Link to={path} className={clsx(s.LinksListItem, className)} onClick={onClick}>
          {name}
        </Link>
      )}
    </>
  );
};
