import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useCart } from '../../../store/cart';
import { useUi } from '../../../store/ui';
import { ReactFCC } from '../../../common/utils/helperTypes';
import { handleDefaultError } from '../../../common/utils/handleDefaultError';
import { getGraphqlErrorMessage } from '../../../common/utils/graphqlErrors';
import {
  AuthModalEnterCode,
  AuthModalEnterEmail,
  AuthModalValues,
  UserDataModal,
  AuthModalEnterEmailValues,
  AuthModalEnterCodeValues,
  AuthModal,
  AuthModalStep
} from '../components';
import { useAuth } from '../../../app/providers/auth-apollo';
import { useUser } from '../../../entities/user';
import { GoogleTagEvents, googleTagSendDefaultEvent } from '../../../features/analytics';
import { useWebviewFCMToken } from '../../../features/user/fcmToken/hooks/useWebviewFCMToken';

export const Auth: ReactFCC = () => {
  const { isAuthenticated, requestCode, login } = useAuth();
  const { authModalActive, userDataModalActive, toggleAuthModal, toggleUserDataModal } = useUi();
  const { updateCart } = useCart();
  const { registerFCMToken } = useWebviewFCMToken();

  const {
    user,
    loading: userLoading,
    refetch: fetchUser
  } = useUser({
    skip: !isAuthenticated,
    fetchPolicy: 'network-only'
  });

  const [currentStep, setCurrentStep] = useState<AuthModalStep>(AuthModalStep.ENTER_EMAIL);

  const [enteredEmail, setEnteredEmail] = useState<string>();
  const [confirmError, setConfirmError] = useState<boolean>(false);

  const isSignUp = !!user && !user.registered && !userLoading;

  const closeAuthModal = useCallback(
    (withLogout = true, toggleDataModal = false) => {
      if (toggleDataModal) {
        toggleUserDataModal(true);
      }
      toggleAuthModal(false);
      setCurrentStep(AuthModalStep.ENTER_EMAIL);
      setConfirmError(false);
    },
    [toggleAuthModal, toggleUserDataModal, setCurrentStep, setConfirmError]
  );

  const onSubmit = useCallback(
    async (values: AuthModalValues) => {
      switch (currentStep) {
        case AuthModalStep.ENTER_EMAIL:
          try {
            const { email } = values as AuthModalEnterEmailValues;
            await requestCode(email);
            setEnteredEmail(email);
            setCurrentStep(AuthModalStep.ENTER_CODE);
            googleTagSendDefaultEvent(GoogleTagEvents.send_code);
          } catch (e) {
            const graphqlErrorMessage = getGraphqlErrorMessage(e);
            handleDefaultError(graphqlErrorMessage || 'Something went wrong, please reload the page');
          }
          break;
        case AuthModalStep.ENTER_CODE:
          if (enteredEmail) {
            try {
              const { code } = values as AuthModalEnterCodeValues;

              await login(enteredEmail, code);
              googleTagSendDefaultEvent(GoogleTagEvents.login);
              registerFCMToken();
              const user = await fetchUser();
              await updateCart();
              closeAuthModal(false, !user?.data?.user?.registered);
            } catch (e) {
              const graphqlErrorMessage = getGraphqlErrorMessage(e);
              handleDefaultError(graphqlErrorMessage || 'Something went wrong, please reload the page');
              setConfirmError(true);
            }
          }
          break;
      }
    },
    // todo
    // eslint-disable-next-line react-hooks/exhaustive-deps

    [currentStep, enteredEmail, requestCode, login, closeAuthModal, fetchUser, updateCart, registerFCMToken]
  );

  const resendCode = useCallback(async () => {
    if (enteredEmail) {
      try {
        await requestCode(enteredEmail);

        toast.success('The code successfully resent');
        googleTagSendDefaultEvent(GoogleTagEvents.send_code);
      } catch (e) {
        const errorText = getGraphqlErrorMessage(e);

        if (errorText) {
          toast.error(errorText);
        }

        console.error(e);
      }
    }
  }, [enteredEmail, requestCode]);

  const authModalStepElement = useMemo(() => {
    switch (currentStep) {
      case AuthModalStep.ENTER_EMAIL:
        return <AuthModalEnterEmail onSubmit={onSubmit} />;
      case AuthModalStep.ENTER_CODE:
        return (
          <AuthModalEnterCode
            error={confirmError}
            email={enteredEmail}
            onSubmit={onSubmit}
            onGoBack={() => setCurrentStep(AuthModalStep.ENTER_EMAIL)}
            resendCode={resendCode}
          />
        );
    }
  }, [currentStep, enteredEmail, onSubmit, resendCode, confirmError]);

  useEffect(() => {
    if (isSignUp) {
      toggleUserDataModal(true);
    } else {
      toggleUserDataModal(false);
    }
  }, [isSignUp, toggleUserDataModal]);

  // todo вообще не уверен, что это нужно (и то что ниже)
  // useEffect(() => {
  //   if (!user || !authInfo) {
  //     return;
  //   }
  //
  //   // TODO добавить поле roles в getUser
  //   // const hasUserSeller = user.roles.includes('SELLER');
  //   // const hasAuthInfoSeller = authInfo.roles.includes('SELLER');
  //
  //   if (user.sellerVerification !== authInfo.sellerVerification) {
  //     refreshToken();
  //   }
  // }, [user, authInfo, refreshToken]);

  // useEffect(() => {
  //   updateProfile(user as User);
  //   // todo
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);

  // useEffect(() => {
  //   updateProfileLoading(userLoading);
  //   // todo
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userLoading]);

  return (
    <>
      <AuthModal isOpen={authModalActive} onClose={closeAuthModal} step={currentStep}>
        {authModalStepElement}
      </AuthModal>

      <UserDataModal
        isOpen={isAuthenticated && userDataModalActive}
        onClose={() => toggleUserDataModal(false)}
        email={user?.email}
        isSignUp={isSignUp}
        initialValues={{
          name: isSignUp ? '' : user?.name || '',
          secondName: isSignUp ? '' : user?.secondName || '',
          nickname: isSignUp ? '' : user?.nickname || ''
        }}
      />
    </>
  );
};
