import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { AvailableLanguagesEnum } from '../../../../store/graphql';
import { useLang } from '../../../../store/intl';
import { Loader, LoaderSize } from '../../../../common/components/Loader';
import { useIsMobile } from '../../../../common/hooks/useIsMobile';
import { Divider } from '../../../../common/components/Divider/Divider';
import { ESpaceSize } from '../../../../common/components/Space/Space';
import s from './HeaderNavLang.module.scss';

export interface HeaderNavLangProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Children для расширения контекстного меню
   */
  children?: React.ReactNode;
}

const languageTitle = {
  [AvailableLanguagesEnum.English]: 'En',
  [AvailableLanguagesEnum.Japanese]: '日本語'
};

const languageValues = {
  [AvailableLanguagesEnum.English]: 'English',
  [AvailableLanguagesEnum.Japanese]: '日本語'
};

export const HeaderNavLang: ReactFCC<HeaderNavLangProps> = (props) => {
  const { className, children } = props;

  const isMobile = useIsMobile();
  const { language, languageLoading, changeUserLanguage } = useLang();

  return languageLoading ? (
    <div className={clsx(s.HeaderNavLang, className)}>
      <Loader size={LoaderSize.SMALL} />
    </div>
  ) : (
    <NavDropdown
      className={clsx(s.HeaderNavLang, className)}
      title={languageTitle[language]}
      align={isMobile ? 'end' : 'start'}
    >
      {Object.entries(AvailableLanguagesEnum).map(([key, value], index) => (
        <NavDropdown.Item role="button" onClick={() => changeUserLanguage(key as AvailableLanguagesEnum)} key={index}>
          {languageValues[value]}
        </NavDropdown.Item>
      ))}
      {children && (
        <>
          <Divider space={ESpaceSize.PIXEL_8} />
          <div className={s.HeaderNavLang__extended}>{children}</div>
        </>
      )}
    </NavDropdown>
  );
};
