export const cdnFilenames = {
  aboutCompany: 'about-company.pdf',
  additionalServices: 'additional-services.pdf',
  commercialTransactionsLaw: 'commercial-transactions-law.pdf',
  contacts: 'contacts.pdf',
  faq: 'faq.pdf',
  methodsAndTermsOfDelivery: 'methods-and-terms-of-delivery.pdf',
  paymentCurrency: 'payment-currency.pdf',
  paymentMethods: 'payment-methods.pdf',
  paymentProcessingTime: 'payment-processing-time.pdf',
  privacyPolicy: 'privacy-policy.pdf',
  problemsWithDelivery: 'problems-with-delivery.pdf',
  productProblems: 'product-problems.pdf',
  prohibitedItems: 'prohibited-items.pdf',
  refunds: 'refunds.pdf',
  sellerIdentification: 'seller-identification.pdf',
  sizeAndWeightLimit: 'size-and-weight-limit.pdf',
  support: 'support.pdf',
  systemCommissions: 'system-commissions.pdf',
  termsOfUse: 'terms-of-use.pdf',
  useOfCookies: 'use-of-cookies.pdf',
  withdrawalOfFundsByTheSeller: 'withdrawal-of-funds-by-the-seller.pdf'
};

export type CDNFilenameType = (typeof cdnFilenames)[keyof typeof cdnFilenames];

export function createCDNFileUrl(cdnUrl: string, fileName: CDNFilenameType): string {
  return `${cdnUrl}/${fileName}`;
}
