import React from 'react';
import clsx from 'clsx';
import { Form } from 'react-bootstrap';
import { FormikErrors, useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactFCC } from 'common/utils/helperTypes';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { EBreakpoints, useBreakpoint } from 'common/hooks/useBreakpoint';
import { ProductDocument, useCreateCommentMutation } from 'store/graphql';
import { handleDefaultError } from 'common/utils/handleDefaultError';
import { useAuth } from 'app/providers/auth-apollo';
import { BsSend } from 'react-icons/bs';
import { useProductStore } from '../../../productStore';
import { IconButton, IconButtonVariant } from '../../../../../common/components/Button';
import { LoaderVariant } from '../../../../../common/components/Loader';
import s from './ProductCommentsForm.module.scss';

export type TProductCommentsForm = {
  className?: string;
  parentId?: string;
  productId: string;
};

type TProductCommentsFormValues = {
  commentText: string;
};

const commentMaxLength = 500;

export const ProductCommentsForm: ReactFCC<TProductCommentsForm> = (props) => {
  const { className, productId, parentId } = props;

  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);

  const { commentsLimit, setCommentsLoading } = useProductStore();

  const { isAuthenticated } = useAuth();

  const intl = useIntl();

  const [createCommentMutation, { loading: createCommentLoading }] = useCreateCommentMutation({
    refetchQueries: [
      {
        query: ProductDocument,
        variables: {
          id: productId,
          commentsPagination: {
            offset: 0,
            limit: commentsLimit
          }
        }
      }
    ],
    awaitRefetchQueries: true,
    onCompleted: () => setCommentsLoading(false)
  });

  const formik = useFormik<TProductCommentsFormValues>({
    initialValues: {
      commentText: ''
    },
    validate: (values) => {
      const errors: FormikErrors<TProductCommentsFormValues> = {};
      if (values.commentText.length > commentMaxLength) {
        errors.commentText = 'Comment too long';
      }

      return errors;
    },
    onSubmit: async (values, helpers) => {
      if (values.commentText.length === 0 || !isAuthenticated) {
        return;
      }

      try {
        await createCommentMutation({
          variables: {
            input: {
              productId,
              parentId: parentId ? parentId : undefined,
              commentary: values.commentText
            }
          }
        });

        helpers.resetForm();
      } catch (e) {
        handleDefaultError('Something went wrong. Failed to leave a comment');
      }
    }
  });

  const handleKeyMessageSend = (e: React.KeyboardEvent) => {
    if (e.ctrlKey && e.key === 'Enter' && !createCommentLoading) formik.submitForm();
  };

  return (
    <div className={clsx(s.ProductCommentsForm, className)}>
      <div className={s.ProductCommentsForm__inputContainer}>
        <Form.Control
          className={s.ProductCommentsForm__textarea}
          as="textarea"
          placeholder={intl.formatMessage({ id: 'product.leaveCommentPlaceholder' })}
          name="commentText"
          value={formik.values.commentText}
          onChange={formik.handleChange}
          onKeyDown={(e) => handleKeyMessageSend(e)}
        />
        <IconButton
          className={s.ProductCommentsForm__button}
          onClick={formik.submitForm}
          loading={createCommentLoading}
          loaderVariant={LoaderVariant.primary}
          disabled={!isAuthenticated || !!formik.errors.commentText || createCommentLoading}
          variant={IconButtonVariant.TERTIARY}
          iconSize={19}
          icon={BsSend}
        />
      </div>

      <Form.Text
        className={clsx(
          s.ProductCommentsForm__formTip,
          !!formik.errors.commentText && s.ProductCommentsForm__formTip_error
        )}
        muted
      >
        {formik.values.commentText.length} / {commentMaxLength}
      </Form.Text>

      {!isAuthenticated && (
        <>
          <Space size={ESpaceSize.PIXEL_8} />

          <div>
            <FormattedMessage id="product.leaveCommentLogInError" />
          </div>
        </>
      )}
    </div>
  );
};
