import { InferType, object, string } from 'yup';
export const maxCustomerServiceMessageLength = 600;

const validateObject = {
  message: string().required().max(maxCustomerServiceMessageLength, 'Must be maximum 600 characters long')
};

export const CustomerServiceModalSchema = object(validateObject);

export type TCustomerServiceModalValues = InferType<typeof CustomerServiceModalSchema>;

export const CustomerServiceModalKeys = Object.fromEntries(
  Object.keys(validateObject).map((key) => [key, key])
) as Record<keyof TCustomerServiceModalValues, string>;
