export enum TaskStatus {
  Done = 'Done',
  InProgress = 'InProgress',
  Error = 'Error'
}

export enum FileAction {
  UploadImage = 'UploadImage',
  UploadVideo = 'UploadVideo',
  UploadFile = 'UploadFile'
}

export type Task = {
  id: number;
  status: TaskStatus;
  action: FileAction;
  originalname: string;
  objectname?: string | null;
  bucket: string;
  parameters: string | null;
  created_at: Date;
  updated_at: Date;
};
