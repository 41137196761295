export const FormDataFileKey = 'file';

export type UploadParams = {
  /**
   * Upload file synchronously or asynchronously.
   * If asynchronously will place file at temp folder and return 201.
   * After that, processor will start converting and uploading the file to the S3 Minio.
   * Do not use this field with large files.
   * The user will wait a long time for a response or the request will end with a timeout.
   */
  synchronously?: boolean;
};

export type FileUploadParams = UploadParams;

export enum ImageUploadExt {
  png = 'png',
  jpeg = 'jpeg',
  jpg = 'jpg',
  webp = 'webp'
}

export type ImageUploadParams = UploadParams & {
  /**
   * Quality of target image in range [1, 100]. Default: 80.
   */
  q?: number;
  /**
   * Target extension of the file.
   */
  ext?: ImageUploadExt;
  /**
   * Target image width in pixels.
   */
  w?: number;
  /**
   * Target image height in pixels.
   */
  h?: number;
  /**
   * todo specify
   */
  convert?: boolean;
};

export type VideoUploadParams = UploadParams & {
  /**
   * Array of target video sizes to conversion separated by comma.
   * Height and width should be divisible by 2.
   * Example : [1280x720,680x360]
   */
  s?: string;
  /**
   * Target extension of the file.
   */
  ext?: ImageUploadExt;
  /**
   * todo specify
   */
  convert?: boolean;
};
