import clsx from 'clsx';
import { BsChevronRight } from 'react-icons/bs';
import React from 'react';
import { NavLink, NavLinkProps } from '../../../../common/components/Link';
import { useIsMobile } from '../../../../common/hooks/useIsMobile';
import s from './HelpNavLink.module.scss';

export const HelpNavLink = (props: NavLinkProps) => {
  const { children, className, ...rest } = props;
  const isMobile = useIsMobile();

  return (
    <NavLink
      className={({ isActive }) =>
        clsx(s.HelpNavLink, className, {
          [s.HelpNavLink_active]: isActive
        })
      }
      {...rest}
    >
      {children}
      {isMobile && <BsChevronRight />}
    </NavLink>
  );
};
