import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { Modal } from '../../../../../../common/components/Modal';
import { ImageWithFallback } from '../../../../../../common/components/ImageWithFallback';
import s from './OrderCombineQRModal.module.scss';

export interface OrderCombineQRModalProps {
  /**
   * Состояние открытого/закрытого модального окна
   */
  isOpen: boolean;
  /**
   * Действие при закрытии модального окна
   */
  onClose: () => void;
  /**
   * Опциональный дополнительный класс на компонент
   */
  className?: string;
  /**
   * Base64 QR-code
   */
  qrDataURL?: string;
}

export const OrderCombineQRModal = (props: OrderCombineQRModalProps) => {
  const { isOpen, onClose, className, qrDataURL } = props;

  const intl = useIntl();

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal
      className={clsx(s.OrderCombineQRModal, className)}
      classes={{ mobileContainer: s.OrderCombineQRModal__container }}
      title={intl.formatMessage({ id: 'orderCombine.qr.shipmentCode' })}
      isOpen={isOpen}
      onClose={closeModal}
    >
      <Modal.Body className={s.OrderCombineQRModal__content}>
        <ImageWithFallback src={qrDataURL} size={300} />
      </Modal.Body>
    </Modal>
  );
};
