import { toast } from 'react-toastify';

export const handleDefaultError = (message: string, err?: any) => {
  // const errMsg = getGraphqlErrorMessage(err);

  if (err) {
    handleDefaultErrorLog(err);
  }

  toast.error(message);
  // toast.error(joinNonEmpty([message, errMsg], '\n\n'));
};

export const handleDefaultErrorLog = (err: any) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(err);
  }
};
