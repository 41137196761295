import { RouteObject } from 'react-router-dom';
import React from 'react';
import { NotFoundPage } from '../notFound/NotFoundPage';
import {
  CALCULATOR_ROUTE,
  COMMISSION_ROUTE,
  DELIVERY_ROUTE,
  MANUALS_ROUTE,
  PAYMENT_ROUTE,
  PROHIBITED_ITEMS_ROUTE,
  REFUNDS_ROUTE,
  SIZE_LIMIT_ROUTE,
  withRoutes
} from '../../common/utils/routes';
import { ManualsLayout } from './_layout/ManualsLayout';
import { PaymentPage } from './payment/PaymentPage';
import { RefundsPage } from './refunds/RefundsPage';
import { CommissionsPage } from './commissions/CommissionsPage';
import { DeliveryPage } from './delivery/DeliveryPage';
import { CalculatorPage } from './calculator/CalculatorPage';
import { ProhibitedItemsPage } from './prohibitedItems/ProhibitedItemsPage';
import { SizeLimitsPage } from './size-limits/SizeLimitsPage';

const routes: RouteObject[] = [
  {
    element: <ManualsLayout />,
    children: [
      {
        index: true,
        element: <PaymentPage />
      },
      {
        path: PAYMENT_ROUTE.replace(MANUALS_ROUTE, ''),
        element: <PaymentPage />
      },
      {
        path: COMMISSION_ROUTE.replace(MANUALS_ROUTE, ''),
        element: <CommissionsPage />
      },
      {
        path: REFUNDS_ROUTE.replace(MANUALS_ROUTE, ''),
        element: <RefundsPage />
      },
      {
        path: DELIVERY_ROUTE.replace(MANUALS_ROUTE, ''),
        element: <DeliveryPage />
      },
      {
        path: CALCULATOR_ROUTE.replace(MANUALS_ROUTE, ''),
        element: <CalculatorPage />
      },
      {
        path: PROHIBITED_ITEMS_ROUTE.replace(MANUALS_ROUTE, ''),
        element: <ProhibitedItemsPage />
      },
      {
        path: SIZE_LIMIT_ROUTE.replace(MANUALS_ROUTE, ''),
        element: <SizeLimitsPage />
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  }
];

export const ManualsRoutes = withRoutes(routes);
