import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { LoaderBox } from 'common/components/Loader/LoaderBox';
import { BankAccountType } from 'store/graphql';
import React, { useCallback, useState } from 'react';
import { useToggle } from 'common/hooks/useToggle';
import { BankCard, BankCardProps } from '../BankCard';
import { BankCardAdd } from '../BankCardAdd';
import { BankAccountFormModal } from '../BankAccountForm';
import s from './BankCardsLayout.module.scss';

export interface BankCardsLayoutProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Список банковских пресетов пользователя (полученный с api)
   */
  bankAccounts: BankCardProps['bankAccount'][];
  /**
   * Состояние загрузки
   */
  isLoading?: boolean;
  /**
   * ID выбранного аккаунта
   */
  selectedId: BankAccountType['id'];
  /**
   * Сеттер ID выбранного аккаунта
   */
  setSelectedId: React.Dispatch<BankAccountType['id']>;
}

export const BankCardsLayout: ReactFCC<BankCardsLayoutProps> = (props) => {
  const { className, bankAccounts, isLoading, selectedId, setSelectedId } = props;

  const [modalOpen, { set: openModal, unset: closeModal }] = useToggle();

  const [editedBankAccount, setEditedBankAccount] = useState<BankAccountType | null>(null);

  const onCloseModal = useCallback(() => {
    setEditedBankAccount(null);
    closeModal();
  }, [closeModal]);

  if (isLoading) {
    return <LoaderBox />;
  }

  return (
    <div className={clsx(s.BankCardsLayout, className)}>
      {bankAccounts.map((item) => (
        <BankCard
          bankAccount={item}
          interactive={true}
          selected={item.id === selectedId}
          onClick={() => setSelectedId(item.id)}
          onClickEdit={() => {
            setEditedBankAccount(item);
            openModal();
          }}
          key={item.id}
        />
      ))}

      <BankCardAdd onClick={openModal} />

      <BankAccountFormModal
        isOpen={modalOpen}
        onClose={onCloseModal}
        bankAccount={editedBankAccount}
        onAccountSaved={onCloseModal}
      />
    </div>
  );
};
