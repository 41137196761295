import { startTransition, useCallback, useLayoutEffect, useRef } from 'react';
import { useStateWithDeps } from './state';

// todo add mutate second arg "options"
export type UseMutationReturn<TData, TArgs extends any[]> = {
  mutate: (...args: TArgs) => Promise<TData | undefined>;
  data: TData | undefined;
  isMutating: boolean;
  error: Error | undefined; // todo add error handling with custom errors
  reset: () => void;
};

export type UseMutateOptionsConfig = {
  throwOnError?: boolean;
};

export type UseMutationOptions<TData, TArgs extends [any] | []> = {
  fetcher: (...args: TArgs) => TData | Promise<TData>;
  onError?: (error: Error) => void;
  // todo add onSuccess, onError
} & UseMutateOptionsConfig;

const defaultConfig: UseMutateOptionsConfig = {
  throwOnError: true
};

export function useMutation<TData, TArgs extends [any] | []>(
  options: UseMutationOptions<TData, TArgs>
): UseMutationReturn<TData, TArgs> {
  const { fetcher, ...config } = options;

  const fetcherRef = useRef(fetcher);
  const configRef = useRef(config);

  const [stateRef, stateDependencies, setState] = useStateWithDeps({
    data: undefined,
    error: undefined,
    isMutating: false
  });
  const currentState = stateRef.current;

  const mutate = useCallback(
    async (...args: TArgs) => {
      setState({ isMutating: true });

      const config = {
        ...defaultConfig,
        ...configRef.current
      };

      try {
        const data = await fetcherRef.current(...args);

        startTransition(() => {
          setState({ data, isMutating: false });
        });

        return data;
      } catch (error) {
        startTransition(() => {
          setState({ error: error, isMutating: false });
        });

        config.onError?.(error as Error);

        if (config.throwOnError) {
          throw error;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const reset = useCallback(() => {
    setState({ data: undefined, error: undefined, isMutating: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    fetcherRef.current = fetcher;
    configRef.current = config;
  });

  return {
    mutate,
    reset,
    get data() {
      stateDependencies.data = true;
      return currentState.data;
    },
    get isMutating() {
      stateDependencies.isMutating = true;
      return currentState.isMutating;
    },
    get error() {
      stateDependencies.error = true;
      return currentState.error;
    }
  };
}
