import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactFCC } from '../../common/utils/helperTypes';
import { NotFound } from '../../common/components/NotFound/NotFound';
import { HOME_ROUTE } from '../../common/utils/routes';
import { Link } from '../../common/components/Link';
import s from './NotFoundPage.module.scss';

export const NotFoundPage: ReactFCC = () => {
  const intl = useIntl();

  return (
    <div className={s.NotFoundPage}>
      <NotFound title={intl.formatMessage({ id: 'notFound.title' })}>
        <FormattedMessage
          id="notFound.text"
          values={{
            a: (chunks) => <Link to={HOME_ROUTE}>{chunks}</Link>
          }}
        />
      </NotFound>
    </div>
  );
};
