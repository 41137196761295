import clsx from 'clsx';
import React from 'react';
import { compareAsc } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { ReactFCC } from '../../../../common/utils/helperTypes';
import { UserAddressType } from '../../../../store/graphql';
import { AddressAddButton, AddressAddButtonVariant, AddressCard, AddressCardProps } from '../../components';
import { Heading, HeadingSize } from '../../../../common/components/Heading';
import { useIsMobile } from '../../../../common/hooks/useIsMobile';
import { UserAddress } from '../../types';
import s from './AddressLayout.module.scss';

export interface AddressLayoutProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Дополнительные css-классы для дочерних элементов
   * * items - список адресов
   * * item - карточка адреса
   * * button - кнопка добавления нового адреса
   */
  classes?: {
    items?: string;
    item?: string;
    button?: string;
  };
  /**
   * Вариант отображения, при котором кнопка при наличии адресов имеет управщенный вариант
   * и отображается в правом углу (на мобилке так же снизу).
   * Используется в информации пользователя в ProfilePage
   */
  dynamicAddButton?: boolean;
  /**
   * Массив объектов адресов, полученный с api
   */
  addresses: UserAddress[];
  /**
   * Состояние интерактивности, визуальное сопровождение при наведении на карточку
   */
  interactive?: AddressCardProps['interactive'];
  /**
   * ID выбранного адреса
   */
  selectedAddressId?: UserAddress['id'] | null;
  /**
   * Сеттер для ID выбранного адреса
   */
  setSelectedAddressId?: React.Dispatch<React.SetStateAction<UserAddressType['id'] | null>>;
  /**
   * Обработчик нажатия на кнопку открытия формы (новый адрес или редактирование)
   */
  onClickOpenForm?: (address: UserAddress | null) => void;
  /**
   * Опциональный флаг выключения всех кнопок в компоненте
   */
  buttonDisabled?: boolean;
  /**
   * Опциональный флаг выключения хедера
   */
  noHeading?: boolean;
}

/**
 * Компонент, отображающий адреса в виде плитки (списка на мобильных устройствах)
 */
export const AddressLayout: ReactFCC<AddressLayoutProps> = (props) => {
  const {
    className,
    classes,
    dynamicAddButton,
    addresses,
    interactive,
    selectedAddressId,
    setSelectedAddressId,
    buttonDisabled,
    onClickOpenForm,
    noHeading
  } = props;

  const isMobile = useIsMobile();

  const addButtonVariant =
    !dynamicAddButton || addresses.length === 0 ? AddressAddButtonVariant.DEFAULT : AddressAddButtonVariant.SIMPLE;

  return (
    <div
      className={clsx(s.AddressLayout, className, {
        [s.AddressLayout_dynamicAddButton]: dynamicAddButton
      })}
    >
      <div className={noHeading ? s.AddressLayout__no_heading : s.AddressLayout__heading}>
        {!noHeading && (
          <Heading size={isMobile ? HeadingSize.H6 : HeadingSize.H5}>
            <FormattedMessage id={'address.title'} />
          </Heading>
        )}
        <AddressAddButton
          className={clsx(s.AddressLayout__addButton, classes?.button)}
          variant={addButtonVariant}
          disabled={buttonDisabled}
          onClick={() => {
            onClickOpenForm?.(null);
          }}
        />
      </div>
      {addresses.length !== 0 && (
        <div className={clsx(s.AddressLayout__items, classes?.items)}>
          {addresses
            .slice()
            .sort((a, b) => compareAsc(new Date(a.lastModified), new Date(b.lastModified)))
            .map((address, index) => (
              <AddressCard
                className={clsx(classes?.item)}
                address={address}
                interactive={interactive && !address.invalid}
                selected={selectedAddressId === address.id}
                onClick={() => setSelectedAddressId?.(address.id)}
                onClickEdit={() => {
                  if (!buttonDisabled) onClickOpenForm?.(address);
                }}
                key={index}
              />
            ))}
        </div>
      )}
    </div>
  );
};
