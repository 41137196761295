import React from 'react';
import { useFormik } from 'formik';
import { useUpdateEffect } from 'react-use';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';
import { Form } from 'react-bootstrap';
import { FormInput } from '../../../../../common/components/inputs';
import { useSendNewCustomerServiceMessageMutation } from '../../../../../store/graphql';
import { ReactFCC } from '../../../../../common/utils/helperTypes';
import { getGraphqlErrorMessage } from '../../../../../common/utils/graphqlErrors';
import { Modal } from '../../../../../common/components/Modal';
import { ESpaceSize } from '../../../../../common/components/Space/Space';
import { handleDefaultError } from '../../../../../common/utils/handleDefaultError';
import { ButtonVariant } from '../../../../../common/components/Button';
import { useAuth } from '../../../../../app/providers/auth-apollo';
import {
  CustomerServiceModalKeys,
  CustomerServiceModalSchema,
  maxCustomerServiceMessageLength,
  TCustomerServiceModalValues
} from './customerServiceModalSchema';
import s from './CustomerServiceModal.module.scss';

export interface TCustomerServiceModal {
  /**
   * Состояние открытого/закрытого модального окна
   */
  isOpen: boolean;
  /**
   * Действие при закрытии модального окна
   */
  onClose: () => void;
  /**
   * ID заказа в Arisora
   */
  orderID?: string;
  /**
   * Начальные значения в инпутах
   */
  initialValues?: TCustomerServiceModalValues;
  /**
   * Опциональный дополнительный класс на компонент
   */
  className?: string;
}

export const CustomerServiceModal: ReactFCC<TCustomerServiceModal> = (props: TCustomerServiceModal) => {
  const { isOpen, onClose, initialValues, orderID, className } = props;
  const intl = useIntl();

  const { isAuthenticated } = useAuth();

  const [sendCustomerServiceMessage] = useSendNewCustomerServiceMessageMutation();

  const formik = useFormik<TCustomerServiceModalValues>({
    initialValues: {
      message: initialValues?.message || ''
    },
    validationSchema: CustomerServiceModalSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, helpers) => {
      try {
        if (!isAuthenticated) {
          return handleDefaultError(intl.formatMessage({ id: 'order.customerServiceSignInError' }));
        }

        const success = await sendCustomerServiceMessage({
          variables: {
            input: {
              message: values.message,
              orderID
            }
          }
        });
        helpers.resetForm();
        if (success) {
          toast.success(intl.formatMessage({ id: 'order.customerServiceSuccess' }));
          closeForm();
        }
      } catch (e: any) {
        const errorText = getGraphqlErrorMessage(e);
        if (errorText) {
          handleDefaultError(errorText);
        }
      }
    }
  });

  useUpdateEffect(() => {
    if (initialValues) {
      formik.setValues(initialValues);
    }
  }, [initialValues]);

  const closeForm = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Modal
      className={clsx(className)}
      title={intl.formatMessage({ id: 'order.contextMenu.customerService' })}
      isOpen={isOpen}
      onClose={closeForm}
    >
      <Modal.Body>
        <FormInput
          as={'textarea'}
          name={CustomerServiceModalKeys.message}
          placeholder={intl.formatMessage({ id: 'order.customerServicePlaceholder' })}
          classes={{ input: s.CustomerServiceModal__textArea }}
          value={formik.values.message}
          onChange={formik.handleChange}
          error={formik.errors.message}
          space={{ bottom: ESpaceSize.PIXEL_6 }}
          maxLength={maxCustomerServiceMessageLength}
        />
        <Form.Text className={s.ComplainModal__formTip} muted>
          {formik.values.message?.length} / {maxCustomerServiceMessageLength}
        </Form.Text>
      </Modal.Body>

      <Modal.Footer className={s.CustomerServiceModal__footer}>
        <Modal.Button onClick={formik.submitForm} loading={formik.isSubmitting}>
          Send
        </Modal.Button>
        <Modal.Button onClick={closeForm} variant={ButtonVariant.SECONDARY}>
          <FormattedMessage id={'general.back'} />
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};
