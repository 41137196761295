import { useMemo, useState, useEffect } from 'react';
import vars from '../../app/styles/_vars_export.module.scss';

export enum EBreakpoints {
  // down
  SM_DOWN,
  MD_DOWN,
  LG_DOWN,
  XL_DOWN,
  XXL_DOWN,
  // up
  LG_UP
}

export const mediaQueries: Record<EBreakpoints, string> = {
  [EBreakpoints.SM_DOWN]: `(max-width: ${parseFloat(vars.breakpointSm) - 0.02}px)`, // <576
  [EBreakpoints.MD_DOWN]: `(max-width: ${parseFloat(vars.breakpointMd) - 0.02}px)`, // <768
  [EBreakpoints.LG_DOWN]: `(max-width: ${parseFloat(vars.breakpointLg) - 0.02}px)`, // <992 *
  [EBreakpoints.LG_UP]: `(min-width: ${parseFloat(vars.breakpointLg)}px)`, // >992 *
  [EBreakpoints.XL_DOWN]: `(max-width: ${parseFloat(vars.breakpointXl) - 0.02}px)`, // <1200
  [EBreakpoints.XXL_DOWN]: `(max-width: ${parseFloat(vars.breakpointXxl) - 0.02}px)` // <1400
};

export function useBreakpoint(breakpoint: EBreakpoints) {
  const mediaQueryString = mediaQueries[breakpoint];

  const mediaQueryList = useMemo(() => {
    return window.matchMedia(mediaQueryString);
  }, [mediaQueryString]);

  const [queryResult, setQueryResult] = useState(mediaQueryList.matches);

  useEffect(() => {
    const handleMediaQueryListChange = (e: MediaQueryListEvent) => setQueryResult(e.matches);

    mediaQueryList.addListener(handleMediaQueryListChange);
    return () => mediaQueryList.removeListener(handleMediaQueryListChange);
  }, [mediaQueryList]);

  return queryResult;
}
