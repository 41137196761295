import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AddressDirectoryType = {
  __typename?: 'AddressDirectoryType';
  addresses: Array<AddressType>;
  countries: Array<CountryType>;
  prefecturesJp: Array<PrefectureJpType>;
  source: Scalars['String'];
};

export type AddressDirectoryTypeAddressesArgs = {
  input: AddressInput;
};

export type AddressDirectoryTypeCountriesArgs = {
  input?: InputMaybe<CountryCommonInput>;
};

export type AddressDirectoryTypePrefecturesJpArgs = {
  input?: InputMaybe<PrefecturesJpCommonInput>;
};

export type AddressInput = {
  countryCode: Scalars['String'];
  zipCode: Scalars['String'];
};

export type AddressType = {
  __typename?: 'AddressType';
  allAddress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  countryCode: Scalars['String'];
  postcode: Scalars['String'];
  prefecture?: Maybe<Scalars['String']>;
  prefectureId?: Maybe<Scalars['Int']>;
  province?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
};

export enum AddressesSortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export enum ArisoraOrderStatus {
  Canceled = 'Canceled',
  CustomsClearance = 'CustomsClearance',
  Delivered = 'Delivered',
  DeliveringWithinJapan = 'DeliveringWithinJapan',
  Destroyed = 'Destroyed',
  InternationalDelivery = 'InternationalDelivery',
  OnPickup = 'OnPickup',
  Preparing = 'Preparing',
  ReadyForShipment = 'ReadyForShipment'
}

export type AttachmentIdsInput = {
  idList?: InputMaybe<Array<Scalars['Int']>>;
  msfilesUidList?: InputMaybe<Array<Scalars['String']>>;
};

export type AttachmentType = {
  __typename?: 'AttachmentType';
  filename: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  size: Scalars['Float'];
  thumbnails?: Maybe<Thumbnails>;
  type: Scalars['String'];
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export enum AttachmentUploadStatus {
  Done = 'Done',
  Error = 'Error',
  InProgress = 'InProgress',
  Pending = 'Pending'
}

export type AttachmentWhereUniqueInput = {
  id?: InputMaybe<Array<Scalars['Int']>>;
  msfilesUid?: InputMaybe<Array<Scalars['String']>>;
};

export type AuthResponseType = {
  __typename?: 'AuthResponseType';
  accessToken: Scalars['String'];
  accessTokenExpiresAt?: Maybe<Scalars['DateTime']>;
  refreshToken: RefreshToken;
  user: UserAuthForm;
};

export enum AuthSellerVerificationEnum {
  Rejected = 'Rejected',
  UnderReview = 'UnderReview',
  Verified = 'Verified'
}

export enum AvailableLanguagesEnum {
  English = 'English',
  Japanese = 'Japanese'
}

export type BankAccountType = {
  __typename?: 'BankAccountType';
  account_number: Scalars['String'];
  account_type: BankAccountTypeEnum;
  branch_code?: Maybe<Scalars['String']>;
  branch_hiragana?: Maybe<Scalars['String']>;
  branch_name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  organization_code: Scalars['String'];
  organization_hiragana: Scalars['String'];
  organization_name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  user_at_id: Scalars['String'];
  user_name: Scalars['String'];
  user_name_hiragana: Scalars['String'];
};

export enum BankAccountTypeEnum {
  CurrentAccount = 'CurrentAccount',
  OrdinaryDeposit = 'OrdinaryDeposit',
  SavingsDeposit = 'SavingsDeposit'
}

export type BankBranchType = {
  __typename?: 'BankBranchType';
  branch_code: Scalars['String'];
  branch_hiragana: Scalars['String'];
  branch_name: Scalars['String'];
};

export type BankBranches = {
  __typename?: 'BankBranches';
  bankBranches: Array<BankBranchType>;
};

export type BankOrganizations = {
  __typename?: 'BankOrganizations';
  bankOrganizations: Array<BankType>;
};

export type BankType = {
  __typename?: 'BankType';
  organization_code: Scalars['String'];
  organization_hiragana: Scalars['String'];
  organization_name: Scalars['String'];
};

export type Banner = {
  __typename?: 'Banner';
  active: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  files?: Maybe<Array<AttachmentType>>;
  id: Scalars['String'];
  lastModified: Scalars['DateTime'];
  name: Scalars['String'];
  redirectUrl?: Maybe<Scalars['String']>;
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type BrandSearchInput = {
  /** Включенные в выборку идентификаторы. */
  ids?: InputMaybe<Array<Scalars['String']>>;
  /** Строка для поиска в названии бренда. */
  nameContains?: InputMaybe<Scalars['String']>;
  /** Идентификаторы брендов которые нужно исключить из выборки. */
  notInIds?: InputMaybe<Array<Scalars['String']>>;
  pagination?: InputMaybe<PaginationInput>;
};

export type BrandSearchResult = {
  __typename?: 'BrandSearchResult';
  brands: Array<Brand>;
  pagination: PaginationType;
};

export type BrandsResult = {
  __typename?: 'BrandsResult';
  entries: Array<Brand>;
  pagination: PaginationType;
  /** Всего брендов без фильтрации */
  totalUnfilteredBrands: Scalars['Int'];
};

export type CalculateFixedDeliveryFeeInput = {
  dimensions: Scalars['Float'];
  weight: Scalars['Float'];
};

export type Cart = {
  __typename?: 'Cart';
  cartItemGroups: Array<CartItemsGroup>;
  pagination: PaginationType;
};

export type CartInput = {
  cartItems: Array<CartItemInput>;
};

export type CartItem = {
  __typename?: 'CartItem';
  itemPosition: Scalars['String'];
  lastModified: Scalars['DateTime'];
  productId: Scalars['String'];
  sellerId: Scalars['String'];
  userId: Scalars['String'];
};

export type CartItemInput = {
  productId: Scalars['String'];
};

export type CartItemsGroup = {
  __typename?: 'CartItemsGroup';
  cartItems: Array<CartItem>;
  deliveryDaysMax: Scalars['Int'];
  deliveryDaysMin: Scalars['Int'];
  sellerId: Scalars['String'];
  sellerName: Scalars['String'];
  sellerNickname: Scalars['String'];
  sellerTrusted: Scalars['Boolean'];
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['String'];
  index: Scalars['Int'];
  lastModified: Scalars['DateTime'];
  name: Scalars['String'];
  nameJp: Scalars['String'];
  productIds?: Maybe<Array<Scalars['String']>>;
  subCategoryIds: Array<Scalars['String']>;
  subSubCategoryIds?: Maybe<Array<Scalars['String']>>;
};

export type ChangeOrderAcceptanceStatusInput = {
  accepted: Scalars['Boolean'];
  orderId: Scalars['String'];
};

export type ChangeTicketStatusInput = {
  status: TicketStatusEnum;
  ticketId: Scalars['Int'];
};

export type CheckAvailabilityOutput = {
  __typename?: 'CheckAvailabilityOutput';
  options: Array<CheckAvailabilityOutputOptions>;
  requestTimestamp: Scalars['String'];
};

export type CheckAvailabilityOutputOptions = {
  __typename?: 'CheckAvailabilityOutputOptions';
  accessTime?: Maybe<FedexTimeObject>;
  available: Scalars['Boolean'];
  carrier: Scalars['String'];
  countryRelationship: Scalars['String'];
  cutOffTime?: Maybe<Scalars['String']>;
  defaultLatestTimeOptions: Scalars['String'];
  defaultReadyTime: Scalars['String'];
  earlyAccessTime?: Maybe<FedexTimeObject>;
  earlyCutOffTime?: Maybe<Scalars['String']>;
  latestTimeOptions?: Maybe<Array<Scalars['String']>>;
  pickupDate: Scalars['String'];
  readyTimeOptions?: Maybe<Array<Scalars['String']>>;
  scheduleDay: Scalars['String'];
};

export type CheckPickupAvailabilityInput = {
  carriers: Array<Scalars['String']>;
  countryCode: Scalars['String'];
  countryRelationship: Scalars['String'];
  dispatchDate: Scalars['String'];
  pickupRequestType?: InputMaybe<Array<PickupRequestTypeEnum>>;
  postalCode: Scalars['String'];
};

export type Color = {
  __typename?: 'Color';
  code: Scalars['String'];
  colorName: Scalars['String'];
  colorNameJp: Scalars['String'];
  id: Scalars['String'];
  lastModified: Scalars['DateTime'];
  productIds?: Maybe<Array<Scalars['String']>>;
};

export type CommentReplies = {
  __typename?: 'CommentReplies';
  entries: Array<CommentReply>;
};

export type CommentReply = {
  __typename?: 'CommentReply';
  commentary: Scalars['String'];
  commentator: Scalars['String'];
  createdDate: Scalars['DateTime'];
  id: Scalars['String'];
  isSeller: Scalars['Boolean'];
  lastModified: Scalars['DateTime'];
  parentId?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  signature: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type ComplaintTypes = {
  __typename?: 'ComplaintTypes';
  /** Массив типов жалоб на товар */
  productComplaintTypes: Array<ProductComplaintType>;
};

export type ConfirmOrderPackagesSendingInput = {
  orderIds: Array<Scalars['String']>;
  pickupUuid: Scalars['String'];
};

export type ConfirmVerificationSmsInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  sms_code: Scalars['String'];
};

export type CountryCommonInput = {
  filter?: InputMaybe<CountryFilter>;
  sort?: InputMaybe<CountrySort>;
};

export type CountryFilter = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CountrySort = {
  name?: InputMaybe<AddressesSortDirection>;
  nameJp?: InputMaybe<AddressesSortDirection>;
  population?: InputMaybe<AddressesSortDirection>;
};

export type CountryType = {
  __typename?: 'CountryType';
  alpha2: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  nameJp: Scalars['String'];
  population: Scalars['Float'];
  supportsZipCode: Scalars['Boolean'];
};

export type CreateBankAccountPresetInput = {
  account_number: Scalars['String'];
  account_type: BankAccountTypeEnum;
  branch_code?: InputMaybe<Scalars['String']>;
  branch_hiragana?: InputMaybe<Scalars['String']>;
  branch_name?: InputMaybe<Scalars['String']>;
  organization_code: Scalars['String'];
  organization_hiragana: Scalars['String'];
  organization_name: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type CreateCustomerServiceMessageInput = {
  /** Сообщение пользователя */
  message: Scalars['String'];
  /** Опциональный номер заказа */
  orderID?: InputMaybe<Scalars['String']>;
};

export type CreateOrderPickupInput = {
  closeTime: Scalars['String'];
  orderIds: Array<Scalars['String']>;
  pickupDate: Scalars['String'];
  pickupDateType: PickupRequestTypeEnum;
  sellerAddressId: Scalars['String'];
};

export type CreateProductCommentInput = {
  commentary: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
};

export type CreateProductComplaintInput = {
  /** Комментарий пользователя с причиной жалобы */
  commentary?: InputMaybe<Scalars['String']>;
  /** Uid типа жалобы */
  complaintTypeUid: Scalars['String'];
  /** Id товара, на который создается жалоба */
  productId: Scalars['String'];
};

export type CreatePublicCustomerServiceMessageInput = {
  /** Почта публичного пользователя */
  email: Scalars['String'];
  /** Сообщение пользователя */
  message: Scalars['String'];
  /** Имя публичного пользователя */
  name: Scalars['String'];
};

export type CreateTicketInput = {
  firstMessage: Scalars['String'];
  name: Scalars['String'];
  topic: TicketTopicEnum;
};

export type CreateUploadUrlInput = {
  /** Available actions: [UploadImage, UploadVideo, UploadFile]. */
  action?: FileActionsEnum;
};

export type CreateUploadUrlResult = {
  __typename?: 'CreateUploadUrlResult';
  objectName: Scalars['String'];
  url: Scalars['String'];
};

export type CreateUploadUrlV2Result = {
  __typename?: 'CreateUploadUrlV2Result';
  /** Код загрузки в msfiles для авторизации задачи конвертации. */
  code: Scalars['String'];
  /** Путь на загрузку файла в пакетный обработчик. */
  url: Scalars['String'];
};

export type CreateYamatoShipmentInput = {
  isPickup: Scalars['Boolean'];
  orderId: Scalars['String'];
  sellerAddressId: Scalars['String'];
  shukaDate?: InputMaybe<Scalars['String']>;
  shukaTimeInterval?: InputMaybe<YamatoShukaTimeEnum>;
};

export type Currencies = {
  __typename?: 'Currencies';
  rates: Array<CurrencyRate>;
};

export type CurrencyRate = {
  __typename?: 'CurrencyRate';
  currency: Scalars['String'];
  id: Scalars['String'];
  rate: Scalars['Float'];
};

export type CustomerPublicInfo = {
  __typename?: 'CustomerPublicInfo';
  customer: UserPublic;
  customerRatingValues: Array<CustomerRatingValue>;
};

export type CustomerPublicInfoType = {
  __typename?: 'CustomerPublicInfoType';
  avatar?: Maybe<LocalAttachmentType>;
  customerRating?: Maybe<Rating>;
  id: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  ratingValues?: Maybe<Array<CustomerRatingValue>>;
};

export type CustomerRatingValue = {
  __typename?: 'CustomerRatingValue';
  commentary?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customerId: Scalars['String'];
  id: Scalars['String'];
  lastModified: Scalars['DateTime'];
  orderId?: Maybe<Scalars['String']>;
  sellerId?: Maybe<Scalars['String']>;
  sellerNickname: Scalars['String'];
  signature: Scalars['String'];
  value: RatingValueEnum;
};

export type CustomersType = {
  __typename?: 'CustomersType';
  entries: Array<CustomerPublicInfoType>;
  pagination: PaginationType;
};

export type DebtType = {
  __typename?: 'DebtType';
  amount: Scalars['Float'];
  id: Scalars['Int'];
  order_id: Scalars['String'];
};

export type DeliveryMetaData = {
  __typename?: 'DeliveryMetaData';
  deliveryDaysMax: Scalars['Int'];
  deliveryDaysMin: Scalars['Int'];
  deliveryPrice: Scalars['Float'];
  productIds: Array<Scalars['String']>;
};

export enum DeliverySpeedDaysEnum {
  FromFiveToSeven = 'FromFiveToSeven',
  OneOrTwo = 'OneOrTwo',
  ThreeOrFour = 'ThreeOrFour'
}

export type DeviceTokenAddInput = {
  token: Scalars['String'];
};

export type DraftAttachment = {
  __typename?: 'DraftAttachment';
  attachmentUid: Scalars['String'];
  id: Scalars['Int'];
};

export type DraftAttachmentInput = {
  attachmentUid: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
};

export enum DraftSortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type DraftSpecificValue = {
  __typename?: 'DraftSpecificValue';
  attributeId: Scalars['String'];
  id: Scalars['String'];
  variantId: Scalars['String'];
};

export type DraftSpecificValueInput = {
  attributeId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  variantId: Scalars['String'];
};

export enum EventTypeEnum {
  AdditionalBonus = 'AdditionalBonus',
  AdditionalCosts = 'AdditionalCosts',
  AdditionalPaymentRequired = 'AdditionalPaymentRequired',
  ComplaintFeedback = 'ComplaintFeedback',
  NewCommentary = 'NewCommentary',
  NewOrder = 'NewOrder',
  NewRate = 'NewRate',
  OrderAutoClosed = 'OrderAutoClosed',
  OrderReceived = 'OrderReceived',
  OrderStatusIsCanceled = 'OrderStatusIsCanceled',
  OrderStatusIsProblem = 'OrderStatusIsProblem',
  OrderWasSent = 'OrderWasSent',
  PaymentReceived = 'PaymentReceived',
  ProductBlocked = 'ProductBlocked',
  ResponseToCommentary = 'ResponseToCommentary',
  SellerBecameTrusted = 'SellerBecameTrusted',
  SellerSentOrder = 'SellerSentOrder',
  TrustPointsAddition = 'TrustPointsAddition',
  TrustPointsDecreased = 'TrustPointsDecreased',
  TrustPointsIncreased = 'TrustPointsIncreased',
  TrustedSellerStatusReceived = 'TrustedSellerStatusReceived',
  TrustedSellerStatusRemoved = 'TrustedSellerStatusRemoved',
  VerificationApproved = 'VerificationApproved',
  VerificationRejected = 'VerificationRejected',
  WithdrawalApproved = 'WithdrawalApproved'
}

export enum FedexCategoryEnum {
  Electronics = 'Electronics',
  Garments = 'Garments',
  Jewellery = 'Jewellery',
  Others = 'Others'
}

export enum FedexStatusEnum {
  Failed = 'Failed',
  Initial = 'Initial',
  Pending = 'Pending',
  Success = 'Success'
}

export type FedexTimeObject = {
  __typename?: 'FedexTimeObject';
  hours: Scalars['Int'];
  minutes: Scalars['Int'];
};

export enum FileActionsEnum {
  UploadFile = 'UploadFile',
  UploadImage = 'UploadImage',
  UploadVideo = 'UploadVideo'
}

export type FilterAttachmentsInput = {
  /** Если ничего не передать, то вернет как удаленные, так и не удаленные attachment. */
  deleted?: InputMaybe<Scalars['Boolean']>;
  idList?: InputMaybe<Array<Scalars['Int']>>;
  msfilesUidList?: InputMaybe<Array<Scalars['String']>>;
  titleContains?: InputMaybe<Scalars['String']>;
  uploadStatusList?: Array<AttachmentUploadStatus>;
  userId?: InputMaybe<Scalars['String']>;
};

export type GetAttachmentsResult = {
  __typename?: 'GetAttachmentsResult';
  entries: Array<LocalAttachmentType>;
  pagination: PaginationType;
};

export type GetBankBranchesInput = {
  branch_code?: InputMaybe<Scalars['String']>;
  branch_hiragana?: InputMaybe<Scalars['String']>;
  branch_name?: InputMaybe<Scalars['String']>;
  organization_code: Scalars['String'];
};

export type GetBankOrganizationsInput = {
  organization_code?: InputMaybe<Scalars['String']>;
  organization_hiragana?: InputMaybe<Scalars['String']>;
  organization_name?: InputMaybe<Scalars['String']>;
};

export type GetTicketInput = {
  ticketId: Scalars['Int'];
};

export type HsCode = {
  __typename?: 'HsCode';
  code: Scalars['String'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type HsCodeUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};

export type HsCodes = {
  __typename?: 'HsCodes';
  entries: Array<HsCode>;
  pagination: PaginationType;
};

export type HsCodesInput = {
  filter?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<HsCodesPaginationInput>;
  sort?: InputMaybe<HsCodesSortInput>;
};

export type HsCodesPaginationInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export enum HsCodesSortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type HsCodesSortInput = {
  createdAt?: InputMaybe<HsCodesSortDirection>;
  updatedAt?: InputMaybe<HsCodesSortDirection>;
};

export enum KuronekoPacketEnum {
  One = 'One',
  Three = 'Three',
  Two = 'Two'
}

export enum LocalAttachmentKindEnum {
  File = 'File',
  Image = 'Image',
  Video = 'Video'
}

export type LocalAttachmentThumbnailsType = {
  __typename?: 'LocalAttachmentThumbnailsType';
  /** Миниатюра 1200x1200 */
  L?: Maybe<LocalFileType>;
  /** Миниатюра 640x640 */
  M?: Maybe<LocalFileType>;
  /** Миниатюра 200x200 */
  S?: Maybe<LocalFileType>;
  /** Attachment ID */
  id: Scalars['Int'];
};

export type LocalAttachmentType = {
  __typename?: 'LocalAttachmentType';
  /** Текст для alt атрибута (SEO) */
  alt_text?: Maybe<Scalars['String']>;
  /** Видео в альтернативных форматах. Например: 720p, 360p. */
  alt_video_files?: Maybe<Array<LocalFileType>>;
  created_at: Scalars['DateTime'];
  /** Обозначает удален или нет attachment. Те attachment, которые отмечены deleted, будут навсегда удалены через некоторое время. Это сделано для возможности восстановления attachments после удаления. */
  deleted: Scalars['Boolean'];
  id: Scalars['Int'];
  /** Основной файл attachment */
  main_file?: Maybe<LocalFileType>;
  main_file_id?: Maybe<Scalars['Int']>;
  /** Уникальный идентификатор attachment. Используется для получения attachment после загрузки файла в пакетный обработчик. */
  msfiles_uid: Scalars['String'];
  /** Превью attachment (видео) */
  preview_file?: Maybe<LocalFileType>;
  preview_file_id?: Maybe<Scalars['Int']>;
  processing: Scalars['Boolean'];
  /** Размер всего attachment в байтах */
  size?: Maybe<Scalars['String']>;
  /** Поле содержит миниатюры изображений и превью к видео. Если оригинальное изображение меньше чем thumbnail, то thumbnail не создается. Например, у картинки 1080x1920 нет thumbnail 1200x1200 (L), но есть остальные (S, M). */
  thumbnails?: Maybe<LocalAttachmentThumbnailsType>;
  /** Понятное пользователю название файла */
  title?: Maybe<Scalars['String']>;
  type: LocalAttachmentKindEnum;
  updated_at: Scalars['DateTime'];
  upload_status: AttachmentUploadStatus;
  user_id?: Maybe<Scalars['String']>;
};

export type LocalFileType = {
  __typename?: 'LocalFileType';
  /** Minio bucket в котором хранится файл */
  bucket: Scalars['String'];
  created_at: Scalars['DateTime'];
  /** Высота изображения/видео */
  height?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** Уникальное имя объекта в minio */
  objectname: Scalars['String'];
  /** Название файла при изначальной загрузке */
  originalname: Scalars['String'];
  /** Ссылка на загрузку файла */
  url?: Maybe<Scalars['String']>;
  /** Ширина изображения/видео */
  width?: Maybe<Scalars['Int']>;
};

export type Marketplace = {
  __typename?: 'Marketplace';
  banners: Array<Banner>;
  brands: BrandsResult;
  categories: Array<Category>;
  colors: Array<Color>;
  products: Products;
  sign: Scalars['String'];
  specificAttributes: Array<SpecificAttributeWithVariants>;
  subCategories: Array<SubCategory>;
  subSubCategories: Array<SubSubCategory>;
};

export type MarketplaceBrandsArgs = {
  input?: InputMaybe<BrandSearchInput>;
};

export type MarketplaceCategoriesArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
};

export type MarketplaceColorsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
};

export type MarketplaceProductsArgs = {
  input?: InputMaybe<ProductsPaginationWithFiltersInput>;
};

export type MarketplaceSpecificAttributesArgs = {
  subSubCategoryId?: InputMaybe<Scalars['String']>;
};

export type MarketplaceSubCategoriesArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
};

export type MarketplaceSubSubCategoriesArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addItemToCart: Scalars['Boolean'];
  addProduct: Product;
  addProductToFavorites: Scalars['Boolean'];
  addPushTokenToUser: Scalars['Boolean'];
  calculateShippingFee: Scalars['Boolean'];
  cancelOrderPickup: Scalars['Boolean'];
  changeAcceptance: Scalars['Boolean'];
  changeStatus: WarehouseChangeStatusResponse;
  changeTicketStatus: Scalars['Boolean'];
  changeUserLanguage: Scalars['Boolean'];
  clearCart: Scalars['Boolean'];
  confirmDebtPayment: Scalars['Boolean'];
  confirmDeliveryPrice: Scalars['Boolean'];
  confirmOrder: Scalars['String'];
  confirmOrderDelivering: Scalars['Boolean'];
  confirmOrderPackaging: Scalars['Boolean'];
  confirmPackagesSending: Scalars['Boolean'];
  confirmPackagesSendingWarehouse: Scalars['Boolean'];
  confirmUserByCode?: Maybe<AuthResponseType>;
  confirmVerificationSms: Scalars['Boolean'];
  createBankAccountPreset: BankAccountType;
  createComment: Scalars['Boolean'];
  createCommentReply: Scalars['Boolean'];
  /** Mutation creates download presigned url for download file from minio. */
  createDownloadUrl: Scalars['String'];
  createFakeSeller: Scalars['String'];
  createPickupRequest: Scalars['String'];
  createProductComplaint: Scalars['Boolean'];
  createShipment: Scalars['Boolean'];
  createStripeSetupSession: Scalars['String'];
  createTicket: Ticket;
  /** Мутация используется для загрузки в minio напрямую. Файлы никак не обрабатываются.Ссылка на загруженный файл передается в Airtable. Так Airtable загружает файлы из открытых источников. */
  createUploadUrl: CreateUploadUrlResult;
  /** Мутация создания ссылки на загрузки файл в minio через пакетный обработчик. Отличие от первой версии в том что загрузка идет не напрямую в minio, а сначала происходит конвертация файла, создание thumbnail, preview, видео в альтернативных разрешениях. После конвертации `Attachment` и его файлы (`File`) сохраняются в БД. */
  createUploadUrlV2: CreateUploadUrlV2Result;
  createUserAddress: UserAddressType;
  createYamatoShipment: Scalars['Boolean'];
  /** Мутация отмечает attachments к удалению. Удаление происходит раз в 30 минут. Настоящее удаление происходит не раньше чем через 60 минут после отметки. В течение этого времени можно вызвать `Mutation.restoreAttachments()` чтобы восстановить attachments. */
  deleteAttachments: Scalars['Boolean'];
  deleteBankAccountPreset: Scalars['Boolean'];
  deleteUserAddress: Scalars['Boolean'];
  detachPaymentMethod: Scalars['Boolean'];
  fillUserMetadataFields: User;
  logoutAllDevices: Scalars['Boolean'];
  logoutUser: Scalars['Boolean'];
  makeWithdrawRequest: Scalars['Boolean'];
  markAllOrdinaryNotificationsAsRead: Scalars['Boolean'];
  markNotificationAsRead: Scalars['Boolean'];
  moveToFavorites: Scalars['Boolean'];
  rateCustomer: Scalars['Boolean'];
  rateSeller: Scalars['Boolean'];
  recalculateDeliveryPrice: Scalars['Boolean'];
  regenerateTokens?: Maybe<AuthResponseType>;
  registerCustomer: Scalars['String'];
  registerSeller: Scalars['String'];
  removeAllProductsFromFavorites: Scalars['Boolean'];
  removeItemFromCart: Scalars['Boolean'];
  removeProduct: Scalars['Boolean'];
  removeProductDraft: Scalars['Boolean'];
  removeProductDraftAttachment: Scalars['Boolean'];
  removeProductDraftsBySeller: Scalars['Boolean'];
  removeProductFromFavorites: Scalars['Boolean'];
  removeProfile: Scalars['Boolean'];
  /** Мутация восстанавливает удаленные attachments.` */
  restoreAttachments: Scalars['Boolean'];
  sendNewCustomerServiceMessage: Scalars['Boolean'];
  sendNewPublicCustomerServiceMessage: Scalars['Boolean'];
  sendOrder: WarehouseSendOrderResponse;
  sendTestSms: Scalars['String'];
  sendVerificationSms: Scalars['Boolean'];
  setNotificationSubscription: Scalars['Boolean'];
  signAdminIn?: Maybe<Scalars['String']>;
  signInOrRegister?: Maybe<Scalars['String']>;
  updateAttachment: Scalars['Boolean'];
  updateBankAccountPreset: BankAccountType;
  updateCart: Scalars['Boolean'];
  updateHsCode: Scalars['Boolean'];
  updateProduct: Product;
  updateUserAddress: UserAddressType;
  upsertPaymentIntent: Scalars['String'];
  upsertProductDraft: ProductDraft;
};

export type MutationAddItemToCartArgs = {
  productId: Scalars['String'];
};

export type MutationAddProductArgs = {
  input: ProductInput;
};

export type MutationAddProductToFavoritesArgs = {
  productId: Scalars['String'];
};

export type MutationAddPushTokenToUserArgs = {
  input: DeviceTokenAddInput;
};

export type MutationCalculateShippingFeeArgs = {
  input: OrderSellerAddressInput;
};

export type MutationCancelOrderPickupArgs = {
  orderIds: Array<Scalars['String']>;
  pickupUuid: Scalars['String'];
};

export type MutationChangeAcceptanceArgs = {
  input: ChangeOrderAcceptanceStatusInput;
};

export type MutationChangeStatusArgs = {
  input: WarehouseChangeStatusInput;
};

export type MutationChangeTicketStatusArgs = {
  input: ChangeTicketStatusInput;
};

export type MutationConfirmDebtPaymentArgs = {
  debtId: Scalars['String'];
};

export type MutationConfirmDeliveryPriceArgs = {
  input: OrderConfirmDeliveryPriceInput;
};

export type MutationConfirmOrderArgs = {
  input: OrderConfirmationInput;
};

export type MutationConfirmOrderDeliveringArgs = {
  orderId: Scalars['String'];
};

export type MutationConfirmOrderPackagingArgs = {
  OrderPackagingInput: OrderPackagingInput;
  orderId: Scalars['String'];
};

export type MutationConfirmPackagesSendingArgs = {
  input: ConfirmOrderPackagesSendingInput;
};

export type MutationConfirmPackagesSendingWarehouseArgs = {
  input: OrderParcelsInput;
};

export type MutationConfirmUserByCodeArgs = {
  input: UserConfirmInput;
};

export type MutationConfirmVerificationSmsArgs = {
  input: ConfirmVerificationSmsInput;
};

export type MutationCreateBankAccountPresetArgs = {
  input: CreateBankAccountPresetInput;
};

export type MutationCreateCommentArgs = {
  input: CreateProductCommentInput;
};

export type MutationCreateCommentReplyArgs = {
  input: CreateProductCommentInput;
};

export type MutationCreateDownloadUrlArgs = {
  objectName: Scalars['String'];
};

export type MutationCreateFakeSellerArgs = {
  additionalEmail: Scalars['String'];
};

export type MutationCreatePickupRequestArgs = {
  input: CreateOrderPickupInput;
};

export type MutationCreateProductComplaintArgs = {
  input: CreateProductComplaintInput;
};

export type MutationCreateShipmentArgs = {
  orderId: Scalars['String'];
  sellerAddressId: Scalars['String'];
};

export type MutationCreateStripeSetupSessionArgs = {
  input: StripePathsInput;
};

export type MutationCreateTicketArgs = {
  input: CreateTicketInput;
};

export type MutationCreateUploadUrlArgs = {
  filename: Scalars['String'];
};

export type MutationCreateUploadUrlV2Args = {
  input: CreateUploadUrlInput;
};

export type MutationCreateUserAddressArgs = {
  input: UserAddressCreateInput;
};

export type MutationCreateYamatoShipmentArgs = {
  input: CreateYamatoShipmentInput;
};

export type MutationDeleteAttachmentsArgs = {
  input: AttachmentIdsInput;
};

export type MutationDeleteBankAccountPresetArgs = {
  where: WhereBankAccountPresetInput;
};

export type MutationDeleteUserAddressArgs = {
  userAddressId: Scalars['String'];
};

export type MutationFillUserMetadataFieldsArgs = {
  input: UserCredentialsInput;
};

export type MutationLogoutUserArgs = {
  token: Scalars['String'];
};

export type MutationMakeWithdrawRequestArgs = {
  input: WithdrawRequestInput;
};

export type MutationMarkNotificationAsReadArgs = {
  notificationId: Scalars['Int'];
};

export type MutationMoveToFavoritesArgs = {
  productId: Scalars['String'];
};

export type MutationRateCustomerArgs = {
  rateInput: RatingInput;
};

export type MutationRateSellerArgs = {
  rateInput: RatingInput;
};

export type MutationRecalculateDeliveryPriceArgs = {
  input: OrderRecalculateDeliveryPriceInput;
};

export type MutationRegenerateTokensArgs = {
  token: Scalars['String'];
};

export type MutationRegisterSellerArgs = {
  input: SellerCredentialsInput;
};

export type MutationRemoveItemFromCartArgs = {
  productId: Scalars['String'];
};

export type MutationRemoveProductArgs = {
  productId: Scalars['String'];
};

export type MutationRemoveProductDraftArgs = {
  input: ProductDraftRemoveInput;
};

export type MutationRemoveProductDraftAttachmentArgs = {
  input: ProductDraftAttachmentRemoveInput;
};

export type MutationRemoveProductFromFavoritesArgs = {
  productId: Scalars['String'];
};

export type MutationRestoreAttachmentsArgs = {
  input: AttachmentIdsInput;
};

export type MutationSendNewCustomerServiceMessageArgs = {
  input: CreateCustomerServiceMessageInput;
};

export type MutationSendNewPublicCustomerServiceMessageArgs = {
  input: CreatePublicCustomerServiceMessageInput;
};

export type MutationSendOrderArgs = {
  input: WarehouseSendOrderInput;
};

export type MutationSendTestSmsArgs = {
  input: SendTestSmsInput;
};

export type MutationSendVerificationSmsArgs = {
  input: SendVerificationSmsInput;
};

export type MutationSetNotificationSubscriptionArgs = {
  input: SetNotificationSubscriptionInput;
};

export type MutationSignAdminInArgs = {
  input: UserSignInInput;
};

export type MutationSignInOrRegisterArgs = {
  input: UserSignInInput;
};

export type MutationUpdateAttachmentArgs = {
  data: UpdateAttachmentDataInput;
  where: AttachmentWhereUniqueInput;
};

export type MutationUpdateBankAccountPresetArgs = {
  data: UpdateBankAccountPresetData;
  where: WhereBankAccountPresetInput;
};

export type MutationUpdateCartArgs = {
  input: CartInput;
};

export type MutationUpdateHsCodeArgs = {
  input: HsCodeUpdateInput;
};

export type MutationUpdateProductArgs = {
  input: ProductUpdateInput;
};

export type MutationUpdateUserAddressArgs = {
  input: UserAddressUpdateInput;
};

export type MutationUpsertPaymentIntentArgs = {
  debtId?: InputMaybe<Scalars['String']>;
};

export type MutationUpsertProductDraftArgs = {
  input: ProductDraftUpsertInput;
};

export type NotificationCommonInput = {
  filter?: InputMaybe<NotificationsFilter>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<NotificationSortInput>;
};

export enum NotificationRateEnum {
  Negative = 'Negative',
  Neutral = 'Neutral',
  Positive = 'Positive'
}

export enum NotificationSortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type NotificationSortInput = {
  createdAt?: InputMaybe<NotificationSortDirection>;
  priority?: InputMaybe<NotificationSortDirection>;
  read?: InputMaybe<NotificationSortDirection>;
};

export enum NotificationSubscriptionEnum {
  DisableAll = 'DisableAll',
  EnableAll = 'EnableAll',
  EnableOnlyImportant = 'EnableOnlyImportant'
}

export type NotificationType = {
  __typename?: 'NotificationType';
  createdAt: Scalars['DateTime'];
  event: EventTypeEnum;
  id: Scalars['Int'];
  orderUrl?: Maybe<Scalars['String']>;
  priority: Scalars['Boolean'];
  productUrl?: Maybe<Scalars['String']>;
  rate?: Maybe<NotificationRateEnum>;
  read: Scalars['Boolean'];
  text: Scalars['String'];
  userId: Scalars['String'];
  userRole: NotificationUserRoleEnum;
};

export enum NotificationUserRoleEnum {
  Customer = 'Customer',
  Seller = 'Seller'
}

export type Notifications = {
  __typename?: 'Notifications';
  entries: Array<NotificationType>;
  pagination: PaginationType;
};

export type NotificationsFilter = {
  event?: InputMaybe<EventTypeEnum>;
  orderIds?: InputMaybe<Array<Scalars['String']>>;
  priority?: InputMaybe<Scalars['Boolean']>;
  read?: InputMaybe<Scalars['Boolean']>;
};

export type OcSpecificValueType = {
  __typename?: 'OcSpecificValueType';
  attributeName: Scalars['String'];
  id: Scalars['String'];
  lastModified: Scalars['DateTime'];
  orderContentId?: Maybe<Scalars['String']>;
  signature: Scalars['String'];
  variantValue: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  ID: Scalars['String'];
  IDWithOrderDate: Scalars['String'];
  accepted: Scalars['Boolean'];
  billingAddress?: Maybe<OrderAddress>;
  billingAddressId?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  deliveryCost: Scalars['Float'];
  destinationAddress?: Maybe<OrderAddress>;
  destinationAddressId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isPaid?: Maybe<Scalars['Boolean']>;
  isProblem: Scalars['Boolean'];
  japaneseOrder?: Maybe<Scalars['Boolean']>;
  lastModified: Scalars['DateTime'];
  orderDate: Scalars['DateTime'];
  ordersContentIds: Array<Scalars['String']>;
  overallDimensions: OrderBoxSizes;
  sellerId?: Maybe<Scalars['String']>;
  sellerIdSnapshot: Scalars['String'];
  sellerName?: Maybe<Scalars['String']>;
  sellerNameSnapshot: Scalars['String'];
  sellerNickname?: Maybe<Scalars['String']>;
  sellerNicknameSnapshot: Scalars['String'];
  shippingFee: Scalars['Float'];
  status: ArisoraOrderStatus;
  totalCost: Scalars['Float'];
  trackingNumber?: Maybe<Scalars['String']>;
  trustedOrder: Scalars['Boolean'];
  userCardLast4: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  userIdSnapshot: Scalars['String'];
  userNameSnapshot: Scalars['String'];
  userNicknameSnapshot: Scalars['String'];
  userPhoneSnapshot: Scalars['String'];
  yamatoShipment?: Maybe<OrderYamatoShipment>;
};

export type OrderAddress = {
  __typename?: 'OrderAddress';
  address?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  countryCode: Scalars['String'];
  countryId: Scalars['String'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['String'];
  lastModified: Scalars['DateTime'];
  orderId: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  prefectureId?: Maybe<Scalars['Int']>;
  province?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  region: Scalars['String'];
  regionCode?: Maybe<Scalars['String']>;
  signature: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type OrderBoxSizes = {
  __typename?: 'OrderBoxSizes';
  boxHeight: Scalars['Float'];
  boxLength: Scalars['Float'];
  boxWidth: Scalars['Float'];
  weight: Scalars['Float'];
};

export type OrderBoxSizesInput = {
  boxHeight: Scalars['Float'];
  boxLength: Scalars['Float'];
  boxWidth: Scalars['Float'];
  weight: Scalars['Float'];
};

export type OrderById = {
  __typename?: 'OrderById';
  content: Array<OrderContent>;
  order: Order;
  packages?: Maybe<OrderPackages>;
  packaging?: Maybe<OrderPackaging>;
  pagination?: Maybe<PaginationType>;
  parcels?: Maybe<OrderParcels>;
};

export type OrderByIdInput = {
  id: Scalars['String'];
  pagination?: InputMaybe<PaginationInput>;
};

export type OrderConfirmDeliveryPriceInput = {
  isTrusted: Scalars['Boolean'];
  kuronekoType?: InputMaybe<OrderContentProductKuronekoEnum>;
  orderId: Scalars['String'];
};

export type OrderConfirmationInput = {
  billingAddressId: Scalars['String'];
  /** Флаг, который позволяет сразу купить товар */
  buyNow?: Scalars['Boolean'];
  deliveryCost: Scalars['Float'];
  productIds?: InputMaybe<Array<Scalars['String']>>;
  shippingAddressId: Scalars['String'];
};

export type OrderContent = {
  __typename?: 'OrderContent';
  boxHeight: Scalars['Float'];
  boxLength: Scalars['Float'];
  boxWidth: Scalars['Float'];
  brandName?: Maybe<Scalars['String']>;
  categoryName: Scalars['String'];
  colorName?: Maybe<Scalars['String']>;
  commission: Scalars['Float'];
  condition: OrderContentProductConditionEnum;
  description: Scalars['String'];
  id: Scalars['String'];
  kuroneko?: Maybe<OrderContentProductKuronekoEnum>;
  lastModified: Scalars['DateTime'];
  mainPhoto?: Maybe<LocalAttachmentType>;
  name: Scalars['String'];
  orderId: Scalars['String'];
  photos?: Maybe<Array<LocalAttachmentType>>;
  price: Scalars['Float'];
  productId: Scalars['String'];
  sellerId: Scalars['String'];
  signature: Scalars['String'];
  specificValues: Array<OcSpecificValueType>;
  status: OrderContentProductStatusEnum;
  subCategoryName: Scalars['String'];
  subSubCategoryName: Scalars['String'];
  unit: Scalars['String'];
  weight: Scalars['Float'];
};

export enum OrderContentProductConditionEnum {
  AlmostUnused = 'AlmostUnused',
  Bad = 'Bad',
  Damaged = 'Damaged',
  LightDamage = 'LightDamage',
  New = 'New',
  SomeDamage = 'SomeDamage'
}

export enum OrderContentProductKuronekoEnum {
  One = 'One',
  Three = 'Three',
  Two = 'Two'
}

export enum OrderContentProductStatusEnum {
  ForSale = 'ForSale',
  Sold = 'Sold'
}

export type OrderPackage = {
  __typename?: 'OrderPackage';
  height: Scalars['Float'];
  id: Scalars['Int'];
  length: Scalars['Float'];
  weight: Scalars['Float'];
  width: Scalars['Float'];
};

export type OrderPackages = {
  __typename?: 'OrderPackages';
  decodedQr?: Maybe<Scalars['String']>;
  deliveryPriceFulfilled?: Maybe<Scalars['Boolean']>;
  fedexStatus?: Maybe<FedexStatusEnum>;
  id: Scalars['Int'];
  kuroneko?: Maybe<OrderContentProductKuronekoEnum>;
  orderId: Scalars['String'];
  packages: Array<OrderPackage>;
  pickupRequestUuid?: Maybe<Scalars['String']>;
};

export type OrderPackaging = {
  __typename?: 'OrderPackaging';
  address?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  carryingDate?: Maybe<Scalars['String']>;
  carryingTime?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  countryCode: Scalars['String'];
  countryId: Scalars['String'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['String'];
  lastModified: Scalars['DateTime'];
  numberOfSeats?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  prefectureId?: Maybe<Scalars['Int']>;
  province?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type OrderPackagingInput = {
  addressId: Scalars['String'];
  carryingDate: Scalars['String'];
  carryingTime: Scalars['String'];
  numberOfSeats: Scalars['Int'];
};

export type OrderParcel = {
  __typename?: 'OrderParcel';
  boxes: Scalars['Int'];
  deliveryCompany: Scalars['String'];
  id: Scalars['Int'];
  trackingNumber: Scalars['String'];
};

export type OrderParcelInput = {
  boxes: Scalars['Int'];
  deliveryCompany: Scalars['String'];
  trackingNumber: Scalars['String'];
};

export type OrderParcels = {
  __typename?: 'OrderParcels';
  addressLines: Scalars['String'];
  id: Scalars['Int'];
  orderID: Scalars['String'];
  parcels: Array<OrderParcel>;
};

export type OrderParcelsInput = {
  addressLines: Scalars['String'];
  isJapaneseOrder: Scalars['Boolean'];
  orderID: Scalars['String'];
  orderId: Scalars['String'];
  parcels: Array<OrderParcelInput>;
};

export type OrderRecalculateDeliveryPriceInput = {
  force: Scalars['Boolean'];
  isTrusted: Scalars['Boolean'];
  kuronekoType?: InputMaybe<OrderContentProductKuronekoEnum>;
  newSizes: OrderBoxSizesInput;
  orderId: Scalars['String'];
  sellerAddressId: Scalars['String'];
  shippingFee: Scalars['Float'];
};

export type OrderReferenceNumberCheck = {
  __typename?: 'OrderReferenceNumberCheck';
  referenceNumber: Scalars['Boolean'];
  status?: Maybe<FedexStatusEnum>;
};

export type OrderSellerAddressInput = {
  orderId: Scalars['String'];
  sellerAddressId: Scalars['String'];
};

export type OrderTrackingInput = {
  orderId: Scalars['String'];
};

export type OrderWithContents = {
  __typename?: 'OrderWithContents';
  contents: Array<OrderContent>;
  order: Order;
  packaging?: Maybe<OrderPackaging>;
  pagination?: Maybe<PaginationType>;
};

export type OrderYamatoShipment = {
  __typename?: 'OrderYamatoShipment';
  id: Scalars['Int'];
  isPickup?: Maybe<Scalars['Boolean']>;
  qrCode?: Maybe<LocalAttachmentType>;
  reserveDateLimit?: Maybe<Scalars['String']>;
  reservePassword: Scalars['String'];
  shukaDate?: Maybe<Scalars['DateTime']>;
  shukaTime?: Maybe<YamatoShukaTimeEnum>;
  slipNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Orders = {
  __typename?: 'Orders';
  entries: Array<OrderWithContents>;
  pagination: PaginationType;
};

export type OrdersInput = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  pagination?: InputMaybe<PaginationInput>;
};

export enum PackageActionEnum {
  Destroyed = 'Destroyed',
  Problem = 'Problem',
  Repack = 'Repack',
  Repacked = 'Repacked',
  Return = 'Return',
  Returned = 'Returned',
  Solved = 'Solved'
}

export type PaginationInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type PaginationType = {
  __typename?: 'PaginationType';
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PhotoInput = {
  /** Уникальный идентификатор attachment. Можно получить после загрузки файла через msfiles. */
  attachment_uid: Scalars['String'];
  /** Флаг обозначающий главную фотографию товара */
  main?: Scalars['Boolean'];
};

export type PickupAvailabilityOption = {
  __typename?: 'PickupAvailabilityOption';
  accessTime: Scalars['Int'];
  cutOffTime: Scalars['String'];
  date: Scalars['String'];
  timesFrom: Array<TimeRecord>;
  timesUntil: Array<TimeRecord>;
};

export enum PickupRequestTypeEnum {
  FutureDay = 'FUTURE_DAY',
  SameDay = 'SAME_DAY',
  TestDay = 'TEST_DAY'
}

export type PrefectureJpType = {
  __typename?: 'PrefectureJpType';
  id: Scalars['Int'];
  population: Scalars['Float'];
  prefectureEn: Scalars['String'];
  prefectureJp: Scalars['String'];
};

export type PrefecturesJpCommonInput = {
  filter?: InputMaybe<PrefecturesJpFilter>;
  sort?: InputMaybe<PrefecturesJpSort>;
};

export type PrefecturesJpFilter = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type PrefecturesJpSort = {
  name?: InputMaybe<AddressesSortDirection>;
  nameJp?: InputMaybe<AddressesSortDirection>;
  population?: InputMaybe<AddressesSortDirection>;
};

export type PriceFilter = {
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
};

export type Product = {
  __typename?: 'Product';
  boxHeight?: Maybe<Scalars['Float']>;
  boxLength?: Maybe<Scalars['Float']>;
  boxWidth?: Maybe<Scalars['Float']>;
  brandId?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  categoryId: Scalars['String'];
  categoryName: Scalars['String'];
  colorCode?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  colorName?: Maybe<Scalars['String']>;
  comments: ProductComments;
  condition: ProductConditionEnum;
  created: Scalars['DateTime'];
  deliverySpeedDays: DeliverySpeedDaysEnum;
  description?: Maybe<Scalars['String']>;
  favorite?: Maybe<Scalars['Boolean']>;
  hsCode?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  kuroneko?: Maybe<KuronekoPacketEnum>;
  lastModified: Scalars['DateTime'];
  mainPhoto?: Maybe<LocalAttachmentType>;
  name: Scalars['String'];
  photos?: Maybe<Array<LocalAttachmentType>>;
  price: Scalars['Float'];
  sellerId: Scalars['String'];
  sellerOtherProducts: Array<Product>;
  similarProducts: Array<Product>;
  specificValues?: Maybe<Array<SpecificValueExtended>>;
  status: ProductStatusEnum;
  subCategoryId?: Maybe<Scalars['String']>;
  subCategoryName: Scalars['String'];
  subSubCategoryId?: Maybe<Scalars['String']>;
  subSubCategoryName: Scalars['String'];
  unit: Scalars['String'];
  warehouseNumber: Scalars['Int'];
  weight?: Maybe<Scalars['Float']>;
};

export type ProductCommentsArgs = {
  input?: InputMaybe<PaginationInput>;
};

export type ProductComment = {
  __typename?: 'ProductComment';
  commentary: Scalars['String'];
  commentator: Scalars['String'];
  createdDate: Scalars['DateTime'];
  id: Scalars['String'];
  isSeller: Scalars['Boolean'];
  lastModified: Scalars['DateTime'];
  parentId?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  replies: CommentReplies;
  signature: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type ProductComments = {
  __typename?: 'ProductComments';
  entries: Array<ProductComment>;
  pagination: PaginationType;
};

export type ProductComplaintType = {
  __typename?: 'ProductComplaintType';
  /** Название типа жалобы */
  name: Scalars['String'];
  /** Uid типа жалобы */
  uid: Scalars['String'];
};

export enum ProductConditionEnum {
  AlmostUnused = 'AlmostUnused',
  Bad = 'Bad',
  Damaged = 'Damaged',
  LightDamage = 'LightDamage',
  New = 'New',
  SomeDamage = 'SomeDamage'
}

export type ProductDraft = {
  __typename?: 'ProductDraft';
  attachments?: Maybe<Array<DraftAttachment>>;
  boxHeight?: Maybe<Scalars['Float']>;
  boxLength?: Maybe<Scalars['Float']>;
  boxWidth?: Maybe<Scalars['Float']>;
  brandId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  condition?: Maybe<ProductConditionEnum>;
  deliverySpeedDays?: Maybe<DeliverySpeedDaysEnum>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  mainPhoto?: Maybe<LocalAttachmentType>;
  mainPhotoIndex?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<LocalAttachmentType>>;
  price?: Maybe<Scalars['Int']>;
  sellerId?: Maybe<Scalars['String']>;
  specificValues?: Maybe<Array<DraftSpecificValue>>;
  subCategoryId?: Maybe<Scalars['String']>;
  subSubCategoryId?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
};

export type ProductDraftAttachmentRemoveInput = {
  attachmentUid: Scalars['String'];
};

export type ProductDraftRemoveInput = {
  id: Scalars['Int'];
};

export type ProductDraftUpsertInput = {
  attachments?: InputMaybe<Array<DraftAttachmentInput>>;
  boxHeight?: InputMaybe<Scalars['Float']>;
  boxLength?: InputMaybe<Scalars['Float']>;
  boxWidth?: InputMaybe<Scalars['Float']>;
  brandId?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  colorId?: InputMaybe<Scalars['String']>;
  condition?: InputMaybe<ProductConditionEnum>;
  deliverySpeedDays?: InputMaybe<DeliverySpeedDaysEnum>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mainPhotoIndex?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  sellerId?: InputMaybe<Scalars['String']>;
  specificValues?: InputMaybe<Array<DraftSpecificValueInput>>;
  subCategoryId?: InputMaybe<Scalars['String']>;
  subSubCategoryId?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type ProductDrafts = {
  __typename?: 'ProductDrafts';
  entries: Array<ProductDraft>;
  pagination: PaginationType;
};

export type ProductFilters = {
  brandExcludeIds?: InputMaybe<Array<Scalars['String']>>;
  brandIds?: InputMaybe<Array<Scalars['String']>>;
  categoryId?: InputMaybe<Scalars['String']>;
  colorIds?: InputMaybe<Array<Scalars['String']>>;
  conditions?: InputMaybe<Array<ProductConditionEnum>>;
  favorite?: InputMaybe<Scalars['Boolean']>;
  isShowcase?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<PriceFilter>;
  productIds?: InputMaybe<Array<Scalars['String']>>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sellerId?: InputMaybe<Scalars['String']>;
  specificAttributes?: InputMaybe<Array<SpecificAttributeInput>>;
  status?: InputMaybe<ProductStatusEnum>;
  subCategoryId?: InputMaybe<Scalars['String']>;
  subSubCategoryId?: InputMaybe<Scalars['String']>;
};

export type ProductInput = {
  boxHeight?: InputMaybe<Scalars['Float']>;
  boxLength?: InputMaybe<Scalars['Float']>;
  boxWidth?: InputMaybe<Scalars['Float']>;
  brandId?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  colorId?: InputMaybe<Scalars['String']>;
  condition: ProductConditionEnum;
  deliverySpeedDays: DeliverySpeedDaysEnum;
  description: Scalars['String'];
  kuroneko?: InputMaybe<KuronekoPacketEnum>;
  name: Scalars['String'];
  photos?: InputMaybe<Array<PhotoInput>>;
  price: Scalars['Float'];
  specificValues?: InputMaybe<Array<SpecificValueInput>>;
  status: ProductStatusEnum;
  subCategoryId: Scalars['String'];
  subSubCategoryId: Scalars['String'];
  warehouseNumber?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export enum ProductStatusEnum {
  ForSale = 'ForSale',
  Sold = 'Sold'
}

export type ProductUpdateInput = {
  boxHeight?: InputMaybe<Scalars['Float']>;
  boxLength?: InputMaybe<Scalars['Float']>;
  boxWidth?: InputMaybe<Scalars['Float']>;
  brandId?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  colorId?: InputMaybe<Scalars['String']>;
  condition: ProductConditionEnum;
  deliverySpeedDays: DeliverySpeedDaysEnum;
  description: Scalars['String'];
  id: Scalars['String'];
  kuroneko?: InputMaybe<KuronekoPacketEnum>;
  name: Scalars['String'];
  photos?: InputMaybe<Array<PhotoInput>>;
  price: Scalars['Float'];
  specificValues?: InputMaybe<Array<SpecificValueInput>>;
  status: ProductStatusEnum;
  subCategoryId: Scalars['String'];
  subSubCategoryId: Scalars['String'];
  warehouseNumber?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type Products = {
  __typename?: 'Products';
  entries: Array<Product>;
  pagination: PaginationType;
};

export type ProductsPaginationWithFiltersInput = {
  filters?: InputMaybe<ProductFilters>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sort?: InputMaybe<ProductsSort>;
};

export type ProductsSort = {
  direction?: InputMaybe<SortDirectionEnum>;
  warehouseNumber?: InputMaybe<Scalars['Boolean']>;
};

export type PublicInfoInput = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  pagination?: InputMaybe<PaginationInput>;
};

export type PublicInfoType = {
  __typename?: 'PublicInfoType';
  customers: CustomersType;
  reviews?: Maybe<Reviews>;
  sellers: SellersType;
  source: Scalars['String'];
};

export type PublicInfoTypeCustomersArgs = {
  input?: InputMaybe<PublicInfoInput>;
};

export type PublicInfoTypeReviewsArgs = {
  input: PublicReviewsInput;
};

export type PublicInfoTypeSellersArgs = {
  input?: InputMaybe<PublicInfoInput>;
};

export type PublicReviewsFilters = {
  ratingTypes?: InputMaybe<Array<RatingValueEnum>>;
  reviewFrom?: InputMaybe<Array<ReviewsUserRoleEnum>>;
  sellerId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type PublicReviewsInput = {
  filters: PublicReviewsFilters;
  pagination?: InputMaybe<PaginationInput>;
};

export type Query = {
  __typename?: 'Query';
  addressDirectory: AddressDirectoryType;
  attachments: GetAttachmentsResult;
  authAlive: Scalars['Boolean'];
  billingAlive: Scalars['Boolean'];
  /** @deprecated marketplace.brands */
  brands: BrandsResult;
  calculateFixedDeliveryFee: Scalars['Int'];
  checkCustomerRateForOrder: Scalars['Boolean'];
  checkPickupAvailability: PickupAvailabilityOption;
  checkProductInUserCart: Scalars['Boolean'];
  checkReferenceNumber?: Maybe<OrderReferenceNumberCheck>;
  checkSellerRateForOrder: Scalars['Boolean'];
  complaintTypes: ComplaintTypes;
  coreAlive: Scalars['Boolean'];
  currencies: Currencies;
  findInBrands: BrandSearchResult;
  getAdminTicket: Ticket;
  getAdminTicketMessages: Array<TicketMessage>;
  getAdminTickets: Tickets;
  getBankBranches: BankBranches;
  getBankOrganizations: BankOrganizations;
  /** @deprecated marketplace.banners */
  getBanners: Array<Banner>;
  getCDN: Scalars['String'];
  /** @deprecated marketplace.categories */
  getCategories: Array<Category>;
  /** @deprecated marketplace.categories(ids: [id]) */
  getCategoryById: Category;
  /** @deprecated marketplace.colors(ids: [id]) */
  getColorById: Color;
  /** @deprecated marketplace.colors */
  getColors: Array<Color>;
  /** @deprecated publicInfo.customers */
  getCustomerById: UserPublic;
  /** @deprecated user.orders */
  getCustomerOrders: Orders;
  /** @deprecated publicInfo.customers */
  getCustomerPublicInfo: CustomerPublicInfo;
  /** Возвращает задолженности пользователя. Задолженности могут возникнуть при перерасчете стоимости доставки на складе (уже после подтверждения заказа). */
  getDebts: Array<DebtType>;
  getDeliveryPriceForOrder: DeliveryMetaData;
  getHsCodes: HsCodes;
  getMyBankAccountPresets: Array<BankAccountType>;
  /** @deprecated user.notificationsCount */
  getNotificationCount: Scalars['Int'];
  /** @deprecated user.notifications */
  getNotifications: Notifications;
  /** @deprecated user.orders(ids) | seller.orders(ids) */
  getOrderById?: Maybe<OrderWithContents>;
  getSellerBalance?: Maybe<SellerBalanceType>;
  getSellerById: SellerPublic;
  /** @deprecated publicInfo.sellers */
  getSellerPublicInfo: SellerPublicInfo;
  getSellerWithdrawRequests?: Maybe<WithdrawRequests>;
  getSellers: Array<SellerPublic>;
  /** @deprecated seller.orders */
  getSellersOrders: Orders;
  getSentryDsn: Scalars['String'];
  /** @deprecated marketplace.specificAttributes */
  getSpecificAttributes: Array<SpecificAttributeWithVariants>;
  /** @deprecated marketplace.specificAttributes(subCategoryId) */
  getSpecificAttributesBySubSubCategory: Array<SpecificAttributeWithVariants>;
  /** @deprecated marketplace.subCategories */
  getSubCategories: Array<SubCategory>;
  /** @deprecated marketplace.subCategories(ids: [id]) */
  getSubCategoryById: SubCategory;
  /** @deprecated marketplace.subSubCategories */
  getSubSubCategories: Array<SubSubCategory>;
  /** @deprecated marketplace.subSubCategories(ids: [id]) */
  getSubSubCategoryById: SubSubCategory;
  getTicket: Ticket;
  getTicketMessages: Array<TicketMessage>;
  getTickets: Tickets;
  /** @deprecated user */
  getUser: User;
  /** @deprecated user.cart */
  getUserCart: Cart;
  /** @deprecated user.cartLength */
  getUserCartLength: Scalars['Int'];
  getWalletTransactions: WalletTransactions;
  isFilesAlive: Scalars['Boolean'];
  marketPlace: Marketplace;
  marketPlaceAlive: Scalars['Boolean'];
  orderTracking: TrackingInfo;
  pickupRequestExtended: SellerPickupRequestExtended;
  pickupRequests: Array<SellerPickupRequest>;
  /** @deprecated marketplace.products */
  products: Products;
  publicInfo: PublicInfoType;
  unreadTicketMessagesCount: Scalars['Int'];
  user: User;
  warehouseOrder: WarehouseOrderDataResponse;
  warehouseStatusList: WarehouseOrderStatusesResponse;
};

export type QueryAttachmentsArgs = {
  filter?: InputMaybe<FilterAttachmentsInput>;
  pagination?: PaginationInput;
  sort?: SortAttachmentsInput;
};

export type QueryBrandsArgs = {
  input?: InputMaybe<BrandSearchInput>;
};

export type QueryCalculateFixedDeliveryFeeArgs = {
  input: CalculateFixedDeliveryFeeInput;
};

export type QueryCheckCustomerRateForOrderArgs = {
  orderId: Scalars['String'];
};

export type QueryCheckPickupAvailabilityArgs = {
  input: CheckPickupAvailabilityInput;
};

export type QueryCheckProductInUserCartArgs = {
  productId: Scalars['String'];
};

export type QueryCheckReferenceNumberArgs = {
  orderId: Scalars['String'];
};

export type QueryCheckSellerRateForOrderArgs = {
  orderId: Scalars['String'];
};

export type QueryFindInBrandsArgs = {
  input?: InputMaybe<SearchInput>;
};

export type QueryGetAdminTicketArgs = {
  input: GetTicketInput;
};

export type QueryGetAdminTicketMessagesArgs = {
  input: TicketMessagesInput;
};

export type QueryGetAdminTicketsArgs = {
  input?: InputMaybe<TicketsInput>;
};

export type QueryGetBankBranchesArgs = {
  input: GetBankBranchesInput;
};

export type QueryGetBankOrganizationsArgs = {
  input: GetBankOrganizationsInput;
};

export type QueryGetCategoryByIdArgs = {
  id: Scalars['String'];
};

export type QueryGetColorByIdArgs = {
  id: Scalars['String'];
};

export type QueryGetCustomerByIdArgs = {
  customerId: Scalars['String'];
};

export type QueryGetCustomerOrdersArgs = {
  input?: InputMaybe<PaginationInput>;
};

export type QueryGetCustomerPublicInfoArgs = {
  customerId: Scalars['String'];
};

export type QueryGetDebtsArgs = {
  orderIds?: InputMaybe<Array<Scalars['String']>>;
  paid?: Scalars['Boolean'];
};

export type QueryGetDeliveryPriceForOrderArgs = {
  addressId: Scalars['String'];
  productIds: Array<Scalars['String']>;
};

export type QueryGetHsCodesArgs = {
  input?: InputMaybe<HsCodesInput>;
};

export type QueryGetNotificationCountArgs = {
  input?: InputMaybe<NotificationsFilter>;
};

export type QueryGetNotificationsArgs = {
  input?: InputMaybe<NotificationCommonInput>;
};

export type QueryGetOrderByIdArgs = {
  input: OrderByIdInput;
};

export type QueryGetSellerByIdArgs = {
  sellerId: Scalars['String'];
};

export type QueryGetSellerPublicInfoArgs = {
  sellerId: Scalars['String'];
};

export type QueryGetSellerWithdrawRequestsArgs = {
  input?: InputMaybe<PaginationInput>;
};

export type QueryGetSellersOrdersArgs = {
  input?: InputMaybe<PaginationInput>;
};

export type QueryGetSpecificAttributesBySubSubCategoryArgs = {
  subSubCategoryId: Scalars['String'];
};

export type QueryGetSubCategoryByIdArgs = {
  id: Scalars['String'];
};

export type QueryGetSubSubCategoryByIdArgs = {
  id: Scalars['String'];
};

export type QueryGetTicketArgs = {
  input: GetTicketInput;
};

export type QueryGetTicketMessagesArgs = {
  input: TicketMessagesInput;
};

export type QueryGetTicketsArgs = {
  input?: InputMaybe<TicketsInput>;
};

export type QueryGetUserCartArgs = {
  input?: InputMaybe<PaginationInput>;
};

export type QueryGetWalletTransactionsArgs = {
  input?: InputMaybe<WalletTransactionsInput>;
};

export type QueryOrderTrackingArgs = {
  input: OrderTrackingInput;
};

export type QueryPickupRequestExtendedArgs = {
  pickupUuid: Scalars['String'];
};

export type QueryProductsArgs = {
  input?: InputMaybe<ProductsPaginationWithFiltersInput>;
};

export type QueryWarehouseOrderArgs = {
  id: Scalars['String'];
};

export type Rating = {
  __typename?: 'Rating';
  negative: Scalars['Int'];
  neutral: Scalars['Int'];
  positive: Scalars['Int'];
};

export type RatingInput = {
  commentary?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  ratingValue: RatingValueEnum;
};

export enum RatingValueEnum {
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
  Positive = 'POSITIVE'
}

export type RefreshToken = {
  __typename?: 'RefreshToken';
  expires_at?: Maybe<Scalars['DateTime']>;
  token: Scalars['String'];
  type: Scalars['String'];
  userId: Scalars['String'];
};

export type Review = {
  __typename?: 'Review';
  commentary?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  customerNickname?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastModified: Scalars['DateTime'];
  orderId?: Maybe<Scalars['String']>;
  reviewFrom: ReviewsUserRoleEnum;
  sellerId?: Maybe<Scalars['String']>;
  sellerNickname?: Maybe<Scalars['String']>;
  signature: Scalars['String'];
  value: RatingValueEnum;
};

export type Reviews = {
  __typename?: 'Reviews';
  entries?: Maybe<Array<Review>>;
  pagination: PaginationType;
  totalReviewsByFilter: ReviewsCount;
};

export type ReviewsCount = {
  __typename?: 'ReviewsCount';
  customer?: Maybe<Scalars['Int']>;
  negative: Scalars['Int'];
  neutral: Scalars['Int'];
  positive: Scalars['Int'];
  seller?: Maybe<Scalars['Int']>;
};

export type ReviewsInput = {
  pagination?: InputMaybe<PaginationInput>;
  ratingTypes?: InputMaybe<Array<RatingValueEnum>>;
  reviewsFrom?: InputMaybe<Array<ReviewsUserRoleEnum>>;
};

export enum ReviewsUserRoleEnum {
  Customer = 'Customer',
  Seller = 'Seller'
}

export type ScanEvent = {
  __typename?: 'ScanEvent';
  date: Scalars['String'];
  eventDescription: Scalars['String'];
  eventType: Scalars['String'];
};

export type SearchInput = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  inputRow?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationInput>;
};

export type Seller = {
  __typename?: 'Seller';
  address?: Maybe<UserAddressType>;
  contactNumber?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  drafts: ProductDrafts;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  inactive: Scalars['Boolean'];
  isTrusted: Scalars['Boolean'];
  lastModified: Scalars['DateTime'];
  name: Scalars['String'];
  nameHiragana?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  order: OrderById;
  orders: Orders;
  ratingValues?: Maybe<Array<SellerRatingValue>>;
  secondName?: Maybe<Scalars['String']>;
  secondNameHiragana?: Maybe<Scalars['String']>;
  sellerAddressId?: Maybe<Scalars['String']>;
  sellerID: Scalars['String'];
  sellerRating?: Maybe<Rating>;
  thirdName?: Maybe<Scalars['String']>;
  totalTrustPoints?: Maybe<Scalars['Int']>;
  trustPoints: TrustPoints;
  userId: Scalars['String'];
};

export type SellerDraftsArgs = {
  input?: InputMaybe<SellerDraftsInput>;
};

export type SellerOrderArgs = {
  input: OrderByIdInput;
};

export type SellerOrdersArgs = {
  input?: InputMaybe<OrdersInput>;
};

export type SellerTrustPointsArgs = {
  input?: InputMaybe<TrustPointsInput>;
};

export type SellerAttachment = {
  url: Scalars['String'];
};

export type SellerBalanceType = {
  __typename?: 'SellerBalanceType';
  availableToWithdraw: Scalars['Float'];
  balance: Scalars['Float'];
};

export type SellerCredentialsInput = {
  addressId: Scalars['String'];
  attachments?: InputMaybe<Array<SellerAttachment>>;
  contactNumber?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nameHiragana: Scalars['String'];
  secondName?: InputMaybe<Scalars['String']>;
  secondNameHiragana: Scalars['String'];
  sms_code?: InputMaybe<Scalars['String']>;
  thirdName?: InputMaybe<Scalars['String']>;
};

export type SellerDraftFilters = {
  id?: InputMaybe<Scalars['Int']>;
};

export type SellerDraftSortInput = {
  createdAt?: InputMaybe<DraftSortDirection>;
};

export type SellerDraftsInput = {
  filters?: InputMaybe<SellerDraftFilters>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SellerDraftSortInput>;
};

export type SellerPickupRequest = {
  __typename?: 'SellerPickupRequest';
  active: Scalars['Boolean'];
  addressLines: Scalars['String'];
  latestTime: Scalars['String'];
  ordersAmount: Scalars['Int'];
  pickupAddressId: Scalars['String'];
  pickupDate: Scalars['String'];
  uuid: Scalars['String'];
};

export type SellerPickupRequestExtended = {
  __typename?: 'SellerPickupRequestExtended';
  active: Scalars['Boolean'];
  addressLines: Scalars['String'];
  latestTime: Scalars['String'];
  ordersAmount: Scalars['Int'];
  packages: Array<OrderPackages>;
  pickupAddressId: Scalars['String'];
  pickupDate: Scalars['String'];
  uuid: Scalars['String'];
};

export type SellerPublic = {
  __typename?: 'SellerPublic';
  address?: Maybe<SellerPublicAddress>;
  contactNumber?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  inactive: Scalars['Boolean'];
  isTrusted: Scalars['Boolean'];
  name: Scalars['String'];
  nameHiragana?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  ratingValues?: Maybe<Array<SellerRatingValue>>;
  secondName?: Maybe<Scalars['String']>;
  secondNameHiragana?: Maybe<Scalars['String']>;
  sellerAddressId?: Maybe<Scalars['String']>;
  sellerID: Scalars['String'];
  sellerRating?: Maybe<Rating>;
};

export type SellerPublicAddress = {
  __typename?: 'SellerPublicAddress';
  address?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  countryCode: Scalars['String'];
  countryId: Scalars['String'];
  id: Scalars['String'];
  prefecture?: Maybe<Scalars['String']>;
  prefectureId?: Maybe<Scalars['Int']>;
  province?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
  sellerAddress: Scalars['Boolean'];
  state?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type SellerPublicInfo = {
  __typename?: 'SellerPublicInfo';
  customer: UserPublic;
  customerRatingValues: Array<CustomerRatingValue>;
  seller: SellerPublic;
  sellerRatingValues: Array<SellerRatingValue>;
};

export type SellerPublicInfoType = {
  __typename?: 'SellerPublicInfoType';
  address?: Maybe<SellerPublicAddress>;
  avatar?: Maybe<LocalAttachmentType>;
  contactNumber?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  inactive: Scalars['Boolean'];
  isTrusted: Scalars['Boolean'];
  name: Scalars['String'];
  nameHiragana?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  rating?: Maybe<Rating>;
  ratingValues?: Maybe<Array<SellerRatingValue>>;
  secondName?: Maybe<Scalars['String']>;
  secondNameHiragana?: Maybe<Scalars['String']>;
  sellerAddressId?: Maybe<Scalars['String']>;
  sellerID: Scalars['String'];
  sellerRating?: Maybe<Rating>;
};

export type SellerRatingValue = {
  __typename?: 'SellerRatingValue';
  commentary?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  customerNickname: Scalars['String'];
  id: Scalars['String'];
  lastModified: Scalars['DateTime'];
  orderId?: Maybe<Scalars['String']>;
  sellerId: Scalars['String'];
  signature: Scalars['String'];
  value: RatingValueEnum;
};

export type SellerTrustPoints = {
  __typename?: 'SellerTrustPoints';
  amount: Scalars['Int'];
  commentary?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  lastModified: Scalars['DateTime'];
  orderID: Scalars['String'];
  orderId: Scalars['String'];
  sellerId: Scalars['String'];
  signature: Scalars['String'];
  total: Scalars['Int'];
  trustPointsFeedback: Scalars['Boolean'];
};

export enum SellerVerificationEnum {
  Rejected = 'Rejected',
  UnderReview = 'UnderReview',
  Verified = 'Verified'
}

export type SellersType = {
  __typename?: 'SellersType';
  entries: Array<SellerPublicInfoType>;
  pagination: PaginationType;
};

export type SendTestSmsInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
};

export type SendVerificationSmsInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
};

export type SetNotificationSubscriptionInput = {
  notificationSubscriptionType: NotificationSubscriptionEnum;
};

export type SortAttachmentsInput = {
  created_at?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
  size?: InputMaybe<SortEnum>;
  updated_at?: InputMaybe<SortEnum>;
};

export enum SortDirectionEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export enum SortEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export type SpecificAttributeInput = {
  attributeId: Scalars['String'];
  variantIds: Array<Scalars['String']>;
};

export type SpecificAttributeWithVariants = {
  __typename?: 'SpecificAttributeWithVariants';
  id: Scalars['String'];
  lastModified: Scalars['DateTime'];
  name: Scalars['String'];
  nameJp: Scalars['String'];
  subSubCategoryIds: Array<Scalars['String']>;
  variants: Array<SpecificVariant>;
};

export type SpecificValueExtended = {
  __typename?: 'SpecificValueExtended';
  attributeId?: Maybe<Scalars['String']>;
  attributeName: Scalars['String'];
  id: Scalars['String'];
  lastModified: Scalars['DateTime'];
  productId?: Maybe<Scalars['String']>;
  signature: Scalars['String'];
  variantId?: Maybe<Scalars['String']>;
  variantValue?: Maybe<Scalars['String']>;
};

export type SpecificValueInput = {
  attributeId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  variantId: Scalars['String'];
};

export type SpecificVariant = {
  __typename?: 'SpecificVariant';
  id: Scalars['String'];
  index: Scalars['Int'];
  lastModified: Scalars['DateTime'];
  signature: Scalars['String'];
  specificAttributeId: Scalars['String'];
  value: Scalars['String'];
  valueJp: Scalars['String'];
};

export type StripePathsInput = {
  cancelPath: Scalars['String'];
  successPath: Scalars['String'];
};

export type SubCategory = {
  __typename?: 'SubCategory';
  categoryIds: Array<Scalars['String']>;
  id: Scalars['String'];
  index: Scalars['Int'];
  lastModified: Scalars['DateTime'];
  name: Scalars['String'];
  nameJp: Scalars['String'];
  productIds?: Maybe<Array<Scalars['String']>>;
  subSubCategoryIds?: Maybe<Array<Scalars['String']>>;
};

export type SubSubCategory = {
  __typename?: 'SubSubCategory';
  categoryIds: Array<Scalars['String']>;
  fedexCategory: FedexCategoryEnum;
  hsCode?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  index: Scalars['Int'];
  lastModified: Scalars['DateTime'];
  mandatoryAttribute?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  nameJp: Scalars['String'];
  productIds?: Maybe<Array<Scalars['String']>>;
  subCategoryIds: Array<Scalars['String']>;
};

export type Thumbnail = {
  __typename?: 'Thumbnail';
  height: Scalars['Int'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type Thumbnails = {
  __typename?: 'Thumbnails';
  full: Thumbnail;
  large: Thumbnail;
  small: Thumbnail;
};

export type Ticket = {
  __typename?: 'Ticket';
  createdAt: Scalars['DateTime'];
  messages?: Maybe<Array<TicketMessage>>;
  name: Scalars['String'];
  status: TicketStatusEnum;
  ticketId: Scalars['Int'];
  topic: TicketTopicEnum;
  unreadCount?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type TicketMessage = {
  __typename?: 'TicketMessage';
  body?: Maybe<Scalars['String']>;
  closing: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  image?: Maybe<TicketMessageImage>;
  messageId: Scalars['String'];
  read: Scalars['Boolean'];
  self: Scalars['Boolean'];
  senderId: Scalars['String'];
  senderName: Scalars['String'];
  sequenceStart: Scalars['Boolean'];
};

export type TicketMessageImage = {
  __typename?: 'TicketMessageImage';
  height: Scalars['Int'];
  imageUrl: Scalars['String'];
  width: Scalars['Int'];
};

export type TicketMessagesInput = {
  latestMessageId: Scalars['String'];
  ticketId: Scalars['Int'];
};

export enum TicketStatusEnum {
  Archived = 'Archived',
  Closed = 'Closed',
  Open = 'Open'
}

export enum TicketTopicEnum {
  Account = 'Account',
  Order = 'Order',
  Other = 'Other',
  Payment = 'Payment',
  Product = 'Product',
  Report = 'Report',
  Shipment = 'Shipment',
  Suggestion = 'Suggestion',
  Technical = 'Technical'
}

export type Tickets = {
  __typename?: 'Tickets';
  entries: Array<Ticket>;
  pagination: PaginationType;
};

export type TicketsFilters = {
  status?: InputMaybe<TicketStatusEnum>;
};

export type TicketsInput = {
  filters?: InputMaybe<TicketsFilters>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<TicketsSortInput>;
};

export enum TicketsSortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type TicketsSortInput = {
  createdAt?: InputMaybe<TicketsSortDirection>;
};

export type TimeRecord = {
  __typename?: 'TimeRecord';
  title: Scalars['String'];
  value: Scalars['String'];
};

export type TrackResult = {
  __typename?: 'TrackResult';
  trackResults: Array<TrackResultDetails>;
  trackingNumber: Scalars['String'];
};

export type TrackResultDetails = {
  __typename?: 'TrackResultDetails';
  scanEvents: Array<ScanEvent>;
};

export type TrackingInfo = {
  __typename?: 'TrackingInfo';
  customerTransactionId: Scalars['String'];
  output: TrackingNumbersOutput;
  transactionId: Scalars['String'];
};

export type TrackingNumbersOutput = {
  __typename?: 'TrackingNumbersOutput';
  completeTrackResults: Array<TrackResult>;
};

export type TrustPoints = {
  __typename?: 'TrustPoints';
  entries: Array<SellerTrustPoints>;
  pagination: PaginationType;
};

export type TrustPointsInput = {
  pagination?: InputMaybe<PaginationInput>;
};

export type UpdateAttachmentDataInput = {
  altText?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateBankAccountPresetData = {
  account_number?: InputMaybe<Scalars['String']>;
  account_type?: InputMaybe<BankAccountTypeEnum>;
  branch_code?: InputMaybe<Scalars['String']>;
  branch_hiragana?: InputMaybe<Scalars['String']>;
  branch_name?: InputMaybe<Scalars['String']>;
  organization_code?: InputMaybe<Scalars['String']>;
  organization_hiragana?: InputMaybe<Scalars['String']>;
  organization_name?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  addresses: Array<UserAddressType>;
  /** Актуальная версия аватарки. Используется локальное хранилище вместо Airtable. */
  avatar?: Maybe<LocalAttachmentType>;
  cart: Cart;
  cartItemsCount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  customerRating?: Maybe<Rating>;
  email?: Maybe<Scalars['String']>;
  fakeUser?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  last4numbers?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** Имя пользователя хираганой. Поле сделано для удобства выборки данных. Если есть возможность, лучше использовать query `getSellers`, `getSellerById`, `getSellerPublicInfo` для оптимизации. */
  nameHiragana?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  notificationSubscription?: Maybe<NotificationSubscriptionEnum>;
  notifications: Notifications;
  notificationsCount: Scalars['Int'];
  order: OrderById;
  orders: Orders;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneVerified: Scalars['Boolean'];
  ratingValues?: Maybe<Array<CustomerRatingValue>>;
  registered: Scalars['Boolean'];
  reviews?: Maybe<Reviews>;
  roles?: Maybe<Array<UserRoles>>;
  secondName?: Maybe<Scalars['String']>;
  /** Фамилия пользователя хираганой. Поле сделано для удобства выборки данных. Если есть возможность, лучше использовать query `getSellers`, `getSellerById`, `getSellerPublicInfo` для оптимизации. */
  secondNameHiragana?: Maybe<Scalars['String']>;
  selectedLanguage?: Maybe<AvailableLanguagesEnum>;
  seller?: Maybe<Seller>;
  sellerId?: Maybe<Scalars['String']>;
  /** Seller verification status */
  sellerVerification?: Maybe<SellerVerificationEnum>;
};

export type UserCartArgs = {
  input?: InputMaybe<PaginationInput>;
};

export type UserNotificationsArgs = {
  input?: InputMaybe<NotificationCommonInput>;
};

export type UserNotificationsCountArgs = {
  input?: InputMaybe<NotificationsFilter>;
};

export type UserOrderArgs = {
  input: OrderByIdInput;
};

export type UserOrdersArgs = {
  input?: InputMaybe<OrdersInput>;
};

export type UserReviewsArgs = {
  input?: InputMaybe<ReviewsInput>;
};

export type UserAddressCreateInput = {
  address?: InputMaybe<Scalars['String']>;
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  countryCode: Scalars['String'];
  defaultBilling?: InputMaybe<Scalars['Boolean']>;
  defaultShipping?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  force?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  prefecture?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  provinceCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  regionCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  town?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UserAddressType = {
  __typename?: 'UserAddressType';
  address?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  countryCode: Scalars['String'];
  countryId: Scalars['String'];
  defaultBilling: Scalars['Boolean'];
  defaultShipping: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['String'];
  invalid: Scalars['Boolean'];
  lastModified: Scalars['DateTime'];
  phone?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  prefectureId?: Maybe<Scalars['Int']>;
  province?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
  sellerAddress: Scalars['Boolean'];
  state?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  town?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  zipCode?: Maybe<Scalars['String']>;
};

export type UserAddressUpdateInput = {
  address?: InputMaybe<Scalars['String']>;
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  defaultBilling?: InputMaybe<Scalars['Boolean']>;
  defaultShipping?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  force?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  prefecture?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  provinceCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  regionCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  town?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UserAuthForm = {
  __typename?: 'UserAuthForm';
  id: Scalars['String'];
  roles: Array<Scalars['String']>;
  sellerVerification?: Maybe<AuthSellerVerificationEnum>;
};

export type UserConfirmInput = {
  code: Scalars['String'];
  email: Scalars['String'];
};

export type UserCredentialsInput = {
  address?: InputMaybe<UserAddressCreateInput>;
  metadata?: InputMaybe<UserMetadataInput>;
};

export type UserMetadataInput = {
  /** Уникальный идентификатор attachment, он же идентификатор задачи загрузки `msfiles_uid`. */
  avatarAttachmentUid?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  secondName?: InputMaybe<Scalars['String']>;
};

export type UserPublic = {
  __typename?: 'UserPublic';
  avatar?: Maybe<LocalAttachmentType>;
  customerRating?: Maybe<Rating>;
  id: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
};

export enum UserRoles {
  Admin = 'ADMIN',
  Assistant = 'ASSISTANT',
  Customer = 'CUSTOMER',
  Lister = 'LISTER',
  Manager = 'MANAGER',
  Packager = 'PACKAGER',
  Seller = 'SELLER',
  Staff = 'STAFF',
  Warehouse = 'WAREHOUSE'
}

export type UserSignInInput = {
  email: Scalars['String'];
};

export type WalletTransactionType = {
  __typename?: 'WalletTransactionType';
  amount: Scalars['Float'];
  balance: Scalars['Float'];
  bonusFeedback: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  fineFeedback: Scalars['Boolean'];
  order?: Maybe<OrderWithContents>;
  orderId?: Maybe<Scalars['String']>;
  sellerId: Scalars['String'];
  showDetails: Scalars['Boolean'];
  signature: Scalars['String'];
  transactionType: WalletTransactionTypeEnum;
  transactionUuid: Scalars['String'];
  withdrawRequestId?: Maybe<Scalars['String']>;
};

export enum WalletTransactionTypeEnum {
  ArisoraTax = 'ArisoraTax',
  BonusPayment = 'BonusPayment',
  Fine = 'Fine',
  PayoutFromWr = 'PayoutFromWR',
  RevenueFromOrder = 'RevenueFromOrder'
}

export type WalletTransactions = {
  __typename?: 'WalletTransactions';
  pagination: PaginationType;
  transactions: Array<WalletTransactionType>;
};

export type WalletTransactionsFilter = {
  type?: InputMaybe<WalletTransactionTypeEnum>;
  uuids?: InputMaybe<Array<Scalars['String']>>;
};

export type WalletTransactionsInput = {
  filter?: InputMaybe<WalletTransactionsFilter>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<WalletTransactionsSortInput>;
};

export enum WalletTransactionsSortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type WalletTransactionsSortInput = {
  amount?: InputMaybe<WalletTransactionsSortDirection>;
  created?: InputMaybe<WalletTransactionsSortDirection>;
  type?: InputMaybe<WalletTransactionsSortDirection>;
};

export type WarehouseChangeStatusInput = {
  action: PackageActionEnum;
  comment: Scalars['String'];
  order_id: Scalars['Int'];
};

export type WarehouseChangeStatusResponse = {
  __typename?: 'WarehouseChangeStatusResponse';
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type WarehouseOrderContentAttributes = {
  __typename?: 'WarehouseOrderContentAttributes';
  attribute_name: Scalars['String'];
  attribute_value: Scalars['String'];
};

export type WarehouseOrderContentResponse = {
  __typename?: 'WarehouseOrderContentResponse';
  description: Scalars['String'];
  images: Array<Scalars['String']>;
  item_id: Scalars['String'];
  specific_attributes: Array<WarehouseOrderContentAttributes>;
  weight: Scalars['Float'];
};

export type WarehouseOrderCustomerMetadataResponse = {
  __typename?: 'WarehouseOrderCustomerMetadataResponse';
  address: OrderAddress;
};

export type WarehouseOrderDataResponse = {
  __typename?: 'WarehouseOrderDataResponse';
  arrived_date?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  customer?: Maybe<WarehouseOrderCustomerMetadataResponse>;
  dispatch_date?: Maybe<Scalars['DateTime']>;
  estimated_delivery_price: Scalars['Float'];
  order_contents: Array<WarehouseOrderContentResponse>;
  order_id: Scalars['String'];
  pieces_amount: Scalars['Int'];
  warehouse_action?: Maybe<PackageActionEnum>;
  warehouse_status: WarehouseOrderStatus;
};

export enum WarehouseOrderStatus {
  AwaitingPayment = 'AwaitingPayment',
  DeliveringWithinJapan = 'DeliveringWithinJapan',
  Destroyed = 'Destroyed',
  ForReturn = 'ForReturn',
  InStock = 'InStock',
  InternationalDelivery = 'InternationalDelivery',
  Problem = 'Problem',
  Repack = 'Repack',
  Returned = 'Returned'
}

export type WarehouseOrderStatusesResponse = {
  __typename?: 'WarehouseOrderStatusesResponse';
  awaiting_payment: Array<Scalars['String']>;
  for_repack: Array<Scalars['String']>;
  for_return: Array<Scalars['String']>;
  on_delivery: Array<Scalars['String']>;
  on_warehouse: Array<Scalars['String']>;
  with_problem: Array<Scalars['String']>;
};

export type WarehouseSendOrderInput = {
  estimated_delivery_price: Scalars['Float'];
  order_id: Scalars['String'];
  size: WarehouseSendOrderPackageSizeInput;
  track_number: Scalars['String'];
  weight: Scalars['Float'];
};

export type WarehouseSendOrderPackageSizeInput = {
  h: Scalars['Float'];
  l: Scalars['Float'];
  w: Scalars['Float'];
};

export type WarehouseSendOrderResponse = {
  __typename?: 'WarehouseSendOrderResponse';
  allow_to_send?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type WhereBankAccountPresetInput = {
  id: Scalars['Int'];
};

export type WithdrawRequest = {
  __typename?: 'WithdrawRequest';
  ID: Scalars['String'];
  arisoraTax: Scalars['Float'];
  date: Scalars['DateTime'];
  id: Scalars['String'];
  lastModified: Scalars['DateTime'];
  sellerId: Scalars['String'];
  signature: Scalars['String'];
  status: WithdrawStatusEnum;
  withdrawAmount: Scalars['Float'];
};

export type WithdrawRequestInput = {
  bankAccountPresetId: Scalars['Int'];
  withdrawAmount: Scalars['Float'];
};

export type WithdrawRequests = {
  __typename?: 'WithdrawRequests';
  pagination: PaginationType;
  withdrawRequests: Array<WithdrawRequest>;
};

export enum WithdrawStatusEnum {
  Denied = 'DENIED',
  Review = 'REVIEW',
  Sent = 'SENT'
}

export enum YamatoShukaTimeEnum {
  FromFiveToHalfSix = 'FromFiveToHalfSix',
  FromFourToSix = 'FromFourToSix',
  FromTwoToFour = 'FromTwoToFour',
  NoSpecified = 'NoSpecified',
  OnOne = 'OnOne'
}

export type ThumbnailLargeFragment = {
  __typename?: 'AttachmentType';
  thumbnails?: { __typename?: 'Thumbnails'; large: { __typename?: 'Thumbnail'; url: string } } | null;
};

export type LocalThumbnailAllFragment = {
  __typename?: 'LocalAttachmentType';
  thumbnails?: {
    __typename?: 'LocalAttachmentThumbnailsType';
    id: number;
    S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
    M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
    L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
  } | null;
};

export type PaginationFragment = { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };

export type CreateUploadUrlMutationVariables = Exact<{
  filename: Scalars['String'];
}>;

export type CreateUploadUrlMutation = {
  __typename?: 'Mutation';
  upload: { __typename?: 'CreateUploadUrlResult'; objectName: string; url: string };
};

export type CreateDownloadUrlMutationVariables = Exact<{
  objectName: Scalars['String'];
}>;

export type CreateDownloadUrlMutation = { __typename?: 'Mutation'; createDownloadUrl: string };

export type CustomerOrdersQueryVariables = Exact<{
  input?: InputMaybe<PaginationInput>;
}>;

export type CustomerOrdersQuery = {
  __typename?: 'Query';
  orders: {
    __typename?: 'Orders';
    entries: Array<{
      __typename?: 'OrderWithContents';
      order: {
        __typename?: 'Order';
        id: string;
        ID: string;
        orderDate: any;
        status: ArisoraOrderStatus;
        isProblem: boolean;
        sellerNickname?: string | null;
        deliveryCost: number;
        sellerId: string;
      };
      contents: Array<{
        __typename?: 'OrderContent';
        productId: string;
        id: string;
        name: string;
        price: number;
        colorName?: string | null;
        status: OrderContentProductStatusEnum;
        specificValues: Array<{ __typename?: 'OcSpecificValueType'; attributeName: string; variantValue: string }>;
        mainPhoto?: {
          __typename?: 'LocalAttachmentType';
          id: number;
          thumbnails?: {
            __typename?: 'LocalAttachmentThumbnailsType';
            id: number;
            S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
            M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
            L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          } | null;
        } | null;
      }>;
    }>;
    pagination: { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };
  };
};

export type DebtsQueryVariables = Exact<{
  orderIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  paid?: Scalars['Boolean'];
}>;

export type DebtsQuery = {
  __typename?: 'Query';
  debts: Array<{ __typename?: 'DebtType'; id: number; order_id: string; amount: number }>;
};

export type OrderQueryVariables = Exact<{
  input: OrderByIdInput;
}>;

export type OrderQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'User';
    order: {
      __typename?: 'OrderById';
      order: {
        __typename?: 'Order';
        id: string;
        ID: string;
        orderDate: any;
        status: ArisoraOrderStatus;
        isProblem: boolean;
        japaneseOrder?: boolean | null;
        sellerNickname?: string | null;
        deliveryCost: number;
        isPaid?: boolean | null;
        sellerId: string;
        customerId: string;
        customerNickname: string;
        customerPhone: string;
        customerLast4: string;
        billingAddress?: {
          __typename?: 'OrderAddress';
          title?: string | null;
          address?: string | null;
          address1?: string | null;
          address2?: string | null;
          zipCode?: string | null;
          state?: string | null;
          stateCode?: string | null;
          city?: string | null;
          country: string;
        } | null;
        destinationAddress?: {
          __typename?: 'OrderAddress';
          title?: string | null;
          address?: string | null;
          address1?: string | null;
          address2?: string | null;
          zipCode?: string | null;
          state?: string | null;
          stateCode?: string | null;
          city?: string | null;
          country: string;
          phone?: string | null;
          fullName: string;
        } | null;
      };
      packages?: {
        __typename?: 'OrderPackages';
        id: number;
        orderId: string;
        deliveryPriceFulfilled?: boolean | null;
        decodedQr?: string | null;
        fedexStatus?: FedexStatusEnum | null;
        pickupRequestUuid?: string | null;
        packages: Array<{
          __typename?: 'OrderPackage';
          id: number;
          weight: number;
          height: number;
          width: number;
          length: number;
        }>;
      } | null;
      packaging?: {
        __typename?: 'OrderPackaging';
        carryingDate?: string | null;
        carryingTime?: string | null;
        address?: string | null;
        address1?: string | null;
        city?: string | null;
        country: string;
        id: string;
        numberOfSeats?: number | null;
        orderId?: string | null;
        zipCode?: string | null;
        province?: string | null;
        countryCode: string;
        countryId: string;
        email: string;
        fullName: string;
        region?: string | null;
      } | null;
    };
  };
};

export type OrderContentQueryVariables = Exact<{
  input: OrderByIdInput;
}>;

export type OrderContentQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'User';
    order: {
      __typename?: 'OrderById';
      content: Array<{
        __typename?: 'OrderContent';
        productId: string;
        id: string;
        name: string;
        price: number;
        colorName?: string | null;
        status: OrderContentProductStatusEnum;
        weight: number;
        commission: number;
        specificValues: Array<{ __typename?: 'OcSpecificValueType'; attributeName: string; variantValue: string }>;
        mainPhoto?: {
          __typename?: 'LocalAttachmentType';
          id: number;
          thumbnails?: {
            __typename?: 'LocalAttachmentThumbnailsType';
            id: number;
            S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
            M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
            L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          } | null;
        } | null;
      }>;
      pagination?: { __typename?: 'PaginationType'; offset: number; limit: number; totalCount: number } | null;
    };
  };
};

export type SellerOrderQueryVariables = Exact<{
  input: OrderByIdInput;
}>;

export type SellerOrderQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'User';
    seller?: {
      __typename?: 'Seller';
      order: {
        __typename?: 'OrderById';
        order: {
          __typename?: 'Order';
          id: string;
          ID: string;
          orderDate: any;
          status: ArisoraOrderStatus;
          isProblem: boolean;
          sellerNickname?: string | null;
          japaneseOrder?: boolean | null;
          accepted: boolean;
          trustedOrder: boolean;
          shippingFee: number;
          deliveryCost: number;
          isPaid?: boolean | null;
          sellerId: string;
          customerId: string;
          customerNickname: string;
          customerPhone: string;
          customerLast4: string;
          yamatoShipment?: {
            __typename?: 'OrderYamatoShipment';
            slipNumber?: string | null;
            status?: string | null;
            reserveDateLimit?: string | null;
            reservePassword: string;
            isPickup?: boolean | null;
            shukaDate?: any | null;
            shukaTime?: YamatoShukaTimeEnum | null;
            qrCode?: {
              __typename?: 'LocalAttachmentType';
              main_file?: { __typename?: 'LocalFileType'; url?: string | null } | null;
            } | null;
          } | null;
          overallDimensions: {
            __typename?: 'OrderBoxSizes';
            weight: number;
            boxHeight: number;
            boxWidth: number;
            boxLength: number;
          };
          billingAddress?: {
            __typename?: 'OrderAddress';
            title?: string | null;
            address?: string | null;
            address1?: string | null;
            address2?: string | null;
            zipCode?: string | null;
            state?: string | null;
            stateCode?: string | null;
            city?: string | null;
            country: string;
          } | null;
          destinationAddress?: {
            __typename?: 'OrderAddress';
            title?: string | null;
            address?: string | null;
            address1?: string | null;
            address2?: string | null;
            zipCode?: string | null;
            state?: string | null;
            stateCode?: string | null;
            city?: string | null;
            country: string;
            phone?: string | null;
            fullName: string;
          } | null;
        };
        packages?: {
          __typename?: 'OrderPackages';
          id: number;
          orderId: string;
          deliveryPriceFulfilled?: boolean | null;
          decodedQr?: string | null;
          pickupRequestUuid?: string | null;
          fedexStatus?: FedexStatusEnum | null;
          kuroneko?: OrderContentProductKuronekoEnum | null;
          packages: Array<{
            __typename?: 'OrderPackage';
            id: number;
            weight: number;
            height: number;
            width: number;
            length: number;
          }>;
        } | null;
        packaging?: {
          __typename?: 'OrderPackaging';
          carryingDate?: string | null;
          carryingTime?: string | null;
          address?: string | null;
          address1?: string | null;
          city?: string | null;
          country: string;
          id: string;
          numberOfSeats?: number | null;
          orderId?: string | null;
          zipCode?: string | null;
          province?: string | null;
          countryCode: string;
          countryId: string;
          email: string;
          fullName: string;
          region?: string | null;
        } | null;
        parcels?: {
          __typename?: 'OrderParcels';
          id: number;
          orderID: string;
          addressLines: string;
          parcels: Array<{
            __typename?: 'OrderParcel';
            id: number;
            deliveryCompany: string;
            trackingNumber: string;
            boxes: number;
          }>;
        } | null;
      };
    } | null;
  };
};

export type SellerOrderContentQueryVariables = Exact<{
  input: OrderByIdInput;
}>;

export type SellerOrderContentQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'User';
    seller?: {
      __typename?: 'Seller';
      order: {
        __typename?: 'OrderById';
        content: Array<{
          __typename?: 'OrderContent';
          productId: string;
          id: string;
          name: string;
          price: number;
          colorName?: string | null;
          status: OrderContentProductStatusEnum;
          kuroneko?: OrderContentProductKuronekoEnum | null;
          weight: number;
          boxLength: number;
          boxHeight: number;
          boxWidth: number;
          commission: number;
          specificValues: Array<{ __typename?: 'OcSpecificValueType'; attributeName: string; variantValue: string }>;
          mainPhoto?: {
            __typename?: 'LocalAttachmentType';
            id: number;
            thumbnails?: {
              __typename?: 'LocalAttachmentThumbnailsType';
              id: number;
              S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
              M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
              L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
            } | null;
          } | null;
        }>;
        pagination?: { __typename?: 'PaginationType'; offset: number; limit: number; totalCount: number } | null;
      };
    } | null;
  };
};

export type ReferenceNumberQueryVariables = Exact<{
  orderId: Scalars['String'];
}>;

export type ReferenceNumberQuery = {
  __typename?: 'Query';
  result?: {
    __typename?: 'OrderReferenceNumberCheck';
    referenceNumber: boolean;
    status?: FedexStatusEnum | null;
  } | null;
};

export type CheckCustomerRateForOrderQueryVariables = Exact<{
  orderId: Scalars['String'];
}>;

export type CheckCustomerRateForOrderQuery = { __typename?: 'Query'; result: boolean };

export type CheckSellerRateForOrderQueryVariables = Exact<{
  orderId: Scalars['String'];
}>;

export type CheckSellerRateForOrderQuery = { __typename?: 'Query'; result: boolean };

export type SellerOrdersQueryVariables = Exact<{
  input?: InputMaybe<OrdersInput>;
}>;

export type SellerOrdersQuery = {
  __typename?: 'Query';
  orders: {
    __typename?: 'User';
    seller?: {
      __typename?: 'Seller';
      orders: {
        __typename?: 'Orders';
        entries: Array<{
          __typename?: 'OrderWithContents';
          order: {
            __typename?: 'Order';
            id: string;
            ID: string;
            orderDate: any;
            status: ArisoraOrderStatus;
            isProblem: boolean;
            trustedOrder: boolean;
            customerId: string;
            customerNickname: string;
          };
          contents: Array<{
            __typename?: 'OrderContent';
            productId: string;
            id: string;
            name: string;
            price: number;
            colorName?: string | null;
            status: OrderContentProductStatusEnum;
            commission: number;
            specificValues: Array<{ __typename?: 'OcSpecificValueType'; attributeName: string; variantValue: string }>;
            mainPhoto?: {
              __typename?: 'LocalAttachmentType';
              id: number;
              msfiles_uid: string;
              thumbnails?: {
                __typename?: 'LocalAttachmentThumbnailsType';
                id: number;
                S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
                M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
                L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
              } | null;
            } | null;
          }>;
        }>;
        pagination: { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };
      };
    } | null;
  };
};

export type UserBaseFragment = {
  __typename?: 'User';
  id: string;
  email?: string | null;
  name?: string | null;
  secondName?: string | null;
  nickname?: string | null;
  selectedLanguage?: AvailableLanguagesEnum | null;
  registered: boolean;
  phoneNumber?: string | null;
  roles?: Array<UserRoles> | null;
  phoneVerified: boolean;
  sellerId?: string | null;
  sellerVerification?: SellerVerificationEnum | null;
  last4numbers?: string | null;
  customerRating?: { __typename?: 'Rating'; positive: number; negative: number; neutral: number } | null;
  seller?: {
    __typename?: 'Seller';
    id: string;
    sellerID: string;
    name: string;
    secondName?: string | null;
    nameHiragana?: string | null;
    secondNameHiragana?: string | null;
    contactNumber?: string | null;
    isTrusted: boolean;
    totalTrustPoints?: number | null;
    sellerRating?: { __typename?: 'Rating'; positive: number; negative: number; neutral: number } | null;
    address?: {
      __typename?: 'UserAddressType';
      id: string;
      title: string;
      fullName: string;
      email: string;
      phone?: string | null;
      countryId: string;
      country: string;
      countryCode: string;
      zipCode?: string | null;
      state?: string | null;
      stateCode?: string | null;
      province?: string | null;
      provinceCode?: string | null;
      region?: string | null;
      regionCode?: string | null;
      city?: string | null;
      prefecture?: string | null;
      town?: string | null;
      address?: string | null;
      address1?: string | null;
      address2?: string | null;
      defaultBilling: boolean;
      defaultShipping: boolean;
      sellerAddress: boolean;
      invalid: boolean;
      lastModified: any;
    } | null;
  } | null;
  addresses: Array<{
    __typename?: 'UserAddressType';
    id: string;
    title: string;
    fullName: string;
    email: string;
    phone?: string | null;
    countryId: string;
    country: string;
    countryCode: string;
    zipCode?: string | null;
    state?: string | null;
    stateCode?: string | null;
    province?: string | null;
    provinceCode?: string | null;
    region?: string | null;
    regionCode?: string | null;
    city?: string | null;
    prefecture?: string | null;
    town?: string | null;
    address?: string | null;
    address1?: string | null;
    address2?: string | null;
    defaultBilling: boolean;
    defaultShipping: boolean;
    sellerAddress: boolean;
    invalid: boolean;
    lastModified: any;
  }>;
  avatar?: {
    __typename?: 'LocalAttachmentType';
    thumbnails?: {
      __typename?: 'LocalAttachmentThumbnailsType';
      id: number;
      S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
      M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
      L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
    } | null;
  } | null;
};

export type AddressBaseFragment = {
  __typename?: 'UserAddressType';
  id: string;
  title: string;
  fullName: string;
  email: string;
  phone?: string | null;
  countryId: string;
  country: string;
  countryCode: string;
  zipCode?: string | null;
  state?: string | null;
  stateCode?: string | null;
  province?: string | null;
  provinceCode?: string | null;
  region?: string | null;
  regionCode?: string | null;
  city?: string | null;
  prefecture?: string | null;
  town?: string | null;
  address?: string | null;
  address1?: string | null;
  address2?: string | null;
  defaultBilling: boolean;
  defaultShipping: boolean;
  sellerAddress: boolean;
  invalid: boolean;
  lastModified: any;
};

export type UserQueryVariables = Exact<{ [key: string]: never }>;

export type UserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    email?: string | null;
    name?: string | null;
    secondName?: string | null;
    nickname?: string | null;
    selectedLanguage?: AvailableLanguagesEnum | null;
    registered: boolean;
    phoneNumber?: string | null;
    roles?: Array<UserRoles> | null;
    phoneVerified: boolean;
    sellerId?: string | null;
    sellerVerification?: SellerVerificationEnum | null;
    last4numbers?: string | null;
    customerRating?: { __typename?: 'Rating'; positive: number; negative: number; neutral: number } | null;
    seller?: {
      __typename?: 'Seller';
      id: string;
      sellerID: string;
      name: string;
      secondName?: string | null;
      nameHiragana?: string | null;
      secondNameHiragana?: string | null;
      contactNumber?: string | null;
      isTrusted: boolean;
      totalTrustPoints?: number | null;
      sellerRating?: { __typename?: 'Rating'; positive: number; negative: number; neutral: number } | null;
      address?: {
        __typename?: 'UserAddressType';
        id: string;
        title: string;
        fullName: string;
        email: string;
        phone?: string | null;
        countryId: string;
        country: string;
        countryCode: string;
        zipCode?: string | null;
        state?: string | null;
        stateCode?: string | null;
        province?: string | null;
        provinceCode?: string | null;
        region?: string | null;
        regionCode?: string | null;
        city?: string | null;
        prefecture?: string | null;
        town?: string | null;
        address?: string | null;
        address1?: string | null;
        address2?: string | null;
        defaultBilling: boolean;
        defaultShipping: boolean;
        sellerAddress: boolean;
        invalid: boolean;
        lastModified: any;
      } | null;
    } | null;
    addresses: Array<{
      __typename?: 'UserAddressType';
      id: string;
      title: string;
      fullName: string;
      email: string;
      phone?: string | null;
      countryId: string;
      country: string;
      countryCode: string;
      zipCode?: string | null;
      state?: string | null;
      stateCode?: string | null;
      province?: string | null;
      provinceCode?: string | null;
      region?: string | null;
      regionCode?: string | null;
      city?: string | null;
      prefecture?: string | null;
      town?: string | null;
      address?: string | null;
      address1?: string | null;
      address2?: string | null;
      defaultBilling: boolean;
      defaultShipping: boolean;
      sellerAddress: boolean;
      invalid: boolean;
      lastModified: any;
    }>;
    avatar?: {
      __typename?: 'LocalAttachmentType';
      thumbnails?: {
        __typename?: 'LocalAttachmentThumbnailsType';
        id: number;
        S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
        M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
        L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
      } | null;
    } | null;
  };
};

export type SendNewCustomerServiceMessageMutationVariables = Exact<{
  input: CreateCustomerServiceMessageInput;
}>;

export type SendNewCustomerServiceMessageMutation = { __typename?: 'Mutation'; success: boolean };

export type ComplaintTypesQueryVariables = Exact<{ [key: string]: never }>;

export type ComplaintTypesQuery = {
  __typename?: 'Query';
  complaintTypes: {
    __typename?: 'ComplaintTypes';
    productComplaintTypes: Array<{ __typename?: 'ProductComplaintType'; uid: string; name: string }>;
  };
};

export type CreateProductComplaintMutationVariables = Exact<{
  input: CreateProductComplaintInput;
}>;

export type CreateProductComplaintMutation = { __typename?: 'Mutation'; success: boolean };

export type AddProductToFavoritesMutationVariables = Exact<{
  productId: Scalars['String'];
}>;

export type AddProductToFavoritesMutation = { __typename?: 'Mutation'; result: boolean };

export type MoveProductToFavoritesMutationVariables = Exact<{
  productId: Scalars['String'];
}>;

export type MoveProductToFavoritesMutation = { __typename?: 'Mutation'; result: boolean };

export type RemoveAllProductsFromFavoritesMutationVariables = Exact<{ [key: string]: never }>;

export type RemoveAllProductsFromFavoritesMutation = { __typename?: 'Mutation'; result: boolean };

export type RemoveProductFromFavoritesMutationVariables = Exact<{
  productId: Scalars['String'];
}>;

export type RemoveProductFromFavoritesMutation = { __typename?: 'Mutation'; result: boolean };

export type AddPushTokenToUserMutationVariables = Exact<{
  input: DeviceTokenAddInput;
}>;

export type AddPushTokenToUserMutation = { __typename?: 'Mutation'; response: boolean };

export type UpdateUserMutationVariables = Exact<{
  input: UserCredentialsInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  user: {
    __typename?: 'User';
    id: string;
    email?: string | null;
    name?: string | null;
    secondName?: string | null;
    nickname?: string | null;
    selectedLanguage?: AvailableLanguagesEnum | null;
    registered: boolean;
    phoneNumber?: string | null;
    roles?: Array<UserRoles> | null;
    phoneVerified: boolean;
    sellerId?: string | null;
    sellerVerification?: SellerVerificationEnum | null;
    last4numbers?: string | null;
    customerRating?: { __typename?: 'Rating'; positive: number; negative: number; neutral: number } | null;
    seller?: {
      __typename?: 'Seller';
      id: string;
      sellerID: string;
      name: string;
      secondName?: string | null;
      nameHiragana?: string | null;
      secondNameHiragana?: string | null;
      contactNumber?: string | null;
      isTrusted: boolean;
      totalTrustPoints?: number | null;
      sellerRating?: { __typename?: 'Rating'; positive: number; negative: number; neutral: number } | null;
      address?: {
        __typename?: 'UserAddressType';
        id: string;
        title: string;
        fullName: string;
        email: string;
        phone?: string | null;
        countryId: string;
        country: string;
        countryCode: string;
        zipCode?: string | null;
        state?: string | null;
        stateCode?: string | null;
        province?: string | null;
        provinceCode?: string | null;
        region?: string | null;
        regionCode?: string | null;
        city?: string | null;
        prefecture?: string | null;
        town?: string | null;
        address?: string | null;
        address1?: string | null;
        address2?: string | null;
        defaultBilling: boolean;
        defaultShipping: boolean;
        sellerAddress: boolean;
        invalid: boolean;
        lastModified: any;
      } | null;
    } | null;
    addresses: Array<{
      __typename?: 'UserAddressType';
      id: string;
      title: string;
      fullName: string;
      email: string;
      phone?: string | null;
      countryId: string;
      country: string;
      countryCode: string;
      zipCode?: string | null;
      state?: string | null;
      stateCode?: string | null;
      province?: string | null;
      provinceCode?: string | null;
      region?: string | null;
      regionCode?: string | null;
      city?: string | null;
      prefecture?: string | null;
      town?: string | null;
      address?: string | null;
      address1?: string | null;
      address2?: string | null;
      defaultBilling: boolean;
      defaultShipping: boolean;
      sellerAddress: boolean;
      invalid: boolean;
      lastModified: any;
    }>;
    avatar?: {
      __typename?: 'LocalAttachmentType';
      thumbnails?: {
        __typename?: 'LocalAttachmentThumbnailsType';
        id: number;
        S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
        M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
        L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
      } | null;
    } | null;
  };
};

export type AttachmentsQueryVariables = Exact<{
  filter?: InputMaybe<FilterAttachmentsInput>;
  pagination: PaginationInput;
  sort: SortAttachmentsInput;
}>;

export type AttachmentsQuery = {
  __typename?: 'Query';
  attachments: {
    __typename?: 'GetAttachmentsResult';
    entries: Array<{
      __typename?: 'LocalAttachmentType';
      id: number;
      msfiles_uid: string;
      title?: string | null;
      upload_status: AttachmentUploadStatus;
      type: LocalAttachmentKindEnum;
      main_file?: { __typename?: 'LocalFileType'; id: number; url?: string | null } | null;
      thumbnails?: {
        __typename?: 'LocalAttachmentThumbnailsType';
        S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
        M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
        L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
      } | null;
    }>;
    pagination: { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };
  };
};

export type CreateUploadUrl_V2MutationVariables = Exact<{
  input: CreateUploadUrlInput;
}>;

export type CreateUploadUrl_V2Mutation = {
  __typename?: 'Mutation';
  createUploadUrlV2: { __typename?: 'CreateUploadUrlV2Result'; url: string; code: string };
};

export type CatalogNavigationQueryVariables = Exact<{ [key: string]: never }>;

export type CatalogNavigationQuery = {
  __typename?: 'Query';
  categories: Array<{ __typename?: 'Category'; id: string; name: string; subCategoryIds: Array<string> }>;
  subCategories: Array<{
    __typename?: 'SubCategory';
    id: string;
    name: string;
    subSubCategoryIds?: Array<string> | null;
  }>;
  subSubCategories: Array<{ __typename?: 'SubSubCategory'; id: string; name: string }>;
};

export type CatalogProductsQueryVariables = Exact<{
  input?: InputMaybe<ProductsPaginationWithFiltersInput>;
}>;

export type CatalogProductsQuery = {
  __typename?: 'Query';
  products: {
    __typename?: 'Products';
    entries: Array<{
      __typename?: 'Product';
      id: string;
      name: string;
      description?: string | null;
      price: number;
      categoryId: string;
      subCategoryId?: string | null;
      subSubCategoryId?: string | null;
      brandId?: string | null;
      sellerId: string;
      colorId?: string | null;
      status: ProductStatusEnum;
      favorite?: boolean | null;
      mainPhoto?: {
        __typename?: 'LocalAttachmentType';
        id: number;
        thumbnails?: {
          __typename?: 'LocalAttachmentThumbnailsType';
          id: number;
          S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
        } | null;
      } | null;
    }>;
    pagination: { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };
  };
};

export type CatalogAttributesQueryVariables = Exact<{ [key: string]: never }>;

export type CatalogAttributesQuery = {
  __typename?: 'Query';
  attributes: Array<{
    __typename?: 'SpecificAttributeWithVariants';
    id: string;
    name: string;
    subSubCategoryIds: Array<string>;
    variants: Array<{ __typename?: 'SpecificVariant'; id: string; value: string }>;
  }>;
};

export type CatalogBrandsQueryVariables = Exact<{
  input: BrandSearchInput;
}>;

export type CatalogBrandsQuery = {
  __typename?: 'Query';
  brands: {
    __typename?: 'BrandsResult';
    totalUnfilteredBrands: number;
    entries: Array<{ __typename?: 'Brand'; id: string; name: string }>;
    pagination: { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };
  };
};

export type CatalogColorsQueryVariables = Exact<{ [key: string]: never }>;

export type CatalogColorsQuery = {
  __typename?: 'Query';
  colors: Array<{ __typename?: 'Color'; id: string; name: string; colorCode: string }>;
};

export type HomePageQueryVariables = Exact<{
  sort?: InputMaybe<ProductsSort>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;

export type HomePageQuery = {
  __typename?: 'Query';
  products: {
    __typename?: 'Products';
    entries: Array<{
      __typename?: 'Product';
      id: string;
      name: string;
      price: number;
      status: ProductStatusEnum;
      sellerId: string;
      favorite?: boolean | null;
      mainPhoto?: {
        __typename?: 'LocalAttachmentType';
        id: number;
        thumbnails?: {
          __typename?: 'LocalAttachmentThumbnailsType';
          id: number;
          S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
        } | null;
      } | null;
    }>;
    pagination: { __typename?: 'PaginationType'; totalCount: number; offset: number; limit: number };
  };
  banners: Array<{
    __typename?: 'Banner';
    name: string;
    description?: string | null;
    redirectUrl?: string | null;
    active: boolean;
    files?: Array<{
      __typename?: 'AttachmentType';
      thumbnails?: { __typename?: 'Thumbnails'; large: { __typename?: 'Thumbnail'; url: string } } | null;
    }> | null;
  }>;
};

export type NotificationsQueryVariables = Exact<{
  input?: InputMaybe<NotificationCommonInput>;
}>;

export type NotificationsQuery = {
  __typename?: 'Query';
  notifications: {
    __typename?: 'Notifications';
    entries: Array<{
      __typename?: 'NotificationType';
      id: number;
      userId: string;
      createdAt: any;
      event: EventTypeEnum;
      priority: boolean;
      read: boolean;
      text: string;
      orderUrl?: string | null;
      productUrl?: string | null;
      rate?: NotificationRateEnum | null;
    }>;
    pagination: { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };
  };
};

export type ConfirmOrderMutationVariables = Exact<{
  input: OrderConfirmationInput;
}>;

export type ConfirmOrderMutation = { __typename?: 'Mutation'; confirmed: string };

export type ConfirmDebtPaymentMutationVariables = Exact<{
  debtId: Scalars['String'];
}>;

export type ConfirmDebtPaymentMutation = { __typename?: 'Mutation'; confirmed: boolean };

export type UpsertPaymentIntentMutationVariables = Exact<{
  debtId?: InputMaybe<Scalars['String']>;
}>;

export type UpsertPaymentIntentMutation = { __typename?: 'Mutation'; paymentIntent: string };

export type DeliveryMetadataQueryVariables = Exact<{
  addressId: Scalars['String'];
  productIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeliveryMetadataQuery = {
  __typename?: 'Query';
  metadata: {
    __typename?: 'DeliveryMetaData';
    deliveryDaysMax: number;
    deliveryDaysMin: number;
    deliveryPrice: number;
  };
};

export type CreateCommentMutationVariables = Exact<{
  input: CreateProductCommentInput;
}>;

export type CreateCommentMutation = { __typename?: 'Mutation'; response: boolean };

export type CreateCommentReplyMutationVariables = Exact<{
  input: CreateProductCommentInput;
}>;

export type CreateCommentReplyMutation = { __typename?: 'Mutation'; response: boolean };

export type ProductQueryVariables = Exact<{
  id: Scalars['String'];
  commentsPagination?: InputMaybe<PaginationInput>;
}>;

export type ProductQuery = {
  __typename?: 'Query';
  products: {
    __typename?: 'Products';
    entries: Array<{
      __typename?: 'Product';
      id: string;
      lastModified: any;
      name: string;
      description?: string | null;
      price: number;
      sellerId: string;
      categoryId: string;
      categoryName: string;
      subCategoryId?: string | null;
      subCategoryName: string;
      subSubCategoryId?: string | null;
      subSubCategoryName: string;
      brandName?: string | null;
      colorName?: string | null;
      deliverySpeedDays: DeliverySpeedDaysEnum;
      condition: ProductConditionEnum;
      status: ProductStatusEnum;
      weight?: number | null;
      boxHeight?: number | null;
      boxWidth?: number | null;
      boxLength?: number | null;
      favorite?: boolean | null;
      photos?: Array<{
        __typename?: 'LocalAttachmentType';
        id: number;
        msfiles_uid: string;
        thumbnails?: {
          __typename?: 'LocalAttachmentThumbnailsType';
          id: number;
          S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
        } | null;
      }> | null;
      mainPhoto?: {
        __typename?: 'LocalAttachmentType';
        id: number;
        msfiles_uid: string;
        thumbnails?: {
          __typename?: 'LocalAttachmentThumbnailsType';
          id: number;
          S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
        } | null;
      } | null;
      specificValues?: Array<{
        __typename?: 'SpecificValueExtended';
        attributeId?: string | null;
        attributeName: string;
        variantId?: string | null;
        variantValue?: string | null;
      }> | null;
      sellerOtherProducts: Array<{
        __typename?: 'Product';
        id: string;
        name: string;
        description?: string | null;
        price: number;
        mainPhoto?: {
          __typename?: 'LocalAttachmentType';
          id: number;
          thumbnails?: {
            __typename?: 'LocalAttachmentThumbnailsType';
            id: number;
            S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
            M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
            L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          } | null;
        } | null;
      }>;
      similarProducts: Array<{
        __typename?: 'Product';
        id: string;
        name: string;
        description?: string | null;
        price: number;
        mainPhoto?: {
          __typename?: 'LocalAttachmentType';
          id: number;
          thumbnails?: {
            __typename?: 'LocalAttachmentThumbnailsType';
            id: number;
            S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
            M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
            L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          } | null;
        } | null;
      }>;
      comments: {
        __typename?: 'ProductComments';
        entries: Array<{
          __typename?: 'ProductComment';
          id: string;
          commentary: string;
          commentator: string;
          isSeller: boolean;
          createdDate: any;
          productId: string;
          parentId?: string | null;
          userId?: string | null;
        }>;
        pagination: { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };
      };
    }>;
  };
};

export type ProductCategoryQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type ProductCategoryQuery = {
  __typename?: 'Query';
  category: { __typename?: 'Category'; id: string; name: string };
};

export type ProductSubCategoryQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type ProductSubCategoryQuery = {
  __typename?: 'Query';
  subCategory: { __typename?: 'SubCategory'; id: string; name: string };
};

export type ProductSubSubCategoryQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type ProductSubSubCategoryQuery = {
  __typename?: 'Query';
  subSubCategory: { __typename?: 'SubSubCategory'; id: string; name: string };
};

export type SellerQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type SellerQuery = {
  __typename?: 'Query';
  seller: {
    __typename?: 'PublicInfoType';
    sellers: {
      __typename?: 'SellersType';
      entries: Array<{
        __typename?: 'SellerPublicInfoType';
        id: string;
        nickname?: string | null;
        rating?: { __typename?: 'Rating'; positive: number; neutral: number; negative: number } | null;
      }>;
    };
  };
};

export type ProfileLayoutQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileLayoutQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    sellerVerification?: SellerVerificationEnum | null;
    sellerId?: string | null;
  };
};

export type CreateProductMutationVariables = Exact<{
  input: ProductInput;
}>;

export type CreateProductMutation = { __typename?: 'Mutation'; addProduct: { __typename?: 'Product'; id: string } };

export type UpdateProductMutationVariables = Exact<{
  input: ProductUpdateInput;
}>;

export type UpdateProductMutation = { __typename?: 'Mutation'; updateProduct: { __typename?: 'Product'; id: string } };

export type RemoveProductMutationVariables = Exact<{
  productId: Scalars['String'];
}>;

export type RemoveProductMutation = { __typename?: 'Mutation'; removeProduct: boolean };

export type CreateProductCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type CreateProductCategoriesQuery = {
  __typename?: 'Query';
  categories: Array<{ __typename?: 'Category'; id: string; name: string }>;
};

export type CreateProductSubCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type CreateProductSubCategoriesQuery = {
  __typename?: 'Query';
  subCategories: Array<{ __typename?: 'SubCategory'; id: string; name: string; categoryIds: Array<string> }>;
};

export type CreateProductSubSubCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type CreateProductSubSubCategoriesQuery = {
  __typename?: 'Query';
  subSubCategories: Array<{
    __typename?: 'SubSubCategory';
    id: string;
    name: string;
    subCategoryIds: Array<string>;
    mandatoryAttribute?: Array<string> | null;
  }>;
};

export type CreateProductBrandsQueryVariables = Exact<{
  input: BrandSearchInput;
}>;

export type CreateProductBrandsQuery = {
  __typename?: 'Query';
  data: {
    __typename?: 'BrandsResult';
    entries: Array<{ __typename?: 'Brand'; id: string; name: string }>;
    pagination: { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };
  };
};

export type CreateProductColorsQueryVariables = Exact<{ [key: string]: never }>;

export type CreateProductColorsQuery = {
  __typename?: 'Query';
  colors: Array<{ __typename?: 'Color'; id: string; code: string; name: string }>;
};

export type CreateProductSpecificAttributesQueryVariables = Exact<{
  subSubCategoryId: Scalars['String'];
}>;

export type CreateProductSpecificAttributesQuery = {
  __typename?: 'Query';
  specificAttributes: Array<{
    __typename?: 'SpecificAttributeWithVariants';
    id: string;
    name: string;
    variants: Array<{ __typename?: 'SpecificVariant'; id: string; value: string }>;
  }>;
};

export type CreateProductDataQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type CreateProductDataQuery = {
  __typename?: 'Query';
  products: {
    __typename?: 'Products';
    entries: Array<{
      __typename?: 'Product';
      id: string;
      name: string;
      description?: string | null;
      price: number;
      sellerId: string;
      categoryId: string;
      subCategoryId?: string | null;
      subSubCategoryId?: string | null;
      brandId?: string | null;
      colorId?: string | null;
      deliverySpeedDays: DeliverySpeedDaysEnum;
      condition: ProductConditionEnum;
      status: ProductStatusEnum;
      weight?: number | null;
      boxHeight?: number | null;
      boxWidth?: number | null;
      boxLength?: number | null;
      kuroneko?: KuronekoPacketEnum | null;
      warehouseNumber: number;
      photos?: Array<{
        __typename?: 'LocalAttachmentType';
        id: number;
        msfiles_uid: string;
        thumbnails?: {
          __typename?: 'LocalAttachmentThumbnailsType';
          id: number;
          S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
        } | null;
      }> | null;
      mainPhoto?: {
        __typename?: 'LocalAttachmentType';
        id: number;
        msfiles_uid: string;
        thumbnails?: {
          __typename?: 'LocalAttachmentThumbnailsType';
          id: number;
          S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
        } | null;
      } | null;
      specificValues?: Array<{
        __typename?: 'SpecificValueExtended';
        id: string;
        attributeId?: string | null;
        attributeName: string;
        variantId?: string | null;
        variantValue?: string | null;
      }> | null;
    }>;
  };
};

export type CreateProductFixedFeeQueryVariables = Exact<{
  input: CalculateFixedDeliveryFeeInput;
}>;

export type CreateProductFixedFeeQuery = { __typename?: 'Query'; fee: number };

export type CreateTicketMutationVariables = Exact<{
  input: CreateTicketInput;
}>;

export type CreateTicketMutation = { __typename?: 'Mutation'; result: { __typename?: 'Ticket'; ticketId: number } };

export type UpsertProductDraftMutationVariables = Exact<{
  input: ProductDraftUpsertInput;
}>;

export type UpsertProductDraftMutation = {
  __typename?: 'Mutation';
  draft: { __typename?: 'ProductDraft'; id: number };
};

export type RemoveProductDraftMutationVariables = Exact<{
  input: ProductDraftRemoveInput;
}>;

export type RemoveProductDraftMutation = { __typename?: 'Mutation'; success: boolean };

export type RemoveAllProductDraftsMutationVariables = Exact<{ [key: string]: never }>;

export type RemoveAllProductDraftsMutation = { __typename?: 'Mutation'; success: boolean };

export type SellerProductDraftsQueryVariables = Exact<{
  input?: InputMaybe<SellerDraftsInput>;
}>;

export type SellerProductDraftsQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    seller?: {
      __typename?: 'Seller';
      id: string;
      drafts: {
        __typename?: 'ProductDrafts';
        entries: Array<{
          __typename?: 'ProductDraft';
          id: number;
          name?: string | null;
          categoryId?: string | null;
          sellerId?: string | null;
          subCategoryId?: string | null;
          subSubCategoryId?: string | null;
          brandId?: string | null;
          colorId?: string | null;
          condition?: ProductConditionEnum | null;
          mainPhotoIndex?: number | null;
          deliverySpeedDays?: DeliverySpeedDaysEnum | null;
          description?: string | null;
          price?: number | null;
          boxHeight?: number | null;
          boxWidth?: number | null;
          boxLength?: number | null;
          weight?: number | null;
          specificValues?: Array<{
            __typename?: 'DraftSpecificValue';
            id: string;
            attributeId: string;
            variantId: string;
          }> | null;
          photos?: Array<{
            __typename?: 'LocalAttachmentType';
            id: number;
            msfiles_uid: string;
            title?: string | null;
            size?: string | null;
            main_file?: { __typename?: 'LocalFileType'; url?: string | null } | null;
            thumbnails?: {
              __typename?: 'LocalAttachmentThumbnailsType';
              id: number;
              S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
            } | null;
          }> | null;
          mainPhoto?: {
            __typename?: 'LocalAttachmentType';
            id: number;
            msfiles_uid: string;
            thumbnails?: {
              __typename?: 'LocalAttachmentThumbnailsType';
              id: number;
              S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
            } | null;
          } | null;
        }>;
        pagination: { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };
      };
    } | null;
  };
};

export type CreateUploadUrlV2MutationVariables = Exact<{
  input: CreateUploadUrlInput;
}>;

export type CreateUploadUrlV2Mutation = {
  __typename?: 'Mutation';
  upload: { __typename?: 'CreateUploadUrlV2Result'; code: string; url: string };
};

export type DeleteAttachmentsMutationVariables = Exact<{
  input: AttachmentIdsInput;
}>;

export type DeleteAttachmentsMutation = { __typename?: 'Mutation'; success: boolean };

export type RemoveProductDraftAttachmentMutationVariables = Exact<{
  input: ProductDraftAttachmentRemoveInput;
}>;

export type RemoveProductDraftAttachmentMutation = { __typename?: 'Mutation'; success: boolean };

export type DraftAttachmentsQueryVariables = Exact<{
  input: FilterAttachmentsInput;
}>;

export type DraftAttachmentsQuery = {
  __typename?: 'Query';
  attachments: {
    __typename?: 'GetAttachmentsResult';
    entries: Array<{
      __typename?: 'LocalAttachmentType';
      size?: string | null;
      title?: string | null;
      msfiles_uid: string;
      main_file?: { __typename?: 'LocalFileType'; id: number; url?: string | null } | null;
      thumbnails?: {
        __typename?: 'LocalAttachmentThumbnailsType';
        id: number;
        S?: { __typename?: 'LocalFileType'; id: number } | null;
      } | null;
    }>;
  };
};

export type FavoritesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;

export type FavoritesQuery = {
  __typename?: 'Query';
  products: {
    __typename?: 'Products';
    entries: Array<{
      __typename?: 'Product';
      id: string;
      name: string;
      price: number;
      status: ProductStatusEnum;
      favorite?: boolean | null;
      mainPhoto?: {
        __typename?: 'LocalAttachmentType';
        id: number;
        thumbnails?: {
          __typename?: 'LocalAttachmentThumbnailsType';
          id: number;
          S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
        } | null;
      } | null;
    }>;
    pagination: { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };
  };
};

export type RateSellerMutationVariables = Exact<{
  rateInput: RatingInput;
}>;

export type RateSellerMutation = { __typename?: 'Mutation'; result: boolean };

export type RateCustomerMutationVariables = Exact<{
  rateInput: RatingInput;
}>;

export type RateCustomerMutation = { __typename?: 'Mutation'; result: boolean };

export type ConfirmOrderPackagingMutationVariables = Exact<{
  input: OrderPackagingInput;
  orderId: Scalars['String'];
}>;

export type ConfirmOrderPackagingMutation = { __typename?: 'Mutation'; result: boolean };

export type ConfirmPackagesSendingWarehouseMutationVariables = Exact<{
  parcelsInput: OrderParcelsInput;
}>;

export type ConfirmPackagesSendingWarehouseMutation = { __typename?: 'Mutation'; result: boolean };

export type ConfirmOrderDeliveringMutationVariables = Exact<{
  orderId: Scalars['String'];
}>;

export type ConfirmOrderDeliveringMutation = { __typename?: 'Mutation'; result: boolean };

export type RecalculateDeliveryPriceMutationVariables = Exact<{
  input: OrderRecalculateDeliveryPriceInput;
}>;

export type RecalculateDeliveryPriceMutation = { __typename?: 'Mutation'; result: boolean };

export type CreateShipmentMutationVariables = Exact<{
  orderId: Scalars['String'];
  sellerAddressId: Scalars['String'];
}>;

export type CreateShipmentMutation = { __typename?: 'Mutation'; result: boolean };

export type CreatePickupRequestMutationVariables = Exact<{
  input: CreateOrderPickupInput;
}>;

export type CreatePickupRequestMutation = { __typename?: 'Mutation'; result: string };

export type ChangeOrderAcceptanceMutationVariables = Exact<{
  input: ChangeOrderAcceptanceStatusInput;
}>;

export type ChangeOrderAcceptanceMutation = { __typename?: 'Mutation'; result: boolean };

export type ConfirmPackagesSendingMutationVariables = Exact<{
  input: ConfirmOrderPackagesSendingInput;
}>;

export type ConfirmPackagesSendingMutation = { __typename?: 'Mutation'; result: boolean };

export type CancelPickupRequestMutationVariables = Exact<{
  pickupUuid: Scalars['String'];
  orderIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type CancelPickupRequestMutation = { __typename?: 'Mutation'; result: boolean };

export type PickupAvailabilityQueryVariables = Exact<{
  input: CheckPickupAvailabilityInput;
}>;

export type PickupAvailabilityQuery = {
  __typename?: 'Query';
  result: {
    __typename?: 'PickupAvailabilityOption';
    cutOffTime: string;
    accessTime: number;
    date: string;
    timesFrom: Array<{ __typename?: 'TimeRecord'; value: string; title: string }>;
    timesUntil: Array<{ __typename?: 'TimeRecord'; value: string; title: string }>;
  };
};

export type PickupRequestsQueryVariables = Exact<{ [key: string]: never }>;

export type PickupRequestsQuery = {
  __typename?: 'Query';
  result: Array<{
    __typename?: 'SellerPickupRequest';
    uuid: string;
    pickupDate: string;
    latestTime: string;
    ordersAmount: number;
    active: boolean;
  }>;
};

export type PickupRequestExtendedQueryVariables = Exact<{
  pickupUuid: Scalars['String'];
}>;

export type PickupRequestExtendedQuery = {
  __typename?: 'Query';
  result: {
    __typename?: 'SellerPickupRequestExtended';
    uuid: string;
    pickupDate: string;
    latestTime: string;
    ordersAmount: number;
    addressLines: string;
    active: boolean;
    packages: Array<{
      __typename?: 'OrderPackages';
      id: number;
      decodedQr?: string | null;
      deliveryPriceFulfilled?: boolean | null;
      orderId: string;
      packages: Array<{
        __typename?: 'OrderPackage';
        id: number;
        length: number;
        width: number;
        height: number;
        weight: number;
      }>;
    }>;
  };
};

export type RegisterSellerMutationVariables = Exact<{
  input: SellerCredentialsInput;
}>;

export type RegisterSellerMutation = { __typename?: 'Mutation'; response: string };

export type RemoveProfileMutationVariables = Exact<{ [key: string]: never }>;

export type RemoveProfileMutation = { __typename?: 'Mutation'; response: boolean };

export type UserReviewsQueryVariables = Exact<{
  limit: Scalars['Int'];
  ratingTypes?: InputMaybe<Array<RatingValueEnum> | RatingValueEnum>;
  reviewsFrom?: InputMaybe<Array<ReviewsUserRoleEnum> | ReviewsUserRoleEnum>;
}>;

export type UserReviewsQuery = {
  __typename?: 'Query';
  reviews: {
    __typename?: 'User';
    id: string;
    reviews?: {
      __typename?: 'Reviews';
      entries?: Array<{
        __typename?: 'Review';
        id: string;
        orderId?: string | null;
        commentary?: string | null;
        createdAt: any;
        customerId?: string | null;
        sellerId?: string | null;
        customerNickname?: string | null;
        sellerNickname?: string | null;
        value: RatingValueEnum;
        reviewFrom: ReviewsUserRoleEnum;
      }> | null;
      pagination: { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };
    } | null;
  };
};

export type UserReviewsCountQueryVariables = Exact<{ [key: string]: never }>;

export type UserReviewsCountQuery = {
  __typename?: 'Query';
  count: {
    __typename?: 'User';
    id: string;
    reviews?: {
      __typename?: 'Reviews';
      totalReviewsByFilter: {
        __typename?: 'ReviewsCount';
        positive: number;
        neutral: number;
        negative: number;
        customer?: number | null;
        seller?: number | null;
      };
    } | null;
  };
};

export type ConfirmDeliveryPriceMutationVariables = Exact<{
  input: OrderConfirmDeliveryPriceInput;
}>;

export type ConfirmDeliveryPriceMutation = { __typename?: 'Mutation'; result: boolean };

export type CalculateShippingFeeMutationVariables = Exact<{
  input: OrderSellerAddressInput;
}>;

export type CalculateShippingFeeMutation = { __typename?: 'Mutation'; result: boolean };

export type CreateYamatoShipmentMutationVariables = Exact<{
  input: CreateYamatoShipmentInput;
}>;

export type CreateYamatoShipmentMutation = { __typename?: 'Mutation'; result: boolean };

export type ShowcaseProductsQueryVariables = Exact<{
  sellerId: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;

export type ShowcaseProductsQuery = {
  __typename?: 'Query';
  products: {
    __typename?: 'Products';
    entries: Array<{
      __typename?: 'Product';
      id: string;
      name: string;
      price: number;
      sellerId: string;
      warehouseNumber: number;
      mainPhoto?: {
        __typename?: 'LocalAttachmentType';
        id: number;
        thumbnails?: {
          __typename?: 'LocalAttachmentThumbnailsType';
          id: number;
          S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
        } | null;
      } | null;
    }>;
    pagination: { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };
  };
};

export type ChangeTicketStatusMutationVariables = Exact<{
  input: ChangeTicketStatusInput;
}>;

export type ChangeTicketStatusMutation = { __typename?: 'Mutation'; result: boolean };

export type TicketQueryVariables = Exact<{
  input: GetTicketInput;
}>;

export type TicketQuery = {
  __typename?: 'Query';
  result: {
    __typename?: 'Ticket';
    ticketId: number;
    topic: TicketTopicEnum;
    status: TicketStatusEnum;
    messages?: Array<{
      __typename?: 'TicketMessage';
      messageId: string;
      senderId: string;
      senderName: string;
      self: boolean;
      closing: boolean;
      body?: string | null;
      read: boolean;
      sequenceStart: boolean;
      createdAt: any;
      image?: { __typename?: 'TicketMessageImage'; imageUrl: string; width: number; height: number } | null;
    }> | null;
  };
};

export type TicketMessagesQueryVariables = Exact<{
  input: TicketMessagesInput;
}>;

export type TicketMessagesQuery = {
  __typename?: 'Query';
  result: Array<{
    __typename?: 'TicketMessage';
    messageId: string;
    senderId: string;
    senderName: string;
    self: boolean;
    closing: boolean;
    body?: string | null;
    read: boolean;
    sequenceStart: boolean;
    createdAt: any;
    image?: { __typename?: 'TicketMessageImage'; imageUrl: string; width: number; height: number } | null;
  }>;
};

export type TicketsQueryVariables = Exact<{
  input?: InputMaybe<TicketsInput>;
}>;

export type TicketsQuery = {
  __typename?: 'Query';
  result: {
    __typename?: 'Tickets';
    entries: Array<{
      __typename?: 'Ticket';
      ticketId: number;
      name: string;
      topic: TicketTopicEnum;
      status: TicketStatusEnum;
      unreadCount?: number | null;
      createdAt: any;
      updatedAt: any;
    }>;
    pagination: { __typename?: 'PaginationType'; offset: number; limit: number; totalCount: number };
  };
};

export type SellerTrustPointsQueryVariables = Exact<{
  input?: InputMaybe<TrustPointsInput>;
}>;

export type SellerTrustPointsQuery = {
  __typename?: 'Query';
  trustPoints: {
    __typename?: 'User';
    seller?: {
      __typename?: 'Seller';
      totalTrustPoints?: number | null;
      trustPoints: {
        __typename?: 'TrustPoints';
        entries: Array<{
          __typename?: 'SellerTrustPoints';
          orderId: string;
          orderID: string;
          commentary?: string | null;
          amount: number;
          total: number;
          createdAt: any;
        }>;
        pagination: { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };
      };
    } | null;
  };
};

export type SendVerificationCodeMutationVariables = Exact<{
  input: SendVerificationSmsInput;
}>;

export type SendVerificationCodeMutation = { __typename?: 'Mutation'; success: boolean };

export type ConfirmVerificationCodeMutationVariables = Exact<{
  input: ConfirmVerificationSmsInput;
}>;

export type ConfirmVerificationCodeMutation = { __typename?: 'Mutation'; success: boolean };

export type SendTestCodeMutationVariables = Exact<{
  input: SendTestSmsInput;
}>;

export type SendTestCodeMutation = { __typename?: 'Mutation'; success: string };

export type WithdrawRequestsQueryVariables = Exact<{
  input: PaginationInput;
}>;

export type WithdrawRequestsQuery = {
  __typename?: 'Query';
  withdrawRequests?: {
    __typename?: 'WithdrawRequests';
    data: Array<{
      __typename?: 'WithdrawRequest';
      ID: string;
      date: any;
      withdrawAmount: number;
      status: WithdrawStatusEnum;
    }>;
    pagination: { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };
  } | null;
};

export type WalletTransactionsQueryVariables = Exact<{
  input?: InputMaybe<WalletTransactionsInput>;
}>;

export type WalletTransactionsQuery = {
  __typename?: 'Query';
  transactions: {
    __typename?: 'WalletTransactions';
    data: Array<{
      __typename?: 'WalletTransactionType';
      amount: number;
      balance: number;
      comment?: string | null;
      createdDate: any;
      transactionType: WalletTransactionTypeEnum;
      order?: {
        __typename?: 'OrderWithContents';
        contents: Array<{
          __typename?: 'OrderContent';
          productId: string;
          price: number;
          commission: number;
          name: string;
        }>;
        order: { __typename?: 'Order'; ID: string; id: string };
      } | null;
    }>;
    pagination: { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };
  };
};

export type SellerBalanceQueryVariables = Exact<{ [key: string]: never }>;

export type SellerBalanceQuery = {
  __typename?: 'Query';
  balance?: { __typename?: 'SellerBalanceType'; availableToWithdraw: number; total: number } | null;
};

export type MakeWithdrawRequestMutationVariables = Exact<{
  input: WithdrawRequestInput;
}>;

export type MakeWithdrawRequestMutation = { __typename?: 'Mutation'; makeWithdrawRequest: boolean };

export type CreateBankAccountMutationVariables = Exact<{
  input: CreateBankAccountPresetInput;
}>;

export type CreateBankAccountMutation = {
  __typename?: 'Mutation';
  createBankAccountPreset: { __typename?: 'BankAccountType'; id: number };
};

export type UpdateBankAccountMutationVariables = Exact<{
  data: UpdateBankAccountPresetData;
  where: WhereBankAccountPresetInput;
}>;

export type UpdateBankAccountMutation = {
  __typename?: 'Mutation';
  updateBankAccountPreset: { __typename?: 'BankAccountType'; id: number };
};

export type DeleteBankAccountMutationVariables = Exact<{
  where: WhereBankAccountPresetInput;
}>;

export type DeleteBankAccountMutation = { __typename?: 'Mutation'; deleteBankAccountPreset: boolean };

export type WithdrawUserQueryVariables = Exact<{ [key: string]: never }>;

export type WithdrawUserQuery = {
  __typename?: 'Query';
  user: { __typename?: 'User'; seller?: { __typename?: 'Seller'; name: string; secondName?: string | null } | null };
};

export type BankAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type BankAccountsQuery = {
  __typename?: 'Query';
  bankAccounts: Array<{
    __typename?: 'BankAccountType';
    id: number;
    title?: string | null;
    user_name: string;
    user_name_hiragana: string;
    organization_name: string;
    organization_code: string;
    organization_hiragana: string;
    branch_name?: string | null;
    branch_code?: string | null;
    branch_hiragana?: string | null;
    account_type: BankAccountTypeEnum;
    account_number: string;
    user_at_id: string;
  }>;
};

export type BanksQueryVariables = Exact<{
  input: GetBankOrganizationsInput;
}>;

export type BanksQuery = {
  __typename?: 'Query';
  banks: {
    __typename?: 'BankOrganizations';
    bankOrganizations: Array<{
      __typename?: 'BankType';
      organization_name: string;
      organization_hiragana: string;
      organization_code: string;
    }>;
  };
};

export type BranchesQueryVariables = Exact<{
  input: GetBankBranchesInput;
}>;

export type BranchesQuery = {
  __typename?: 'Query';
  branches: {
    __typename?: 'BankBranches';
    bankBranches: Array<{
      __typename?: 'BankBranchType';
      branch_name: string;
      branch_hiragana: string;
      branch_code: string;
    }>;
  };
};

export type ReviewsQueryVariables = Exact<{
  input: PublicReviewsInput;
}>;

export type ReviewsQuery = {
  __typename?: 'Query';
  reviews: {
    __typename?: 'PublicInfoType';
    reviews?: {
      __typename?: 'Reviews';
      entries?: Array<{
        __typename?: 'Review';
        id: string;
        commentary?: string | null;
        createdAt: any;
        customerId?: string | null;
        sellerId?: string | null;
        customerNickname?: string | null;
        sellerNickname?: string | null;
        value: RatingValueEnum;
        reviewFrom: ReviewsUserRoleEnum;
      }> | null;
      totalReviewsByFilter: {
        __typename?: 'ReviewsCount';
        positive: number;
        negative: number;
        neutral: number;
        customer?: number | null;
        seller?: number | null;
      };
      pagination: { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };
    } | null;
  };
};

export type SellerPublicInfoQueryVariables = Exact<{
  input?: InputMaybe<PublicInfoInput>;
}>;

export type SellerPublicInfoQuery = {
  __typename?: 'Query';
  seller: {
    __typename?: 'PublicInfoType';
    sellers: {
      __typename?: 'SellersType';
      entries: Array<{
        __typename?: 'SellerPublicInfoType';
        id: string;
        nickname?: string | null;
        isTrusted: boolean;
        avatar?: {
          __typename?: 'LocalAttachmentType';
          thumbnails?: {
            __typename?: 'LocalAttachmentThumbnailsType';
            M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          } | null;
        } | null;
        rating?: { __typename?: 'Rating'; positive: number; neutral: number; negative: number } | null;
      }>;
    };
  };
};

export type SellerProductsQueryVariables = Exact<{
  input: ProductsPaginationWithFiltersInput;
}>;

export type SellerProductsQuery = {
  __typename?: 'Query';
  products: {
    __typename?: 'Products';
    entries: Array<{
      __typename?: 'Product';
      id: string;
      name: string;
      price: number;
      favorite?: boolean | null;
      mainPhoto?: {
        __typename?: 'LocalAttachmentType';
        id: number;
        msfiles_uid: string;
        thumbnails?: {
          __typename?: 'LocalAttachmentThumbnailsType';
          id: number;
          S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
        } | null;
      } | null;
    }>;
    pagination: { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };
  };
};

export type CustomerPublicInfoQueryVariables = Exact<{
  input?: InputMaybe<PublicInfoInput>;
}>;

export type CustomerPublicInfoQuery = {
  __typename?: 'Query';
  customer: {
    __typename?: 'PublicInfoType';
    customers: {
      __typename?: 'CustomersType';
      entries: Array<{
        __typename?: 'CustomerPublicInfoType';
        id: string;
        nickname?: string | null;
        customerRating?: { __typename?: 'Rating'; positive: number; neutral: number; negative: number } | null;
        avatar?: {
          __typename?: 'LocalAttachmentType';
          thumbnails?: {
            __typename?: 'LocalAttachmentThumbnailsType';
            M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          } | null;
        } | null;
      }>;
    };
  };
};

export type ReviewsCountQueryVariables = Exact<{
  input: PublicReviewsInput;
}>;

export type ReviewsCountQuery = {
  __typename?: 'Query';
  count: {
    __typename?: 'PublicInfoType';
    reviews?: {
      __typename?: 'Reviews';
      totalReviewsByFilter: {
        __typename?: 'ReviewsCount';
        positive: number;
        neutral: number;
        negative: number;
        customer?: number | null;
        seller?: number | null;
      };
    } | null;
  };
};

export type AvatarQueryVariables = Exact<{
  input: PublicInfoInput;
}>;

export type AvatarQuery = {
  __typename?: 'Query';
  avatar: {
    __typename?: 'PublicInfoType';
    customers: {
      __typename?: 'CustomersType';
      entries: Array<{
        __typename?: 'CustomerPublicInfoType';
        id: string;
        avatar?: {
          __typename?: 'LocalAttachmentType';
          thumbnails?: {
            __typename?: 'LocalAttachmentThumbnailsType';
            M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          } | null;
        } | null;
      }>;
    };
  };
};

export type AddItemToCartMutationVariables = Exact<{
  productId: Scalars['String'];
}>;

export type AddItemToCartMutation = { __typename?: 'Mutation'; addItemToCart: boolean };

export type RemoveItemFromCartMutationVariables = Exact<{
  productId: Scalars['String'];
}>;

export type RemoveItemFromCartMutation = { __typename?: 'Mutation'; removeItemFromCart: boolean };

export type UpdateCartMutationVariables = Exact<{
  input: CartInput;
}>;

export type UpdateCartMutation = { __typename?: 'Mutation'; updateCart: boolean };

export type CartQueryVariables = Exact<{
  input?: InputMaybe<PaginationInput>;
}>;

export type CartQuery = {
  __typename?: 'Query';
  cart: {
    __typename?: 'Cart';
    groups: Array<{
      __typename?: 'CartItemsGroup';
      sellerId: string;
      sellerName: string;
      sellerNickname: string;
      sellerTrusted: boolean;
      deliveryDaysMin: number;
      deliveryDaysMax: number;
      items: Array<{ __typename?: 'CartItem'; productId: string }>;
    }>;
    pagination: { __typename?: 'PaginationType'; limit: number; offset: number; totalCount: number };
  };
};

export type CartLengthQueryVariables = Exact<{ [key: string]: never }>;

export type CartLengthQuery = { __typename?: 'Query'; length: number };

export type CartProductsQueryVariables = Exact<{
  productIds: Array<Scalars['String']> | Scalars['String'];
  limit: Scalars['Int'];
}>;

export type CartProductsQuery = {
  __typename?: 'Query';
  products: {
    __typename?: 'Products';
    entries: Array<{
      __typename?: 'Product';
      id: string;
      name: string;
      brandName?: string | null;
      colorName?: string | null;
      price: number;
      deliverySpeedDays: DeliverySpeedDaysEnum;
      weight?: number | null;
      boxHeight?: number | null;
      boxLength?: number | null;
      boxWidth?: number | null;
      status: ProductStatusEnum;
      sellerId: string;
      specificValues?: Array<{
        __typename?: 'SpecificValueExtended';
        attributeName: string;
        variantValue?: string | null;
      }> | null;
      mainPhoto?: {
        __typename?: 'LocalAttachmentType';
        id: number;
        thumbnails?: {
          __typename?: 'LocalAttachmentThumbnailsType';
          id: number;
          S?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          M?: { __typename?: 'LocalFileType'; url?: string | null } | null;
          L?: { __typename?: 'LocalFileType'; url?: string | null } | null;
        } | null;
      } | null;
    }>;
  };
};

export type CartSellersQueryVariables = Exact<{ [key: string]: never }>;

export type CartSellersQuery = {
  __typename?: 'Query';
  sellers: Array<{
    __typename?: 'SellerPublic';
    id: string;
    name: string;
    nickname?: string | null;
    isTrusted: boolean;
  }>;
};

export type IsProductInCartQueryVariables = Exact<{
  productId: Scalars['String'];
}>;

export type IsProductInCartQuery = { __typename?: 'Query'; isProductInCart: boolean };

export type CurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type CurrenciesQuery = {
  __typename?: 'Query';
  result: {
    __typename?: 'Currencies';
    rates: Array<{ __typename?: 'CurrencyRate'; id: string; currency: string; rate: number }>;
  };
};

export type ChangeUserLanguageMutationVariables = Exact<{ [key: string]: never }>;

export type ChangeUserLanguageMutation = { __typename?: 'Mutation'; result: boolean };

export type MarkNotificationReadMutationVariables = Exact<{
  notificationId: Scalars['Int'];
}>;

export type MarkNotificationReadMutation = { __typename?: 'Mutation'; markedRead: boolean };

export type MarkAllOrdinaryNotificationsReadMutationVariables = Exact<{ [key: string]: never }>;

export type MarkAllOrdinaryNotificationsReadMutation = { __typename?: 'Mutation'; markedRead: boolean };

export type NotificationsCountQueryVariables = Exact<{
  input?: InputMaybe<NotificationsFilter>;
}>;

export type NotificationsCountQuery = { __typename?: 'Query'; count: number };

export type CreateStripeSetupSessionMutationVariables = Exact<{
  input: StripePathsInput;
}>;

export type CreateStripeSetupSessionMutation = { __typename?: 'Mutation'; stripeSessionUrl: string };

export type DetachPaymentMethodMutationVariables = Exact<{ [key: string]: never }>;

export type DetachPaymentMethodMutation = { __typename?: 'Mutation'; result: boolean };

export type TicketMessagesCountQueryVariables = Exact<{ [key: string]: never }>;

export type TicketMessagesCountQuery = { __typename?: 'Query'; count: number };

export type CdnQueryVariables = Exact<{ [key: string]: never }>;

export type CdnQuery = { __typename?: 'Query'; cdn: string };

export type CreateUserAddressMutationVariables = Exact<{
  input: UserAddressCreateInput;
}>;

export type CreateUserAddressMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'UserAddressType'; id: string };
};

export type DeleteUserAddressMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteUserAddressMutation = { __typename?: 'Mutation'; result: boolean };

export type UpdateUserAddressMutationVariables = Exact<{
  input: UserAddressUpdateInput;
}>;

export type UpdateUserAddressMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'UserAddressType'; id: string };
};

export type CountriesQueryVariables = Exact<{
  input: CountryCommonInput;
}>;

export type CountriesQuery = {
  __typename?: 'Query';
  addressDirectory: {
    __typename?: 'AddressDirectoryType';
    countries: Array<{
      __typename?: 'CountryType';
      id: number;
      name: string;
      nameJp: string;
      supportsZipCode: boolean;
      alpha2: string;
    }>;
  };
};

export type AddressQueryVariables = Exact<{
  zipCode: Scalars['String'];
  countryCode: Scalars['String'];
}>;

export type AddressQuery = {
  __typename?: 'Query';
  addressDirectory: {
    __typename?: 'AddressDirectoryType';
    addresses: Array<{
      __typename?: 'AddressType';
      city?: string | null;
      postcode: string;
      allAddress?: string | null;
      town?: string | null;
      countryCode: string;
      province?: string | null;
      provinceCode?: string | null;
      prefecture?: string | null;
      prefectureId?: number | null;
      state?: string | null;
      stateCode?: string | null;
    }>;
  };
};

export type RegisterCustomerMutationVariables = Exact<{ [key: string]: never }>;

export type RegisterCustomerMutation = { __typename?: 'Mutation'; result: string };

export type SendFeedbackMessageMutationVariables = Exact<{
  input: CreatePublicCustomerServiceMessageInput;
}>;

export type SendFeedbackMessageMutation = { __typename?: 'Mutation'; success: boolean };

export type NavigationQueryVariables = Exact<{ [key: string]: never }>;

export type NavigationQuery = {
  __typename?: 'Query';
  marketPlace: {
    __typename?: 'Marketplace';
    categories: Array<{ __typename?: 'Category'; id: string; name: string; subCategoryIds: Array<string> }>;
    subCategories: Array<{
      __typename?: 'SubCategory';
      id: string;
      name: string;
      subSubCategoryIds?: Array<string> | null;
    }>;
    subSubCategories: Array<{ __typename?: 'SubSubCategory'; id: string; name: string }>;
  };
};

export const ThumbnailLargeFragmentDoc = gql`
  fragment thumbnailLarge on AttachmentType {
    thumbnails {
      large {
        url
      }
    }
  }
`;
export const PaginationFragmentDoc = gql`
  fragment pagination on PaginationType {
    limit
    offset
    totalCount
  }
`;
export const AddressBaseFragmentDoc = gql`
  fragment addressBase on UserAddressType {
    id
    title
    fullName
    email
    phone
    countryId
    country
    countryCode
    zipCode
    state
    stateCode
    province
    provinceCode
    region
    regionCode
    city
    prefecture
    town
    address
    address1
    address2
    defaultBilling
    defaultShipping
    sellerAddress
    invalid
    lastModified
  }
`;
export const LocalThumbnailAllFragmentDoc = gql`
  fragment localThumbnailAll on LocalAttachmentType {
    thumbnails {
      id
      S {
        url
      }
      M {
        url
      }
      L {
        url
      }
    }
  }
`;
export const UserBaseFragmentDoc = gql`
  fragment userBase on User {
    id
    email
    name
    secondName
    nickname
    selectedLanguage
    registered
    phoneNumber
    roles
    phoneVerified
    customerRating {
      positive
      negative
      neutral
    }
    seller {
      id
      sellerID
      name
      secondName
      nameHiragana
      secondNameHiragana
      contactNumber
      sellerRating {
        positive
        negative
        neutral
      }
      address {
        ...addressBase
      }
      isTrusted
      totalTrustPoints
    }
    sellerId
    sellerVerification
    last4numbers
    addresses {
      ...addressBase
    }
    avatar {
      ...localThumbnailAll
    }
  }
  ${AddressBaseFragmentDoc}
  ${LocalThumbnailAllFragmentDoc}
`;
export const CreateUploadUrlDocument = gql`
  mutation CreateUploadUrl($filename: String!) {
    upload: createUploadUrl(filename: $filename) {
      objectName
      url
    }
  }
`;
export type CreateUploadUrlMutationFn = Apollo.MutationFunction<
  CreateUploadUrlMutation,
  CreateUploadUrlMutationVariables
>;

/**
 * __useCreateUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreateUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadUrlMutation, { data, loading, error }] = useCreateUploadUrlMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useCreateUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUploadUrlMutation, CreateUploadUrlMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUploadUrlMutation, CreateUploadUrlMutationVariables>(
    CreateUploadUrlDocument,
    options
  );
}
export type CreateUploadUrlMutationHookResult = ReturnType<typeof useCreateUploadUrlMutation>;
export type CreateUploadUrlMutationResult = Apollo.MutationResult<CreateUploadUrlMutation>;
export type CreateUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateUploadUrlMutation,
  CreateUploadUrlMutationVariables
>;
export const CreateDownloadUrlDocument = gql`
  mutation CreateDownloadUrl($objectName: String!) {
    createDownloadUrl(objectName: $objectName)
  }
`;
export type CreateDownloadUrlMutationFn = Apollo.MutationFunction<
  CreateDownloadUrlMutation,
  CreateDownloadUrlMutationVariables
>;

/**
 * __useCreateDownloadUrlMutation__
 *
 * To run a mutation, you first call `useCreateDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDownloadUrlMutation, { data, loading, error }] = useCreateDownloadUrlMutation({
 *   variables: {
 *      objectName: // value for 'objectName'
 *   },
 * });
 */
export function useCreateDownloadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDownloadUrlMutation, CreateDownloadUrlMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDownloadUrlMutation, CreateDownloadUrlMutationVariables>(
    CreateDownloadUrlDocument,
    options
  );
}
export type CreateDownloadUrlMutationHookResult = ReturnType<typeof useCreateDownloadUrlMutation>;
export type CreateDownloadUrlMutationResult = Apollo.MutationResult<CreateDownloadUrlMutation>;
export type CreateDownloadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateDownloadUrlMutation,
  CreateDownloadUrlMutationVariables
>;
export const CustomerOrdersDocument = gql`
  query CustomerOrders($input: PaginationInput) {
    orders: getCustomerOrders(input: $input) {
      entries {
        order {
          id
          ID
          orderDate
          status
          isProblem
          sellerId: sellerIdSnapshot
          sellerNickname
          deliveryCost
        }
        contents {
          productId
          id
          name
          price
          colorName
          status
          specificValues {
            attributeName
            variantValue
          }
          mainPhoto {
            id
            ...localThumbnailAll
          }
        }
      }
      pagination {
        ...pagination
      }
    }
  }
  ${LocalThumbnailAllFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useCustomerOrdersQuery__
 *
 * To run a query within a React component, call `useCustomerOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerOrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomerOrdersQuery, CustomerOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerOrdersQuery, CustomerOrdersQueryVariables>(CustomerOrdersDocument, options);
}
export function useCustomerOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerOrdersQuery, CustomerOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerOrdersQuery, CustomerOrdersQueryVariables>(CustomerOrdersDocument, options);
}
export type CustomerOrdersQueryHookResult = ReturnType<typeof useCustomerOrdersQuery>;
export type CustomerOrdersLazyQueryHookResult = ReturnType<typeof useCustomerOrdersLazyQuery>;
export type CustomerOrdersQueryResult = Apollo.QueryResult<CustomerOrdersQuery, CustomerOrdersQueryVariables>;
export const DebtsDocument = gql`
  query Debts($orderIds: [String!], $paid: Boolean! = false) {
    debts: getDebts(orderIds: $orderIds, paid: $paid) {
      id
      order_id
      amount
    }
  }
`;

/**
 * __useDebtsQuery__
 *
 * To run a query within a React component, call `useDebtsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtsQuery({
 *   variables: {
 *      orderIds: // value for 'orderIds'
 *      paid: // value for 'paid'
 *   },
 * });
 */
export function useDebtsQuery(baseOptions?: Apollo.QueryHookOptions<DebtsQuery, DebtsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DebtsQuery, DebtsQueryVariables>(DebtsDocument, options);
}
export function useDebtsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DebtsQuery, DebtsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DebtsQuery, DebtsQueryVariables>(DebtsDocument, options);
}
export type DebtsQueryHookResult = ReturnType<typeof useDebtsQuery>;
export type DebtsLazyQueryHookResult = ReturnType<typeof useDebtsLazyQuery>;
export type DebtsQueryResult = Apollo.QueryResult<DebtsQuery, DebtsQueryVariables>;
export const OrderDocument = gql`
  query Order($input: OrderByIdInput!) {
    order: user {
      order(input: $input) {
        order {
          id
          ID
          orderDate
          status
          isProblem
          japaneseOrder
          sellerId: sellerIdSnapshot
          sellerNickname
          customerId: userIdSnapshot
          customerNickname: userNicknameSnapshot
          customerPhone: userPhoneSnapshot
          customerLast4: userCardLast4
          billingAddress {
            title
            address
            address1
            address2
            zipCode
            state
            stateCode
            city
            country
          }
          destinationAddress {
            title
            address
            address1
            address2
            zipCode
            state
            stateCode
            city
            country
            phone
            fullName
          }
          deliveryCost
          isPaid
        }
        packages {
          id
          orderId
          deliveryPriceFulfilled
          decodedQr
          fedexStatus
          pickupRequestUuid
          packages {
            id
            weight
            height
            width
            length
          }
        }
        packaging {
          carryingDate
          carryingTime
          address
          address1
          city
          country
          id
          numberOfSeats
          orderId
          zipCode
          province
          countryCode
          countryId
          email
          fullName
          region
        }
      }
    }
  }
`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
}
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
}
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const OrderContentDocument = gql`
  query OrderContent($input: OrderByIdInput!) {
    order: user {
      order(input: $input) {
        content {
          productId
          id
          name
          price
          colorName
          status
          weight
          specificValues {
            attributeName
            variantValue
          }
          mainPhoto {
            id
            ...localThumbnailAll
          }
          commission
        }
        pagination {
          offset
          limit
          totalCount
        }
      }
    }
  }
  ${LocalThumbnailAllFragmentDoc}
`;

/**
 * __useOrderContentQuery__
 *
 * To run a query within a React component, call `useOrderContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderContentQuery(
  baseOptions: Apollo.QueryHookOptions<OrderContentQuery, OrderContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderContentQuery, OrderContentQueryVariables>(OrderContentDocument, options);
}
export function useOrderContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderContentQuery, OrderContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderContentQuery, OrderContentQueryVariables>(OrderContentDocument, options);
}
export type OrderContentQueryHookResult = ReturnType<typeof useOrderContentQuery>;
export type OrderContentLazyQueryHookResult = ReturnType<typeof useOrderContentLazyQuery>;
export type OrderContentQueryResult = Apollo.QueryResult<OrderContentQuery, OrderContentQueryVariables>;
export const SellerOrderDocument = gql`
  query SellerOrder($input: OrderByIdInput!) {
    order: user {
      seller {
        order(input: $input) {
          order {
            id
            ID
            orderDate
            status
            isProblem
            sellerId: sellerIdSnapshot
            sellerNickname
            customerId: userIdSnapshot
            customerNickname: userNicknameSnapshot
            customerPhone: userPhoneSnapshot
            customerLast4: userCardLast4
            japaneseOrder
            accepted
            trustedOrder
            shippingFee
            yamatoShipment {
              slipNumber
              status
              reserveDateLimit
              reservePassword
              isPickup
              shukaDate
              shukaTime
              qrCode {
                main_file {
                  url
                }
              }
            }
            overallDimensions {
              weight
              boxHeight
              boxWidth
              boxLength
            }
            billingAddress {
              title
              address
              address1
              address2
              zipCode
              state
              stateCode
              city
              country
            }
            destinationAddress {
              title
              address
              address1
              address2
              zipCode
              state
              stateCode
              city
              country
              phone
              fullName
            }
            deliveryCost
            isPaid
          }
          packages {
            id
            orderId
            deliveryPriceFulfilled
            decodedQr
            pickupRequestUuid
            fedexStatus
            kuroneko
            packages {
              id
              weight
              height
              width
              length
            }
          }
          packaging {
            carryingDate
            carryingTime
            address
            address1
            city
            country
            id
            numberOfSeats
            orderId
            zipCode
            province
            countryCode
            countryId
            email
            fullName
            region
          }
          parcels {
            id
            orderID
            addressLines
            parcels {
              id
              deliveryCompany
              trackingNumber
              deliveryCompany
              boxes
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSellerOrderQuery__
 *
 * To run a query within a React component, call `useSellerOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSellerOrderQuery(baseOptions: Apollo.QueryHookOptions<SellerOrderQuery, SellerOrderQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SellerOrderQuery, SellerOrderQueryVariables>(SellerOrderDocument, options);
}
export function useSellerOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SellerOrderQuery, SellerOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SellerOrderQuery, SellerOrderQueryVariables>(SellerOrderDocument, options);
}
export type SellerOrderQueryHookResult = ReturnType<typeof useSellerOrderQuery>;
export type SellerOrderLazyQueryHookResult = ReturnType<typeof useSellerOrderLazyQuery>;
export type SellerOrderQueryResult = Apollo.QueryResult<SellerOrderQuery, SellerOrderQueryVariables>;
export const SellerOrderContentDocument = gql`
  query SellerOrderContent($input: OrderByIdInput!) {
    order: user {
      seller {
        order(input: $input) {
          content {
            productId
            id
            name
            price
            colorName
            status
            kuroneko
            specificValues {
              attributeName
              variantValue
            }
            weight
            boxLength
            boxHeight
            boxWidth
            mainPhoto {
              id
              ...localThumbnailAll
            }
            commission
          }
          pagination {
            offset
            limit
            totalCount
          }
        }
      }
    }
  }
  ${LocalThumbnailAllFragmentDoc}
`;

/**
 * __useSellerOrderContentQuery__
 *
 * To run a query within a React component, call `useSellerOrderContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerOrderContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerOrderContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSellerOrderContentQuery(
  baseOptions: Apollo.QueryHookOptions<SellerOrderContentQuery, SellerOrderContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SellerOrderContentQuery, SellerOrderContentQueryVariables>(
    SellerOrderContentDocument,
    options
  );
}
export function useSellerOrderContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SellerOrderContentQuery, SellerOrderContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SellerOrderContentQuery, SellerOrderContentQueryVariables>(
    SellerOrderContentDocument,
    options
  );
}
export type SellerOrderContentQueryHookResult = ReturnType<typeof useSellerOrderContentQuery>;
export type SellerOrderContentLazyQueryHookResult = ReturnType<typeof useSellerOrderContentLazyQuery>;
export type SellerOrderContentQueryResult = Apollo.QueryResult<
  SellerOrderContentQuery,
  SellerOrderContentQueryVariables
>;
export const ReferenceNumberDocument = gql`
  query ReferenceNumber($orderId: String!) {
    result: checkReferenceNumber(orderId: $orderId) {
      referenceNumber
      status
    }
  }
`;

/**
 * __useReferenceNumberQuery__
 *
 * To run a query within a React component, call `useReferenceNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferenceNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferenceNumberQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useReferenceNumberQuery(
  baseOptions: Apollo.QueryHookOptions<ReferenceNumberQuery, ReferenceNumberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReferenceNumberQuery, ReferenceNumberQueryVariables>(ReferenceNumberDocument, options);
}
export function useReferenceNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReferenceNumberQuery, ReferenceNumberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReferenceNumberQuery, ReferenceNumberQueryVariables>(ReferenceNumberDocument, options);
}
export type ReferenceNumberQueryHookResult = ReturnType<typeof useReferenceNumberQuery>;
export type ReferenceNumberLazyQueryHookResult = ReturnType<typeof useReferenceNumberLazyQuery>;
export type ReferenceNumberQueryResult = Apollo.QueryResult<ReferenceNumberQuery, ReferenceNumberQueryVariables>;
export const CheckCustomerRateForOrderDocument = gql`
  query CheckCustomerRateForOrder($orderId: String!) {
    result: checkCustomerRateForOrder(orderId: $orderId)
  }
`;

/**
 * __useCheckCustomerRateForOrderQuery__
 *
 * To run a query within a React component, call `useCheckCustomerRateForOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCustomerRateForOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCustomerRateForOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCheckCustomerRateForOrderQuery(
  baseOptions: Apollo.QueryHookOptions<CheckCustomerRateForOrderQuery, CheckCustomerRateForOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckCustomerRateForOrderQuery, CheckCustomerRateForOrderQueryVariables>(
    CheckCustomerRateForOrderDocument,
    options
  );
}
export function useCheckCustomerRateForOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckCustomerRateForOrderQuery, CheckCustomerRateForOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckCustomerRateForOrderQuery, CheckCustomerRateForOrderQueryVariables>(
    CheckCustomerRateForOrderDocument,
    options
  );
}
export type CheckCustomerRateForOrderQueryHookResult = ReturnType<typeof useCheckCustomerRateForOrderQuery>;
export type CheckCustomerRateForOrderLazyQueryHookResult = ReturnType<typeof useCheckCustomerRateForOrderLazyQuery>;
export type CheckCustomerRateForOrderQueryResult = Apollo.QueryResult<
  CheckCustomerRateForOrderQuery,
  CheckCustomerRateForOrderQueryVariables
>;
export const CheckSellerRateForOrderDocument = gql`
  query CheckSellerRateForOrder($orderId: String!) {
    result: checkSellerRateForOrder(orderId: $orderId)
  }
`;

/**
 * __useCheckSellerRateForOrderQuery__
 *
 * To run a query within a React component, call `useCheckSellerRateForOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckSellerRateForOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckSellerRateForOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCheckSellerRateForOrderQuery(
  baseOptions: Apollo.QueryHookOptions<CheckSellerRateForOrderQuery, CheckSellerRateForOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckSellerRateForOrderQuery, CheckSellerRateForOrderQueryVariables>(
    CheckSellerRateForOrderDocument,
    options
  );
}
export function useCheckSellerRateForOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckSellerRateForOrderQuery, CheckSellerRateForOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckSellerRateForOrderQuery, CheckSellerRateForOrderQueryVariables>(
    CheckSellerRateForOrderDocument,
    options
  );
}
export type CheckSellerRateForOrderQueryHookResult = ReturnType<typeof useCheckSellerRateForOrderQuery>;
export type CheckSellerRateForOrderLazyQueryHookResult = ReturnType<typeof useCheckSellerRateForOrderLazyQuery>;
export type CheckSellerRateForOrderQueryResult = Apollo.QueryResult<
  CheckSellerRateForOrderQuery,
  CheckSellerRateForOrderQueryVariables
>;
export const SellerOrdersDocument = gql`
  query SellerOrders($input: OrdersInput) {
    orders: user {
      seller {
        orders(input: $input) {
          entries {
            order {
              id
              ID
              orderDate
              status
              isProblem
              customerId: userIdSnapshot
              customerNickname: userNicknameSnapshot
              trustedOrder
            }
            contents {
              productId
              id
              name
              price
              colorName
              status
              specificValues {
                attributeName
                variantValue
              }
              mainPhoto {
                id
                msfiles_uid
                ...localThumbnailAll
              }
              commission
            }
          }
          pagination {
            ...pagination
          }
        }
      }
    }
  }
  ${LocalThumbnailAllFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useSellerOrdersQuery__
 *
 * To run a query within a React component, call `useSellerOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerOrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSellerOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<SellerOrdersQuery, SellerOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SellerOrdersQuery, SellerOrdersQueryVariables>(SellerOrdersDocument, options);
}
export function useSellerOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SellerOrdersQuery, SellerOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SellerOrdersQuery, SellerOrdersQueryVariables>(SellerOrdersDocument, options);
}
export type SellerOrdersQueryHookResult = ReturnType<typeof useSellerOrdersQuery>;
export type SellerOrdersLazyQueryHookResult = ReturnType<typeof useSellerOrdersLazyQuery>;
export type SellerOrdersQueryResult = Apollo.QueryResult<SellerOrdersQuery, SellerOrdersQueryVariables>;
export const UserDocument = gql`
  query User {
    user: getUser {
      ...userBase
    }
  }
  ${UserBaseFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const SendNewCustomerServiceMessageDocument = gql`
  mutation SendNewCustomerServiceMessage($input: CreateCustomerServiceMessageInput!) {
    success: sendNewCustomerServiceMessage(input: $input)
  }
`;
export type SendNewCustomerServiceMessageMutationFn = Apollo.MutationFunction<
  SendNewCustomerServiceMessageMutation,
  SendNewCustomerServiceMessageMutationVariables
>;

/**
 * __useSendNewCustomerServiceMessageMutation__
 *
 * To run a mutation, you first call `useSendNewCustomerServiceMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNewCustomerServiceMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNewCustomerServiceMessageMutation, { data, loading, error }] = useSendNewCustomerServiceMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendNewCustomerServiceMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendNewCustomerServiceMessageMutation,
    SendNewCustomerServiceMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendNewCustomerServiceMessageMutation, SendNewCustomerServiceMessageMutationVariables>(
    SendNewCustomerServiceMessageDocument,
    options
  );
}
export type SendNewCustomerServiceMessageMutationHookResult = ReturnType<
  typeof useSendNewCustomerServiceMessageMutation
>;
export type SendNewCustomerServiceMessageMutationResult = Apollo.MutationResult<SendNewCustomerServiceMessageMutation>;
export type SendNewCustomerServiceMessageMutationOptions = Apollo.BaseMutationOptions<
  SendNewCustomerServiceMessageMutation,
  SendNewCustomerServiceMessageMutationVariables
>;
export const ComplaintTypesDocument = gql`
  query ComplaintTypes {
    complaintTypes {
      productComplaintTypes {
        uid
        name
      }
    }
  }
`;

/**
 * __useComplaintTypesQuery__
 *
 * To run a query within a React component, call `useComplaintTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useComplaintTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComplaintTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useComplaintTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<ComplaintTypesQuery, ComplaintTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ComplaintTypesQuery, ComplaintTypesQueryVariables>(ComplaintTypesDocument, options);
}
export function useComplaintTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ComplaintTypesQuery, ComplaintTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ComplaintTypesQuery, ComplaintTypesQueryVariables>(ComplaintTypesDocument, options);
}
export type ComplaintTypesQueryHookResult = ReturnType<typeof useComplaintTypesQuery>;
export type ComplaintTypesLazyQueryHookResult = ReturnType<typeof useComplaintTypesLazyQuery>;
export type ComplaintTypesQueryResult = Apollo.QueryResult<ComplaintTypesQuery, ComplaintTypesQueryVariables>;
export const CreateProductComplaintDocument = gql`
  mutation CreateProductComplaint($input: CreateProductComplaintInput!) {
    success: createProductComplaint(input: $input)
  }
`;
export type CreateProductComplaintMutationFn = Apollo.MutationFunction<
  CreateProductComplaintMutation,
  CreateProductComplaintMutationVariables
>;

/**
 * __useCreateProductComplaintMutation__
 *
 * To run a mutation, you first call `useCreateProductComplaintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductComplaintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductComplaintMutation, { data, loading, error }] = useCreateProductComplaintMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductComplaintMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProductComplaintMutation, CreateProductComplaintMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProductComplaintMutation, CreateProductComplaintMutationVariables>(
    CreateProductComplaintDocument,
    options
  );
}
export type CreateProductComplaintMutationHookResult = ReturnType<typeof useCreateProductComplaintMutation>;
export type CreateProductComplaintMutationResult = Apollo.MutationResult<CreateProductComplaintMutation>;
export type CreateProductComplaintMutationOptions = Apollo.BaseMutationOptions<
  CreateProductComplaintMutation,
  CreateProductComplaintMutationVariables
>;
export const AddProductToFavoritesDocument = gql`
  mutation AddProductToFavorites($productId: String!) {
    result: addProductToFavorites(productId: $productId)
  }
`;
export type AddProductToFavoritesMutationFn = Apollo.MutationFunction<
  AddProductToFavoritesMutation,
  AddProductToFavoritesMutationVariables
>;

/**
 * __useAddProductToFavoritesMutation__
 *
 * To run a mutation, you first call `useAddProductToFavoritesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductToFavoritesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductToFavoritesMutation, { data, loading, error }] = useAddProductToFavoritesMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useAddProductToFavoritesMutation(
  baseOptions?: Apollo.MutationHookOptions<AddProductToFavoritesMutation, AddProductToFavoritesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddProductToFavoritesMutation, AddProductToFavoritesMutationVariables>(
    AddProductToFavoritesDocument,
    options
  );
}
export type AddProductToFavoritesMutationHookResult = ReturnType<typeof useAddProductToFavoritesMutation>;
export type AddProductToFavoritesMutationResult = Apollo.MutationResult<AddProductToFavoritesMutation>;
export type AddProductToFavoritesMutationOptions = Apollo.BaseMutationOptions<
  AddProductToFavoritesMutation,
  AddProductToFavoritesMutationVariables
>;
export const MoveProductToFavoritesDocument = gql`
  mutation MoveProductToFavorites($productId: String!) {
    result: moveToFavorites(productId: $productId)
  }
`;
export type MoveProductToFavoritesMutationFn = Apollo.MutationFunction<
  MoveProductToFavoritesMutation,
  MoveProductToFavoritesMutationVariables
>;

/**
 * __useMoveProductToFavoritesMutation__
 *
 * To run a mutation, you first call `useMoveProductToFavoritesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveProductToFavoritesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveProductToFavoritesMutation, { data, loading, error }] = useMoveProductToFavoritesMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useMoveProductToFavoritesMutation(
  baseOptions?: Apollo.MutationHookOptions<MoveProductToFavoritesMutation, MoveProductToFavoritesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MoveProductToFavoritesMutation, MoveProductToFavoritesMutationVariables>(
    MoveProductToFavoritesDocument,
    options
  );
}
export type MoveProductToFavoritesMutationHookResult = ReturnType<typeof useMoveProductToFavoritesMutation>;
export type MoveProductToFavoritesMutationResult = Apollo.MutationResult<MoveProductToFavoritesMutation>;
export type MoveProductToFavoritesMutationOptions = Apollo.BaseMutationOptions<
  MoveProductToFavoritesMutation,
  MoveProductToFavoritesMutationVariables
>;
export const RemoveAllProductsFromFavoritesDocument = gql`
  mutation RemoveAllProductsFromFavorites {
    result: removeAllProductsFromFavorites
  }
`;
export type RemoveAllProductsFromFavoritesMutationFn = Apollo.MutationFunction<
  RemoveAllProductsFromFavoritesMutation,
  RemoveAllProductsFromFavoritesMutationVariables
>;

/**
 * __useRemoveAllProductsFromFavoritesMutation__
 *
 * To run a mutation, you first call `useRemoveAllProductsFromFavoritesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAllProductsFromFavoritesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAllProductsFromFavoritesMutation, { data, loading, error }] = useRemoveAllProductsFromFavoritesMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveAllProductsFromFavoritesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAllProductsFromFavoritesMutation,
    RemoveAllProductsFromFavoritesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveAllProductsFromFavoritesMutation, RemoveAllProductsFromFavoritesMutationVariables>(
    RemoveAllProductsFromFavoritesDocument,
    options
  );
}
export type RemoveAllProductsFromFavoritesMutationHookResult = ReturnType<
  typeof useRemoveAllProductsFromFavoritesMutation
>;
export type RemoveAllProductsFromFavoritesMutationResult =
  Apollo.MutationResult<RemoveAllProductsFromFavoritesMutation>;
export type RemoveAllProductsFromFavoritesMutationOptions = Apollo.BaseMutationOptions<
  RemoveAllProductsFromFavoritesMutation,
  RemoveAllProductsFromFavoritesMutationVariables
>;
export const RemoveProductFromFavoritesDocument = gql`
  mutation RemoveProductFromFavorites($productId: String!) {
    result: removeProductFromFavorites(productId: $productId)
  }
`;
export type RemoveProductFromFavoritesMutationFn = Apollo.MutationFunction<
  RemoveProductFromFavoritesMutation,
  RemoveProductFromFavoritesMutationVariables
>;

/**
 * __useRemoveProductFromFavoritesMutation__
 *
 * To run a mutation, you first call `useRemoveProductFromFavoritesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProductFromFavoritesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProductFromFavoritesMutation, { data, loading, error }] = useRemoveProductFromFavoritesMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useRemoveProductFromFavoritesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveProductFromFavoritesMutation,
    RemoveProductFromFavoritesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveProductFromFavoritesMutation, RemoveProductFromFavoritesMutationVariables>(
    RemoveProductFromFavoritesDocument,
    options
  );
}
export type RemoveProductFromFavoritesMutationHookResult = ReturnType<typeof useRemoveProductFromFavoritesMutation>;
export type RemoveProductFromFavoritesMutationResult = Apollo.MutationResult<RemoveProductFromFavoritesMutation>;
export type RemoveProductFromFavoritesMutationOptions = Apollo.BaseMutationOptions<
  RemoveProductFromFavoritesMutation,
  RemoveProductFromFavoritesMutationVariables
>;
export const AddPushTokenToUserDocument = gql`
  mutation addPushTokenToUser($input: DeviceTokenAddInput!) {
    response: addPushTokenToUser(input: $input)
  }
`;
export type AddPushTokenToUserMutationFn = Apollo.MutationFunction<
  AddPushTokenToUserMutation,
  AddPushTokenToUserMutationVariables
>;

/**
 * __useAddPushTokenToUserMutation__
 *
 * To run a mutation, you first call `useAddPushTokenToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPushTokenToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPushTokenToUserMutation, { data, loading, error }] = useAddPushTokenToUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPushTokenToUserMutation(
  baseOptions?: Apollo.MutationHookOptions<AddPushTokenToUserMutation, AddPushTokenToUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPushTokenToUserMutation, AddPushTokenToUserMutationVariables>(
    AddPushTokenToUserDocument,
    options
  );
}
export type AddPushTokenToUserMutationHookResult = ReturnType<typeof useAddPushTokenToUserMutation>;
export type AddPushTokenToUserMutationResult = Apollo.MutationResult<AddPushTokenToUserMutation>;
export type AddPushTokenToUserMutationOptions = Apollo.BaseMutationOptions<
  AddPushTokenToUserMutation,
  AddPushTokenToUserMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UserCredentialsInput!) {
    user: fillUserMetadataFields(input: $input) {
      ...userBase
    }
  }
  ${UserBaseFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const AttachmentsDocument = gql`
  query Attachments($filter: FilterAttachmentsInput, $pagination: PaginationInput!, $sort: SortAttachmentsInput!) {
    attachments(filter: $filter, pagination: $pagination, sort: $sort) {
      entries {
        id
        msfiles_uid
        title
        upload_status
        type
        main_file {
          id
          url
        }
        thumbnails {
          S {
            url
          }
          M {
            url
          }
          L {
            url
          }
        }
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useAttachmentsQuery__
 *
 * To run a query within a React component, call `useAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAttachmentsQuery(baseOptions: Apollo.QueryHookOptions<AttachmentsQuery, AttachmentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AttachmentsQuery, AttachmentsQueryVariables>(AttachmentsDocument, options);
}
export function useAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AttachmentsQuery, AttachmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AttachmentsQuery, AttachmentsQueryVariables>(AttachmentsDocument, options);
}
export type AttachmentsQueryHookResult = ReturnType<typeof useAttachmentsQuery>;
export type AttachmentsLazyQueryHookResult = ReturnType<typeof useAttachmentsLazyQuery>;
export type AttachmentsQueryResult = Apollo.QueryResult<AttachmentsQuery, AttachmentsQueryVariables>;
export const CreateUploadUrl_V2Document = gql`
  mutation CreateUploadUrl_v2($input: CreateUploadUrlInput!) {
    createUploadUrlV2(input: $input) {
      url
      code
    }
  }
`;
export type CreateUploadUrl_V2MutationFn = Apollo.MutationFunction<
  CreateUploadUrl_V2Mutation,
  CreateUploadUrl_V2MutationVariables
>;

/**
 * __useCreateUploadUrl_V2Mutation__
 *
 * To run a mutation, you first call `useCreateUploadUrl_V2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadUrl_V2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadUrlV2Mutation, { data, loading, error }] = useCreateUploadUrl_V2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUploadUrl_V2Mutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUploadUrl_V2Mutation, CreateUploadUrl_V2MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUploadUrl_V2Mutation, CreateUploadUrl_V2MutationVariables>(
    CreateUploadUrl_V2Document,
    options
  );
}
export type CreateUploadUrl_V2MutationHookResult = ReturnType<typeof useCreateUploadUrl_V2Mutation>;
export type CreateUploadUrl_V2MutationResult = Apollo.MutationResult<CreateUploadUrl_V2Mutation>;
export type CreateUploadUrl_V2MutationOptions = Apollo.BaseMutationOptions<
  CreateUploadUrl_V2Mutation,
  CreateUploadUrl_V2MutationVariables
>;
export const CatalogNavigationDocument = gql`
  query CatalogNavigation {
    categories: getCategories {
      id
      name
      subCategoryIds
    }
    subCategories: getSubCategories {
      id
      name
      subSubCategoryIds
    }
    subSubCategories: getSubSubCategories {
      id
      name
    }
  }
`;

/**
 * __useCatalogNavigationQuery__
 *
 * To run a query within a React component, call `useCatalogNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogNavigationQuery({
 *   variables: {
 *   },
 * });
 */
export function useCatalogNavigationQuery(
  baseOptions?: Apollo.QueryHookOptions<CatalogNavigationQuery, CatalogNavigationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CatalogNavigationQuery, CatalogNavigationQueryVariables>(CatalogNavigationDocument, options);
}
export function useCatalogNavigationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CatalogNavigationQuery, CatalogNavigationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CatalogNavigationQuery, CatalogNavigationQueryVariables>(
    CatalogNavigationDocument,
    options
  );
}
export type CatalogNavigationQueryHookResult = ReturnType<typeof useCatalogNavigationQuery>;
export type CatalogNavigationLazyQueryHookResult = ReturnType<typeof useCatalogNavigationLazyQuery>;
export type CatalogNavigationQueryResult = Apollo.QueryResult<CatalogNavigationQuery, CatalogNavigationQueryVariables>;
export const CatalogProductsDocument = gql`
  query CatalogProducts($input: ProductsPaginationWithFiltersInput) {
    products(input: $input) {
      entries {
        id
        name
        description
        price
        mainPhoto {
          id
          ...localThumbnailAll
        }
        categoryId
        subCategoryId
        subSubCategoryId
        brandId
        sellerId
        colorId
        status
        favorite
      }
      pagination {
        ...pagination
      }
    }
  }
  ${LocalThumbnailAllFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useCatalogProductsQuery__
 *
 * To run a query within a React component, call `useCatalogProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCatalogProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<CatalogProductsQuery, CatalogProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CatalogProductsQuery, CatalogProductsQueryVariables>(CatalogProductsDocument, options);
}
export function useCatalogProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CatalogProductsQuery, CatalogProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CatalogProductsQuery, CatalogProductsQueryVariables>(CatalogProductsDocument, options);
}
export type CatalogProductsQueryHookResult = ReturnType<typeof useCatalogProductsQuery>;
export type CatalogProductsLazyQueryHookResult = ReturnType<typeof useCatalogProductsLazyQuery>;
export type CatalogProductsQueryResult = Apollo.QueryResult<CatalogProductsQuery, CatalogProductsQueryVariables>;
export const CatalogAttributesDocument = gql`
  query CatalogAttributes {
    attributes: getSpecificAttributes {
      id
      name
      subSubCategoryIds
      variants {
        id
        value
      }
    }
  }
`;

/**
 * __useCatalogAttributesQuery__
 *
 * To run a query within a React component, call `useCatalogAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCatalogAttributesQuery(
  baseOptions?: Apollo.QueryHookOptions<CatalogAttributesQuery, CatalogAttributesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CatalogAttributesQuery, CatalogAttributesQueryVariables>(CatalogAttributesDocument, options);
}
export function useCatalogAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CatalogAttributesQuery, CatalogAttributesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CatalogAttributesQuery, CatalogAttributesQueryVariables>(
    CatalogAttributesDocument,
    options
  );
}
export type CatalogAttributesQueryHookResult = ReturnType<typeof useCatalogAttributesQuery>;
export type CatalogAttributesLazyQueryHookResult = ReturnType<typeof useCatalogAttributesLazyQuery>;
export type CatalogAttributesQueryResult = Apollo.QueryResult<CatalogAttributesQuery, CatalogAttributesQueryVariables>;
export const CatalogBrandsDocument = gql`
  query CatalogBrands($input: BrandSearchInput!) {
    brands(input: $input) {
      entries {
        id
        name
      }
      pagination {
        ...pagination
      }
      totalUnfilteredBrands
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useCatalogBrandsQuery__
 *
 * To run a query within a React component, call `useCatalogBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogBrandsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCatalogBrandsQuery(
  baseOptions: Apollo.QueryHookOptions<CatalogBrandsQuery, CatalogBrandsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CatalogBrandsQuery, CatalogBrandsQueryVariables>(CatalogBrandsDocument, options);
}
export function useCatalogBrandsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CatalogBrandsQuery, CatalogBrandsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CatalogBrandsQuery, CatalogBrandsQueryVariables>(CatalogBrandsDocument, options);
}
export type CatalogBrandsQueryHookResult = ReturnType<typeof useCatalogBrandsQuery>;
export type CatalogBrandsLazyQueryHookResult = ReturnType<typeof useCatalogBrandsLazyQuery>;
export type CatalogBrandsQueryResult = Apollo.QueryResult<CatalogBrandsQuery, CatalogBrandsQueryVariables>;
export const CatalogColorsDocument = gql`
  query CatalogColors {
    colors: getColors {
      id
      name: colorName
      colorCode: code
    }
  }
`;

/**
 * __useCatalogColorsQuery__
 *
 * To run a query within a React component, call `useCatalogColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogColorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCatalogColorsQuery(
  baseOptions?: Apollo.QueryHookOptions<CatalogColorsQuery, CatalogColorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CatalogColorsQuery, CatalogColorsQueryVariables>(CatalogColorsDocument, options);
}
export function useCatalogColorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CatalogColorsQuery, CatalogColorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CatalogColorsQuery, CatalogColorsQueryVariables>(CatalogColorsDocument, options);
}
export type CatalogColorsQueryHookResult = ReturnType<typeof useCatalogColorsQuery>;
export type CatalogColorsLazyQueryHookResult = ReturnType<typeof useCatalogColorsLazyQuery>;
export type CatalogColorsQueryResult = Apollo.QueryResult<CatalogColorsQuery, CatalogColorsQueryVariables>;
export const HomePageDocument = gql`
  query HomePage($sort: ProductsSort, $limit: Int!, $offset: Int!) {
    products(input: { limit: $limit, offset: $offset, sort: $sort }) {
      entries {
        id
        name
        price
        status
        sellerId
        mainPhoto {
          id
          ...localThumbnailAll
        }
        favorite
      }
      pagination {
        totalCount
        offset
        limit
      }
    }
    banners: getBanners {
      name
      description
      redirectUrl
      active
      files {
        ...thumbnailLarge
      }
    }
  }
  ${LocalThumbnailAllFragmentDoc}
  ${ThumbnailLargeFragmentDoc}
`;

/**
 * __useHomePageQuery__
 *
 * To run a query within a React component, call `useHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useHomePageQuery(baseOptions: Apollo.QueryHookOptions<HomePageQuery, HomePageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomePageQuery, HomePageQueryVariables>(HomePageDocument, options);
}
export function useHomePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomePageQuery, HomePageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomePageQuery, HomePageQueryVariables>(HomePageDocument, options);
}
export type HomePageQueryHookResult = ReturnType<typeof useHomePageQuery>;
export type HomePageLazyQueryHookResult = ReturnType<typeof useHomePageLazyQuery>;
export type HomePageQueryResult = Apollo.QueryResult<HomePageQuery, HomePageQueryVariables>;
export const NotificationsDocument = gql`
  query Notifications($input: NotificationCommonInput) {
    notifications: getNotifications(input: $input) {
      entries {
        id
        userId
        createdAt
        event
        priority
        read
        text
        orderUrl
        productUrl
        rate
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
}
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const ConfirmOrderDocument = gql`
  mutation ConfirmOrder($input: OrderConfirmationInput!) {
    confirmed: confirmOrder(input: $input)
  }
`;
export type ConfirmOrderMutationFn = Apollo.MutationFunction<ConfirmOrderMutation, ConfirmOrderMutationVariables>;

/**
 * __useConfirmOrderMutation__
 *
 * To run a mutation, you first call `useConfirmOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmOrderMutation, { data, loading, error }] = useConfirmOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<ConfirmOrderMutation, ConfirmOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmOrderMutation, ConfirmOrderMutationVariables>(ConfirmOrderDocument, options);
}
export type ConfirmOrderMutationHookResult = ReturnType<typeof useConfirmOrderMutation>;
export type ConfirmOrderMutationResult = Apollo.MutationResult<ConfirmOrderMutation>;
export type ConfirmOrderMutationOptions = Apollo.BaseMutationOptions<
  ConfirmOrderMutation,
  ConfirmOrderMutationVariables
>;
export const ConfirmDebtPaymentDocument = gql`
  mutation confirmDebtPayment($debtId: String!) {
    confirmed: confirmDebtPayment(debtId: $debtId)
  }
`;
export type ConfirmDebtPaymentMutationFn = Apollo.MutationFunction<
  ConfirmDebtPaymentMutation,
  ConfirmDebtPaymentMutationVariables
>;

/**
 * __useConfirmDebtPaymentMutation__
 *
 * To run a mutation, you first call `useConfirmDebtPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmDebtPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmDebtPaymentMutation, { data, loading, error }] = useConfirmDebtPaymentMutation({
 *   variables: {
 *      debtId: // value for 'debtId'
 *   },
 * });
 */
export function useConfirmDebtPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<ConfirmDebtPaymentMutation, ConfirmDebtPaymentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmDebtPaymentMutation, ConfirmDebtPaymentMutationVariables>(
    ConfirmDebtPaymentDocument,
    options
  );
}
export type ConfirmDebtPaymentMutationHookResult = ReturnType<typeof useConfirmDebtPaymentMutation>;
export type ConfirmDebtPaymentMutationResult = Apollo.MutationResult<ConfirmDebtPaymentMutation>;
export type ConfirmDebtPaymentMutationOptions = Apollo.BaseMutationOptions<
  ConfirmDebtPaymentMutation,
  ConfirmDebtPaymentMutationVariables
>;
export const UpsertPaymentIntentDocument = gql`
  mutation UpsertPaymentIntent($debtId: String) {
    paymentIntent: upsertPaymentIntent(debtId: $debtId)
  }
`;
export type UpsertPaymentIntentMutationFn = Apollo.MutationFunction<
  UpsertPaymentIntentMutation,
  UpsertPaymentIntentMutationVariables
>;

/**
 * __useUpsertPaymentIntentMutation__
 *
 * To run a mutation, you first call `useUpsertPaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPaymentIntentMutation, { data, loading, error }] = useUpsertPaymentIntentMutation({
 *   variables: {
 *      debtId: // value for 'debtId'
 *   },
 * });
 */
export function useUpsertPaymentIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertPaymentIntentMutation, UpsertPaymentIntentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertPaymentIntentMutation, UpsertPaymentIntentMutationVariables>(
    UpsertPaymentIntentDocument,
    options
  );
}
export type UpsertPaymentIntentMutationHookResult = ReturnType<typeof useUpsertPaymentIntentMutation>;
export type UpsertPaymentIntentMutationResult = Apollo.MutationResult<UpsertPaymentIntentMutation>;
export type UpsertPaymentIntentMutationOptions = Apollo.BaseMutationOptions<
  UpsertPaymentIntentMutation,
  UpsertPaymentIntentMutationVariables
>;
export const DeliveryMetadataDocument = gql`
  query DeliveryMetadata($addressId: String!, $productIds: [String!]!) {
    metadata: getDeliveryPriceForOrder(addressId: $addressId, productIds: $productIds) {
      deliveryDaysMax
      deliveryDaysMin
      deliveryPrice
    }
  }
`;

/**
 * __useDeliveryMetadataQuery__
 *
 * To run a query within a React component, call `useDeliveryMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryMetadataQuery({
 *   variables: {
 *      addressId: // value for 'addressId'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useDeliveryMetadataQuery(
  baseOptions: Apollo.QueryHookOptions<DeliveryMetadataQuery, DeliveryMetadataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DeliveryMetadataQuery, DeliveryMetadataQueryVariables>(DeliveryMetadataDocument, options);
}
export function useDeliveryMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DeliveryMetadataQuery, DeliveryMetadataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DeliveryMetadataQuery, DeliveryMetadataQueryVariables>(DeliveryMetadataDocument, options);
}
export type DeliveryMetadataQueryHookResult = ReturnType<typeof useDeliveryMetadataQuery>;
export type DeliveryMetadataLazyQueryHookResult = ReturnType<typeof useDeliveryMetadataLazyQuery>;
export type DeliveryMetadataQueryResult = Apollo.QueryResult<DeliveryMetadataQuery, DeliveryMetadataQueryVariables>;
export const CreateCommentDocument = gql`
  mutation CreateComment($input: CreateProductCommentInput!) {
    response: createComment(input: $input)
  }
`;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
}
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateCommentMutation,
  CreateCommentMutationVariables
>;
export const CreateCommentReplyDocument = gql`
  mutation CreateCommentReply($input: CreateProductCommentInput!) {
    response: createCommentReply(input: $input)
  }
`;
export type CreateCommentReplyMutationFn = Apollo.MutationFunction<
  CreateCommentReplyMutation,
  CreateCommentReplyMutationVariables
>;

/**
 * __useCreateCommentReplyMutation__
 *
 * To run a mutation, you first call `useCreateCommentReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentReplyMutation, { data, loading, error }] = useCreateCommentReplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentReplyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCommentReplyMutation, CreateCommentReplyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCommentReplyMutation, CreateCommentReplyMutationVariables>(
    CreateCommentReplyDocument,
    options
  );
}
export type CreateCommentReplyMutationHookResult = ReturnType<typeof useCreateCommentReplyMutation>;
export type CreateCommentReplyMutationResult = Apollo.MutationResult<CreateCommentReplyMutation>;
export type CreateCommentReplyMutationOptions = Apollo.BaseMutationOptions<
  CreateCommentReplyMutation,
  CreateCommentReplyMutationVariables
>;
export const ProductDocument = gql`
  query Product($id: String!, $commentsPagination: PaginationInput) {
    products(input: { limit: 0, offset: 0, filters: { productIds: [$id] } }) {
      entries {
        id
        lastModified
        name
        description
        price
        photos {
          id
          msfiles_uid
          ...localThumbnailAll
        }
        mainPhoto {
          id
          msfiles_uid
          ...localThumbnailAll
        }
        sellerId
        categoryId
        categoryName
        subCategoryId
        subCategoryName
        subSubCategoryId
        subSubCategoryName
        brandName
        colorName
        deliverySpeedDays
        condition
        status
        specificValues {
          attributeId
          attributeName
          variantId
          variantValue
        }
        weight
        boxHeight
        boxWidth
        boxLength
        favorite
        sellerOtherProducts {
          id
          name
          description
          price
          mainPhoto {
            id
            ...localThumbnailAll
          }
        }
        similarProducts {
          id
          name
          description
          price
          mainPhoto {
            id
            ...localThumbnailAll
          }
        }
        comments(input: $commentsPagination) {
          entries {
            id
            commentary
            commentator
            isSeller
            createdDate
            productId
            parentId
            userId
          }
          pagination {
            ...pagination
          }
        }
      }
    }
  }
  ${LocalThumbnailAllFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *      commentsPagination: // value for 'commentsPagination'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
}
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
}
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const ProductCategoryDocument = gql`
  query ProductCategory($id: String = "") {
    category: getCategoryById(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useProductCategoryQuery__
 *
 * To run a query within a React component, call `useProductCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductCategoryQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductCategoryQuery, ProductCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCategoryQuery, ProductCategoryQueryVariables>(ProductCategoryDocument, options);
}
export function useProductCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductCategoryQuery, ProductCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCategoryQuery, ProductCategoryQueryVariables>(ProductCategoryDocument, options);
}
export type ProductCategoryQueryHookResult = ReturnType<typeof useProductCategoryQuery>;
export type ProductCategoryLazyQueryHookResult = ReturnType<typeof useProductCategoryLazyQuery>;
export type ProductCategoryQueryResult = Apollo.QueryResult<ProductCategoryQuery, ProductCategoryQueryVariables>;
export const ProductSubCategoryDocument = gql`
  query ProductSubCategory($id: String = "") {
    subCategory: getSubCategoryById(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useProductSubCategoryQuery__
 *
 * To run a query within a React component, call `useProductSubCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSubCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSubCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductSubCategoryQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductSubCategoryQuery, ProductSubCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductSubCategoryQuery, ProductSubCategoryQueryVariables>(
    ProductSubCategoryDocument,
    options
  );
}
export function useProductSubCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductSubCategoryQuery, ProductSubCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductSubCategoryQuery, ProductSubCategoryQueryVariables>(
    ProductSubCategoryDocument,
    options
  );
}
export type ProductSubCategoryQueryHookResult = ReturnType<typeof useProductSubCategoryQuery>;
export type ProductSubCategoryLazyQueryHookResult = ReturnType<typeof useProductSubCategoryLazyQuery>;
export type ProductSubCategoryQueryResult = Apollo.QueryResult<
  ProductSubCategoryQuery,
  ProductSubCategoryQueryVariables
>;
export const ProductSubSubCategoryDocument = gql`
  query ProductSubSubCategory($id: String = "") {
    subSubCategory: getSubSubCategoryById(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useProductSubSubCategoryQuery__
 *
 * To run a query within a React component, call `useProductSubSubCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSubSubCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSubSubCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductSubSubCategoryQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductSubSubCategoryQuery, ProductSubSubCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductSubSubCategoryQuery, ProductSubSubCategoryQueryVariables>(
    ProductSubSubCategoryDocument,
    options
  );
}
export function useProductSubSubCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductSubSubCategoryQuery, ProductSubSubCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductSubSubCategoryQuery, ProductSubSubCategoryQueryVariables>(
    ProductSubSubCategoryDocument,
    options
  );
}
export type ProductSubSubCategoryQueryHookResult = ReturnType<typeof useProductSubSubCategoryQuery>;
export type ProductSubSubCategoryLazyQueryHookResult = ReturnType<typeof useProductSubSubCategoryLazyQuery>;
export type ProductSubSubCategoryQueryResult = Apollo.QueryResult<
  ProductSubSubCategoryQuery,
  ProductSubSubCategoryQueryVariables
>;
export const SellerDocument = gql`
  query Seller($id: String!) {
    seller: publicInfo {
      sellers(input: { ids: [$id] }) {
        entries {
          id
          nickname
          rating {
            positive
            neutral
            negative
          }
        }
      }
    }
  }
`;

/**
 * __useSellerQuery__
 *
 * To run a query within a React component, call `useSellerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSellerQuery(baseOptions: Apollo.QueryHookOptions<SellerQuery, SellerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SellerQuery, SellerQueryVariables>(SellerDocument, options);
}
export function useSellerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellerQuery, SellerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SellerQuery, SellerQueryVariables>(SellerDocument, options);
}
export type SellerQueryHookResult = ReturnType<typeof useSellerQuery>;
export type SellerLazyQueryHookResult = ReturnType<typeof useSellerLazyQuery>;
export type SellerQueryResult = Apollo.QueryResult<SellerQuery, SellerQueryVariables>;
export const ProfileLayoutDocument = gql`
  query ProfileLayout {
    user: getUser {
      id
      sellerVerification
      sellerId
    }
  }
`;

/**
 * __useProfileLayoutQuery__
 *
 * To run a query within a React component, call `useProfileLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileLayoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileLayoutQuery(
  baseOptions?: Apollo.QueryHookOptions<ProfileLayoutQuery, ProfileLayoutQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProfileLayoutQuery, ProfileLayoutQueryVariables>(ProfileLayoutDocument, options);
}
export function useProfileLayoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProfileLayoutQuery, ProfileLayoutQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProfileLayoutQuery, ProfileLayoutQueryVariables>(ProfileLayoutDocument, options);
}
export type ProfileLayoutQueryHookResult = ReturnType<typeof useProfileLayoutQuery>;
export type ProfileLayoutLazyQueryHookResult = ReturnType<typeof useProfileLayoutLazyQuery>;
export type ProfileLayoutQueryResult = Apollo.QueryResult<ProfileLayoutQuery, ProfileLayoutQueryVariables>;
export const CreateProductDocument = gql`
  mutation CreateProduct($input: ProductInput!) {
    addProduct(input: $input) {
      id
    }
  }
`;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
}
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<
  CreateProductMutation,
  CreateProductMutationVariables
>;
export const UpdateProductDocument = gql`
  mutation UpdateProduct($input: ProductUpdateInput!) {
    updateProduct(input: $input) {
      id
    }
  }
`;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
}
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductMutation,
  UpdateProductMutationVariables
>;
export const RemoveProductDocument = gql`
  mutation RemoveProduct($productId: String!) {
    removeProduct(productId: $productId)
  }
`;
export type RemoveProductMutationFn = Apollo.MutationFunction<RemoveProductMutation, RemoveProductMutationVariables>;

/**
 * __useRemoveProductMutation__
 *
 * To run a mutation, you first call `useRemoveProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProductMutation, { data, loading, error }] = useRemoveProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useRemoveProductMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveProductMutation, RemoveProductMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveProductMutation, RemoveProductMutationVariables>(RemoveProductDocument, options);
}
export type RemoveProductMutationHookResult = ReturnType<typeof useRemoveProductMutation>;
export type RemoveProductMutationResult = Apollo.MutationResult<RemoveProductMutation>;
export type RemoveProductMutationOptions = Apollo.BaseMutationOptions<
  RemoveProductMutation,
  RemoveProductMutationVariables
>;
export const CreateProductCategoriesDocument = gql`
  query CreateProductCategories {
    categories: getCategories {
      id
      name
    }
  }
`;

/**
 * __useCreateProductCategoriesQuery__
 *
 * To run a query within a React component, call `useCreateProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateProductCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateProductCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<CreateProductCategoriesQuery, CreateProductCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CreateProductCategoriesQuery, CreateProductCategoriesQueryVariables>(
    CreateProductCategoriesDocument,
    options
  );
}
export function useCreateProductCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CreateProductCategoriesQuery, CreateProductCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CreateProductCategoriesQuery, CreateProductCategoriesQueryVariables>(
    CreateProductCategoriesDocument,
    options
  );
}
export type CreateProductCategoriesQueryHookResult = ReturnType<typeof useCreateProductCategoriesQuery>;
export type CreateProductCategoriesLazyQueryHookResult = ReturnType<typeof useCreateProductCategoriesLazyQuery>;
export type CreateProductCategoriesQueryResult = Apollo.QueryResult<
  CreateProductCategoriesQuery,
  CreateProductCategoriesQueryVariables
>;
export const CreateProductSubCategoriesDocument = gql`
  query CreateProductSubCategories {
    subCategories: getSubCategories {
      id
      name
      categoryIds
    }
  }
`;

/**
 * __useCreateProductSubCategoriesQuery__
 *
 * To run a query within a React component, call `useCreateProductSubCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateProductSubCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateProductSubCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateProductSubCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<CreateProductSubCategoriesQuery, CreateProductSubCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CreateProductSubCategoriesQuery, CreateProductSubCategoriesQueryVariables>(
    CreateProductSubCategoriesDocument,
    options
  );
}
export function useCreateProductSubCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CreateProductSubCategoriesQuery, CreateProductSubCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CreateProductSubCategoriesQuery, CreateProductSubCategoriesQueryVariables>(
    CreateProductSubCategoriesDocument,
    options
  );
}
export type CreateProductSubCategoriesQueryHookResult = ReturnType<typeof useCreateProductSubCategoriesQuery>;
export type CreateProductSubCategoriesLazyQueryHookResult = ReturnType<typeof useCreateProductSubCategoriesLazyQuery>;
export type CreateProductSubCategoriesQueryResult = Apollo.QueryResult<
  CreateProductSubCategoriesQuery,
  CreateProductSubCategoriesQueryVariables
>;
export const CreateProductSubSubCategoriesDocument = gql`
  query CreateProductSubSubCategories {
    subSubCategories: getSubSubCategories {
      id
      name
      subCategoryIds
      mandatoryAttribute
    }
  }
`;

/**
 * __useCreateProductSubSubCategoriesQuery__
 *
 * To run a query within a React component, call `useCreateProductSubSubCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateProductSubSubCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateProductSubSubCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateProductSubSubCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<CreateProductSubSubCategoriesQuery, CreateProductSubSubCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CreateProductSubSubCategoriesQuery, CreateProductSubSubCategoriesQueryVariables>(
    CreateProductSubSubCategoriesDocument,
    options
  );
}
export function useCreateProductSubSubCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreateProductSubSubCategoriesQuery,
    CreateProductSubSubCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CreateProductSubSubCategoriesQuery, CreateProductSubSubCategoriesQueryVariables>(
    CreateProductSubSubCategoriesDocument,
    options
  );
}
export type CreateProductSubSubCategoriesQueryHookResult = ReturnType<typeof useCreateProductSubSubCategoriesQuery>;
export type CreateProductSubSubCategoriesLazyQueryHookResult = ReturnType<
  typeof useCreateProductSubSubCategoriesLazyQuery
>;
export type CreateProductSubSubCategoriesQueryResult = Apollo.QueryResult<
  CreateProductSubSubCategoriesQuery,
  CreateProductSubSubCategoriesQueryVariables
>;
export const CreateProductBrandsDocument = gql`
  query CreateProductBrands($input: BrandSearchInput!) {
    data: brands(input: $input) {
      entries {
        id
        name
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useCreateProductBrandsQuery__
 *
 * To run a query within a React component, call `useCreateProductBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateProductBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateProductBrandsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductBrandsQuery(
  baseOptions: Apollo.QueryHookOptions<CreateProductBrandsQuery, CreateProductBrandsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CreateProductBrandsQuery, CreateProductBrandsQueryVariables>(
    CreateProductBrandsDocument,
    options
  );
}
export function useCreateProductBrandsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CreateProductBrandsQuery, CreateProductBrandsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CreateProductBrandsQuery, CreateProductBrandsQueryVariables>(
    CreateProductBrandsDocument,
    options
  );
}
export type CreateProductBrandsQueryHookResult = ReturnType<typeof useCreateProductBrandsQuery>;
export type CreateProductBrandsLazyQueryHookResult = ReturnType<typeof useCreateProductBrandsLazyQuery>;
export type CreateProductBrandsQueryResult = Apollo.QueryResult<
  CreateProductBrandsQuery,
  CreateProductBrandsQueryVariables
>;
export const CreateProductColorsDocument = gql`
  query CreateProductColors {
    colors: getColors {
      id
      name: colorName
      code
    }
  }
`;

/**
 * __useCreateProductColorsQuery__
 *
 * To run a query within a React component, call `useCreateProductColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateProductColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateProductColorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateProductColorsQuery(
  baseOptions?: Apollo.QueryHookOptions<CreateProductColorsQuery, CreateProductColorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CreateProductColorsQuery, CreateProductColorsQueryVariables>(
    CreateProductColorsDocument,
    options
  );
}
export function useCreateProductColorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CreateProductColorsQuery, CreateProductColorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CreateProductColorsQuery, CreateProductColorsQueryVariables>(
    CreateProductColorsDocument,
    options
  );
}
export type CreateProductColorsQueryHookResult = ReturnType<typeof useCreateProductColorsQuery>;
export type CreateProductColorsLazyQueryHookResult = ReturnType<typeof useCreateProductColorsLazyQuery>;
export type CreateProductColorsQueryResult = Apollo.QueryResult<
  CreateProductColorsQuery,
  CreateProductColorsQueryVariables
>;
export const CreateProductSpecificAttributesDocument = gql`
  query CreateProductSpecificAttributes($subSubCategoryId: String!) {
    specificAttributes: getSpecificAttributesBySubSubCategory(subSubCategoryId: $subSubCategoryId) {
      id
      name
      variants {
        id
        value
      }
    }
  }
`;

/**
 * __useCreateProductSpecificAttributesQuery__
 *
 * To run a query within a React component, call `useCreateProductSpecificAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateProductSpecificAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateProductSpecificAttributesQuery({
 *   variables: {
 *      subSubCategoryId: // value for 'subSubCategoryId'
 *   },
 * });
 */
export function useCreateProductSpecificAttributesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CreateProductSpecificAttributesQuery,
    CreateProductSpecificAttributesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CreateProductSpecificAttributesQuery, CreateProductSpecificAttributesQueryVariables>(
    CreateProductSpecificAttributesDocument,
    options
  );
}
export function useCreateProductSpecificAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreateProductSpecificAttributesQuery,
    CreateProductSpecificAttributesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CreateProductSpecificAttributesQuery, CreateProductSpecificAttributesQueryVariables>(
    CreateProductSpecificAttributesDocument,
    options
  );
}
export type CreateProductSpecificAttributesQueryHookResult = ReturnType<typeof useCreateProductSpecificAttributesQuery>;
export type CreateProductSpecificAttributesLazyQueryHookResult = ReturnType<
  typeof useCreateProductSpecificAttributesLazyQuery
>;
export type CreateProductSpecificAttributesQueryResult = Apollo.QueryResult<
  CreateProductSpecificAttributesQuery,
  CreateProductSpecificAttributesQueryVariables
>;
export const CreateProductDataDocument = gql`
  query CreateProductData($id: String!) {
    products(input: { limit: 0, offset: 0, filters: { productIds: [$id], isShowcase: true } }) {
      entries {
        id
        name
        description
        price
        photos {
          id
          msfiles_uid
          ...localThumbnailAll
        }
        mainPhoto {
          id
          msfiles_uid
          ...localThumbnailAll
        }
        sellerId
        categoryId
        subCategoryId
        subSubCategoryId
        brandId
        colorId
        deliverySpeedDays
        condition
        status
        sellerId
        specificValues {
          id
          attributeId
          attributeName
          variantId
          variantValue
        }
        weight
        boxHeight
        boxWidth
        boxLength
        kuroneko
        warehouseNumber
      }
    }
  }
  ${LocalThumbnailAllFragmentDoc}
`;

/**
 * __useCreateProductDataQuery__
 *
 * To run a query within a React component, call `useCreateProductDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateProductDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateProductDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateProductDataQuery(
  baseOptions: Apollo.QueryHookOptions<CreateProductDataQuery, CreateProductDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CreateProductDataQuery, CreateProductDataQueryVariables>(CreateProductDataDocument, options);
}
export function useCreateProductDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CreateProductDataQuery, CreateProductDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CreateProductDataQuery, CreateProductDataQueryVariables>(
    CreateProductDataDocument,
    options
  );
}
export type CreateProductDataQueryHookResult = ReturnType<typeof useCreateProductDataQuery>;
export type CreateProductDataLazyQueryHookResult = ReturnType<typeof useCreateProductDataLazyQuery>;
export type CreateProductDataQueryResult = Apollo.QueryResult<CreateProductDataQuery, CreateProductDataQueryVariables>;
export const CreateProductFixedFeeDocument = gql`
  query CreateProductFixedFee($input: CalculateFixedDeliveryFeeInput!) {
    fee: calculateFixedDeliveryFee(input: $input)
  }
`;

/**
 * __useCreateProductFixedFeeQuery__
 *
 * To run a query within a React component, call `useCreateProductFixedFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateProductFixedFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateProductFixedFeeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductFixedFeeQuery(
  baseOptions: Apollo.QueryHookOptions<CreateProductFixedFeeQuery, CreateProductFixedFeeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CreateProductFixedFeeQuery, CreateProductFixedFeeQueryVariables>(
    CreateProductFixedFeeDocument,
    options
  );
}
export function useCreateProductFixedFeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CreateProductFixedFeeQuery, CreateProductFixedFeeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CreateProductFixedFeeQuery, CreateProductFixedFeeQueryVariables>(
    CreateProductFixedFeeDocument,
    options
  );
}
export type CreateProductFixedFeeQueryHookResult = ReturnType<typeof useCreateProductFixedFeeQuery>;
export type CreateProductFixedFeeLazyQueryHookResult = ReturnType<typeof useCreateProductFixedFeeLazyQuery>;
export type CreateProductFixedFeeQueryResult = Apollo.QueryResult<
  CreateProductFixedFeeQuery,
  CreateProductFixedFeeQueryVariables
>;
export const CreateTicketDocument = gql`
  mutation CreateTicket($input: CreateTicketInput!) {
    result: createTicket(input: $input) {
      ticketId
    }
  }
`;
export type CreateTicketMutationFn = Apollo.MutationFunction<CreateTicketMutation, CreateTicketMutationVariables>;

/**
 * __useCreateTicketMutation__
 *
 * To run a mutation, you first call `useCreateTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketMutation, { data, loading, error }] = useCreateTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTicketMutation, CreateTicketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTicketMutation, CreateTicketMutationVariables>(CreateTicketDocument, options);
}
export type CreateTicketMutationHookResult = ReturnType<typeof useCreateTicketMutation>;
export type CreateTicketMutationResult = Apollo.MutationResult<CreateTicketMutation>;
export type CreateTicketMutationOptions = Apollo.BaseMutationOptions<
  CreateTicketMutation,
  CreateTicketMutationVariables
>;
export const UpsertProductDraftDocument = gql`
  mutation UpsertProductDraft($input: ProductDraftUpsertInput!) {
    draft: upsertProductDraft(input: $input) {
      id
    }
  }
`;
export type UpsertProductDraftMutationFn = Apollo.MutationFunction<
  UpsertProductDraftMutation,
  UpsertProductDraftMutationVariables
>;

/**
 * __useUpsertProductDraftMutation__
 *
 * To run a mutation, you first call `useUpsertProductDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProductDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProductDraftMutation, { data, loading, error }] = useUpsertProductDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertProductDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertProductDraftMutation, UpsertProductDraftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertProductDraftMutation, UpsertProductDraftMutationVariables>(
    UpsertProductDraftDocument,
    options
  );
}
export type UpsertProductDraftMutationHookResult = ReturnType<typeof useUpsertProductDraftMutation>;
export type UpsertProductDraftMutationResult = Apollo.MutationResult<UpsertProductDraftMutation>;
export type UpsertProductDraftMutationOptions = Apollo.BaseMutationOptions<
  UpsertProductDraftMutation,
  UpsertProductDraftMutationVariables
>;
export const RemoveProductDraftDocument = gql`
  mutation RemoveProductDraft($input: ProductDraftRemoveInput!) {
    success: removeProductDraft(input: $input)
  }
`;
export type RemoveProductDraftMutationFn = Apollo.MutationFunction<
  RemoveProductDraftMutation,
  RemoveProductDraftMutationVariables
>;

/**
 * __useRemoveProductDraftMutation__
 *
 * To run a mutation, you first call `useRemoveProductDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProductDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProductDraftMutation, { data, loading, error }] = useRemoveProductDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveProductDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveProductDraftMutation, RemoveProductDraftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveProductDraftMutation, RemoveProductDraftMutationVariables>(
    RemoveProductDraftDocument,
    options
  );
}
export type RemoveProductDraftMutationHookResult = ReturnType<typeof useRemoveProductDraftMutation>;
export type RemoveProductDraftMutationResult = Apollo.MutationResult<RemoveProductDraftMutation>;
export type RemoveProductDraftMutationOptions = Apollo.BaseMutationOptions<
  RemoveProductDraftMutation,
  RemoveProductDraftMutationVariables
>;
export const RemoveAllProductDraftsDocument = gql`
  mutation RemoveAllProductDrafts {
    success: removeProductDraftsBySeller
  }
`;
export type RemoveAllProductDraftsMutationFn = Apollo.MutationFunction<
  RemoveAllProductDraftsMutation,
  RemoveAllProductDraftsMutationVariables
>;

/**
 * __useRemoveAllProductDraftsMutation__
 *
 * To run a mutation, you first call `useRemoveAllProductDraftsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAllProductDraftsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAllProductDraftsMutation, { data, loading, error }] = useRemoveAllProductDraftsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveAllProductDraftsMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveAllProductDraftsMutation, RemoveAllProductDraftsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveAllProductDraftsMutation, RemoveAllProductDraftsMutationVariables>(
    RemoveAllProductDraftsDocument,
    options
  );
}
export type RemoveAllProductDraftsMutationHookResult = ReturnType<typeof useRemoveAllProductDraftsMutation>;
export type RemoveAllProductDraftsMutationResult = Apollo.MutationResult<RemoveAllProductDraftsMutation>;
export type RemoveAllProductDraftsMutationOptions = Apollo.BaseMutationOptions<
  RemoveAllProductDraftsMutation,
  RemoveAllProductDraftsMutationVariables
>;
export const SellerProductDraftsDocument = gql`
  query SellerProductDrafts($input: SellerDraftsInput) {
    user {
      seller {
        id
        drafts(input: $input) {
          entries {
            id
            name
            categoryId
            sellerId
            subCategoryId
            subSubCategoryId
            brandId
            colorId
            condition
            mainPhotoIndex
            deliverySpeedDays
            description
            price
            specificValues {
              id
              attributeId
              variantId
            }
            photos {
              id
              msfiles_uid
              title
              size
              main_file {
                url
              }
              thumbnails {
                id
                S {
                  url
                }
              }
            }
            mainPhoto {
              id
              msfiles_uid
              thumbnails {
                id
                S {
                  url
                }
              }
            }
            boxHeight
            boxWidth
            boxLength
            weight
          }
          pagination {
            ...pagination
          }
        }
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useSellerProductDraftsQuery__
 *
 * To run a query within a React component, call `useSellerProductDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerProductDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerProductDraftsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSellerProductDraftsQuery(
  baseOptions?: Apollo.QueryHookOptions<SellerProductDraftsQuery, SellerProductDraftsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SellerProductDraftsQuery, SellerProductDraftsQueryVariables>(
    SellerProductDraftsDocument,
    options
  );
}
export function useSellerProductDraftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SellerProductDraftsQuery, SellerProductDraftsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SellerProductDraftsQuery, SellerProductDraftsQueryVariables>(
    SellerProductDraftsDocument,
    options
  );
}
export type SellerProductDraftsQueryHookResult = ReturnType<typeof useSellerProductDraftsQuery>;
export type SellerProductDraftsLazyQueryHookResult = ReturnType<typeof useSellerProductDraftsLazyQuery>;
export type SellerProductDraftsQueryResult = Apollo.QueryResult<
  SellerProductDraftsQuery,
  SellerProductDraftsQueryVariables
>;
export const CreateUploadUrlV2Document = gql`
  mutation CreateUploadUrlV2($input: CreateUploadUrlInput!) {
    upload: createUploadUrlV2(input: $input) {
      code
      url
    }
  }
`;
export type CreateUploadUrlV2MutationFn = Apollo.MutationFunction<
  CreateUploadUrlV2Mutation,
  CreateUploadUrlV2MutationVariables
>;

/**
 * __useCreateUploadUrlV2Mutation__
 *
 * To run a mutation, you first call `useCreateUploadUrlV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadUrlV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadUrlV2Mutation, { data, loading, error }] = useCreateUploadUrlV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUploadUrlV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUploadUrlV2Mutation, CreateUploadUrlV2MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUploadUrlV2Mutation, CreateUploadUrlV2MutationVariables>(
    CreateUploadUrlV2Document,
    options
  );
}
export type CreateUploadUrlV2MutationHookResult = ReturnType<typeof useCreateUploadUrlV2Mutation>;
export type CreateUploadUrlV2MutationResult = Apollo.MutationResult<CreateUploadUrlV2Mutation>;
export type CreateUploadUrlV2MutationOptions = Apollo.BaseMutationOptions<
  CreateUploadUrlV2Mutation,
  CreateUploadUrlV2MutationVariables
>;
export const DeleteAttachmentsDocument = gql`
  mutation DeleteAttachments($input: AttachmentIdsInput!) {
    success: deleteAttachments(input: $input)
  }
`;
export type DeleteAttachmentsMutationFn = Apollo.MutationFunction<
  DeleteAttachmentsMutation,
  DeleteAttachmentsMutationVariables
>;

/**
 * __useDeleteAttachmentsMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentsMutation, { data, loading, error }] = useDeleteAttachmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAttachmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAttachmentsMutation, DeleteAttachmentsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAttachmentsMutation, DeleteAttachmentsMutationVariables>(
    DeleteAttachmentsDocument,
    options
  );
}
export type DeleteAttachmentsMutationHookResult = ReturnType<typeof useDeleteAttachmentsMutation>;
export type DeleteAttachmentsMutationResult = Apollo.MutationResult<DeleteAttachmentsMutation>;
export type DeleteAttachmentsMutationOptions = Apollo.BaseMutationOptions<
  DeleteAttachmentsMutation,
  DeleteAttachmentsMutationVariables
>;
export const RemoveProductDraftAttachmentDocument = gql`
  mutation RemoveProductDraftAttachment($input: ProductDraftAttachmentRemoveInput!) {
    success: removeProductDraftAttachment(input: $input)
  }
`;
export type RemoveProductDraftAttachmentMutationFn = Apollo.MutationFunction<
  RemoveProductDraftAttachmentMutation,
  RemoveProductDraftAttachmentMutationVariables
>;

/**
 * __useRemoveProductDraftAttachmentMutation__
 *
 * To run a mutation, you first call `useRemoveProductDraftAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProductDraftAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProductDraftAttachmentMutation, { data, loading, error }] = useRemoveProductDraftAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveProductDraftAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveProductDraftAttachmentMutation,
    RemoveProductDraftAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveProductDraftAttachmentMutation, RemoveProductDraftAttachmentMutationVariables>(
    RemoveProductDraftAttachmentDocument,
    options
  );
}
export type RemoveProductDraftAttachmentMutationHookResult = ReturnType<typeof useRemoveProductDraftAttachmentMutation>;
export type RemoveProductDraftAttachmentMutationResult = Apollo.MutationResult<RemoveProductDraftAttachmentMutation>;
export type RemoveProductDraftAttachmentMutationOptions = Apollo.BaseMutationOptions<
  RemoveProductDraftAttachmentMutation,
  RemoveProductDraftAttachmentMutationVariables
>;
export const DraftAttachmentsDocument = gql`
  query DraftAttachments($input: FilterAttachmentsInput!) {
    attachments: attachments(filter: $input) {
      entries {
        size
        title
        main_file {
          id
          url
        }
        msfiles_uid
        thumbnails {
          id
          S {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useDraftAttachmentsQuery__
 *
 * To run a query within a React component, call `useDraftAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftAttachmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDraftAttachmentsQuery(
  baseOptions: Apollo.QueryHookOptions<DraftAttachmentsQuery, DraftAttachmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DraftAttachmentsQuery, DraftAttachmentsQueryVariables>(DraftAttachmentsDocument, options);
}
export function useDraftAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DraftAttachmentsQuery, DraftAttachmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DraftAttachmentsQuery, DraftAttachmentsQueryVariables>(DraftAttachmentsDocument, options);
}
export type DraftAttachmentsQueryHookResult = ReturnType<typeof useDraftAttachmentsQuery>;
export type DraftAttachmentsLazyQueryHookResult = ReturnType<typeof useDraftAttachmentsLazyQuery>;
export type DraftAttachmentsQueryResult = Apollo.QueryResult<DraftAttachmentsQuery, DraftAttachmentsQueryVariables>;
export const FavoritesDocument = gql`
  query Favorites($limit: Int!, $offset: Int!) {
    products(input: { limit: $limit, offset: $offset, filters: { favorite: true } }) {
      entries {
        id
        name
        price
        status
        mainPhoto {
          id
          ...localThumbnailAll
        }
        favorite
      }
      pagination {
        ...pagination
      }
    }
  }
  ${LocalThumbnailAllFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useFavoritesQuery__
 *
 * To run a query within a React component, call `useFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavoritesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFavoritesQuery(baseOptions: Apollo.QueryHookOptions<FavoritesQuery, FavoritesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FavoritesQuery, FavoritesQueryVariables>(FavoritesDocument, options);
}
export function useFavoritesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FavoritesQuery, FavoritesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FavoritesQuery, FavoritesQueryVariables>(FavoritesDocument, options);
}
export type FavoritesQueryHookResult = ReturnType<typeof useFavoritesQuery>;
export type FavoritesLazyQueryHookResult = ReturnType<typeof useFavoritesLazyQuery>;
export type FavoritesQueryResult = Apollo.QueryResult<FavoritesQuery, FavoritesQueryVariables>;
export const RateSellerDocument = gql`
  mutation RateSeller($rateInput: RatingInput!) {
    result: rateSeller(rateInput: $rateInput)
  }
`;
export type RateSellerMutationFn = Apollo.MutationFunction<RateSellerMutation, RateSellerMutationVariables>;

/**
 * __useRateSellerMutation__
 *
 * To run a mutation, you first call `useRateSellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateSellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateSellerMutation, { data, loading, error }] = useRateSellerMutation({
 *   variables: {
 *      rateInput: // value for 'rateInput'
 *   },
 * });
 */
export function useRateSellerMutation(
  baseOptions?: Apollo.MutationHookOptions<RateSellerMutation, RateSellerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RateSellerMutation, RateSellerMutationVariables>(RateSellerDocument, options);
}
export type RateSellerMutationHookResult = ReturnType<typeof useRateSellerMutation>;
export type RateSellerMutationResult = Apollo.MutationResult<RateSellerMutation>;
export type RateSellerMutationOptions = Apollo.BaseMutationOptions<RateSellerMutation, RateSellerMutationVariables>;
export const RateCustomerDocument = gql`
  mutation RateCustomer($rateInput: RatingInput!) {
    result: rateCustomer(rateInput: $rateInput)
  }
`;
export type RateCustomerMutationFn = Apollo.MutationFunction<RateCustomerMutation, RateCustomerMutationVariables>;

/**
 * __useRateCustomerMutation__
 *
 * To run a mutation, you first call `useRateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateCustomerMutation, { data, loading, error }] = useRateCustomerMutation({
 *   variables: {
 *      rateInput: // value for 'rateInput'
 *   },
 * });
 */
export function useRateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<RateCustomerMutation, RateCustomerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RateCustomerMutation, RateCustomerMutationVariables>(RateCustomerDocument, options);
}
export type RateCustomerMutationHookResult = ReturnType<typeof useRateCustomerMutation>;
export type RateCustomerMutationResult = Apollo.MutationResult<RateCustomerMutation>;
export type RateCustomerMutationOptions = Apollo.BaseMutationOptions<
  RateCustomerMutation,
  RateCustomerMutationVariables
>;
export const ConfirmOrderPackagingDocument = gql`
  mutation ConfirmOrderPackaging($input: OrderPackagingInput!, $orderId: String!) {
    result: confirmOrderPackaging(OrderPackagingInput: $input, orderId: $orderId)
  }
`;
export type ConfirmOrderPackagingMutationFn = Apollo.MutationFunction<
  ConfirmOrderPackagingMutation,
  ConfirmOrderPackagingMutationVariables
>;

/**
 * __useConfirmOrderPackagingMutation__
 *
 * To run a mutation, you first call `useConfirmOrderPackagingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmOrderPackagingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmOrderPackagingMutation, { data, loading, error }] = useConfirmOrderPackagingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useConfirmOrderPackagingMutation(
  baseOptions?: Apollo.MutationHookOptions<ConfirmOrderPackagingMutation, ConfirmOrderPackagingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmOrderPackagingMutation, ConfirmOrderPackagingMutationVariables>(
    ConfirmOrderPackagingDocument,
    options
  );
}
export type ConfirmOrderPackagingMutationHookResult = ReturnType<typeof useConfirmOrderPackagingMutation>;
export type ConfirmOrderPackagingMutationResult = Apollo.MutationResult<ConfirmOrderPackagingMutation>;
export type ConfirmOrderPackagingMutationOptions = Apollo.BaseMutationOptions<
  ConfirmOrderPackagingMutation,
  ConfirmOrderPackagingMutationVariables
>;
export const ConfirmPackagesSendingWarehouseDocument = gql`
  mutation ConfirmPackagesSendingWarehouse($parcelsInput: OrderParcelsInput!) {
    result: confirmPackagesSendingWarehouse(input: $parcelsInput)
  }
`;
export type ConfirmPackagesSendingWarehouseMutationFn = Apollo.MutationFunction<
  ConfirmPackagesSendingWarehouseMutation,
  ConfirmPackagesSendingWarehouseMutationVariables
>;

/**
 * __useConfirmPackagesSendingWarehouseMutation__
 *
 * To run a mutation, you first call `useConfirmPackagesSendingWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPackagesSendingWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPackagesSendingWarehouseMutation, { data, loading, error }] = useConfirmPackagesSendingWarehouseMutation({
 *   variables: {
 *      parcelsInput: // value for 'parcelsInput'
 *   },
 * });
 */
export function useConfirmPackagesSendingWarehouseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmPackagesSendingWarehouseMutation,
    ConfirmPackagesSendingWarehouseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmPackagesSendingWarehouseMutation, ConfirmPackagesSendingWarehouseMutationVariables>(
    ConfirmPackagesSendingWarehouseDocument,
    options
  );
}
export type ConfirmPackagesSendingWarehouseMutationHookResult = ReturnType<
  typeof useConfirmPackagesSendingWarehouseMutation
>;
export type ConfirmPackagesSendingWarehouseMutationResult =
  Apollo.MutationResult<ConfirmPackagesSendingWarehouseMutation>;
export type ConfirmPackagesSendingWarehouseMutationOptions = Apollo.BaseMutationOptions<
  ConfirmPackagesSendingWarehouseMutation,
  ConfirmPackagesSendingWarehouseMutationVariables
>;
export const ConfirmOrderDeliveringDocument = gql`
  mutation ConfirmOrderDelivering($orderId: String!) {
    result: confirmOrderDelivering(orderId: $orderId)
  }
`;
export type ConfirmOrderDeliveringMutationFn = Apollo.MutationFunction<
  ConfirmOrderDeliveringMutation,
  ConfirmOrderDeliveringMutationVariables
>;

/**
 * __useConfirmOrderDeliveringMutation__
 *
 * To run a mutation, you first call `useConfirmOrderDeliveringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmOrderDeliveringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmOrderDeliveringMutation, { data, loading, error }] = useConfirmOrderDeliveringMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useConfirmOrderDeliveringMutation(
  baseOptions?: Apollo.MutationHookOptions<ConfirmOrderDeliveringMutation, ConfirmOrderDeliveringMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmOrderDeliveringMutation, ConfirmOrderDeliveringMutationVariables>(
    ConfirmOrderDeliveringDocument,
    options
  );
}
export type ConfirmOrderDeliveringMutationHookResult = ReturnType<typeof useConfirmOrderDeliveringMutation>;
export type ConfirmOrderDeliveringMutationResult = Apollo.MutationResult<ConfirmOrderDeliveringMutation>;
export type ConfirmOrderDeliveringMutationOptions = Apollo.BaseMutationOptions<
  ConfirmOrderDeliveringMutation,
  ConfirmOrderDeliveringMutationVariables
>;
export const RecalculateDeliveryPriceDocument = gql`
  mutation RecalculateDeliveryPrice($input: OrderRecalculateDeliveryPriceInput!) {
    result: recalculateDeliveryPrice(input: $input)
  }
`;
export type RecalculateDeliveryPriceMutationFn = Apollo.MutationFunction<
  RecalculateDeliveryPriceMutation,
  RecalculateDeliveryPriceMutationVariables
>;

/**
 * __useRecalculateDeliveryPriceMutation__
 *
 * To run a mutation, you first call `useRecalculateDeliveryPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecalculateDeliveryPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recalculateDeliveryPriceMutation, { data, loading, error }] = useRecalculateDeliveryPriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecalculateDeliveryPriceMutation(
  baseOptions?: Apollo.MutationHookOptions<RecalculateDeliveryPriceMutation, RecalculateDeliveryPriceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RecalculateDeliveryPriceMutation, RecalculateDeliveryPriceMutationVariables>(
    RecalculateDeliveryPriceDocument,
    options
  );
}
export type RecalculateDeliveryPriceMutationHookResult = ReturnType<typeof useRecalculateDeliveryPriceMutation>;
export type RecalculateDeliveryPriceMutationResult = Apollo.MutationResult<RecalculateDeliveryPriceMutation>;
export type RecalculateDeliveryPriceMutationOptions = Apollo.BaseMutationOptions<
  RecalculateDeliveryPriceMutation,
  RecalculateDeliveryPriceMutationVariables
>;
export const CreateShipmentDocument = gql`
  mutation CreateShipment($orderId: String!, $sellerAddressId: String!) {
    result: createShipment(orderId: $orderId, sellerAddressId: $sellerAddressId)
  }
`;
export type CreateShipmentMutationFn = Apollo.MutationFunction<CreateShipmentMutation, CreateShipmentMutationVariables>;

/**
 * __useCreateShipmentMutation__
 *
 * To run a mutation, you first call `useCreateShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShipmentMutation, { data, loading, error }] = useCreateShipmentMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      sellerAddressId: // value for 'sellerAddressId'
 *   },
 * });
 */
export function useCreateShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateShipmentMutation, CreateShipmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateShipmentMutation, CreateShipmentMutationVariables>(CreateShipmentDocument, options);
}
export type CreateShipmentMutationHookResult = ReturnType<typeof useCreateShipmentMutation>;
export type CreateShipmentMutationResult = Apollo.MutationResult<CreateShipmentMutation>;
export type CreateShipmentMutationOptions = Apollo.BaseMutationOptions<
  CreateShipmentMutation,
  CreateShipmentMutationVariables
>;
export const CreatePickupRequestDocument = gql`
  mutation CreatePickupRequest($input: CreateOrderPickupInput!) {
    result: createPickupRequest(input: $input)
  }
`;
export type CreatePickupRequestMutationFn = Apollo.MutationFunction<
  CreatePickupRequestMutation,
  CreatePickupRequestMutationVariables
>;

/**
 * __useCreatePickupRequestMutation__
 *
 * To run a mutation, you first call `useCreatePickupRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePickupRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPickupRequestMutation, { data, loading, error }] = useCreatePickupRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePickupRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePickupRequestMutation, CreatePickupRequestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePickupRequestMutation, CreatePickupRequestMutationVariables>(
    CreatePickupRequestDocument,
    options
  );
}
export type CreatePickupRequestMutationHookResult = ReturnType<typeof useCreatePickupRequestMutation>;
export type CreatePickupRequestMutationResult = Apollo.MutationResult<CreatePickupRequestMutation>;
export type CreatePickupRequestMutationOptions = Apollo.BaseMutationOptions<
  CreatePickupRequestMutation,
  CreatePickupRequestMutationVariables
>;
export const ChangeOrderAcceptanceDocument = gql`
  mutation ChangeOrderAcceptance($input: ChangeOrderAcceptanceStatusInput!) {
    result: changeAcceptance(input: $input)
  }
`;
export type ChangeOrderAcceptanceMutationFn = Apollo.MutationFunction<
  ChangeOrderAcceptanceMutation,
  ChangeOrderAcceptanceMutationVariables
>;

/**
 * __useChangeOrderAcceptanceMutation__
 *
 * To run a mutation, you first call `useChangeOrderAcceptanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeOrderAcceptanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeOrderAcceptanceMutation, { data, loading, error }] = useChangeOrderAcceptanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeOrderAcceptanceMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeOrderAcceptanceMutation, ChangeOrderAcceptanceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeOrderAcceptanceMutation, ChangeOrderAcceptanceMutationVariables>(
    ChangeOrderAcceptanceDocument,
    options
  );
}
export type ChangeOrderAcceptanceMutationHookResult = ReturnType<typeof useChangeOrderAcceptanceMutation>;
export type ChangeOrderAcceptanceMutationResult = Apollo.MutationResult<ChangeOrderAcceptanceMutation>;
export type ChangeOrderAcceptanceMutationOptions = Apollo.BaseMutationOptions<
  ChangeOrderAcceptanceMutation,
  ChangeOrderAcceptanceMutationVariables
>;
export const ConfirmPackagesSendingDocument = gql`
  mutation ConfirmPackagesSending($input: ConfirmOrderPackagesSendingInput!) {
    result: confirmPackagesSending(input: $input)
  }
`;
export type ConfirmPackagesSendingMutationFn = Apollo.MutationFunction<
  ConfirmPackagesSendingMutation,
  ConfirmPackagesSendingMutationVariables
>;

/**
 * __useConfirmPackagesSendingMutation__
 *
 * To run a mutation, you first call `useConfirmPackagesSendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPackagesSendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPackagesSendingMutation, { data, loading, error }] = useConfirmPackagesSendingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmPackagesSendingMutation(
  baseOptions?: Apollo.MutationHookOptions<ConfirmPackagesSendingMutation, ConfirmPackagesSendingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmPackagesSendingMutation, ConfirmPackagesSendingMutationVariables>(
    ConfirmPackagesSendingDocument,
    options
  );
}
export type ConfirmPackagesSendingMutationHookResult = ReturnType<typeof useConfirmPackagesSendingMutation>;
export type ConfirmPackagesSendingMutationResult = Apollo.MutationResult<ConfirmPackagesSendingMutation>;
export type ConfirmPackagesSendingMutationOptions = Apollo.BaseMutationOptions<
  ConfirmPackagesSendingMutation,
  ConfirmPackagesSendingMutationVariables
>;
export const CancelPickupRequestDocument = gql`
  mutation CancelPickupRequest($pickupUuid: String!, $orderIds: [String!]!) {
    result: cancelOrderPickup(pickupUuid: $pickupUuid, orderIds: $orderIds)
  }
`;
export type CancelPickupRequestMutationFn = Apollo.MutationFunction<
  CancelPickupRequestMutation,
  CancelPickupRequestMutationVariables
>;

/**
 * __useCancelPickupRequestMutation__
 *
 * To run a mutation, you first call `useCancelPickupRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPickupRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPickupRequestMutation, { data, loading, error }] = useCancelPickupRequestMutation({
 *   variables: {
 *      pickupUuid: // value for 'pickupUuid'
 *      orderIds: // value for 'orderIds'
 *   },
 * });
 */
export function useCancelPickupRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelPickupRequestMutation, CancelPickupRequestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelPickupRequestMutation, CancelPickupRequestMutationVariables>(
    CancelPickupRequestDocument,
    options
  );
}
export type CancelPickupRequestMutationHookResult = ReturnType<typeof useCancelPickupRequestMutation>;
export type CancelPickupRequestMutationResult = Apollo.MutationResult<CancelPickupRequestMutation>;
export type CancelPickupRequestMutationOptions = Apollo.BaseMutationOptions<
  CancelPickupRequestMutation,
  CancelPickupRequestMutationVariables
>;
export const PickupAvailabilityDocument = gql`
  query PickupAvailability($input: CheckPickupAvailabilityInput!) {
    result: checkPickupAvailability(input: $input) {
      cutOffTime
      accessTime
      date
      timesFrom {
        value
        title
      }
      timesUntil {
        value
        title
      }
    }
  }
`;

/**
 * __usePickupAvailabilityQuery__
 *
 * To run a query within a React component, call `usePickupAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupAvailabilityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePickupAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<PickupAvailabilityQuery, PickupAvailabilityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PickupAvailabilityQuery, PickupAvailabilityQueryVariables>(
    PickupAvailabilityDocument,
    options
  );
}
export function usePickupAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PickupAvailabilityQuery, PickupAvailabilityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PickupAvailabilityQuery, PickupAvailabilityQueryVariables>(
    PickupAvailabilityDocument,
    options
  );
}
export type PickupAvailabilityQueryHookResult = ReturnType<typeof usePickupAvailabilityQuery>;
export type PickupAvailabilityLazyQueryHookResult = ReturnType<typeof usePickupAvailabilityLazyQuery>;
export type PickupAvailabilityQueryResult = Apollo.QueryResult<
  PickupAvailabilityQuery,
  PickupAvailabilityQueryVariables
>;
export const PickupRequestsDocument = gql`
  query PickupRequests {
    result: pickupRequests {
      uuid
      pickupDate
      latestTime
      ordersAmount
      active
    }
  }
`;

/**
 * __usePickupRequestsQuery__
 *
 * To run a query within a React component, call `usePickupRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePickupRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<PickupRequestsQuery, PickupRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PickupRequestsQuery, PickupRequestsQueryVariables>(PickupRequestsDocument, options);
}
export function usePickupRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PickupRequestsQuery, PickupRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PickupRequestsQuery, PickupRequestsQueryVariables>(PickupRequestsDocument, options);
}
export type PickupRequestsQueryHookResult = ReturnType<typeof usePickupRequestsQuery>;
export type PickupRequestsLazyQueryHookResult = ReturnType<typeof usePickupRequestsLazyQuery>;
export type PickupRequestsQueryResult = Apollo.QueryResult<PickupRequestsQuery, PickupRequestsQueryVariables>;
export const PickupRequestExtendedDocument = gql`
  query PickupRequestExtended($pickupUuid: String!) {
    result: pickupRequestExtended(pickupUuid: $pickupUuid) {
      uuid
      pickupDate
      latestTime
      ordersAmount
      addressLines
      active
      packages {
        id
        decodedQr
        packages {
          id
          length
          width
          height
          weight
        }
        deliveryPriceFulfilled
        orderId
      }
    }
  }
`;

/**
 * __usePickupRequestExtendedQuery__
 *
 * To run a query within a React component, call `usePickupRequestExtendedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupRequestExtendedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupRequestExtendedQuery({
 *   variables: {
 *      pickupUuid: // value for 'pickupUuid'
 *   },
 * });
 */
export function usePickupRequestExtendedQuery(
  baseOptions: Apollo.QueryHookOptions<PickupRequestExtendedQuery, PickupRequestExtendedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PickupRequestExtendedQuery, PickupRequestExtendedQueryVariables>(
    PickupRequestExtendedDocument,
    options
  );
}
export function usePickupRequestExtendedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PickupRequestExtendedQuery, PickupRequestExtendedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PickupRequestExtendedQuery, PickupRequestExtendedQueryVariables>(
    PickupRequestExtendedDocument,
    options
  );
}
export type PickupRequestExtendedQueryHookResult = ReturnType<typeof usePickupRequestExtendedQuery>;
export type PickupRequestExtendedLazyQueryHookResult = ReturnType<typeof usePickupRequestExtendedLazyQuery>;
export type PickupRequestExtendedQueryResult = Apollo.QueryResult<
  PickupRequestExtendedQuery,
  PickupRequestExtendedQueryVariables
>;
export const RegisterSellerDocument = gql`
  mutation RegisterSeller($input: SellerCredentialsInput!) {
    response: registerSeller(input: $input)
  }
`;
export type RegisterSellerMutationFn = Apollo.MutationFunction<RegisterSellerMutation, RegisterSellerMutationVariables>;

/**
 * __useRegisterSellerMutation__
 *
 * To run a mutation, you first call `useRegisterSellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterSellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerSellerMutation, { data, loading, error }] = useRegisterSellerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterSellerMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterSellerMutation, RegisterSellerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterSellerMutation, RegisterSellerMutationVariables>(RegisterSellerDocument, options);
}
export type RegisterSellerMutationHookResult = ReturnType<typeof useRegisterSellerMutation>;
export type RegisterSellerMutationResult = Apollo.MutationResult<RegisterSellerMutation>;
export type RegisterSellerMutationOptions = Apollo.BaseMutationOptions<
  RegisterSellerMutation,
  RegisterSellerMutationVariables
>;
export const RemoveProfileDocument = gql`
  mutation RemoveProfile {
    response: removeProfile
  }
`;
export type RemoveProfileMutationFn = Apollo.MutationFunction<RemoveProfileMutation, RemoveProfileMutationVariables>;

/**
 * __useRemoveProfileMutation__
 *
 * To run a mutation, you first call `useRemoveProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProfileMutation, { data, loading, error }] = useRemoveProfileMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveProfileMutation, RemoveProfileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveProfileMutation, RemoveProfileMutationVariables>(RemoveProfileDocument, options);
}
export type RemoveProfileMutationHookResult = ReturnType<typeof useRemoveProfileMutation>;
export type RemoveProfileMutationResult = Apollo.MutationResult<RemoveProfileMutation>;
export type RemoveProfileMutationOptions = Apollo.BaseMutationOptions<
  RemoveProfileMutation,
  RemoveProfileMutationVariables
>;
export const UserReviewsDocument = gql`
  query UserReviews($limit: Int!, $ratingTypes: [RatingValueEnum!], $reviewsFrom: [ReviewsUserRoleEnum!]) {
    reviews: user {
      id
      reviews(
        input: { pagination: { limit: $limit, offset: 0 }, ratingTypes: $ratingTypes, reviewsFrom: $reviewsFrom }
      ) {
        entries {
          id
          orderId
          commentary
          createdAt
          customerId
          sellerId
          customerNickname
          sellerNickname
          value
          reviewFrom
        }
        pagination {
          ...pagination
        }
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useUserReviewsQuery__
 *
 * To run a query within a React component, call `useUserReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserReviewsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      ratingTypes: // value for 'ratingTypes'
 *      reviewsFrom: // value for 'reviewsFrom'
 *   },
 * });
 */
export function useUserReviewsQuery(baseOptions: Apollo.QueryHookOptions<UserReviewsQuery, UserReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserReviewsQuery, UserReviewsQueryVariables>(UserReviewsDocument, options);
}
export function useUserReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserReviewsQuery, UserReviewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserReviewsQuery, UserReviewsQueryVariables>(UserReviewsDocument, options);
}
export type UserReviewsQueryHookResult = ReturnType<typeof useUserReviewsQuery>;
export type UserReviewsLazyQueryHookResult = ReturnType<typeof useUserReviewsLazyQuery>;
export type UserReviewsQueryResult = Apollo.QueryResult<UserReviewsQuery, UserReviewsQueryVariables>;
export const UserReviewsCountDocument = gql`
  query UserReviewsCount {
    count: user {
      id
      reviews {
        totalReviewsByFilter {
          positive
          neutral
          negative
          customer
          seller
        }
      }
    }
  }
`;

/**
 * __useUserReviewsCountQuery__
 *
 * To run a query within a React component, call `useUserReviewsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserReviewsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserReviewsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserReviewsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<UserReviewsCountQuery, UserReviewsCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserReviewsCountQuery, UserReviewsCountQueryVariables>(UserReviewsCountDocument, options);
}
export function useUserReviewsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserReviewsCountQuery, UserReviewsCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserReviewsCountQuery, UserReviewsCountQueryVariables>(UserReviewsCountDocument, options);
}
export type UserReviewsCountQueryHookResult = ReturnType<typeof useUserReviewsCountQuery>;
export type UserReviewsCountLazyQueryHookResult = ReturnType<typeof useUserReviewsCountLazyQuery>;
export type UserReviewsCountQueryResult = Apollo.QueryResult<UserReviewsCountQuery, UserReviewsCountQueryVariables>;
export const ConfirmDeliveryPriceDocument = gql`
  mutation ConfirmDeliveryPrice($input: OrderConfirmDeliveryPriceInput!) {
    result: confirmDeliveryPrice(input: $input)
  }
`;
export type ConfirmDeliveryPriceMutationFn = Apollo.MutationFunction<
  ConfirmDeliveryPriceMutation,
  ConfirmDeliveryPriceMutationVariables
>;

/**
 * __useConfirmDeliveryPriceMutation__
 *
 * To run a mutation, you first call `useConfirmDeliveryPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmDeliveryPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmDeliveryPriceMutation, { data, loading, error }] = useConfirmDeliveryPriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmDeliveryPriceMutation(
  baseOptions?: Apollo.MutationHookOptions<ConfirmDeliveryPriceMutation, ConfirmDeliveryPriceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmDeliveryPriceMutation, ConfirmDeliveryPriceMutationVariables>(
    ConfirmDeliveryPriceDocument,
    options
  );
}
export type ConfirmDeliveryPriceMutationHookResult = ReturnType<typeof useConfirmDeliveryPriceMutation>;
export type ConfirmDeliveryPriceMutationResult = Apollo.MutationResult<ConfirmDeliveryPriceMutation>;
export type ConfirmDeliveryPriceMutationOptions = Apollo.BaseMutationOptions<
  ConfirmDeliveryPriceMutation,
  ConfirmDeliveryPriceMutationVariables
>;
export const CalculateShippingFeeDocument = gql`
  mutation CalculateShippingFee($input: OrderSellerAddressInput!) {
    result: calculateShippingFee(input: $input)
  }
`;
export type CalculateShippingFeeMutationFn = Apollo.MutationFunction<
  CalculateShippingFeeMutation,
  CalculateShippingFeeMutationVariables
>;

/**
 * __useCalculateShippingFeeMutation__
 *
 * To run a mutation, you first call `useCalculateShippingFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculateShippingFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculateShippingFeeMutation, { data, loading, error }] = useCalculateShippingFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculateShippingFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<CalculateShippingFeeMutation, CalculateShippingFeeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CalculateShippingFeeMutation, CalculateShippingFeeMutationVariables>(
    CalculateShippingFeeDocument,
    options
  );
}
export type CalculateShippingFeeMutationHookResult = ReturnType<typeof useCalculateShippingFeeMutation>;
export type CalculateShippingFeeMutationResult = Apollo.MutationResult<CalculateShippingFeeMutation>;
export type CalculateShippingFeeMutationOptions = Apollo.BaseMutationOptions<
  CalculateShippingFeeMutation,
  CalculateShippingFeeMutationVariables
>;
export const CreateYamatoShipmentDocument = gql`
  mutation CreateYamatoShipment($input: CreateYamatoShipmentInput!) {
    result: createYamatoShipment(input: $input)
  }
`;
export type CreateYamatoShipmentMutationFn = Apollo.MutationFunction<
  CreateYamatoShipmentMutation,
  CreateYamatoShipmentMutationVariables
>;

/**
 * __useCreateYamatoShipmentMutation__
 *
 * To run a mutation, you first call `useCreateYamatoShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateYamatoShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createYamatoShipmentMutation, { data, loading, error }] = useCreateYamatoShipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateYamatoShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateYamatoShipmentMutation, CreateYamatoShipmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateYamatoShipmentMutation, CreateYamatoShipmentMutationVariables>(
    CreateYamatoShipmentDocument,
    options
  );
}
export type CreateYamatoShipmentMutationHookResult = ReturnType<typeof useCreateYamatoShipmentMutation>;
export type CreateYamatoShipmentMutationResult = Apollo.MutationResult<CreateYamatoShipmentMutation>;
export type CreateYamatoShipmentMutationOptions = Apollo.BaseMutationOptions<
  CreateYamatoShipmentMutation,
  CreateYamatoShipmentMutationVariables
>;
export const ShowcaseProductsDocument = gql`
  query ShowcaseProducts($sellerId: String!, $limit: Int!, $offset: Int!) {
    products(
      input: {
        limit: $limit
        offset: $offset
        filters: { sellerId: $sellerId, isShowcase: true }
        sort: { direction: desc, warehouseNumber: true }
      }
    ) {
      entries {
        id
        name
        price
        mainPhoto {
          id
          ...localThumbnailAll
        }
        sellerId
        warehouseNumber
      }
      pagination {
        ...pagination
      }
    }
  }
  ${LocalThumbnailAllFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useShowcaseProductsQuery__
 *
 * To run a query within a React component, call `useShowcaseProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowcaseProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowcaseProductsQuery({
 *   variables: {
 *      sellerId: // value for 'sellerId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useShowcaseProductsQuery(
  baseOptions: Apollo.QueryHookOptions<ShowcaseProductsQuery, ShowcaseProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShowcaseProductsQuery, ShowcaseProductsQueryVariables>(ShowcaseProductsDocument, options);
}
export function useShowcaseProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShowcaseProductsQuery, ShowcaseProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShowcaseProductsQuery, ShowcaseProductsQueryVariables>(ShowcaseProductsDocument, options);
}
export type ShowcaseProductsQueryHookResult = ReturnType<typeof useShowcaseProductsQuery>;
export type ShowcaseProductsLazyQueryHookResult = ReturnType<typeof useShowcaseProductsLazyQuery>;
export type ShowcaseProductsQueryResult = Apollo.QueryResult<ShowcaseProductsQuery, ShowcaseProductsQueryVariables>;
export const ChangeTicketStatusDocument = gql`
  mutation ChangeTicketStatus($input: ChangeTicketStatusInput!) {
    result: changeTicketStatus(input: $input)
  }
`;
export type ChangeTicketStatusMutationFn = Apollo.MutationFunction<
  ChangeTicketStatusMutation,
  ChangeTicketStatusMutationVariables
>;

/**
 * __useChangeTicketStatusMutation__
 *
 * To run a mutation, you first call `useChangeTicketStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTicketStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTicketStatusMutation, { data, loading, error }] = useChangeTicketStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTicketStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeTicketStatusMutation, ChangeTicketStatusMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeTicketStatusMutation, ChangeTicketStatusMutationVariables>(
    ChangeTicketStatusDocument,
    options
  );
}
export type ChangeTicketStatusMutationHookResult = ReturnType<typeof useChangeTicketStatusMutation>;
export type ChangeTicketStatusMutationResult = Apollo.MutationResult<ChangeTicketStatusMutation>;
export type ChangeTicketStatusMutationOptions = Apollo.BaseMutationOptions<
  ChangeTicketStatusMutation,
  ChangeTicketStatusMutationVariables
>;
export const TicketDocument = gql`
  query Ticket($input: GetTicketInput!) {
    result: getTicket(input: $input) {
      ticketId
      topic
      status
      messages {
        messageId
        senderId
        senderName
        self
        closing
        body
        image {
          imageUrl
          width
          height
        }
        read
        sequenceStart
        createdAt
      }
    }
  }
`;

/**
 * __useTicketQuery__
 *
 * To run a query within a React component, call `useTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTicketQuery(baseOptions: Apollo.QueryHookOptions<TicketQuery, TicketQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketQuery, TicketQueryVariables>(TicketDocument, options);
}
export function useTicketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketQuery, TicketQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketQuery, TicketQueryVariables>(TicketDocument, options);
}
export type TicketQueryHookResult = ReturnType<typeof useTicketQuery>;
export type TicketLazyQueryHookResult = ReturnType<typeof useTicketLazyQuery>;
export type TicketQueryResult = Apollo.QueryResult<TicketQuery, TicketQueryVariables>;
export const TicketMessagesDocument = gql`
  query TicketMessages($input: TicketMessagesInput!) {
    result: getTicketMessages(input: $input) {
      messageId
      senderId
      senderName
      self
      closing
      body
      image {
        imageUrl
        width
        height
      }
      read
      sequenceStart
      createdAt
    }
  }
`;

/**
 * __useTicketMessagesQuery__
 *
 * To run a query within a React component, call `useTicketMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketMessagesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTicketMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<TicketMessagesQuery, TicketMessagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketMessagesQuery, TicketMessagesQueryVariables>(TicketMessagesDocument, options);
}
export function useTicketMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TicketMessagesQuery, TicketMessagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketMessagesQuery, TicketMessagesQueryVariables>(TicketMessagesDocument, options);
}
export type TicketMessagesQueryHookResult = ReturnType<typeof useTicketMessagesQuery>;
export type TicketMessagesLazyQueryHookResult = ReturnType<typeof useTicketMessagesLazyQuery>;
export type TicketMessagesQueryResult = Apollo.QueryResult<TicketMessagesQuery, TicketMessagesQueryVariables>;
export const TicketsDocument = gql`
  query Tickets($input: TicketsInput) {
    result: getTickets(input: $input) {
      entries {
        ticketId
        name
        topic
        status
        unreadCount
        createdAt
        updatedAt
      }
      pagination {
        offset
        limit
        totalCount
      }
    }
  }
`;

/**
 * __useTicketsQuery__
 *
 * To run a query within a React component, call `useTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTicketsQuery(baseOptions?: Apollo.QueryHookOptions<TicketsQuery, TicketsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketsQuery, TicketsQueryVariables>(TicketsDocument, options);
}
export function useTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketsQuery, TicketsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketsQuery, TicketsQueryVariables>(TicketsDocument, options);
}
export type TicketsQueryHookResult = ReturnType<typeof useTicketsQuery>;
export type TicketsLazyQueryHookResult = ReturnType<typeof useTicketsLazyQuery>;
export type TicketsQueryResult = Apollo.QueryResult<TicketsQuery, TicketsQueryVariables>;
export const SellerTrustPointsDocument = gql`
  query SellerTrustPoints($input: TrustPointsInput) {
    trustPoints: user {
      seller {
        trustPoints(input: $input) {
          entries {
            orderId
            orderID
            commentary
            amount
            total
            createdAt
          }
          pagination {
            ...pagination
          }
        }
        totalTrustPoints
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useSellerTrustPointsQuery__
 *
 * To run a query within a React component, call `useSellerTrustPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerTrustPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerTrustPointsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSellerTrustPointsQuery(
  baseOptions?: Apollo.QueryHookOptions<SellerTrustPointsQuery, SellerTrustPointsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SellerTrustPointsQuery, SellerTrustPointsQueryVariables>(SellerTrustPointsDocument, options);
}
export function useSellerTrustPointsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SellerTrustPointsQuery, SellerTrustPointsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SellerTrustPointsQuery, SellerTrustPointsQueryVariables>(
    SellerTrustPointsDocument,
    options
  );
}
export type SellerTrustPointsQueryHookResult = ReturnType<typeof useSellerTrustPointsQuery>;
export type SellerTrustPointsLazyQueryHookResult = ReturnType<typeof useSellerTrustPointsLazyQuery>;
export type SellerTrustPointsQueryResult = Apollo.QueryResult<SellerTrustPointsQuery, SellerTrustPointsQueryVariables>;
export const SendVerificationCodeDocument = gql`
  mutation SendVerificationCode($input: SendVerificationSmsInput!) {
    success: sendVerificationSms(input: $input)
  }
`;
export type SendVerificationCodeMutationFn = Apollo.MutationFunction<
  SendVerificationCodeMutation,
  SendVerificationCodeMutationVariables
>;

/**
 * __useSendVerificationCodeMutation__
 *
 * To run a mutation, you first call `useSendVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerificationCodeMutation, { data, loading, error }] = useSendVerificationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<SendVerificationCodeMutation, SendVerificationCodeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendVerificationCodeMutation, SendVerificationCodeMutationVariables>(
    SendVerificationCodeDocument,
    options
  );
}
export type SendVerificationCodeMutationHookResult = ReturnType<typeof useSendVerificationCodeMutation>;
export type SendVerificationCodeMutationResult = Apollo.MutationResult<SendVerificationCodeMutation>;
export type SendVerificationCodeMutationOptions = Apollo.BaseMutationOptions<
  SendVerificationCodeMutation,
  SendVerificationCodeMutationVariables
>;
export const ConfirmVerificationCodeDocument = gql`
  mutation ConfirmVerificationCode($input: ConfirmVerificationSmsInput!) {
    success: confirmVerificationSms(input: $input)
  }
`;
export type ConfirmVerificationCodeMutationFn = Apollo.MutationFunction<
  ConfirmVerificationCodeMutation,
  ConfirmVerificationCodeMutationVariables
>;

/**
 * __useConfirmVerificationCodeMutation__
 *
 * To run a mutation, you first call `useConfirmVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmVerificationCodeMutation, { data, loading, error }] = useConfirmVerificationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<ConfirmVerificationCodeMutation, ConfirmVerificationCodeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmVerificationCodeMutation, ConfirmVerificationCodeMutationVariables>(
    ConfirmVerificationCodeDocument,
    options
  );
}
export type ConfirmVerificationCodeMutationHookResult = ReturnType<typeof useConfirmVerificationCodeMutation>;
export type ConfirmVerificationCodeMutationResult = Apollo.MutationResult<ConfirmVerificationCodeMutation>;
export type ConfirmVerificationCodeMutationOptions = Apollo.BaseMutationOptions<
  ConfirmVerificationCodeMutation,
  ConfirmVerificationCodeMutationVariables
>;
export const SendTestCodeDocument = gql`
  mutation SendTestCode($input: SendTestSmsInput!) {
    success: sendTestSms(input: $input)
  }
`;
export type SendTestCodeMutationFn = Apollo.MutationFunction<SendTestCodeMutation, SendTestCodeMutationVariables>;

/**
 * __useSendTestCodeMutation__
 *
 * To run a mutation, you first call `useSendTestCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTestCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTestCodeMutation, { data, loading, error }] = useSendTestCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendTestCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<SendTestCodeMutation, SendTestCodeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendTestCodeMutation, SendTestCodeMutationVariables>(SendTestCodeDocument, options);
}
export type SendTestCodeMutationHookResult = ReturnType<typeof useSendTestCodeMutation>;
export type SendTestCodeMutationResult = Apollo.MutationResult<SendTestCodeMutation>;
export type SendTestCodeMutationOptions = Apollo.BaseMutationOptions<
  SendTestCodeMutation,
  SendTestCodeMutationVariables
>;
export const WithdrawRequestsDocument = gql`
  query WithdrawRequests($input: PaginationInput!) {
    withdrawRequests: getSellerWithdrawRequests(input: $input) {
      data: withdrawRequests {
        ID
        date
        withdrawAmount
        status
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useWithdrawRequestsQuery__
 *
 * To run a query within a React component, call `useWithdrawRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWithdrawRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWithdrawRequestsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<WithdrawRequestsQuery, WithdrawRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WithdrawRequestsQuery, WithdrawRequestsQueryVariables>(WithdrawRequestsDocument, options);
}
export function useWithdrawRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WithdrawRequestsQuery, WithdrawRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WithdrawRequestsQuery, WithdrawRequestsQueryVariables>(WithdrawRequestsDocument, options);
}
export type WithdrawRequestsQueryHookResult = ReturnType<typeof useWithdrawRequestsQuery>;
export type WithdrawRequestsLazyQueryHookResult = ReturnType<typeof useWithdrawRequestsLazyQuery>;
export type WithdrawRequestsQueryResult = Apollo.QueryResult<WithdrawRequestsQuery, WithdrawRequestsQueryVariables>;
export const WalletTransactionsDocument = gql`
  query WalletTransactions($input: WalletTransactionsInput) {
    transactions: getWalletTransactions(input: $input) {
      data: transactions {
        amount
        balance
        comment
        createdDate
        order {
          contents {
            productId
            price
            commission
            name
          }
          order {
            ID
            id
          }
        }
        transactionType
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useWalletTransactionsQuery__
 *
 * To run a query within a React component, call `useWalletTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletTransactionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWalletTransactionsQuery(
  baseOptions?: Apollo.QueryHookOptions<WalletTransactionsQuery, WalletTransactionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WalletTransactionsQuery, WalletTransactionsQueryVariables>(
    WalletTransactionsDocument,
    options
  );
}
export function useWalletTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WalletTransactionsQuery, WalletTransactionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WalletTransactionsQuery, WalletTransactionsQueryVariables>(
    WalletTransactionsDocument,
    options
  );
}
export type WalletTransactionsQueryHookResult = ReturnType<typeof useWalletTransactionsQuery>;
export type WalletTransactionsLazyQueryHookResult = ReturnType<typeof useWalletTransactionsLazyQuery>;
export type WalletTransactionsQueryResult = Apollo.QueryResult<
  WalletTransactionsQuery,
  WalletTransactionsQueryVariables
>;
export const SellerBalanceDocument = gql`
  query SellerBalance {
    balance: getSellerBalance {
      total: balance
      availableToWithdraw
    }
  }
`;

/**
 * __useSellerBalanceQuery__
 *
 * To run a query within a React component, call `useSellerBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useSellerBalanceQuery(
  baseOptions?: Apollo.QueryHookOptions<SellerBalanceQuery, SellerBalanceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SellerBalanceQuery, SellerBalanceQueryVariables>(SellerBalanceDocument, options);
}
export function useSellerBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SellerBalanceQuery, SellerBalanceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SellerBalanceQuery, SellerBalanceQueryVariables>(SellerBalanceDocument, options);
}
export type SellerBalanceQueryHookResult = ReturnType<typeof useSellerBalanceQuery>;
export type SellerBalanceLazyQueryHookResult = ReturnType<typeof useSellerBalanceLazyQuery>;
export type SellerBalanceQueryResult = Apollo.QueryResult<SellerBalanceQuery, SellerBalanceQueryVariables>;
export const MakeWithdrawRequestDocument = gql`
  mutation MakeWithdrawRequest($input: WithdrawRequestInput!) {
    makeWithdrawRequest(input: $input)
  }
`;
export type MakeWithdrawRequestMutationFn = Apollo.MutationFunction<
  MakeWithdrawRequestMutation,
  MakeWithdrawRequestMutationVariables
>;

/**
 * __useMakeWithdrawRequestMutation__
 *
 * To run a mutation, you first call `useMakeWithdrawRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeWithdrawRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeWithdrawRequestMutation, { data, loading, error }] = useMakeWithdrawRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeWithdrawRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<MakeWithdrawRequestMutation, MakeWithdrawRequestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MakeWithdrawRequestMutation, MakeWithdrawRequestMutationVariables>(
    MakeWithdrawRequestDocument,
    options
  );
}
export type MakeWithdrawRequestMutationHookResult = ReturnType<typeof useMakeWithdrawRequestMutation>;
export type MakeWithdrawRequestMutationResult = Apollo.MutationResult<MakeWithdrawRequestMutation>;
export type MakeWithdrawRequestMutationOptions = Apollo.BaseMutationOptions<
  MakeWithdrawRequestMutation,
  MakeWithdrawRequestMutationVariables
>;
export const CreateBankAccountDocument = gql`
  mutation CreateBankAccount($input: CreateBankAccountPresetInput!) {
    createBankAccountPreset(input: $input) {
      id
    }
  }
`;
export type CreateBankAccountMutationFn = Apollo.MutationFunction<
  CreateBankAccountMutation,
  CreateBankAccountMutationVariables
>;

/**
 * __useCreateBankAccountMutation__
 *
 * To run a mutation, you first call `useCreateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankAccountMutation, { data, loading, error }] = useCreateBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBankAccountMutation, CreateBankAccountMutationVariables>(
    CreateBankAccountDocument,
    options
  );
}
export type CreateBankAccountMutationHookResult = ReturnType<typeof useCreateBankAccountMutation>;
export type CreateBankAccountMutationResult = Apollo.MutationResult<CreateBankAccountMutation>;
export type CreateBankAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateBankAccountMutation,
  CreateBankAccountMutationVariables
>;
export const UpdateBankAccountDocument = gql`
  mutation UpdateBankAccount($data: UpdateBankAccountPresetData!, $where: WhereBankAccountPresetInput!) {
    updateBankAccountPreset(data: $data, where: $where) {
      id
    }
  }
`;
export type UpdateBankAccountMutationFn = Apollo.MutationFunction<
  UpdateBankAccountMutation,
  UpdateBankAccountMutationVariables
>;

/**
 * __useUpdateBankAccountMutation__
 *
 * To run a mutation, you first call `useUpdateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankAccountMutation, { data, loading, error }] = useUpdateBankAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>(
    UpdateBankAccountDocument,
    options
  );
}
export type UpdateBankAccountMutationHookResult = ReturnType<typeof useUpdateBankAccountMutation>;
export type UpdateBankAccountMutationResult = Apollo.MutationResult<UpdateBankAccountMutation>;
export type UpdateBankAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateBankAccountMutation,
  UpdateBankAccountMutationVariables
>;
export const DeleteBankAccountDocument = gql`
  mutation DeleteBankAccount($where: WhereBankAccountPresetInput!) {
    deleteBankAccountPreset(where: $where)
  }
`;
export type DeleteBankAccountMutationFn = Apollo.MutationFunction<
  DeleteBankAccountMutation,
  DeleteBankAccountMutationVariables
>;

/**
 * __useDeleteBankAccountMutation__
 *
 * To run a mutation, you first call `useDeleteBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBankAccountMutation, { data, loading, error }] = useDeleteBankAccountMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>(
    DeleteBankAccountDocument,
    options
  );
}
export type DeleteBankAccountMutationHookResult = ReturnType<typeof useDeleteBankAccountMutation>;
export type DeleteBankAccountMutationResult = Apollo.MutationResult<DeleteBankAccountMutation>;
export type DeleteBankAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteBankAccountMutation,
  DeleteBankAccountMutationVariables
>;
export const WithdrawUserDocument = gql`
  query WithdrawUser {
    user: getUser {
      seller {
        name
        secondName
      }
    }
  }
`;

/**
 * __useWithdrawUserQuery__
 *
 * To run a query within a React component, call `useWithdrawUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useWithdrawUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWithdrawUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useWithdrawUserQuery(
  baseOptions?: Apollo.QueryHookOptions<WithdrawUserQuery, WithdrawUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WithdrawUserQuery, WithdrawUserQueryVariables>(WithdrawUserDocument, options);
}
export function useWithdrawUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WithdrawUserQuery, WithdrawUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WithdrawUserQuery, WithdrawUserQueryVariables>(WithdrawUserDocument, options);
}
export type WithdrawUserQueryHookResult = ReturnType<typeof useWithdrawUserQuery>;
export type WithdrawUserLazyQueryHookResult = ReturnType<typeof useWithdrawUserLazyQuery>;
export type WithdrawUserQueryResult = Apollo.QueryResult<WithdrawUserQuery, WithdrawUserQueryVariables>;
export const BankAccountsDocument = gql`
  query BankAccounts {
    bankAccounts: getMyBankAccountPresets {
      id
      title
      user_name
      user_name_hiragana
      organization_name
      organization_code
      organization_hiragana
      branch_name
      branch_code
      branch_hiragana
      account_type
      account_number
      user_at_id
    }
  }
`;

/**
 * __useBankAccountsQuery__
 *
 * To run a query within a React component, call `useBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBankAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<BankAccountsQuery, BankAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BankAccountsQuery, BankAccountsQueryVariables>(BankAccountsDocument, options);
}
export function useBankAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BankAccountsQuery, BankAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BankAccountsQuery, BankAccountsQueryVariables>(BankAccountsDocument, options);
}
export type BankAccountsQueryHookResult = ReturnType<typeof useBankAccountsQuery>;
export type BankAccountsLazyQueryHookResult = ReturnType<typeof useBankAccountsLazyQuery>;
export type BankAccountsQueryResult = Apollo.QueryResult<BankAccountsQuery, BankAccountsQueryVariables>;
export const BanksDocument = gql`
  query Banks($input: GetBankOrganizationsInput!) {
    banks: getBankOrganizations(input: $input) {
      bankOrganizations {
        organization_name
        organization_hiragana
        organization_code
      }
    }
  }
`;

/**
 * __useBanksQuery__
 *
 * To run a query within a React component, call `useBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBanksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBanksQuery(baseOptions: Apollo.QueryHookOptions<BanksQuery, BanksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BanksQuery, BanksQueryVariables>(BanksDocument, options);
}
export function useBanksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BanksQuery, BanksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BanksQuery, BanksQueryVariables>(BanksDocument, options);
}
export type BanksQueryHookResult = ReturnType<typeof useBanksQuery>;
export type BanksLazyQueryHookResult = ReturnType<typeof useBanksLazyQuery>;
export type BanksQueryResult = Apollo.QueryResult<BanksQuery, BanksQueryVariables>;
export const BranchesDocument = gql`
  query Branches($input: GetBankBranchesInput!) {
    branches: getBankBranches(input: $input) {
      bankBranches {
        branch_name
        branch_hiragana
        branch_code
      }
    }
  }
`;

/**
 * __useBranchesQuery__
 *
 * To run a query within a React component, call `useBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBranchesQuery(baseOptions: Apollo.QueryHookOptions<BranchesQuery, BranchesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BranchesQuery, BranchesQueryVariables>(BranchesDocument, options);
}
export function useBranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchesQuery, BranchesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BranchesQuery, BranchesQueryVariables>(BranchesDocument, options);
}
export type BranchesQueryHookResult = ReturnType<typeof useBranchesQuery>;
export type BranchesLazyQueryHookResult = ReturnType<typeof useBranchesLazyQuery>;
export type BranchesQueryResult = Apollo.QueryResult<BranchesQuery, BranchesQueryVariables>;
export const ReviewsDocument = gql`
  query Reviews($input: PublicReviewsInput!) {
    reviews: publicInfo {
      reviews(input: $input) {
        entries {
          id
          commentary
          createdAt
          customerId
          sellerId
          customerNickname
          sellerNickname
          value
          reviewFrom
        }
        totalReviewsByFilter {
          positive
          negative
          neutral
          customer
          seller
        }
        pagination {
          ...pagination
        }
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useReviewsQuery__
 *
 * To run a query within a React component, call `useReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewsQuery(baseOptions: Apollo.QueryHookOptions<ReviewsQuery, ReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReviewsQuery, ReviewsQueryVariables>(ReviewsDocument, options);
}
export function useReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewsQuery, ReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReviewsQuery, ReviewsQueryVariables>(ReviewsDocument, options);
}
export type ReviewsQueryHookResult = ReturnType<typeof useReviewsQuery>;
export type ReviewsLazyQueryHookResult = ReturnType<typeof useReviewsLazyQuery>;
export type ReviewsQueryResult = Apollo.QueryResult<ReviewsQuery, ReviewsQueryVariables>;
export const SellerPublicInfoDocument = gql`
  query SellerPublicInfo($input: PublicInfoInput) {
    seller: publicInfo {
      sellers(input: $input) {
        entries {
          id
          nickname
          avatar {
            thumbnails {
              M {
                url
              }
            }
          }
          isTrusted
          rating {
            positive
            neutral
            negative
          }
        }
      }
    }
  }
`;

/**
 * __useSellerPublicInfoQuery__
 *
 * To run a query within a React component, call `useSellerPublicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerPublicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerPublicInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSellerPublicInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<SellerPublicInfoQuery, SellerPublicInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SellerPublicInfoQuery, SellerPublicInfoQueryVariables>(SellerPublicInfoDocument, options);
}
export function useSellerPublicInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SellerPublicInfoQuery, SellerPublicInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SellerPublicInfoQuery, SellerPublicInfoQueryVariables>(SellerPublicInfoDocument, options);
}
export type SellerPublicInfoQueryHookResult = ReturnType<typeof useSellerPublicInfoQuery>;
export type SellerPublicInfoLazyQueryHookResult = ReturnType<typeof useSellerPublicInfoLazyQuery>;
export type SellerPublicInfoQueryResult = Apollo.QueryResult<SellerPublicInfoQuery, SellerPublicInfoQueryVariables>;
export const SellerProductsDocument = gql`
  query SellerProducts($input: ProductsPaginationWithFiltersInput!) {
    products(input: $input) {
      entries {
        id
        name
        price
        mainPhoto {
          id
          msfiles_uid
          ...localThumbnailAll
        }
        favorite
      }
      pagination {
        ...pagination
      }
    }
  }
  ${LocalThumbnailAllFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useSellerProductsQuery__
 *
 * To run a query within a React component, call `useSellerProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSellerProductsQuery(
  baseOptions: Apollo.QueryHookOptions<SellerProductsQuery, SellerProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SellerProductsQuery, SellerProductsQueryVariables>(SellerProductsDocument, options);
}
export function useSellerProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SellerProductsQuery, SellerProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SellerProductsQuery, SellerProductsQueryVariables>(SellerProductsDocument, options);
}
export type SellerProductsQueryHookResult = ReturnType<typeof useSellerProductsQuery>;
export type SellerProductsLazyQueryHookResult = ReturnType<typeof useSellerProductsLazyQuery>;
export type SellerProductsQueryResult = Apollo.QueryResult<SellerProductsQuery, SellerProductsQueryVariables>;
export const CustomerPublicInfoDocument = gql`
  query CustomerPublicInfo($input: PublicInfoInput) {
    customer: publicInfo {
      customers(input: $input) {
        entries {
          id
          nickname
          customerRating {
            positive
            neutral
            negative
          }
          avatar {
            thumbnails {
              M {
                url
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCustomerPublicInfoQuery__
 *
 * To run a query within a React component, call `useCustomerPublicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerPublicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerPublicInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerPublicInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomerPublicInfoQuery, CustomerPublicInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerPublicInfoQuery, CustomerPublicInfoQueryVariables>(
    CustomerPublicInfoDocument,
    options
  );
}
export function useCustomerPublicInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerPublicInfoQuery, CustomerPublicInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerPublicInfoQuery, CustomerPublicInfoQueryVariables>(
    CustomerPublicInfoDocument,
    options
  );
}
export type CustomerPublicInfoQueryHookResult = ReturnType<typeof useCustomerPublicInfoQuery>;
export type CustomerPublicInfoLazyQueryHookResult = ReturnType<typeof useCustomerPublicInfoLazyQuery>;
export type CustomerPublicInfoQueryResult = Apollo.QueryResult<
  CustomerPublicInfoQuery,
  CustomerPublicInfoQueryVariables
>;
export const ReviewsCountDocument = gql`
  query ReviewsCount($input: PublicReviewsInput!) {
    count: publicInfo {
      reviews(input: $input) {
        totalReviewsByFilter {
          positive
          neutral
          negative
          customer
          seller
        }
      }
    }
  }
`;

/**
 * __useReviewsCountQuery__
 *
 * To run a query within a React component, call `useReviewsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewsCountQuery(
  baseOptions: Apollo.QueryHookOptions<ReviewsCountQuery, ReviewsCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReviewsCountQuery, ReviewsCountQueryVariables>(ReviewsCountDocument, options);
}
export function useReviewsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReviewsCountQuery, ReviewsCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReviewsCountQuery, ReviewsCountQueryVariables>(ReviewsCountDocument, options);
}
export type ReviewsCountQueryHookResult = ReturnType<typeof useReviewsCountQuery>;
export type ReviewsCountLazyQueryHookResult = ReturnType<typeof useReviewsCountLazyQuery>;
export type ReviewsCountQueryResult = Apollo.QueryResult<ReviewsCountQuery, ReviewsCountQueryVariables>;
export const AvatarDocument = gql`
  query Avatar($input: PublicInfoInput!) {
    avatar: publicInfo {
      customers(input: $input) {
        entries {
          id
          avatar {
            thumbnails {
              M {
                url
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAvatarQuery__
 *
 * To run a query within a React component, call `useAvatarQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvatarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvatarQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAvatarQuery(baseOptions: Apollo.QueryHookOptions<AvatarQuery, AvatarQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvatarQuery, AvatarQueryVariables>(AvatarDocument, options);
}
export function useAvatarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvatarQuery, AvatarQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvatarQuery, AvatarQueryVariables>(AvatarDocument, options);
}
export type AvatarQueryHookResult = ReturnType<typeof useAvatarQuery>;
export type AvatarLazyQueryHookResult = ReturnType<typeof useAvatarLazyQuery>;
export type AvatarQueryResult = Apollo.QueryResult<AvatarQuery, AvatarQueryVariables>;
export const AddItemToCartDocument = gql`
  mutation AddItemToCart($productId: String!) {
    addItemToCart(productId: $productId)
  }
`;
export type AddItemToCartMutationFn = Apollo.MutationFunction<AddItemToCartMutation, AddItemToCartMutationVariables>;

/**
 * __useAddItemToCartMutation__
 *
 * To run a mutation, you first call `useAddItemToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemToCartMutation, { data, loading, error }] = useAddItemToCartMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useAddItemToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<AddItemToCartMutation, AddItemToCartMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddItemToCartMutation, AddItemToCartMutationVariables>(AddItemToCartDocument, options);
}
export type AddItemToCartMutationHookResult = ReturnType<typeof useAddItemToCartMutation>;
export type AddItemToCartMutationResult = Apollo.MutationResult<AddItemToCartMutation>;
export type AddItemToCartMutationOptions = Apollo.BaseMutationOptions<
  AddItemToCartMutation,
  AddItemToCartMutationVariables
>;
export const RemoveItemFromCartDocument = gql`
  mutation RemoveItemFromCart($productId: String!) {
    removeItemFromCart(productId: $productId)
  }
`;
export type RemoveItemFromCartMutationFn = Apollo.MutationFunction<
  RemoveItemFromCartMutation,
  RemoveItemFromCartMutationVariables
>;

/**
 * __useRemoveItemFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveItemFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemFromCartMutation, { data, loading, error }] = useRemoveItemFromCartMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useRemoveItemFromCartMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>(
    RemoveItemFromCartDocument,
    options
  );
}
export type RemoveItemFromCartMutationHookResult = ReturnType<typeof useRemoveItemFromCartMutation>;
export type RemoveItemFromCartMutationResult = Apollo.MutationResult<RemoveItemFromCartMutation>;
export type RemoveItemFromCartMutationOptions = Apollo.BaseMutationOptions<
  RemoveItemFromCartMutation,
  RemoveItemFromCartMutationVariables
>;
export const UpdateCartDocument = gql`
  mutation UpdateCart($input: CartInput!) {
    updateCart(input: $input)
  }
`;
export type UpdateCartMutationFn = Apollo.MutationFunction<UpdateCartMutation, UpdateCartMutationVariables>;

/**
 * __useUpdateCartMutation__
 *
 * To run a mutation, you first call `useUpdateCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartMutation, { data, loading, error }] = useUpdateCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCartMutation, UpdateCartMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCartMutation, UpdateCartMutationVariables>(UpdateCartDocument, options);
}
export type UpdateCartMutationHookResult = ReturnType<typeof useUpdateCartMutation>;
export type UpdateCartMutationResult = Apollo.MutationResult<UpdateCartMutation>;
export type UpdateCartMutationOptions = Apollo.BaseMutationOptions<UpdateCartMutation, UpdateCartMutationVariables>;
export const CartDocument = gql`
  query Cart($input: PaginationInput) {
    cart: getUserCart(input: $input) {
      groups: cartItemGroups {
        sellerId
        sellerName
        sellerNickname
        sellerTrusted
        items: cartItems {
          productId
        }
        deliveryDaysMin
        deliveryDaysMax
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useCartQuery__
 *
 * To run a query within a React component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCartQuery(baseOptions?: Apollo.QueryHookOptions<CartQuery, CartQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CartQuery, CartQueryVariables>(CartDocument, options);
}
export function useCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CartQuery, CartQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CartQuery, CartQueryVariables>(CartDocument, options);
}
export type CartQueryHookResult = ReturnType<typeof useCartQuery>;
export type CartLazyQueryHookResult = ReturnType<typeof useCartLazyQuery>;
export type CartQueryResult = Apollo.QueryResult<CartQuery, CartQueryVariables>;
export const CartLengthDocument = gql`
  query CartLength {
    length: getUserCartLength
  }
`;

/**
 * __useCartLengthQuery__
 *
 * To run a query within a React component, call `useCartLengthQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartLengthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartLengthQuery({
 *   variables: {
 *   },
 * });
 */
export function useCartLengthQuery(baseOptions?: Apollo.QueryHookOptions<CartLengthQuery, CartLengthQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CartLengthQuery, CartLengthQueryVariables>(CartLengthDocument, options);
}
export function useCartLengthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CartLengthQuery, CartLengthQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CartLengthQuery, CartLengthQueryVariables>(CartLengthDocument, options);
}
export type CartLengthQueryHookResult = ReturnType<typeof useCartLengthQuery>;
export type CartLengthLazyQueryHookResult = ReturnType<typeof useCartLengthLazyQuery>;
export type CartLengthQueryResult = Apollo.QueryResult<CartLengthQuery, CartLengthQueryVariables>;
export const CartProductsDocument = gql`
  query CartProducts($productIds: [String!]!, $limit: Int!) {
    products(input: { offset: 0, limit: $limit, filters: { productIds: $productIds } }) {
      entries {
        id
        name
        brandName
        colorName
        price
        deliverySpeedDays
        weight
        boxHeight
        boxLength
        boxWidth
        specificValues {
          attributeName
          variantValue
        }
        mainPhoto {
          id
          ...localThumbnailAll
        }
        status
        sellerId
      }
    }
  }
  ${LocalThumbnailAllFragmentDoc}
`;

/**
 * __useCartProductsQuery__
 *
 * To run a query within a React component, call `useCartProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartProductsQuery({
 *   variables: {
 *      productIds: // value for 'productIds'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCartProductsQuery(
  baseOptions: Apollo.QueryHookOptions<CartProductsQuery, CartProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CartProductsQuery, CartProductsQueryVariables>(CartProductsDocument, options);
}
export function useCartProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CartProductsQuery, CartProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CartProductsQuery, CartProductsQueryVariables>(CartProductsDocument, options);
}
export type CartProductsQueryHookResult = ReturnType<typeof useCartProductsQuery>;
export type CartProductsLazyQueryHookResult = ReturnType<typeof useCartProductsLazyQuery>;
export type CartProductsQueryResult = Apollo.QueryResult<CartProductsQuery, CartProductsQueryVariables>;
export const CartSellersDocument = gql`
  query CartSellers {
    sellers: getSellers {
      id
      name
      nickname
      isTrusted
    }
  }
`;

/**
 * __useCartSellersQuery__
 *
 * To run a query within a React component, call `useCartSellersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartSellersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartSellersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCartSellersQuery(
  baseOptions?: Apollo.QueryHookOptions<CartSellersQuery, CartSellersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CartSellersQuery, CartSellersQueryVariables>(CartSellersDocument, options);
}
export function useCartSellersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CartSellersQuery, CartSellersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CartSellersQuery, CartSellersQueryVariables>(CartSellersDocument, options);
}
export type CartSellersQueryHookResult = ReturnType<typeof useCartSellersQuery>;
export type CartSellersLazyQueryHookResult = ReturnType<typeof useCartSellersLazyQuery>;
export type CartSellersQueryResult = Apollo.QueryResult<CartSellersQuery, CartSellersQueryVariables>;
export const IsProductInCartDocument = gql`
  query IsProductInCart($productId: String!) {
    isProductInCart: checkProductInUserCart(productId: $productId)
  }
`;

/**
 * __useIsProductInCartQuery__
 *
 * To run a query within a React component, call `useIsProductInCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsProductInCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsProductInCartQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useIsProductInCartQuery(
  baseOptions: Apollo.QueryHookOptions<IsProductInCartQuery, IsProductInCartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsProductInCartQuery, IsProductInCartQueryVariables>(IsProductInCartDocument, options);
}
export function useIsProductInCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsProductInCartQuery, IsProductInCartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsProductInCartQuery, IsProductInCartQueryVariables>(IsProductInCartDocument, options);
}
export type IsProductInCartQueryHookResult = ReturnType<typeof useIsProductInCartQuery>;
export type IsProductInCartLazyQueryHookResult = ReturnType<typeof useIsProductInCartLazyQuery>;
export type IsProductInCartQueryResult = Apollo.QueryResult<IsProductInCartQuery, IsProductInCartQueryVariables>;
export const CurrenciesDocument = gql`
  query Currencies {
    result: currencies {
      rates {
        id
        currency
        rate
      }
    }
  }
`;

/**
 * __useCurrenciesQuery__
 *
 * To run a query within a React component, call `useCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, options);
}
export function useCurrenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, options);
}
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>;
export type CurrenciesLazyQueryHookResult = ReturnType<typeof useCurrenciesLazyQuery>;
export type CurrenciesQueryResult = Apollo.QueryResult<CurrenciesQuery, CurrenciesQueryVariables>;
export const ChangeUserLanguageDocument = gql`
  mutation ChangeUserLanguage {
    result: changeUserLanguage
  }
`;
export type ChangeUserLanguageMutationFn = Apollo.MutationFunction<
  ChangeUserLanguageMutation,
  ChangeUserLanguageMutationVariables
>;

/**
 * __useChangeUserLanguageMutation__
 *
 * To run a mutation, you first call `useChangeUserLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserLanguageMutation, { data, loading, error }] = useChangeUserLanguageMutation({
 *   variables: {
 *   },
 * });
 */
export function useChangeUserLanguageMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeUserLanguageMutation, ChangeUserLanguageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeUserLanguageMutation, ChangeUserLanguageMutationVariables>(
    ChangeUserLanguageDocument,
    options
  );
}
export type ChangeUserLanguageMutationHookResult = ReturnType<typeof useChangeUserLanguageMutation>;
export type ChangeUserLanguageMutationResult = Apollo.MutationResult<ChangeUserLanguageMutation>;
export type ChangeUserLanguageMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserLanguageMutation,
  ChangeUserLanguageMutationVariables
>;
export const MarkNotificationReadDocument = gql`
  mutation MarkNotificationRead($notificationId: Int!) {
    markedRead: markNotificationAsRead(notificationId: $notificationId)
  }
`;
export type MarkNotificationReadMutationFn = Apollo.MutationFunction<
  MarkNotificationReadMutation,
  MarkNotificationReadMutationVariables
>;

/**
 * __useMarkNotificationReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationReadMutation, { data, loading, error }] = useMarkNotificationReadMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useMarkNotificationReadMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkNotificationReadMutation, MarkNotificationReadMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkNotificationReadMutation, MarkNotificationReadMutationVariables>(
    MarkNotificationReadDocument,
    options
  );
}
export type MarkNotificationReadMutationHookResult = ReturnType<typeof useMarkNotificationReadMutation>;
export type MarkNotificationReadMutationResult = Apollo.MutationResult<MarkNotificationReadMutation>;
export type MarkNotificationReadMutationOptions = Apollo.BaseMutationOptions<
  MarkNotificationReadMutation,
  MarkNotificationReadMutationVariables
>;
export const MarkAllOrdinaryNotificationsReadDocument = gql`
  mutation MarkAllOrdinaryNotificationsRead {
    markedRead: markAllOrdinaryNotificationsAsRead
  }
`;
export type MarkAllOrdinaryNotificationsReadMutationFn = Apollo.MutationFunction<
  MarkAllOrdinaryNotificationsReadMutation,
  MarkAllOrdinaryNotificationsReadMutationVariables
>;

/**
 * __useMarkAllOrdinaryNotificationsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllOrdinaryNotificationsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllOrdinaryNotificationsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllOrdinaryNotificationsReadMutation, { data, loading, error }] = useMarkAllOrdinaryNotificationsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllOrdinaryNotificationsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkAllOrdinaryNotificationsReadMutation,
    MarkAllOrdinaryNotificationsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkAllOrdinaryNotificationsReadMutation,
    MarkAllOrdinaryNotificationsReadMutationVariables
  >(MarkAllOrdinaryNotificationsReadDocument, options);
}
export type MarkAllOrdinaryNotificationsReadMutationHookResult = ReturnType<
  typeof useMarkAllOrdinaryNotificationsReadMutation
>;
export type MarkAllOrdinaryNotificationsReadMutationResult =
  Apollo.MutationResult<MarkAllOrdinaryNotificationsReadMutation>;
export type MarkAllOrdinaryNotificationsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkAllOrdinaryNotificationsReadMutation,
  MarkAllOrdinaryNotificationsReadMutationVariables
>;
export const NotificationsCountDocument = gql`
  query NotificationsCount($input: NotificationsFilter) {
    count: getNotificationCount(input: $input)
  }
`;

/**
 * __useNotificationsCountQuery__
 *
 * To run a query within a React component, call `useNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotificationsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<NotificationsCountQuery, NotificationsCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationsCountQuery, NotificationsCountQueryVariables>(
    NotificationsCountDocument,
    options
  );
}
export function useNotificationsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotificationsCountQuery, NotificationsCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationsCountQuery, NotificationsCountQueryVariables>(
    NotificationsCountDocument,
    options
  );
}
export type NotificationsCountQueryHookResult = ReturnType<typeof useNotificationsCountQuery>;
export type NotificationsCountLazyQueryHookResult = ReturnType<typeof useNotificationsCountLazyQuery>;
export type NotificationsCountQueryResult = Apollo.QueryResult<
  NotificationsCountQuery,
  NotificationsCountQueryVariables
>;
export const CreateStripeSetupSessionDocument = gql`
  mutation CreateStripeSetupSession($input: StripePathsInput!) {
    stripeSessionUrl: createStripeSetupSession(input: $input)
  }
`;
export type CreateStripeSetupSessionMutationFn = Apollo.MutationFunction<
  CreateStripeSetupSessionMutation,
  CreateStripeSetupSessionMutationVariables
>;

/**
 * __useCreateStripeSetupSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeSetupSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeSetupSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeSetupSessionMutation, { data, loading, error }] = useCreateStripeSetupSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStripeSetupSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateStripeSetupSessionMutation, CreateStripeSetupSessionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateStripeSetupSessionMutation, CreateStripeSetupSessionMutationVariables>(
    CreateStripeSetupSessionDocument,
    options
  );
}
export type CreateStripeSetupSessionMutationHookResult = ReturnType<typeof useCreateStripeSetupSessionMutation>;
export type CreateStripeSetupSessionMutationResult = Apollo.MutationResult<CreateStripeSetupSessionMutation>;
export type CreateStripeSetupSessionMutationOptions = Apollo.BaseMutationOptions<
  CreateStripeSetupSessionMutation,
  CreateStripeSetupSessionMutationVariables
>;
export const DetachPaymentMethodDocument = gql`
  mutation DetachPaymentMethod {
    result: detachPaymentMethod
  }
`;
export type DetachPaymentMethodMutationFn = Apollo.MutationFunction<
  DetachPaymentMethodMutation,
  DetachPaymentMethodMutationVariables
>;

/**
 * __useDetachPaymentMethodMutation__
 *
 * To run a mutation, you first call `useDetachPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachPaymentMethodMutation, { data, loading, error }] = useDetachPaymentMethodMutation({
 *   variables: {
 *   },
 * });
 */
export function useDetachPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<DetachPaymentMethodMutation, DetachPaymentMethodMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DetachPaymentMethodMutation, DetachPaymentMethodMutationVariables>(
    DetachPaymentMethodDocument,
    options
  );
}
export type DetachPaymentMethodMutationHookResult = ReturnType<typeof useDetachPaymentMethodMutation>;
export type DetachPaymentMethodMutationResult = Apollo.MutationResult<DetachPaymentMethodMutation>;
export type DetachPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  DetachPaymentMethodMutation,
  DetachPaymentMethodMutationVariables
>;
export const TicketMessagesCountDocument = gql`
  query TicketMessagesCount {
    count: unreadTicketMessagesCount
  }
`;

/**
 * __useTicketMessagesCountQuery__
 *
 * To run a query within a React component, call `useTicketMessagesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketMessagesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketMessagesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useTicketMessagesCountQuery(
  baseOptions?: Apollo.QueryHookOptions<TicketMessagesCountQuery, TicketMessagesCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketMessagesCountQuery, TicketMessagesCountQueryVariables>(
    TicketMessagesCountDocument,
    options
  );
}
export function useTicketMessagesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TicketMessagesCountQuery, TicketMessagesCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketMessagesCountQuery, TicketMessagesCountQueryVariables>(
    TicketMessagesCountDocument,
    options
  );
}
export type TicketMessagesCountQueryHookResult = ReturnType<typeof useTicketMessagesCountQuery>;
export type TicketMessagesCountLazyQueryHookResult = ReturnType<typeof useTicketMessagesCountLazyQuery>;
export type TicketMessagesCountQueryResult = Apollo.QueryResult<
  TicketMessagesCountQuery,
  TicketMessagesCountQueryVariables
>;
export const CdnDocument = gql`
  query CDN {
    cdn: getCDN
  }
`;

/**
 * __useCdnQuery__
 *
 * To run a query within a React component, call `useCdnQuery` and pass it any options that fit your needs.
 * When your component renders, `useCdnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCdnQuery({
 *   variables: {
 *   },
 * });
 */
export function useCdnQuery(baseOptions?: Apollo.QueryHookOptions<CdnQuery, CdnQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CdnQuery, CdnQueryVariables>(CdnDocument, options);
}
export function useCdnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CdnQuery, CdnQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CdnQuery, CdnQueryVariables>(CdnDocument, options);
}
export type CdnQueryHookResult = ReturnType<typeof useCdnQuery>;
export type CdnLazyQueryHookResult = ReturnType<typeof useCdnLazyQuery>;
export type CdnQueryResult = Apollo.QueryResult<CdnQuery, CdnQueryVariables>;
export const CreateUserAddressDocument = gql`
  mutation CreateUserAddress($input: UserAddressCreateInput!) {
    result: createUserAddress(input: $input) {
      id
    }
  }
`;
export type CreateUserAddressMutationFn = Apollo.MutationFunction<
  CreateUserAddressMutation,
  CreateUserAddressMutationVariables
>;

/**
 * __useCreateUserAddressMutation__
 *
 * To run a mutation, you first call `useCreateUserAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserAddressMutation, { data, loading, error }] = useCreateUserAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserAddressMutation, CreateUserAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserAddressMutation, CreateUserAddressMutationVariables>(
    CreateUserAddressDocument,
    options
  );
}
export type CreateUserAddressMutationHookResult = ReturnType<typeof useCreateUserAddressMutation>;
export type CreateUserAddressMutationResult = Apollo.MutationResult<CreateUserAddressMutation>;
export type CreateUserAddressMutationOptions = Apollo.BaseMutationOptions<
  CreateUserAddressMutation,
  CreateUserAddressMutationVariables
>;
export const DeleteUserAddressDocument = gql`
  mutation DeleteUserAddress($id: String!) {
    result: deleteUserAddress(userAddressId: $id)
  }
`;
export type DeleteUserAddressMutationFn = Apollo.MutationFunction<
  DeleteUserAddressMutation,
  DeleteUserAddressMutationVariables
>;

/**
 * __useDeleteUserAddressMutation__
 *
 * To run a mutation, you first call `useDeleteUserAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAddressMutation, { data, loading, error }] = useDeleteUserAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserAddressMutation, DeleteUserAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserAddressMutation, DeleteUserAddressMutationVariables>(
    DeleteUserAddressDocument,
    options
  );
}
export type DeleteUserAddressMutationHookResult = ReturnType<typeof useDeleteUserAddressMutation>;
export type DeleteUserAddressMutationResult = Apollo.MutationResult<DeleteUserAddressMutation>;
export type DeleteUserAddressMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserAddressMutation,
  DeleteUserAddressMutationVariables
>;
export const UpdateUserAddressDocument = gql`
  mutation UpdateUserAddress($input: UserAddressUpdateInput!) {
    result: updateUserAddress(input: $input) {
      id
    }
  }
`;
export type UpdateUserAddressMutationFn = Apollo.MutationFunction<
  UpdateUserAddressMutation,
  UpdateUserAddressMutationVariables
>;

/**
 * __useUpdateUserAddressMutation__
 *
 * To run a mutation, you first call `useUpdateUserAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAddressMutation, { data, loading, error }] = useUpdateUserAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserAddressMutation, UpdateUserAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserAddressMutation, UpdateUserAddressMutationVariables>(
    UpdateUserAddressDocument,
    options
  );
}
export type UpdateUserAddressMutationHookResult = ReturnType<typeof useUpdateUserAddressMutation>;
export type UpdateUserAddressMutationResult = Apollo.MutationResult<UpdateUserAddressMutation>;
export type UpdateUserAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserAddressMutation,
  UpdateUserAddressMutationVariables
>;
export const CountriesDocument = gql`
  query Countries($input: CountryCommonInput!) {
    addressDirectory {
      countries(input: $input) {
        id
        name
        nameJp
        supportsZipCode
        alpha2
      }
    }
  }
`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCountriesQuery(baseOptions: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
}
export function useCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
}
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const AddressDocument = gql`
  query Address($zipCode: String!, $countryCode: String!) {
    addressDirectory {
      addresses(input: { zipCode: $zipCode, countryCode: $countryCode }) {
        city
        postcode
        allAddress
        town
        countryCode
        province
        provinceCode
        prefecture
        prefectureId
        state
        stateCode
      }
    }
  }
`;

/**
 * __useAddressQuery__
 *
 * To run a query within a React component, call `useAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressQuery({
 *   variables: {
 *      zipCode: // value for 'zipCode'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useAddressQuery(baseOptions: Apollo.QueryHookOptions<AddressQuery, AddressQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AddressQuery, AddressQueryVariables>(AddressDocument, options);
}
export function useAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddressQuery, AddressQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AddressQuery, AddressQueryVariables>(AddressDocument, options);
}
export type AddressQueryHookResult = ReturnType<typeof useAddressQuery>;
export type AddressLazyQueryHookResult = ReturnType<typeof useAddressLazyQuery>;
export type AddressQueryResult = Apollo.QueryResult<AddressQuery, AddressQueryVariables>;
export const RegisterCustomerDocument = gql`
  mutation RegisterCustomer {
    result: registerCustomer
  }
`;
export type RegisterCustomerMutationFn = Apollo.MutationFunction<
  RegisterCustomerMutation,
  RegisterCustomerMutationVariables
>;

/**
 * __useRegisterCustomerMutation__
 *
 * To run a mutation, you first call `useRegisterCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCustomerMutation, { data, loading, error }] = useRegisterCustomerMutation({
 *   variables: {
 *   },
 * });
 */
export function useRegisterCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterCustomerMutation, RegisterCustomerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterCustomerMutation, RegisterCustomerMutationVariables>(
    RegisterCustomerDocument,
    options
  );
}
export type RegisterCustomerMutationHookResult = ReturnType<typeof useRegisterCustomerMutation>;
export type RegisterCustomerMutationResult = Apollo.MutationResult<RegisterCustomerMutation>;
export type RegisterCustomerMutationOptions = Apollo.BaseMutationOptions<
  RegisterCustomerMutation,
  RegisterCustomerMutationVariables
>;
export const SendFeedbackMessageDocument = gql`
  mutation SendFeedbackMessage($input: CreatePublicCustomerServiceMessageInput!) {
    success: sendNewPublicCustomerServiceMessage(input: $input)
  }
`;
export type SendFeedbackMessageMutationFn = Apollo.MutationFunction<
  SendFeedbackMessageMutation,
  SendFeedbackMessageMutationVariables
>;

/**
 * __useSendFeedbackMessageMutation__
 *
 * To run a mutation, you first call `useSendFeedbackMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFeedbackMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFeedbackMessageMutation, { data, loading, error }] = useSendFeedbackMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendFeedbackMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<SendFeedbackMessageMutation, SendFeedbackMessageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendFeedbackMessageMutation, SendFeedbackMessageMutationVariables>(
    SendFeedbackMessageDocument,
    options
  );
}
export type SendFeedbackMessageMutationHookResult = ReturnType<typeof useSendFeedbackMessageMutation>;
export type SendFeedbackMessageMutationResult = Apollo.MutationResult<SendFeedbackMessageMutation>;
export type SendFeedbackMessageMutationOptions = Apollo.BaseMutationOptions<
  SendFeedbackMessageMutation,
  SendFeedbackMessageMutationVariables
>;
export const NavigationDocument = gql`
  query Navigation {
    marketPlace {
      categories {
        id
        name
        subCategoryIds
      }
      subCategories {
        id
        name
        subSubCategoryIds
      }
      subSubCategories {
        id
        name
      }
    }
  }
`;

/**
 * __useNavigationQuery__
 *
 * To run a query within a React component, call `useNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavigationQuery(baseOptions?: Apollo.QueryHookOptions<NavigationQuery, NavigationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NavigationQuery, NavigationQueryVariables>(NavigationDocument, options);
}
export function useNavigationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NavigationQuery, NavigationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NavigationQuery, NavigationQueryVariables>(NavigationDocument, options);
}
export type NavigationQueryHookResult = ReturnType<typeof useNavigationQuery>;
export type NavigationLazyQueryHookResult = ReturnType<typeof useNavigationLazyQuery>;
export type NavigationQueryResult = Apollo.QueryResult<NavigationQuery, NavigationQueryVariables>;
