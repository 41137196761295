import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import s from './ChatInput.module.scss';
export interface ChatInputProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  maxInputHeight?: number;
  pasteAction?: (file: File) => void;
  pasteLimiter?: boolean;
}

export const ChatInput = ({
  onChange,
  disabled,
  className,
  onKeyDown,
  value,
  maxInputHeight,
  maxLength,
  pasteLimiter,
  pasteAction
}: ChatInputProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isComposing, setIsComposing] = useState(false);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '40px';
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, maxInputHeight || 300)}px`;
    }
  }, [value]);

  const pasteHandler = useCallback(
    (event: any) => {
      const clipboardData = event.clipboardData;
      const items = clipboardData?.items;
      let hasImage = false;

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (item.type.startsWith('image/')) {
          event.preventDefault();

          const file = item.getAsFile();
          if (file) {
            pasteAction?.(file);
            hasImage = true;
          }
        }
      }

      if (!hasImage) {
        return;
      }
    },
    [pasteLimiter, pasteAction]
  );

  const handleCompositionStart = useCallback(() => {
    setIsComposing(true);
  }, [setIsComposing]);

  const handleCompositionEnd = useCallback(() => {
    setIsComposing(false);
  }, [setIsComposing]);

  // Send message on Enter and avoid issues with Japanese romanji editor
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.altKey && !e.metaKey && !e.shiftKey && !e.ctrlKey && !isComposing) {
        e.preventDefault();
        onKeyDown?.(e);
      }
    },
    [isComposing, onKeyDown]
  );

  return (
    <textarea
      ref={textareaRef}
      className={clsx(s.ChatInput, className)}
      onChange={onChange}
      maxLength={maxLength}
      value={value}
      disabled={disabled}
      onKeyDown={handleKeyDown}
      onCompositionStart={handleCompositionStart}
      onCompositionEnd={handleCompositionEnd}
      onPaste={pasteHandler}
    />
  );
};
