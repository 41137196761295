import clsx from 'clsx';
import { BsEmojiFrown, BsEmojiNeutral, BsEmojiSmile } from 'react-icons/bs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonSize, ButtonVariant } from '../../../../../common/components/Button';
import { RatingValueEnum, ReviewsCount, ReviewsUserRoleEnum } from '../../../../../store/graphql';
import { Divider } from '../../../../../common/components/Divider/Divider';
import { Grid } from '../../../../../common/components/Grid';
import s from './ReviewFilters.module.scss';

export type ReviewFiltersProps = {
  ratingTypes: RatingValueEnum[];
  reviewFrom?: ReviewsUserRoleEnum[];
  chooseRatingTypes: (ratingType: RatingValueEnum) => void;
  chooseReviewer?: (changedReviewer: ReviewsUserRoleEnum) => void;
  reviewsCount: ReviewsCount;
  isSeller?: boolean;
  isOwnReview?: boolean;
};
export const ReviewFilters = ({
  ratingTypes,
  reviewFrom,
  chooseRatingTypes,
  chooseReviewer,
  reviewsCount,
  isSeller,
  isOwnReview
}: ReviewFiltersProps) => {
  return (
    <Grid cols={{ xs: 3, md: 12, lg: 12 }}>
      <Grid.GridItem cols={{ xs: 3, md: 12, lg: 12 }}>
        <div className={s.ReviewFilters}>
          <span className={s.ReviewFilters__filtersLabel}>Filter: </span>
          <div className={s.ReviewFilters__ratingButtons}>
            <Button
              variant={ButtonVariant.SECONDARY_OUTLINE}
              className={clsx(
                s.ReviewFilters__button,
                s.ReviewFilters__button_positive,
                ratingTypes.includes(RatingValueEnum.Positive) && s.ReviewFilters__buttonActive
              )}
              size={ButtonSize.SMALL}
              onClick={() => chooseRatingTypes(RatingValueEnum.Positive)}
            >
              <BsEmojiSmile /> <FormattedMessage id={'reviews.positive'} /> ({reviewsCount?.positive})
            </Button>
            <Button
              variant={ButtonVariant.SECONDARY_OUTLINE}
              className={clsx(
                s.ReviewFilters__button,
                s.ReviewFilters__button_neutral,
                ratingTypes.includes(RatingValueEnum.Neutral) && s.ReviewFilters__buttonActive
              )}
              size={ButtonSize.SMALL}
              onClick={() => chooseRatingTypes(RatingValueEnum.Neutral)}
            >
              <BsEmojiNeutral /> <FormattedMessage id={'reviews.neutral'} /> ({reviewsCount?.neutral})
            </Button>
            <Button
              variant={ButtonVariant.SECONDARY_OUTLINE}
              className={clsx(
                s.ReviewFilters__button,
                s.ReviewFilters__button_negative,
                ratingTypes && ratingTypes.includes(RatingValueEnum.Negative) && s.ReviewFilters__buttonActive
              )}
              size={ButtonSize.SMALL}
              onClick={() => chooseRatingTypes(RatingValueEnum.Negative)}
            >
              <BsEmojiFrown /> <FormattedMessage id={'reviews.negative'} /> ({reviewsCount?.negative})
            </Button>
          </div>
          {isSeller && chooseReviewer && (
            <>
              <Divider className={s.ReviewFilters__filtersDivider} />
              <div className={s.ReviewFilters__reviewerButtons}>
                <Button
                  variant={ButtonVariant.SECONDARY_OUTLINE}
                  className={clsx(
                    s.ReviewFilters__button,
                    s.ReviewFilters__button_reviewer,
                    reviewFrom && reviewFrom.includes(ReviewsUserRoleEnum.Customer) && s.ReviewFilters__buttonActive
                  )}
                  size={ButtonSize.SMALL}
                  onClick={() => chooseReviewer(ReviewsUserRoleEnum.Customer)}
                >
                  {isOwnReview ? (
                    <FormattedMessage id={'reviews.toCustomer'} />
                  ) : (
                    <FormattedMessage id={'reviews.customer'} />
                  )}{' '}
                  ({reviewsCount?.customer})
                </Button>
                <Button
                  variant={ButtonVariant.SECONDARY_OUTLINE}
                  className={clsx(
                    s.ReviewFilters__button,
                    s.ReviewFilters__button_reviewer,
                    reviewFrom && reviewFrom.includes(ReviewsUserRoleEnum.Seller) && s.ReviewFilters__buttonActive
                  )}
                  size={ButtonSize.SMALL}
                  onClick={() => chooseReviewer(ReviewsUserRoleEnum.Seller)}
                >
                  {isOwnReview ? (
                    <FormattedMessage id={'reviews.toSeller'} />
                  ) : (
                    <FormattedMessage id={'reviews.seller'} />
                  )}{' '}
                  ({reviewsCount?.seller})
                </Button>
              </div>
            </>
          )}
        </div>
      </Grid.GridItem>
    </Grid>
  );
};
