import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { FormattedDate } from 'common/components/CustomFormatters';
import { formatMoney } from 'common/utils/format';
import { Badge } from 'common/components/Badge/Badge';
import { WalletOperationsCell, WalletOperationsRow } from '../../WalletOperationsGrid';
import { RequestType } from '../../WalletTransactions/types';
import { requestBadgeVariantByStatus, requestStatusIntlId } from '../../WalletTransactions/constants';
import s from './RequestRow.module.scss';

export interface RequestRowProps extends RequestType {
  className?: string;
}

export const RequestRow = ({ className, date, withdrawAmount, status }: RequestRowProps) => {
  return (
    <WalletOperationsRow className={clsx(s.RequestRow, className)}>
      <WalletOperationsCell>
        <FormattedDate value={new Date(date)} format={'yyyy.MM.dd'} />
      </WalletOperationsCell>
      <WalletOperationsCell>
        <FormattedDate value={new Date(date)} format={'HH:mm'} />
      </WalletOperationsCell>
      <WalletOperationsCell>{formatMoney(withdrawAmount)}</WalletOperationsCell>
      <WalletOperationsCell>
        <Badge className={s.RequestRow__status} variant={requestBadgeVariantByStatus[status]}>
          <FormattedMessage id={requestStatusIntlId[status]} />
        </Badge>
      </WalletOperationsCell>
    </WalletOperationsRow>
  );
};
