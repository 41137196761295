import { NotificationRateEnum, RatingValueEnum } from '../graphql';

export class NotificationsUtils {
  static ratingValueToNotificationRateMap = {
    [RatingValueEnum.Positive]: NotificationRateEnum.Positive,
    [RatingValueEnum.Neutral]: NotificationRateEnum.Neutral,
    [RatingValueEnum.Negative]: NotificationRateEnum.Negative
  };
  static notificationRateToRatingValueMap = {
    [NotificationRateEnum.Positive]: RatingValueEnum.Positive,
    [NotificationRateEnum.Neutral]: RatingValueEnum.Neutral,
    [NotificationRateEnum.Negative]: RatingValueEnum.Negative
  };
}
