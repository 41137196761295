import { createBreakpoint } from 'react-use';
import vars from '../../app/styles/_vars_export.module.scss';

export enum Breakpoints {
  mobile = 'mobile',
  // tablet = 'tablet',
  desktop = 'desktop'
}

export const BreakpointValues = {
  [Breakpoints.mobile]: parseFloat(vars.mediaWidthMobile),
  // [Breakpoints.tablet]: parseFloat(vars.mediaWidthTablet),
  [Breakpoints.desktop]: parseFloat(vars.mediaWidthDesktop)
};

const useBreakpoint = createBreakpoint(BreakpointValues);

export const useWindowSize = () => {
  const breakpoint = useBreakpoint();

  return {
    isMobile: breakpoint === Breakpoints.mobile,
    // isTablet: breakpoint === Breakpoints.tablet,
    isDesktop: breakpoint === Breakpoints.desktop
  };
};
