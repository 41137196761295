import React from 'react';
import { ToastContainer as BaseToastContainer } from 'react-toastify';

export interface ToastProps {}

export const ToastContainer: React.FC<ToastProps> = () => {
  return (
    <BaseToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};
