import { ReactFCC } from 'common/utils/helperTypes';
import { BsTrash3 } from 'react-icons/bs';
import React, { useCallback } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Anchor, AnchorVariant } from '../../../../../common/components/Anchor/Anchor';
import { FavoritesDocument, useRemoveAllProductsFromFavoritesMutation } from '../../../../../store/graphql';
import { handleDefaultError } from '../../../../../common/utils/handleDefaultError';
import { useToggle } from '../../../../../common/hooks/useToggle';
import { RemoveAllFavoritesModal } from './RemoveAllFavoritesModal';

export interface RemoveAllFavoritesProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
}

export const RemoveAllFavorites: ReactFCC<RemoveAllFavoritesProps> = (props) => {
  const { className } = props;

  const [isOpen, { set: openModal, unset: closeModal }] = useToggle();

  const [removeMutation, { loading }] = useRemoveAllProductsFromFavoritesMutation();

  const removeFavorites = useCallback(async () => {
    try {
      await removeMutation({
        refetchQueries: [FavoritesDocument],
        awaitRefetchQueries: true
      });

      closeModal();
    } catch (e) {
      handleDefaultError('Something went wrong', e);
    }
  }, [closeModal, removeMutation]);

  return (
    <>
      <Anchor
        component={'button'}
        className={clsx(className)}
        variant={AnchorVariant.DANGER}
        leftIcon={BsTrash3}
        leftIconOptions={{ size: 16 }}
        onClick={openModal}
      >
        <FormattedMessage id={'product.favoriteRemoveAll'} />
      </Anchor>

      <RemoveAllFavoritesModal
        isOpen={isOpen}
        onClose={closeModal}
        onClickConfirm={removeFavorites}
        loading={loading}
      />
    </>
  );
};
