import React, { useMemo } from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';
import { ImageViewerDesktop } from './ImageViewerDesktop/ImageViewerDesktop';
import { ImageViewerBaseProps } from './types';
import { imageSize } from './constants';
import { ImageViewerMobile } from './ImageViewerMobile/ImageViewerMobile';

const imagePlaceholder = `https://via.placeholder.com/${imageSize}`;

export const ImageViewer: React.FC<ImageViewerBaseProps> = ({ className, images: inputImages, mainPhoto }) => {
  const { isDesktop } = useWindowSize();

  const images = useMemo(() => {
    if (inputImages && inputImages.length) {
      return [
        mainPhoto,
        ...inputImages.filter((image) => image.msfiles_uid && image.msfiles_uid !== mainPhoto?.msfiles_uid)
      ]
        .filter(Boolean)
        .map((i) => i?.thumbnails?.L?.url || imagePlaceholder);
    }

    return [imagePlaceholder];
  }, [inputImages, mainPhoto]);

  return isDesktop ? (
    <ImageViewerDesktop className={className} images={images} />
  ) : (
    <ImageViewerMobile className={className} images={images} />
  );
};
