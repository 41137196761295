import { format } from 'date-fns';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { usePickupRequestsQuery } from '../../../../store/graphql';
import { LoaderBox } from '../../../../common/components/Loader';
import { Heading, HeadingSize } from '../../../../common/components/Heading';
import { ESpaceSize, Space } from '../../../../common/components/Space/Space';
import { PickupRequestCard } from './components/PickupRequestCard';
import s from './PickupRequests.module.scss';

export const PickupRequests = () => {
  const { data: pickupRequestsQuery, loading: pickupRequestsLoading } = usePickupRequestsQuery({
    fetchPolicy: 'cache-and-network'
  });

  const pickupRequests = pickupRequestsQuery?.result;

  if (pickupRequestsLoading) {
    return <LoaderBox />;
  }

  return (
    <div className={s.PickupRequests}>
      {pickupRequests && pickupRequests?.length > 0 ? (
        pickupRequests?.map((request, index) => (
          <PickupRequestCard
            key={index}
            uuid={request.uuid}
            pickupDate={format(
              new Date(new Date(request?.pickupDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })),
              'yyyy.MM.dd'
            )}
            interval={`${format(
              new Date(new Date(request?.pickupDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })),
              'HH:mm'
            )} - ${request.latestTime.slice(0, request.latestTime.length - 3)}`}
            ordersAmount={request.ordersAmount}
            active={request.active}
          />
        ))
      ) : (
        <>
          <Heading size={HeadingSize.H5}>
            <Space size={ESpaceSize.PIXEL_16} />
            <FormattedMessage id={'pickupRequest.noPickups'} />
          </Heading>
        </>
      )}
    </div>
  );
};
