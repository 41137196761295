import { createContext } from 'react';

export interface QueryParamBatchType {
  key: string;
  value: string | null;
}

export interface IQueryParamContext {
  setParamValue: (key: string, value: string | null) => void;
  searchParams: URLSearchParams;
}

export const QueryParamContext = createContext<IQueryParamContext>({
  setParamValue: () => {},
  searchParams: new URLSearchParams()
});
