export const LOCAL_STORAGE_AUTH = 'auth';
export const LOCAL_STORAGE_CART = 'cart';

export const LOCAL_STORAGE_LANGUAGE = 'language';

export const LOCAL_STORAGE_CURRENCY = 'currency';

export const LOCAL_STORAGE_COOKIE = 'cookie';

export const LOCAL_STORAGE_ORDERING = 'ordering';
