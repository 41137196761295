export const validateEmail = (v: string) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v);

export const validateNickname = (v: string) => /^[A-Za-z0-9]+([A-Za-z0-9]*|[_.]?[A-Za-z0-9]+)*$/.test(v);

export const validateErrors = {
  required: 'Required field',
  wrongEmail: 'E-mail entered incorrectly',
  wrongNickname: 'Must contains only letters, numbers and "_" or "."',
  positiveNumber: 'Must be greater than 0'
};

export class Validator {
  static errors = {
    required: 'Required field',
    wrongEmail: 'E-mail entered incorrectly',
    wrongNickname: 'Must contains only letters, numbers and "_" or "."',
    positiveNumber: 'Must be greater than 0',
    maxLength: (length = 255) => `Must be maximum ${length} characters long`
  };

  static validateEmail = (v: string) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v);

  static validateNickname = (v: string) => /^[A-Za-z0-9]+([A-Za-z0-9]*|[_.]?[A-Za-z0-9]+)*$/.test(v);
}
