import { useEffect } from 'react';

/**
 * Hook to trigger action on tab close
 * - works as useBeforeUnload, but with skip dependency
 *
 * @param action
 * @param skip
 */
export function useOnTabClose(action: () => void, skip?: boolean) {
  useEffect(() => {
    if (!skip) return;
    window.addEventListener('beforeunload', action);
    return () => {
      window.removeEventListener('beforeunload', action);
    };
  }, [skip, action]);
}
