import React from 'react';

export enum AvailableCurrencyEnum {
  JPY = 'JPY',
  USD = 'USD',
  EUR = 'EUR',
  CAD = 'CAD',
  GBP = 'GBP',
  AUD = 'AUD',
  MYR = 'MYR'
}

export type TCurrencyContext = {
  currency: AvailableCurrencyEnum;
  rates: { [key: string]: number };
  currencyLoading?: boolean;
  changeUserCurrency: (currency: AvailableCurrencyEnum) => void;
  convertCurrency: (value: number) => string;
};

export const CurrencyContext = React.createContext<TCurrencyContext>({
  currency: AvailableCurrencyEnum.JPY,
  rates: {},
  currencyLoading: false,
  changeUserCurrency: () => {},
  convertCurrency: () => ''
});
