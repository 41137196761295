/**
 * Home
 */
export const HOME_ROUTE = '/';

/**
 * Product
 */
export const PRODUCT_PAGE_PARAM = 'productId';
export const PRODUCT_ROUTE = `/product/:${PRODUCT_PAGE_PARAM}`;

/**
 * Catalog
 */
export const CATEGORY_PAGE_PARAM = 'categoryId';
export const SUB_CATEGORY_PAGE_PARAM = 'subCategoryId';
export const SUB_SUB_CATEGORY_PAGE_PARAM = 'subSubCategoryId';
export const CATALOG_ROUTE = `/catalog/:${CATEGORY_PAGE_PARAM}/:${SUB_CATEGORY_PAGE_PARAM}/:${SUB_SUB_CATEGORY_PAGE_PARAM}`;

/**
 * Cart
 */
export const CART_ROUTE = '/cart';

/**
 * Profile
 */
export const PROFILE_ROUTE = '/me';
export const PERSONAL_INFORMATION_ROUTE = '/me/personal-information';
export const PUBLIC_PROFILE_ROUTE = '/me/public-page';
export const FAVORITES_ROUTE = '/me/favorites';
export const WALLET_ROUTE = '/me/wallet';
export const TRUST_POINTS_ROUTE = '/me/trust-points';
export const TRUST_POINTS_GUIDE_ROUTE = '/me/trust-points/guide';
export const VERIFICATION_ROUTE = '/me/verification';
export const SUPPORT_ROUTE = '/me/support';
export const WITHDRAW_ROUTE = '/me/wallet/withdraw';
export const SHOWCASE_ROUTE = '/me/showcase';
export const DRAFTS_ROUTE = '/me/drafts';
export const REVIEWS_ROUTE = '/me/reviews';

export const CREATE_PRODUCT_ROUTE = '/me/create';
export const RELIST_PRODUCT_PAGE_PARAM = 'relistId';
export const EDIT_PRODUCT_PAGE_PARAM = 'productId';
export const EDIT_PRODUCT_DRAFT_PAGE_PARAM = 'draftId';
export const EDIT_PRODUCT_ROUTE = `/me/edit/:${EDIT_PRODUCT_PAGE_PARAM}`;
export const EDIT_PRODUCT_DRAFT_ROUTE = `/me/editDraft/:${EDIT_PRODUCT_DRAFT_PAGE_PARAM}`;
export const RELIST_PRODUCT_ROUTE = `/me/relist/:${RELIST_PRODUCT_PAGE_PARAM}`;

/**
 * Order (for customer and for seller)
 */
export const ORDER_PAGE_PARAM = 'orderId';
export const CUSTOMER_ORDERS_ROUTE = '/me/orders';
export const CUSTOMER_ORDER_ROUTE = `/me/orders/:${ORDER_PAGE_PARAM}`;
export const SELLER_ORDER_ROUTE = `/me/orders/seller/:${ORDER_PAGE_PARAM}`;

/**
 *  Tickets
 */
export const TICKET_PAGE_PARAM = 'ticketId';
export const CREATE_TICKET_ROUTE = '/me/tickets/create';
export const SINGLE_TICKET_ROUTE = `/me/tickets/:${TICKET_PAGE_PARAM}`;
export const TICKETS_ROUTE = '/me/tickets';

/**
 * Pick-up Requests
 */
export const PICKUP_REQUEST_PAGE_PARAM = 'pickupUuid';
export const PICKUP_ROUTE = '/me/pickup-requests';
export const PICKUP_REQUEST_ROUTE = `/me/pickup-requests/:${PICKUP_REQUEST_PAGE_PARAM}`;

/**
 * Ordering
 */
export const ORDERING_ROUTE = `/ordering`;

/**
 * Notifications
 */
export const NOTIFICATIONS_ROUTE = '/notifications';

/**
 * Public
 */
export const PUBLIC_PAGE_PARAM = 'id';
export const CUSTOMER_PUBLIC_ROUTE = `/users/:${PUBLIC_PAGE_PARAM}`;
export const SELLER_PUBLIC_ROUTE = `/sellers/:${PUBLIC_PAGE_PARAM}`;

/**
 * About
 */
export const ABOUT_ROUTE = '/about';
export const COMPANY_ROUTE = `/about/company`;
export const TERMS_ROUTE = `/about/terms-of-use`;
export const PRIVACY_ROUTE = '/about/privacy-policy';
export const LAW_ROUTE = '/about/commercial-law';
export const COOKIES_ROUTE = '/about/use-of-cookies';
export const TESTIMONIALS_ROUTE = '/about/testimonials';
export const CONTACT_ROUTE = '/about/contact';

/**
 * Manuals
 */
export const MANUALS_ROUTE = '/manuals';
export const PAYMENT_ROUTE = '/manuals/payment';
export const COMMISSION_ROUTE = '/manuals/commission';
export const REFUNDS_ROUTE = '/manuals/refunds';
export const DELIVERY_ROUTE = '/manuals/delivery';
export const CALCULATOR_ROUTE = '/manuals/calculator';
export const PROHIBITED_ITEMS_ROUTE = '/manuals/prohibited-items';
export const SIZE_LIMIT_ROUTE = '/manuals/size-limitation';

/**
 * Support
 */
export const SUPPORT_HUB_ROUTE = '/support';
export const HELP_ROUTE = '/help';
export const ADDITIONAL_SERVICE_ROUTE = '/help/additional-services';
export const DELIVERY_PROBLEMS_ROUTE = '/help/delivery-problems';
export const PURCHASE_ISSUES_ROUTE = '/help/purchase-issues';
export const SELLER_IDENTITY_ROUTE = '/help/seller-identity';
export const WITHDRAW_HELP_ROUTE = '/help/withdraw';
export const SUPPORT_HELP_ROUTE = '/help/support';

/**
 * Mobile app
 */
export const OPEN_MOBILE_APP_ROUTE = '/mobile-app';

/**
 * Not found
 */
export const NOT_FOUND_ROUTE = '/404';
