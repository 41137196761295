import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import { BsBook, BsBriefcase, BsPatchQuestion } from 'react-icons/bs';
import { cdnFilenames, useUi } from '../../../store/ui';
import { Head } from '../../../common/components/Head';
import { Heading, HeadingSize } from '../../../common/components/Heading';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';

import { ReactComponent as IconTwitter } from '../../../widgets/layout/components/Footer/assets/i-twitter.svg';
import { ReactComponent as IconLinkedin } from '../../../widgets/layout/components/Footer/assets/i-linkedin.svg';
import { ReactComponent as IconInstagram } from '../../../widgets/layout/components/Footer/assets/i-instagram.svg';
import { ReactComponent as IconFacebook } from '../../../widgets/layout/components/Footer/assets/i-facebook.svg';
import { Divider } from '../../../common/components/Divider/Divider';
import { SupportItem } from './components/SupportItem/SupportItem';

import s from './SupportPage.module.scss';

// TODO - практически копия футера, будет ли обновляться в будущем?
export const SupportPage = () => {
  const intl = useIntl();
  const { createCDNFileLink } = useUi();

  return (
    <div className={s.SupportPage}>
      <Head title={'Information & Guides'} />

      <Heading size={HeadingSize.H3}>
        <FormattedMessage id={'profile.guides'} />
      </Heading>

      <Space size={ESpaceSize.PIXEL_32} />
      <div className={s.SupportPage__items}>
        <div className={s.SupportPage__item}>
          <SupportItem
            title={intl.formatMessage({ id: 'footer.userManual' })}
            headerIcon={BsBook}
            items={[
              {
                name: intl.formatMessage({ id: 'footer.linkPaymentMethods' }),
                path: createCDNFileLink(cdnFilenames.paymentMethods),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkPaymentCurrency' }),
                path: createCDNFileLink(cdnFilenames.paymentCurrency),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkPaymentProcessing' }),
                path: createCDNFileLink(cdnFilenames.paymentProcessingTime),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkCommissions' }),
                path: createCDNFileLink(cdnFilenames.systemCommissions),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkRefunds' }),
                path: createCDNFileLink(cdnFilenames.refunds),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkDelivery' }),
                path: createCDNFileLink(cdnFilenames.methodsAndTermsOfDelivery),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkShipping' }),
                path: '#'
              },
              {
                name: intl.formatMessage({ id: 'footer.linkProhibited' }),
                path: createCDNFileLink(cdnFilenames.prohibitedItems),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkLimit' }),
                path: createCDNFileLink(cdnFilenames.sizeAndWeightLimit),
                external: true
              }
            ]}
          ></SupportItem>
          <SupportItem
            title={intl.formatMessage({ id: 'footer.help' })}
            headerIcon={BsPatchQuestion}
            items={[
              {
                name: intl.formatMessage({ id: 'footer.linkFaq' }),
                path: createCDNFileLink(cdnFilenames.faq),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkSupport' }),
                path: createCDNFileLink(cdnFilenames.support),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkAdditionalServices' }),
                path: createCDNFileLink(cdnFilenames.additionalServices),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkDeliveryProblems' }),
                path: createCDNFileLink(cdnFilenames.problemsWithDelivery),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkProductProblems' }),
                path: createCDNFileLink(cdnFilenames.productProblems),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkSellerIdentification' }),
                path: createCDNFileLink(cdnFilenames.sellerIdentification),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkWithdrawal' }),
                path: createCDNFileLink(cdnFilenames.withdrawalOfFundsByTheSeller),
                external: true
              }
            ]}
          ></SupportItem>
          <SupportItem
            title={intl.formatMessage({ id: 'footer.aboutUs' })}
            headerIcon={BsBriefcase}
            items={[
              {
                name: intl.formatMessage({ id: 'footer.linkAboutCompany' }),
                path: createCDNFileLink(cdnFilenames.aboutCompany),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkTermsOfUse' }),
                path: createCDNFileLink(cdnFilenames.termsOfUse),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkPrivacyPolicy' }),
                path: createCDNFileLink(cdnFilenames.privacyPolicy),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkLaw' }),
                path: createCDNFileLink(cdnFilenames.commercialTransactionsLaw),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkCookies' }),
                path: createCDNFileLink(cdnFilenames.useOfCookies),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkReviews' }),
                path: '#'
              },
              {
                name: intl.formatMessage({ id: 'footer.linkContacts' }),
                path: createCDNFileLink(cdnFilenames.contacts),
                external: true
              },
              {
                name: intl.formatMessage({ id: 'footer.linkFeedback' }),
                path: '#'
              }
            ]}
          ></SupportItem>
        </div>
        <Divider space={ESpaceSize.PIXEL_16} />
        <div className={s.SupportPage__footer}>
          <div className={s.SupportPage__socialMediaLabel}>
            <Heading size={HeadingSize.H6}>
              <FormattedMessage id={'footer.socialNetworks'} />
            </Heading>
          </div>
          <div className={s.SupportPage__socialMedia}>
            <a href="/src/pages" className={s.SupportPage__socialMediaLink}>
              <IconFacebook />
            </a>
            <a href="/src/pages" className={s.SupportPage__socialMediaLink}>
              <IconInstagram />
            </a>
            <a href="/src/pages" className={s.SupportPage__socialMediaLink}>
              <IconTwitter />
            </a>
            <a href="/src/pages" className={s.SupportPage__socialMediaLink}>
              <IconLinkedin />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
