import { DeliverySpeedDaysEnum } from '../../store/graphql';
import { ICartGroup } from './types';

export const getTotalPrice = (group: ICartGroup) => {
  return group.items
    .map((item) => item.product)
    .reduce((acc, product) => {
      if (product) {
        return acc + product.price;
      } else {
        return acc;
      }
    }, 0);
};

export const getDeliverySpeedDays = (group: ICartGroup) => {
  const deliverySpeedDaysCompareMap: Record<DeliverySpeedDaysEnum, number> = {
    [DeliverySpeedDaysEnum.OneOrTwo]: 1,
    [DeliverySpeedDaysEnum.ThreeOrFour]: 2,
    [DeliverySpeedDaysEnum.FromFiveToSeven]: 3
  };

  return (
    group.items
      .map((item) => item.product)
      .sort((a, b) => {
        if (a && b) {
          return deliverySpeedDaysCompareMap[b.deliverySpeedDays] - deliverySpeedDaysCompareMap[a.deliverySpeedDays];
        }
        return 0;
      })[0]?.deliverySpeedDays || DeliverySpeedDaysEnum.OneOrTwo
  );
};
