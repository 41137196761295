import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedDate } from '../../../../../../common/components/CustomFormatters';
import { RequestType } from '../../WalletTransactions/types';
import { formatMoney } from '../../../../../../common/utils/format';
import { requestBadgeVariantByStatus, requestStatusIntlId } from '../../WalletTransactions/constants';
import { Badge } from '../../../../../../common/components/Badge/Badge';
import s from './RequestCard.module.scss';

export interface RequestCardProps extends RequestType {}

export const RequestCard = ({ date, withdrawAmount, status }: RequestCardProps) => {
  return (
    <div className={s.RequestCard}>
      <div className={s.RequestCard__content}>
        <div className={s.RequestCard__info}>
          <FormattedDate value={new Date(date)} format={'yyyy.MM.dd'} />{' '}
          <FormattedDate value={new Date(date)} format={'HH:mm'} />
        </div>
        <h5 className={s.RequestCard__amount}>{formatMoney(withdrawAmount)}</h5>
      </div>
      <div className={s.RequestCard__status}>
        <Badge className={s.RequestRow__status} variant={requestBadgeVariantByStatus[status]}>
          <FormattedMessage id={requestStatusIntlId[status]} />
        </Badge>
      </div>
    </div>
  );
};
