import React, { ComponentProps, ComponentPropsWithoutRef, ElementType, JSX } from 'react';
import { PolyExtends } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { Loader, LoaderSize, LoaderVariant } from 'common/components/Loader';
import s from './IconButton.module.scss';

export enum IconButtonSize {
  SMALL_X = 'small_x',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

export enum IconButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary'
}

export const IconButtonDefaultComponent = 'button' as const;
export type IconButtonDefaultComponentType = typeof IconButtonDefaultComponent;

export interface IconButtonPropsSelf<ComponentType extends ElementType = IconButtonDefaultComponentType> {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Дополнительные css-классы элементов:
   * * content – контейнер содержимого
   * * icon – класс иконки
   */
  classes?: {
    content?: string;
    icon?: string;
  };
  /**
   * Вариант иконки
   */
  variant?: IconButtonVariant;
  /**
   * Размер кнопки (small_x (default), small, medium, large)
   */
  size?: IconButtonSize;
  /**
   * Компонент иконки
   */
  icon?: React.ElementType;
  /**
   * Цвет кнопки
   */
  color?: string;
  /**
   * Размер иконки в пикселях (default 12px)
   */
  iconSize?: number;
  /**
   * Цвет иконки
   */
  iconColor?: string;
  /**
   * Заблокированное состояние Button
   */
  disabled?: boolean;
  /**
   * Проп для контролируемого включения состояния ховера
   */
  hovered?: boolean;
  /**
   * Проп для контролируемого включения состояния нажатия
   */
  active?: boolean;
  /**
   * Состояние загрузки
   */
  loading?: boolean;
  /**
   * Вариант загрузки
   */
  loaderVariant?: LoaderVariant;
  /**
   * Реф на корневой элемент
   */
  innerRef?: ComponentProps<ComponentType>['ref'];
  /**
   * Слот для произвольного контента кнопки. Приоритетнее, чем icon.
   */
  children?: React.ReactNode;
}

export type IconButtonProps<ComponentType extends ElementType = IconButtonDefaultComponentType> = PolyExtends<
  ComponentType,
  IconButtonPropsSelf<ComponentType>,
  ComponentPropsWithoutRef<ComponentType>
>;

export function IconButton(props: IconButtonProps<'button'>): JSX.Element;
export function IconButton<ComponentType extends ElementType>(props: IconButtonProps<ComponentType>): JSX.Element;
export function IconButton<ComponentType extends ElementType = IconButtonDefaultComponentType>(
  props: IconButtonProps<ComponentType>
) {
  const {
    children,
    component,
    className,
    classes,
    variant = IconButtonVariant.TERTIARY,
    size = IconButtonSize.SMALL_X,
    color,
    icon,
    iconSize,
    iconColor,
    disabled,
    hovered,
    active,
    loading,
    innerRef,
    style,
    loaderVariant,
    ...restProps
  } = props;

  const Component = component || IconButtonDefaultComponent;
  const IconComponent = icon as ElementType;

  return (
    <Component
      ref={innerRef}
      className={clsx(s.IconButton, className, s[`IconButton_variant_${variant}`], s[`IconButton_size_${size}`], {
        [s.IconButton_hovered]: hovered,
        [s.IconButton_active]: active,
        [s.IconButton_disabled]: disabled
      })}
      type={'button'}
      disabled={disabled}
      style={{
        backgroundColor: color,
        ...style
      }}
      {...restProps}
    >
      <div className={clsx(s.IconButton__content, classes?.content)}>
        {children}

        {!children &&
          icon &&
          (loading ? (
            <Loader className={s.IconButton__loader} variant={loaderVariant} size={LoaderSize.SMALL} />
          ) : (
            <IconComponent
              className={clsx(s.IconButton__icon, classes?.icon)}
              size={iconSize}
              style={{
                width: iconSize,
                height: iconSize,
                color: (!disabled && iconColor) || 'currentColor'
              }}
            />
          ))}
      </div>
    </Component>
  );
}
