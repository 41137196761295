import React from 'react';

import clsx from 'clsx';
import { ReactFCC } from '../../../../../common/utils/helperTypes';
import { OrderPageBannerText } from './OrderPageBannerText';
import { OrderPageBannerActions } from './OrderPageBannerActions';
import s from './OrderPageBanner.module.scss';

export type TOrderPageBanner = {
  className?: string;
};

export type TOrderPageBannerType = ReactFCC<TOrderPageBanner> & {
  Text: typeof OrderPageBannerText;
  Actions: typeof OrderPageBannerActions;
};

export const OrderPageBanner: TOrderPageBannerType = (props) => {
  const { children, className } = props;

  return <div className={clsx(s.OrderPageBanner__container, className)}>{children}</div>;
};

OrderPageBanner.Text = OrderPageBannerText;

OrderPageBanner.Actions = OrderPageBannerActions;

export { OrderPageBannerText, OrderPageBannerActions };
