import { ReactFCC } from 'common/utils/helperTypes';
import { FormInput } from 'common/components/inputs';
import React from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { INPUT_DELAY } from '../../CatalogFilter';
import s from './CatalogFilterPrice.module.scss';

export interface CatalogFilterPriceProps {
  className?: string;
  minPrice: string;
  maxPrice: string;
  setMinPrice: React.Dispatch<string>;
  setMaxPrice: React.Dispatch<string>;
}

export const CatalogFilterPrice: ReactFCC<CatalogFilterPriceProps> = (props) => {
  const { className, minPrice, setMinPrice, maxPrice, setMaxPrice } = props;

  const intl = useIntl();

  return (
    <div className={clsx(s.CatalogFilterPrice, className)}>
      <FormInput
        className={s.CatalogFilterPrice__input}
        type="number"
        placeholder={intl.formatMessage({ id: 'catalog.priceFrom' })}
        value={minPrice}
        onChange={(e) => setMinPrice(e.target.value)}
        inputDelayMs={INPUT_DELAY}
      />

      <FormInput
        className={s.CatalogFilterPrice__input}
        type="number"
        placeholder={intl.formatMessage({ id: 'catalog.priceTo' })}
        value={maxPrice}
        onChange={(e) => setMaxPrice(e.target.value)}
        inputDelayMs={INPUT_DELAY}
      />
    </div>
  );
};
