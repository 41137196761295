import { ReactFCC } from 'common/utils/helperTypes';
import { ProductStatusEnum } from 'store/graphql';
import { useIntl } from 'react-intl';
import { BaseCatalogFilter, BaseCatalogFilterProps } from '../../CatalogFilter';
import { productStatusIntlNames } from '../../../../../common/constants/product';

export type CatalogFilterStatusProps = Pick<BaseCatalogFilterProps, 'className' | 'classes' | 'active' | 'setActive'>;

export const CatalogFilterStatus: ReactFCC<CatalogFilterStatusProps> = (props) => {
  const intl = useIntl();

  const itemStatusOptions = Object.values(ProductStatusEnum).map((type) => ({
    name: intl.formatMessage({ id: productStatusIntlNames[type] }),
    id: type as string
  }));

  return (
    <BaseCatalogFilter title={intl.formatMessage({ id: 'catalog.itemStatus' })} items={itemStatusOptions} {...props} />
  );
};
