import { ImgPropsWithoutRef, ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import s from './ImageWithFallback.module.scss';

export interface ImageWithFallbackProps extends ImgPropsWithoutRef {
  /**
   * Дополнительный css-класс корневого элемента
   */
  className?: string;
  /**
   * Дополнительные css-класс
   */
  classes?: {
    placeholder?: string;
  };
  /**
   * Ссылка на изображение
   */
  src?: string;
  /**
   * Ссылка на плейсхолдер изображения
   */
  fallback?: string;
  /**
   * Размер картинки
   */
  size?: number;
  /**
   * Ширина картинки (приоритетнее, чем размер)
   */
  width?: number;
  /**
   * Высота картинки (приоритетнее, чем размер)
   */
  height?: number;
  /**
   * Тип файла (по умолчанию image/png)
   */
  type?: string;
  /**
   * Не применять стандартные стили
   */
  noStyle?: boolean;
}

export const ImageWithFallback: ReactFCC<ImageWithFallbackProps> = (props) => {
  const {
    className,
    classes,
    src,
    fallback,
    size = 100,
    width = size,
    height = size,
    type = 'image/png',
    noStyle,
    style,
    alt,
    ...restProps
  } = props;

  const placeholderUrl = useMemo(
    () => fallback ?? `https://via.placeholder.com/${width}x${height}`,
    [fallback, height, width]
  );

  return (
    <object
      className={clsx(!noStyle && s.ImageWithFallback, className)}
      data={src || placeholderUrl}
      type={type}
      style={{
        width,
        height,
        ...style
      }}
    >
      <img
        className={clsx(!noStyle && s.ImageWithFallback__placeholder, classes?.placeholder)}
        src={placeholderUrl}
        alt={alt ?? ''}
        {...restProps}
      />
    </object>
  );
};
