import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CloseButton } from 'react-bootstrap';
import { EventTypeEnum, useNotificationsQuery } from '../../../../../store/graphql';
import { Alert, AlertVariant } from '../../../../../common/components/Alert';
import { useNotifications } from '../../../../../store/notifications/useNotifications';
import { Heading, HeadingSize } from '../../../../../common/components/Heading';
import { ESpaceSize, Space } from '../../../../../common/components/Space/Space';
import { Link } from '../../../../../common/components/Link';
import { TRUST_POINTS_GUIDE_ROUTE } from '../../../../../common/utils/routes';
import s from './TrustPointsNotification.module.scss';

export type TrustedSellerNotificationProps = {
  trustedSeller: boolean;
};

export const TrustPointsNotification = ({ trustedSeller }: TrustedSellerNotificationProps) => {
  const { markAsRead } = useNotifications();

  const { data: trustedStatusReceivedData, loading: trustedStatusReceivedLoading } = useNotificationsQuery({
    skip: !trustedSeller,
    variables: {
      input: {
        filter: {
          event: EventTypeEnum.TrustedSellerStatusReceived,
          read: false
        },
        pagination: {
          limit: 1,
          offset: 0
        }
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const { data: trustedStatusRemovedData, loading: trustedStatusRemovedLoading } = useNotificationsQuery({
    skip: trustedSeller,
    variables: {
      input: {
        filter: {
          event: EventTypeEnum.TrustedSellerStatusRemoved,
          read: false
        },
        pagination: {
          limit: 1,
          offset: 0
        }
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const trustedStatusReceived = trustedStatusReceivedData?.notifications?.entries[0];
  const trustedStatusRemoved = trustedStatusRemovedData?.notifications?.entries[0];

  const loading = trustedStatusReceivedLoading || trustedStatusRemovedLoading;

  if (loading) {
    return <></>;
  }

  return (
    <>
      {trustedStatusRemoved && (
        <>
          <Alert variant={AlertVariant.Danger} className={s.TrustPointsNotification__alert}>
            <div className={s.TrustPointsNotification__alertContent}>
              <div className={s.TrustPointsNotification__alertHead}>
                <Heading size={HeadingSize.H5}>
                  <FormattedMessage id={'profile.trustPointsDecreased'} />
                </Heading>
                <CloseButton onClick={() => markAsRead(trustedStatusRemoved?.id)} />
              </div>

              <Space size={ESpaceSize.PIXEL_24} />
              <FormattedMessage
                id={'profile.trustedSellerRemoved'}
                values={{
                  a: (chunks) => <Link to={TRUST_POINTS_GUIDE_ROUTE}>{chunks}</Link>,
                  br: () => <br />
                }}
              />
            </div>
          </Alert>
        </>
      )}
      {trustedStatusReceived && (
        <>
          <Alert variant={AlertVariant.Success} className={s.TrustPointsNotification__alert}>
            <div className={s.TrustPointsNotification__alertContent}>
              <div className={s.TrustPointsNotification__alertHead}>
                <Heading size={HeadingSize.H5}>
                  <FormattedMessage id={'profile.trustPointsFilled'} />
                </Heading>
                <CloseButton onClick={() => markAsRead(trustedStatusReceived?.id)} />
              </div>
              <Space size={ESpaceSize.PIXEL_24} />
              <FormattedMessage
                id={'profile.trustedSellerReceived'}
                values={{
                  a: (chunks) => <Link to={TRUST_POINTS_GUIDE_ROUTE}>{chunks}</Link>,
                  br: () => <br />
                }}
              />
            </div>
          </Alert>
        </>
      )}
    </>
  );
};
