import React from 'react';
import { useIntl } from 'react-intl';
import { ReactFCC } from '../../../../../../common/utils/helperTypes';
import { FormInput, FormInputSelect } from '../../../../../../common/components/inputs';
import { ESpaceSize } from '../../../../../../common/components/Space/Space';
import { AddressFormFieldsProps } from '../types';
import { AddressSchemaKeys } from '../../schema';

export const AddressFormFieldsZip: ReactFCC<AddressFormFieldsProps> = (props) => {
  const { formik, cityOptions } = props;

  const intl = useIntl();

  return (
    <>
      <FormInput
        name={AddressSchemaKeys.region}
        label={intl.formatMessage({ id: 'address.form.region' })}
        placeholder={intl.formatMessage({ id: 'address.form.region.placeholder' })}
        onChange={formik.handleChange}
        value={formik.values.region}
        error={formik.errors.region}
        space={ESpaceSize.PIXEL_12}
      />

      <FormInputSelect
        name={AddressSchemaKeys.city}
        label={intl.formatMessage({ id: 'address.form.city' })}
        placeholder={intl.formatMessage({ id: 'address.form.city.placeholder' })}
        options={cityOptions}
        onItemSelect={(option) => formik.setFieldValue(AddressSchemaKeys.city, option.value)}
        value={formik.values.city}
        onChange={(e) => {
          formik.setFieldValue(AddressSchemaKeys.city, e.target.value);
        }}
        error={formik.errors.city}
        space={ESpaceSize.PIXEL_12}
        showOptionsAnyway
      />
    </>
  );
};
