import { useNavigate } from 'react-router';
import { Product } from '../../../../store/graphql';
import { ORDERING_ROUTE } from '../../../../common/utils/routes';

export interface UseBuyNowProps {
  /**
   * ID товара из API
   */
  productId: Product['id'];
}

export const useBuyNow = ({ productId }: UseBuyNowProps) => {
  const navigate = useNavigate();
  const buyNow = async () => {
    try {
      navigate(ORDERING_ROUTE, {
        state: {
          productIds: [productId],
          buyNow: true
        }
      });
    } catch (e) {}
  };

  return {
    buyNow
  };
};
