import { FileUploadParams, FormDataFileKey } from './types';
import { UploadFn } from './types/uploadFn';

export type UploadFileFn = UploadFn<FileUploadParams>;

export const uploadFile: UploadFileFn = async (apiUrl, key, file, params) => {
  const query = new URLSearchParams();

  if (params) {
    if (typeof params.synchronously === 'boolean') query.set('synchronously', params.synchronously ? 'true' : 'false');
  }

  const formData = new FormData();
  formData.set(FormDataFileKey, file);

  const response = await fetch(`${apiUrl}/uploadFile/${key}?${query}`, {
    method: 'POST',
    body: formData
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
};
