import { ReactFCC } from 'common/utils/helperTypes';
import { ProductConditionEnum } from 'store/graphql';
import { useIntl } from 'react-intl';
import { productConditionIntlNames } from 'common/constants/product';
import { BaseCatalogFilter, BaseCatalogFilterProps } from '../../CatalogFilter';

export type CatalogFilterConditionProps = Pick<
  BaseCatalogFilterProps,
  'className' | 'classes' | 'active' | 'setActive'
>;

export const CatalogFilterCondition: ReactFCC<CatalogFilterConditionProps> = (props) => {
  const intl = useIntl();

  const conditionOptions = Object.values(ProductConditionEnum).map((type) => ({
    name: intl.formatMessage({ id: productConditionIntlNames[type] }),
    id: type as string
  }));

  return (
    <BaseCatalogFilter title={intl.formatMessage({ id: 'catalog.condition' })} items={conditionOptions} {...props} />
  );
};
