import { toast } from 'react-toastify';
import { extname } from '../../file-uploader';
import { FormDataFileKey, ImageUploadParams } from './types';
import { UploadFn } from './types/uploadFn';

export type UploadImageFn = UploadFn<ImageUploadParams>;

export const uploadImage: UploadImageFn = async (apiUrl, key, file, params) => {
  const query = new URLSearchParams();

  if (params) {
    if (params.q) query.set('q', params.q.toString());
    if (params.ext) query.set('ext', params.ext);
    if (params.w) query.set('w', params.w.toString());
    if (params.h) query.set('h', params.h.toString());
    if (typeof params.convert === 'boolean') query.set('convert', params.convert ? 'true' : 'false');
    if (typeof params.synchronously === 'boolean') query.set('synchronously', params.synchronously ? 'true' : 'false');
  }

  let processedImage: File;

  processedImage = file;

  const ext = extname(file.name);
  if (!ext || !['png', 'jpg', 'jpeg', 'webp', 'heic'].includes(ext)) {
    toast.error('Incorrect file type');
    return;
  }

  const formData = new FormData();
  formData.set(FormDataFileKey, processedImage);

  const response = await fetch(`${apiUrl}/uploadImage/${key}?${query}`, {
    method: 'POST',
    body: formData
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
};
