import { FileUploadParams, ImageUploadParams, UploadResponse, VideoUploadParams } from '../api';
import { FileAction } from '../types';
import { useMFClient } from './useMFClient';
import { useMutation, UseMutationOptions } from './internal/useMutation';

export interface MFUploadMutationBaseProps {
  key: string;
  file: File;
}

// todo можно перенести эту штуку на уровень класса-клиента
export type MFUploadFileMutationDynamicTypeProps =
  | {
      action: FileAction.UploadFile;
      params?: FileUploadParams;
    }
  | {
      action: FileAction.UploadImage;
      params?: ImageUploadParams;
    }
  | {
      action: FileAction.UploadVideo;
      params?: VideoUploadParams;
    };

export type MFUploadMutationProps = MFUploadMutationBaseProps & MFUploadFileMutationDynamicTypeProps;

export type UseMFUploadProps = Omit<UseMutationOptions<UploadResponse, [props: MFUploadMutationProps]>, 'fetcher'>;

export const useMFUpload = (props: UseMFUploadProps = {}) => {
  const client = useMFClient();

  return useMutation({
    fetcher: (props: MFUploadMutationProps) => {
      let func: (...args: any) => Promise<UploadResponse>;
      switch (props.action) {
        case FileAction.UploadFile:
          func = client.uploadFile;
          break;
        case FileAction.UploadImage:
          func = client.uploadImage;
          break;
        case FileAction.UploadVideo:
          func = client.uploadVideo;
          break;
        default:
          func = client.uploadFile;
      }

      return func.apply(client, [props.key, props.file, props.params]);
    },
    ...props
  });
};
