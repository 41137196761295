import React, { useMemo } from 'react';
import { find } from 'lodash-es';
import { useNavigate } from 'react-router';
import { useCartProductsQuery, useCartQuery } from '../../../../store/graphql';
import { CartBlock } from '../../CartBlock/CartBlock';
import { CartEmpty } from '../../CartEmpty/CartEmpty';
import { CartProduct, ICartGroup } from '../../types';
import { usePagination } from '../../../../common/components/Pagination';
import { Pagination } from '../../../../common/components/Pagination';
import { groupsPerPage } from '../../constants';
import { LoaderBox } from '../../../../common/components/Loader';
import { ICartContext } from '../../../../store/cart/CartContext';
import { useLocalStorageChange } from '../../../../common/hooks/useLocalStorageChange';
import { LOCAL_STORAGE_CART } from '../../../../store/storageKeys';
import { ORDERING_ROUTE } from '../../../../common/utils/routes';
import { useUser } from '../../../../entities/user';
import { useCurrency } from '../../../../store/currency';
import s from '../../CartPage.module.scss';

export interface CartContentAuthorizedProps
  extends Pick<ICartContext, 'removeItemFromCart' | 'removeItemFromCartLoading'> {}

export const CartContentAuthorized: React.FC<CartContentAuthorizedProps> = ({
  removeItemFromCart,
  removeItemFromCartLoading
}) => {
  const { offset, setOffset } = usePagination();

  const { user } = useUser();

  const shippingAddress = user?.addresses.find((address) => address.defaultShipping);

  const {
    data: cartQuery,
    loading: cartLoading,
    refetch: fetchCart
  } = useCartQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        offset,
        limit: groupsPerPage
      }
    }
  });
  const groups = cartQuery?.cart.groups;
  const pagination = cartQuery?.cart.pagination;

  // useUpdatePageCount(pagination?.pagesCount);

  useLocalStorageChange(LOCAL_STORAGE_CART, fetchCart);

  const productIds = groups?.flatMap((group) => group.items.map((item) => item.productId as string));

  const { data: productsQuery, loading: productsLoading } = useCartProductsQuery({
    skip: !productIds,
    variables: {
      productIds: productIds || [],
      limit: productIds?.length || 0
    }
  });

  const products = productsQuery?.products.entries;

  const groupsWithProducts: ICartGroup[] | undefined = useMemo(() => {
    if (groups && products) {
      return groups.map((group) => {
        const items = group.items.map((item) => ({
          productId: item.productId as string,
          product: find(products, { id: item.productId }) as CartProduct
        }));
        return {
          ...group,
          items
        };
      });
    }
  }, [products, groups]);

  const navigate = useNavigate();

  const onConfirm = async (group: ICartGroup) => {
    try {
      const productIds = group.items.map((item) => item.productId);

      navigate(ORDERING_ROUTE, {
        state: {
          productIds
        }
      });
    } catch (e) {}
  };

  const { currencyLoading } = useCurrency();

  const loading = cartLoading || productsLoading || currencyLoading;

  return (
    <>
      {loading ? (
        <LoaderBox />
      ) : (
        <>
          {!!groupsWithProducts?.length && (
            <>
              <div className={s.CartPage__blocks}>
                {groupsWithProducts.map((group, index) => (
                  <CartBlock
                    group={group}
                    onConfirm={onConfirm}
                    onRemove={removeItemFromCart}
                    removeLoading={removeItemFromCartLoading}
                    shippingAddress={shippingAddress}
                    key={index}
                  />
                ))}
              </div>

              {pagination && (
                <Pagination
                  limit={pagination.limit}
                  offset={offset}
                  setOffset={setOffset}
                  totalCount={pagination.totalCount}
                  showTotalCount={false}
                />
              )}
            </>
          )}

          {!groupsWithProducts?.length && !cartLoading && !productsLoading && <CartEmpty />}
        </>
      )}
    </>
  );
};
