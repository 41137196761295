import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { ReactFCC } from '../../../../../common/utils/helperTypes';
import { Anchor } from '../../../../../common/components/Anchor';
import { AddressCard } from '../../../components';
import { UserAddress } from '../../../types';
import s from './AddressSelectSlot.module.scss';

export enum AddressSelectSlotVariant {
  SHIPPING = 'shipping',
  BILLING = 'billing'
}

export interface AddressSelectSlotProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Дополнительные css-классы для дочерних элементов
   * * item - карточка адреса
   */
  classes?: {
    item?: string;
  };
  /**
   * Объектов адреса, полученный с api
   */
  address?: UserAddress;
  /**
   * Обработчик нажатия на кнопку изменения
   */
  onClickChange?: () => void;
  /**
   * Вариант компонента
   * * shipping
   * * billing
   */
  variant: AddressSelectSlotVariant;
  /**
   * Обработчик нажатие на кнопку редактирования адреса
   */
  onClickEdit?: () => void;
}

const slotTitleIntlId = {
  [AddressSelectSlotVariant.SHIPPING]: 'address.select.titleShipping',
  [AddressSelectSlotVariant.BILLING]: 'address.select.titleBilling'
};

const slotButtonIntlId = {
  [AddressSelectSlotVariant.SHIPPING]: 'address.select.buttonShipping',
  [AddressSelectSlotVariant.BILLING]: 'address.select.buttonBilling'
};

export const AddressSelectSlot: ReactFCC<AddressSelectSlotProps> = (props) => {
  const { className, classes, address, onClickChange, variant, onClickEdit } = props;

  return (
    <div className={clsx(s.AddressSelectSlot, className)}>
      <span className={s.AddressSelectSlot__title}>
        <FormattedMessage id={slotTitleIntlId[variant]} />
      </span>

      {address && (
        <AddressCard
          className={clsx(s.AddressSelectSlot__item, classes?.item)}
          address={address}
          onClickEdit={onClickEdit}
          selected
        />
      )}

      <Anchor className={s.AddressSelectSlot__button} component={'button'} onClick={onClickChange}>
        <FormattedMessage id={slotButtonIntlId[variant]} />
      </Anchor>
    </div>
  );
};
