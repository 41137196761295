import React from 'react';
import clsx from 'clsx';
import { BsEmojiFrown, BsEmojiNeutral, BsEmojiSmile } from 'react-icons/bs';
import s from './Rating.module.scss';

export interface RatingProps {
  className?: string;
  positive: number;
  neutral: number;
  negative: number;
}

export const Rating: React.FC<RatingProps> = ({ className, positive, neutral, negative }) => {
  return (
    <div className={clsx(s.Rating, className)}>
      <div className={clsx(s.Rating__item, s.Rating__item_positive)}>
        <BsEmojiSmile /> {positive}
      </div>
      <div className={clsx(s.Rating__item, s.Rating__item_neutral)}>
        <BsEmojiNeutral /> {neutral}
      </div>
      <div className={clsx(s.Rating__item, s.Rating__item_negative)}>
        <BsEmojiFrown /> {negative}
      </div>
    </div>
  );
};
