import 'focus-visible';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale-subtle.css';
import './app/styles/index.scss';

import './store/rnMessenger';

import ReactDOM from 'react-dom/client';
import React from 'react';
import { App, AppProvider } from './app';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <AppProvider>
    <App />
  </AppProvider>
  // </React.StrictMode>
);
