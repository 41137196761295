import { useCallback, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash-es';
import { Cashify } from 'cashify';
import { ReactFCC } from '../../common/utils/helperTypes';
import { LOCAL_STORAGE_CURRENCY } from '../storageKeys';
import { useLocalStorage } from '../../common/hooks/useLocalStorage';
import { CurrencyRate, useCurrenciesQuery } from '../graphql';
import { formatMoney } from '../../common/utils/format';
import { AvailableCurrencyEnum, CurrencyContext } from './CurrencyContext';
import { defaultRates } from './constants';

export const CurrencyProvider: ReactFCC = ({ children }) => {
  const [currency, setCurrency] = useLocalStorage<AvailableCurrencyEnum>(
    LOCAL_STORAGE_CURRENCY,
    AvailableCurrencyEnum.JPY,
    {
      raw: false,
      serializer: (value) => value,
      deserializer: (value) => value as AvailableCurrencyEnum
    }
  );
  const currencyRef = useRef(currency);

  const { data: currenciesQuery, loading: currenciesLoading } = useCurrenciesQuery({
    fetchPolicy: 'cache-and-network'
  });

  const ratesMapped = currenciesQuery?.result?.rates?.map((rate: CurrencyRate) => {
    return {
      currency: rate.currency,
      rate: rate.rate
    };
  });

  const rates =
    (ratesMapped &&
      ratesMapped.length > 0 &&
      ratesMapped.reduce((prev: { currency: string; rate: number }, current: { currency: string; rate: number }) => ({
        ...prev,
        [current.currency]: current.rate
      }))) ||
    {};

  const cashify = new Cashify({ base: 'JPY', rates });

  const convertCurrency = (amount: number) => {
    return formatMoney(cashify.convert(amount, { from: 'JPY', to: currency }), currency);
  };

  const changeUserCurrency = useCallback(
    async (value: AvailableCurrencyEnum) => {
      if (value === currency) {
        return;
      }

      setCurrency(value);
      currencyRef.current = value;
    },
    [currency, setCurrency]
  );

  useEffect(() => {
    if (currency && currency !== currencyRef.current) {
      changeUserCurrency(currency);
    }
  }, [changeUserCurrency, currency]);

  return (
    <CurrencyContext.Provider
      value={{
        currency: currency || AvailableCurrencyEnum.JPY,
        currencyLoading: currenciesLoading,
        rates: isEmpty(rates) ? defaultRates : rates,
        changeUserCurrency,
        convertCurrency
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};
