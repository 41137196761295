import { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { unstable_batchedUpdates } from 'react-dom';
import { ReactFCC } from '../../../common/utils/helperTypes';
import { IQueryParamContext, QueryParamBatchType, QueryParamContext } from './QueryParamContext';

/**
 * Провайдер для синхронного обновления query-параметров в рамках
 * одного тика приложения.
 * При использовании useSearchParams вызововы в одном рендере
 * будут перезаписывать друг друга.
 */
export const QueryParamProvider: ReactFCC = ({ children }) => {
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [batch, setBatch] = useState<QueryParamBatchType[]>([]);

  const setParamValue: IQueryParamContext['setParamValue'] = useCallback((key, value) => {
    setBatch((batch) => [...batch, { key, value }]);
  }, []);

  useEffect(() => {
    if (batch.length !== 0) {
      const params = getObjectFromURLParams(searchParams);

      for (const item of batch) {
        if (item.value === null) {
          delete params[item.key];
        } else {
          params[item.key] = item.value;
        }
      }

      unstable_batchedUpdates(() => {
        setBatch([]);
        setSearchParams(params, {
          replace: true,
          state
        });
      });
    }
  }, [batch, searchParams, setSearchParams, state]);

  return (
    <QueryParamContext.Provider
      value={{
        setParamValue,
        searchParams
      }}
    >
      {children}
    </QueryParamContext.Provider>
  );
};

const getObjectFromURLParams = (params: URLSearchParams) => {
  return Object.fromEntries(Array.from(params));
};
