import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { Modal, ModalBody, ModalButton, ModalFooter } from '../../../../../../common/components/Modal';
import { ButtonVariant } from '../../../../../../common/components/Button';
import s from './RemoveAllFavoritesModal.module.scss';

export interface RemoveAllFavoritesModalProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Модальное окно открыто
   */
  isOpen: boolean;
  /**
   * Обработчик закрытия модального окна
   */
  onClose: () => void;
  /**
   * Обработчик нажатия кнопки подтверждения
   */
  onClickConfirm?: () => void;
  /**
   * Состояния загрузки
   */
  loading?: boolean;
}

export const RemoveAllFavoritesModal: ReactFCC<RemoveAllFavoritesModalProps> = (props) => {
  const { className, isOpen, onClose, onClickConfirm, loading } = props;

  return (
    <Modal className={clsx(className)} title={'Remove all favorites'} isOpen={isOpen} onClose={onClose}>
      <ModalBody>
        <p>Are you sure you want to remove all favorite products from list?</p>
      </ModalBody>

      <ModalFooter className={s.RemoveAllFavoritesModal__footer}>
        <ModalButton variant={ButtonVariant.SECONDARY} onClick={onClose}>
          Back
        </ModalButton>

        <ModalButton onClick={onClickConfirm} loading={loading}>
          Remove
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};
