import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import s from './WalletNoItems.module.scss';

export interface WalletNoItemsProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
}

export const WalletNoItems: ReactFCC<WalletNoItemsProps> = (props) => {
  const { children, className } = props;

  return (
    <div className={clsx(s.WalletNoItems, className)}>
      <p className={s.WalletNoItems__text}>{children}</p>
    </div>
  );
};
