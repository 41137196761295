import React from 'react';

import clsx from 'clsx';
import { To } from 'react-router-dom';
import { ReactFCC } from '../../../../../common/utils/helperTypes';

import { Link } from '../../../../../common/components/Link/Link';
import s from './ProductCharacteristicsItem.module.scss';

export type TProductCharacteristicsItem = {
  className?: string;
  name: string;
  value?: string | null;
  to?: To;
};

export const ProductCharacteristicsItem: ReactFCC<TProductCharacteristicsItem> = (props) => {
  const { className, name, value, to } = props;

  return (
    <div className={clsx(s.ProductCharacteristicsItem, className)}>
      <span>{name}</span>

      <div className={s.ProductCharacteristicsItem__filler} />

      {to ? <Link to={to}>{value}</Link> : <span>{value}</span>}
    </div>
  );
};
