import React from 'react';

import clsx from 'clsx';

import { ReactFCC } from '../../utils/helperTypes';
import s from './Heading.module.scss';

export enum HeadingSize {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6'
}

export enum HeadingWeight {
  NORMAL = 'normal',
  WEIGHT_500 = '500'
}

export interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  className?: string;
  size?: HeadingSize;
  adaptive?: boolean;
  weight?: HeadingWeight;
}

export const Heading: ReactFCC<HeadingProps> = (props) => {
  const {
    children,
    className,
    size = HeadingSize.H1,
    weight = HeadingWeight.WEIGHT_500,
    adaptive = true,
    ...rest
  } = props;

  const Component = size as React.ElementType<React.HTMLAttributes<HTMLHeadingElement>>;

  return (
    <Component
      {...rest}
      className={clsx(s.Heading, className, s[`Heading_weight_${weight}`], {
        [s.Heading_adaptive]: adaptive && size !== HeadingSize.H6
      })}
    >
      {children}
    </Component>
  );
};
