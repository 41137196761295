import React from 'react';
import { useUi } from 'store/ui/useUi';
import { ICartContext } from 'store/cart/CartContext';
import { CartEmpty } from '../../CartEmpty/CartEmpty';
import { CartBlock } from '../../CartBlock/CartBlock';
import s from '../../CartPage.module.scss';

export interface CartContentLocalProps
  extends Pick<
    ICartContext,
    'localCartGroups' | 'cartProductsLoading' | 'removeItemFromCart' | 'removeItemFromCartLoading'
  > {}

export const CartContentLocal: React.FC<CartContentLocalProps> = ({
  localCartGroups,
  cartProductsLoading,
  removeItemFromCart,
  removeItemFromCartLoading
}) => {
  const { toggleAuthModal } = useUi();

  // todo убедиться, что в корзине вообще нужна пагинация и работает ли она сейчас правильно
  // const [page, { setPage, useUpdatePageCount }] = usePagination();
  // const { offset, setOffset } = usePagination();
  //
  // const pageCount = useMemo(() => {
  //   if (!localCartGroups) {
  //     return;
  //   }
  //   return Math.ceil(localCartGroups.length / groupsPerPage);
  // }, [localCartGroups]);

  // useUpdatePageCount(pageCount);

  // const groupsOnPage = useMemo(() => {
  //   if (!localCartGroups) {
  //     return [];
  //   }
  //
  //   return localCartGroups.slice((page - 1) * groupsPerPage, page * groupsPerPage);
  // }, [page, localCartGroups]);

  const onConfirm = async () => {
    toggleAuthModal(true);
  };

  return (
    <>
      {/*{!!groupsOnPage.length && (*/}
      {/*  <>*/}
      {/*    <div className={s.CartPage__blocks}>*/}
      {/*      {groupsOnPage.map((group, index) => (*/}
      {/*        <CartBlock*/}
      {/*          group={group}*/}
      {/*          onConfirm={onConfirm}*/}
      {/*          onRemove={removeItemFromCart}*/}
      {/*          removeLoading={removeItemFromCartLoading}*/}
      {/*          signInWarning*/}
      {/*          key={index}*/}
      {/*        />*/}
      {/*      ))}*/}
      {/*    </div>*/}

      {/*    <Pagination page={page} setPage={setPage} amount={pageCount || 0} totalCount={localCartGroups?.length || 0} />*/}
      {/*  </>*/}
      {/*)}*/}

      {localCartGroups && localCartGroups?.length !== 0 && (
        <div className={s.CartPage__blocks}>
          {localCartGroups.map((group, index) => (
            <CartBlock
              group={group}
              onConfirm={onConfirm}
              onRemove={removeItemFromCart}
              removeLoading={removeItemFromCartLoading}
              signInWarning
              key={index}
            />
          ))}
        </div>
      )}

      {!localCartGroups?.length && !cartProductsLoading && <CartEmpty />}
    </>
  );
};
