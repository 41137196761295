import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { ReactFCC } from '../../common/utils/helperTypes';
import { WebviewProvider } from '../../store/webview/WebviewProvider';
import { IntlProvider } from '../../store/intl';
import { NotificationsProvider } from '../../store/notifications/NotificationsProvider';
import { PaymentProvider } from '../../store/payment/PaymentProvider';
import { CartProvider } from '../../store/cart';
import { UiProvider } from '../../store/ui';
import { MsFilesClient, MsFilesProvider } from '../../lib/msfiles-client';
import { msFilesApiUrl } from '../../config';
import { CurrencyProvider } from '../../store/currency';
import { TicketsProvider } from '../../store/tickets/TicketsProvider';
import { AuthApolloProvider } from './auth-apollo';
import { QueryParamProvider } from './QueryParamProvider';

const msFilesClient = new MsFilesClient({
  apiUrl: msFilesApiUrl
});

export const AppProvider: ReactFCC = ({ children }) => (
  <BrowserRouter>
    <WebviewProvider>
      <AuthApolloProvider>
        <MsFilesProvider client={msFilesClient}>
          <QueryParamProvider>
            <IntlProvider>
              <CurrencyProvider>
                <NotificationsProvider>
                  <TicketsProvider>
                    <PaymentProvider>
                      <CartProvider>
                        <UiProvider>{children}</UiProvider>
                      </CartProvider>
                    </PaymentProvider>
                  </TicketsProvider>
                </NotificationsProvider>
              </CurrencyProvider>
            </IntlProvider>
          </QueryParamProvider>
        </MsFilesProvider>
      </AuthApolloProvider>
    </WebviewProvider>
  </BrowserRouter>
);
