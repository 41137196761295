import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useClickOutside } from '@proscom/ui-react';
import { isEnterKey } from 'common/utils/isKey';
import { Anchor } from '../../Anchor/Anchor';
import { FormInput, FormInputProps, FormInputClasses } from '../FormInput/FormInput';
import { InfiniteScroll, InfiniteScrollProps } from '../../InfiniteScroll';
import s from './FormInputSelect.module.scss';

export interface FormInputSelectClasses extends FormInputClasses {
  select?: string;
  option?: string;
}

export interface FormInputSelectOption {
  title: string;
  value?: string | number;
}

export interface FormInputSelectSelfProps extends Partial<Pick<InfiniteScrollProps, 'hasMore' | 'fetchMore'>> {
  className?: string;
  classes?: FormInputSelectClasses;
  defaultOption?: FormInputSelectOption;
  options: FormInputSelectOption[];
  loading?: boolean;
  onItemSelect?: (option: FormInputSelectOption) => void;
  showOptionsAnyway?: boolean;
}

export type FormInputSelectProps = FormInputProps & FormInputSelectSelfProps;

const FormInputSelectCmp: React.ForwardRefRenderFunction<HTMLInputElement, FormInputSelectProps> = (props, ref) => {
  const {
    className,
    classes,
    defaultOption,
    options,
    value: defaultValue,
    onChange,
    onItemSelect,
    hasMore,
    fetchMore,
    showOptionsAnyway,
    ...rest
  } = props;

  const [value, setValue] = useState(defaultValue || '');

  const [active, setActive] = useState(false);

  const dropdownRef = useRef<HTMLUListElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  const onItemSelectDefault = (option: FormInputSelectOption) => {
    setValue(option.title);
    onItemSelect?.(option);

    setTimeout(() => {
      setActive(false);
    });
  };

  useClickOutside(innerRef, () => {
    setActive(false);
  });

  useEffect(() => {
    if (typeof defaultValue === 'string') {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  /**
   * В зависимости от переданных параметров для пагинации рендерим обычный ul, либо пагинированный
   */
  const ListComponent = useMemo(() => (fetchMore ? InfiniteScroll : ('ul' as React.ElementType)), [fetchMore]);
  const listComponentProps = useMemo(
    () => (fetchMore ? { component: 'ul', innerRef: dropdownRef, hasMore, fetchMore } : { ref: dropdownRef }),
    [fetchMore, hasMore]
  );

  return (
    <FormInput
      {...rest}
      className={clsx(s.FormInputSelect, className)}
      classes={classes}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        setActive(!!e.target.value);
        onChange?.(e);
      }}
      onFocus={() => (value || showOptionsAnyway) && setActive(true)}
      ref={ref}
      innerRef={innerRef}
      contentAfter={
        <ListComponent
          className={clsx(
            'dropdown-menu',
            options.length !== 0 && active && 'show',
            s.FormInputSelect__dropdown,
            classes?.select
          )}
          {...listComponentProps}
        >
          {options.map((option, index) => (
            <li key={index}>
              <Anchor
                component="span"
                className={clsx(s.FormInputSelect__dropdownItem, 'dropdown-item', classes?.option)}
                onClick={() => onItemSelectDefault(option)}
                onKeyDownCapture={(e: React.KeyboardEvent) => {
                  if (isEnterKey(e)) {
                    onItemSelectDefault(option);
                  }
                }}
              >
                {option.title}
              </Anchor>
            </li>
          ))}
        </ListComponent>
      }
    />
  );
};

export const FormInputSelect = React.forwardRef(FormInputSelectCmp);
