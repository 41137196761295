import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import s from './WalletOperationsGrid.module.scss';

export interface WalletOperationsGridProps {
  className?: string;
  children?: React.ReactNode;
}

export const WalletOperationsGrid = ({ className, children }: WalletOperationsGridProps) => {
  return <div className={clsx(s.WalletOperationsGrid, className)}>{children}</div>;
};

export interface WalletOperationsRowProps {
  className?: string;
  head?: boolean;
  children?: React.ReactNode;
  onClick?: MouseEventHandler;
}

export const WalletOperationsRow = ({ className, head, children, onClick }: WalletOperationsRowProps) => {
  return (
    <div
      className={clsx(s.WalletOperationsRow, className, {
        [s.WalletOperationsRow_head]: head,
        [s.WalletOperationsRow_clickable]: !!onClick
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export interface WalletOperationsCellProps {
  className?: string;
  smallPadding?: boolean;
  toFlexCol?: boolean;
  children?: React.ReactNode;
}

export const WalletOperationsCell = ({ className, smallPadding, toFlexCol, children }: WalletOperationsCellProps) => {
  return (
    <div
      className={clsx(s.WalletOperationsCell, className, {
        [s.WalletOperationsCell_smallPadding]: smallPadding,
        [s.WalletOperationsCell_toFlexCol]: toFlexCol
      })}
    >
      {children}
    </div>
  );
};
