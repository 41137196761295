import React from 'react';
import { ReactFCC } from '../../../common/utils/helperTypes';
import { Head } from '../../../common/components/Head';
import { ShowcasePageContent } from './content/ShowcasePageContent';

export const ShowcasePage: ReactFCC = () => {
  return (
    <>
      <Head title={'My showcase'} />

      <ShowcasePageContent />
    </>
  );
};
