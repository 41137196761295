import { useLocalStorage } from 'react-use';
import { useEffect } from 'react';
import { LOCAL_STORAGE_COOKIE } from '../../store/storageKeys';
import { URL_KEY_COOKIE } from '../../store/urlKeys';
import { useBatchedQueryParam } from './queryParam/useBatchedQueryParam';

export function useCookies() {
  const [urlCookieAccepted] = useBatchedQueryParam<boolean>(URL_KEY_COOKIE, {
    defaultValue: false,
    getter: (value) => value === 'true'
  });
  const [lsCookieAccepted, setCookieAccepted] = useLocalStorage<boolean>(LOCAL_STORAGE_COOKIE, false);

  const cookieAccepted = urlCookieAccepted || lsCookieAccepted;

  useEffect(() => {
    setCookieAccepted(cookieAccepted);
  }, [cookieAccepted, setCookieAccepted]);

  return {
    cookieAccepted: Boolean(cookieAccepted),
    setCookieAccepted
  };
}
