import React, { useCallback } from 'react';
import { ReactFCC } from '../../common/utils/helperTypes';
import { rnMessenger } from '../rnMessenger';
import { WebviewContext } from './WebviewContext';

export const WebviewProvider: ReactFCC = ({ children }) => {
  const isWebview = rnMessenger.isActive;

  const callWebviewMethod = useCallback(
    async (methodName: string, ...args: any[]): Promise<any | null> => {
      if (isWebview && methodName) {
        try {
          return await rnMessenger.call(methodName, ...args);
        } catch (err) {
          console.error(err);
          return null;
        }
      }
    },
    [isWebview]
  );

  return (
    <WebviewContext.Provider
      value={{
        isWebview,
        callWebviewMethod
      }}
    >
      {children}
    </WebviewContext.Provider>
  );
};
