import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Button, ButtonVariant } from '../../../../../../../common/components/Button';
import {
  LocalFileType,
  OrderYamatoShipment,
  useCreateYamatoShipmentMutation
} from '../../../../../../../store/graphql';
import { getGraphqlErrorMessage } from '../../../../../../../common/utils/graphqlErrors';
import { handleDefaultError } from '../../../../../../../common/utils/handleDefaultError';
import { sellerOrderMutationOptions } from '../../../../SellerOrderPage';
import s from './OrderCombineYamatoBringIn.module.scss';

type YamatoBringInProps = {
  orderId: string;
  sellerAddressId: string;
  yamatoShipment?:
    | (Omit<OrderYamatoShipment, 'id' | 'qrCode'> & {
        qrCode?: {
          main_file?: Pick<LocalFileType, 'url'> | null;
        } | null;
      })
    | null;
};

export const yamatoLink = `https://locations.kuronekoyamato.co.jp/p/yamato01/`;

export const OrderCombineYamatoBringIn = (props: YamatoBringInProps) => {
  const { orderId, sellerAddressId, yamatoShipment } = props;
  const [createShipment, { loading: createShimpentLoading }] =
    useCreateYamatoShipmentMutation(sellerOrderMutationOptions);

  const handleNewShipment = async () => {
    try {
      await createShipment({
        variables: {
          input: {
            sellerAddressId,
            orderId,
            isPickup: false
          }
        }
      });
    } catch (e) {
      const errorMessage = getGraphqlErrorMessage(e);

      if (errorMessage) {
        handleDefaultError(errorMessage, e);
      }
    }
  };

  return (
    <div className={s.OrderCombineYamatoBringIn}>
      <div className={s.OrderCombineYamatoBringIn__manual}>
        {yamatoShipment ? (
          <>
            <FormattedMessage id={'orderCombine.yamatoSuccessBringIn'} />
          </>
        ) : (
          <FormattedMessage id={'orderCombine.yamatoGuide'} />
        )}
      </div>
      {!yamatoShipment && (
        <Button onClick={handleNewShipment} loading={createShimpentLoading} variant={ButtonVariant.PRIMARY}>
          Create shipment
        </Button>
      )}
    </div>
  );
};
