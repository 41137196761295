import React from 'react';
import { Spinner, SpinnerProps } from 'react-bootstrap';
import clsx from 'clsx';
import s from './Loader.module.scss';

export enum LoaderVariant {
  primary = 'primary',
  light = 'light'
}

export enum LoaderSize {
  DEFAULT = 'default',
  SMALL = 'small'
}

export interface LoaderProps extends Omit<SpinnerProps, 'animation' | 'variant' | 'size'> {
  variant?: LoaderVariant;
  /**
   * @deprecated
   */
  small?: boolean;
  /**
   * Размер лоадера (default, small)
   */
  size?: LoaderSize;
}

export const Loader: React.FC<LoaderProps> = ({
  className,
  variant = LoaderVariant.primary,
  small,
  size = LoaderSize.DEFAULT,
  ...props
}) => {
  return (
    <Spinner
      className={clsx(s.Loader, s[`Loader_${variant}`], className)}
      {...props}
      animation={'border'}
      variant={variant}
      size={small || size === LoaderSize.SMALL ? 'sm' : undefined}
    />
  );
};
