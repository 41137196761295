import React from 'react';
import clsx from 'clsx';
import { Product } from '../../../../store/graphql';
import { ReactFCC } from '../../../../common/utils/helperTypes';
import { ImageWithFallback } from '../../../../common/components/ImageWithFallback';
import { formatMoney } from '../../../../common/utils/format';
import s from './ProductComplaintCard.module.scss';

export type TProductComplaintType = Pick<Product, 'name' | 'price'> & {
  mainPhotoUrl: string | undefined;
};
export interface ProductComplaintCardProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Объект товара
   */
  product: TProductComplaintType;
}

const placeholderImageUrl = `https://via.placeholder.com/100x100`;

export const ProductComplaintCard: ReactFCC<ProductComplaintCardProps> = (props) => {
  const { className, product } = props;

  return (
    <div className={clsx(s.ProductComplaintCard__card, className)}>
      <ImageWithFallback src={product.mainPhotoUrl || placeholderImageUrl} size={100} />
      <div className={s.ProductComplaintCard__textContent}>
        <span>{product.name}</span>
        <span className={s.ProductComplaintCard__price}>{formatMoney(product.price)}</span>
      </div>
    </div>
  );
};
