import React from 'react';
import { AvailableLanguagesEnum } from '../graphql';
import { defaultLanguage } from './constants';

export type TLangContext = {
  language: AvailableLanguagesEnum;
  languageLoading?: boolean;
  changeUserLanguage: (language: AvailableLanguagesEnum) => void;
};

export const LangContext = React.createContext<TLangContext>({
  language: defaultLanguage,
  languageLoading: false,
  changeUserLanguage: () => {}
});
