import React from 'react';
import { LinkProps as LinkRouterProps, useHref, useLinkClickHandler } from 'react-router-dom';
import clsx from 'clsx';
import { ReactFCC } from 'common/utils/helperTypes';
import { Anchor, AnchorProps } from '../Anchor';

export type LinkProps = AnchorProps<'a'> & LinkRouterProps;

export const Link: ReactFCC<LinkProps> = (props) => {
  const { className, children, onClick, replace = false, state, target, to, relative, ...rest } = props;

  let href = useHref(to, { relative });
  let handleClick = useLinkClickHandler(to, {
    replace,
    state,
    target,
    relative
  });

  return (
    <Anchor
      {...rest}
      component={'a'}
      className={clsx(className)}
      href={href}
      onClick={(event) => {
        onClick?.(event);
        if (!event.defaultPrevented) {
          handleClick(event as React.MouseEvent<HTMLAnchorElement, MouseEvent>);
        }
      }}
    >
      {children}
    </Anchor>
  );
};
