import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import {
  ArisoraOrderStatus,
  useChangeOrderAcceptanceMutation,
  useCheckCustomerRateForOrderQuery,
  useCheckSellerRateForOrderQuery,
  useDebtsQuery,
  useSellerOrderContentQuery,
  useSellerOrderQuery
} from 'store/graphql';
import { Divider, EDividerVariant } from 'common/components/Divider/Divider';
import { ReactFCC } from 'common/utils/helperTypes';
import { LinkBack } from 'common/components/LinkBack/LinkBack';
import { EBreakpoints, useBreakpoint } from 'common/hooks/useBreakpoint';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { Heading, HeadingSize } from 'common/components/Heading/Heading';
import { Grid, GridGap } from 'common/components/Grid/Grid';
import { LoaderBox } from 'common/components/Loader/LoaderBox';
import { Button, ButtonFit, ButtonVariant } from 'common/components/Button';
import { useToggle } from 'common/hooks/useToggle';
import { Link } from 'common/components/Link/Link';
import { FormattedDate } from 'common/components/CustomFormatters';
import { getGraphqlErrorMessage } from 'common/utils/graphqlErrors';
import { handleDefaultError } from 'common/utils/handleDefaultError';
import { clearCache } from 'app/providers/auth-apollo';
import { EMDASH } from '@proscom/ui-utils';
import { getOrderTotalPrice, OrderStatusBadge } from '../../../entities/order';
import { ProductCard } from '../../../entities/product';
import { NOT_FOUND_ROUTE, ORDER_PAGE_PARAM, PathBuilder, WALLET_ROUTE } from '../../../common/utils/routes';
import { Anchor } from '../../../common/components/Anchor';
import { Head } from '../../../common/components/Head';
import { useUrlParam } from '../../../common/hooks/useUrlParam';
import { useUser } from '../../../entities/user';
import { useCurrency } from '../../../store/currency';
import { useDownloadInvoice } from './hooks/useDownloadInvoice';
import { OrderDebtAlert, OrderHistory, OrderPageBanner, OrderPageReviewModal } from './components/';
import { OrderProgress } from './components/OrderProgress';
import { OrderPageCombineYamato } from './components/OrderCombineYamato/OrderPageCombineYamato';
import { OrderPageCombineFedex } from './components/OrderCombineFedex/OrderPageCombineFedex';
import s from '../order/OrderPage.module.scss';

const mutationOptions = {
  refetchQueries: ['Order', 'CheckSellerRateForOrder'],
  update: clearCache(['getOrderById', 'checkSellerRateForOrder'])
};

export const sellerOrderMutationOptions = {
  refetchQueries: ['Order', 'SellerOrder'],
  update: clearCache(['order'])
};

export const SellerOrderPage: ReactFCC = () => {
  const navigate = useNavigate();

  const [reviewModalOpen, { set: openReviewModal, unset: closeReviewModal }] = useToggle(false);

  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);

  const entityId = useUrlParam(ORDER_PAGE_PARAM);

  const { user } = useUser();

  const {
    data: sellerOrderData,
    loading: sellerOrderLoading,
    refetch: refetchSellerOrder,
    error
  } = useSellerOrderQuery({
    skip: !entityId,
    variables: {
      input: {
        id: entityId || ''
      }
    }
  });

  const { data: sellerOrderContentData, loading: sellerOrderContentLoading } = useSellerOrderContentQuery({
    skip: !entityId,
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        id: entityId || ''
      }
    }
  });

  //seller content
  const sellerOrder = sellerOrderData?.order.seller?.order.order;
  const sellerOrderStatus = sellerOrder?.status;
  const parcels = sellerOrderData?.order.seller?.order.parcels;

  //customer content
  const order = sellerOrderData?.order.seller?.order.order;
  const contents = useMemo(
    () => sellerOrderContentData?.order.seller?.order?.content || [],
    [sellerOrderContentData?.order.seller?.order?.content]
  );
  const packaging = sellerOrderData?.order?.seller?.order.packaging;
  const packages = sellerOrderData?.order.seller?.order.packages;
  const pagination = sellerOrderContentData?.order?.seller?.order.pagination;
  const shippingFee = sellerOrderData?.order.seller?.order.order.shippingFee;

  const status = order?.status || sellerOrder?.status;
  const statusInternationalDelivery =
    status === ArisoraOrderStatus.InternationalDelivery ||
    sellerOrderStatus === ArisoraOrderStatus.InternationalDelivery;
  const statusDeliveryWithinJapan =
    status === ArisoraOrderStatus.DeliveringWithinJapan ||
    sellerOrderStatus === ArisoraOrderStatus.DeliveringWithinJapan;
  const statusIsDelivered =
    status === ArisoraOrderStatus.Delivered || sellerOrderStatus === ArisoraOrderStatus.Delivered;
  const statusCancelled = status === ArisoraOrderStatus.Canceled;

  const orderDeliveryCost = order?.deliveryCost || sellerOrder?.deliveryCost || 0;
  const isPaid = order?.isPaid || sellerOrder?.isPaid;
  const trustedOrder = order?.trustedOrder;

  const acceptRequired =
    order?.status !== ArisoraOrderStatus.Canceled && !sellerOrderData?.order.seller?.order.order.accepted;
  const defaultCombine = !statusIsDelivered && !statusInternationalDelivery && !acceptRequired && !statusCancelled;
  const trustedCombine = defaultCombine && trustedOrder;
  const yamatoShipment = order?.yamatoShipment ?? null;
  const isYuPacket = contents.every((product) => product.kuroneko);

  const intl = useIntl();

  const { download } = useDownloadInvoice(order?.id, order?.ID);

  useLayoutEffect(() => {
    if (!order || !user) {
      return;
    }

    if (order.sellerId !== user.sellerId) {
      navigate(NOT_FOUND_ROUTE, { replace: true });
      return;
    }
  }, [navigate, order, user]);

  const orderCost = getOrderTotalPrice(contents, orderDeliveryCost, false);
  const totalCost = getOrderTotalPrice(contents, orderDeliveryCost);
  const totalSize = contents.reduce((acc, item) => acc + item.weight, 0) || 0;
  const deliveryCost = (totalCost || 0) - (orderCost || 0);

  const commission = contents?.reduce((acc, item) => acc + item.commission, 0) || 0;

  const { data: debtsQueryData, loading: debtLoading } = useDebtsQuery({
    variables: {
      orderIds: [order?.id || '']
    },
    skip: !order?.id
  });
  const debt = debtsQueryData?.debts?.[0];

  useEffect(() => {
    if (error) {
      navigate(NOT_FOUND_ROUTE);
    }
  }, [error, navigate]);

  const { data: checkSellerRateData, loading: checkSellerRateLoading } = useCheckSellerRateForOrderQuery({
    variables: {
      orderId: order?.id || ''
    },
    skip: !order?.id
  });

  const { data: checkCustomerRateData, loading: checkCustomerRateLoading } = useCheckCustomerRateForOrderQuery({
    variables: {
      orderId: order?.id || ''
    },
    skip: !order?.id
  });

  const [changeOrderAcceptance, { loading: changeOrderAcceptanceLoading }] =
    useChangeOrderAcceptanceMutation(sellerOrderMutationOptions);

  const [acceptanceStatus, setAcceptanceStatus] = useState<boolean | null>(null);

  const handleOrderAccept = async (accepted: boolean) => {
    setAcceptanceStatus(accepted);
    if (!order?.id || !acceptRequired) {
      return;
    }

    try {
      await changeOrderAcceptance({
        variables: {
          input: {
            orderId: order?.id || '',
            accepted
          }
        }
      });

      toast.success('Order acceptance was changed');
    } catch (e) {
      const errorMessage = getGraphqlErrorMessage(e);

      if (errorMessage) {
        handleDefaultError(errorMessage, e);
      }
    }
    setAcceptanceStatus(null);
  };

  const canSellerRate = !!checkCustomerRateData?.result && statusIsDelivered;

  const { convertCurrency, currencyLoading } = useCurrency();

  const loading =
    sellerOrderLoading ||
    sellerOrderContentLoading ||
    debtLoading ||
    checkSellerRateLoading ||
    checkCustomerRateLoading ||
    currencyLoading;

  return (
    <>
      <Head title={`Order №${order?.ID} ${EMDASH} Orders ${EMDASH} Showcase`} />

      <div className={s.OrderPage}>
        <LinkBack>
          <FormattedMessage id="general.back" />
        </LinkBack>

        <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />

        {loading ? (
          <LoaderBox />
        ) : (
          <>
            {order && contents && (
              <div className={s.OrderPage__container}>
                <div className={s.OrderPage__title}>
                  <Heading size={HeadingSize.H3}>
                    <FormattedMessage id="general.order" /> {order.ID}
                  </Heading>

                  <OrderStatusBadge status={order.status} isProblem={order.isProblem} />
                </div>

                <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />

                <>
                  <OrderProgress status={order.status} isProblem={order.isProblem} />
                  <Divider
                    variant={EDividerVariant.LIGHT}
                    space={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24}
                  />

                  {acceptRequired && (
                    <>
                      <div className={s.OrderPage__acceptBlock}>
                        <div className={s.OrderPage__acceptBlock_content}>
                          <Heading size={HeadingSize.H5}>
                            <FormattedMessage id={'order.acceptTitle'} />
                          </Heading>
                          <span className={s.OrderPage__acceptBlock_text}>
                            <FormattedMessage id={'order.acceptText'} />
                          </span>
                          <div className={s.OrderPage__acceptBlock_buttons}>
                            <Button
                              variant={ButtonVariant.PRIMARY_OUTLINE}
                              fit={ButtonFit.FILL}
                              loading={changeOrderAcceptanceLoading && !acceptanceStatus}
                              disabled={changeOrderAcceptanceLoading}
                              onClick={() => handleOrderAccept(false)}
                            >
                              <FormattedMessage id={'general.cancel'} />
                            </Button>
                            <Button
                              variant={ButtonVariant.PRIMARY}
                              fit={ButtonFit.FILL}
                              loading={changeOrderAcceptanceLoading && !!acceptanceStatus}
                              disabled={changeOrderAcceptanceLoading}
                              onClick={() => handleOrderAccept(true)}
                            >
                              <FormattedMessage id={'general.accept'} />
                            </Button>
                          </div>
                        </div>
                      </div>
                      <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />
                    </>
                  )}

                  <div className={s.OrderPage__user}>
                    <FormattedMessage id="general.buyer" />{' '}
                    <Link to={PathBuilder.getCustomerPublicPath(order.customerId)}>{order.customerNickname}</Link>
                  </div>
                  <Space size={isMobile ? ESpaceSize.PIXEL_12 : ESpaceSize.PIXEL_24} />

                  {statusInternationalDelivery && (
                    <>
                      <OrderPageBanner>
                        <Heading size={HeadingSize.H5}>The order has passed custom clearance</Heading>

                        {isPaid === false && (
                          <OrderPageBanner.Text>Funds will be credited to your wallet soon.</OrderPageBanner.Text>
                        )}

                        <OrderPageBanner.Actions>
                          <Button variant={ButtonVariant.PRIMARY} onClick={() => navigate(WALLET_ROUTE)}>
                            Check the wallet
                          </Button>
                        </OrderPageBanner.Actions>
                      </OrderPageBanner>

                      <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />
                    </>
                  )}

                  {trustedCombine ? (
                    <>
                      <OrderPageCombineFedex
                        {...{
                          orderId: order.id,
                          orderID: order.ID,
                          orderStatus: status as ArisoraOrderStatus,
                          packaging,
                          packages,
                          dimensions: order.overallDimensions,
                          refetchOrder: refetchSellerOrder
                        }}
                      />
                      <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />
                    </>
                  ) : defaultCombine ? (
                    <>
                      <OrderPageCombineYamato
                        {...{
                          orderId: order.id,
                          orderID: order.ID,
                          orderStatus: status as ArisoraOrderStatus,
                          dimensions: order.overallDimensions,
                          yamatoShipment: yamatoShipment,
                          isYuPacket,
                          packages
                        }}
                      />
                      <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />
                    </>
                  ) : (
                    <></>
                  )}
                </>

                {canSellerRate && (
                  <>
                    <OrderPageBanner>
                      <Heading className={s.OrderPage__alertTitle} size={HeadingSize.H5}>
                        Review about the buyer
                      </Heading>

                      <OrderPageBanner.Text>
                        Your order has been received. Do not forget to leave a review and rate the buyer
                      </OrderPageBanner.Text>

                      <OrderPageBanner.Actions>
                        <Button variant={ButtonVariant.PRIMARY_OUTLINE} onClick={openReviewModal}>
                          Leave review
                        </Button>
                      </OrderPageBanner.Actions>
                    </OrderPageBanner>

                    <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />
                  </>
                )}

                {debt && (
                  <>
                    <OrderDebtAlert debt={debt} orderID={entityId} />

                    <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />
                  </>
                )}

                <div>
                  <Grid cols={12} gap={{ column: GridGap.MEDIUM, row: GridGap.SMALL }}>
                    <>
                      <>
                        <Grid.GridItem cols={{ xs: 1, lg: 2 }} className={s.OrderPage__option}>
                          <FormattedMessage id="profile.orderDate" />
                        </Grid.GridItem>

                        <Grid.GridItem cols={{ xs: 1, lg: 10 }}>
                          <FormattedDate value={new Date(order.orderDate)} />
                        </Grid.GridItem>
                      </>
                      <Grid.GridItem cols={{ xs: 1, lg: 2 }} className={s.OrderPage__option}>
                        <FormattedMessage id="profile.orderTotalCost" />
                      </Grid.GridItem>
                      <Grid.GridItem cols={{ xs: 1, lg: 10 }}>{convertCurrency(orderCost || 0)}</Grid.GridItem>
                      <Grid.GridItem cols={{ xs: 1, lg: 2 }} className={s.OrderPage__option}>
                        <FormattedMessage id={'profile.walletOrderCommission'} />
                      </Grid.GridItem>
                      <Grid.GridItem cols={{ xs: 1, lg: 10 }}>{convertCurrency(commission)}</Grid.GridItem>
                      {!!shippingFee && (
                        <>
                          <Grid.GridItem cols={{ xs: 1, lg: 2 }} className={s.OrderPage__option}>
                            <FormattedMessage id={'profile.walletOrderShippingFee'} />
                          </Grid.GridItem>
                          <Grid.GridItem cols={{ xs: 1, lg: 10 }}>{convertCurrency(shippingFee)}</Grid.GridItem>
                        </>
                      )}
                      <Grid.GridItem cols={{ xs: 1, lg: 2 }} className={s.OrderPage__option}>
                        <FormattedMessage id={'profile.createProductYouWillGot'} />
                      </Grid.GridItem>
                      <Grid.GridItem cols={{ xs: 1, lg: 10 }}>
                        {convertCurrency((orderCost || 0) - commission - (shippingFee || 0))}
                      </Grid.GridItem>
                      <>
                        <Grid.GridItem cols={{ xs: 1, lg: 2 }} className={s.OrderPage__option}>
                          <FormattedMessage id={'order.invoice'} />
                        </Grid.GridItem>

                        <Grid.GridItem cols={{ xs: 1, lg: 10 }}>
                          <Anchor onClick={download}>
                            <FormattedMessage id={'general.download'} />
                          </Anchor>
                        </Grid.GridItem>
                      </>
                    </>
                  </Grid>
                </div>

                <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />

                <>
                  <div className={s.OrderPage__badge}>
                    <div className={s.OrderPage__badge_block}>
                      <div>
                        <FormattedMessage id={'order.total'} />{' '}
                        <span className={s.OrderPage__badgeAmount}>
                          {contents?.length} <FormattedMessage id={'order.totalProducts'} />
                        </span>
                      </div>
                      <div>
                        <FormattedMessage id={'order.size'} />{' '}
                        <span className={s.OrderPage__badgeAmount}>{`${totalSize} kg`}</span>
                      </div>
                    </div>
                  </div>
                  <div className={s.OrderPage__products}>
                    {contents.map(
                      (product, index) =>
                        product && (
                          <div className={s.OrderPage__products_item} key={index}>
                            <React.Fragment>
                              <ProductCard
                                product={{
                                  ...product,
                                  id: product.productId
                                }}
                                key={index}
                              />
                            </React.Fragment>
                            {!isMobile ? (
                              index !== contents?.length - 2 &&
                              index !== contents.length - 1 && <Divider space={ESpaceSize.PIXEL_12} />
                            ) : (
                              <Divider space={ESpaceSize.PIXEL_12} />
                            )}
                          </div>
                        )
                    )}
                  </div>
                  {!isMobile && <Divider space={{ bottom: ESpaceSize.PIXEL_12 }} />}
                </>

                {entityId && (
                  <div className={s.OrderPage__history}>
                    <OrderHistory orderId={entityId} />
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>

      <OrderPageReviewModal
        orderId={order?.id || ''}
        title={`Review about the buyer`}
        isOpen={reviewModalOpen}
        onClose={closeReviewModal}
        isSeller={true}
      />
    </>
  );
};
