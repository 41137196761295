import React, { useMemo, useState } from 'react';
import { BsBox, BsInfoCircle } from 'react-icons/bs';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { getDeliveryDate } from 'common/utils/date';
import { Divider } from 'common/components/Divider/Divider';
import { Button, ButtonFit } from 'common/components/Button';
import { ExtractArray, ReactFCC } from 'common/utils/helperTypes';
import { ProductStatusEnum, useDeliveryMetadataQuery } from 'store/graphql';
import { useLang } from 'store/intl';
import { Link } from 'common/components/Link/Link';
import { Anchor } from 'common/components/Anchor/Anchor';
import { useUi } from 'store/ui/useUi';
import { Loader, LoaderSize } from 'common/components/Loader/Loader';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { EBreakpoints, useBreakpoint } from 'common/hooks/useBreakpoint';
import { ICartGroup } from '../types';
import { PathBuilder, PROFILE_ROUTE } from '../../../common/utils/routes';
import { ProductCard } from '../../../entities/product';
import { MoveToFavoritesButton } from '../../../features/product/favorite';
import { User } from '../../../entities/user';
import { useCurrency } from '../../../store/currency';
import { getTotalPrice } from '../utils';
import { FormCheckbox } from '../../../common/components/inputs';
import { AddressSchemaKeys } from '../../../widgets/address/components/AddressForm/schema';
import { ESpaceSize } from '../../../common/components/Space/Space';
import { useOrderDimensions } from '../../../features/order/orderDimensions/hooks/useOrderDimensions';
import { calculateOverallCartDimensions } from '../../../features/order/orderDimensions/utils/functions';
import s from './CartBlock.module.scss';

export type TCartBlock = {
  className?: string;
  group: ICartGroup;
  onConfirm?: (group: ICartGroup) => any;
  onRemove?: (id: string) => void;
  confirmOrderLoading?: boolean;
  removeLoading?: boolean;
  signInWarning?: boolean;
  shippingAddress?: ExtractArray<User['addresses']>;
};

export const CartBlock: ReactFCC<TCartBlock> = (props) => {
  const { className, group, onConfirm, onRemove, confirmOrderLoading, signInWarning, shippingAddress } = props;

  const isMultiple = group.items.length > 1;

  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);

  const isProductSold = group.items.some((item) => item.product?.status === ProductStatusEnum.Sold);

  const initialProducts = group.items.map((item) => item.productId);

  const [sizesExceeds, setSizesExceeds] = useState(false);

  const [selectedProductIds, setSelectedProductIds] = useState<string[]>(initialProducts);

  const { language } = useLang();

  const { toggleAuthModal } = useUi();

  const { checkOrderDimensions } = useOrderDimensions();

  const totalSizeAllowed = useMemo(() => {
    const { yamatoAllowed } = checkOrderDimensions(calculateOverallCartDimensions(group.items));
    return yamatoAllowed;
  }, [group.items, checkOrderDimensions]);

  const onClickSignIn = () => {
    toggleAuthModal();
  };

  const toggleAllProducts = () => {
    if (selectedProductIds.length > 0) {
      setSelectedProductIds([]);
    } else {
      setSelectedProductIds(group.items.map((item) => item.productId));
    }
  };

  const toggleProduct = (productId: string) => {
    if (selectedProductIds.includes(productId)) {
      setSelectedProductIds([...selectedProductIds.filter((p) => p !== productId)]);
    } else {
      const productIds = [...selectedProductIds, productId];
      const { fedexAllowed, yamatoAllowed } = checkOrderDimensions(
        calculateOverallCartDimensions(group.items.filter((item) => productIds.includes(item.productId)))
      );
      if ((group.sellerTrusted && fedexAllowed) || (!group.sellerTrusted && yamatoAllowed)) {
        setSelectedProductIds([...selectedProductIds, productId]);
      } else {
        setSizesExceeds(true);
      }
    }
  };

  const updatedGroup = useMemo(() => {
    const items = group.items.filter((i) => selectedProductIds.includes(i.productId));
    return { ...group, items };
  }, [group, selectedProductIds]);

  const { data: deliveryMetadataData, loading: deliveryMetadataLoading } = useDeliveryMetadataQuery({
    variables: {
      addressId: shippingAddress?.id || '',
      productIds: updatedGroup.items.map((item) => item.productId)
    },
    skip: !shippingAddress || shippingAddress.invalid
  });

  const { deliveryDaysMax, deliveryDaysMin, deliveryPrice } = deliveryMetadataData?.metadata || {};

  const { convertCurrency } = useCurrency();

  const totalProductsPrice = getTotalPrice(updatedGroup);
  const totalPrice = totalProductsPrice + (deliveryPrice || 0);

  const deliveryDate =
    deliveryDaysMin && deliveryDaysMax ? getDeliveryDate(deliveryDaysMin, deliveryDaysMax, language) : null;

  const allProductsChecked = useMemo(
    () => group.items.every((item) => selectedProductIds.includes(item.productId)),
    [selectedProductIds, group.items]
  );

  return (
    <div className={clsx(s.CartBlock, className)}>
      <div className={s.CartBlock__header}>
        {isMultiple && totalSizeAllowed && (
          <FormCheckbox
            className={s.CartBlock__checkbox}
            name={AddressSchemaKeys.defaultBilling}
            onChange={() => toggleAllProducts()}
            checked={allProductsChecked}
            space={{ bottom: ESpaceSize.PIXEL_6 }}
            disabled={false}
          />
        )}
        <span className={s.CartBlock__headerText}>
          <FormattedMessage id="general.seller" />{' '}
          <Link to={PathBuilder.getSellerPublicPath(group.sellerId)} className={s.CartBlock__headerLink}>
            {group.sellerNickname}
          </Link>
        </span>

        <div className={s.CartBlock__headerDate}>
          {!deliveryMetadataLoading ? (
            <>
              <BsBox className={s.CartBlock__headerDateIcon} />

              <span>
                {signInWarning ? (
                  <>
                    <FormattedMessage
                      id={'cart.date.signIn'}
                      values={{
                        a: (chunks) => (
                          <Anchor component={'span'} onClick={onClickSignIn}>
                            {chunks}
                          </Anchor>
                        )
                      }}
                    />
                  </>
                ) : !!shippingAddress?.invalid ? (
                  <FormattedMessage id="cart.date.invalid" />
                ) : deliveryDate ? (
                  <>
                    <FormattedMessage id="product.deliveryDate" />{' '}
                    <b className={s.CartBlock__mobileDeliveryText}>{deliveryDate}</b>
                  </>
                ) : (
                  <>
                    <FormattedMessage
                      id={'cart.date.noAddress'}
                      values={{
                        a: (chunks) => <Link to={PROFILE_ROUTE}>{chunks}</Link>
                      }}
                    />
                  </>
                )}
              </span>
            </>
          ) : (
            <Loader size={LoaderSize.SMALL} />
          )}
        </div>
      </div>

      <div className={s.CartBlock__body}>
        {group.items.map(
          ({ product }, index) =>
            product && (
              <>
                {index !== 0 && <Divider />}
                <div className={s.CartBlock__products} key={index}>
                  {isMultiple && (!sizesExceeds || selectedProductIds.includes(product.id)) && (
                    <FormCheckbox
                      name={AddressSchemaKeys.defaultBilling}
                      onChange={() => toggleProduct(product.id)}
                      checked={selectedProductIds.includes(product.id)}
                      space={{ bottom: ESpaceSize.PIXEL_6 }}
                      disabled={false}
                    />
                  )}
                  <ProductCard
                    className={s.CartBlock__products_product}
                    product={product}
                    onClickRemove={onRemove}
                    showSoldBadge
                    actionsSlot={<MoveToFavoritesButton productId={product.id} />}
                  />
                </div>
              </>
            )
        )}
      </div>

      <div className={s.CartBlock__footer}>
        <div className={s.CartBlock__delivery}>
          {!deliveryMetadataLoading ? (
            <>
              <div className={s.CartBlock__deliveryInfo}>
                {shippingAddress && !!deliveryPrice ? (
                  <>
                    <FormattedMessage id={'cart.price.calculated'} />{' '}
                    <span className={s.CartBlock__deliveryInfoAddress}>
                      {shippingAddress.address1}, {shippingAddress.city}, {shippingAddress.country}
                    </span>
                  </>
                ) : (
                  shippingAddress?.invalid && (
                    <FormattedMessage
                      id={'cart.price.invalid'}
                      values={{
                        a: (chunks) => <Link to={PROFILE_ROUTE}>{chunks}</Link>
                      }}
                    />
                  )
                )}
              </div>

              <div className={s.CartBlock__deliveryText}>
                {signInWarning ? (
                  <>
                    <FormattedMessage
                      id={'cart.price.signIn'}
                      values={{
                        a: (chunks) => (
                          <Anchor component={'span'} onClick={onClickSignIn}>
                            {chunks}
                          </Anchor>
                        )
                      }}
                    />
                  </>
                ) : (
                  !shippingAddress && (
                    <>
                      <span className={s.CartBlock__mobileAddAddress}>
                        <FormattedMessage
                          id={'cart.price.noAddress'}
                          values={{
                            a: (chunks) => <Link to={PROFILE_ROUTE}>{chunks}</Link>
                          }}
                        />
                      </span>
                    </>
                  )
                )}
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>

        {deliveryPrice && (
          <div className={s.CartBlock__footerTotal}>
            <span className={s.CartBlock__footerTotalValue}>{convertCurrency(deliveryPrice)}</span>
            <span className={s.CartBlock__footerTotalText}>
              <FormattedMessage id="profile.orderEstimatedShipping" />
              <Tooltip text={<FormattedMessage id="profile.orderEstimatedShippingHint" />}>
                <Tooltip.Container>
                  <BsInfoCircle />
                </Tooltip.Container>
              </Tooltip>
            </span>
          </div>
        )}

        <div className={s.CartBlock__footerTotal}>
          <span className={s.CartBlock__footerTotalValue}>{convertCurrency(totalPrice)}</span>
          <span className={s.CartBlock__footerTotalText}>
            <FormattedMessage id="cart.totalCost" />
          </span>
        </div>

        <Button
          className={s.CartBlock__button}
          loading={confirmOrderLoading}
          onClick={() => {
            if (!isProductSold || !selectedProductIds.length) {
              onConfirm?.(updatedGroup);
            }
          }}
          disabled={isProductSold || !selectedProductIds.length}
          fit={isMobile ? ButtonFit.FILL : ButtonFit.FIT}
        >
          <FormattedMessage id="cart.processOrder" />
        </Button>
      </div>
    </div>
  );
};
