import { useLocalStorage as useLocalStorageReactUse, usePrevious, useUpdateEffect } from 'react-use';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { tryParseJson } from '@proscom/ui-utils';

export function useLocalStorage<T>(
  key: string,
  initialValue?: T | undefined,
  options?: {
    raw: false;
    serializer: (value: T) => string;
    deserializer: (value: string) => T;
  }
): [T | undefined, Dispatch<SetStateAction<T | undefined>>, () => void] {
  const [lsData, setLsData, removeLsData] = useLocalStorageReactUse<T>(key, initialValue, options);

  const prevLsData = usePrevious(lsData);

  const handleLSChange = useCallback(
    (e: StorageEvent) => {
      if (e.key === key) {
        const itemStr = e.newValue;
        const item = (tryParseJson<T>(itemStr) || itemStr) as T;
        setLsData(item || undefined);
      }
    },
    [key, setLsData]
  );

  useUpdateEffect(() => {
    const prevStr = JSON.stringify(prevLsData);
    const newStr = JSON.stringify(lsData);

    if (prevStr === newStr) {
      return;
    }

    const event = new StorageEvent('storage', {
      key,
      oldValue: JSON.stringify(prevLsData),
      newValue: JSON.stringify(lsData)
    });

    window.dispatchEvent(event);
  }, [key, prevLsData, lsData]);

  useEffect(() => {
    window.addEventListener('storage', handleLSChange);

    return () => {
      window.removeEventListener('storage', handleLSChange);
    };
  }, [handleLSChange]);

  return [lsData, setLsData, removeLsData];
}
