import { FormattedMessage, useIntl } from 'react-intl';
import { Heading, HeadingSize } from '../../../common/components/Heading';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import s from './PrivacyPage.module.scss';

export const PrivacyPage = () => {
  const intl = useIntl();
  return (
    <>
      <div className={s.PrivacyPage__content}>
        <Heading size={HeadingSize.H4}>
          <FormattedMessage id={'static.privacy.title'} />
        </Heading>
        <Space size={ESpaceSize.PIXEL_24} />
        <span>
          <FormattedMessage id={'static.privacy.starting'} />
        </span>
        <div className={s.PrivacyPage__contentItem}>
          <Heading size={HeadingSize.H6} className={s.PrivacyPage__heading}>
            <FormattedMessage id={'static.privacy.nameBusiness'} />
          </Heading>
          <span>
            <FormattedMessage id={'static.privacy.arisora'} />
          </span>
        </div>
        <div className={s.PrivacyPage__contentItem}>
          <Heading size={HeadingSize.H6} className={s.PrivacyPage__heading}>
            <FormattedMessage id={'static.privacy.weObtain'} />
          </Heading>
          <span>
            <FormattedMessage id={'static.privacy.obtainText'} />
          </span>
          <ul>
            <li>
              <FormattedMessage id={'static.privacy.obtainLi1'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.obtainLi2'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.obtainLi3'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.obtainLi4'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.obtainLi5'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.obtainLi6'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.obtainLi7'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.obtainLi8'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.obtainLi9'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.obtainLi10'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.obtainLi11'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.obtainLi12'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.obtainLi13'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.obtainLi14'} />
            </li>
          </ul>
        </div>
        <div className={s.PrivacyPage__contentItem}>
          <Heading className={s.PrivacyPage__heading} size={HeadingSize.H6}>
            <FormattedMessage id={'static.privacy.methods'} />
          </Heading>
          <span>
            <FormattedMessage id={'static.privacy.methodsText'} />
          </span>
          <ul>
            <li>
              <FormattedMessage id={'static.privacy.methodsLi1'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.methodsLi2'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.methodsLi3'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.methodsLi4'} />
              <ul>
                <li>
                  <FormattedMessage id={'static.privacy.methodsLi4s1'} />
                </li>
                <li>
                  <FormattedMessage id={'static.privacy.methodsLi4s2'} />
                </li>
                <li>
                  <FormattedMessage id={'static.privacy.methodsLi4s3'} />
                </li>
                <li>
                  <FormattedMessage id={'static.privacy.methodsLi4s4'} />
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className={s.PrivacyPage__contentItem}>
          <Heading size={HeadingSize.H6} className={s.PrivacyPage__heading}>
            <FormattedMessage id={'static.privacy.purpose'} />
          </Heading>
          <span>
            <FormattedMessage id={'static.privacy.purposeText'} />
          </span>
          <ul>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi1'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi2'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi3'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi4'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi5'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi6'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi7'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi8'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi9'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi10'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi11'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi12'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi13'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi14'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi15'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi16'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi17'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi18'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi19'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi20'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi21'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.purposeLi22'} />
            </li>
          </ul>
        </div>
        <div className={s.PrivacyPage__contentItem}>
          <Heading size={HeadingSize.H6} className={s.PrivacyPage__heading}>
            <FormattedMessage id={'static.privacy.management'} />
          </Heading>
          <ul>
            <li>
              <FormattedMessage id={'static.privacy.managementLi1'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.managementLi2'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.managementLi3'} />
            </li>
          </ul>
        </div>
        <div className={s.PrivacyPage__contentItem}>
          <Heading size={HeadingSize.H6} className={s.PrivacyPage__heading}>
            <FormattedMessage id={'static.privacy.provision'} />
          </Heading>
          <ul>
            <li>
              <FormattedMessage id={'static.privacy.provisionLi1'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.provisionLi2'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.provisionLi3'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.provisionLi4'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.provisionLi5'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.provisionLi6'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.provisionLi7'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.provisionLi8'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.provisionLi9'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.provisionLi10'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.provisionLi11'} />
            </li>
          </ul>
        </div>
        <div className={s.PrivacyPage__contentItem}>
          <Heading size={HeadingSize.H6} className={s.PrivacyPage__heading}>
            <FormattedMessage id={'static.privacy.outsourcing'} />
          </Heading>
          <span>
            <FormattedMessage id={'static.privacy.outsourcingText'} />
          </span>
        </div>
        <div className={s.PrivacyPage__contentItem}>
          <Heading size={HeadingSize.H6} className={s.PrivacyPage__heading}>
            <FormattedMessage id={'static.privacy.correction'} />
          </Heading>
          <span>
            <FormattedMessage id={'static.privacy.correctionText'} />
          </span>
        </div>
        {intl.locale !== 'ja-JP' && (
          <div className={s.PrivacyPage__contentItem}>
            <Heading size={HeadingSize.H6} className={s.PrivacyPage__heading}>
              <FormattedMessage id={'static.privacy.minor'} />
            </Heading>
            <span>
              <FormattedMessage id={'static.privacy.minorText'} />
            </span>
          </div>
        )}

        <div className={s.PrivacyPage__contentItem}>
          <Heading size={HeadingSize.H6} className={s.PrivacyPage__heading}>
            <FormattedMessage id={'static.privacy.improvement'} />
          </Heading>
          <ul>
            <li>
              <FormattedMessage id={'static.privacy.improvementLi1'} />
            </li>
            <li>
              <FormattedMessage id={'static.privacy.improvementLi2'} />
            </li>
          </ul>
        </div>
        <div className={s.PrivacyPage__contentItem}>
          <Heading size={HeadingSize.H6} className={s.PrivacyPage__heading}>
            <FormattedMessage id={'static.privacy.disclosure'} />
          </Heading>
          <span>
            <FormattedMessage id={'static.privacy.disclosureText'} />
          </span>
        </div>
        <div className={s.PrivacyPage__contentItem}>
          <Heading size={HeadingSize.H6} className={s.PrivacyPage__heading}>
            <FormattedMessage id={'static.privacy.contacts'} />
          </Heading>
          <div className={s.PrivacyPage__textCols}>
            <span>
              <FormattedMessage id={'static.privacy.contactCompany'} />
            </span>
            <span>
              <FormattedMessage id={'static.privacy.contactZip'} />
            </span>
            <span>
              <FormattedMessage id={'static.privacy.contactAddress'} />
            </span>
            <span>
              <FormattedMessage id={'static.privacy.contactPhone'} />
            </span>
            <span>
              <FormattedMessage id={'static.privacy.contactEmail'} />
            </span>
            {intl.locale === 'ja-JP' && (
              <span>
                <FormattedMessage id={'static.privacy.toPersonInCharge'} />
              </span>
            )}
          </div>
        </div>
        <Space size={ESpaceSize.PIXEL_24} />
        <div className={s.PrivacyPage__footer}>
          <FormattedMessage id={'static.privacy.issueDate'} />
        </div>
      </div>
    </>
  );
};
