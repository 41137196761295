import React from 'react';
import clsx from 'clsx';
import s from './TrustPointsAmount.module.scss';

export interface TrustPointsAmountProps {
  className?: string;
  value: number;
  colorize?: boolean;
}

export const TrustPointsAmount = ({ className, value, colorize = true }: TrustPointsAmountProps) => {
  return (
    <span
      className={clsx(s.TransactionAmount, className, {
        [s.TrustPointsAmount_positive]: colorize && value && value > 0,
        [s.TrustPointsAmount_negative]: colorize && value && value < 0
      })}
    >
      {value <= 0 ? `${value}` : `+${value}`}
    </span>
  );
};
