import { SpecificAttributeInput, useCreateProductSpecificAttributesQuery } from '../../../../../store/graphql';
import { CatalogFiltersSpecificAttributesProps } from './CatalogFiltersSpecificAttributes';

export type UseCatalogFiltersSpecificAttributesProps = Pick<
  CatalogFiltersSpecificAttributesProps,
  'subSubCategoryId' | 'specificAttributeValues' | 'setSpecificAttributeValues'
>;

export const useCatalogFiltersSpecificAttributes = (props: UseCatalogFiltersSpecificAttributesProps) => {
  const { subSubCategoryId, specificAttributeValues, setSpecificAttributeValues } = props;

  const { data } = useCreateProductSpecificAttributesQuery({
    skip: !subSubCategoryId,
    variables: {
      subSubCategoryId: subSubCategoryId || ''
    }
  });

  const specificAttributes = data?.specificAttributes || [];

  const specificAttributesOptions = specificAttributes.map((attr) => ({
    id: attr.id,
    name: attr.name,
    variants: attr.variants.map((variant) => ({ id: variant.id, name: variant.value }))
  }));

  const toggleAttributeValue = (attributeId: string, value: string[]) => {
    const attribute = (specificAttributeValues.find((attr) => attr.attributeId === attributeId) || {
      attributeId,
      variantIds: []
    }) as SpecificAttributeInput;

    setSpecificAttributeValues((values) => {
      const valsArray = [...values.filter((attr) => attr.attributeId !== attributeId)];
      if (!!value?.length) {
        valsArray.push({
          attributeId: attribute.attributeId,
          variantIds: value
        });
      }
      return valsArray;
    });
  };

  return {
    specificAttributes,
    specificAttributesOptions,
    toggleAttributeValue
  };
};
