import clsx from 'clsx';
import { ReactFCC } from 'common/utils/helperTypes';
import React, { useCallback, useMemo } from 'react';
import { pull } from 'lodash-es';
import { FormCheckbox, FormLabel } from 'common/components/inputs';
import { Loader, LoaderSize } from 'common/components/Loader/Loader';
import { allOption } from '../constants';
import s from './BaseCatalogFilter.module.scss';

export interface CatalogFilterItem {
  /**
   * ID опции
   */
  id: string;
  /**
   * Название опции
   */
  name: string;
  /**
   * Цветовой код для отображения кружка с цветов
   */
  colorCode?: string;
}

export interface BaseCatalogFilterProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Дополнительный css-класс
   */
  classes?: {
    items?: string;
    item?: string;
  };
  /**
   * Название фильтра
   */
  title: string;
  /**
   * Список элементов
   */
  items: CatalogFilterItem[];
  /**
   * Массов ID выбранных опций
   */
  active: CatalogFilterItem['id'][];
  /**
   * Сеттер массова ID выбранных опций
   */
  setActive: React.Dispatch<CatalogFilterItem['id'][]>;
  /**
   * Состояние загрузки
   */
  isLoading?: boolean;
}

export const BaseCatalogFilter: ReactFCC<BaseCatalogFilterProps> = (props) => {
  const { className, classes, title, items, active, setActive, isLoading } = props;

  const allItemsChecked = useMemo(() => {
    return items.every((item) => active.includes(item.id));
  }, [items, active]);

  const onChange = useCallback(
    (item: CatalogFilterItem, value: boolean) => {
      if (value) {
        setActive([...active, item.id]);
      } else {
        setActive(pull(active.slice(), item.id));
      }
    },
    [active, setActive]
  );

  const onChangeAll = (value: boolean) => {
    setActive(value ? items.map((item) => item.id) : []);
  };

  return (
    <div className={clsx(s.CatalogFilter, className)}>
      <FormLabel>{title}</FormLabel>

      <div className={s.CatalogFilter__options}>
        <FormCheckbox
          className={clsx(s.CatalogFilter__option, classes?.item)}
          classes={{
            label: s.CatalogFilter__optionLabel
          }}
          label={allOption.name}
          checked={allItemsChecked}
          onChange={(e) => onChangeAll(e.target.checked)}
        />

        {items.map((item) => (
          <FormCheckbox
            className={clsx(s.CatalogFilter__option, classes?.item)}
            classes={{
              label: s.CatalogFilter__optionLabel
            }}
            label={
              <>
                {item.colorCode && (
                  <div
                    className={
                      item.colorCode === '#FFFFFF' ? s.CatalogFilter__optionColorWhite : s.CatalogFilter__optionColor
                    }
                    style={{ backgroundColor: item.colorCode }}
                  />
                )}
                <span className={s.CatalogFilter__optionLabelText}>{item.name}</span>
              </>
            }
            checked={active.includes(item.id)}
            onChange={(e) => onChange(item, e.target.checked)}
            key={item.id}
          />
        ))}
      </div>

      {isLoading && (
        <div className={s.CatalogFilter__helper}>
          Loading <Loader size={LoaderSize.SMALL} />
        </div>
      )}
    </div>
  );
};
