import { RouteObject } from 'react-router-dom';
import React from 'react';
import { NotFoundPage } from '../notFound/NotFoundPage';
import {
  ADDITIONAL_SERVICE_ROUTE,
  DELIVERY_PROBLEMS_ROUTE,
  HELP_ROUTE,
  PURCHASE_ISSUES_ROUTE,
  SELLER_IDENTITY_ROUTE,
  SUPPORT_HELP_ROUTE,
  WITHDRAW_HELP_ROUTE,
  withRoutes
} from '../../common/utils/routes';
import { HelpLayout } from './_layout/HelpLayout';

import { SupportHelpPage } from './SupportHelpPage';

const routes: RouteObject[] = [
  {
    element: <HelpLayout />,
    children: [
      {
        index: true,
        element: <SupportHelpPage />
      },
      {
        path: SUPPORT_HELP_ROUTE.replace(HELP_ROUTE, ''),
        element: <SupportHelpPage />
      },
      {
        path: ADDITIONAL_SERVICE_ROUTE.replace(HELP_ROUTE, ''),
        element: <SupportHelpPage />
      },
      {
        path: DELIVERY_PROBLEMS_ROUTE.replace(HELP_ROUTE, ''),
        element: <SupportHelpPage />
      },
      {
        path: PURCHASE_ISSUES_ROUTE.replace(HELP_ROUTE, ''),
        element: <SupportHelpPage />
      },
      {
        path: SELLER_IDENTITY_ROUTE.replace(HELP_ROUTE, ''),
        element: <SupportHelpPage />
      },
      {
        path: WITHDRAW_HELP_ROUTE.replace(HELP_ROUTE, ''),
        element: <SupportHelpPage />
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  }
];

export const HelpRoutes = withRoutes(routes);
