import React from 'react';

import clsx, { ClassValue } from 'clsx';

import { BsCheck, BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { ReactFCC } from '../../utils/helperTypes';
import { Anchor, AnchorVariant } from '../Anchor/Anchor';
import { HeadingSize, Heading } from '../Heading/Heading';
import { isKeyDownEnter } from '../../utils/isKeyDownEnter';
import s from './OpeningBlock.module.scss';

export enum EOpeningBlockStatus {
  ACTIVE = 'active',
  DONE = 'done',
  DISABLED = 'disabled'
}

export enum EOpeningBlockVariant {
  DEFAULT = 'default',
  LARGE = 'large'
}

export type TOpeningBlockClasses = {
  root?: ClassValue;
  header?: ClassValue;
  content?: ClassValue;
};

export type TOpeningBlock = {
  className?: string;
  classes?: TOpeningBlockClasses;
  number?: number;
  variant?: EOpeningBlockVariant;
  status: EOpeningBlockStatus;
  title: string;
  isOpen: boolean;
  setOpen?: (open: boolean) => void;
  control?: boolean;
};

export const OpeningBlock: ReactFCC<TOpeningBlock> = (props) => {
  const {
    children,
    className,
    classes,
    number,
    variant = EOpeningBlockVariant.DEFAULT,
    status,
    title,
    isOpen,
    setOpen,
    control = true
  } = props;

  const IconComponent = isOpen ? BsChevronUp : BsChevronDown;

  return (
    <div className={clsx(s.OpeningBlock, className, classes?.root, s[`OpeningBlock_variant_${variant}`])}>
      <div
        className={clsx(s.OpeningBlock__header, classes?.header, s[`OpeningBlock__header_variant_${variant}`], {
          [s.OpeningBlock__header_opened]: variant === EOpeningBlockVariant.DEFAULT && isOpen
        })}
      >
        {number && (
          <div className={clsx(s.OpeningBlock__iconContainer, s[`OpeningBlock__iconContainer_${status}`])}>
            {status !== EOpeningBlockStatus.DONE ? number : <BsCheck className={s.OpeningBlock__icon} />}
          </div>
        )}

        <Heading
          className={clsx(
            s.OpeningBlock__title,
            status === EOpeningBlockStatus.DISABLED && s.OpeningBlock__title_disabled
          )}
          size={variant === EOpeningBlockVariant.LARGE ? HeadingSize.H5 : HeadingSize.H6}
        >
          {title}
        </Heading>

        {control && (
          <Anchor
            component="div"
            className={clsx(
              s.OpeningBlock__arrow,
              status === EOpeningBlockStatus.DISABLED && s.OpeningBlock__arrow_disabled
            )}
            variant={AnchorVariant.DEFAULT}
            onClick={() => status !== EOpeningBlockStatus.DISABLED && setOpen?.(!isOpen)}
            onKeyDownCapture={(e: React.KeyboardEvent) => {
              if (isKeyDownEnter(e) && status !== EOpeningBlockStatus.DISABLED) {
                setOpen?.(!isOpen);
              }
            }}
          >
            <IconComponent />
          </Anchor>
        )}
      </div>

      {isOpen && (
        <div
          className={clsx(s.OpeningBlock__content, classes?.content, s[`OpeningBlock__content_variant_${variant}`], {
            [s.OpeningBlock__content_padding_left]: variant === EOpeningBlockVariant.DEFAULT && number
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};
