import React from 'react';
import { Key } from 'ts-key-enum';

export const isKey = (e: KeyboardEvent | React.KeyboardEvent, key: Key | string | (Key | string)[]) => {
  const keyArr = Array.isArray(key) ? key : [key];

  return keyArr.some((key) => e.key === key);
};

export const isEnterKey = (e: KeyboardEvent | React.KeyboardEvent) => isKey(e, Key.Enter);
