import React, { useMemo } from 'react';
import { Grid } from 'common/components/Grid/Grid';
import { Heading, HeadingSize } from 'common/components/Heading/Heading';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { TabItem, Tabs } from 'common/components/Tabs/Tabs';
import { URL_KEY_TAB } from 'store/urlKeys';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useBatchedQueryParam } from '../../../common/hooks/queryParam/useBatchedQueryParam';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { useUser } from '../../../entities/user';
import { LoaderBox } from '../../../common/components/Loader';
import { ReadyToSendOrders } from './ReadyToSendOrders/ReadyToSendOrders';
import { PickupRequests } from './PickupRequests/PickupRequests';
import s from './PickupRequestsPage.module.scss';

enum EPickupRequestsTabs {
  REQUESTS = 'requests',
  READY_TO_SEND = 'readyToSend'
}

const tabs: TabItem[] = [
  {
    name: EPickupRequestsTabs.REQUESTS,
    title: `My pick-up requests`,
    intlId: 'pickupRequest.myRequestsTitle'
  },
  {
    name: EPickupRequestsTabs.READY_TO_SEND,
    title: `Ready to send parcels`,
    intlId: 'pickupRequest.readyToSendTitle'
  }
];

const tabsContent: Record<EPickupRequestsTabs, React.ReactNode> = {
  [EPickupRequestsTabs.REQUESTS]: <PickupRequests />,
  [EPickupRequestsTabs.READY_TO_SEND]: <ReadyToSendOrders />
};

export const PickupRequestsPage = () => {
  const isMobile = useIsMobile();

  const { user } = useUser();

  const { state } = useLocation();

  const [activeTabName, setActiveTabName] = useBatchedQueryParam<EPickupRequestsTabs>(URL_KEY_TAB, {
    defaultValue: state?.tab ? state?.tab : EPickupRequestsTabs.REQUESTS
  });

  const activeTab = useMemo(() => tabs.find((tab) => tab.name === activeTabName) as TabItem, [activeTabName]);

  if (!user) {
    return <LoaderBox />;
  }

  return (
    <>
      <Grid className={s.PickupRequestsPage} cols={9}>
        <Grid.GridItem full>
          <Heading size={HeadingSize.H3}>
            <FormattedMessage id={'pickupRequest.title'} />
          </Heading>

          <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />
        </Grid.GridItem>
        <Grid.GridItem full>
          <Tabs
            items={tabs}
            activeItem={activeTab}
            onItemClick={(tab) => setActiveTabName(tab.name as EPickupRequestsTabs)}
          />
        </Grid.GridItem>
        <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />
        <Grid.GridItem full>{tabsContent[activeTab.name as EPickupRequestsTabs]}</Grid.GridItem>
      </Grid>
    </>
  );
};
