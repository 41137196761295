import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'common/components/Button/Button/Button';
import { BsPlusCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import { useTicketsQuery } from '../../../store/graphql';
import { Head } from '../../../common/components/Head';
import { Grid } from '../../../common/components/Grid';
import { Heading, HeadingSize } from '../../../common/components/Heading';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { CREATE_TICKET_ROUTE } from '../../../common/utils/routes';
import { Pagination, usePagination } from '../../../common/components/Pagination';
import { TicketsList } from './components/TicketsList/TicketsList';
import s from './TicketsPage.module.scss';

const DEFAULT_TICKET_LIMIT = 10;
const DEFAULT_POLLING_INTERVAL = 30000;

export const TicketsPage = () => {
  const { offset, setOffset } = usePagination();
  const { data: ticketsQuery, loading: ticketsLoading } = useTicketsQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: DEFAULT_POLLING_INTERVAL,
    variables: {
      input: {
        pagination: {
          offset,
          limit: DEFAULT_TICKET_LIMIT
        }
      }
    }
  });
  const intl = useIntl();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const tickets = ticketsQuery?.result.entries || [];
  const pagination = ticketsQuery?.result.pagination;

  return (
    <div className={s.TicketsPage}>
      <div className={s.TicketsPage__header}>
        <Head title={intl.formatMessage({ id: 'profile.tickets' })} />
        <Grid.GridItem full>
          <Heading size={HeadingSize.H3}>
            <FormattedMessage id={'profile.tickets'} />
          </Heading>
        </Grid.GridItem>
        <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />
        <Button onClick={() => navigate(CREATE_TICKET_ROUTE)} leftIcon={BsPlusCircle}>
          <FormattedMessage id={'profile.ticketsOpen'} />
        </Button>
        <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />
      </div>
      <TicketsList tickets={tickets} loading={ticketsLoading} />
      <Space size={ESpaceSize.PIXEL_16} />
      {pagination && (
        <Pagination limit={pagination.limit} offset={offset} setOffset={setOffset} totalCount={pagination.totalCount} />
      )}
    </div>
  );
};
