import React from 'react';
import { Carousel } from 'react-bootstrap';
import clsx from 'clsx';
import { ImageViewerProps } from '../types';
import s from './ImageViewerMobile.module.scss';

export const ImageViewerMobile: React.FC<ImageViewerProps> = ({ className, images }) => {
  return (
    <Carousel className={clsx(s.ImageViewerMobile, className)} variant="dark" controls={false}>
      {images.map((image, index) => (
        <Carousel.Item key={index}>
          <object className={clsx(s.ImageViewerMobile__image, 'd-block w-100')} data={image} type="image/png">
            <img
              className={clsx(s.ImageViewerMobile__image, 'd-block w-100')}
              src="https://via.placeholder.com/85"
              alt=""
            />
          </object>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
