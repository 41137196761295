import { RouteObject } from 'react-router-dom';
import React from 'react';
import {
  ABOUT_ROUTE,
  CART_ROUTE,
  CATALOG_ROUTE,
  CUSTOMER_PUBLIC_ROUTE,
  HELP_ROUTE,
  HOME_ROUTE,
  MANUALS_ROUTE,
  NOT_FOUND_ROUTE,
  OPEN_MOBILE_APP_ROUTE,
  PRODUCT_ROUTE,
  SELLER_PUBLIC_ROUTE,
  SUPPORT_HUB_ROUTE
} from '../../common/utils/routes';
import { AboutRoutes } from '../../pages/about';
import { ManualsRoutes } from '../../pages/manuals';
import { HelpRoutes } from '../../pages/help';
import { CatalogPage } from '../../pages/catalog';
import { NotFoundPage } from '../../pages/notFound/NotFoundPage';
import { ProductPage } from '../../pages/product/ProductPage';
import { CartPage } from '../../pages/cart/CartPage';
import { UserPage } from '../../pages/user/UserPage';
import { MobileAppPage } from '../../pages/mobileApp/MobileAppPage';
import { SupportHub } from '../../pages/support/SupportHub';
import { HomePage } from '../../pages/home';
export const publicRoutes: RouteObject[] = [
  {
    path: HOME_ROUTE,
    element: <HomePage />,
    index: true
  },
  {
    path: CATALOG_ROUTE,
    element: <CatalogPage />
  },
  {
    path: PRODUCT_ROUTE,
    element: <ProductPage />
  },
  {
    path: CART_ROUTE,
    element: <CartPage />
  },
  {
    path: SELLER_PUBLIC_ROUTE,
    element: <UserPage isSeller={true} />
  },
  {
    path: CUSTOMER_PUBLIC_ROUTE,
    element: <UserPage isSeller={false} />
  },
  {
    path: OPEN_MOBILE_APP_ROUTE,
    element: <MobileAppPage />
  },
  {
    path: NOT_FOUND_ROUTE,
    element: <NotFoundPage />
  },
  {
    path: `${ABOUT_ROUTE}/*`,
    element: <AboutRoutes />
  },
  {
    path: `${MANUALS_ROUTE}/*`,
    element: <ManualsRoutes />
  },
  {
    path: `${HELP_ROUTE}/*`,
    element: <HelpRoutes />
  },
  {
    path: SUPPORT_HUB_ROUTE,
    element: <SupportHub />
  },
  {
    path: '*',
    element: <NotFoundPage />
  }
];
