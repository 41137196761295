import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useMaskito } from '@maskito/react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { FormInput, InputLayout } from '../../../../../common/components/inputs';
import { Button, ButtonVariant } from '../../../../../common/components/Button';
import { DEFAULT_SMS_TIMEOUT_IN_SECONDS } from '../VerificationForm';
import {
  useConfirmVerificationCodeMutation,
  UserDocument,
  useSendTestCodeMutation,
  useSendVerificationCodeMutation
} from '../../../../../store/graphql';
import { isStaging } from '../../../../../config';
import { codeMask, defaultPhoneMask, japanesePhoneMask } from './mask';
import s from './PhoneVerification.module.scss';

export interface PhoneVerificationProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Пометить код как invalid, вывести ошибку, запретить отправку
   */
  invalid?: boolean;
  /**
   * Счетчик для запрета на запрос кода заново
   */
  resendTimeout?: number;
  /**
   * Обработчик нажатия на кнопку отправки кода для верификации
   */
  pullCode?: (code: string) => void;
  pullPhone?: (phone: string) => void;
  pullNumberVerified?: (isVerified: boolean) => void;
  isSeller?: boolean;
  initialData?: {
    phone: string;
    numberVerified: boolean;
  };
}

export const PhoneVerification: ReactFCC<PhoneVerificationProps> = (props) => {
  const { className, resendTimeout, pullPhone, pullCode, pullNumberVerified, initialData, isSeller } = props;

  const intl = useIntl();

  const japanesePhoneMaskRef = useMaskito({ options: japanesePhoneMask });
  const defaultPhoneMaskRef = useMaskito({ options: defaultPhoneMask });
  const codeMaskRef = useMaskito({ options: codeMask });

  const [isNumberVerified, setNumberVerified] = useState(initialData?.numberVerified || false);
  const [isCodeInvalid, setCodeInvalid] = useState(false);
  const [isCodeSent, setCodeSent] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [seconds, setSeconds] = useState(resendTimeout || DEFAULT_SMS_TIMEOUT_IN_SECONDS);
  const [startCountdown, setStartCountDown] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(initialData?.phone || '');
  const [testKey, setTestKey] = useState('');

  const [sendVerificationCode, { loading: sendCodeLoading }] = useSendVerificationCodeMutation();
  const [sendTestCode] = useSendTestCodeMutation();
  const [confirmVerificationCode, { loading: confirmLoading }] = useConfirmVerificationCodeMutation({
    refetchQueries: [UserDocument]
  });

  const verifyNumber = useCallback(async () => {
    try {
      if (phoneNumber.length) {
        setCodeInvalid(false);
        setPhoneError('');
        setStartCountDown(true);
        setSeconds(resendTimeout || DEFAULT_SMS_TIMEOUT_IN_SECONDS);

        if (isStaging) {
          const code = await sendTestCode({
            variables: {
              input: {
                phone: phoneNumber,
                countryCode: isSeller || intl.locale === 'ja-JP' ? 'JP' : undefined,
                key: testKey
              }
            }
          });
          console.log(code.data);
          setCodeSent(true);
          return;
        }

        const success = await sendVerificationCode({
          variables: {
            input: {
              phone: phoneNumber,
              countryCode: isSeller || intl.locale === 'ja-JP' ? 'JP' : undefined
            }
          }
        });
        if (!success) {
          setPhoneError(intl.formatMessage({ id: 'phoneVerification.codeNotSent' }));
        } else {
          setCodeSent(true);
        }
      }
    } catch (e: any) {
      setStartCountDown(false);
      switch (e.message) {
        case 'TOO_SHORT':
          setPhoneError(intl.formatMessage({ id: 'phoneVerification.tooShort' }));
          break;
        case 'NOT_VALID_NUMBER':
          setPhoneError(intl.formatMessage({ id: 'phoneVerification.notValid' }));
          break;
        case 'NOT_POSSIBLE_NUMBER':
          setPhoneError(intl.formatMessage({ id: 'phoneVerification.notPossible' }));
          break;
        case 'WRONG_TEST_KEY':
          toast.error('Wrong test key');
          break;
        default:
          setPhoneError(intl.formatMessage({ id: 'phoneVerification.checkAgain' }));
          break;
      }
    }
  }, [intl, phoneNumber, testKey, setPhoneError, isSeller, resendTimeout, sendVerificationCode, sendTestCode]);

  const syncPhone = (phone: string) => {
    setPhoneNumber(phone);
    pullPhone?.(phone);
  };

  const syncCode = (code: string) => {
    setVerificationCode(code);
    pullCode?.(code);
  };

  const syncNumberVerified = useCallback(
    (isVerified: boolean) => {
      setNumberVerified(isVerified);
      pullNumberVerified?.(isVerified);
    },
    [setNumberVerified, pullNumberVerified]
  );

  useEffect(() => {
    const checkCode = async () => {
      try {
        if (verificationCode.length === 6 && /^[0-9]{6}$/g.test(verificationCode)) {
          const success = await confirmVerificationCode({
            variables: {
              input: {
                phone: phoneNumber,
                countryCode: isSeller || intl.locale === 'ja-JP' ? 'JP' : undefined,
                sms_code: verificationCode
              }
            }
          });
          if (success) {
            syncNumberVerified(true);
          }
        }
      } catch (e: any) {
        setCodeInvalid(true);
      }
    };
    checkCode();
  }, [
    verificationCode,
    confirmVerificationCode,
    intl.locale,
    setNumberVerified,
    isSeller,
    phoneNumber,
    syncNumberVerified
  ]);

  useLayoutEffect(() => {
    if (startCountdown && isCodeSent) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          setStartCountDown(false);
          clearInterval(interval);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, isCodeSent, startCountdown]);

  return (
    <div className={clsx(s.PhoneVerification, className)}>
      <InputLayout className={s.PhoneVerification__input}>
        <FormInput
          className={s.PhoneVerification__input}
          label={isSeller ? intl.formatMessage({ id: 'profile.verificationNumber' }) : undefined}
          placeholder={intl.formatMessage({ id: 'phoneVerification.phonePlaceholder' })}
          disabled={isNumberVerified}
          value={phoneNumber}
          onChange={(e) => syncPhone(e.target.value)}
          error={phoneError}
          inputMode={'tel'}
          success={isNumberVerified ? intl.formatMessage({ id: 'phoneVerification.phoneVerified' }) : undefined}
          maxLength={15}
          ref={
            isSeller && !isNumberVerified
              ? japanesePhoneMaskRef
              : isNumberVerified
              ? undefined
              : intl.locale === 'ja-JP'
              ? japanesePhoneMaskRef
              : defaultPhoneMaskRef
          }
          required
        />

        {isStaging && (
          <FormInput
            className={s.PhoneVerification__input}
            placeholder={'Test key'}
            value={testKey}
            onChange={(e) => setTestKey(e.target.value)}
            required
          />
        )}

        {!isNumberVerified && isCodeSent && (
          <FormInput
            className={s.PhoneVerification__input}
            placeholder={intl.formatMessage({ id: 'phoneVerification.smsPlaceholder' })}
            value={verificationCode}
            onChange={(e) => syncCode(e.target.value)}
            help={isCodeSent && !isCodeInvalid ? intl.formatMessage({ id: 'phoneVerification.helpText' }) : undefined}
            error={isCodeInvalid ? intl.formatMessage({ id: 'phoneVerification.invalidCode' }) : undefined}
            ref={codeMaskRef}
            required
          />
        )}
        <div className={!isSeller ? s.PhoneVerification__controls : ''}>
          {!isNumberVerified && (
            <Button
              variant={isSeller ? ButtonVariant.PRIMARY_OUTLINE : ButtonVariant.PRIMARY}
              disabled={startCountdown}
              loading={sendCodeLoading || confirmLoading}
              onClick={verifyNumber}
            >
              {!isCodeSent
                ? intl.formatMessage({ id: 'phoneVerification.verifyButton' })
                : `${intl.formatMessage({ id: 'phoneVerification.sendAgainButton' })} ${
                    seconds && startCountdown ? '(' + seconds + ')' : ''
                  }`}
            </Button>
          )}
        </div>
      </InputLayout>
    </div>
  );
};
