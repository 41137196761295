import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { Product } from '../../../../../store/graphql';
import { useUi } from '../../../../../store/ui';
import { useToggleFavorite } from '../../hooks';
import { handleDefaultError } from '../../../../../common/utils/handleDefaultError';
import { Tooltip, TooltipContainer } from '../../../../../common/components/Tooltip';
import { useAuth } from '../../../../../app/providers/auth-apollo';
import s from './ToggleFavoriteButton.module.scss';

export interface ToggleFavoriteButtonProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * ID товара из API
   */
  productId: Product['id'];
  /**
   * Добавлен ли товар уже в избранное
   */
  active?: boolean;
}

export const ToggleFavoriteButton: ReactFCC<ToggleFavoriteButtonProps> = (props) => {
  const { className, productId, active: activeProp } = props;

  const { isAuthenticated } = useAuth();
  const { toggleAuthModal } = useUi();

  const { toggleFavorite } = useToggleFavorite({
    productId,
    onError: (e) => handleDefaultError('Something went wrong', e),
    onSettled: () => setProcessingStatus(null)
  });

  const [processingStatus, setProcessingStatus] = useState<boolean | null>(null);

  const onClick = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();

      if (!isAuthenticated) {
        toggleAuthModal(true);
        return;
      }

      setProcessingStatus(!activeProp);
      await toggleFavorite(!activeProp);
    },
    [activeProp, isAuthenticated, toggleAuthModal, toggleFavorite]
  );

  const active = processingStatus !== null ? processingStatus : activeProp;

  return (
    <button
      className={clsx(s.ToggleFavoriteButton, className, {
        [s.ToggleFavoriteButton_active]: active
      })}
      onClick={onClick}
    >
      <Tooltip text={active ? 'Remove from favorites' : 'Add to favorites'} delay={[500, 0]}>
        <TooltipContainer>
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="26" height="26" fill="white" fillOpacity="0.01" />
            <g clipPath="url(#clip0_6634_349484)">
              <mask id="path-1-inside-1_6634_349484" fill="white">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.9996 2.13533C20.2114 -5.27792 38.2424 7.69446 12.9996 24.3751C-12.2431 7.69608 5.7879 -5.27792 12.9996 2.13533Z"
                />
              </mask>
              <path
                className={s.ToggleFavoriteButton__inner}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9996 2.13533C20.2114 -5.27792 38.2424 7.69446 12.9996 24.3751C-12.2431 7.69608 5.7879 -5.27792 12.9996 2.13533Z"
              />
              <path
                className={s.ToggleFavoriteButton__border}
                d="M12.9996 2.13533L11.5661 3.52993L12.9996 5.00355L14.4332 3.52993L12.9996 2.13533ZM12.9996 24.3751L11.8971 26.0437L12.9997 26.7723L14.1023 26.0437L12.9996 24.3751ZM14.4332 3.52993C16.9694 0.922854 21.6824 1.79713 23.3899 5.13158C24.1775 6.66963 24.3871 8.94125 22.8792 11.9163C21.346 14.9411 18.0444 18.6442 11.897 22.7065L14.1023 26.0437C20.5763 21.7656 24.4868 17.592 26.447 13.7247C28.4324 9.80766 28.4166 6.1719 26.9503 3.30843C24.15 -2.16015 16.2416 -4.06544 11.5661 0.740737L14.4332 3.52993ZM14.1022 22.7064C7.95484 18.6446 4.65327 14.9416 3.12013 11.917C1.61222 8.94203 1.82179 6.67034 2.60941 5.13215C4.31698 1.79735 9.03002 0.923006 11.5661 3.52993L14.4332 0.740737C9.75752 -4.06559 1.84919 -2.15955 -0.950986 3.30908C-2.41724 6.17261 -2.43314 9.8084 -0.447712 13.7254C1.51249 17.5926 5.42308 21.7661 11.8971 26.0437L14.1022 22.7064Z"
                mask="url(#path-1-inside-1_6634_349484)"
              />
            </g>
            <defs>
              <clipPath id="clip0_6634_349484">
                <rect width="48" height="48" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </TooltipContainer>
      </Tooltip>
    </button>
  );
};
