import React, { JSX, useEffect } from 'react';
import { CloseButton, Container, Modal as ModalBootstrap } from 'react-bootstrap';
import clsx from 'clsx';
import { useUi } from 'store/ui';
import { ReactFCC } from 'common/utils/helperTypes';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { ModalBody, ModalButton, ModalFooter } from './components';
import s from './Modal.module.scss';

export interface ModalProps {
  className?: string;
  classes?: {
    mobileContainer: string;
  };
  isOpen?: boolean;
  onClose?: () => void;
  title?: string;
  body?: JSX.Element;
  buttons?: JSX.Element[];
  size?: 'sm' | 'lg' | 'xl';
  scrollPrevented?: boolean;
  hideHeader?: boolean;
  paddingBottomLarge?: boolean;
  disablePadding?: boolean;
}

export type ModalType = ReactFCC<ModalProps> & {
  Body: typeof ModalBody;
  Footer: typeof ModalFooter;
  Button: typeof ModalButton;
};

export const Modal: ModalType = (props) => {
  const {
    children,
    className,
    classes,
    isOpen,
    onClose,
    title,
    body,
    buttons,
    size,
    scrollPrevented,
    hideHeader,
    paddingBottomLarge = true
  } = props;

  const { preventBodyScroll } = useUi();

  const isMobile = useIsMobile();

  const preventScroll = !!(scrollPrevented !== undefined ? scrollPrevented : isOpen);
  useEffect(() => {
    if (isMobile) {
      preventBodyScroll(preventScroll);
    }

    return () => preventBodyScroll(false);
  }, [isMobile, preventBodyScroll, preventScroll]);

  return (
    <>
      {!isMobile ? (
        <ModalBootstrap className={clsx(s.Modal, className)} show={isOpen} onHide={onClose} centered={true} size={size}>
          {!hideHeader && (
            <ModalBootstrap.Header closeButton>
              <ModalBootstrap.Title>{title}</ModalBootstrap.Title>
            </ModalBootstrap.Header>
          )}

          {children}

          {body && <ModalBootstrap.Body>{body}</ModalBootstrap.Body>}

          {buttons && <ModalBootstrap.Footer>{buttons}</ModalBootstrap.Footer>}
        </ModalBootstrap>
      ) : (
        <div
          className={clsx(s.ModalMobile, className, {
            [s.ModalMobile_open]: isOpen,
            [s.ModalMobile_padding]: paddingBottomLarge
          })}
        >
          <Container className={clsx(s.ModalMobile__container, classes?.mobileContainer)}>
            {!hideHeader && (
              <ModalBootstrap.Header className={s.ModalMobile__header} closeButton={false}>
                <ModalBootstrap.Title>{title}</ModalBootstrap.Title>

                <CloseButton onClick={onClose} />
              </ModalBootstrap.Header>
            )}

            {children}

            {body && <div className={s.ModalMobile__body}>{body}</div>}

            {buttons && <div className={s.ModalMobile__footer}>{buttons}</div>}
          </Container>
        </div>
      )}
    </>
  );
};

Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
Modal.Button = ModalButton;

export { ModalBody, ModalFooter, ModalButton };
