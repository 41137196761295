import React, { ElementType } from 'react';
import clsx from 'clsx';
import { customIcons } from './icons';
import s from './CustomIcon.module.scss';

export interface CustomIconClasses {
  root?: string;
  icon?: string;
}

export interface CustomIconProps extends Omit<React.SVGProps<SVGElement>, 'ref'> {
  classes?: CustomIconClasses;
  icon: (typeof customIcons)[keyof typeof customIcons] | ElementType;
}

export const CustomIcon = ({ className, classes, icon, ...props }: CustomIconProps) => {
  const Icon = icon;

  return (
    <div className={clsx(s.CustomIcon, className, classes?.root)}>
      <Icon className={clsx(s.CustomIcon__icon, classes?.icon)} {...props} />
    </div>
  );
};
