import React from 'react';
import clsx from 'clsx';
import { To } from 'react-router-dom';

import { Link } from '../../../../../../common/components/Link';
import s from './TrustPointsItemLink.module.scss';

export interface TrustPointsItemLinkProps {
  className?: string;
  to: To;
  children?: React.ReactNode;
}

export const TrustPointsItemLink = ({ className, to, children }: TrustPointsItemLinkProps) => {
  return (
    <Link className={clsx(s.TrustPointsItemLink, className)} to={to}>
      {children}
    </Link>
  );
};
