import { Navigate, useRoutes, useSearchParams } from 'react-router-dom';
import { DefaultLayout } from '../../widgets/layout';
import { useAuth } from '../providers/auth-apollo';
import { useScrollTopOnRouteChange } from './hooks/useScrollTopOnRouteChange';
import { publicRoutes } from './public';
import { protectedRoutes } from './protected';

export const AppRoutes = () => {
  const [searchParams] = useSearchParams();
  const next = searchParams.get('next');

  const { isAuthenticated } = useAuth();

  useScrollTopOnRouteChange();

  const routes = [...protectedRoutes, ...publicRoutes];

  const element = useRoutes([
    {
      path: '/',
      element: <DefaultLayout />,
      children: routes
    }
  ]);

  if (isAuthenticated && next) return <Navigate to={next || '/'} replace />;

  return <>{element}</>;
};
