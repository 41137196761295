import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { TokenResponse } from './types';

const RequestTokenDocument = gql`
  mutation request_token($input: UserConfirmInput!) {
    response: confirmUserByCode(input: $input) {
      accessToken
      accessTokenExpiresAt
      refreshToken {
        token
        expires_at
      }
      user {
        id
      }
    }
  }
`;

export interface RequestTokenVariables {
  email: string;
  code: string;
}

export async function requestToken(client: ApolloClient<NormalizedCacheObject>, variables: RequestTokenVariables) {
  return await client.mutate<{ response: TokenResponse }>({
    mutation: RequestTokenDocument,
    variables: {
      input: variables
    }
  });
}
