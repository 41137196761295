import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { TokenResponse } from './types';

const RefreshTokenDocument = gql`
  mutation refresh_token($token: String!) {
    response: regenerateTokens(token: $token) {
      accessToken
      accessTokenExpiresAt
      refreshToken {
        token
        expires_at
      }
      user {
        id
      }
    }
  }
`;

export interface RefreshTokenVariables {
  token: string;
}

export async function refreshToken(client: ApolloClient<NormalizedCacheObject>, variables: RefreshTokenVariables) {
  return await client.mutate<{ response: TokenResponse }>({
    mutation: RefreshTokenDocument,
    variables
  });
}
