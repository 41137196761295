import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { BsBook } from 'react-icons/bs';
import { Grid } from '../../../common/components/Grid';
import { LinkBack } from '../../../common/components/LinkBack';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import {
  CALCULATOR_ROUTE,
  COMMISSION_ROUTE,
  DELIVERY_ROUTE,
  MANUALS_ROUTE,
  PAYMENT_ROUTE,
  PROHIBITED_ITEMS_ROUTE,
  REFUNDS_ROUTE,
  SIZE_LIMIT_ROUTE
} from '../../../common/utils/routes';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { HelpNavLink } from './HelpNavLink';
import s from './ManualsLayout.module.scss';

export const ManualsLayout: React.FC = () => {
  const location = useLocation();

  const isMobile = useIsMobile();

  return (
    <Grid
      className={location.pathname === MANUALS_ROUTE && isMobile ? s.ManualsLayout__mobile : s.ManualsLayout}
      cols={12}
    >
      <Grid.GridItem
        cols={{ xs: 2, lg: 3, xl: 2 }}
        className={location.pathname === MANUALS_ROUTE || !isMobile ? s.ManualsLayout__content : ''}
      >
        {location.pathname === MANUALS_ROUTE || !isMobile ? (
          <>
            <div className={s.ManualsLayout__back}>
              <LinkBack>
                <FormattedMessage id="general.back" />
              </LinkBack>
            </div>
            <div className={s.ManualsLayout__badge}>
              <BsBook size={16} />
              <span className={s.ManualsLayout__badge_text}>
                <FormattedMessage id={'footer.userManual'} />
              </span>
            </div>
            <HelpNavLink to={PAYMENT_ROUTE} end>
              <FormattedMessage id={'static.manuals.paymentsTitle'} />
            </HelpNavLink>

            <HelpNavLink to={COMMISSION_ROUTE} end>
              <FormattedMessage id={'static.manuals.commissionsTitle'} />
            </HelpNavLink>

            <HelpNavLink to={REFUNDS_ROUTE} end>
              <FormattedMessage id={'static.manuals.refundsTitle'} />
            </HelpNavLink>

            <HelpNavLink to={DELIVERY_ROUTE} end>
              <FormattedMessage id={'static.manuals.deliveryTitle'} />
            </HelpNavLink>

            <HelpNavLink to={CALCULATOR_ROUTE} end>
              <FormattedMessage id={'static.manuals.calculatorTitle'} />
            </HelpNavLink>

            <HelpNavLink to={PROHIBITED_ITEMS_ROUTE} end>
              <FormattedMessage id={'static.manuals.prohibitedTitle'} />
            </HelpNavLink>

            <HelpNavLink to={SIZE_LIMIT_ROUTE} end>
              <FormattedMessage id={'static.manuals.sizeLimitTitle'} />
            </HelpNavLink>
          </>
        ) : (
          <>
            <LinkBack>
              <FormattedMessage id="general.back" />
            </LinkBack>

            <Space size={ESpaceSize.PIXEL_24} />
          </>
        )}
      </Grid.GridItem>

      <Grid.GridItem cols={{ xs: 2, lg: 9 }} start={{ xs: 1, lg: 4 }}>
        <Outlet />
      </Grid.GridItem>
    </Grid>
  );
};
