import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { Heading, HeadingSize } from '../../../common/components/Heading';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import { Divider } from '../../../common/components/Divider/Divider';
import s from './TermsPage.module.scss';

export const TermsPage = () => {
  const { hash } = useLocation();

  const [expanded, setExpanded] = useState(false);
  const IconComponent = expanded ? BsChevronUp : BsChevronDown;
  const toggleExpand = () => {
    setExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    if (hash) {
      document.querySelector(hash)?.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [hash]);

  return (
    <>
      <div className={s.TermsPage__content}>
        <Heading size={HeadingSize.H4}>
          <FormattedMessage id={'static.terms.header'} />
        </Heading>
        <Space size={ESpaceSize.PIXEL_24} />
        <span>
          <FormattedMessage id={'static.terms.start'} />
        </span>
        <Space size={ESpaceSize.PIXEL_24} />
        <div className={s.TermsPage__tableOfContents}>
          <Heading size={HeadingSize.H6}>Table of contents</Heading>
          <Link to={`#service-contents`}>
            <FormattedMessage id={'static.terms.li1'} />
          </Link>
          <Link to={`#terms-change`}>
            <FormattedMessage id={'static.terms.li2'} />
          </Link>
          <Link to={`#user-registration`}>
            <FormattedMessage id={'static.terms.li3'} />
          </Link>
          <Link to={`#cancellation`}>
            <FormattedMessage id={'static.terms.li4'} />
          </Link>
          <Link to={`#withdrawal`}>
            <FormattedMessage id={'static.terms.li5'} />
          </Link>
          <Link to={`#personal-information`}>
            <FormattedMessage id={'static.terms.li6'} />
          </Link>
          <Link to={`#prohibited-matters`}>
            <FormattedMessage id={'static.terms.li7'} />
          </Link>
          <Link to={`#product-listing`}>
            <FormattedMessage id={'static.terms.li8'} />
          </Link>
          <Link to={`#product-purchase`}>
            <FormattedMessage id={'static.terms.li9'} />
          </Link>
          <Link to={`#product-price`}>
            <FormattedMessage id={'static.terms.li10'} />
          </Link>
          <Link to={`#transactions`}>
            <FormattedMessage id={'static.terms.li11'} />
          </Link>
          <Link to={`#sales-fee`}>
            <FormattedMessage id={'static.terms.li12'} />
          </Link>
          <Link to={`#wallet-balance`}>
            <FormattedMessage id={'static.terms.li13'} />
          </Link>
          <Link to={`#discount-coupon`}>
            <FormattedMessage id={'static.terms.li14'} />
          </Link>
          <Link to={`#listing-by-company`}>
            <FormattedMessage id={'static.terms.li15'} />
          </Link>
          <Link to={`#delivery-by-company`}>
            <FormattedMessage id={'static.terms.li16'} />
          </Link>
          <Link to={`#delivery-by-company`}>
            <FormattedMessage id={'static.terms.li17'} />
          </Link>
          <Link to={`#termination`}>
            <FormattedMessage id={'static.terms.li18'} />
          </Link>
          <Link to={`#property-rights`}>
            <FormattedMessage id={'static.terms.li19'} />
          </Link>
          <Link to={`#user-responsibilities`}>
            <FormattedMessage id={'static.terms.li20'} />
          </Link>
          <Link to={`#non-warranty`}>
            <FormattedMessage id={'static.terms.li21'} />
          </Link>
          <Link to={`#damage`}>
            <FormattedMessage id={'static.terms.li22'} />
          </Link>
          <Link to={`#general-terms`}>
            <FormattedMessage id={'static.terms.li23'} />
          </Link>
        </div>
        <Space size={ESpaceSize.PIXEL_24} />
        <div className={s.TermsPage__item} id={'service-contents'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li1'} />
          </Heading>
          <span className={s.TermsPage__itemText}>
            <FormattedMessage id={'static.terms.li1content'} />
          </span>
        </div>
        <div className={s.TermsPage__item} id={'terms-change'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li2'} />
          </Heading>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li2s1'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li2s1content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li2s2'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li2s2content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li2s3'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li2s3content'} />
          </span>
        </div>
        <div className={s.TermsPage__item} id={'user-registration'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li3'} />
          </Heading>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li3s1'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li3s1content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li3s2'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li3s2content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li3s3'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li3s3content'} />
          </span>
          <ol>
            <li>
              <FormattedMessage id={'static.terms.li3s3s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li3s3s2'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li3s3s3'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li3s3s4'} />
            </li>
            <li onClick={() => toggleExpand()}>
              <FormattedMessage id={'static.terms.li3s3s5'} /> <IconComponent />
            </li>
            {expanded && (
              <div className={s.TermsPage__pep}>
                <Divider space={ESpaceSize.PIXEL_24} />
                <span>
                  <FormattedMessage id={'static.terms.li3s3s5pep'} />
                </span>
                <Space size={ESpaceSize.PIXEL_12} />
                <Heading size={HeadingSize.H6}>
                  <FormattedMessage id={'static.terms.li3s3s5pep.heading'} />
                </Heading>
                <ol>
                  <li>
                    <FormattedMessage id={'static.terms.li3s3s5pep.li1'} />
                  </li>
                  <li>
                    <FormattedMessage id={'static.terms.li3s3s5pep.li2'} />
                  </li>
                  <ul>
                    <li>
                      <FormattedMessage id={'static.terms.li3s3s5pep.li2s1'} />
                    </li>
                    <li>
                      <FormattedMessage id={'static.terms.li3s3s5pep.li2s2'} />
                    </li>
                    <li>
                      <FormattedMessage id={'static.terms.li3s3s5pep.li2s3'} />
                    </li>
                    <li>
                      <FormattedMessage id={'static.terms.li3s3s5pep.li2s4'} />
                    </li>
                    <li>
                      <FormattedMessage id={'static.terms.li3s3s5pep.li2s5'} />
                    </li>
                    <li>
                      <FormattedMessage id={'static.terms.li3s3s5pep.li2s6'} />
                    </li>
                    <li>
                      <FormattedMessage id={'static.terms.li3s3s5pep.li2s7'} />
                    </li>
                  </ul>
                  <li>
                    <FormattedMessage id={'static.terms.li3s3s5pep.li3'} />
                  </li>
                  <li>
                    <FormattedMessage id={'static.terms.li3s3s5pep.li4'} />
                    <ul>
                      <li>
                        <FormattedMessage id={'static.terms.li3s3s5pep.li4s1'} />
                      </li>
                      <li>
                        <FormattedMessage id={'static.terms.li3s3s5pep.li4s2'} />
                      </li>
                      <li>
                        <FormattedMessage id={'static.terms.li3s3s5pep.li4s3'} />
                      </li>
                      <li>
                        <FormattedMessage id={'static.terms.li3s3s5pep.li4s4'} />
                      </li>
                      <li>
                        <FormattedMessage id={'static.terms.li3s3s5pep.li4s5'} />
                      </li>
                    </ul>
                  </li>
                  <li>
                    <FormattedMessage id={'static.terms.li3s3s5pep.li5'} />
                  </li>
                </ol>
                <div>
                  <Space size={ESpaceSize.PIXEL_12} />
                  <Heading size={HeadingSize.H6}>
                    <FormattedMessage id={'static.terms.li3s3s5pep.ending'} />
                  </Heading>
                  <ul>
                    <li>
                      <FormattedMessage id={'static.terms.li3s3s5pep.ending.s1'} />
                    </li>
                    <li>
                      <FormattedMessage id={'static.terms.li3s3s5pep.ending.s2'} />
                    </li>
                    <li>
                      <FormattedMessage id={'static.terms.li3s3s5pep.ending.s3'} />
                    </li>
                    <li>
                      <FormattedMessage id={'static.terms.li3s3s5pep.ending.s4'} />
                    </li>
                    <li>
                      <FormattedMessage id={'static.terms.li3s3s5pep.ending.s5'} />
                    </li>
                  </ul>
                </div>
                <Divider space={ESpaceSize.PIXEL_12} />
              </div>
            )}
            <li>
              <FormattedMessage id={'static.terms.li3s3s6'} />
            </li>
          </ol>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li3s4'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li3s4content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li3s5'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li3s5content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li3s6'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li3s6content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li3s7'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li3s7content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li3s8'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li3s8content'} />
          </span>
        </div>
        <div className={s.TermsPage__item} id={'cancellation'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li4'} />
          </Heading>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li4s1'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li4s1content'} />
          </span>
          <Space size={ESpaceSize.PIXEL_24} />
          <ol>
            <li>
              <FormattedMessage id={'static.terms.li4s1s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li4s1s2'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li4s1s3'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li4s1s4'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li4s1s5'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li4s1s6'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li4s1s7'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li4s1s8'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li4s1s9'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li4s1s10'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li4s1s11'} />
            </li>
          </ol>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li4s2'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li4s2content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li4s3'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li4s3content'} />
          </span>
        </div>
        <div className={s.TermsPage__item} id={'withdrawal'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li5'} />
          </Heading>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li5s1'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li5s1content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li5s2'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li5s2content'} />
          </span>
        </div>
        <div className={s.TermsPage__item} id={'personal-information'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li6'} />
          </Heading>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li6s1'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li6s1content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li6s2'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li6s2content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li6s3'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li6s3content'} />
          </span>
        </div>
        <div className={s.TermsPage__item} id={'prohibited-matters'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li7'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li7starting'} />
          </span>
          <ol className={s.TermsPage__itemOrderedList}>
            <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
              <FormattedMessage id={'static.terms.li7s1'} />
            </Heading>
            <li>
              <FormattedMessage id={'static.terms.li7s1s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s1s2'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s1s3'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s1s5'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s1s6'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s1s7'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s1s8'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s1s9'} />
            </li>
          </ol>
          <ol className={s.TermsPage__itemOrderedList}>
            <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
              <FormattedMessage id={'static.terms.li7s2'} />
            </Heading>
            <li>
              <FormattedMessage id={'static.terms.li7s2s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s2s2'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s2s3'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s2s4'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s2s5'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s2s6'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s2s7'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s2s8'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s2s9'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s2s10'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s2s11'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s2s12'} />
            </li>
          </ol>
          <ol className={s.TermsPage__itemOrderedList}>
            <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
              <FormattedMessage id={'static.terms.li7s3'} />
            </Heading>
            <li>
              <FormattedMessage id={'static.terms.li7s3s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s3s2'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s3s3'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s3s4'} />
            </li>
          </ol>
          <ol className={s.TermsPage__itemOrderedList}>
            <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
              <FormattedMessage id={'static.terms.li7s4'} />
            </Heading>
            <li>
              <FormattedMessage id={'static.terms.li7s4s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s4s2'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s4s3'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s4s4'} />
            </li>
          </ol>
          <ol className={s.TermsPage__itemOrderedList}>
            <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
              <FormattedMessage id={'static.terms.li7s5'} />
            </Heading>
            <li>
              <FormattedMessage id={'static.terms.li7s5s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s2'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s3'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s4'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s5'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s6'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s7'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s8'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s9'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s10'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s11'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s12'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s13'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s14'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s15'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s16'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s17'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s18'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s19'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s20'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s21'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s22'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li7s5s23'} />
            </li>
          </ol>
        </div>
        <div className={s.TermsPage__item} id={'product-listing'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li8'} />
          </Heading>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li8s1'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li8s1content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li8s2'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li8s2content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li8s3'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li8s3content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li8s4'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li8s4content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li8s5'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li8s5content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li8s6'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li8s6content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li8s7'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li8s7content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li8s8'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li8s8content'} />
          </span>
        </div>
        <div className={s.TermsPage__item} id={'product-purchase'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li9'} />
          </Heading>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li9s1'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li9s1content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li9s2'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li9s2content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li9s3'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li9s3content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li9s4'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li9s4content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li9s5'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li9s5content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li9s6'} />
          </Heading>
          <ol className={s.TermsPage__itemOrderedList}>
            <li>
              <FormattedMessage id={'static.terms.li9s6s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li9s6s2'} />
            </li>
          </ol>
        </div>
        <div className={s.TermsPage__item} id={'product-price'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li10'} />
          </Heading>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li10s1'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li10s1content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li10s2'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li10s2content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li10s3'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li10s3content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li10s4'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li10s4content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li10s5'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li10s5content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li10s6'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li10s6content'} />
          </span>
          <ol className={s.TermsPage__itemOrderedList}>
            <li>
              <FormattedMessage id={'static.terms.li10s6s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li10s6s2'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li10s6s3'} />
            </li>
          </ol>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li10s7'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li10s7content'} />
          </span>
          <ol className={s.TermsPage__itemOrderedList}>
            <li>
              <FormattedMessage id={'static.terms.li10s7s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li10s7s2'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li10s7s3'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li10s7s4'} />
            </li>
          </ol>
        </div>
        <div className={s.TermsPage__item} id={'transactions'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li11'} />
          </Heading>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li11s1'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li11s1content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li11s2'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li11s2content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li11s3'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li11s3content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li11s4'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li11s4content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li11s5'} />
          </Heading>
          <ol className={s.TermsPage__itemOrderedList}>
            <li>
              <FormattedMessage id={'static.terms.li11s5s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li11s5s2'} />
            </li>
          </ol>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li11s6'} />
          </Heading>
          <ol className={s.TermsPage__itemOrderedList}>
            <li>
              <FormattedMessage id={'static.terms.li11s6s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li11s6s2'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li11s6s3'} />
            </li>
          </ol>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li11s7'} />
          </Heading>
          <ol className={s.TermsPage__itemOrderedList}>
            <li>
              <FormattedMessage id={'static.terms.li11s7s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li11s7s2'} />
            </li>
          </ol>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li11s8'} />
          </Heading>
          <ol className={s.TermsPage__itemOrderedList}>
            <li>
              <FormattedMessage id={'static.terms.li11s8s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li11s8s2'} />
            </li>
          </ol>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li11s9'} />
          </Heading>
          <ol className={s.TermsPage__itemOrderedList}>
            <li>
              <FormattedMessage id={'static.terms.li11s9s1'} />
              <ol type={'a'}>
                <li>
                  <FormattedMessage id={'static.terms.li11s9s1a'} />
                </li>
                <li>
                  <FormattedMessage id={'static.terms.li11s9s1b'} />
                </li>
              </ol>
            </li>
            <li>
              <FormattedMessage id={'static.terms.li11s9s2'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li11s9s3'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li11s9s4'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li11s9s5'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li11s9s6'} />
              <ol type={'a'}>
                <li>
                  <FormattedMessage id={'static.terms.li11s9s6a'} />
                </li>
                <li>
                  <FormattedMessage id={'static.terms.li11s9s6b'} />
                </li>
                <li>
                  <FormattedMessage id={'static.terms.li11s9s6c'} />
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div className={s.TermsPage__item} id={'sales-fee'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li12'} />
          </Heading>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li12s1'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li12s1content'} />
          </span>
        </div>
        <div className={s.TermsPage__item} id={'wallet-balance'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li13'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li13content'} />
          </span>
        </div>
        <div className={s.TermsPage__item} id={'discount-coupon'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li14'} />
          </Heading>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li14s1'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li14s1content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li14s2'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li14s2content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li14s3'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li14s3content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li14s4'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li14s4content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li14s5'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li14s5content'} />
          </span>
        </div>
        <div className={s.TermsPage__item} id={'listing-by-company'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li15'} />
          </Heading>
          <ol>
            <li>
              <FormattedMessage id={'static.terms.li15s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li15s2'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li15s3'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li15s4'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li15s5'} />
            </li>
          </ol>
        </div>
        <div className={s.TermsPage__item} id={'delivery-by-company'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li16'} />
          </Heading>
          <ol>
            <li>
              <FormattedMessage id={'static.terms.li16s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li16s2'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li16s3'} />
            </li>
          </ol>
        </div>
        <div className={s.TermsPage__item} id={'delivery-by-company'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li17'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li17content'} />
          </span>
        </div>
        <div className={s.TermsPage__item} id={'termination'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li18'} />
          </Heading>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li18s1'} />
          </Heading>
          <ol>
            <li>
              <FormattedMessage id={'static.terms.li18s1s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li18s1s2'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li18s1s3'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li18s1s4'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li18s1s5'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li18s1s6'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li18s1s7'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li18s1s8'} />
            </li>
          </ol>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li18s2'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li18s2content'} />
          </span>
        </div>
        <div className={s.TermsPage__item} id={'property-rights'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li19'} />
          </Heading>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li19s1'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li19s1content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li19s2'} />
          </Heading>
          <ol>
            <li>
              <FormattedMessage id={'static.terms.li19s2s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li19s2s2'} />
            </li>
          </ol>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li19s3'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li19s3content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li19s4'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li19s4content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li19s5'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li19s5content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li19s6'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li19s6content'} />
          </span>
        </div>
        <div className={s.TermsPage__item} id={'user-responsibilities'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li20'} />
          </Heading>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li20s1'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li20s1content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li20s2'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li20s2content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li20s3'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li20s3content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li20s4'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li20s4content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li20s5'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li20s5content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li20s6'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li20s6content'} />
          </span>
        </div>
        <div className={s.TermsPage__item} id={'non-warranty'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li21'} />
          </Heading>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li21s1'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li21s1content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li21s2'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li21s2content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li21s3'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li21s3content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li21s4'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li21s4content'} />
          </span>
        </div>
        <div className={s.TermsPage__item} id={'damage'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li22'} />
          </Heading>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li22s1'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li22s1content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li22s2'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li22s2content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li22s3'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li22s3content'} />
          </span>
        </div>
        <div className={s.TermsPage__item} id={'general-terms'}>
          <Heading size={HeadingSize.H4} className={s.TermsPage__itemHeading}>
            <FormattedMessage id={'static.terms.li23'} />
          </Heading>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li23s1'} />
          </Heading>
          <ol>
            <li>
              <FormattedMessage id={'static.terms.li23s1s1'} />
            </li>
            <li>
              <FormattedMessage id={'static.terms.li23s1s2'} />
            </li>
          </ol>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li23s2'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li23s2content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li23s3'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li23s3content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li23s4'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li23s4content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li23s5'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li23s5content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li23s6'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li23s6content'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.TermsPage__itemSubHeading}>
            <FormattedMessage id={'static.terms.li23s7'} />
          </Heading>
          <span className={s.TermsPage__itemSubText}>
            <FormattedMessage id={'static.terms.li23s7content'} />
          </span>
        </div>
        <span className={s.TermsPage__issueDate}>
          <FormattedMessage id={'static.terms.issueDate'} />
        </span>
      </div>
    </>
  );
};
