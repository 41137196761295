import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { customIcons } from '../../CustomIcon/icons';
import { Loader } from '../../Loader/Loader';
import { Anchor, AnchorVariant } from '../../Anchor/Anchor';
import s from './PaymentAddButton.module.scss';

export interface PaymentAddButtonProps {
  className?: string;
  loading?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  variant?: AnchorVariant;
}

export const PaymentAddButton = ({ className, loading, onClick, variant }: PaymentAddButtonProps) => {
  return (
    <Anchor
      component={'button'}
      className={clsx(s.PaymentAddButton, className)}
      variant={variant}
      leftIcon={customIcons.CardAdd}
      onClick={loading ? undefined : onClick}
    >
      {loading ? <Loader small /> : <FormattedMessage id="payment.addCard" />}
    </Anchor>
  );
};
