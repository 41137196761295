import React from 'react';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { ReactFCC } from '../../../../common/utils/helperTypes';
import { useCookies } from '../../../../common/hooks/useCookies';
import { FixedBanner, FixedBannerVariant } from '../../../../common/components/FixedBanner/FixedBanner';
import { PRODUCT_ROUTE } from '../../../../common/utils/routes';
import s from './CookieBanner.module.scss';

export const CookieBanner: ReactFCC = () => {
  const { cookieAccepted, setCookieAccepted } = useCookies();
  const location = useLocation();

  const isOnProductPage = !!matchPath(PRODUCT_ROUTE, location.pathname);

  return (
    <>
      {!cookieAccepted && (
        <FixedBanner
          className={clsx({
            [s.CookieBanner_productPage]: isOnProductPage
          })}
          variant={FixedBannerVariant.SECONDARY}
          onClose={() => setCookieAccepted(true)}
          dismissible
        >
          Our site uses cookies and other similar technologies (web beacons, pixel tags, etc.) to provide services that
          best meet your needs, as well as collect statistical information to analyze and improve our services and sites
        </FixedBanner>
      )}
    </>
  );
};
