export const getOrderTotalPrice = (products?: { price: number }[], deliveryCost: number = 0, withShipping = true) => {
  return (
    (products?.reduce((acc, product) => {
      if (product) {
        return acc + product.price;
      } else {
        return acc;
      }
    }, 0) || 0) + (withShipping ? deliveryCost : 0)
  );
};
