import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { Product } from 'store/graphql';
import { ImageWithFallback } from 'common/components/ImageWithFallback';
import { FormattedMessage } from 'react-intl';
import React, { ReactNode } from 'react';
import { PathBuilder } from 'common/utils/routes';
import { Link } from 'common/components/Link/Link';
import { Anchor, AnchorVariant } from 'common/components/Anchor/Anchor';
import { BsTrash } from 'react-icons/bs';
import { ProductBadge } from '../ProductBadge';
import { Loader, LoaderBox, LoaderSize } from '../../../../common/components/Loader';
import { useCurrency } from '../../../../store/currency';
import s from './ProductCard.module.scss';

export interface BaseProductCardProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * ID товара
   */
  id: Product['id'];
  /**
   * Название товара
   */
  name: Product['name'];
  /**
   * Цена товара
   */
  price: Product['price'];
  /**
   * Отображать бейджик SOLD
   */
  showSoldBadge?: boolean;
  /**
   * Цена доставки
   */
  deliveryCost?: number;
  /**
   * URL фотографии
   */
  photoUrl?: string;
  /**
   * Значение атрибута цвета
   */
  colorAttributeValue?: string;
  /**
   * Значение атрибута размера
   */
  sizeAttributeValue?: string;
  /**
   * Обработчик нажатия на кнопку удаления
   */
  onClickRemove?: (productId: Product['id']) => void;
  /**
   * Состояние загрузки удаления
   */
  removeLoading?: boolean;
  /**
   * Слот для действий
   * todo временно, лучше сделать полноценный after
   */
  actionsSlot?: ReactNode | string;
}

export const BaseProductCard: ReactFCC<BaseProductCardProps> = (props) => {
  const {
    className,
    id,
    name,
    price,
    showSoldBadge,
    deliveryCost,
    photoUrl,
    colorAttributeValue,
    sizeAttributeValue,
    onClickRemove,
    removeLoading,
    actionsSlot
  } = props;

  const { convertCurrency, currencyLoading } = useCurrency();

  return (
    <div className={clsx(s.ProductCard, className)}>
      {showSoldBadge && <ProductBadge className={s.ProductCard__badge} />}

      <Link to={PathBuilder.getProductPath(id)}>
        <ImageWithFallback src={photoUrl} size={85} />
      </Link>

      <div className={s.ProductCard__info}>
        <Link className={s.ProductCard__title} to={PathBuilder.getProductPath(id)} variant={AnchorVariant.BODY}>
          {name}
        </Link>

        <span className={s.ProductCard__infoText}>
          <FormattedMessage id="catalog.color" />: {colorAttributeValue ? colorAttributeValue : '-'}
        </span>

        <span className={s.ProductCard__infoText}>
          <FormattedMessage id="catalog.size" />: {sizeAttributeValue ? sizeAttributeValue : '-'}
        </span>

        {currencyLoading ? (
          <LoaderBox />
        ) : (
          <div className={s.ProductCard__price}>
            <span className={s.ProductCard__priceValue}>{convertCurrency(price)}</span>

            {typeof deliveryCost === 'number' && (
              <span className={s.ProductCard__priceText}>
                <FormattedMessage id="product.deliveryPrice" /> {convertCurrency(deliveryCost)}
              </span>
            )}
          </div>
        )}
      </div>

      <div className={s.ProductCard__actions}>
        {/* todo убрать отсюда, в сущностях не должно быть действий, перенести в корзину*/}
        {onClickRemove && (
          <Anchor
            component={'button'}
            variant={AnchorVariant.SECONDARY}
            leftIcon={BsTrash}
            leftIconOptions={{ size: 16 }}
            onClick={() => !removeLoading && onClickRemove(id)}
          >
            {removeLoading && <Loader size={LoaderSize.SMALL} />}
          </Anchor>
        )}

        {actionsSlot}
      </div>
    </div>
  );
};
