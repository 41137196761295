import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { EMDASH } from '@proscom/ui-utils';
import { FormattedDate } from 'common/components/CustomFormatters';
import { TransactionType } from '../types';
import { TransactionToggle } from '../TransactionToggle/TransactionToggle';
import { transactionTypeIntlIds } from '../constants';
import { TransactionItemLink } from '../TransactionItemLink/TransactionItemLink';
import { TransactionAmount } from '../TransactionAmount/TransactionAmount';
import { PathBuilder } from '../../../../../../common/utils/routes';
import { Divider, EDividerVariant } from '../../../../../../common/components/Divider/Divider';
import { TransactionCardOrder } from './TransactionCardOrder';
import s from './TransactionCard.module.scss';

export interface TransactionCardProps extends TransactionType {
  className?: string;
  expanded: boolean;
  toggleExpand: () => void;
}

export const TransactionCard = ({
  className,
  expanded,
  toggleExpand,
  createdDate,
  transactionType,
  amount,
  balance,
  comment,
  order
}: TransactionCardProps) => {
  const transactionTypeIntlId = transactionTypeIntlIds[transactionType];

  return (
    <div className={clsx(s.TransactionCard, className)}>
      <div className={s.TransactionCardMain} onClick={toggleExpand}>
        {order && <TransactionToggle active={expanded} onClick={toggleExpand} />}
        <div className={s.TransactionCardMain__content}>
          <div className={s.TransactionCardMain__info}>
            <div className={s.TransactionCardMain__infoHead}>
              <div className={s.TransactionCardMain__date}>
                <FormattedDate value={new Date(createdDate)} format={'yyyy.MM.dd'} />{' '}
                <FormattedDate value={new Date(createdDate)} format={'HH:mm'} />
              </div>
              {transactionTypeIntlId ? <FormattedMessage id={transactionTypeIntlId} /> : transactionType}
            </div>
            <div className={s.TransactionCardMain__text}>
              {order ? (
                <TransactionItemLink to={PathBuilder.getOrderPath(order.order.id, true)}>
                  Order №{order.order.ID}
                </TransactionItemLink>
              ) : (
                comment || EMDASH
              )}
            </div>
          </div>
          <div className={s.TransactionCardMain__amounts}>
            <h5 className={s.TransactionCardMain__diffAmount}>
              <TransactionAmount value={amount} />
            </h5>
            <TransactionAmount value={balance} colorize={false} />
          </div>
        </div>
      </div>

      {!!order?.contents?.length && expanded && (
        <div className={s.TransactionCardInner}>
          <Divider variant={EDividerVariant.DEFAULT} />
          {order.contents.map((item, iItem) => (
            <TransactionCardOrder key={iItem} {...item} />
          ))}
        </div>
      )}
    </div>
  );
};
