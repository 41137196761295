import { ReactFCC } from 'common/utils/helperTypes';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { BsArrowLeftShort, BsChevronRight } from 'react-icons/bs';
import { useUi } from 'store/ui';
import { Modal } from 'common/components/Modal';
import { Anchor } from 'common/components/Anchor/Anchor';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { BrandInput, FormLabel } from 'common/components/inputs';
import { Divider } from 'common/components/Divider/Divider';
import { CatalogFilterCondition } from '../../CatalogFilterCondition';
import { CatalogFilterColor } from '../../CatalogFilterColor';
import { CatalogFilterPrice } from '../../CatalogFilterPrice';
import { CatalogFiltersLayoutProps } from '../types';
import { useCatalogFiltersSpecificAttributes } from '../../CatalogFiltersSpecificAttributes';
import { BaseCatalogFilter } from '../../../CatalogFilter';
import { ButtonVariant } from '../../../../../../common/components/Button';
import { CatalogFilterStatus } from '../../CatalogFilterStatus';
import s from './CatalogFiltersLayoutMobile.module.scss';

export interface CatalogFiltersLayoutMobileProps extends CatalogFiltersLayoutProps {
  open: boolean;
  onClose: () => void;
}

export const CatalogFiltersLayoutMobile: ReactFCC<CatalogFiltersLayoutMobileProps> = (props) => {
  const {
    open,
    onClose,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    brandId,
    setBrandId,
    colorIds,
    setColorIds,
    conditionIds,
    setConditionIds,
    statusIds,
    setStatusIds,
    subSubCategoryId,
    specificAttributeValues,
    setSpecificAttributeValues
  } = props;

  const intl = useIntl();
  const { preventBodyScroll } = useUi();

  const { specificAttributes, specificAttributesOptions, toggleAttributeValue } = useCatalogFiltersSpecificAttributes({
    subSubCategoryId,
    specificAttributeValues,
    setSpecificAttributeValues
  });

  const [isBrandFilter, setIsBrandFilter] = useState(false);
  const [isColorFilter, setIsColorFilter] = useState(false);
  const [isConditionFilter, setIsConditionFilter] = useState(false);
  const [isStatusFilter, setIsStatusFilter] = useState(false);
  const [specificAttributeOpenedIndex, setSpecificAttributeOpenedIndex] = useState<number | null>(null);

  const activeSpecificAttribute =
    (specificAttributeOpenedIndex !== null && specificAttributes && specificAttributes[specificAttributeOpenedIndex]) ||
    undefined;
  const activeSpecificAttributeOptions = activeSpecificAttribute
    ? specificAttributesOptions.find((i) => i.id === activeSpecificAttribute.id)?.variants
    : undefined;

  const isActiveFilter =
    isBrandFilter || isColorFilter || isConditionFilter || isStatusFilter || activeSpecificAttribute;

  const onClickButton = () => {
    if (isActiveFilter) {
      setIsBrandFilter(false);
      setIsColorFilter(false);
      setIsConditionFilter(false);
      setIsStatusFilter(false);
      setSpecificAttributeOpenedIndex(null);
    } else {
      onClose();
    }
  };

  const onClearButton = () => {
    setColorIds([]);
    setBrandId(null);
    setConditionIds([]);
    setStatusIds([]);
    setSpecificAttributeValues([]);
    setMinPrice('');
    setMaxPrice('');
  };

  useEffect(() => {
    preventBodyScroll(open);
    return () => preventBodyScroll(false);
  }, [open, preventBodyScroll]);
  return (
    <Modal title={intl.formatMessage({ id: 'catalog.filters' })} isOpen={open} onClose={onClose}>
      <Modal.Body>
        {isActiveFilter ? (
          <>
            <Anchor component="button" leftIcon={BsArrowLeftShort} onClick={onClickButton}>
              <FormattedMessage id="general.back" />
            </Anchor>

            <Space size={ESpaceSize.PIXEL_16} />
          </>
        ) : (
          <>
            <FormLabel>
              <FormattedMessage id="catalog.price" />
            </FormLabel>

            <CatalogFilterPrice
              minPrice={minPrice}
              maxPrice={maxPrice}
              setMinPrice={setMinPrice}
              setMaxPrice={setMaxPrice}
            />

            <Divider space={ESpaceSize.PIXEL_16} />

            <Anchor
              component="button"
              className={s.CatalogFiltersLayoutMobile__button}
              rightIcon={BsChevronRight}
              rightIconOptions={{ size: 14 }}
              onClick={() => setIsBrandFilter(true)}
            >
              <span>
                <FormattedMessage id="catalog.brand" />
                {brandId && <span className={s.CatalogFiltersLayoutMobile__filterCounter}>{1}</span>}
              </span>
            </Anchor>

            <Divider space={ESpaceSize.PIXEL_16} />

            <Anchor
              component="button"
              className={s.CatalogFiltersLayoutMobile__button}
              rightIcon={BsChevronRight}
              rightIconOptions={{ size: 14 }}
              onClick={() => setIsColorFilter(true)}
            >
              <span>
                <FormattedMessage id="catalog.color" />
                {!!colorIds.length && (
                  <span className={s.CatalogFiltersLayoutMobile__filterCounter}>{colorIds.length}</span>
                )}
              </span>
            </Anchor>

            <Divider space={ESpaceSize.PIXEL_16} />

            <Anchor
              component="button"
              className={s.CatalogFiltersLayoutMobile__button}
              rightIcon={BsChevronRight}
              rightIconOptions={{ size: 14 }}
              onClick={() => setIsConditionFilter(true)}
            >
              <span>
                <FormattedMessage id="catalog.condition" />
                {!!conditionIds.length && (
                  <span className={s.CatalogFiltersLayoutMobile__filterCounter}>{conditionIds.length}</span>
                )}
              </span>
            </Anchor>

            <Divider space={ESpaceSize.PIXEL_16} />

            <Anchor
              component="button"
              className={s.CatalogFiltersLayoutMobile__button}
              rightIcon={BsChevronRight}
              rightIconOptions={{ size: 14 }}
              onClick={() => setIsStatusFilter(true)}
            >
              <span>
                <FormattedMessage id="catalog.itemStatus" />
                {statusIds.length > 0 && (
                  <span className={s.CatalogFiltersLayoutMobile__filterCounter}>{+!!statusIds.length}</span>
                )}
              </span>
            </Anchor>

            {specificAttributes?.map((attr, index) => (
              <React.Fragment key={index}>
                <Divider space={ESpaceSize.PIXEL_16} />

                <Anchor
                  component="button"
                  className={s.CatalogFiltersLayoutMobile__button}
                  rightIcon={BsChevronRight}
                  rightIconOptions={{ size: 14 }}
                  onClick={() => setSpecificAttributeOpenedIndex(index)}
                >
                  <span>
                    {attr.name}
                    {specificAttributeValues &&
                      specificAttributeValues?.find((sa) => sa.attributeId === attr.id)?.variantIds.length && (
                        <span className={s.CatalogFiltersLayoutMobile__filterCounter}>
                          {specificAttributeValues?.find((sa) => sa.attributeId === attr.id)?.variantIds.length}
                        </span>
                      )}
                  </span>
                </Anchor>
              </React.Fragment>
            ))}
          </>
        )}

        {isBrandFilter && (
          <BrandInput
            label={intl.formatMessage({ id: 'catalog.brand' })}
            placeholder={intl.formatMessage({ id: 'catalog.brand' })}
            active={brandId}
            setActive={setBrandId}
          />
        )}

        {isColorFilter && <CatalogFilterColor active={colorIds} setActive={setColorIds} />}

        {isConditionFilter && <CatalogFilterCondition active={conditionIds} setActive={setConditionIds} />}

        {isStatusFilter && <CatalogFilterStatus active={statusIds} setActive={setStatusIds} />}

        {activeSpecificAttribute && (
          <BaseCatalogFilter
            title={activeSpecificAttribute.name}
            items={activeSpecificAttributeOptions || []}
            active={
              specificAttributeValues.find((value) => value.attributeId === activeSpecificAttribute.id)?.variantIds ||
              []
            }
            setActive={(value) => toggleAttributeValue(activeSpecificAttribute.id, value)}
          />
        )}
      </Modal.Body>

      <Modal.Footer>
        {(brandId ||
          !!statusIds.length ||
          !!colorIds.length ||
          !!conditionIds.length ||
          !!specificAttributeValues.length ||
          maxPrice ||
          minPrice) && (
          <Modal.Button variant={ButtonVariant.PRIMARY_OUTLINE} onClick={onClearButton}>
            <FormattedMessage id="catalog.filtersClearButton" />
          </Modal.Button>
        )}
        <Modal.Button onClick={onClickButton}>
          {isActiveFilter ? (
            <FormattedMessage id="catalog.filtersDoneButton" />
          ) : (
            <FormattedMessage id="catalog.filtersShowResultButton" />
          )}
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};
