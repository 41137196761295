import { FormattedMessage } from 'react-intl';
import React, { useRef } from 'react';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import { BsPrinter, BsSave } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import { useUrlParam } from '../../../../common/hooks/useUrlParam';
import { PICKUP_REQUEST_PAGE_PARAM, PICKUP_ROUTE } from '../../../../common/utils/routes';
import { useCancelPickupRequestMutation, usePickupRequestExtendedQuery } from '../../../../store/graphql';
import { Head } from '../../../../common/components/Head';
import { LinkBack } from '../../../../common/components/LinkBack';
import { ESpaceSize, Space } from '../../../../common/components/Space/Space';
import { useIsMobile } from '../../../../common/hooks/useIsMobile';
import { Grid } from '../../../../common/components/Grid';
import { LoaderBox } from '../../../../common/components/Loader';
import { Button, ButtonFit, ButtonVariant } from '../../../../common/components/Button';
import { Divider } from '../../../../common/components/Divider/Divider';
import { useToggle } from '../../../../common/hooks/useToggle';
import { clearCache, useAuth } from '../../../../app/providers/auth-apollo';
import { externalApiUrl } from '../../../../config';
import { useWebview } from '../../../../store/webview/useWebview';
import { PickupRequestParcelCard } from './components/PickupRequestParcelCard/PickupRequestParcelCard';
import { PickupRequestConfirmModal } from './components/PickupRequestConfirmModal/PickupRequestConfirmModal';
import s from './PickupRequestExtended.module.scss';

const mutationOptions = {
  refetchQueries: ['PickupRequestExtended'],
  update: clearCache(['pickupRequestExtended'])
};
export const PickupRequestExtended = () => {
  const pickupUuid = useUrlParam(PICKUP_REQUEST_PAGE_PARAM);

  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { isWebview, callWebviewMethod } = useWebview();
  const { getToken } = useAuth();

  const { data: pickupRequestExtendedQuery, loading: pickupRequestLoading } = usePickupRequestExtendedQuery({
    fetchPolicy: 'cache-and-network',
    skip: !pickupUuid,
    variables: {
      pickupUuid: pickupUuid || ''
    }
  });

  const printRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: s.PickupRequestsExtended__print
  });

  const [pickupRequestModalOpen, { set: openPickupRequestModal, unset: closePickupRequestModal }] = useToggle(false);
  const [cancelPickupMutation, { loading: cancelPickupLoading }] = useCancelPickupRequestMutation(mutationOptions);

  const pickupRequest = pickupRequestExtendedQuery?.result;
  const packages = pickupRequest?.packages;
  const pickupOrderIds = packages?.map((pack) => pack.orderId);

  const cancelPickup = async () => {
    if (!pickupUuid || !pickupOrderIds || !pickupOrderIds.length) {
      return;
    }

    try {
      await cancelPickupMutation({ variables: { pickupUuid: pickupUuid, orderIds: pickupOrderIds } });
      toast.success('Pick-up request was canceled');
      navigate(PICKUP_ROUTE);
    } catch (e) {
      toast.error('Something went wrong with pick-up cancelling');
    }
  };

  if (pickupRequestLoading) {
    return <LoaderBox />;
  }

  return (
    <>
      <Head title={`Pick-up request №${pickupUuid}`} />

      <div className={s.PickupRequestsExtended}>
        <LinkBack>
          <FormattedMessage id="general.back" />
        </LinkBack>
        <Space size={ESpaceSize.PIXEL_16} />
        <div>
          {pickupRequest && (
            <>
              <div className={s.PickupRequestsExtended__header}>
                <span className={s.PickupRequestsExtended__header_title}>
                  Pick-up request №{pickupRequest.uuid.slice(0, 3)}
                </span>
                <div className={s.PickupRequestsExtended__header_controls}>
                  {isWebview ? (
                    <Button
                      fit={ButtonFit.FILL}
                      onClick={async () => {
                        const token = await getToken();
                        callWebviewMethod('saveOrderPickupRequest', [
                          `${externalApiUrl}/documents/packages/pickupRequest`,
                          token,
                          pickupUuid
                        ]);
                      }}
                    >
                      <BsSave /> <FormattedMessage id={'orderCombine.save'} />
                    </Button>
                  ) : (
                    <>
                      {/* TODO Download PDF externally */}
                      <Button fit={isMobile ? ButtonFit.FILL : ButtonFit.FIT} onClick={handlePrint}>
                        <BsPrinter /> <FormattedMessage id={'orderCombine.print'} />
                      </Button>
                    </>
                  )}
                </div>
              </div>
              <Space size={ESpaceSize.PIXEL_16} />
              <div ref={printRef}>
                <div className={s.PickupRequestsExtended__section}>
                  <span className={s.PickupRequestsExtended__section_title}>
                    <FormattedMessage id={'pickupRequestExtended.details'} />
                  </span>
                </div>
                <Grid className={s.PickupRequestsExtended__details}>
                  <Grid.GridItem cols={{ xs: 1, lg: 10 }} className={s.PickupRequestsExtended_option}>
                    <div className={s.PickupRequestsExtended__details_item}>
                      <span className={s.PickupRequestsExtended__details_item_title}>
                        <FormattedMessage id={'pickupRequest.card.date'} />
                      </span>
                      <span>{format(new Date(pickupRequest?.pickupDate), 'yyyy.MM.dd')}</span>
                    </div>
                  </Grid.GridItem>
                  <Grid.GridItem cols={{ xs: 1, lg: 10 }} className={s.PickupRequestsExtended_option}>
                    <div className={s.PickupRequestsExtended__details_item}>
                      <span className={s.PickupRequestsExtended__details_item_title}>
                        <FormattedMessage id={'pickupRequest.card.time'} />
                      </span>
                      <span>{`${format(
                        new Date(
                          new Date(pickupRequest?.pickupDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })
                        ),
                        'HH:mm'
                      )} - ${pickupRequest.latestTime.slice(0, pickupRequest.latestTime.length - 3)}`}</span>
                    </div>
                  </Grid.GridItem>

                  <Grid.GridItem cols={{ xs: 1, lg: 10 }} className={s.PickupRequestsExtended_option}>
                    <div className={s.PickupRequestsExtended__details_item}>
                      <span className={s.PickupRequestsExtended__details_item_title}>
                        <FormattedMessage id={'pickupRequest.card.address'} />
                      </span>
                      <span>{pickupRequest?.addressLines}</span>
                    </div>
                  </Grid.GridItem>
                </Grid>
                <Space size={ESpaceSize.PIXEL_24} />
                <div className={s.PickupRequestsExtended__section}>
                  <span className={s.PickupRequestsExtended__section_title}>
                    <FormattedMessage id={'pickupRequestExtended.parcels'} />
                  </span>
                </div>
                {packages?.map((pack, index) => (
                  <PickupRequestParcelCard key={index} packages={pack} />
                ))}
                {isMobile && (
                  <>
                    <div className={s.PickupRequestsExtended__section}>
                      <span className={s.PickupRequestsExtended__section_title}>
                        <FormattedMessage id={'pickupRequestExtended.summary'} />
                      </span>
                    </div>
                  </>
                )}

                <div className={s.PickupRequestsExtended__summary}>
                  <span className={s.PickupRequestsExtended__summary_item}>
                    <FormattedMessage id={'pickupRequestExtended.totalParcels'} /> {packages?.length}
                  </span>
                  <span className={s.PickupRequestsExtended__summary_item}>
                    <FormattedMessage id={'pickupRequestExtended.totalBoxes'} />{' '}
                    {packages?.reduce((prev, curr) => prev + curr.packages.length, 0)}
                  </span>
                  <span className={s.PickupRequestsExtended__summary_item}>
                    <FormattedMessage id={'pickupRequestExtended.totalWeight'} />{' '}
                    {packages?.reduce(
                      (prev, curr) => prev + curr.packages.reduce((prev, curr) => prev + curr.weight, 0),
                      0
                    )}{' '}
                    kg
                  </span>
                </div>
              </div>
              {pickupRequest.active && (
                <>
                  <Divider space={ESpaceSize.PIXEL_24} />
                  <div className={s.PickupRequestsExtended__controls}>
                    <Button variant={ButtonVariant.PRIMARY} onClick={() => openPickupRequestModal()}>
                      <span className={s.PickupRequestsExtended__controls_title}>
                        <FormattedMessage id={'orderCombine.confirmSending'} />
                      </span>
                    </Button>
                    <Button variant={ButtonVariant.ERROR} loading={cancelPickupLoading} onClick={() => cancelPickup()}>
                      <span className={s.PickupRequestsExtended__controls_title}>
                        <FormattedMessage id={'pickupRequestExtended.cancelPickup'} />
                      </span>
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <PickupRequestConfirmModal
        isOpen={pickupRequestModalOpen}
        onClose={closePickupRequestModal}
        orderIds={pickupOrderIds || []}
        pickupUuid={pickupUuid || ''}
      />
    </>
  );
};
