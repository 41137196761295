import React from 'react';
import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { ReactFCC } from 'common/utils/helperTypes';
import { Grid } from 'common/components/Grid/Grid';
import { HeadingSize, Heading } from 'common/components/Heading/Heading';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { EBreakpoints, useBreakpoint } from 'common/hooks/useBreakpoint';
import { ConditionalWrapper } from 'common/components/ConditionalWrapper/ConditionalWrapper';
import { Button, ButtonFit, ButtonVariant } from 'common/components/Button/Button/Button';
import { BsCamera } from 'react-icons/bs';
import { CREATE_PRODUCT_ROUTE } from '../../../../common/utils/routes';
import { ShowcaseProducts } from './products/ShowcaseProducts';
import s from './ShowcasePageContent.module.scss';

export const ShowcasePageContent: ReactFCC = () => {
  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);

  const navigate = useNavigate();

  return (
    <>
      <Grid className={s.ShowcasePageContent} cols={9}>
        <Grid.GridItem full>
          <Heading size={HeadingSize.H3}>
            <FormattedMessage id="profile.showcaseTitle" />
          </Heading>

          <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />
        </Grid.GridItem>

        <Grid.GridItem className={s.ShowcasePageContent__controls} full>
          <ConditionalWrapper
            condition={!isMobile}
            wrapper={(children) => (
              <Grid className={s.ShowcasePageContent__controlsInner} cols={9}>
                {children}
              </Grid>
            )}
          >
            <ConditionalWrapper
              condition={!isMobile}
              wrapper={(children) => <Grid.GridItem cols={{ md: 3, xxl: 2 }}>{children}</Grid.GridItem>}
            >
              <Button
                className={s.ShowcasePageContent__button}
                variant={ButtonVariant.PRIMARY}
                fit={ButtonFit.FIT}
                onClick={() => navigate(CREATE_PRODUCT_ROUTE)}
              >
                <BsCamera size={24} />
                <FormattedMessage id="profile.showcaseAddItemButton" />
              </Button>
            </ConditionalWrapper>
          </ConditionalWrapper>
        </Grid.GridItem>

        <Grid.GridItem full>
          <Space size={isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16} />
        </Grid.GridItem>

        <Grid.GridItem full>
          <ShowcaseProducts />
        </Grid.GridItem>
      </Grid>
    </>
  );
};
