import { FormattedMessage, useIntl } from 'react-intl';
import { Heading, HeadingSize } from '../../../common/components/Heading';
import s from './CookiesInfoPage.module.scss';

export const CookiesInfoPage = () => {
  const intl = useIntl();
  return (
    <>
      <div className={s.CookiesPage__content}>
        <Heading size={HeadingSize.H4}>
          <FormattedMessage id={'static.cookies.header'} />
        </Heading>
        <div className={s.CookiesPage__contentItem}>
          <Heading size={HeadingSize.H5} className={s.CookiesPage__heading}>
            <FormattedMessage id={'static.cookies.whatTitle'} />
          </Heading>
          <span>
            <FormattedMessage id={'static.cookies.what'} />
          </span>
        </div>
        <div className={s.CookiesPage__contentItem}>
          <Heading size={HeadingSize.H5} className={s.CookiesPage__heading}>
            <FormattedMessage id={'static.cookies.useTitle'} />
          </Heading>
          <span>
            <FormattedMessage id={'static.cookies.use'} />
          </span>
        </div>
        <div className={s.CookiesPage__contentItem}>
          <Heading size={HeadingSize.H5} className={s.CookiesPage__heading}>
            <FormattedMessage id={'static.cookies.settingsTitle'} />
          </Heading>
          <span>
            <FormattedMessage id={'static.cookies.settings'} />
          </span>
        </div>
        <div className={s.CookiesPage__contentItem}>
          <Heading size={HeadingSize.H5} className={s.CookiesPage__heading}>
            <FormattedMessage id={'static.cookies.googleTitle'} />
          </Heading>
          <div className={s.CookiesPage__textCols}>
            <span>
              <FormattedMessage id={'static.cookies.googlePt1'} />
            </span>
            <span className={s.CookiesPage__textLeft}>
              <FormattedMessage id={'static.cookies.googlePt2'} />
            </span>
            <span className={s.CookiesPage__textLeft}>
              <FormattedMessage id={'static.cookies.googlePt3'} />
            </span>
            <span className={intl.locale === 'ja-JP' ? s.CookiesPage__textLeft : ''}>
              <FormattedMessage id={'static.cookies.googlePt4'} />
            </span>
            <span className={intl.locale === 'ja-JP' ? '' : s.CookiesPage__textLeft}>
              <FormattedMessage id={'static.cookies.googlePt5'} />
            </span>
            {intl.locale === 'ja-JP' && (
              <span className={s.CookiesPage__textLeft}>
                <FormattedMessage id={'static.cookies.googlePt6'} />
              </span>
            )}
          </div>
        </div>
        <div className={s.CookiesPage__contentItem}>
          <Heading size={HeadingSize.H5} className={s.CookiesPage__heading}>
            <FormattedMessage id={'static.cookies.logsTitle'} />
          </Heading>
          <Heading size={HeadingSize.H6} className={s.CookiesPage__heading}>
            <FormattedMessage id={'static.cookies.logs'} />
          </Heading>
          <span>
            <FormattedMessage id={'static.cookies.logsText'} />
          </span>
          <Heading size={HeadingSize.H6} className={s.CookiesPage__heading}>
            <FormattedMessage id={'static.cookies.device'} />
          </Heading>
          <span>
            <FormattedMessage id={'static.cookies.deviceText'} />
          </span>
        </div>
      </div>
    </>
  );
};
