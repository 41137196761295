import { RouteObject } from 'react-router-dom';
import React from 'react';
import {
  CREATE_PRODUCT_ROUTE,
  CUSTOMER_ORDER_ROUTE,
  CUSTOMER_ORDERS_ROUTE,
  DRAFTS_ROUTE,
  EDIT_PRODUCT_DRAFT_ROUTE,
  EDIT_PRODUCT_ROUTE,
  FAVORITES_ROUTE,
  PERSONAL_INFORMATION_ROUTE,
  PROFILE_ROUTE,
  SELLER_ORDER_ROUTE,
  SHOWCASE_ROUTE,
  SUPPORT_ROUTE,
  VERIFICATION_ROUTE,
  TRUST_POINTS_GUIDE_ROUTE,
  TRUST_POINTS_ROUTE,
  WALLET_ROUTE,
  WITHDRAW_ROUTE,
  withRoutes,
  REVIEWS_ROUTE,
  PUBLIC_PROFILE_ROUTE,
  PICKUP_ROUTE,
  PICKUP_REQUEST_ROUTE,
  RELIST_PRODUCT_ROUTE,
  TICKETS_ROUTE,
  SINGLE_TICKET_ROUTE,
  CREATE_TICKET_ROUTE
} from '../../common/utils/routes';

import { UserPage } from '../user/UserPage';
import { NotFoundPage } from '../notFound/NotFoundPage';

import { ProfileLayout } from './_layout/ProfileLayout';
import { ProfilePage } from './profile/ProfilePage';
import { WalletPage } from './wallet/WalletPage';
import { ShowcasePage } from './showcase/ShowcasePage';
import { CreateProductPage } from './createProduct/CreateProductPage';
import { WithdrawPage } from './withdraw/WithdrawPage';
import { FavoritesPage } from './favorites';
import { VerificationPage } from './verification/VerificationPage';
import { SupportPage } from './support/SupportPage';
import { DraftsPage } from './drafts';
import { TrustPointsPage } from './trustPoints/TrustPointsPage';
import { TrustPointsGuidePage } from './trustPoints/pages/guide/TrustPointsGuidePage';
import { OrdersPage } from './orders/OrdersPage';
import { ReviewsPage } from './reviews/ReviewsPage';
import { PickupRequestsPage } from './pickupRequests/PickupRequestsPage';
import { PickupRequestExtended } from './pickupRequests/PickupRequestExtended/PickupRequestExtended';
import { OrderPage } from './order/OrderPage';
import { SellerOrderPage } from './sellerOrder/SellerOrderPage';
import { TicketsPage } from './tickets/TicketsPage';
import { TicketPage } from './ticket/TicketPage';
import { CreateTicketPage } from './createTicket/CreateTicketPage';

const routes: RouteObject[] = [
  {
    element: <ProfileLayout />,
    children: [
      {
        index: true,
        element: <ProfilePage />
      },
      {
        path: CUSTOMER_ORDERS_ROUTE.replace(PROFILE_ROUTE, ''),
        element: <OrdersPage />
      },
      {
        path: WALLET_ROUTE.replace(PROFILE_ROUTE, ''),
        element: <WalletPage />
      },
      {
        path: TRUST_POINTS_ROUTE.replace(PROFILE_ROUTE, ''),
        element: <TrustPointsPage />
      },
      {
        path: PICKUP_ROUTE.replace(PROFILE_ROUTE, ''),
        element: <PickupRequestsPage />
      },
      {
        path: VERIFICATION_ROUTE.replace(PROFILE_ROUTE, ''),
        element: <VerificationPage />
      },
      {
        path: REVIEWS_ROUTE.replace(PROFILE_ROUTE, ''),
        element: <ReviewsPage />
      },
      {
        path: SUPPORT_ROUTE.replace(PROFILE_ROUTE, ''),
        element: <SupportPage />
      },
      {
        path: SHOWCASE_ROUTE.replace(PROFILE_ROUTE, ''),
        element: <ShowcasePage />
      },
      {
        path: FAVORITES_ROUTE.replace(PROFILE_ROUTE, ''),
        element: <FavoritesPage />
      },
      {
        path: DRAFTS_ROUTE.replace(PROFILE_ROUTE, ''),
        element: <DraftsPage />
      },
      {
        path: PUBLIC_PROFILE_ROUTE.replace(PROFILE_ROUTE, ''),
        element: <UserPage isInternalPage={true} />
      },
      {
        path: TICKETS_ROUTE.replace(PROFILE_ROUTE, ''),
        element: <TicketsPage />
      },
      {
        path: SINGLE_TICKET_ROUTE.replace(PROFILE_ROUTE, ''),
        element: <TicketPage />
      },
      {
        path: CREATE_TICKET_ROUTE.replace(PROFILE_ROUTE, ''),
        element: <CreateTicketPage />
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  },
  {
    path: PICKUP_REQUEST_ROUTE.replace(PROFILE_ROUTE, ''),
    element: <PickupRequestExtended />
  },
  {
    path: CUSTOMER_ORDER_ROUTE.replace(PROFILE_ROUTE, ''),
    element: <OrderPage />
  },
  {
    path: SELLER_ORDER_ROUTE.replace(PROFILE_ROUTE, ''),
    element: <SellerOrderPage />
  },
  {
    path: WITHDRAW_ROUTE.replace(PROFILE_ROUTE, ''),
    element: <WithdrawPage />
  },
  {
    path: CREATE_PRODUCT_ROUTE.replace(PROFILE_ROUTE, ''),
    element: <CreateProductPage />
  },
  {
    path: RELIST_PRODUCT_ROUTE.replace(PROFILE_ROUTE, ''),
    element: <CreateProductPage />
  },
  {
    path: EDIT_PRODUCT_ROUTE.replace(PROFILE_ROUTE, ''),
    element: <CreateProductPage />
  },
  {
    path: TRUST_POINTS_GUIDE_ROUTE.replace(PROFILE_ROUTE, ''),
    element: <TrustPointsGuidePage />
  },
  {
    path: EDIT_PRODUCT_DRAFT_ROUTE.replace(PROFILE_ROUTE, ''),
    element: <CreateProductPage />
  },
  {
    path: PERSONAL_INFORMATION_ROUTE.replace(PROFILE_ROUTE, ''),
    element: <ProfilePage />
  }
];

export const ProfileRoutes = withRoutes(routes);
