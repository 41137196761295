import { PropsWithChildren } from 'react';
import { ApolloClientProvider } from './apollo/ApolloClientProvider';
import { AuthProvider } from './auth/AuthProvider';

export const AuthApolloProvider = (props: PropsWithChildren) => {
  return (
    <AuthProvider>
      <ApolloClientProvider>{props.children}</ApolloClientProvider>
    </AuthProvider>
  );
};
