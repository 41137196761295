import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ExtractArray, ReactFCC } from '../../../../common/utils/helperTypes';
import { useToggle } from '../../../../common/hooks/useToggle';
import { AddressAddButton, AddressForm } from '../../components';
import { AddressPersonalInformation, UserAddress } from '../../types';
import { User } from '../../../../entities/user';
import { AddressSelectSlot, AddressSelectSlotVariant } from './AddressSelectSlot';
import { AddressSelectModal } from './AddressSelectModal';
import s from './AddressSelectLayout.module.scss';

export enum AddressSelectVariant {
  SHIPPING = 'shipping',
  BILLING = 'billing'
}

export interface AddressSelectLayoutProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Дополнительные css-классы для дочерних элементов
   * * layout - дочерний лейтаут списка адресов
   * * item - карточка адреса
   * * button - кнопка добавления нового адреса
   */
  classes?: {
    layout?: string;
    item?: string;
    button?: string;
  };
  /**
   * Массив объектов адресов, полученный с api
   */
  addresses: UserAddress[];
  /**
   * ID адреса для доставки
   */
  shippingAddressId?: UserAddress['id'];
  /**
   * ID адреса для выставления счета
   */
  billingAddressId?: UserAddress['id'];
  /**
   * Сеттер ID адреса для доставки
   */
  setShippingAddressId?: (id: UserAddress['id']) => void;
  /**
   * Сеттер ID адреса для выставления счета
   */
  setBillingAddressId?: (id: UserAddress['id']) => void;
  /**
   * Персональная информация по умолчанию
   */
  personalInformation?: AddressPersonalInformation;
  /**
   * Флаг для скрытия биллинг адреса
   */
  hideBilling?: boolean;
  /**
   * Флаг для скрытия шиппинг адреса
   */
  hideShipping?: boolean;
  /**
   * Флаг сохранения при первичном создании
   */
  selectOnFirst?: boolean;
}

const selectModalTitleIntlId = {
  [AddressSelectVariant.SHIPPING]: 'address.select.chooseShipping',
  [AddressSelectVariant.BILLING]: 'address.select.chooseSBilling'
};

/**
 * Сложный компонент, отображающий выбор адресов для доставки.
 * Включает в себя также модальное окно для выбора адреса доставки/биллинга из существующих,
 * а также модальное окно для редактирования адресов и добавления новых.
 * Используется на странице заказа OrderingPage
 */
export const AddressSelectLayout: ReactFCC<AddressSelectLayoutProps> = (props) => {
  const {
    className,
    classes,
    addresses,
    shippingAddressId,
    billingAddressId,
    setShippingAddressId,
    setBillingAddressId,
    personalInformation,
    hideBilling,
    hideShipping,
    selectOnFirst
  } = props;

  const intl = useIntl();

  const shippingAddress = addresses.find((address) => address.id === shippingAddressId);
  const billingAddress = addresses.find((address) => address.id === billingAddressId);

  const [selectVariant, setSelectVariant] = useState<AddressSelectVariant | null>(null);
  const [selectModalOpen, { set: openSelectModal, unset: closeSelectModal }] = useToggle(false);
  const [selectedAddressId, setSelectedAddressId] = useState<UserAddress['id'] | null>(null);

  const [addressModalOpen, { set: openAddressModal, unset: closeAddressModal }] = useToggle(false);
  const [editedAddress, setEditedAddress] = useState<UserAddress | null>(null);

  const openEditFromSelectRef = useRef(false);

  const onClickChangeAddress = (variant: AddressSelectVariant) => {
    if (variant === AddressSelectVariant.SHIPPING) {
      setSelectedAddressId(shippingAddressId || null);
      setSelectVariant(AddressSelectVariant.SHIPPING);
    } else {
      setSelectedAddressId(billingAddressId || null);
      setSelectVariant(AddressSelectVariant.BILLING);
    }

    openSelectModal();
  };

  const onClickEditAddress = (address: ExtractArray<User['addresses']> | undefined) => {
    if (address) {
      setEditedAddress(address);
      openAddressModal();
    }
  };

  const onSubmit = () => {
    if (!selectedAddressId) {
      return;
    }

    if (selectVariant === AddressSelectVariant.SHIPPING) {
      setShippingAddressId?.(selectedAddressId);
    } else {
      setBillingAddressId?.(selectedAddressId);
    }

    closeSelectModal();
  };

  //Select on initial address adding to reflect layout
  useEffect(() => {
    if (addresses.length === 1 && selectOnFirst) {
      const [address] = addresses;
      address && setBillingAddressId?.(address.id);
      address && setShippingAddressId?.(address.id);
    }
  }, [addresses, setBillingAddressId, setShippingAddressId, selectOnFirst]);

  return (
    <>
      {addresses.length !== 0 ? (
        <div className={clsx(s.AddressSelectLayout, className)}>
          {!hideShipping && (
            <AddressSelectSlot
              className={s.AddressSelectLayout__slot}
              classes={{
                item: classes?.item
              }}
              address={shippingAddress}
              variant={AddressSelectSlotVariant.SHIPPING}
              onClickChange={() => onClickChangeAddress(AddressSelectVariant.SHIPPING)}
              onClickEdit={() => onClickEditAddress(shippingAddress)}
            />
          )}

          {!hideBilling && (
            <AddressSelectSlot
              className={s.AddressSelectLayout__slot}
              classes={{
                item: classes?.item
              }}
              address={billingAddress}
              variant={AddressSelectSlotVariant.BILLING}
              onClickChange={() => onClickChangeAddress(AddressSelectVariant.BILLING)}
              onClickEdit={() => onClickEditAddress(billingAddress)}
            />
          )}
        </div>
      ) : (
        <AddressAddButton onClick={openAddressModal} />
      )}

      <AddressSelectModal
        title={selectVariant ? intl.formatMessage({ id: selectModalTitleIntlId[selectVariant] }) : ''}
        isOpen={selectModalOpen}
        onClose={closeSelectModal}
        onClickOpenForm={(address) => {
          closeSelectModal();
          setEditedAddress(address);
          openAddressModal();
          openEditFromSelectRef.current = true;
        }}
        addresses={addresses}
        selectedAddressId={selectedAddressId}
        setSelectedAddressId={setSelectedAddressId}
        onSubmit={onSubmit}
      />

      <AddressForm
        isOpen={addressModalOpen}
        address={editedAddress}
        defaultsSelected={addresses.length === 0}
        personalInformation={personalInformation}
        onClose={() => {
          closeAddressModal();
          setEditedAddress(null);
          if (openEditFromSelectRef.current) {
            openSelectModal();
          }
          openEditFromSelectRef.current = false;
        }}
      />
    </>
  );
};
