import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TransactionOrderContentType } from '../types';
import { TransactionToggle } from '../TransactionToggle/TransactionToggle';
import { TransactionItemLink } from '../TransactionItemLink/TransactionItemLink';
import { TransactionAmount } from '../TransactionAmount/TransactionAmount';
import { PathBuilder } from '../../../../../../common/utils/routes';
import s from './TransactionCard.module.scss';

export interface TransactionCardOrderProps extends TransactionOrderContentType {}

export const TransactionCardOrder = ({ productId, name, price, commission }: TransactionCardOrderProps) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <div className={s.TransactionCardOrder} onClick={toggleExpand}>
      <div className={s.TransactionCardOrder__head}>
        <TransactionToggle active={expanded} onClick={toggleExpand} />
        <TransactionItemLink to={PathBuilder.getProductPath(productId)}>{name}</TransactionItemLink>
        <h5 className={s.TransactionCardOrder__price}>
          <TransactionAmount value={price - commission} />
        </h5>
      </div>

      {expanded && (
        <div className={s.TransactionCardOrder__content}>
          <div className={s.TransactionCardOrder__property}>
            <FormattedMessage id={'profile.walletOrderCost'} />
            <TransactionAmount value={price} colorize={false} />
          </div>
          <div className={s.TransactionCardOrder__property}>
            <FormattedMessage id={'profile.walletOrderCommission'} />
            <TransactionAmount value={-1 * commission} colorize={false} />
          </div>
        </div>
      )}
    </div>
  );
};
