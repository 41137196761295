import { MutationResult } from '@apollo/client';
import { UpdateUserMutation, UserDocument, useUpdateUserMutation } from '../../../../store/graphql';

type UseUpdateUserAvatarReturn = [(avatarAttachmentUid: string) => any, MutationResult<UpdateUserMutation>];

export const useUpdateUserAvatar = (): UseUpdateUserAvatarReturn => {
  const [fillUserMetadata, result] = useUpdateUserMutation({
    refetchQueries: [UserDocument]
  });

  const updateAvatar = async (avatarAttachmentUid: string) => {
    return await fillUserMetadata({
      variables: {
        input: {
          metadata: {
            avatarAttachmentUid
          }
        }
      }
    });
  };

  return [updateAvatar, result];
};
