import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { EBreakpoints, useBreakpoint } from 'common/hooks/useBreakpoint';
import { HeadingSize, Heading } from 'common/components/Heading/Heading';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { useSellerBalanceQuery } from 'store/graphql';
import { Grid } from 'common/components/Grid/Grid';
import { Button, ButtonFit } from 'common/components/Button';
import { Divider, EDividerVariant } from 'common/components/Divider/Divider';
import { LoaderBox } from 'common/components/Loader/LoaderBox';
import { Tabs, TabItem } from 'common/components/Tabs/Tabs';
import { URL_KEY_TAB } from 'store/urlKeys';
import { formatMoney } from 'common/utils/format';
import { EMDASH } from '@proscom/ui-utils';
import { useLocation } from 'react-router-dom';
import { WITHDRAW_ROUTE } from '../../../common/utils/routes';
import { Head } from '../../../common/components/Head';
import { useBatchedQueryParam } from '../../../common/hooks/queryParam/useBatchedQueryParam';
import { WalletRequests, WalletTransactions } from './components';
import s from './WalletPage.module.scss';

export enum EWalletTabs {
  TRANSACTIONS = 'transactions',
  REQUESTS = 'requests'
}

const tabs: TabItem[] = [
  {
    name: EWalletTabs.TRANSACTIONS,
    title: 'Transactions',
    intlId: 'profile.walletTransactions'
  },
  {
    name: EWalletTabs.REQUESTS,
    title: 'Requests',
    intlId: 'profile.walletRequests'
  }
];

export const WalletPage = () => {
  const { state } = useLocation();

  const [activeTabName, setActiveTabName] = useBatchedQueryParam<EWalletTabs>(URL_KEY_TAB, {
    defaultValue: state?.defaultTab ? state.defaultTab : EWalletTabs.TRANSACTIONS
  });
  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);

  const navigate = useNavigate();

  const { data, loading } = useSellerBalanceQuery();
  const balance = data?.balance?.total;

  const activeTab = useMemo(() => tabs.find((tab) => tab.name === activeTabName) as TabItem, [activeTabName]);

  return (
    <>
      <Head title={'My wallet'} />

      <Heading size={HeadingSize.H3}>
        <FormattedMessage id="profile.walletTitle" />
      </Heading>

      <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />

      {loading ? (
        <LoaderBox />
      ) : (
        <Grid cols={{ xs: 2, lg: 9 }}>
          <Grid.GridItem cols={{ xs: 1, lg: 3, xl: 2 }}>
            <div className={s.WalletPageContent__balance}>
              <span className={s.WalletPageContent__balanceText}>
                <FormattedMessage id="profile.walletBalance" />
              </span>

              <span className={s.WalletPageContent__balanceValue}>{balance ? formatMoney(balance) : EMDASH}</span>
            </div>

            <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />
          </Grid.GridItem>

          <Grid.GridItem cols={{ xs: 2, lg: 3, xl: 2 }} start={1}>
            <Button fit={ButtonFit.FILL} onClick={() => navigate(WITHDRAW_ROUTE)} disabled={!balance}>
              <FormattedMessage id="profile.walletWithdrawButton" />
            </Button>
          </Grid.GridItem>

          <Grid.GridItem>
            <Divider variant={EDividerVariant.LIGHT} space={ESpaceSize.PIXEL_24} />

            <Tabs
              className={s.WalletPageContent__tabs}
              items={tabs}
              activeItem={activeTab}
              onItemClick={(tab) => setActiveTabName(tab.name as EWalletTabs)}
            />

            {activeTab.name === EWalletTabs.TRANSACTIONS && <WalletTransactions isMobile={isMobile} />}
            {activeTab.name === EWalletTabs.REQUESTS && <WalletRequests isMobile={isMobile} />}
          </Grid.GridItem>
        </Grid>
      )}
    </>
  );
};
