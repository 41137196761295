import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const RequestCodeDocument = gql`
  mutation request_code($input: UserSignInInput!) {
    response: signInOrRegister(input: $input)
  }
`;

export interface RequestCodeVariables {
  email: string;
}

export async function requestCode(client: ApolloClient<NormalizedCacheObject>, variables: RequestCodeVariables) {
  return await client.mutate<{ response: string }>({
    mutation: RequestCodeDocument,
    variables: {
      input: variables
    }
  });
}
