import { LocalFileType, OrderYamatoShipment, YamatoShukaTimeEnum } from '../../../../../store/graphql';

export type YamatoCompactShipment =
  | (Omit<OrderYamatoShipment, 'id' | 'qrCode'> & {
      qrCode?: {
        main_file?: Pick<LocalFileType, 'url'> | null;
      } | null;
    })
  | null;

export type YamatoTimeInterval = {
  title: string;
  titleJp: string;
  value: YamatoShukaTimeEnum;
  limit: number;
};

export const yamatoIntervalMapping = {
  [YamatoShukaTimeEnum.NoSpecified]: 'Unspecified',
  [YamatoShukaTimeEnum.OnOne]: '8:00-13:00',
  [YamatoShukaTimeEnum.FromTwoToFour]: '14:00-16:00',
  [YamatoShukaTimeEnum.FromFourToSix]: '16:00-18:00',
  [YamatoShukaTimeEnum.FromFiveToHalfSix]: '17:00-18:30'
};

export const yamatoIntervalMappingJp = {
  [YamatoShukaTimeEnum.NoSpecified]: '未指定',
  [YamatoShukaTimeEnum.OnOne]: '08:00から13:00まで',
  [YamatoShukaTimeEnum.FromTwoToFour]: '14:00から16:00まで',
  [YamatoShukaTimeEnum.FromFourToSix]: '16:00から18:00まで',
  [YamatoShukaTimeEnum.FromFiveToHalfSix]: '17:00から18:30まで'
};

export const yamatoTimeIntervals: YamatoTimeInterval[] = [
  {
    title: yamatoIntervalMapping[YamatoShukaTimeEnum.NoSpecified],
    titleJp: yamatoIntervalMappingJp[YamatoShukaTimeEnum.NoSpecified],
    value: YamatoShukaTimeEnum.NoSpecified,
    limit: 17
  },
  {
    title: yamatoIntervalMapping[YamatoShukaTimeEnum.OnOne],
    titleJp: yamatoIntervalMappingJp[YamatoShukaTimeEnum.OnOne],
    value: YamatoShukaTimeEnum.OnOne,
    limit: 10
  },
  {
    title: yamatoIntervalMapping[YamatoShukaTimeEnum.FromTwoToFour],
    titleJp: yamatoIntervalMappingJp[YamatoShukaTimeEnum.FromTwoToFour],
    value: YamatoShukaTimeEnum.FromTwoToFour,
    limit: 14
  },
  {
    title: yamatoIntervalMapping[YamatoShukaTimeEnum.FromFourToSix],
    titleJp: yamatoIntervalMappingJp[YamatoShukaTimeEnum.FromFourToSix],
    value: YamatoShukaTimeEnum.FromFourToSix,
    limit: 16
  },
  {
    title: yamatoIntervalMapping[YamatoShukaTimeEnum.FromFiveToHalfSix],
    titleJp: yamatoIntervalMappingJp[YamatoShukaTimeEnum.FromFiveToHalfSix],
    value: YamatoShukaTimeEnum.FromFiveToHalfSix,
    limit: 17
  }
];
