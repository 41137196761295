import React from 'react';
import { useIntl } from 'react-intl';
import { ReactFCC } from '../../../../../../common/utils/helperTypes';
import { FormInput } from '../../../../../../common/components/inputs';
import { ESpaceSize } from '../../../../../../common/components/Space/Space';
import { AddressFormFieldsProps } from '../types';
import { AddressSchemaKeys } from '../../schema';

export const AddressFormFieldsJapan: ReactFCC<AddressFormFieldsProps> = (props) => {
  const { formik, address } = props;

  const intl = useIntl();

  return address ? (
    <>
      <FormInput
        name={AddressSchemaKeys.address}
        label={intl.formatMessage({ id: 'address.form.address' })}
        placeholder={intl.formatMessage({ id: 'address.form.address.placeholder' })}
        onChange={formik.handleChange}
        value={formik.values.address}
        error={formik.errors.address}
        space={ESpaceSize.PIXEL_12}
        disabled
      />
    </>
  ) : null;
};
