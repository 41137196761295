import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import s from './TrustPointsGrid.module.scss';

export interface TrustPointsGridProps {
  className?: string;
  children?: React.ReactNode;
}

export const TrustPointsGrid = ({ className, children }: TrustPointsGridProps) => {
  return <div className={clsx(s.TrustPointsGrid, className)}>{children}</div>;
};

export interface TrustPointsRecordRowProps {
  className?: string;
  head?: boolean;
  children?: React.ReactNode;
  onClick?: MouseEventHandler;
}

export const TrustPointsRecordRow = ({ className, head, children, onClick }: TrustPointsRecordRowProps) => {
  return (
    <div
      className={clsx(s.TrustPointsRow, className, {
        [s.TrustPointsRow_head]: head,
        [s.TrustPointsRow_clickable]: !!onClick
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export interface TrustPointsCellProps {
  className?: string;
  smallPadding?: boolean;
  children?: React.ReactNode;
}

export const TrustPointsCell = ({ className, smallPadding, children }: TrustPointsCellProps) => {
  return (
    <div
      className={clsx(s.TrustPointsCell, className, {
        [s.TrustPointsCell__smallPadding]: smallPadding
      })}
    >
      {children}
    </div>
  );
};
