import React from 'react';

import clsx from 'clsx';

import { ReactFCC } from '../../utils/helperTypes';
import s from './Space.module.scss';

export enum ESpaceSize {
  PIXEL_4 = 'pixel_4',
  PIXEL_6 = 'pixel_6',
  PIXEL_8 = 'pixel_8',
  PIXEL_12 = 'pixel_12',
  PIXEL_16 = 'pixel_16',
  PIXEL_24 = 'pixel_24',
  PIXEL_32 = 'pixel_32',
  PIXEL_40 = 'pixel_40',
  PIXEL_48 = 'pixel_48',
  PIXEL_56 = 'pixel_56',
  PIXEL_64 = 'pixel_64'
}

export type TSpace = {
  className?: string;
  size: ESpaceSize;
  vertical?: boolean;
  horizontal?: boolean;
};

export const Space: ReactFCC<TSpace> = (props) => {
  const { children, className, size, horizontal = true, vertical } = props;

  return (
    <div
      className={clsx(s.Space, className, s[`Space_size_${size}`], {
        [s.Space_horizontal]: horizontal,
        [s.Space_vertical]: vertical
      })}
    >
      {children}
    </div>
  );
};
