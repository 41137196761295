import { FC, PropsWithChildren } from 'react';
import { IMsFilesContext, MsFilesContext } from './MsFilesContext';

export const MsFilesProvider: FC<PropsWithChildren<IMsFilesContext>> = (props) => {
  const { children, client } = props;

  return (
    <MsFilesContext.Provider
      value={{
        client
      }}
    >
      {children}
    </MsFilesContext.Provider>
  );
};
