import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { BsThreeDots } from 'react-icons/bs';
import { ReactFCC } from '../../../../../common/utils/helperTypes';
import { ContextMenu, ContextMenuItem } from '../../../../../common/components/ContextMenu';
import s from './OrderContextMenu.module.scss';

export interface OrderContextMenuProps {
  /**
   * Функция передаваемая при нажатии на кнопку в контекстном меню
   */
  onClick: () => void;
}

export const OrderContextMenu: ReactFCC<OrderContextMenuProps> = (props) => {
  const intl = useIntl();

  const { onClick } = props;
  const contextMenuItems: ContextMenuItem[] = useMemo(
    () =>
      [
        {
          title: intl.formatMessage({ id: 'customerService.title' }),
          onClick
        }
      ].filter(Boolean) as ContextMenuItem[],
    [intl, onClick]
  );
  return (
    <>
      <ContextMenu
        className={s.OrderContextMenu__button}
        items={contextMenuItems}
        classes={{ item: s.OrderContextMenu__item, button: s.OrderContextMenu__button_self }}
        icon={BsThreeDots}
      />
    </>
  );
};
