import React from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { ICartGroup } from '../../pages/cart/types';
import { CartLengthQuery } from '../graphql';

export interface ICartContext {
  localCartGroups?: ICartGroup[];
  sellerId?: string;
  cartProductsLoading: boolean;
  cartLength?: number;
  addItemToCart: (productId: string) => Promise<any>;
  addItemToCartLoading: boolean;
  removeItemFromCart: (productId: string) => Promise<any>;
  removeItemFromCartLoading: boolean;
  updateCart: () => Promise<any>;
  setLocalProductIds: (productIds: string[] | undefined) => void;
  fetchCartLength: () => Promise<ApolloQueryResult<CartLengthQuery>>;
}

export const CartContext = React.createContext<ICartContext>({
  localCartGroups: undefined,
  sellerId: undefined,
  cartProductsLoading: false,
  cartLength: undefined,
  addItemToCart: async () => {},
  addItemToCartLoading: false,
  removeItemFromCart: async () => {},
  removeItemFromCartLoading: false,
  updateCart: async () => {},
  setLocalProductIds: () => {},
  fetchCartLength: () => ({} as any)
});
