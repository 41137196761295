import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactFCC } from 'common/utils/helperTypes';
import { Grid } from 'common/components/Grid/Grid';
import { Heading, HeadingSize } from 'common/components/Heading/Heading';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { TabItem, Tabs } from 'common/components/Tabs/Tabs';
import { URL_KEY_TAB } from 'store/urlKeys';
import { useBatchedQueryParam } from '../../../common/hooks/queryParam/useBatchedQueryParam';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { useUser } from '../../../entities/user';
import { LoaderBox } from '../../../common/components/Loader';
import { SellerVerificationEnum } from '../../../store/graphql';
import { CustomerOrders } from './CustomerOrders/CustomerOrders';
import { SellerOrders } from './SellerOrders/SellerOrders';
import s from './OrdersPage.module.scss';

enum EOrdersTabs {
  CUSTOMER = 'customerOrders',
  SELLER = 'sellerOrders'
}

const tabs: TabItem[] = [
  {
    name: EOrdersTabs.CUSTOMER,
    title: `Customers Orders`,
    intlId: 'profile.customerOrders'
  },
  {
    name: EOrdersTabs.SELLER,
    title: `Sellers orders`,
    intlId: 'profile.sellerOrders'
  }
];

const tabsContent: Record<EOrdersTabs, React.ReactNode> = {
  [EOrdersTabs.CUSTOMER]: <CustomerOrders />,
  [EOrdersTabs.SELLER]: <SellerOrders />
};

export const OrdersPage: ReactFCC = () => {
  const isMobile = useIsMobile();

  const { user } = useUser();

  const [activeTabName, setActiveTabName] = useBatchedQueryParam<EOrdersTabs>(URL_KEY_TAB, {
    defaultValue: EOrdersTabs.CUSTOMER
  });

  const activeTab = useMemo(() => tabs.find((tab) => tab.name === activeTabName) as TabItem, [activeTabName]);

  if (!user) {
    return <LoaderBox />;
  }

  return (
    <>
      <Grid className={s.OrderPageContent} cols={9}>
        <Grid.GridItem full>
          <Heading size={HeadingSize.H3}>
            <FormattedMessage id="profile.ordersTitle" />
          </Heading>

          <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />
        </Grid.GridItem>
        {user.sellerVerification === SellerVerificationEnum.Verified && (
          <Grid.GridItem full>
            <Tabs
              items={tabs}
              activeItem={activeTab}
              onItemClick={(tab) => setActiveTabName(tab.name as EOrdersTabs)}
            />
          </Grid.GridItem>
        )}
        <Grid.GridItem full>
          <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />
        </Grid.GridItem>
        <Grid.GridItem full>
          {user.sellerVerification === SellerVerificationEnum.Verified
            ? tabsContent[activeTab.name as EOrdersTabs]
            : tabsContent['customerOrders']}
        </Grid.GridItem>
      </Grid>
    </>
  );
};
