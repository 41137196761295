import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { ProductType } from '../../../../entities/product/types';
import { ProductShowcaseCard } from '../../../../entities/product';
import { ToggleFavoriteButton } from '../../../../features/product/favorite';
import { useIsDesktop } from '../../../../common/hooks/useIsDesktop';
import { useUser } from '../../../../entities/user';
import s from './ProductsGrid.module.scss';

export interface ProductsGridProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Число колонок с картчоками (по умолчанию 4)
   */
  cols?: number;
  /**
   * Массив товаров
   */
  products: ProductType[];
}

export const ProductsGrid: ReactFCC<ProductsGridProps> = (props) => {
  const { className, cols, products } = props;
  const { user } = useUser();

  const isDesktop = useIsDesktop();

  return (
    <div
      className={clsx(s.ProductsGrid, className)}
      style={{
        gridTemplateColumns: isDesktop && cols ? `repeat(${cols}, 1fr)` : undefined
      }}
    >
      {products.map((product) => (
        <ProductShowcaseCard
          className={s.ProductsGrid__card}
          product={product}
          key={product.id}
          after={
            product.sellerId !== user?.sellerId && (
              <ToggleFavoriteButton
                className={clsx(s.ProductsGrid__toggleFavoriteButton, {
                  [s.ProductsGrid__toggleFavoriteButton_active]: !!product.favorite
                })}
                productId={product.id}
                active={!!product.favorite}
              />
            )
          }
        />
      ))}
    </div>
  );
};
