import {
  LocalAttachmentType,
  LocalFileType,
  OcSpecificValueType,
  OrderContentProductConditionEnum,
  OrderContentProductKuronekoEnum,
  OrderContentProductStatusEnum,
  Product,
  ProductConditionEnum,
  ProductStatusEnum,
  SpecificValueExtended
} from '../../store/graphql';

export type ProductRequiredProps = 'id' | 'name' | 'price';
export type ProductType = Omit<
  Pick<Product, ProductRequiredProps> & Partial<Omit<Product, ProductRequiredProps>>,
  'status' | 'specificValues' | 'condition' | 'photos' | 'kuroneko' | 'mainPhoto' | '__typename'
> & {
  status?: ProductStatusEnum | OrderContentProductStatusEnum;
  specificValues?: (Partial<SpecificValueExtended> | Partial<OcSpecificValueType>)[] | null;
  condition?: ProductConditionEnum | OrderContentProductConditionEnum;
  photos?:
    | (Partial<Omit<LocalAttachmentType, 'thumbnails' | '__typename'>> & {
        thumbnails?: {
          M?: Partial<Omit<LocalFileType, '__typename'>> | null;
          S?: Partial<Omit<LocalFileType, '__typename'>> | null;
        } | null;
      })[]
    | null;
  mainPhoto?:
    | (Partial<Omit<LocalAttachmentType, 'thumbnails' | '__typename'>> & {
        thumbnails?: {
          M?: Partial<Omit<LocalFileType, '__typename'>> | null;
          S?: Partial<Omit<LocalFileType, '__typename'>> | null;
        } | null;
      })
    | null;
};

export const kuronekoPrices = (height: number) => {
  switch (height) {
    case 1:
      return 227;
    case 2:
      return 282;
    case 3:
      return 327;
    default:
      return 0;
  }
};

export const kuronekoValues = (height: number) => {
  switch (height) {
    case 1:
      return OrderContentProductKuronekoEnum.One;
    case 2:
      return OrderContentProductKuronekoEnum.Two;
    case 3:
      return OrderContentProductKuronekoEnum.Three;
    default:
      return null;
  }
};
