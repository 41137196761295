import React from 'react';
import { ReactFCC } from '../../../../common/utils/helperTypes';
import { useIsMobile } from '../../../../common/hooks/useIsMobile';
import { NavigationBaseProps } from './types';
import { NavigationDesktop, NavigationMobile } from './components';

export const Navigation: ReactFCC<NavigationBaseProps> = (props) => {
  const isMobile = useIsMobile();

  return !isMobile ? <NavigationDesktop {...props} /> : <NavigationMobile {...props} />;
};
