import { ReactFCC } from 'common/utils/helperTypes';
import { Form } from 'react-bootstrap';
import React, { useId } from 'react';
import clsx from 'clsx';
import { InputLayout, InputLayoutProps } from '../InputLayout/InputLayout';
import s from './FormCheckbox.module.scss';

export type FormCheckboxProps = {
  className?: string;
  classes?: {
    item?: string;
    checkbox?: string;
    label?: string;
  };
  label?: React.ReactNode;
  type?: 'checkbox' | 'radio';
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'style'> &
  Pick<InputLayoutProps, 'space' | 'error' | 'style' | 'help'>;

export const FormCheckbox: ReactFCC<FormCheckboxProps> = (props) => {
  const {
    className,
    classes,
    label,
    checked,
    onChange,
    space,
    error,
    disabled,
    style,
    name,
    type = 'checkbox',
    help,
    ...inputProps
  } = props;
  const id = useId();

  return (
    <InputLayout className={clsx(className)} space={space} error={error} style={style} help={help}>
      <Form.Check className={clsx(s.FormCheckbox__item, classes?.item)} type={type} id={id}>
        <Form.Check.Input
          className={clsx(s.FormCheckbox__input, classes?.checkbox)}
          name={name}
          type={type}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          {...inputProps}
        />

        <Form.Check.Label
          htmlFor={id}
          className={clsx(s.FormCheckbox__label, classes?.label, {
            [s.FormCheckbox__label_disabled]: disabled
          })}
        >
          {label}
        </Form.Check.Label>
      </Form.Check>
    </InputLayout>
  );
};
