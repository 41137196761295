import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Button, ButtonVariant } from '../../../../../../../common/components/Button';
import {
  AvailableLanguagesEnum,
  useCreateYamatoShipmentMutation,
  YamatoShukaTimeEnum
} from '../../../../../../../store/graphql';
import { getGraphqlErrorMessage } from '../../../../../../../common/utils/graphqlErrors';
import { handleDefaultError } from '../../../../../../../common/utils/handleDefaultError';
import { sellerOrderMutationOptions } from '../../../../SellerOrderPage';
import { Grid, GridGap } from '../../../../../../../common/components/Grid';
import { ESpaceSize, Space } from '../../../../../../../common/components/Space/Space';
import {
  getDateFromHoursString,
  getYamatoWeekDates,
  isAheadCurrentTime,
  isCurrentDay
} from '../../../../../../../common/utils/date';
import { YamatoCompactShipment, YamatoTimeInterval, yamatoTimeIntervals } from '../../types';
import { locales } from '../../../../../../../store/intl';
import { OrderCombinePickupDate } from '../../../OrderCombinePickupDate/OrderCombinePickupDate';
import s from './OrderCombineYamatoPickup.module.scss';

type YamatoPickupsProps = {
  orderId: string;
  sellerAddressId: string;
  yamatoShipment?: YamatoCompactShipment;
};

export const OrderCombineYamatoPickup = (props: YamatoPickupsProps) => {
  const { orderId, sellerAddressId, yamatoShipment } = props;
  const [createShipment, { loading: createShimpentLoading }] =
    useCreateYamatoShipmentMutation(sellerOrderMutationOptions);

  const [weekDates, setWeekDates] = useState<Date[]>([]);
  const [timeIntervals, setTimeIntervals] = useState<YamatoTimeInterval[]>([]);
  const [selectedDateId, setSelectedDateId] = useState<number | null>(null);
  const [selectedTimeInterval, setSelectedTimeInterval] = useState<YamatoShukaTimeEnum | null>();

  const handleNewPickupRequest = async () => {
    if (!selectedTimeInterval || selectedDateId === null) {
      handleDefaultError('Please choose pickup time');
      return;
    }
    try {
      await createShipment({
        variables: {
          input: {
            sellerAddressId,
            orderId,
            isPickup: true,
            shukaDate: weekDates[selectedDateId].toISOString(),
            shukaTimeInterval: selectedTimeInterval
          }
        }
      });
    } catch (e) {
      const errorMessage = getGraphqlErrorMessage(e);

      if (errorMessage) {
        handleDefaultError(errorMessage, e);
      }
    }
  };

  useEffect(() => {
    const dates = getYamatoWeekDates();
    setWeekDates(dates);
  }, []);

  useEffect(() => {
    let intervals = [];
    for (const interval of yamatoTimeIntervals) {
      if (selectedDateId !== null && weekDates.length && isCurrentDay(weekDates[selectedDateId])) {
        const currentDate = getDateFromHoursString(interval.limit);
        const isAheadTime = isAheadCurrentTime(currentDate);
        if (isAheadTime) continue;
      }
      intervals.push(interval);
    }
    setTimeIntervals(intervals);
  }, [selectedDateId, weekDates]);

  const intl = useIntl();

  return (
    <div className={s.OrderCombineYamatoPickup}>
      {!yamatoShipment ? (
        <>
          <span className={s.OrderCombineYamatoPickup__manual}>
            <FormattedMessage id={'orderCombine.yamatoNextPickup'} />
          </span>
          <Space size={ESpaceSize.PIXEL_8} />
          <FormattedMessage id={'orderCombine.selectDay'} />
          <Grid cols={{ xs: 2, md: 7, lg: 7 }} gap={GridGap.SMALL}>
            {weekDates.map((date, index) => (
              <Grid.GridItem cols={{ xs: 1, md: 1, lg: 1 }} key={index}>
                <OrderCombinePickupDate
                  value={format(date, 'dd-MM-yyyy')}
                  selected={selectedDateId === index}
                  onClick={() => setSelectedDateId(index)}
                />
              </Grid.GridItem>
            ))}
          </Grid>
          <Space size={ESpaceSize.PIXEL_8} />
          <FormattedMessage id={'orderCombine.selectTime'} />
          <Grid cols={{ xs: 2, md: 7, lg: 7 }} gap={GridGap.SMALL}>
            {timeIntervals.map((interval, index) => (
              <Grid.GridItem cols={{ xs: 1, md: 1, lg: 1 }} key={index}>
                <OrderCombinePickupDate
                  value={intl.locale === locales[AvailableLanguagesEnum.Japanese] ? interval.titleJp : interval.title}
                  selected={interval.value === selectedTimeInterval}
                  onClick={() => setSelectedTimeInterval(interval.value)}
                />
              </Grid.GridItem>
            ))}
          </Grid>
          <Space size={ESpaceSize.PIXEL_8} />
          <Button onClick={handleNewPickupRequest} loading={createShimpentLoading} variant={ButtonVariant.PRIMARY}>
            Create courier request
          </Button>
        </>
      ) : (
        <span className={s.OrderCombineYamatoPickup__manual}>
          <FormattedMessage id={'orderCombine.yamatoSuccessPickup'} />
        </span>
      )}
    </div>
  );
};
