import { create } from 'zustand';

interface ProductStore {
  commentsLimit: number;
  commentsLoading: boolean;
  setCommentsLimit: (limit: number) => void;
  setCommentsLoading: (loading: boolean) => void;
}

export const DEFAULT_COMMENTS_LIMIT = 10;

export const useProductStore = create<ProductStore>((set) => ({
  commentsLimit: DEFAULT_COMMENTS_LIMIT,
  commentsLoading: false,
  setCommentsLoading: (loading: boolean) => set({ commentsLoading: loading }),
  setCommentsLimit: (limit: number) => set({ commentsLimit: limit })
}));
