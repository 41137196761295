import React from 'react';
import { NotificationType } from '../graphql';

export interface INotificationsContext {
  unreadCount: number;
  importantUnreadCount: number;
  countLoading: boolean;
  markAsRead: (id: NotificationType['id']) => Promise<boolean>;
  markAllOrdinaryAsRead: () => Promise<boolean>;
}

export const NotificationsContext = React.createContext<INotificationsContext>({
  unreadCount: 0,
  importantUnreadCount: 0,
  countLoading: false,
  markAsRead: () => new Promise((res) => res(false)),
  markAllOrdinaryAsRead: () => new Promise((res) => res(false))
});
