import React, { Ref } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Heading, HeadingSize } from '../../../../../../common/components/Heading';

import s from './OrderCombinePrint.module.scss';

export type OrderPageCombinePrintProps = {
  className?: string;
  orderID: string;
  qrDataURL: string;
  decodedQr: string;
};

export const OrderPageCombinePrint = React.forwardRef((props: OrderPageCombinePrintProps, ref: Ref<HTMLDivElement>) => {
  const { className, orderID, qrDataURL, decodedQr } = props;

  const QrCodes = () => {
    return (
      <>
        {Array.from(Array(9), (_, i) => (
          <div key={i} className={s.OrderCombinePrint__content_item}>
            <Heading size={HeadingSize.H5}>Arisora</Heading>
            <Heading size={HeadingSize.H5}>
              <FormattedMessage id={'orderCombine.pdf.order'} /> {orderID}
            </Heading>
            <img src={qrDataURL} className={s.OrderCombinePrint__image} alt={decodedQr} />
          </div>
        ))}
      </>
    );
  };

  return (
    <div className={clsx(className, s.OrderCombinePrint)} ref={ref}>
      <div className={s.OrderCombinePrint__content}>
        <QrCodes />
      </div>
    </div>
  );
});
