import React from 'react';
import { useTicketMessagesCountQuery } from '../graphql';
import { useAuth } from '../../app/providers/auth-apollo';
import { TicketsContext } from './TicketsContext';

export const TicketsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  const {
    data: unreadTicketMessagesCountData,
    loading: unreadTicketMessagesLoading,
    refetch
  } = useTicketMessagesCountQuery({
    pollInterval: 60000,
    skip: !isAuthenticated
  });

  const unreadTicketMessagesCount = unreadTicketMessagesCountData?.count || 0;
  const countLoading = unreadTicketMessagesLoading;

  return (
    <TicketsContext.Provider
      value={{
        unreadCount: unreadTicketMessagesCount,
        recountMessages: refetch,
        countLoading
      }}
    >
      {children}
    </TicketsContext.Provider>
  );
};
