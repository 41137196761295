import React from 'react';
import clsx from 'clsx';
import { Loader, LoaderProps } from './Loader';
import s from './Loader.module.scss';

export interface LoaderBoxProps {
  className?: string;
  centered?: boolean;
  loaderProps?: LoaderProps;
}

export const LoaderBox: React.FC<LoaderBoxProps> = ({ className, centered = true, loaderProps }) => {
  return (
    <div
      className={clsx(s.LoaderBox, className, {
        [s.LoaderBox_centered]: centered
      })}
    >
      <Loader {...loaderProps} />
    </div>
  );
};
