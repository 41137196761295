import React, { Dispatch, Fragment, SetStateAction } from 'react';
import { FormattedDate } from 'common/components/CustomFormatters';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { PathBuilder } from '../../../../../common/utils/routes';
import { TrustPointsCell, TrustPointsGrid, TrustPointsRecordRow } from '../TrustPointsGrid';
import { Divider, EDividerVariant } from '../../../../../common/components/Divider/Divider';
import { Pagination } from '../../../../../common/components/Pagination';
import { ESpaceSize, Space } from '../../../../../common/components/Space/Space';
import { LoaderBox } from '../../../../../common/components/Loader';
import { SellerTrustPoints, TrustPoints } from '../../../../../store/graphql';
import { useIsMobile } from '../../../../../common/hooks/useIsMobile';
import { TrustPointsAmount } from './TrustPointsAmount/TrustPointsAmount';
import { TrustPointsItemLink } from './TrustPointsItemLink/TrustPointsItemLink';
import s from './TrustPointsRecords.module.scss';

export type TrustPointsRowProps = Pick<TrustPoints, 'pagination'> & {
  className?: string;
  loading?: boolean;
  trustPoints: Pick<SellerTrustPoints, 'commentary' | 'total' | 'amount' | 'orderID' | 'orderId' | 'createdAt'>[];
  setOffset: Dispatch<SetStateAction<number>>;
  offset: number;
};

export const TrustPointsRecords = ({
  className,
  loading,
  trustPoints,
  pagination,
  offset,
  setOffset
}: TrustPointsRowProps) => {
  const isMobile = useIsMobile();
  const intl = useIntl();

  return (
    <>
      {loading ? (
        <LoaderBox />
      ) : !isMobile ? (
        <TrustPointsGrid className={clsx(s.TrustPoints, className)}>
          <>
            <TrustPointsRecordRow className={s.TrustPointsRecords__row} head>
              <TrustPointsCell>
                <FormattedMessage id={'profile.trustPointsTable.date'} />
              </TrustPointsCell>
              <TrustPointsCell>
                <FormattedMessage id={'profile.trustPointsTable.time'} />
              </TrustPointsCell>
              <TrustPointsCell>
                <FormattedMessage id={'profile.trustPointsTable.order'} />
              </TrustPointsCell>
              <TrustPointsCell>
                <FormattedMessage id={'profile.trustPointsTable.comment'} />
              </TrustPointsCell>
              <TrustPointsCell smallPadding>
                <FormattedMessage id={'profile.trustPointsTable.amount'} />
              </TrustPointsCell>
              <TrustPointsCell smallPadding>
                <FormattedMessage id={'profile.trustPointsTable.total'} />
              </TrustPointsCell>
            </TrustPointsRecordRow>
            <Divider variant={EDividerVariant.LIGHT} />
          </>
          {trustPoints?.map((record, index) => (
            <TrustPointsRecordRow key={index} className={s.TrustPointsRecords__row}>
              <TrustPointsCell>
                <FormattedDate value={new Date(record.createdAt)} format={'yyyy.MM.dd'} />
              </TrustPointsCell>
              <TrustPointsCell>
                <FormattedDate value={new Date(record.createdAt)} format={'HH:mm'} />
              </TrustPointsCell>
              <TrustPointsCell>
                <TrustPointsItemLink to={PathBuilder.getOrderPath(record.orderId, true)}>
                  <FormattedMessage
                    id={'profile.trustPointsTable.order'}
                    children={(nodes) => (
                      <span>
                        {nodes} №{record.orderID}
                      </span>
                    )}
                  />
                </TrustPointsItemLink>
              </TrustPointsCell>
              <TrustPointsCell>{record.commentary}</TrustPointsCell>
              <TrustPointsCell smallPadding>
                <TrustPointsAmount value={record.amount} />
              </TrustPointsCell>
              <TrustPointsCell smallPadding>{record.total}</TrustPointsCell>
            </TrustPointsRecordRow>
          ))}
          <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />
        </TrustPointsGrid>
      ) : (
        <>
          {trustPoints?.map((record, index) => (
            <Fragment key={index}>
              <div className={clsx(s.TrustPointsRecordsMobile, className)}>
                <div className={s.TrustPointsRecordsMobileMain}>
                  <div className={s.TrustPointsRecordsMobileMain__content}>
                    <div className={s.TrustPointsRecordsMobileMain__info}>
                      <div className={s.TrustPointsRecordsMobileMain__infoHead}>
                        <div className={s.TrustPointsRecordsMobileMain__date}>
                          <FormattedDate value={new Date(record.createdAt)} format={'yyyy.MM.dd'} />{' '}
                          <FormattedDate value={new Date(record.createdAt)} format={'HH:mm'} />
                        </div>
                      </div>
                      <div className={s.TrustPointsRecordsMobileMain__text}>
                        <TrustPointsItemLink to={PathBuilder.getOrderPath(record.orderId, true)}>
                          Order №{record.orderID}
                        </TrustPointsItemLink>
                      </div>
                    </div>
                    <div className={s.TrustPointsRecordsMobileMain__amounts}>
                      <h5 className={s.TrustPointsRecordsMobileMain__diffAmount}>
                        <TrustPointsAmount value={record.amount} />
                      </h5>
                      {record.total}
                    </div>
                  </div>
                </div>
                <Space size={ESpaceSize.PIXEL_4} />
                {record.commentary}
              </div>
              <Divider space={ESpaceSize.PIXEL_16} />
            </Fragment>
          ))}
        </>
      )}
      <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />
      {pagination && (
        <Pagination
          limit={pagination.limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={pagination.totalCount}
          totalCountText={intl.formatMessage(
            { id: 'profile.trustPointsPaginationText' },
            { count: pagination.totalCount }
          )}
        />
      )}
    </>
  );
};
