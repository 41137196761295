import {
  UpdateUserMutationResult,
  User,
  UserCredentialsInput,
  UserDocument,
  useUpdateUserMutation
} from '../../../../store/graphql';

export interface UseUpdateUserReturn extends UpdateUserMutationResult {
  run: (input: UserCredentialsInput) => Promise<User>;
}

export const useUpdateUser = () => {
  const [fillUserMetadataFieldsMutation, mutationResult] = useUpdateUserMutation({
    refetchQueries: [UserDocument]
  });

  const mutate = async (input: UserCredentialsInput) => {
    const result = await fillUserMetadataFieldsMutation({
      variables: {
        input
      }
    });
    return result?.data?.user;
  };

  return {
    mutate,
    ...mutationResult
  };
};
