import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';

import { toast } from 'react-toastify';
import { PhoneVerification } from '../../../../../pages/profile/verification/components/PhoneVerification';
import { ReactFCC } from '../../../../../common/utils/helperTypes';
import { Modal } from '../../../../../common/components/Modal';
import { ESpaceSize, Space } from '../../../../../common/components/Space/Space';

export interface PhoneVerificationModalProps {
  /**
   * Состояние открытого/закрытого модального окна
   */
  isOpen: boolean;
  /**
   * Действие при закрытии модального окна
   */
  onClose: () => void;
  /**
   * Опциональный дополнительный класс на компонент
   */
  className?: string;
}

export const PhoneVerificationModal: ReactFCC<PhoneVerificationModalProps> = (props: PhoneVerificationModalProps) => {
  const { isOpen, onClose, className } = props;

  const [isNumberVerified, setNumberVerified] = useState(false);

  const intl = useIntl();

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const pullNumberVerified = (isVerified: boolean) => {
    setNumberVerified(isVerified);
  };

  useEffect(() => {
    if (isNumberVerified) {
      closeModal();
      toast.success(intl.formatMessage({ id: 'phoneVerification.modalSuccess' }));
    }
  }, [intl, isNumberVerified, closeModal]);

  return (
    <Modal
      className={clsx(className)}
      title={intl.formatMessage({ id: 'phoneVerification.title' })}
      isOpen={isOpen}
      onClose={closeModal}
    >
      <Modal.Body>
        <FormattedMessage id="phoneVerification.modalText" />
        <Space size={ESpaceSize.PIXEL_12} />
        <PhoneVerification pullNumberVerified={pullNumberVerified} resendTimeout={60} />
      </Modal.Body>
    </Modal>
  );
};
