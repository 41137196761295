import React from 'react';
import { BsBook, BsBriefcase, BsExclamationCircle } from 'react-icons/bs';
import { FormattedMessage } from 'react-intl';
import {
  ABOUT_ROUTE,
  ADDITIONAL_SERVICE_ROUTE,
  CALCULATOR_ROUTE,
  COMMISSION_ROUTE,
  COMPANY_ROUTE,
  CONTACT_ROUTE,
  COOKIES_ROUTE,
  DELIVERY_PROBLEMS_ROUTE,
  DELIVERY_ROUTE,
  HELP_ROUTE,
  LAW_ROUTE,
  MANUALS_ROUTE,
  PAYMENT_ROUTE,
  PRIVACY_ROUTE,
  PROHIBITED_ITEMS_ROUTE,
  PURCHASE_ISSUES_ROUTE,
  REFUNDS_ROUTE,
  SELLER_IDENTITY_ROUTE,
  SIZE_LIMIT_ROUTE,
  SUPPORT_HELP_ROUTE,
  TERMS_ROUTE,
  TESTIMONIALS_ROUTE,
  WITHDRAW_HELP_ROUTE
} from '../../common/utils/routes';
import { Heading, HeadingSize } from '../../common/components/Heading';
import { ESpaceSize, Space } from '../../common/components/Space/Space';
import { SupportQuestionAnswer } from './SupportQuestionAnswer/SupportQuestionAnswer';
import { SupportCard } from './SupportCard/SupportCard';
import s from './SupportHub.module.scss';

export const SupportHub = () => {
  return (
    <div className={s.SupportHub}>
      <div className={s.SupportHub__content}>
        <div className={s.SupportHub__navButtons}>
          <SupportCard
            headingId={'footer.aboutUs'}
            link={ABOUT_ROUTE}
            icon={<BsBriefcase className={s.SupportCard__badge_icon} size={25} />}
            items={[
              {
                titleId: 'footer.linkAboutCompany',
                link: COMPANY_ROUTE
              },
              {
                titleId: 'footer.linkTermsOfUse',
                link: TERMS_ROUTE
              },
              {
                titleId: 'footer.linkPrivacyPolicy',
                link: PRIVACY_ROUTE
              },
              {
                titleId: 'footer.linkLaw',
                link: LAW_ROUTE
              },
              {
                titleId: 'footer.linkCookies',
                link: COOKIES_ROUTE
              },
              {
                titleId: 'footer.linkReviews',
                link: TESTIMONIALS_ROUTE
              },
              {
                titleId: 'footer.linkContacts',
                link: CONTACT_ROUTE
              }
            ]}
          />
          <SupportCard
            headingId={'footer.userManual'}
            link={MANUALS_ROUTE}
            icon={<BsBook className={s.SupportCard__badge_icon} size={25} />}
            items={[
              {
                titleId: 'footer.linkPaymentMethods',
                link: PAYMENT_ROUTE
              },
              {
                titleId: 'footer.linkCommissions',
                link: COMMISSION_ROUTE
              },
              {
                titleId: 'footer.linkRefunds',
                link: REFUNDS_ROUTE
              },
              {
                titleId: 'footer.linkDelivery',
                link: DELIVERY_ROUTE
              },
              {
                titleId: 'footer.linkShipping',
                link: CALCULATOR_ROUTE
              },
              {
                titleId: 'footer.linkProhibited',
                link: PROHIBITED_ITEMS_ROUTE
              },
              {
                titleId: 'footer.linkLimit',
                link: SIZE_LIMIT_ROUTE
              }
            ]}
          />
          <SupportCard
            headingId={'footer.help'}
            link={HELP_ROUTE}
            icon={<BsExclamationCircle className={s.SupportCard__badge_icon} size={25} />}
            items={[
              {
                titleId: 'footer.linkSupport',
                link: SUPPORT_HELP_ROUTE
              },
              {
                titleId: 'footer.linkAdditionalServices',
                link: ADDITIONAL_SERVICE_ROUTE
              },
              {
                titleId: 'footer.linkDeliveryProblems',
                link: DELIVERY_PROBLEMS_ROUTE
              },
              {
                titleId: 'footer.linkDelivery',
                link: DELIVERY_ROUTE
              },
              {
                titleId: 'footer.linkProductProblems',
                link: PURCHASE_ISSUES_ROUTE
              },
              {
                titleId: 'footer.linkSellerIdentification',
                link: SELLER_IDENTITY_ROUTE
              },
              {
                titleId: 'footer.linkWithdrawal',
                link: WITHDRAW_HELP_ROUTE
              }
            ]}
          />
        </div>
        <Space size={ESpaceSize.PIXEL_24} />
        <div className={s.SupportHub__faq}>
          <Heading size={HeadingSize.H5}>
            <FormattedMessage id={'footer.linkFaq'} />
          </Heading>
          <SupportQuestionAnswer
            question={'How can I buy any item if I have no money in my bag?'}
            answer={'Unfortunately we cannot help you with that, please earn some money'}
          />
          <SupportQuestionAnswer
            question={'How can I buy any item if I have no money in my bag?'}
            answer={'Unfortunately we cannot help you with that, please earn some money'}
          />
          <SupportQuestionAnswer
            question={'How can I buy any item if I have no money in my bag?'}
            answer={'Unfortunately we cannot help you with that, please earn some money'}
          />
          <SupportQuestionAnswer
            question={'How can I buy any item if I have no money in my bag?'}
            answer={'Unfortunately we cannot help you with that, please earn some money'}
          />
          <SupportQuestionAnswer
            question={'How can I buy any item if I have no money in my bag?'}
            answer={'Unfortunately we cannot help you with that, please earn some money'}
          />
          <SupportQuestionAnswer
            question={'How can I buy any item if I have no money in my bag?'}
            answer={'Unfortunately we cannot help you with that, please earn some money'}
          />
        </div>
      </div>
    </div>
  );
};
