import { Navigate, Outlet, RouteObject, useLocation } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';
import { useUi } from '../../store/ui';
import { ProfileRoutes } from '../../pages/profile';
import { HOME_ROUTE, NOTIFICATIONS_ROUTE, ORDERING_ROUTE, PROFILE_ROUTE } from '../../common/utils/routes';
import { useAuth } from '../providers/auth-apollo';
import { OrderingPage } from '../../pages/ordering/OrderingPage';
import { NotificationsPage } from '../../pages/notifications/NotificationsPage';

function AuthLayout() {
  const { toggleAuthModal } = useUi();
  const { isAuthenticated } = useAuth();

  const { pathname } = useLocation();

  const mountedRef = useRef(false);
  useEffect(() => {
    if (!isAuthenticated && !mountedRef.current) {
      toggleAuthModal(true);
    }
    mountedRef.current = true;
  }, [isAuthenticated, toggleAuthModal]);

  return isAuthenticated ? (
    <Outlet />
  ) : !mountedRef.current ? (
    <Navigate to={`${HOME_ROUTE}?next=${pathname}`} />
  ) : (
    <Navigate to={HOME_ROUTE} />
  );
}

export const protectedRoutes: RouteObject[] = [
  {
    element: <AuthLayout />,
    children: [
      {
        path: `${PROFILE_ROUTE}/*`,
        element: <ProfileRoutes />
      },
      {
        path: ORDERING_ROUTE,
        element: <OrderingPage />
      },
      {
        path: NOTIFICATIONS_ROUTE,
        element: <NotificationsPage />
      }
    ]
  }
];
