import { WEBVIEW_METHOD_GET_FCM_TOKEN } from '../../../../common/constants/webview';
import { useWebview } from '../../../../store/webview/useWebview';
import { useAddPushTokenToUserMutation } from '../../../../store/graphql';

export function useWebviewFCMToken() {
  const { callWebviewMethod, isWebview } = useWebview();
  const [addPushTokenUserMutation] = useAddPushTokenToUserMutation();
  const registerFCMToken = () => {
    if (!isWebview) return;
    callWebviewMethod(WEBVIEW_METHOD_GET_FCM_TOKEN).then((token) => {
      if (!token) return;
      addPushTokenUserMutation({
        variables: {
          input: { token: token as string }
        }
      }).catch((err) => {
        console.log('addPushTokenUserMutation error', err);
      });
    });
  };

  return {
    registerFCMToken
  };
}
