import React from 'react';
import clsx from 'clsx';
import { ReactFCC } from '../../../../../common/utils/helperTypes';

import s from './OrderPageBanner.module.scss';

export type TOrderPageBannerText = {
  className?: string;
};

export const OrderPageBannerText: ReactFCC<TOrderPageBannerText> = (props) => {
  const { children, className } = props;

  return <div className={clsx(s.OrderPageBanner__text, className)}>{children}</div>;
};
