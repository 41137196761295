import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import React from 'react';
import s from './Progress.module.scss';

export interface ProgressProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Дочерний компонент прогресса
   */
  children?: React.ReactNode;
}

export const Progress: ReactFCC<ProgressProps> = (props) => {
  const { className, children } = props;
  return <div className={clsx(s.Progress, className)}>{children}</div>;
};
