import React from 'react';
import { RequestType } from '../WalletTransactions/types';
import { RequestRow } from './RequestRow/RequestRow';
import { RequestCard } from './RequestCard/RequestCard';

export interface WalletRequestProps extends RequestType {
  rowClassName?: string;
  isMobile: boolean;
}

export const WalletRequest = ({ isMobile, rowClassName, ...requestProps }: WalletRequestProps) => {
  return (
    <>{isMobile ? <RequestCard {...requestProps} /> : <RequestRow className={rowClassName} {...requestProps} />}</>
  );
};
