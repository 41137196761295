import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import React, { useCallback, useMemo, useRef } from 'react';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { useIntl } from 'react-intl';
import { UpdateUserAvatarModal } from '../../../features/user/updateAvatar';
import { DeleteUserAvatarModal } from '../../../features/user/deleteAvatar';
import { User, UserAvatar, UserInfo } from '../../../entities/user';
import { useToggle } from '../../../common/hooks/useToggle';
import { ContextMenuContent, ContextMenuItem } from '../../../common/components/ContextMenu';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { useIsDesktop } from '../../../common/hooks/useIsDesktop';
import s from './UserCard.module.scss';

export interface UserCardProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Объект пользователя из API
   */
  user: User | null;
  /**
   * Возможность редактировать аватар пользователя
   */
  isAvatarUpdated?: boolean;
  /**
   * Флаг если аватар нужно отрисовать на внутренней странице пользователя
   */
  isOwnPage?: boolean;
}

export const UserCard: ReactFCC<UserCardProps> = (props) => {
  const { className, user, isAvatarUpdated, isOwnPage } = props;

  const [updateAvatarModalOpen, { set: openUpdateAvatarModal, unset: closeUpdateAvatarModal }] = useToggle(false);
  const [deleteAvatarModalOpen, { set: openDeleteAvatarModal, unset: closeDeleteAvatarModal }] = useToggle(false);

  const [contextMenuOpen, { unset: closeContextMenu, toggle: toggleContextMenu }] = useToggle(false);

  const referenceRef = useRef<HTMLDivElement>(null);

  const isAvatarExisted = !!user?.avatar?.thumbnails?.M;

  const intl = useIntl();

  const onClickAvatar = useCallback(() => {
    if (!isAvatarUpdated) {
      return;
    }

    if (isAvatarExisted) {
      toggleContextMenu();
    } else {
      openUpdateAvatarModal();
    }
  }, [isAvatarExisted, isAvatarUpdated, openUpdateAvatarModal, toggleContextMenu]);

  const contextMenuItems: ContextMenuItem[] = useMemo(
    () => [
      {
        title: 'Change the photo',
        icon: BsPencil,
        onClick: openUpdateAvatarModal
      },
      {
        title: 'Delete the photo',
        danger: true,
        icon: BsTrash,
        onClick: openDeleteAvatarModal
      }
    ],
    [openDeleteAvatarModal, openUpdateAvatarModal]
  );

  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();

  return (
    <>
      {isMobile && (
        <div className={clsx(s.UserCard, className)}>
          <div className={s.UserCard__mobileFiller}></div>
          <div className={s.UserCard__content}>
            <div className={s.UserCard__contentItems}>
              <UserAvatar
                src={user?.avatar?.thumbnails?.M?.url ?? undefined}
                placeholderText={isAvatarUpdated ? intl.formatMessage({ id: 'profile.uploadAvatar' }) : undefined}
                onClick={isAvatarUpdated ? onClickAvatar : undefined}
                innerRef={referenceRef}
                className={s.UserCard__mobileAvatar}
                isTrusted={user?.seller?.isTrusted}
                isSeller={user?.sellerVerification === 'Verified'}
                isOwnPage={isOwnPage}
              />
              <UserInfo user={user} className={s.UserCard__infoMobile} />
            </div>
          </div>
        </div>
      )}

      {isDesktop && (
        <div className={clsx(s.UserCard, className)}>
          <UserAvatar
            src={user?.avatar?.thumbnails?.M?.url ?? undefined}
            placeholderText={isAvatarUpdated ? intl.formatMessage({ id: 'profile.uploadAvatar' }) : undefined}
            onClick={isAvatarUpdated ? onClickAvatar : undefined}
            innerRef={referenceRef}
            isTrusted={user?.seller?.isTrusted}
            isSeller={user?.sellerVerification === 'Verified'}
            isOwnPage={isOwnPage}
          />
          <UserInfo user={user} />
        </div>
      )}

      {isAvatarUpdated && (
        <>
          <UpdateUserAvatarModal isOpen={updateAvatarModalOpen} onClose={closeUpdateAvatarModal} />
          <DeleteUserAvatarModal isOpen={deleteAvatarModalOpen} onClose={closeDeleteAvatarModal} />

          {isAvatarExisted && (
            <ContextMenuContent
              classes={{
                dropdown: s.UserCard__contextMenuContextDropdown
              }}
              referenceRef={referenceRef}
              items={contextMenuItems}
              isOpen={contextMenuOpen}
              onClose={closeContextMenu}
              offset={[isMobile ? -40 : -60, -16]}
            />
          )}
        </>
      )}
    </>
  );
};
