import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useCatalogNavigationQuery } from 'store/graphql';
import {
  CATEGORY_PAGE_PARAM,
  SUB_CATEGORY_PAGE_PARAM,
  SUB_SUB_CATEGORY_PAGE_PARAM
} from '../../../common/utils/routes';

export const useNavigation = () => {
  const { data, loading } = useCatalogNavigationQuery();
  const {
    [CATEGORY_PAGE_PARAM]: categoryId,
    [SUB_CATEGORY_PAGE_PARAM]: subCategoryId,
    [SUB_SUB_CATEGORY_PAGE_PARAM]: subSubCategoryId
  } = useParams();

  const categories = data?.categories;
  const category = useMemo(() => categories?.find((i) => i.id === categoryId), [categories, categoryId]);

  const subCategories = data?.subCategories.filter((i) => category?.subCategoryIds.includes(i.id));
  const subCategory = useMemo(() => subCategories?.find((i) => i.id === subCategoryId), [subCategories, subCategoryId]);

  const subSubCategories = data?.subSubCategories.filter((i) => subCategory?.subSubCategoryIds?.includes(i.id));
  const subSubCategory = useMemo(
    () => subSubCategories?.find((i) => i.id === subSubCategoryId),
    [subSubCategories, subSubCategoryId]
  );

  return {
    categories,
    subCategories,
    subSubCategories,
    category,
    subCategory,
    subSubCategory,
    navigationLoading: loading
  };
};
