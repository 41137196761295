import React, { ComponentPropsWithoutRef, ElementType, JSX } from 'react';
import clsx from 'clsx';
import { PolyExtends } from '../../utils/helperTypes';
import s from './Anchor.module.scss';

export enum AnchorVariant {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  BODY = 'secondary_black',
  DANGER = 'danger'
}

export interface AnchorIconOptions {
  size?: number;
}

export const AnchorDefaultComponent = 'a' as const;
export type AnchorDefaultComponentType = typeof AnchorDefaultComponent;

export interface AnchorPropsSelf<ComponentType extends ElementType = AnchorDefaultComponentType> {
  className?: string;

  classes?: {
    root?: string;
    leftIcon?: string;
    rightIcon?: string;
  };

  variant?: AnchorVariant;

  leftIcon?: React.ElementType;

  leftIconOptions?: AnchorIconOptions;

  rightIcon?: React.ElementType;

  rightIconOptions?: AnchorIconOptions;

  disabled?: boolean;

  onClick?: (e: React.MouseEvent) => void;

  innerRef?: React.ComponentProps<ComponentType>['ref'];

  noStyle?: boolean;

  inline?: boolean;
}

export type AnchorProps<ComponentType extends ElementType = AnchorDefaultComponentType> = PolyExtends<
  ComponentType,
  AnchorPropsSelf<ComponentType>,
  ComponentPropsWithoutRef<ComponentType>
>;

export function Anchor(props: AnchorProps<'a'>): JSX.Element;
export function Anchor<ComponentType extends ElementType>(props: AnchorProps<ComponentType>): JSX.Element;
export function Anchor<ComponentType extends ElementType = AnchorDefaultComponentType>(
  props: AnchorProps<ComponentType>
) {
  const {
    children,
    component,
    className,
    variant = AnchorVariant.PRIMARY,
    leftIcon: LeftIcon,
    rightIcon: RightIcon,
    leftIconOptions,
    rightIconOptions,
    classes,
    disabled,
    innerRef,
    noStyle,
    inline,
    ...rest
  } = props;

  const Component = component || AnchorDefaultComponent;

  return (
    <Component
      tabIndex={0}
      {...rest}
      className={clsx(
        ...(!noStyle
          ? [
              s.Anchor,
              s[`Anchor_variant_${variant}`],
              {
                [s.Anchor_disabled]: disabled,
                [s.Anchor_button]: component === 'button',
                [s.Anchor_inline]: inline
              }
            ]
          : []),
        className,
        classes?.root
      )}
      ref={innerRef}
    >
      {LeftIcon && (
        <LeftIcon
          className={clsx(s.Anchor__icon, classes?.leftIcon)}
          style={{
            width: leftIconOptions?.size && `${leftIconOptions.size}px`,
            height: leftIconOptions?.size && `${leftIconOptions.size}px`
          }}
        />
      )}

      {children}

      {RightIcon && (
        <RightIcon
          className={clsx(s.Anchor__icon, classes?.rightIcon)}
          style={{
            width: rightIconOptions?.size && `${rightIconOptions.size}px`,
            height: rightIconOptions?.size && `${rightIconOptions.size}px`
          }}
        />
      )}
    </Component>
  );
}
