import React from 'react';
import { Link } from '../../../common/components/Link';
import { HOME_ROUTE } from '../../../common/utils/routes';
import { useAuth } from '../../../app/providers/auth-apollo';
import s from './NotificationsEmpty.module.scss';

export const NotificationsEmpty: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <div className={s.NotificationsEmpty}>
      <h5 className={s.NotificationsEmpty__title}>There are no notifications</h5>
      {isAuthenticated ? (
        <span className={s.NotificationsEmpty__text}>
          Start with the <Link to={HOME_ROUTE}>homepage</Link> or use the search to find something specific
        </span>
      ) : (
        <span className={s.NotificationsEmpty__text}>
          Start with the <Link to={HOME_ROUTE}>homepage</Link> or use the search to find something specific
        </span>
      )}
    </div>
  );
};
