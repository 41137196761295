import React, { useEffect, useLayoutEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useCart } from '../../store/cart';
import { ReactFCC } from '../../common/utils/helperTypes';
import { HeadingSize, Heading } from '../../common/components/Heading';
import { ESpaceSize, Space } from '../../common/components/Space/Space';
import { GoogleTagEvents, googleTagSendDefaultEvent } from '../../features/analytics';
import { Head } from '../../common/components/Head';
import { useAuth } from '../../app/providers/auth-apollo';
import { CartContentAuthorized } from './content/authorized/CartContentAuthorized';
import { CartContentLocal } from './content/local/CartContentLocal';
import s from './CartPage.module.scss';

export const CartPage: ReactFCC = () => {
  const { isAuthenticated } = useAuth();

  const {
    cartLength,
    cartProductsLoading,
    localCartGroups,
    removeItemFromCart,
    removeItemFromCartLoading,
    fetchCartLength
  } = useCart();

  // рефетчим размер корзины при заходе в корзину
  useLayoutEffect(() => {
    fetchCartLength();
  }, [fetchCartLength]);

  /** Sentry events Sentry.captureEvent({
    message: 'ChartPage render',
    breadcrumbs: [
      {
        category: 'info',
        message: 'ChartPage info',
        data: {
          isLoggedIn: isAuthenticated,
          cartLength,
          cartProductsLoading,
          localCartGroups
        }
      }
    ]
  }); **/

  useEffect(() => {
    googleTagSendDefaultEvent(GoogleTagEvents.view_cart);
  }, []);

  return (
    <>
      <Head title={'Cart'} />

      <div className={s.CartPage}>
        <Heading size={HeadingSize.H3}>
          <FormattedMessage id="cart.title" /> ({cartLength || 0})
        </Heading>

        <Space size={ESpaceSize.PIXEL_24} />

        <div className={s.CartPage__content}>
          {isAuthenticated ? (
            <CartContentAuthorized
              removeItemFromCart={removeItemFromCart}
              removeItemFromCartLoading={removeItemFromCartLoading}
            />
          ) : (
            <CartContentLocal
              localCartGroups={localCartGroups}
              cartProductsLoading={cartProductsLoading}
              removeItemFromCart={removeItemFromCart}
              removeItemFromCartLoading={removeItemFromCartLoading}
            />
          )}
        </div>
      </div>
    </>
  );
};
