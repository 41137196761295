import { useLocation } from 'react-router-dom';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { MANUALS_ROUTE } from '../../../common/utils/routes';

export const PaymentPage = () => {
  const isMobile = useIsMobile();
  const location = useLocation();

  if (isMobile && location.pathname === MANUALS_ROUTE) {
    return (
      <>
        <Space size={ESpaceSize.PIXEL_24} />
      </>
    );
  }
  return <>Under development</>;
};
