import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { BsArrowLeftShort, BsChevronRight, BsX } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import { NavigationBaseProps } from '../../types';
import { useNavigationItems } from '../../hooks/useNavigationItems';
import { Modal } from '../../../../../../common/components/Modal';
import { Link } from '../../../../../../common/components/Link';
import { Anchor, AnchorVariant } from '../../../../../../common/components/Anchor';
import { PathBuilder } from '../../../../../../common/utils/routes';
import { ReactFCC } from '../../../../../../common/utils/helperTypes';
import s from './NavigationMobile.module.scss';

export const NavigationMobile: ReactFCC<NavigationBaseProps> = (props) => {
  const { className, open, onClose, onKeyDown, searchRef } = props;
  const { search } = useLocation();

  let restParams: string | undefined = undefined;
  if (search.includes('?q')) {
    if (search.includes('&')) {
      restParams = search.substring(search.indexOf('&') + 1);
    }
  } else if (search.includes('?') && !search.includes('?q')) {
    restParams = search.substring(search.indexOf('?') + 1);
  }

  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [subCategoryId, setSubCategoryId] = useState<string | null>(null);

  const categories = useNavigationItems();
  const subCategories = categories?.find(({ id }) => id === categoryId)?.subCategories;
  const subSubCategories = subCategories?.find(({ id }) => id === subCategoryId)?.subSubCategories;

  const activeCategory = useMemo(() => categories?.find((i) => i.id === categoryId), [categories, categoryId]);
  const activeSubCategory = useMemo(
    () => subCategories?.find((i) => i.id === subCategoryId),
    [subCategories, subCategoryId]
  );

  useEffect(() => {
    const input = searchRef?.current;
    if (open && input) {
      input.focus();
    }
  }, [searchRef, open]);

  return (
    <Modal className={className} isOpen={open} hideHeader>
      <Modal.Body>
        <div className={s.NavigationMobile__header}>
          <BsX className={s.NavigationMobile__close} onClick={onClose} />
          <Form action="" onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              type="search"
              placeholder="Search"
              aria-label="Search"
              ref={searchRef}
              onKeyDown={onKeyDown}
            />
          </Form>
        </div>
        {activeSubCategory ? (
          <Anchor
            component={'button'}
            variant={AnchorVariant.BODY}
            className={s.NavigationMobile__back}
            onClick={() => setSubCategoryId(null)}
          >
            <BsArrowLeftShort />
            <span>{activeSubCategory.name}</span>
          </Anchor>
        ) : (
          activeCategory && (
            <Anchor
              component={'button'}
              variant={AnchorVariant.BODY}
              className={s.NavigationMobile__back}
              onClick={() => setCategoryId(null)}
            >
              <BsArrowLeftShort />
              <span>{activeCategory.name}</span>
            </Anchor>
          )
        )}

        {activeCategory ? (
          activeSubCategory ? (
            <>
              <Link
                to={PathBuilder.getCatalogPath({
                  categoryId: activeCategory.id,
                  subCategoryId: activeSubCategory.id,
                  searchQuery: searchRef?.current?.value,
                  restParams
                })}
                className={s.NavigationMobile__item}
                onClick={onClose}
              >
                <span>All</span>
                <BsChevronRight />
              </Link>

              {subSubCategories?.map(({ id, name }, index) => (
                <Link
                  to={PathBuilder.getCatalogPath({
                    categoryId: activeCategory.id,
                    subCategoryId: activeSubCategory.id,
                    subSubCategoryId: id,
                    searchQuery: searchRef?.current?.value,
                    restParams
                  })}
                  className={s.NavigationMobile__item}
                  onClick={onClose}
                  key={index}
                >
                  <span>{name}</span>
                  <BsChevronRight />
                </Link>
              ))}
            </>
          ) : (
            <>
              <Link
                to={PathBuilder.getCatalogPath({
                  categoryId: activeCategory.id,
                  searchQuery: searchRef?.current?.value,
                  restParams
                })}
                className={s.NavigationMobile__item}
                onClick={onClose}
              >
                <span>All</span>
                <BsChevronRight />
              </Link>

              {subCategories?.map(({ id, name }, index) => (
                <Anchor
                  component={'div'}
                  className={s.NavigationMobile__item}
                  onClick={() => setSubCategoryId(id)}
                  key={index}
                >
                  <span>{name}</span>
                  <BsChevronRight />
                </Anchor>
              ))}
            </>
          )
        ) : (
          categories?.map(({ name, id }, index) => (
            <Anchor
              component={'div'}
              className={s.NavigationMobile__item}
              onClick={() => setCategoryId(id)}
              key={index}
            >
              <span>{name}</span>
              <BsChevronRight />
            </Anchor>
          ))
        )}
      </Modal.Body>
    </Modal>
  );
};
