import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { SellerVerificationEnum } from 'store/graphql';
import { Anchor } from 'common/components/Anchor/Anchor';
import { LoaderBox } from 'common/components/Loader';
import { Badge, BadgeVariant } from 'common/components/Badge/Badge';
import { ReactFCC } from 'common/utils/helperTypes';
import { isEnterKey } from 'common/utils/isKey';

import { useAuth } from '../../../../../app/providers/auth-apollo';
import { useUser } from '../../../../../entities/user';
import { Button } from '../../../../../common/components/Button';
import s from './VerificationBanner.module.scss';

export interface VerificationBannerProps {
  className?: string;
  onClickVerify?: () => void;
}

export const VerificationBanner: ReactFCC<VerificationBannerProps> = (props) => {
  const { className, onClickVerify } = props;

  const { loading } = useAuth();
  const { user } = useUser();

  const verification = user?.sellerVerification;

  return (
    <div className={clsx(s.VerificationBanner, className)}>
      <h5 className={s.VerificationBanner__heading}>
        <FormattedMessage id="profile.verificationTitle" />
      </h5>

      {loading ? (
        <LoaderBox centered={false} />
      ) : (
        <>
          {!verification && (
            <>
              <p className={s.VerificationBanner__text}>
                <FormattedMessage id="profile.verificationDefaultText" values={{ br: () => <br /> }} />
              </p>

              <Button onClick={onClickVerify} className={s.VerificationBanner__button}>
                <FormattedMessage id="profile.passVerificationButton" />
              </Button>
            </>
          )}

          {verification === SellerVerificationEnum.UnderReview && (
            <>
              <Badge className={s.VerificationBanner__badge}>
                <FormattedMessage id="profile.verificationStatusReview" />
              </Badge>

              <p className={s.VerificationBanner__text}>
                <FormattedMessage id="profile.verificationReviewText" />
              </p>
            </>
          )}

          {verification === SellerVerificationEnum.Rejected && (
            <>
              <Badge className={s.VerificationBanner__badge} variant={BadgeVariant.error}>
                <FormattedMessage id="profile.verificationStatusReject" />
              </Badge>

              <p className={s.VerificationBanner__text}>
                <FormattedMessage id="profile.verificationRejectText" />
              </p>

              <Button onClick={onClickVerify} className={s.VerificationBanner__button}>
                <FormattedMessage id="profile.passVerificationButton" />
              </Button>
            </>
          )}

          {verification === SellerVerificationEnum.Verified && (
            <>
              <Badge className={s.VerificationBanner__badge} variant={BadgeVariant.success}>
                <FormattedMessage id="profile.verificationStatusVerify" />
              </Badge>

              <p className={s.VerificationBanner__text}>
                <FormattedMessage
                  id="profile.verificationVerifyText"
                  values={{
                    a: (chunks) => (
                      <Anchor
                        component="span"
                        onClick={onClickVerify}
                        onKeyDownCapture={(e: React.KeyboardEvent) => {
                          if (isEnterKey(e)) {
                            onClickVerify?.();
                          }
                        }}
                      >
                        {chunks}
                      </Anchor>
                    )
                  }}
                />
              </p>
            </>
          )}
        </>
      )}
    </div>
  );
};
