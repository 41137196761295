import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonVariant } from '../../../../../common/components/Button';
import { Badge, BadgeVariant } from '../../../../../common/components/Badge';
import { PathBuilder } from '../../../../../common/utils/routes';
import { useIsMobile } from '../../../../../common/hooks/useIsMobile';
import s from './PickupRequestCard.module.scss';

export type PickupRequestCardProps = {
  uuid: string;
  pickupDate: string;
  interval: string;
  ordersAmount: number;
  active: boolean;
};

export const PickupRequestCard = (props: PickupRequestCardProps) => {
  const { uuid, pickupDate, interval, ordersAmount, active } = props;
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return (
    <div className={s.PickupRequestCard}>
      <div className={s.PickupRequestCard__item}>
        {active && <Badge variant={BadgeVariant.primary}>Active</Badge>}
        <div className={s.PickupRequestCard__item_text}>
          <span>
            <FormattedMessage id={'pickupRequest.card.date'} /> {pickupDate}
          </span>
          <span>
            <FormattedMessage id={'pickupRequest.card.time'} /> {interval}
          </span>
        </div>
      </div>
      <div className={s.PickupRequestCard__item}>
        {!isMobile && (
          <span>
            <FormattedMessage id={'pickupRequest.card.totalOrders'} /> {ordersAmount}
          </span>
        )}
        <Button
          variant={ButtonVariant.PRIMARY_OUTLINE}
          onClick={() => navigate(PathBuilder.getPickupRequestPath(uuid))}
        >
          <FormattedMessage id={'pickupRequest.card.viewButton'} />
        </Button>
      </div>
    </div>
  );
};
