import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { BankType, useBanksLazyQuery } from 'store/graphql';
import { FormInputSelectOption } from 'common/components/inputs';
import { useDebounce } from 'react-use';
import { handleDefaultError } from 'common/utils/handleDefaultError';
import { INPUT_DELAY } from '../constants';

export interface UseBankProps {
  /**
   * Значение банка по умолчанию
   */
  initialBank?: BankType;
  /**
   * Название банка, выбранное в форме из доступных опций для поиска объекта банка
   */
  bankName?: string;
  /**
   * Код банка, выбранное в форме из доступных опций для поиска объекта банка
   */
  bankCode?: string;
  /**
   * Название банка (хирагана), выбранное в форме из доступных опций для поиска объекта банка
   */
  bankNameHiragana?: string;
}

export const useBank = (props: UseBankProps = {}) => {
  const { initialBank, bankName, bankCode, bankNameHiragana } = props;

  const [bankNameInput, setBankNameInput] = useState('');
  const [bankCodeInput, setBankCodeInput] = useState('');
  const [bankNameHiraganaInput, setBankNameHiraganaInput] = useState('');

  const [getBanks, { data: banksData, loading: banksLoading, error: bankFetchError }] = useBanksLazyQuery({
    nextFetchPolicy: 'cache-and-network',
    onError: (e) => {
      handleDefaultError('An error occurred while fetching banks. Please try again later', e);
    }
  });

  const [searchError, setSearchError] = useState(false);

  /**
   * Состояние "измененности" инпутов для отображения лоадера от момента изменения до вызова запроса
   */
  const [inputChanged, setInputChanged] = useState(false);

  useEffect(() => {
    if (bankNameInput || bankCodeInput || bankNameHiraganaInput) {
      setInputChanged(true);
    }
  }, [bankCodeInput, bankNameHiraganaInput, bankNameInput]);

  /**
   * Название банка (хирагана), выбранное в форме из доступных опций для поиска объекта банка
   */
  useDebounce(
    async () => {
      setInputChanged(false);

      if ((bankNameInput || bankCodeInput || bankNameHiraganaInput) && !bank) {
        try {
          setSearchError(false);

          const banksData = await getBanks({
            variables: {
              input: {
                organization_name: bankNameInput || undefined,
                organization_code: bankCodeInput || undefined,
                organization_hiragana: bankNameHiraganaInput || undefined
              }
            }
          });

          if (banksData.data?.banks.bankOrganizations && banksData.data.banks.bankOrganizations.length === 0) {
            setSearchError(true);
          }
        } catch (e) {
          // todo все равно нет нормальной обработки ошибок
        }
      }
    },
    INPUT_DELAY,
    [bankNameInput, bankCodeInput, bankNameHiraganaInput, setInputChanged, setSearchError]
  );

  const isBankInput = !!(bankNameInput || bankCodeInput || bankNameHiraganaInput);

  const banks = useMemo(() => banksData?.banks.bankOrganizations || [], [banksData?.banks]);

  const bankNameOptions: FormInputSelectOption[] = useMemo(
    () =>
      isBankInput
        ? banks?.map((bank) => ({
            title: bank.organization_name,
            value: bank.organization_name
          }))
        : initialBank
        ? [
            {
              title: initialBank.organization_name,
              value: initialBank.organization_name
            }
          ]
        : [],
    [banks, initialBank, isBankInput]
  );

  const bankCodeOptions: FormInputSelectOption[] = useMemo(
    () =>
      isBankInput
        ? banks?.map((bank) => ({
            title: bank.organization_code,
            value: bank.organization_code
          }))
        : initialBank
        ? [
            {
              title: initialBank.organization_code,
              value: initialBank.organization_code
            }
          ]
        : [],
    [banks, initialBank, isBankInput]
  );

  const bankNameHiraganaOptions: FormInputSelectOption[] = useMemo(
    () =>
      isBankInput
        ? banks?.map((bank) => ({
            title: bank.organization_hiragana,
            value: bank.organization_hiragana
          }))
        : initialBank
        ? [
            {
              title: initialBank.organization_hiragana,
              value: initialBank.organization_hiragana
            }
          ]
        : [],
    [banks, initialBank, isBankInput]
  );

  useLayoutEffect(() => {
    if (initialBank) {
      setBankNameInput(initialBank.organization_name);
      setBankCodeInput(initialBank.organization_code);
      setBankNameHiraganaInput(initialBank.organization_hiragana);
    }
  }, [initialBank]);

  const bank = useMemo(
    () =>
      banks &&
      ((bankName && banks.find((i) => i.organization_name === bankName)) ||
        (bankCode && banks.find((i) => i.organization_code === bankCode)) ||
        (bankNameHiragana && banks.find((i) => i.organization_hiragana === bankNameHiragana))),
    [bankCode, bankName, bankNameHiragana, banks]
  );

  return {
    bankNameInput,
    setBankNameInput,
    bankCodeInput,
    setBankCodeInput,
    bankNameHiraganaInput,
    setBankNameHiraganaInput,
    bankNameOptions: !bank ? bankNameOptions : [],
    bankCodeOptions: !bank ? bankCodeOptions : [],
    bankNameHiraganaOptions: !bank ? bankNameHiraganaOptions : [],
    bank,
    banksLoading: !bank ? banksLoading || inputChanged : false,
    bankSearchError: searchError,
    bankFetchError
  };
};
