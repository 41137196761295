import { AvailableCurrencyEnum } from '../../store/currency/CurrencyContext';

export const formatMoney = (value: number = 0, currency?: AvailableCurrencyEnum) => {
  return Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: currency || 'JPY',
    currencyDisplay: 'narrowSymbol'
  }).format(value);
};

export const getCurrencySymbol = (currency?: AvailableCurrencyEnum) =>
  (0)
    .toLocaleString('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      currencyDisplay: 'narrowSymbol'
    })
    .replace(/\d/g, '')
    .trim();
