import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Product } from '../../../../store/graphql';
import { ReactFCC } from '../../../../common/utils/helperTypes';
import { Button, ButtonFit, ButtonSize, ButtonVariant } from '../../../../common/components/Button';
import { useBuyNow } from '../hooks/useBuyNow';

export interface BuyNowButtonProps {
  /**
   * ID товара из API
   */
  productId: Product['id'];
  /**
   * Состояние, если товар является товаром пользователя
   */
  isMyProduct: boolean;
  /**
   * Состояние, если товар проданный
   */
  isSold: boolean;
  /**
   * Состояние, если товар находится в корзине
   */
  isProductInCart: boolean;
}

export const BuyNowButton: ReactFCC<BuyNowButtonProps> = (props) => {
  const { productId, isProductInCart, isSold, isMyProduct } = props;
  const { buyNow } = useBuyNow({ productId });

  return (
    <>
      {!isMyProduct && !isSold && !isProductInCart && (
        <Button
          size={ButtonSize.LARGE}
          fit={ButtonFit.FILL}
          variant={ButtonVariant.PRIMARY_OUTLINE}
          disabled={isSold || isProductInCart || isMyProduct}
          onClick={() => buyNow()}
        >
          <FormattedMessage id="product.buyNow" />
        </Button>
      )}
    </>
  );
};
