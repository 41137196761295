import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { addDays, format } from 'date-fns';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  OrderPickupKeys,
  OrderPickupSchema,
  TOrderPickupValues
} from '../../pages/profile/sellerOrder/components/OrderCombineFedex/schema';
import {
  PickupRequestTypeEnum,
  TimeRecord,
  useCreatePickupRequestMutation,
  usePickupAvailabilityLazyQuery
} from '../../store/graphql';
import { PathBuilder, VERIFICATION_ROUTE } from '../../common/utils/routes';
import { getGraphqlErrorMessage } from '../../common/utils/graphqlErrors';
import { handleDefaultError } from '../../common/utils/handleDefaultError';
import { useUser } from '../../entities/user';
import { clearCache } from '../../app/providers/auth-apollo';
import { FormSelect, InputLayout } from '../../common/components/inputs';
import { ESpaceSize, Space } from '../../common/components/Space/Space';
import { AddressCardWithForm } from '../address';
import { Link } from '../../common/components/Link';
import { Button, ButtonVariant } from '../../common/components/Button';
import { Grid, GridGap } from '../../common/components/Grid';
import { OrderCombinePickupDate } from '../../pages/profile/sellerOrder/components/OrderCombinePickupDate/OrderCombinePickupDate';
import { Loader, LoaderSize } from '../../common/components/Loader';
import s from '../../pages/profile/pickupRequests/ReadyToSendOrders/ReadyToSendOrders.module.scss';

export type PickupRequestFormProps = {
  readyOrderIds: string[];
  nextStep: boolean;
};
const mutationOptions = {
  refetchQueries: ['Order', 'SellerOrder'],
  update: clearCache(['user'])
};

export type PickupOption = {
  timeFrom: Array<{ title: string; value: string }>;
  timeUntil: Array<{ title: string; value: string }>;
  cutOffTime: string;
  accessTime: number;
  pickupDate: string;
};
export const PickupRequestForm = (props: PickupRequestFormProps) => {
  const { readyOrderIds, nextStep } = props;

  const { user } = useUser();
  const addresses = user?.addresses;
  const sellerAddress = addresses?.find((address) => address.sellerAddress);
  const navigate = useNavigate();
  const intl = useIntl();

  const [pickupDay, setPickupDay] = useState<PickupRequestTypeEnum | null>(null);
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [pickupDates, setPickupDates] = useState<Array<{ title: string; value: PickupRequestTypeEnum }>>([]);
  const [pickupTimes, setPickupTimes] = useState<{
    same?: PickupOption;
    future?: PickupOption;
  } | null>(null);

  const [createPickup, { loading: createPickupLoading }] = useCreatePickupRequestMutation(mutationOptions);

  const [fetchPickupTimes, { loading: pickupTimesLoading }] = usePickupAvailabilityLazyQuery();

  const formik = useFormik<TOrderPickupValues>({
    initialValues: {
      sellerAddressId: sellerAddress?.id || '',
      readyTime: '',
      closeTime: ''
    } as unknown as TOrderPickupValues,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: OrderPickupSchema,
    onSubmit: async (values, formikHelpers) => {
      if (!pickupDay || !startTime || !endTime || !pickupTimes) {
        return;
      }

      let pickupDateTimestamp;

      if (pickupDay === PickupRequestTypeEnum.SameDay && pickupTimes.same) {
        const day = new Date(pickupTimes.same.pickupDate);
        day.setHours(+values.readyTime.slice(0, 2), +values.readyTime.slice(3, 5));
        pickupDateTimestamp = format(day, `yyyy-MM-dd'T'HH:mm:00'+0900'`);
      }
      if (pickupDay === PickupRequestTypeEnum.FutureDay && pickupTimes.future) {
        const day = new Date(pickupTimes.future.pickupDate);
        day.setHours(+values.readyTime.slice(0, 2), +values.readyTime.slice(3, 5));
        pickupDateTimestamp = format(day, `yyyy-MM-dd'T'HH:mm:00'+0900'`);
      }

      try {
        const result = await createPickup({
          variables: {
            input: {
              sellerAddressId: values.sellerAddressId,
              closeTime: values.closeTime,
              pickupDate: pickupDateTimestamp || '',
              pickupDateType: pickupDay === PickupRequestTypeEnum.TestDay ? PickupRequestTypeEnum.FutureDay : pickupDay,
              orderIds: readyOrderIds
            }
          }
        });
        if (result.data?.result) {
          toast.success('Pick-up request was successfully scheduled');
          navigate(PathBuilder.getPickupRequestPath(result.data?.result));
        }
      } catch (e) {
        const errorMessage = getGraphqlErrorMessage(e);

        if (errorMessage) {
          handleDefaultError(errorMessage, e);
        }
      }
    }
  });

  const fillPickupTimes = useCallback(async () => {
    if (!sellerAddress) {
      return;
    }

    const currentDate = format(new Date(), 'yyyy-MM-dd');
    const tomorrowDate = format(addDays(new Date(), 1), 'yyyy-MM-dd');

    const { data: sameDayPickupTimesQuery } = await fetchPickupTimes({
      fetchPolicy: 'cache-and-network',
      variables: {
        input: {
          postalCode: sellerAddress?.zipCode || '',
          countryCode: sellerAddress?.countryCode || 'JP',
          pickupRequestType: [PickupRequestTypeEnum.SameDay],
          dispatchDate: currentDate,
          carriers: ['FDXE'],
          countryRelationship: 'INTERNATIONAL'
        }
      }
    });

    const { data: futureDayPickupTimesQuery } = await fetchPickupTimes({
      fetchPolicy: 'cache-and-network',
      variables: {
        input: {
          postalCode: sellerAddress?.zipCode || '',
          countryCode: sellerAddress?.countryCode || 'JP',
          pickupRequestType: [PickupRequestTypeEnum.FutureDay],
          carriers: ['FDXE'],
          dispatchDate: tomorrowDate,
          countryRelationship: 'INTERNATIONAL'
        }
      }
    });

    const sameDate = sameDayPickupTimesQuery?.result.date;
    const futureDate = futureDayPickupTimesQuery?.result.date;

    const same: PickupOption = {
      timeFrom: sameDayPickupTimesQuery?.result.timesFrom || [],
      timeUntil: sameDayPickupTimesQuery?.result.timesUntil || [],
      cutOffTime: sameDayPickupTimesQuery?.result.cutOffTime || '',
      accessTime: sameDayPickupTimesQuery?.result.accessTime || 0,
      pickupDate: sameDate || ''
    };

    const future: PickupOption = {
      timeFrom: futureDayPickupTimesQuery?.result.timesFrom || [],
      timeUntil: futureDayPickupTimesQuery?.result.timesUntil || [],
      cutOffTime: futureDayPickupTimesQuery?.result.cutOffTime || '',
      accessTime: futureDayPickupTimesQuery?.result.accessTime || 0,
      pickupDate: futureDate || ''
    };

    const currentPickupDates: { title: string; value: PickupRequestTypeEnum }[] = [];

    if (sameDate) {
      currentPickupDates.push({ title: sameDate, value: PickupRequestTypeEnum.SameDay });
    }

    if (futureDate) {
      currentPickupDates.push({ title: futureDate, value: PickupRequestTypeEnum.FutureDay });
    }

    setPickupDates(currentPickupDates);

    setPickupTimes({
      ...(sameDate && { same }),
      ...(futureDate && { future })
    });
  }, [sellerAddress, fetchPickupTimes]);

  const pickupType = useMemo(
    () => ({
      [PickupRequestTypeEnum.SameDay]: pickupTimes?.same,
      [PickupRequestTypeEnum.FutureDay]: pickupTimes?.future,
      [PickupRequestTypeEnum.TestDay]: pickupTimes?.future
    }),
    [pickupTimes]
  );

  const timeUntil = useMemo<TimeRecord[]>(() => {
    if (!pickupDay) {
      return [];
    }

    const accessTime = pickupType[pickupDay]?.accessTime;
    return (
      pickupType[pickupDay]?.timeUntil.filter((time) => {
        if (!accessTime) return false;
        return time.value >= (+startTime.slice(0, 2) + accessTime).toString().padStart(2, '0') + startTime.slice(2, 8);
      }) || []
    );
  }, [pickupDay, startTime, pickupType]);

  const timeFrom = useMemo<TimeRecord[]>(() => {
    if (!pickupDay) {
      return [];
    }
    return pickupType[pickupDay]?.timeFrom || [];
  }, [pickupDay, pickupType]);

  useEffect(() => {
    if (nextStep) {
      fillPickupTimes();
    }
  }, [nextStep, fillPickupTimes]);

  return (
    <div className={s.PickupRequestForm}>
      <div className={s.PickupRequestForm_inputs}>
        {sellerAddress ? (
          <InputLayout
            label={intl.formatMessage({ id: 'orderCombine.chooseAddress' })}
            space={{ bottom: ESpaceSize.PIXEL_24 }}
            error={formik.errors.sellerAddressId}
            required
          >
            <AddressCardWithForm
              className={s.PickupRequestForm__packagingFormAddress}
              address={sellerAddress}
              selected
            />
          </InputLayout>
        ) : (
          <span className={s.PickupRequestForm__sellerAddressWarning}>
            <FormattedMessage
              id={'orderCombine.noSellerAddress'}
              values={{
                a: (chunks) => <Link to={VERIFICATION_ROUTE}>{chunks}</Link>
              }}
            />
          </span>
        )}
        <div className={s.PickupRequestForm__daytimeControl}>
          <FormattedMessage id={'orderCombine.selectTime'} />
          <Space size={ESpaceSize.PIXEL_8} />
          {pickupDates.length ? (
            <Grid cols={{ xs: 2, md: 7, lg: 7 }} gap={GridGap.SMALL}>
              {pickupDates.map((date, index) => (
                <Grid.GridItem cols={{ xs: 1, md: 1, lg: 1 }} key={index}>
                  <OrderCombinePickupDate
                    value={date.title}
                    selected={date.value === pickupDay}
                    onClick={() => setPickupDay(date.value as PickupRequestTypeEnum)}
                  />
                </Grid.GridItem>
              ))}
            </Grid>
          ) : (
            <Loader size={LoaderSize.SMALL} />
          )}
          <Space size={ESpaceSize.PIXEL_16} />
          <div className={s.PickupRequestForm__timeForm}>
            <FormSelect
              name={OrderPickupKeys.readyTime}
              value={startTime}
              options={timeFrom || []}
              defaultOption={{
                title: intl.formatMessage({ id: 'orderCombine.readyTime' }),
                value: ''
              }}
              label={intl.formatMessage({ id: 'orderCombine.from' })}
              onItemSelect={(option) => {
                formik.setFieldValue(OrderPickupKeys.readyTime, option.value);
                option.value && setStartTime(option.value as string);
              }}
              error={formik.errors.readyTime}
              disabled={!pickupDay || !timeFrom.length}
              required
            />
            <Space size={ESpaceSize.PIXEL_16} />
            <FormSelect
              name={OrderPickupKeys.closeTime}
              value={endTime}
              options={timeUntil || []}
              defaultOption={{
                title: intl.formatMessage({ id: 'orderCombine.latestTime' }),
                value: ''
              }}
              label={intl.formatMessage({ id: 'orderCombine.to' })}
              onItemSelect={(option) => {
                formik.setFieldValue(OrderPickupKeys.closeTime, option.value);
                option.value && setEndTime(option.value as string);
              }}
              error={formik.errors.closeTime}
              disabled={!startTime || !timeUntil.length}
              required
            />
          </div>
        </div>
      </div>
      <Space size={ESpaceSize.PIXEL_16} />

      <Button
        className={s.PickupRequestForm__sendingFormButton}
        variant={ButtonVariant.PRIMARY}
        onClick={formik.submitForm}
        loading={createPickupLoading}
        disabled={!sellerAddress}
      >
        <FormattedMessage id={'orderCombine.createPickupButton'} />
      </Button>
    </div>
  );
};
