import { DeliverySpeedDaysEnum, ProductConditionEnum, ProductStatusEnum } from '../../store/graphql';

export const productConditionIntlNames: Record<ProductConditionEnum, string> = {
  [ProductConditionEnum.New]: 'catalog.conditionNew',
  [ProductConditionEnum.Bad]: 'catalog.conditionBad',
  [ProductConditionEnum.AlmostUnused]: 'catalog.conditionAlmostUnused',
  [ProductConditionEnum.LightDamage]: 'catalog.conditionLightDamage',
  [ProductConditionEnum.SomeDamage]: 'catalog.conditionSomeDamage',
  [ProductConditionEnum.Damaged]: 'catalog.conditionDamaged'
};

export const deliverySpeedDaysIntlNames: Record<DeliverySpeedDaysEnum, string> = {
  [DeliverySpeedDaysEnum.OneOrTwo]: 'product.deliveryOneOrTwo',
  [DeliverySpeedDaysEnum.ThreeOrFour]: 'product.deliveryThreeOrFour',
  [DeliverySpeedDaysEnum.FromFiveToSeven]: 'product.deliveryFromFiveToSeven'
};

export const productStatusIntlNames: Record<ProductStatusEnum, string> = {
  [ProductStatusEnum.ForSale]: 'catalog.statusForSale',
  [ProductStatusEnum.Sold]: 'catalog.statusSold'
};

/**
 * Дефолтное значение задержки инпута при создании товара в миллисекундах
 **/
export const defaultProductInputDelay = 700;
