import React from 'react';
import clsx from 'clsx';
import { ESpaceSize, Space } from '../Space/Space';
import s from './Divider.module.scss';

export enum EDividerVariant {
  DEFAULT,
  LIGHT
}

type TDividerSpaces = {
  top?: ESpaceSize;
  bottom?: ESpaceSize;
};

type TDividerClasses = {
  root?: string;
  content?: string;
};

export type TDivider = {
  className?: string;
  variant?: EDividerVariant;
  space?: ESpaceSize | TDividerSpaces;
  classes?: TDividerClasses;
};

export const Divider: React.FC<TDivider> = (props) => {
  const { className, variant, space, classes } = props;

  const spaceTop = typeof space === 'object' ? space?.top : space;
  const spaceBottom = typeof space === 'object' ? space?.bottom : space;

  return (
    <div className={clsx(s.Divider, className, classes?.root)}>
      {spaceTop && <Space size={spaceTop} />}

      <div
        className={clsx(s.Divider__content, classes?.content, {
          [s.Divider_light]: variant === EDividerVariant.LIGHT
        })}
      />

      {spaceBottom && <Space size={spaceBottom} />}
    </div>
  );
};
