import React, { useState } from 'react';
import { ReactFCC } from '../../../../common/utils/helperTypes';
import { useToggle } from '../../../../common/hooks/useToggle';
import { AddressForm } from '../../components';
import { AddressLayout, AddressLayoutProps } from '../AddressLayout';
import { AddressPersonalInformation, UserAddress } from '../../types';

export interface AddressLayoutWithFormProps extends AddressLayoutProps {
  /**
   * Персональная информация по умолчанию
   */
  personalInformation?: AddressPersonalInformation;
}

/**
 * Компонент-обертка над обычным AddressLayout,
 * предоставляющая в дополнение модалку с формой создания/редактирования адреса.
 * Используется в форме на странице профиля ProfilePage
 */
export const AddressLayoutWithForm: ReactFCC<AddressLayoutWithFormProps> = (props) => {
  const { addresses, onClickOpenForm, personalInformation, ...restProps } = props;

  const [addressModalOpen, { set: openAddressModal, unset: closeAddressModal }] = useToggle(false);
  const [editedAddress, setEditedAddress] = useState<UserAddress | null>(null);

  return (
    <>
      <AddressLayout
        addresses={addresses}
        onClickOpenForm={(address) => {
          openAddressModal();
          setEditedAddress(address);
          onClickOpenForm?.(address);
        }}
        {...restProps}
      />

      <AddressForm
        isOpen={addressModalOpen}
        address={editedAddress}
        defaultsSelected={addresses.length === 0}
        personalInformation={personalInformation}
        onClose={() => {
          closeAddressModal();
          setEditedAddress(null);
        }}
      />
    </>
  );
};
