import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { customIcons } from 'common/components/CustomIcon';
import { ReactFCC } from '../../../../../../common/utils/helperTypes';
import { ArisoraOrderStatus } from '../../../../../../store/graphql';
import {
  ProgressItem,
  ProgressItemProps
} from '../../../../../../common/components/Progress/ProgressItem/ProgressItem';

export interface OrderProgressItemProps extends ProgressItemProps {
  /**
   * Статус заказа из API
   */
  status: ArisoraOrderStatus;
  /**
   * Переведенная строка названия статуса, треубется для названия айтема прогресса
   */
  titleIntl: string;
}

export const OrderProgressItem: ReactFCC<OrderProgressItemProps> = (props) => {
  const { error, className, active, completed, ending, starting, growing, nextError, classes, status, titleIntl } =
    props;

  const deliveryIcon = useMemo(() => {
    if (error) {
      return customIcons.DeliveryError;
    } else if (active) {
      return customIcons.DeliveryActive;
    } else if (completed) {
      return customIcons.DeliveryCompleted;
    } else {
      return customIcons.Delivery;
    }
  }, [active, completed, error]);

  /**
   * Не отображаем прогресс при "неудачных" статусах
   */
  const disabled = status === ArisoraOrderStatus.Canceled || status === ArisoraOrderStatus.Destroyed;

  return (
    <ProgressItem
      className={className}
      starting={starting}
      ending={ending}
      active={!disabled && active}
      growing={!disabled && growing}
      completed={!disabled && completed}
      error={!disabled && error}
      nextError={!disabled && nextError}
      customIcon={ending ? deliveryIcon : undefined}
      classes={classes}
    >
      <FormattedMessage id={titleIntl} />
    </ProgressItem>
  );
};
