import clsx from 'clsx';
import React from 'react';
import s from '../../../widgets/layout/components/FooterLinks/FooterLinks.module.scss';

export type LinksListProps = {
  /**
   * Дополнительный класс для общего компонента листа ссылок
   **/
  className?: string;
  /**
   * Дочерний компонент
   **/
  children?: React.ReactNode;
  /**
   * Дополнительные классы
   *
   * expanded - класс, когда лист использует туглер для скрытия/раскрытия
   **/
  classes?: {
    expanded?: string;
  };
  /**
   * Флаг, если требуется сделать лист развёртываемым
   **/
  expanded?: boolean;
};

export const LinksList = ({ className, classes, children, expanded }: LinksListProps) => {
  return (
    <div className={clsx(s.LinksList, className, expanded && [s.LinksList_expanded, classes?.expanded].join(' '))}>
      {children}
    </div>
  );
};
