import { boolean, InferType, object, string } from 'yup';
import { validateErrors } from '../../../../common/utils/validate';
import { JAPANESE_COUNTRY_CODE } from './constants';

export const titleMaxLength = 50;
export const nameMaxLength = 50;
export const maxJapaneseAddressLength = 15;
export const addressMaxLength = 30;
export const minPhoneLength = 8;

const addressLengthError = `Must be maximum ${addressMaxLength} characters long`;
const addressLengthErrorJp = `最大${maxJapaneseAddressLength}文字までです`;

const validateObject = {
  title: string().max(titleMaxLength, `Must be maximum ${titleMaxLength} characters long`).optional(),
  fullName: string()
    .max(nameMaxLength, `Must be maximum ${nameMaxLength} characters long`)
    .required(validateErrors.required),
  email: string().email(validateErrors.wrongEmail).required(validateErrors.required),
  phone: string().min(minPhoneLength).required(validateErrors.required),
  countryCode: string().required(validateErrors.required),
  zipCode: string().optional(),

  // not Japan
  state: string().max(nameMaxLength, addressLengthError).optional(),
  stateCode: string().max(nameMaxLength, addressLengthError).optional(),
  province: string().max(nameMaxLength, addressLengthError).optional(),
  provinceCode: string().max(nameMaxLength, addressLengthError).optional(),
  region: string().max(nameMaxLength, addressLengthError),
  regionCode: string().max(nameMaxLength, addressLengthError).optional(),
  city: string().max(nameMaxLength, addressLengthError).optional(),

  // Japan
  prefecture: string().max(nameMaxLength, addressLengthError).optional(),
  // city is above
  town: string().max(nameMaxLength, addressLengthError).optional(),
  address: string().max(nameMaxLength, addressLengthError).optional(),

  // address fields common
  address1: string()
    .when('countryCode', {
      is: (val: string) => val === JAPANESE_COUNTRY_CODE,
      then: string().max(maxJapaneseAddressLength, addressLengthErrorJp),
      otherwise: string().max(nameMaxLength, addressLengthError)
    })
    .required(validateErrors.required),
  address2: string().max(nameMaxLength, addressLengthError).optional(),

  defaultBilling: boolean().optional(),
  defaultShipping: boolean().optional(),

  force: boolean().optional()
};

export const AddressSchema = object(validateObject);

export type AddressValues = InferType<typeof AddressSchema>;

export const AddressSchemaKeys = Object.fromEntries(Object.keys(validateObject).map((key) => [key, key])) as Record<
  keyof AddressValues,
  string
>;
