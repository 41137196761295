import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'common/components/Link/Link';
import { Grid, GridGap } from '../../../common/components/Grid';
import { useUser } from '../../../entities/user';
import { LoaderBox } from '../../../common/components/Loader';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { Heading, HeadingSize } from '../../../common/components/Heading';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import { ReviewFilters } from '../../user/content/review/reviewFilters/ReviewFilters';
import { UserReview } from '../../user/content/review/UserReview';
import {
  RatingValueEnum,
  Review,
  ReviewsUserRoleEnum,
  useUserReviewsCountQuery,
  useUserReviewsQuery
} from '../../../store/graphql';
import { Button, ButtonFit, ButtonVariant } from '../../../common/components/Button';
import { Head } from '../../../common/components/Head';
import { PathBuilder } from '../../../common/utils/routes';
import { DEFAULT_PUBLIC_PAGE_REVIEWS_LIMIT } from '../../user/content/UserPageContent';
import s from './ReviewsPage.module.scss';

export type TUserReviews =
  | Pick<
      Review,
      | 'commentary'
      | 'customerNickname'
      | 'sellerNickname'
      | 'sellerId'
      | 'customerId'
      | 'value'
      | 'createdAt'
      | 'reviewFrom'
      | 'orderId'
    >[]
  | null;
export const ReviewsPage = () => {
  const { user } = useUser();
  const isMobile = useIsMobile();
  const intl = useIntl();

  const [ratingTypes, setRatingTypes] = useState<RatingValueEnum[]>([]);
  const [reviewsLimit, setReviewsLimit] = useState<number>(6);
  const [reviewFrom, setReviewFrom] = useState<ReviewsUserRoleEnum[]>([]);
  const [filteredReviews, setFilteredReviews] = useState<TUserReviews | undefined>([]);

  const chooseRatingTypes = (ratingType: RatingValueEnum) => {
    const ratingEnabled = ratingTypes.includes(ratingType);
    if (ratingEnabled) {
      setRatingTypes((prev) => prev.filter((rating) => rating !== ratingType));
    } else {
      setRatingTypes((prev) => [...prev, ratingType]);
    }
  };

  const chooseReviewers = (newReviewer: ReviewsUserRoleEnum) => {
    const reviewerEnabled = reviewFrom.includes(newReviewer);
    if (reviewerEnabled) {
      setReviewFrom((prev) => prev.filter((reviewer) => reviewer !== newReviewer));
    } else {
      setReviewFrom((prev) => [...prev, newReviewer]);
    }
  };

  const {
    data: reviewsQuery,
    loading: reviewsLoading,
    fetchMore
  } = useUserReviewsQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: DEFAULT_PUBLIC_PAGE_REVIEWS_LIMIT
    }
  });

  const { data: reviewsCountQuery } = useUserReviewsCountQuery({
    fetchPolicy: 'cache-and-network'
  });

  const reviews = reviewsQuery?.reviews.reviews?.entries;
  const reviewsCount = reviewsCountQuery?.count.reviews?.totalReviewsByFilter;
  const reviewsPagination = reviewsQuery?.reviews?.reviews?.pagination;

  useLayoutEffect(() => {
    if (reviewsPagination && reviewsPagination.totalCount < DEFAULT_PUBLIC_PAGE_REVIEWS_LIMIT) {
      fetchMore({
        variables: {
          input: {
            pagination: {
              limit: reviewsLimit,
              offset: 0
            }
          }
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (fetchMoreResult.reviews?.reviews && fetchMoreResult.reviews?.id) {
            return {
              reviews: {
                id: fetchMoreResult.reviews.id,
                reviews: {
                  entries: fetchMoreResult.reviews.reviews.entries,
                  pagination: fetchMoreResult.reviews.reviews.pagination
                }
              }
            };
          }
          return prevResult;
        }
      });
    }
  }, [reviewsLimit, fetchMore, reviewsPagination]);

  const seller = user?.seller;

  useLayoutEffect(() => {
    if (
      seller &&
      ratingTypes &&
      ratingTypes.length > 0 &&
      [RatingValueEnum.Neutral, RatingValueEnum.Negative, RatingValueEnum.Positive].every((value) =>
        ratingTypes.includes(value)
      ) &&
      reviewFrom &&
      reviewFrom.length > 0 &&
      [ReviewsUserRoleEnum.Customer, ReviewsUserRoleEnum.Seller].every((role) => reviewFrom.includes(role))
    ) {
      setRatingTypes([]);
      setReviewFrom([]);
    }
  }, [reviewFrom, ratingTypes, seller]);

  useLayoutEffect(() => {
    if (
      !seller &&
      ratingTypes &&
      ratingTypes.length > 0 &&
      [RatingValueEnum.Neutral, RatingValueEnum.Negative, RatingValueEnum.Positive].every((value) =>
        ratingTypes.includes(value)
      )
    ) {
      setRatingTypes([]);
    }
  }, [ratingTypes, seller]);

  useEffect(() => {
    setFilteredReviews(reviews);
    if (ratingTypes && ratingTypes.length > 0) {
      setFilteredReviews((prev) => prev?.filter((review) => ratingTypes.includes(review.value)));
    }
    if (reviewFrom && reviewFrom.length > 0) {
      setFilteredReviews((prev) => prev?.filter((review) => reviewFrom.includes(review.reviewFrom)));
    }
  }, [reviews, ratingTypes, reviewFrom]);
  const overallCount = useMemo(() => {
    if (reviewsCount) {
      return reviewsCount.negative + reviewsCount.neutral + reviewsCount.positive;
    }
  }, [reviewsCount]);

  const [currentCount, setCurrentCount] = useState(0);
  useLayoutEffect(() => {
    setCurrentCount(overallCount || 0);
    if (reviewsCount) {
      let count = 0;
      if (ratingTypes.length > 0) {
        for (let rating of ratingTypes) {
          count += reviewsCount[rating.toLowerCase() as keyof typeof reviewsCount]
            ? +reviewsCount[rating.toLowerCase() as keyof typeof reviewsCount]!
            : 0;
        }
        setCurrentCount(count);
      }
      if (!ratingTypes.length && reviewFrom.length > 0) {
        for (let reviewer of reviewFrom) {
          count += reviewsCount[reviewer.toLowerCase() as keyof typeof reviewsCount]
            ? +reviewsCount[reviewer.toLowerCase() as keyof typeof reviewsCount]!
            : 0;
        }
        setCurrentCount(count);
      }
      if (ratingTypes.length > 0 && reviewFrom.length > 0 && filteredReviews) {
        setCurrentCount(filteredReviews?.length);
      }
    }
  }, [currentCount, ratingTypes, reviewFrom, setCurrentCount, filteredReviews, reviewsCount, overallCount]);

  if (!user) return <LoaderBox />;

  return (
    <>
      <Head title={intl.formatMessage({ id: 'reviews.profileTitle' })} />

      <Heading size={HeadingSize.H3}>
        <FormattedMessage id={'reviews.profileTitle'} />
      </Heading>

      <Space size={ESpaceSize.PIXEL_32} />

      <Grid cols={{ xs: 1, md: 12, lg: 12 }}>
        <Grid.GridItem cols={{ xs: 1, md: 12, lg: 12 }}>
          {reviewsCount && (
            <ReviewFilters
              ratingTypes={ratingTypes}
              reviewFrom={reviewFrom}
              chooseRatingTypes={chooseRatingTypes}
              chooseReviewer={chooseReviewers}
              reviewsCount={reviewsCount}
              isSeller={!!user.sellerId}
              isOwnReview={true}
            />
          )}
        </Grid.GridItem>

        <Space size={ESpaceSize.PIXEL_24} />
        <Grid.GridItem cols={{ xs: 1, md: 12, xl: 12 }}>
          <Grid gap={GridGap.SMALL}>
            {filteredReviews && filteredReviews.length > 0 && reviews && reviews.length > 0 ? (
              filteredReviews.slice(0, reviewsLimit).map((review, index) => (
                <Grid.GridItem cols={{ xs: 2, lg: 6 }} key={index}>
                  <Link
                    to={
                      review.orderId
                        ? PathBuilder.getOrderPath(review.orderId, ReviewsUserRoleEnum.Customer === review.reviewFrom)
                        : ''
                    }
                    className={s.ReviewsPage__orderLink}
                  >
                    <UserReview
                      role={review.reviewFrom}
                      comment={{
                        authorId:
                          review.reviewFrom === ReviewsUserRoleEnum.Seller ? review.sellerId! : review.customerId!,
                        author: user.nickname || '',
                        date: review.createdAt,
                        text: review.commentary || '',
                        value: review.value
                      }}
                      orderPath={
                        review.orderId
                          ? PathBuilder.getOrderPath(review.orderId, ReviewsUserRoleEnum.Customer === review.reviewFrom)
                          : ''
                      }
                      isOwnReview
                    />
                  </Link>
                </Grid.GridItem>
              ))
            ) : reviewsLoading ? (
              <Grid.GridItem>
                <LoaderBox />
              </Grid.GridItem>
            ) : (
              <Grid.GridItem cols={{ xs: 2, lg: 6 }}>
                <Heading size={HeadingSize.H6} className={s.ReviewsPage__noReviewsHeading}>
                  <FormattedMessage id={'reviews.empty'} />
                </Heading>
              </Grid.GridItem>
            )}
          </Grid>

          {filteredReviews &&
            reviewsPagination &&
            currentCount > filteredReviews.slice(0, reviewsLimit).length &&
            reviewsPagination.totalCount > reviewsLimit && (
              <Button
                variant={ButtonVariant.PRIMARY_OUTLINE}
                fit={isMobile ? ButtonFit.FILL : ButtonFit.FIT}
                loading={reviewsLoading}
                onClick={() => setReviewsLimit((prev) => prev + 10)}
              >
                <FormattedMessage id={'reviews.showMoreReviews'} />
              </Button>
            )}
        </Grid.GridItem>
      </Grid>
      <Space size={ESpaceSize.PIXEL_24} />
    </>
  );
};
