import { InferType, object, string } from 'yup';
import { validateErrors } from '../../../../../common/utils/validate';

export const commentaryMaxLength = 500;

const validateObject = {
  ratingValue: string().defined(validateErrors.required),
  commentary: string().max(commentaryMaxLength, `Must be maximum ${commentaryMaxLength} characters long`).optional()
};

export const OrderReviewSchema = object(validateObject);

export type TOrderReviewValues = InferType<typeof OrderReviewSchema>;

export const OrderReviewKeys = Object.fromEntries(Object.keys(validateObject).map((key) => [key, key])) as Record<
  keyof TOrderReviewValues,
  string
>;
