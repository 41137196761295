import React from 'react';
import clsx from 'clsx';
import s from './TicketMessagesCount.module.scss';

type TicketMessagesCountProps = {
  count: number;
  className?: string;
};

export const TicketMessagesCount = ({ count, className }: TicketMessagesCountProps) => {
  if (!count) {
    return <></>;
  }
  return <div className={clsx(s.TicketMessagesCount, className)}>{count}</div>;
};
