import { array, InferType, mixed, number, object, string } from 'yup';
import { DeliverySpeedDaysEnum, ProductStatusEnum, SpecificValueInput } from '../../../../store/graphql';
import { validateErrors } from '../../../../common/utils/validate';

export const productAttributeLimits = {
  price: {
    min: 300,
    max: 9999999,
    unit: '¥'
  },
  weight: {
    min: 0.1,
    max: 50,
    unit: 'kg'
  },
  size: {
    min: 1,
    max: 50,
    unit: 'cm'
  }
};

export const descriptionMaxLength = 500;

export const productNameMaxLength = 50;

export const dimensionsMaxSum = 260;

const validateObject = {
  categoryId: string().defined(validateErrors.required),
  subCategoryId: string().defined(validateErrors.required),
  subSubCategoryId: string().defined(validateErrors.required),
  name: string()
    .min(3, 'Must be at least 3 characters long')
    .max(50, 'Must be maximum 50 characters long')
    .defined(validateErrors.required),
  description: string()
    .max(descriptionMaxLength, `Must be maximum ${descriptionMaxLength} characters long`)
    .defined(validateErrors.required),
  brandId: string().optional(),
  colorId: string().optional(),
  warehouseNumber: number().optional(),
  kuroneko: string().optional(),
  condition: string().defined(validateErrors.required),
  status: string().default(ProductStatusEnum.ForSale).defined(validateErrors.required),
  specificValues: array().of(mixed<SpecificValueInput>().optional()).defined(),
  price: number()
    .positive(validateErrors.positiveNumber)
    .integer('Should be integer')
    .min(
      productAttributeLimits.price.min,
      ({ min }) => `Can't be less than ${productAttributeLimits.price.unit}${new Intl.NumberFormat().format(min)}`
    )
    .max(
      productAttributeLimits.price.max,
      ({ max }) => `Can't be greater than ${productAttributeLimits.price.unit}${new Intl.NumberFormat().format(max)}`
    )
    .defined(validateErrors.required),
  weight: number()
    .positive(validateErrors.positiveNumber)
    .min(
      productAttributeLimits.weight.min,
      ({ min }) => `Can't be less than ${min}${productAttributeLimits.weight.unit}`
    )
    .max(
      productAttributeLimits.weight.max,
      ({ max }) => `Can't be greater than ${max}${productAttributeLimits.weight.unit}`
    )
    .defined(validateErrors.required),
  boxHeight: number()
    .positive(validateErrors.positiveNumber)
    .integer('Should be integer')
    .min(productAttributeLimits.size.min, ({ min }) => `Can't be less than ${min}${productAttributeLimits.size.unit}`)
    .defined(validateErrors.required),
  boxWidth: number()
    .positive(validateErrors.positiveNumber)
    .integer('Should be integer')
    .min(productAttributeLimits.size.min, ({ min }) => `Can't be less than ${min}${productAttributeLimits.size.unit}`)
    .defined(validateErrors.required),
  boxLength: number()
    .positive(validateErrors.positiveNumber)
    .integer('Should be integer')
    .min(productAttributeLimits.size.min, ({ min }) => `Can't be less than ${min}${productAttributeLimits.size.unit}`)
    .defined(validateErrors.required),
  deliverySpeedDays: string().default(DeliverySpeedDaysEnum.OneOrTwo).defined(validateErrors.required),
  dimensions: number().optional(),
  mainPhotoIndex: number().integer().optional()
};

export const CreateProductSchema = object(validateObject);

export type TCreateProductValues = InferType<typeof CreateProductSchema>;

export const CreateProductSchemaKeys = Object.fromEntries(
  Object.keys(validateObject).map((key) => [key, key])
) as Record<keyof TCreateProductValues, string>;
