import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import { ReactFCC } from 'common/utils/helperTypes';
import { BrandInput, FormLabel } from 'common/components/inputs';
import { CatalogFiltersLayoutProps } from '../types';
import { CatalogFilterPrice } from '../../CatalogFilterPrice';
import { CatalogFilterColor } from '../../CatalogFilterColor';
import { CatalogFilterCondition } from '../../CatalogFilterCondition';
import { CatalogFiltersSpecificAttributes } from '../../CatalogFiltersSpecificAttributes';
import { CatalogFilterStatus } from '../../CatalogFilterStatus';
import s from './CatalogFiltersLayoutDesktop.module.scss';

export interface CatalogFiltersLayoutDesktopProps extends CatalogFiltersLayoutProps {}

export const CatalogFiltersLayoutDesktop: ReactFCC<CatalogFiltersLayoutDesktopProps> = (props) => {
  const {
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    brandId,
    setBrandId,
    colorIds,
    setColorIds,
    conditionIds,
    setConditionIds,
    statusIds,
    setStatusIds,
    subSubCategoryId,
    specificAttributeValues,
    setSpecificAttributeValues
  } = props;

  const intl = useIntl();

  return (
    <div className={s.CatalogFiltersLayoutDesktop}>
      <FormLabel>
        <FormattedMessage id="catalog.price" />
      </FormLabel>

      <CatalogFilterPrice
        className={s.CatalogFiltersLayoutDesktop__filter}
        minPrice={minPrice}
        maxPrice={maxPrice}
        setMinPrice={setMinPrice}
        setMaxPrice={setMaxPrice}
      />

      <BrandInput
        label={intl.formatMessage({ id: 'catalog.brand' })}
        placeholder={intl.formatMessage({ id: 'catalog.brand' })}
        className={s.CatalogFiltersLayoutDesktop__filter}
        active={brandId}
        setActive={setBrandId}
      />

      <CatalogFilterColor className={s.CatalogFiltersLayoutDesktop__filter} active={colorIds} setActive={setColorIds} />

      <CatalogFilterCondition
        className={s.CatalogFiltersLayoutDesktop__filter}
        active={conditionIds}
        setActive={setConditionIds}
      />

      <CatalogFiltersSpecificAttributes
        classes={{
          filter: s.CatalogFiltersLayoutDesktop__filter
        }}
        subSubCategoryId={subSubCategoryId}
        specificAttributeValues={specificAttributeValues}
        setSpecificAttributeValues={setSpecificAttributeValues}
      />

      <CatalogFilterStatus
        className={s.CatalogFiltersLayoutDesktop__filter}
        active={statusIds}
        setActive={setStatusIds}
      />
    </div>
  );
};
