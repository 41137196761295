import React, { useCallback } from 'react';
import { useUpsertPaymentIntentMutation } from '../graphql';
import { handleDefaultError } from '../../common/utils/handleDefaultError';
import { getGraphqlErrorMessage } from '../../common/utils/graphqlErrors';
import { useUser } from '../../entities/user';
import { PaymentContext } from './PaymentContext';
import { usePaymentSession } from './usePaymentSession';

export const PaymentProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { user } = useUser();
  const paymentCardLastNumbers = user?.last4numbers || '';
  const paymentSession = usePaymentSession();

  const [paymentIntentRun, { loading: paymentIntentLoading }] = useUpsertPaymentIntentMutation();
  const intentPayment = useCallback(
    async (debtId?: string): Promise<string | null> => {
      try {
        const result = await paymentIntentRun({ variables: { debtId } });
        const paymentIntent = result.data?.paymentIntent || null;

        if (!paymentIntent) {
          handleDefaultError('Something went wrong! Please try again.');
        }

        return paymentIntent;
      } catch (e) {
        const errMessage = getGraphqlErrorMessage(e);
        handleDefaultError('Something went wrong! Please try again.', errMessage);
        return null;
      }
    },
    [paymentIntentRun]
  );

  return (
    <PaymentContext.Provider
      value={{
        ...paymentSession,
        hasPaymentMethod: !!paymentCardLastNumbers,
        paymentCardLastNumbers,
        intentPayment,
        intentPaymentLoading: paymentIntentLoading
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
