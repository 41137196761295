import { InferType, object, string } from 'yup';
import { validateErrors } from '../../../../../common/utils/validate';
import { maxComplaintCommentLength } from '../../../../../widgets/auth/components';

const validateObject = {
  reasonId: string().defined(validateErrors.required).required(),
  commentary: string().max(maxComplaintCommentLength, 'Must be maximum 600 characters long')
};

export const ComplaintModalSchema = object(validateObject);

export type TComplaintModalValues = InferType<typeof ComplaintModalSchema>;

export const ComplaintModalKeys = Object.fromEntries(Object.keys(validateObject).map((key) => [key, key])) as Record<
  keyof TComplaintModalValues,
  string
>;
