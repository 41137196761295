import { useLocation } from 'react-router-dom';
import { HELP_ROUTE } from '../../common/utils/routes';
import { ESpaceSize, Space } from '../../common/components/Space/Space';
import { useIsMobile } from '../../common/hooks/useIsMobile';

export const SupportHelpPage = () => {
  const isMobile = useIsMobile();
  const location = useLocation();
  if (isMobile && location.pathname === HELP_ROUTE) {
    return (
      <>
        <Space size={ESpaceSize.PIXEL_24} />
      </>
    );
  }
  return <>Under development</>;
};
