import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { CustomIcon } from 'common/components/CustomIcon/CustomIcon';
import { customIcons } from 'common/components/CustomIcon/icons';
import { BsPencil, BsTrash } from 'react-icons/bs';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { IconType } from 'react-icons';
import { BankAccountType, useDeleteBankAccountMutation } from 'store/graphql';
import { handleDefaultError } from 'common/utils/handleDefaultError';
import { clearCache } from 'app/providers/auth-apollo';
import { ContextMenu } from 'common/components/ContextMenu';
import s from './BankCard.module.scss';

export interface BankCardProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Объектов адреса, полученный с api
   */
  bankAccount: BankAccountType;
  /**
   * Обработчик нажатия кнопки редактирования
   */
  onClickEdit?: () => void;
  /**
   * Состояние интерактивности, визуальное сопровождение при наведении на карточку
   */
  interactive?: boolean;
  /**
   * Состояние выбранности
   */
  selected?: boolean;
  /**
   * Обработчик нажатия на карточку (работает только при включенном interactive)
   */
  onClick?: () => void;
}

interface ContextMenuItem {
  title: string;
  icon?: IconType;
  onClick?: (e: React.MouseEvent) => void;
  danger?: boolean;
}

const mutationOptions = {
  refetchQueries: ['BankAccounts'],
  update: clearCache(['getMyBankAccountPresets'])
};

export const BankCard: ReactFCC<BankCardProps> = (props) => {
  const { className, bankAccount, interactive, selected, onClick, onClickEdit } = props;

  const intl = useIntl();

  const [deleteBankAccountMutation, { loading: deleteLoading }] = useDeleteBankAccountMutation(mutationOptions);

  const deleteBankAccount = useCallback(async () => {
    try {
      await deleteBankAccountMutation({
        variables: {
          where: {
            id: bankAccount.id
          }
        }
      });
    } catch (e) {
      handleDefaultError('Sorry, an error occurred while deleting the bank details preset', e);
    }
  }, [bankAccount.id, deleteBankAccountMutation]);

  const contextMenuItems: ContextMenuItem[] = useMemo(
    () =>
      [
        {
          title: intl.formatMessage({ id: 'general.edit' }),
          icon: BsPencil,
          onClick: onClickEdit
        },
        {
          title: intl.formatMessage({ id: 'general.delete' }),
          icon: BsTrash,
          danger: true,
          onClick: deleteBankAccount
        }
      ].filter(Boolean) as ContextMenuItem[],
    [deleteBankAccount, intl, onClickEdit]
  );

  return (
    <div
      className={clsx(s.BankCard, className, {
        [s.BankCard_interactive]: interactive,
        [s.BankCard_selected]: selected
      })}
      onClick={interactive ? onClick : undefined}
    >
      <div className={s.BankCard__icon}>
        <CustomIcon icon={customIcons.CardsStack} />
      </div>

      <div className={s.BankCard__content}>
        <span className={s.BankCard__contentText}>{bankAccount.organization_name}</span>
        <span className={clsx(s.BankCard__contentText, s.BankCard__contentText_small)}>
          {bankAccount.account_number}
        </span>
        <span className={s.BankCard__contentCaption}>{bankAccount.branch_name}</span>
      </div>

      <ContextMenu items={contextMenuItems} loading={deleteLoading} />
    </div>
  );
};
