import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { Text } from 'common/components/Text';
import { Colors } from 'common/utils/colors';
import { FormInput, FormInputSelect, FormSelect, InputLayout } from 'common/components/inputs';
import { BankAccountTypeEnum, Seller } from 'store/graphql';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo } from 'react';
import { Loader, LoaderSize } from 'common/components/Loader/Loader';
import { useBank } from '../../../hooks/useBank';
import { useBranch } from '../../../hooks/useBranch';
import { BankAccountSchemaKeys } from '../schema';
import { UseBankAccountFormReturn } from '../useBankAccountForm';
import { accountTypeIntlId } from '../../../constants';
import { useUser } from '../../../../../../entities/user';
import s from './BaseBankAccountForm.module.scss';

export interface BaseBankAccountFormProps extends UseBankAccountFormReturn {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Состояние открытости модального окна. Используется в хуке обработки закрытия для сброса формы
   */
  isOpen?: boolean;
}

export const BaseBankAccountForm: ReactFCC<BaseBankAccountFormProps> = (props) => {
  const { className, initialBankAccount, formik, isOpen } = props;

  const intl = useIntl();

  const { user } = useUser();
  const seller = user?.seller as Seller;

  const formSetFieldValue = formik.setFieldValue;
  const formReset = formik.resetForm;

  /**
   * Начальные значения банка
   */
  const initialBank = useMemo(
    () =>
      initialBankAccount?.organization_name &&
      initialBankAccount?.organization_code &&
      initialBankAccount?.organization_hiragana
        ? {
            organization_name: initialBankAccount.organization_name,
            organization_code: initialBankAccount.organization_code,
            organization_hiragana: initialBankAccount.organization_hiragana
          }
        : undefined,
    [
      initialBankAccount?.organization_code,
      initialBankAccount?.organization_hiragana,
      initialBankAccount?.organization_name
    ]
  );

  /**
   * Обработка инпутов для банка
   */
  const {
    bankNameInput,
    setBankNameInput,
    bankCodeInput,
    setBankCodeInput,
    bankNameHiraganaInput,
    setBankNameHiraganaInput,
    bankNameOptions,
    bankCodeOptions,
    bankNameHiraganaOptions,
    bank,
    banksLoading,
    bankSearchError
  } = useBank({
    initialBank,
    bankName: formik.values.organization_name,
    bankCode: formik.values.organization_code,
    bankNameHiragana: formik.values.organization_hiragana
  });

  /**
   * Выводим ошибку при отсутствии какого-либо поля, либо когда банк не найден
   */
  const bankError =
    formik.errors.organization_name ||
    formik.errors.organization_code ||
    formik.errors.organization_hiragana ||
    (!banksLoading && bankSearchError ? 'Nothing has been found. Maybe you should try the other fields' : undefined);

  /**
   * При получении банка заполняем все остальные поля
   */
  useEffect(() => {
    if (bank) {
      formSetFieldValue(BankAccountSchemaKeys.organization_name, bank.organization_name);
      formSetFieldValue(BankAccountSchemaKeys.organization_code, bank.organization_code);
      formSetFieldValue(BankAccountSchemaKeys.organization_hiragana, bank.organization_hiragana);
      setBankNameInput(bank.organization_name);
      setBankCodeInput(bank.organization_code);
      setBankNameHiraganaInput(bank.organization_hiragana);
    }
  }, [bank, formSetFieldValue, setBankCodeInput, setBankNameHiraganaInput, setBankNameInput]);

  /**
   * Начальные значения отделения
   */
  const initialBranch = useMemo(
    () =>
      initialBankAccount?.branch_name && initialBankAccount?.branch_code && initialBankAccount?.branch_hiragana
        ? {
            branch_name: initialBankAccount.branch_name,
            branch_code: initialBankAccount.branch_code,
            branch_hiragana: initialBankAccount.branch_hiragana
          }
        : undefined,
    [initialBankAccount?.branch_code, initialBankAccount?.branch_hiragana, initialBankAccount?.branch_name]
  );

  /**
   * Обработка инпутов для отделения
   */
  const {
    branchNameInput,
    setBranchNameInput,
    branchCodeInput,
    setBranchCodeInput,
    branchNameHiraganaInput,
    setBranchNameHiraganaInput,
    branchNameOptions,
    branchCodeOptions,
    branchNameHiraganaOptions,
    branch,
    branchesLoading,
    branchSearchError
  } = useBranch({
    initialBranch,
    branchName: formik.values.branch_name,
    branchCode: formik.values.branch_code,
    branchNameHiragana: formik.values.branch_hiragana,
    bankCode: formik.values.organization_code
  });

  /**
   * Нельзя вводить отделение до ввода банка
   */
  const branchDisabled = !bank;

  /**
   * Выводим ошибку при отсутствии какого-либо поля, либо когда отделение не найдено
   */
  const branchError =
    formik.errors.branch_name ||
    formik.errors.branch_code ||
    formik.errors.branch_hiragana ||
    (!branchesLoading && branchSearchError
      ? 'Nothing has been found. Maybe you should try the other fields'
      : undefined);

  /**
   * При получении отделения заполняем все остальные поля
   */
  useEffect(() => {
    if (branch) {
      formSetFieldValue(BankAccountSchemaKeys.branch_name, branch.branch_name);
      formSetFieldValue(BankAccountSchemaKeys.branch_code, branch.branch_code);
      formSetFieldValue(BankAccountSchemaKeys.branch_hiragana, branch.branch_hiragana);
      setBranchNameInput(branch.branch_name);
      setBranchCodeInput(branch.branch_code);
      setBranchNameHiraganaInput(branch.branch_hiragana);
    }
  }, [branch, formSetFieldValue, setBranchCodeInput, setBranchNameHiraganaInput, setBranchNameInput]);

  /**
   * Сброс отделения
   */
  const resetBranch = useCallback(() => {
    setBranchNameInput('');
    setBranchCodeInput('');
    setBranchNameHiraganaInput('');
    formSetFieldValue(BankAccountSchemaKeys.branch_name, '');
    formSetFieldValue(BankAccountSchemaKeys.branch_code, '');
    formSetFieldValue(BankAccountSchemaKeys.branch_hiragana, '');
  }, [formSetFieldValue, setBranchCodeInput, setBranchNameHiraganaInput, setBranchNameInput]);

  /**
   * Вывод сообщения при ошибке запроса банков/отделений
   * возможно пригодится
   */
  // useLayoutEffect(() => {
  //   if (bankFetchError) {
  //     formSetFieldError(BankAccountSchemaKeys.organization_name, fetchErrorMessage);
  //   }
  //
  //   if (branchFetchError) {
  //     formSetFieldError(BankAccountSchemaKeys.branch_name, fetchErrorMessage);
  //   }
  // }, [bankFetchError, branchFetchError, formSetFieldError]);

  /**
   * Сброс значений при закрытии модалки
   */
  useEffect(() => {
    if (isOpen === false) {
      setBankNameInput('');
      setBankCodeInput('');
      setBankNameHiraganaInput('');
      setBranchNameInput('');
      setBranchCodeInput('');
      setBranchNameHiraganaInput('');
      formReset();
    }
  }, [
    formReset,
    isOpen,
    setBankCodeInput,
    setBankNameHiraganaInput,
    setBankNameInput,
    setBranchCodeInput,
    setBranchNameHiraganaInput,
    setBranchNameInput
  ]);

  return (
    <div className={clsx(s.BaseBankAccountForm, className)}>
      <Text
        className={s.BaseBankAccountForm__label}
        tooltip={
          'Name fields are automatically embedded. Make sure that they match the following props. Withdrawal is only possible for bank accounts opened in User’s name.'
        }
        tooltipProps={{
          placement: 'bottom'
        }}
      >
        Your name
      </Text>

      <div className={s.BaseBankAccountForm__nameList}>
        <div className={s.BaseBankAccountForm__nameRow}>
          <Text component={'span'} color={Colors.GRAY_600}>
            Kanji/Latin
          </Text>
          <Text component={'span'}>
            {seller.name} {seller.secondName}
          </Text>
        </div>

        <div className={s.BaseBankAccountForm__nameRow}>
          <Text component={'span'} color={Colors.GRAY_600}>
            Hiragana
          </Text>
          <Text component={'span'}>
            {seller.nameHiragana} {seller.secondNameHiragana}
          </Text>
        </div>
      </div>

      <div className={s.BaseBankAccountForm__group}>
        <InputLayout
          className={s.BaseBankAccountForm__groupLayout}
          label={'Financial organization'}
          description={'Start typing in one of the three fields, the rest will be filled in automatically'}
          error={bankError}
          required
        >
          {banksLoading && <Loader className={s.BaseBankAccountForm__groupLoader} size={LoaderSize.SMALL} />}

          <div className={s.BaseBankAccountForm__groupInputs}>
            <FormInputSelect
              name={BankAccountSchemaKeys.organization_name}
              placeholder={'Name'}
              options={bankNameOptions || []}
              value={bankNameInput}
              onChange={(e) => {
                setBankNameInput(e.target.value);
                setBankCodeInput('');
                setBankNameHiraganaInput('');
                formik.setFieldValue(BankAccountSchemaKeys.organization_name, '');
                formik.setFieldValue(BankAccountSchemaKeys.organization_code, '');
                formik.setFieldValue(BankAccountSchemaKeys.organization_hiragana, '');
                resetBranch();
              }}
              onItemSelect={(option) =>
                option.value && formik.setFieldValue(BankAccountSchemaKeys.organization_name, option.value)
              }
            />
            <FormInputSelect
              name={BankAccountSchemaKeys.organization_code}
              placeholder={'Code'}
              options={bankCodeOptions || []}
              value={bankCodeInput}
              onChange={(e) => {
                setBankCodeInput(e.target.value);
                setBankNameInput('');
                setBankNameHiraganaInput('');
                formik.setFieldValue(BankAccountSchemaKeys.organization_name, '');
                formik.setFieldValue(BankAccountSchemaKeys.organization_code, '');
                formik.setFieldValue(BankAccountSchemaKeys.organization_hiragana, '');
                resetBranch();
              }}
              onItemSelect={(option) =>
                option.value && formik.setFieldValue(BankAccountSchemaKeys.organization_code, option.value)
              }
            />
          </div>
        </InputLayout>
      </div>

      <div className={s.BaseBankAccountForm__group}>
        <InputLayout
          className={s.BaseBankAccountForm__groupLayout}
          label={'Branch'}
          description={'Start typing in one of the three fields, the rest will be filled in automatically'}
          error={branchError}
        >
          {branchesLoading && <Loader className={s.BaseBankAccountForm__groupLoader} size={LoaderSize.SMALL} />}

          <div className={s.BaseBankAccountForm__groupInputs}>
            <FormInputSelect
              name={BankAccountSchemaKeys.branch_name}
              placeholder={'Name'}
              options={branchNameOptions || []}
              value={branchNameInput}
              onChange={(e) => {
                setBranchNameInput(e.target.value);
                setBranchCodeInput('');
                setBranchNameHiraganaInput('');
                formik.setFieldValue(BankAccountSchemaKeys.branch_name, '');
                formik.setFieldValue(BankAccountSchemaKeys.branch_code, '');
                formik.setFieldValue(BankAccountSchemaKeys.branch_hiragana, '');
              }}
              onItemSelect={(option) =>
                option.value && formik.setFieldValue(BankAccountSchemaKeys.branch_name, option.value)
              }
              disabled={branchDisabled}
            />
            <FormInputSelect
              name={BankAccountSchemaKeys.branch_code}
              placeholder={'Code'}
              options={branchCodeOptions || []}
              value={branchCodeInput}
              onChange={(e) => {
                setBranchNameInput('');
                setBranchCodeInput(e.target.value);
                setBranchNameHiraganaInput('');
                formik.setFieldValue(BankAccountSchemaKeys.branch_name, '');
                formik.setFieldValue(BankAccountSchemaKeys.branch_code, '');
                formik.setFieldValue(BankAccountSchemaKeys.branch_hiragana, '');
              }}
              onItemSelect={(option) =>
                option.value && formik.setFieldValue(BankAccountSchemaKeys.branch_code, option.value)
              }
              disabled={branchDisabled}
            />
          </div>
        </InputLayout>
      </div>

      <div className={s.BaseBankAccountForm__group}>
        <div className={clsx(s.BaseBankAccountForm__groupInputs, s.BaseBankAccountForm__groupInputs_double)}>
          <FormSelect
            name={BankAccountSchemaKeys.account_type}
            label={'Account type'}
            placeholder={'Account type'}
            options={Object.values(BankAccountTypeEnum).map((type) => ({
              title: intl.formatMessage({ id: accountTypeIntlId[type] }),
              value: type
            }))}
            value={formik.values.account_type}
            onItemSelect={(option) => formik.setFieldValue(BankAccountSchemaKeys.account_type, option.value)}
            error={formik.errors.account_type}
            required
          />

          <FormInput
            type={'number'}
            name={BankAccountSchemaKeys.account_number}
            label={'Account number'}
            placeholder={'Account number'}
            onChange={formik.handleChange}
            value={formik.values.account_number}
            error={formik.errors.account_number}
            required
          />
        </div>
      </div>

      <Text className={s.BaseBankAccountForm__label}>Restrictions</Text>
      <Text color={Colors.SECONDARY}>
        The name of the bank account holder must match the username. You cannot withdraw funds to someone else's bank
        account. In case of name change, a repeated verification procedure is required. In case of providing erroneous
        data, withdrawal of funds is impossible and there is a possibility of charging an additional commission.
      </Text>
    </div>
  );
};
