import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { Card as CardBootstrap } from 'react-bootstrap';
import React, { ReactNode, useMemo } from 'react';
import { useHref, useLinkClickHandler } from 'react-router-dom';
import { ProductStatusEnum } from '../../../../store/graphql';
import { ProductType } from '../../types';
import { PathBuilder } from '../../../../common/utils/routes';
import { ProductBadge } from '../ProductBadge';
import { useCurrency } from '../../../../store/currency';
import { Loader, LoaderSize } from '../../../../common/components/Loader';
import { useIsMobile } from '../../../../common/hooks/useIsMobile';
import s from './ProductShowcaseCard.module.scss';

export interface ProductShowcaseCardProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Объект товара из API
   */
  product: ProductType;
  /**
   * Рендер дополнительного контента
   */
  after?: ReactNode | string;
}

const placeholderImageUrl = `https://via.placeholder.com/304x200`;

export const ProductShowcaseCard: ReactFCC<ProductShowcaseCardProps> = (props) => {
  const isMobile = useIsMobile();
  const { className, product, after } = props;

  const { convertCurrency, currencyLoading } = useCurrency();

  const photo = useMemo(() => {
    if (product.mainPhoto) {
      return product.mainPhoto;
    }

    return product.photos ? product.photos[0] : undefined;
  }, [product.mainPhoto, product.photos]);

  let href = useHref(PathBuilder.getProductPath(product.id));
  let handleClick = useLinkClickHandler(PathBuilder.getProductPath(product.id), {
    state: { from: window.location.pathname }
  });

  return (
    <div className={clsx(s.ProductShowcaseCard, className)}>
      <a className={clsx(s.ProductShowcaseCard__card, 'card')} href={href} onClick={handleClick}>
        <CardBootstrap.Img variant="top" className={s.ProductShowcaseCard__imageWrap} as={'div'}>
          <img
            className={s.ProductShowcaseCard__image}
            loading={'lazy'}
            height={isMobile ? '162px' : '200px'}
            width={isMobile ? '182px' : '304px'}
            src={
              isMobile
                ? photo?.thumbnails?.S?.url || placeholderImageUrl
                : photo?.thumbnails?.M?.url || placeholderImageUrl
            }
            alt={product?.name || 'Ariosra-product'}
          />

          {product.status === ProductStatusEnum.Sold && <ProductBadge className={s.ProductShowcaseCard__soldBadge} />}
        </CardBootstrap.Img>

        <CardBootstrap.Body>
          {currencyLoading ? (
            <Loader size={LoaderSize.SMALL} />
          ) : (
            <CardBootstrap.Title className={s.ProductShowcaseCard__title}>
              {convertCurrency(product.price)}
            </CardBootstrap.Title>
          )}

          <CardBootstrap.Text className={s.ProductShowcaseCard__text}>{product.name}</CardBootstrap.Text>
        </CardBootstrap.Body>
      </a>

      {after}
    </div>
  );
};
