import { InferType, object, string } from 'yup';
import { validateErrors } from '../../../common/utils/validate';
import { TicketTopicEnum } from '../../../store/graphql';

export const ticketAttributeLimits = {
  firstMessage: {
    min: 10,
    max: 500
  },
  name: {
    min: 5,
    max: 25
  }
};

const validateObject = {
  name: string()
    .min(ticketAttributeLimits.name.min)
    .max(ticketAttributeLimits.name.max)
    .defined(validateErrors.required),
  topic: string().defined(validateErrors.required),
  firstMessage: string()
    .min(ticketAttributeLimits.firstMessage.min)
    .max(ticketAttributeLimits.firstMessage.max)
    .defined(validateErrors.required)
};

export const CreateTicketSchema = object(validateObject);

export type TCreateTicketValues = InferType<typeof CreateTicketSchema>;

export const CreateTicketSchemaKeys = Object.fromEntries(
  Object.keys(validateObject).map((key) => [key, key])
) as Record<keyof TCreateTicketValues, string>;

export const ticketTopics: { title: string; value: TicketTopicEnum }[] = [
  ...Object.values(TicketTopicEnum)
    .map((topic) => {
      return {
        title: topic,
        value: topic
      };
    })
    .filter((ticket) => ticket.value !== TicketTopicEnum.Other),
  { title: TicketTopicEnum.Other, value: TicketTopicEnum.Other }
];
