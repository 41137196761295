import React, { useMemo, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { ArisoraOrderStatus, OrderContent, useSellerOrdersQuery } from '../../../../store/graphql';
import { ReactFCC } from '../../../../common/utils/helperTypes';
import { DEFAULT_PAGE_LIMIT, usePagination } from '../../../../common/components/Pagination';
import { ESpaceSize, Space } from '../../../../common/components/Space/Space';
import { LoaderBox } from '../../../../common/components/Loader';
import { OrderCard } from '../../../../entities/order';
import { Button, ButtonVariant } from '../../../../common/components/Button';
import { FormCheckbox } from '../../../../common/components/inputs';
import { clearCache } from '../../../../app/providers/auth-apollo';
import { Heading, HeadingSize } from '../../../../common/components/Heading';
import { PickupRequestForm } from '../../../../widgets/pickupRequest/PickupRequestForm';
import s from './ReadyToSendOrders.module.scss';

const mutationOptions = {
  refetchQueries: ['Order', 'SellerOrder'],
  update: clearCache(['user'])
};
export const ReadyToSendOrders: ReactFCC = () => {
  const { offset, setOffset } = usePagination();
  const [readyOrderIds, setReadyOrderIds] = useState<string[]>([]);
  const intl = useIntl();

  const { data, loading } = useSellerOrdersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        pagination: {
          limit: DEFAULT_PAGE_LIMIT,
          offset
        }
      }
    }
  });

  const orders = useMemo(() => data?.orders.seller?.orders?.entries || [], [data?.orders.seller?.orders?.entries]);
  const readyToSendOrders = orders.filter(
    (order) => order.order.status === ArisoraOrderStatus.ReadyForShipment && order.order.trustedOrder
  );

  const [nextStep, setNextStep] = useState(false);

  const toggleIds = (orderId: string) => {
    if (readyOrderIds.includes(orderId)) {
      setReadyOrderIds((prev) => prev.filter((id) => id !== orderId));
    } else {
      setReadyOrderIds((prev) => [...prev, orderId]);
    }
  };

  return (
    <div className={s.ReadyToSendOrders}>
      <Space size={ESpaceSize.PIXEL_16} />

      {loading ? (
        <LoaderBox />
      ) : !nextStep ? (
        <>
          {readyToSendOrders.length > 0 && (
            <>
              <span className={s.ReadyToSendOrders__readyGuide}>
                <FormattedMessage id={'pickupRequest.readyOrders'} />
              </span>
              <Space size={ESpaceSize.PIXEL_16} />
              <div className={s.ReadyToSendOrders__content}>
                {readyToSendOrders?.map(({ order, contents }, index) => (
                  <OrderCard
                    key={index}
                    headerLeft={
                      <FormCheckbox
                        classes={{ checkbox: s.ReadyToSendOrders__checkbox }}
                        onChange={() => toggleIds(order.id)}
                      />
                    }
                    id={order.ID}
                    entityId={order.id}
                    date={order.orderDate}
                    status={order.status}
                    isProblem={order.isProblem}
                    contents={contents as OrderContent[]}
                    customerId={order.customerId}
                    customerNickname={order.customerNickname}
                    isSeller
                  />
                ))}
              </div>
              <Space size={ESpaceSize.PIXEL_40} />
              <div className={s.ReadyToSendOrders__controls}>
                {readyOrderIds.length > 0 && (
                  <Button variant={ButtonVariant.PRIMARY_OUTLINE} onClick={() => setNextStep(true)}>
                    <FormattedMessage id={'pickupRequest.readyNextStep'} />
                  </Button>
                )}
              </div>
            </>
          )}
          {!readyToSendOrders.length && (
            <Heading size={HeadingSize.H5}>
              {' '}
              <FormattedMessage id={'pickupRequest.noOrders'} />
            </Heading>
          )}
        </>
      ) : (
        <>
          <PickupRequestForm readyOrderIds={readyOrderIds} nextStep={nextStep} />
        </>
      )}
    </div>
  );
};
