import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { CookieBanner } from '../CookieBanner';
import { useUi } from '../../../../store/ui';
import { useWebview } from '../../../../store/webview/useWebview';
import { OPEN_MOBILE_APP_ROUTE } from '../../../../common/utils/routes';
import s from './DefaultLayout.module.scss';

const hideTopNavPaths = [OPEN_MOBILE_APP_ROUTE];
const hideMobileNavPaths = [OPEN_MOBILE_APP_ROUTE];
const hideFooterPaths = [OPEN_MOBILE_APP_ROUTE];

export function DefaultLayout() {
  const { pathname } = useLocation();
  const { showTopNav, setShowTopNav, showMobileNav, setShowMobileNav, showFooter, setShowFooter } = useUi();
  const { isWebview } = useWebview();

  useEffect(() => {
    setShowTopNav(!hideTopNavPaths.some((path) => pathname.startsWith(path)));
    setShowMobileNav(!hideMobileNavPaths.some((path) => pathname.startsWith(path)));
    setShowFooter(!hideFooterPaths.some((path) => pathname.startsWith(path)));
  }, [pathname, setShowFooter, setShowMobileNav, setShowTopNav]);

  return (
    <div className={s.DefaultLayout}>
      <Header showTopNav={showTopNav} showMobileNav={showMobileNav} />

      <Container
        className={clsx({
          [s.DefaultLayout__container_webview]: isWebview
        })}
      >
        <Outlet />

        <CookieBanner />
      </Container>

      {!isWebview && showFooter && <Footer />}
    </div>
  );
}
