import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Heading } from '../../../../../common/components/Heading';
import { Head } from '../../../../../common/components/Head';
import { Grid } from '../../../../../common/components/Grid';
import { Text } from '../../../../../common/components/Text';
import { ESpaceSize, Space } from '../../../../../common/components/Space/Space';
import { LinkBack } from '../../../../../common/components/LinkBack';
import s from './TrustPointsGuidePage.module.scss';

export const TrustPointsGuidePage = () => {
  return (
    <Grid cols={9} className={s.TrustPointsGuidePage}>
      <Head title={'Trust points guide'} />
      <Grid.GridItem cols={{ xs: 2, lg: 3 }}>
        <LinkBack>
          <FormattedMessage id="general.back" />
        </LinkBack>
      </Grid.GridItem>
      <Grid.GridItem cols={{ xs: 3, lg: 9 }}>
        <Heading>Trust points guide</Heading>
        <Space size={ESpaceSize.PIXEL_12} />
      </Grid.GridItem>
      <Grid.GridItem cols={{ xs: 3, lg: 9 }}>
        <Text>There should be guide info in the future</Text>
      </Grid.GridItem>
    </Grid>
  );
};
