import React from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal } from '../../../../../common/components/Modal';
import { ReactFCC } from '../../../../../common/utils/helperTypes';
import { ButtonVariant } from '../../../../../common/components/Button';
import { FormInput } from '../../../../../common/components/inputs';
import { Anchor } from '../../../../../common/components/Anchor';
import { AuthModalBaseProps } from '../types';
import { AuthModalEnterCodeKeys, AuthModalEnterCodeSchema } from './authModalEnterCodeSchema';
import s from './AuthModalEnterCode.module.scss';

export interface AuthModalEnterCodeProps extends AuthModalBaseProps {
  error?: boolean;
  email?: string;
  resendCode?: () => void;
}

const wrongCodeMessage = 'The code is outdated or entered incorrectly';

export const AuthModalEnterCode: ReactFCC<AuthModalEnterCodeProps> = (props) => {
  const { error, email, onSubmit, onGoBack, resendCode } = props;

  const intl = useIntl();

  return (
    <Formik
      initialValues={{
        code: ''
      }}
      validationSchema={AuthModalEnterCodeSchema}
      validateOnMount={false}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={onSubmit}
    >
      {({ values, errors, isSubmitting, handleChange, submitForm }) => (
        <>
          <Form>
            <Modal.Body>
              <p>
                <FormattedMessage
                  id="auth.codeText"
                  values={{
                    email,
                    b: (chunks) => <b>{chunks}</b>,
                    a: (chunks) => (
                      <Anchor component="span" onClick={resendCode}>
                        {chunks}
                      </Anchor>
                    )
                  }}
                />
              </p>

              <FormInput
                type="text"
                name={AuthModalEnterCodeKeys.code}
                label={intl.formatMessage({ id: 'auth.codeLabel' })}
                placeholder={intl.formatMessage({ id: 'auth.codePlaceholder' })}
                value={values.code}
                onChange={handleChange}
                error={errors.code || (error ? wrongCodeMessage : undefined)}
                required
              />
            </Modal.Body>
          </Form>
          <Modal.Footer className={s.AuthModalEnterCode__footer}>
            <Modal.Button variant={ButtonVariant.PRIMARY_OUTLINE} onClick={onGoBack}>
              <FormattedMessage id="auth.codeBackButton" />
            </Modal.Button>

            <Modal.Button type={'submit'} loading={isSubmitting} onClick={submitForm}>
              <FormattedMessage id="auth.codeSubmitButton" />
            </Modal.Button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};
