import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';

import { isEmpty } from 'lodash-es';
import { toast } from 'react-toastify';
import { FormCheckbox } from '../../../../../../common/components/inputs';
import { Modal } from '../../../../../../common/components/Modal';
import { ESpaceSize, Space } from '../../../../../../common/components/Space/Space';
import { OrderCard } from '../../../../../../entities/order';
import { OrderContent, useConfirmPackagesSendingMutation, useSellerOrdersQuery } from '../../../../../../store/graphql';
import { Button, ButtonFit, ButtonVariant } from '../../../../../../common/components/Button';
import { useIsMobile } from '../../../../../../common/hooks/useIsMobile';
import { LoaderBox } from '../../../../../../common/components/Loader';
import { clearCache } from '../../../../../../app/providers/auth-apollo';
import s from './PickupRequestConfirmModal.module.scss';

export interface PickupRequestConfirmModalProps {
  /**
   * Состояние открытого/закрытого модального окна
   */
  isOpen: boolean;
  /**
   * Действие при закрытии модального окна
   */
  onClose: () => void;
  /**
   * Опциональный дополнительный класс на компонент
   */
  className?: string;
  orderIds: string[];
  pickupUuid: string;
}
const mutationOptions = {
  refetchQueries: ['PickupRequestExtended'],
  update: clearCache(['pickupRequestExtended'])
};
export const PickupRequestConfirmModal = (props: PickupRequestConfirmModalProps) => {
  const { isOpen, onClose, className, orderIds, pickupUuid } = props;

  const intl = useIntl();

  const isMobile = useIsMobile();

  const [isAllOrdersSent, setIsAllOrdersSent] = useState(true);
  const [sentOrderIds, setSentOrderIds] = useState<string[]>(orderIds);

  const { data: ordersQuery, loading: ordersLoading } = useSellerOrdersQuery({
    skip: !orderIds,
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        ids: orderIds
      }
    }
  });

  const [confirmPickup, { loading: confirmPickupLoading }] = useConfirmPackagesSendingMutation(mutationOptions);

  const orders = ordersQuery?.orders.seller?.orders.entries;

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const toggleIds = (orderId: string) => {
    if (sentOrderIds.includes(orderId)) {
      setSentOrderIds((prev) => prev.filter((id) => id !== orderId));
    } else {
      setSentOrderIds((prev) => [...prev, orderId]);
    }
  };

  const confirmPickupSending = async () => {
    if (!pickupUuid || isEmpty(sentOrderIds)) {
      return;
    }

    try {
      await confirmPickup({
        variables: {
          input: {
            orderIds: sentOrderIds,
            pickupUuid
          }
        }
      });
      toast.success('Thank you for sending your order!');
      closeModal();
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  return (
    <Modal
      className={clsx(className, s.PickupRequestConfirmModal)}
      title={'Confirm orders sending'}
      isOpen={isOpen}
      onClose={closeModal}
    >
      <Modal.Body>
        <div className={s.PickupRequestConfirmModal__content}>
          {ordersLoading ? (
            <LoaderBox />
          ) : (
            <>
              <FormattedMessage id={'pickupRequestExtended.modal.allIsSent'} />
              <Space size={ESpaceSize.PIXEL_12} />
              <div className={s.PickupRequestConfirmModal__group_radio}>
                <FormCheckbox
                  type={'radio'}
                  name={'All orders'}
                  label={intl.formatMessage({ id: 'pickupRequestExtended.modal.yes' })}
                  checked={isAllOrdersSent}
                  onChange={() => {
                    setIsAllOrdersSent(true);
                    setSentOrderIds(orderIds);
                  }}
                />

                <FormCheckbox
                  type={'radio'}
                  name={'All orders'}
                  label={intl.formatMessage({ id: 'pickupRequestExtended.modal.no' })}
                  checked={!isAllOrdersSent}
                  onChange={() => {
                    setIsAllOrdersSent(false);
                    setSentOrderIds([]);
                  }}
                />
              </div>
              <Space size={ESpaceSize.PIXEL_12} />
              {!isAllOrdersSent && (
                <>
                  <FormattedMessage id={'pickupRequestExtended.modal.select'} />
                  <Space size={ESpaceSize.PIXEL_12} />
                  {orders &&
                    orders.map((order) => (
                      <div className={s.PickupRequestConfirmModal__orderCard}>
                        <OrderCard
                          id={order.order.ID}
                          entityId={order.order.id}
                          date={order.order.orderDate}
                          status={order.order.status}
                          contents={order.contents as OrderContent[]}
                          isDetailed={true}
                          compact={true}
                          headerLeft={
                            <FormCheckbox
                              classes={{ checkbox: s.PickupRequestConfirmModal__checkbox }}
                              onChange={() => toggleIds(order.order.id)}
                            />
                          }
                        />
                        <Space size={ESpaceSize.PIXEL_16} />
                      </div>
                    ))}
                </>
              )}
              <Space size={ESpaceSize.PIXEL_16} />
              <div className={s.PickupRequestConfirmModal__controls}>
                <Button
                  variant={ButtonVariant.PRIMARY}
                  fit={isMobile ? ButtonFit.FILL : ButtonFit.FIT}
                  loading={confirmPickupLoading}
                  onClick={() => confirmPickupSending()}
                >
                  Confirm
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
