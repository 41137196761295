import React, { LegacyRef, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import clsx from 'clsx';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { useMeasure } from 'react-use';
import { Alert } from '../../../../common/components/Alert';
import { DebtOrderType, ordersDebtsVariantIntlNames } from '../../../../common/constants/debt';
import { CustomIcon } from '../../../../common/components/CustomIcon';
import { EBreakpoints, useBreakpoint } from '../../../../common/hooks/useBreakpoint';
import { PathBuilder } from '../../../../common/utils/routes';
import s from './OrderDebts.module.scss';

export interface OrderDebtsProps {
  debtOrders: DebtOrderType[];
  isSeller?: boolean;
}

export const OrderDebts = ({ debtOrders, isSeller }: OrderDebtsProps) => {
  const navigate = useNavigate();
  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);
  const [scrollPos, setScrollPos] = useState({ start: true, end: false });
  const [contentRef, { width: contentWidth }] = useMeasure();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const itemRef = useRef<HTMLDivElement | null>(null);
  const showButtons = !isMobile && contentWidth > (scrollRef.current?.clientWidth || 0);

  const handleScrollButtonClick = useCallback((isNext: boolean) => {
    if (scrollRef.current && itemRef.current) {
      const currScrollLeft = scrollRef.current.scrollLeft || 0;
      let scrollOffset = itemRef.current.clientWidth || 0;
      if (!isNext) {
        scrollOffset = -scrollOffset;
      }
      scrollRef.current?.scrollTo({
        left: currScrollLeft + scrollOffset,
        behavior: 'smooth'
      });
    }
  }, []);

  const handleScrolled = useCallback(() => {
    const scrollWidth = scrollRef.current?.scrollWidth || 0;
    const scrollNodeWidth = scrollRef.current?.clientWidth || 0;
    const currScrollLeft = scrollRef.current?.scrollLeft || 0;
    setScrollPos({
      start: currScrollLeft === 0,
      end: scrollWidth - currScrollLeft - scrollNodeWidth === 0
    });
  }, []);

  useEffect(() => {
    const scrollNode = scrollRef.current;
    scrollNode?.addEventListener('scroll', handleScrolled);
    return () => {
      scrollNode?.removeEventListener('scroll', handleScrolled);
    };
  }, [handleScrolled]);

  return (
    <div className={s.OrderDebts}>
      {showButtons && !scrollPos.start && (
        <div className={clsx(s.OrderDebts__scrollButton, s.OrderDebts__scrollButton_prev)}>
          <CustomIcon
            className={s.OrderDebts__scrollButtonIcon}
            onClick={() => handleScrollButtonClick(false)}
            icon={BsChevronLeft}
          />
        </div>
      )}
      <div className={s.OrderDebts__scroll} ref={scrollRef}>
        <div className={s.OrderDebts__items} ref={contentRef as LegacyRef<HTMLDivElement>}>
          {debtOrders.map(({ entityId, orderId, variant }, i) => (
            <div key={i} className={s.OrderDebts__item} ref={i === 0 ? itemRef : undefined}>
              <Alert
                className={s.OrderDebts__card}
                onClick={() => navigate(PathBuilder.getOrderPath(entityId, isSeller))}
              >
                <FormattedMessage id={ordersDebtsVariantIntlNames[variant]} values={{ orderId }} />
              </Alert>
            </div>
          ))}
        </div>
      </div>
      {showButtons && !scrollPos.end && (
        <div className={clsx(s.OrderDebts__scrollButton, s.OrderDebts__scrollButton_next)}>
          <CustomIcon
            className={s.OrderDebts__scrollButtonIcon}
            onClick={() => handleScrollButtonClick(true)}
            icon={BsChevronRight}
          />
        </div>
      )}
    </div>
  );
};
