export const getMimeType = async (file: File): Promise<string> => {
  return await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const arr = new Uint8Array(reader.result as any).subarray(0, 4);
      let header = '';
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      console.log(header);
      switch (header) {
        case '89504e47':
          return resolve('image/png');
        case '47494638':
          return resolve('image/gif');
        case '25504446':
          return resolve('application/pdf');
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffdb':
          return resolve('image/jpeg');
        default:
          return resolve('unknown');
      }
    };
    reader.readAsArrayBuffer(file);
  });
};
