import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Heading, HeadingSize } from 'common/components/Heading/Heading';
import { Anchor } from 'common/components/Anchor/Anchor';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import {
  ArisoraOrderStatus,
  AvailableLanguagesEnum,
  LocalFileType,
  OrderPackages,
  OrderYamatoShipment,
  useCalculateShippingFeeMutation
} from 'store/graphql';
import { Link } from 'common/components/Link/Link';
import clsx from 'clsx';
import { BsBuilding, BsCheckCircleFill, BsPhone } from 'react-icons/bs';
import { format } from 'date-fns';
import { AddressCard, AddressForm, getAddressInformationFromUser, UserAddress } from '../../../../../widgets/address';
import { OrderPageBanner } from '../OrderBanner/OrderPageBanner';
import { HOME_ROUTE } from '../../../../../common/utils/routes';
import { User, useUser } from '../../../../../entities/user';
import { Grid, GridGap } from '../../../../../common/components/Grid';

import { useIsMobile } from '../../../../../common/hooks/useIsMobile';
import { AddressSelectModal } from '../../../../../widgets/address/layouts/AddressSelectLayout/AddressSelectModal';
import { useToggle } from '../../../../../common/hooks/useToggle';
import { ExtractArray } from '../../../../../common/utils/helperTypes';
import { LoaderBox } from '../../../../../common/components/Loader';

import { OrderCombineParcelInput } from '../OrderCombineParcelInput/OrderCombineParcelInput';
import { clearCache } from '../../../../../app/providers/auth-apollo';
import { OrderDimensions } from '../../../../../features/order/yamatoFee/hooks/useYamatoFee';
import { EOpeningBlockStatus, OpeningBlock } from '../../../../../common/components/OpeningBlock/OpeningBlock';
import { Button, ButtonVariant } from '../../../../../common/components/Button';
import { locales } from '../../../../../store/intl';
import {
  OrderCombineYamatoBringIn,
  yamatoLink
} from './components/OrderCombineYamatoBringIn/OrderCombineYamatoBringIn';
import { OrderCombineYamatoPickup } from './components/OrderCombineYamatoPickup/OrderCombineYamatoPickup';
import { yamatoIntervalMapping, yamatoIntervalMappingJp } from './types';
import s from './OrderPageCombineYamato.module.scss';

export type TOrderPageCombineYamato = {
  orderId: string;
  orderID: string;
  orderStatus: ArisoraOrderStatus;
  packages?: OrderPackages | null;
  yamatoShipment:
    | (Omit<OrderYamatoShipment, 'id' | 'qrCode'> & {
        qrCode?: {
          main_file?: Pick<LocalFileType, 'url'> | null;
        } | null;
      })
    | null;
  initialParcels?: any[];
  dimensions: OrderDimensions;
  isYuPacket: boolean;
};

export const OrderPageCombineYamato = (props: TOrderPageCombineYamato) => {
  const { orderId, orderID, orderStatus, packages, dimensions, yamatoShipment, isYuPacket } = props;
  const { user } = useUser();
  const addresses = user?.addresses.filter((address) => address.countryCode === 'JP');
  const [hidePassword, setHidePassword] = useState<boolean>(true);

  const isMobile = useIsMobile();

  const intl = useIntl();

  const isKuroneko = !!packages?.kuroneko;

  const isSent =
    orderStatus === ArisoraOrderStatus.DeliveringWithinJapan ||
    orderStatus === ArisoraOrderStatus.InternationalDelivery ||
    orderStatus === ArisoraOrderStatus.CustomsClearance;

  const deliveryPriceFulfilled = !!packages?.deliveryPriceFulfilled;
  const shipmentCreated = !!yamatoShipment;

  const [stepOneOpen, setStepOneOpen] = useState(true);
  const [stepTwoOpen, setStepTwoOpen] = useState(false);
  const [stepThreeOpen, setStepThreeOpen] = useState(false);
  const [stepFourOpen, setStepFourOpen] = useState(false);

  const [bringInOpen, setBringInOpen] = useState(false);
  const [pickupOpen, setPickupOpen] = useState(false);

  const yamatoPickupTime =
    yamatoShipment?.shukaDate && yamatoShipment.shukaTime
      ? `${
          intl.locale === locales[AvailableLanguagesEnum.Japanese]
            ? yamatoIntervalMappingJp[yamatoShipment.shukaTime]
            : yamatoIntervalMapping[yamatoShipment.shukaTime]
        } - ${format(new Date(yamatoShipment?.shukaDate), 'dd.MM.yyyy')}`
      : null;

  const [selectModalOpen, { set: openSelectModal, unset: closeSelectModal }] = useToggle(false);
  const [selectedAddressId, setSelectedAddressId] = useState<UserAddress['id'] | null>(null);
  const [editedAddress, setEditedAddress] = useState<UserAddress | null>(null);
  const [addressModalOpen, { set: openAddressModal, unset: closeAddressModal }] = useToggle(false);
  const sellerAddress =
    addresses?.find((address) => address.id === selectedAddressId) ||
    addresses?.find((address) => address.sellerAddress);
  const openEditFromSelectRef = useRef(false);
  const onClickEditAddress = (address: ExtractArray<User['addresses']> | undefined) => {
    if (address) {
      setEditedAddress(address);
      openAddressModal();
    }
  };

  const [calculateShippingFee, { loading: calculateShippingFeeLoading }] = useCalculateShippingFeeMutation({
    refetchQueries: ['SellerOrder'],
    update: clearCache(['order'])
  });

  const onSubmit = async () => {
    if (!selectedAddressId) {
      return;
    }

    await calculateShippingFee({
      variables: {
        input: {
          sellerAddressId: selectedAddressId,
          orderId
        }
      }
    });

    closeSelectModal();
  };
  const onClickChangeAddress = () => {
    openSelectModal();
  };

  if (!user) {
    return <LoaderBox />;
  }

  return (
    <>
      <>
        {!isSent ? (
          <OrderPageBanner className={s.OrderPageCombineYamato__banner}>
            <Heading size={HeadingSize.H5}>
              <FormattedMessage id="profile.orderCombineWarehouseAlertTitle" />
            </Heading>

            <OrderPageBanner.Text>
              <FormattedMessage
                id="profile.orderCombineWarehouseAlertText"
                values={{
                  a: (chunks) => <Link to={HOME_ROUTE}>{chunks}</Link>
                }}
              />
            </OrderPageBanner.Text>
          </OrderPageBanner>
        ) : (
          <OrderPageBanner className={s.OrderPageCombineYamato__bannerSuccess}>
            <div>
              <Heading size={HeadingSize.H5}>
                <FormattedMessage id={'orderCombineWarehouse.thanks'} />
              </Heading>
              <OrderPageBanner.Text>
                <FormattedMessage id={'orderCombineWarehouse.thanksSent'} />
              </OrderPageBanner.Text>
            </div>
            <BsCheckCircleFill className={s.OrderPageCombineYamato__bannerSuccess_icon} />
          </OrderPageBanner>
        )}

        <Space size={ESpaceSize.PIXEL_24} />
        <div className={s.OrderPageCombineYamato__badge}>
          <span className={s.OrderPageCombineYamato__badgeAmount}>
            <FormattedMessage id={'profile.orderCombineWarehouseShippingText'} />
          </span>
        </div>
        <Grid cols={{ xs: 1, md: 12, lg: 12 }} gap={GridGap.MEDIUM}>
          <Grid.GridItem cols={{ xs: 1, md: 6, lg: 6 }}>
            <div className={s.OrderPageCombineYamato__addressOption}>
              <span className={s.OrderPageCombineYamato__addressTitle}>
                <FormattedMessage id={'orderCombineWarehouse.from'} />
              </span>
              <div className={s.OrderPageCombineYamato__addressBlock}>
                {user && sellerAddress && (
                  <AddressCard
                    className={clsx(s.OrderPageCombineYamato__addressItem)}
                    address={sellerAddress}
                    onClickEdit={() => onClickEditAddress(sellerAddress)}
                    interactive={!isSent}
                    disableEdit={isSent}
                    disableRichEdit
                    selected
                  />
                )}
                {!isSent && (
                  <Anchor
                    className={s.AddressSelectSlot__button}
                    component={'button'}
                    onClick={() => onClickChangeAddress()}
                  >
                    <FormattedMessage id={'address.select.buttonGeneral'} />
                  </Anchor>
                )}
              </div>
            </div>
          </Grid.GridItem>
        </Grid>
        <Space size={ESpaceSize.PIXEL_24} />
        <div className={s.OrderPageCombineYamato__badge}>
          <span className={s.OrderPageCombineYamato__badgeAmount}>
            <FormattedMessage id={'profile.orderCombineWarehouseParcelsText'} />
          </span>
        </div>
        <OpeningBlock
          title={intl.formatMessage({ id: 'orderCombine.recalculate' })}
          status={deliveryPriceFulfilled ? EOpeningBlockStatus.DONE : EOpeningBlockStatus.ACTIVE}
          number={1}
          isOpen={stepOneOpen || !deliveryPriceFulfilled}
          setOpen={setStepOneOpen}
        >
          {sellerAddress && (
            <Grid cols={{ xs: 1, md: 12, lg: 12 }} gap={GridGap.MEDIUM}>
              <Grid.GridItem cols={{ xs: 1, md: 12, lg: 12 }}>
                <OrderCombineParcelInput
                  sellerAddressId={sellerAddress.id}
                  orderId={orderId}
                  isSent={isSent}
                  packages={packages}
                  dimensions={dimensions}
                  trustedOrder={false}
                  isYuPacket={isYuPacket}
                />
              </Grid.GridItem>
            </Grid>
          )}
        </OpeningBlock>
        <Space size={ESpaceSize.PIXEL_16} />
        <OpeningBlock
          title={intl.formatMessage({ id: 'orderCombine.bringOption' })}
          status={!yamatoShipment ? EOpeningBlockStatus.ACTIVE : EOpeningBlockStatus.DONE}
          number={2}
          isOpen={stepTwoOpen || deliveryPriceFulfilled}
          setOpen={setStepTwoOpen}
        >
          {!yamatoShipment && !bringInOpen && !pickupOpen ? (
            <div className={s.OrderPageCombineYamato__deliveryOptions}>
              <Button
                onClick={() => setBringInOpen(true)}
                variant={ButtonVariant.PRIMARY_OUTLINE}
                rightIcon={BsBuilding}
              >
                Bring-In
              </Button>
              {!isKuroneko && (
                <Button onClick={() => setPickupOpen(true)} variant={ButtonVariant.PRIMARY_OUTLINE} rightIcon={BsPhone}>
                  Courier Request
                </Button>
              )}
            </div>
          ) : (
            <>
              {(bringInOpen || yamatoShipment?.isPickup === false) && sellerAddress && (
                <OrderCombineYamatoBringIn
                  sellerAddressId={sellerAddress.id}
                  orderId={orderId}
                  yamatoShipment={yamatoShipment}
                />
              )}
              {(pickupOpen || yamatoShipment?.isPickup) && sellerAddress && (
                <>
                  <OrderCombineYamatoPickup
                    orderId={orderId}
                    sellerAddressId={sellerAddress.id}
                    yamatoShipment={yamatoShipment}
                  />
                </>
              )}
            </>
          )}
        </OpeningBlock>
        <Space size={ESpaceSize.PIXEL_16} />
        <OpeningBlock
          title={intl.formatMessage({ id: 'orderCombine.sendPackage' })}
          status={!yamatoShipment?.status ? EOpeningBlockStatus.ACTIVE : EOpeningBlockStatus.DONE}
          number={3}
          isOpen={stepThreeOpen || shipmentCreated}
          setOpen={setStepThreeOpen}
        >
          <>
            {yamatoShipment && !yamatoShipment?.isPickup ? (
              <div className={s.OrderPageCombineYamato__bringInGuide}>
                <FormattedMessage
                  id={'orderCombine.yamatoGuideBringIn'}
                  values={{
                    a: (chunks) => (
                      <a
                        className={s.OrderPageCombineYamato__bringInGuide_link}
                        target={'_blank'}
                        rel="noreferrer"
                        href={yamatoLink}
                      >
                        {chunks}
                      </a>
                    )
                  }}
                />
                <Space size={ESpaceSize.PIXEL_12}></Space>
                <FormattedMessage id={'orderCombine.password'} />
                <span
                  className={clsx([
                    s.OrderPageCombineYamato__password,
                    hidePassword ? s.OrderPageCombineYamato__password_hidden : s.OrderPageCombineYamato__password_show
                  ])}
                  onClick={() => setHidePassword(!hidePassword)}
                >
                  {yamatoShipment.reservePassword}
                </span>
                <Space size={ESpaceSize.PIXEL_16} />
                {yamatoShipment?.qrCode && (
                  <div className={s.OrderPageCombineYamato__qrCode}>
                    <img
                      className={s.OrderPageCombineYamato__qrCode_image}
                      src={yamatoShipment?.qrCode.main_file?.url || ''}
                      alt={'qr-code'}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className={s.OrderPageCombineYamato__bringInGuide}>
                <FormattedMessage
                  id={'orderCombine.yamatoGuidePickup'}
                  values={{
                    a: (chunks) => (
                      <a
                        className={s.OrderPageCombineYamato__bringInGuide_link}
                        target={'_blank'}
                        rel="noreferrer"
                        href={yamatoLink}
                      >
                        {chunks}
                      </a>
                    )
                  }}
                />
                <Space size={ESpaceSize.PIXEL_12} />
                <FormattedMessage id={'pickupRequest.card.date'} />
                {yamatoShipment?.slipNumber && (
                  <span className={s.OrderPageCombineYamato__slipNumber}>{yamatoPickupTime}</span>
                )}
                <Space size={ESpaceSize.PIXEL_4} />
                <FormattedMessage id={'orderCombine.slipNumber'} />
                {yamatoShipment?.slipNumber && (
                  <span className={s.OrderPageCombineYamato__slipNumber}>{yamatoShipment.slipNumber}</span>
                )}
                <Space size={ESpaceSize.PIXEL_4} />
                <FormattedMessage id={'orderCombine.password'} />
                <span
                  className={clsx([
                    s.OrderPageCombineYamato__password,
                    hidePassword ? s.OrderPageCombineYamato__password_hidden : s.OrderPageCombineYamato__password_show
                  ])}
                  onClick={() => setHidePassword(!hidePassword)}
                >
                  {yamatoShipment && yamatoShipment.reservePassword}
                </span>
              </div>
            )}
          </>
        </OpeningBlock>
      </>
      <AddressSelectModal
        title={intl.formatMessage({ id: 'address.select.modalTitle' })}
        isOpen={selectModalOpen}
        onClose={closeSelectModal}
        onClickOpenForm={(address) => {
          closeSelectModal();
          setEditedAddress(address);
          openAddressModal();
          openEditFromSelectRef.current = true;
        }}
        addresses={addresses!}
        selectedAddressId={selectedAddressId}
        setSelectedAddressId={setSelectedAddressId}
        onSubmit={onSubmit}
      />
      {/* Форма адреса, вызываемая из модалки верификации */}
      <AddressForm
        isOpen={addressModalOpen}
        address={editedAddress}
        defaultsSelected={addresses?.length === 0}
        personalInformation={getAddressInformationFromUser(user)}
        onClose={() => {
          closeAddressModal();
          setEditedAddress(null);
        }}
      />
    </>
  );
};
