import React, { useState } from 'react';

import clsx from 'clsx';

import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { FormattedMessage } from 'react-intl';
import { ReactFCC } from '../../../../../common/utils/helperTypes';
import { ProductComment as ProductCommentType, useAvatarQuery } from '../../../../../store/graphql';
import { Anchor } from '../../../../../common/components/Anchor';
import { useToggle } from '../../../../../common/hooks/useToggle';
import { isKeyDownEnter } from '../../../../../common/utils/isKeyDownEnter';
import { UserAvatar } from '../../../../../entities/user';
import { Badge } from '../../../../../common/components/Badge';
import { useTimeAgo } from '../../../../../common/hooks/useTimeAgo';
import s from './ProductComment.module.scss';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export type TProductComment = {
  className?: string;
  sellerId: string;
  productStatus: string;
  comment: Pick<
    ProductCommentType,
    'commentator' | 'createdDate' | 'commentary' | 'isSeller' | 'productId' | 'id' | 'userId'
  >;
};

export const ProductComment: ReactFCC<TProductComment> = (props) => {
  const { className, comment, sellerId, productStatus } = props;
  const { timeAgo } = useTimeAgo();
  const [commentOverflow, setCommentOverflow] = useState(false);
  const [isExpanded, { toggle }] = useToggle(false);

  const { data: commentUser, loading: commentUserLoading } = useAvatarQuery({
    variables: {
      input: {
        ids: comment.userId ? [comment.userId] : undefined
      }
    }
  });

  const avatar = commentUser?.avatar?.customers.entries[0];

  return (
    <>
      <div className={clsx(s.ProductComment, className)}>
        <div className={s.ProductComment__content}>
          <UserAvatar className={s.ProductComment__avatar} src={avatar?.avatar?.thumbnails?.M?.url || undefined} />
          <div className={s.ProductComment__userInfo}>
            <span className={s.ProductComment__name}>{comment.commentator}</span>
            {comment.isSeller && (
              <Badge className={s.ProductComment__role}>
                <FormattedMessage id={'general.seller'} />
              </Badge>
            )}
            <div className={s.ProductComment__commentBox}>
              <ResponsiveEllipsis
                className={s.ProductComment__text}
                text={comment.commentary}
                maxLine={!isExpanded ? 3 : 1000}
                ellipsis={'...'}
                basedOn={'words'}
                onReflow={({ clamped }) => setCommentOverflow(clamped)}
              />

              {(commentOverflow || isExpanded) && (
                <Anchor
                  component="div"
                  className={s.ProductComment__button}
                  onClick={toggle}
                  onKeyDownCapture={(e: React.KeyboardEvent) => {
                    if (isKeyDownEnter(e)) {
                      toggle();
                    }
                  }}
                >
                  {isExpanded ? 'Show less' : 'Show more'}
                </Anchor>
              )}
            </div>
            <span className={s.ProductComment__date}>{timeAgo(comment.createdDate)}</span>
          </div>
        </div>
      </div>
    </>
  );
};
