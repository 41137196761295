import { useEffect, useMemo, useState } from 'react';
import { useCountriesQuery } from '../../../store/graphql';
import { FormInputSelectOption } from '../../../common/components/inputs';

export interface UseCountryProps {
  countryCode?: string;
  initialCountryOption?: FormInputSelectOption;
}

export const useCountry = (props: UseCountryProps = {}) => {
  const { countryCode, initialCountryOption } = props;

  const [countryInput, setCountryInput] = useState('');

  const { data: countryData } = useCountriesQuery({
    skip: !countryInput,
    variables: {
      input: {
        filter: {
          name: countryInput
        }
      }
    }
  });

  const countries = useMemo(
    () => countryData?.addressDirectory.countries || [],
    [countryData?.addressDirectory.countries]
  );
  const countryOptions: FormInputSelectOption[] = useMemo(
    () =>
      countryInput
        ? countries.map((country) => ({
            title: country.name,
            value: country.alpha2
          }))
        : initialCountryOption
        ? [initialCountryOption]
        : [],
    [countries, countryInput, initialCountryOption]
  );

  useEffect(() => {
    if (initialCountryOption) {
      setCountryInput(initialCountryOption.title);
    }
  }, [initialCountryOption]);

  const country = useMemo(
    () => (countries && countryCode ? countries.find((i) => i.alpha2 === countryCode) || null : null),
    [countries, countryCode]
  );

  return {
    countryInput,
    setCountryInput,
    countryOptions,
    country
  };
};
