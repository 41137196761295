import React, { useCallback, useEffect } from 'react';
import { Pagination as PaginationBootstrap } from 'react-bootstrap';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { ReactFCC } from 'common/utils/helperTypes';
import s from './Pagination.module.scss';

export interface PaginationProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Текущее смещение
   */
  offset: number;
  /**
   * Сеттер смещения
   */
  setOffset: (page: number) => void;
  /**
   * Лимит элементов
   */
  limit: number;
  /**
   * Общее число элементов
   */
  totalCount: number;
  /**
   * Отображать общее число элементов
   */
  showTotalCount?: boolean;
  /**
   * Кастомный вывод общего числа элементов
   */
  totalCountText?: string | React.ReactNode;
}

export const Pagination: ReactFCC<PaginationProps> = ({
  className,
  offset,
  setOffset,
  limit,
  totalCount,
  showTotalCount = true,
  totalCountText
}) => {
  const currentPage = Math.floor(offset / limit) + 1;
  const totalPages = Math.ceil(totalCount / limit);

  const setPage = useCallback(
    (page: number) => {
      let nextOffset = (page - 1) * limit;

      if (nextOffset > totalCount) {
        nextOffset = totalCount;
      }

      setOffset(nextOffset);
    },
    [limit, setOffset, totalCount]
  );

  useEffect(() => {
    if (offset > totalCount) {
      setOffset(0);
    }
  }, [offset, setOffset, totalCount]);

  return (
    <div className={clsx(s.Pagination, className)}>
      {showTotalCount && (
        <span>{totalCountText ?? <FormattedMessage id="general.pagination" values={{ count: totalCount }} />}</span>
      )}

      {totalPages > 1 && (
        <PaginationBootstrap className={s.Pagination__body}>
          <PaginationBootstrap.Prev disabled={currentPage === 1} onClick={() => setPage(currentPage - 1)} />
          {currentPage > 1 && (
            <>
              <PaginationBootstrap.Item onClick={() => setPage(1)}>{1}</PaginationBootstrap.Item>
              {currentPage > 3 && <PaginationBootstrap.Ellipsis disabled={true} />}
              {currentPage > 2 && (
                <PaginationBootstrap.Item onClick={() => setPage(currentPage - 1)}>
                  {currentPage - 1}
                </PaginationBootstrap.Item>
              )}
            </>
          )}
          <PaginationBootstrap.Item active>{currentPage}</PaginationBootstrap.Item>
          {currentPage < totalPages && (
            <>
              {totalPages - currentPage > 1 && (
                <PaginationBootstrap.Item onClick={() => setPage(currentPage + 1)}>
                  {currentPage + 1}
                </PaginationBootstrap.Item>
              )}
              {totalPages - currentPage > 2 && <PaginationBootstrap.Ellipsis disabled={true} />}
              <PaginationBootstrap.Item onClick={() => setPage(totalPages)}>{totalPages}</PaginationBootstrap.Item>
            </>
          )}
          <PaginationBootstrap.Next disabled={currentPage === totalPages} onClick={() => setPage(currentPage + 1)} />
        </PaginationBootstrap>
      )}
    </div>
  );
};
