import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { EMDASH } from '@proscom/ui-utils';
import { FormattedDate } from 'common/components/CustomFormatters';
import { WalletOperationsCell, WalletOperationsRow } from '../../WalletOperationsGrid';
import { TransactionType } from '../types';
import { TransactionAmount } from '../TransactionAmount/TransactionAmount';
import { TransactionToggle } from '../TransactionToggle/TransactionToggle';
import { transactionTypeIntlIds } from '../constants';
import { TransactionItemLink } from '../TransactionItemLink/TransactionItemLink';
import { PathBuilder } from '../../../../../../common/utils/routes';
import { TransactionRowOrder } from './TransactionRowOrder';
import s from './TransactionRow.module.scss';

interface TransactionRowProps extends TransactionType {
  className?: string;
  expanded: boolean;
  toggleExpand: () => void;
}

export const TransactionRow = ({
  className,
  expanded,
  toggleExpand,
  createdDate,
  transactionType,
  amount,
  balance,
  comment,
  order
}: TransactionRowProps) => {
  const transactionTypeIntlId = transactionTypeIntlIds[transactionType];

  return (
    <div className={s.TransactionRow__container}>
      <WalletOperationsRow
        className={clsx(className, {
          [s.TransactionRow_expanded]: expanded,
          [s.TransactionRow_expandable]: !!order
        })}
        onClick={order ? toggleExpand : undefined}
      >
        <WalletOperationsCell>
          <TransactionToggle
            className={clsx(s.TransactionRow__toggle, s.TransactionRow__toggler)}
            active={expanded}
            onClick={order ? toggleExpand : undefined}
          />
          <FormattedDate value={new Date(createdDate)} format={'yyyy.MM.dd'} />
        </WalletOperationsCell>
        <WalletOperationsCell>
          <FormattedDate value={new Date(createdDate)} format={'HH:mm'} />
        </WalletOperationsCell>
        <WalletOperationsCell>
          {transactionTypeIntlId ? <FormattedMessage id={transactionTypeIntlId} /> : transactionType}
        </WalletOperationsCell>
        <WalletOperationsCell>
          {order ? (
            <TransactionItemLink to={PathBuilder.getOrderPath(order.order.id, true)}>
              Order №{order.order.ID}
            </TransactionItemLink>
          ) : (
            comment || EMDASH
          )}
        </WalletOperationsCell>
        <WalletOperationsCell smallPadding>
          <TransactionAmount value={amount} />
        </WalletOperationsCell>
        <WalletOperationsCell smallPadding>
          <TransactionAmount value={balance} colorize={false} />
        </WalletOperationsCell>
      </WalletOperationsRow>
      {!!order?.contents?.length && expanded && (
        <div className={s.TransactionRow__orders}>
          {order.contents.map((item, iItem) => (
            <TransactionRowOrder key={iItem} className={clsx(className, s.TransactionRow__order)} {...item} />
          ))}
        </div>
      )}
    </div>
  );
};
