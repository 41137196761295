import { RouteObject } from 'react-router-dom';
import React from 'react';
import { NotFoundPage } from '../notFound/NotFoundPage';
import {
  ABOUT_ROUTE,
  COMPANY_ROUTE,
  CONTACT_ROUTE,
  COOKIES_ROUTE,
  LAW_ROUTE,
  PRIVACY_ROUTE,
  TERMS_ROUTE,
  TESTIMONIALS_ROUTE,
  withRoutes
} from '../../common/utils/routes';
import { AboutLayout } from './_layout/AboutLayout';

import { ContactPage } from './contact/ContactPage';
import { TestimonialsPage } from './testimonials/TestimonialsPage';
import { CookiesInfoPage } from './cookies/CookiesInfoPage';
import { LawPage } from './law/LawPage';
import { PrivacyPage } from './privacy/PrivacyPage';
import { TermsPage } from './terms/TermsPage';
import { AboutPage } from './about/AboutPage';
const routes: RouteObject[] = [
  {
    element: <AboutLayout />,
    children: [
      {
        index: true,
        element: <AboutPage />
      },
      {
        path: COMPANY_ROUTE.replace(ABOUT_ROUTE, ''),
        element: <AboutPage />
      },
      {
        path: TERMS_ROUTE.replace(ABOUT_ROUTE, ''),
        element: <TermsPage />
      },
      {
        path: PRIVACY_ROUTE.replace(ABOUT_ROUTE, ''),
        element: <PrivacyPage />
      },
      {
        path: LAW_ROUTE.replace(ABOUT_ROUTE, ''),
        element: <LawPage />
      },
      {
        path: COOKIES_ROUTE.replace(ABOUT_ROUTE, ''),
        element: <CookiesInfoPage />
      },
      {
        path: TESTIMONIALS_ROUTE.replace(ABOUT_ROUTE, ''),
        element: <TestimonialsPage />
      },
      {
        path: CONTACT_ROUTE.replace(ABOUT_ROUTE, ''),
        element: <ContactPage />
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  }
];

export const AboutRoutes = withRoutes(routes);
