import React from 'react';
import { Alert as AlertBootstrap } from 'react-bootstrap';
import clsx from 'clsx';
import s from './Alert.module.scss';

export enum AlertVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',
  Success = 'success'
}

export interface AlertProps {
  className?: string;
  variant?: AlertVariant;
  onClick?: () => void;
  children?: React.ReactNode;
}

export const Alert = ({ className, variant = AlertVariant.Danger, onClick, children }: AlertProps) => {
  return (
    <AlertBootstrap
      className={clsx(s.Alert, s[`CardAlert_${variant}`], className, {
        [s.Alert_clickable]: !!onClick
      })}
      variant={variant}
      onClick={onClick}
    >
      {children}
    </AlertBootstrap>
  );
};
