import { InferType, object, string } from 'yup';
import { validateErrors, validateNickname } from '../../../../common/utils/validate';
import { maxNameLength } from './constants';

const validateObject = {
  name: string().max(maxNameLength, 'Must be maximum 500 characters long').defined(validateErrors.required),
  secondName: string().max(maxNameLength, 'Must be maximum 500 characters long').defined(validateErrors.required),
  nickname: string()
    .max(maxNameLength, 'Must be maximum 500 characters long')
    .required(validateErrors.required)
    .test('is-nickname', validateErrors.wrongNickname, (value) => validateNickname(value || ''))
    .defined(validateErrors.required)
};

export const UserDataModalSchema = object(validateObject);

export type TUserDataModalValues = InferType<typeof UserDataModalSchema>;

export const UserDataModalKeys = Object.fromEntries(Object.keys(validateObject).map((key) => [key, key])) as Record<
  keyof TUserDataModalValues,
  string
>;
