import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const LogoutDocument = gql`
  mutation logout($token: String!) {
    response: logoutUser(token: $token)
  }
`;

export interface LogoutVariables {
  token: string;
}

export async function logout(client: ApolloClient<NormalizedCacheObject>, variables: LogoutVariables) {
  return await client.mutate<{ response: boolean }>({
    mutation: LogoutDocument,
    variables
  });
}
