import { ReactFCC, RecursivePartial } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { Carousel } from 'react-bootstrap';
import { first } from 'lodash-es';
import React, { useCallback } from 'react';
import { Banner, Thumbnails } from '../../../../store/graphql';
import { openPathInNewTab } from '../../../../common/utils/url';
import s from './AdBanners.module.scss';

type BannerType = Partial<Omit<Banner, 'files'>> & {
  files?:
    | {
        thumbnails?: RecursivePartial<Thumbnails> | null;
      }[]
    | null;
};

export interface AdBannersProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;

  banners: BannerType[];
}

export const AdBanners: ReactFCC<AdBannersProps> = (props) => {
  const { className, banners } = props;

  const onClick = useCallback((path?: string | null) => {
    if (path) {
      openPathInNewTab({ path });
    }
  }, []);

  return (
    <Carousel className={clsx(s.AdBanners, className)} variant="dark" interval={null}>
      {banners
        ?.filter((banner) => banner.active)
        .map((banner, index) => (
          <Carousel.Item className={s.AdBanners__item} onClick={() => onClick(banner.redirectUrl)} key={index}>
            <div className={s.AdBanners__container}>
              <img
                className={s.AdBanners__banner}
                src={first(banner.files)?.thumbnails?.large?.url}
                alt={banner.description || banner.name}
              />
            </div>
          </Carousel.Item>
        ))}
    </Carousel>
  );
};
