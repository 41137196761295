import React from 'react';
import { Alert, Container } from 'react-bootstrap';
import clsx from 'clsx';
import { ReactFCC } from '../../utils/helperTypes';
import s from './FixedBanner.module.scss';

export enum FixedBannerVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

export interface FixedBannerProps {
  className?: string;
  variant?: FixedBannerVariant;
  dismissible?: boolean;
  onClose?: () => void;
}

export const FixedBanner: ReactFCC<FixedBannerProps> = (props) => {
  const { children, className, variant = FixedBannerVariant.PRIMARY, dismissible, onClose } = props;

  return (
    <div className={clsx(s.FixedBanner, className)}>
      <Container className={s.FixedBanner__container}>
        <Alert className={s.FixedBanner__alert} variant={variant} dismissible={dismissible} onClose={onClose}>
          <div>{children}</div>
        </Alert>
      </Container>
    </div>
  );
};
