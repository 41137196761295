import React from 'react';
import clsx from 'clsx';
import { ReactFCC } from '../../utils/helperTypes';
import { HeadingSize, Heading } from '../Heading';
import { ESpaceSize, Space } from '../Space/Space';
import s from './NotFound.module.scss';

type TNotFoundClasses = {
  root?: string;
  title?: string;
  content?: string;
};

export type TNotFound = {
  className?: string;
  classes?: TNotFoundClasses;
  title: React.ReactNode;
};

export const NotFound: ReactFCC<TNotFound> = (props) => {
  const { children, className, classes, title } = props;

  return (
    <div className={clsx(s.NotFound, classes?.root, className)}>
      <Heading className={clsx(s.NotFound__heading, classes?.title)} size={HeadingSize.H5}>
        {title}
      </Heading>

      <Space size={ESpaceSize.PIXEL_16} />

      <div className={clsx(s.NotFound__content, classes?.content)}>{children}</div>
    </div>
  );
};
