import React, { useState } from 'react';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import LinesEllipsis from 'react-lines-ellipsis';
import { useIntl } from 'react-intl';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import { Alert, AlertVariant } from '../../../common/components/Alert';
import { Anchor } from '../../../common/components/Anchor';
import { isKeyDownEnter } from '../../../common/utils/isKeyDownEnter';
import { FormCheckbox } from '../../../common/components/inputs';
import { useToggle } from '../../../common/hooks/useToggle';
import s from '../../../pages/profile/sellerOrder/components/OrderCombineParcelInput/OrderCombineParcelInput.module.scss';

type YuPacketBlockProps = {
  kuronekoEnabled: boolean;
  toggleKuroneko: () => void;
};

export const YuPacketBlock = ({ kuronekoEnabled, toggleKuroneko }: YuPacketBlockProps) => {
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);
  const intl = useIntl();
  const [isKuronekoExpanded, { toggle }] = useToggle(false);
  const [kuronekoOverflow, setKuronekoOverflow] = useState(false);

  return (
    <div>
      <Space size={ESpaceSize.PIXEL_16} />
      <Alert variant={AlertVariant.Primary} className={s.OrderCombineParcelInput__kuronekoAlert}>
        <ResponsiveEllipsis
          className={s.ProductComment__text}
          text={intl.formatMessage({ id: 'profile.createProductKuronekoInfo' })}
          maxLine={!isKuronekoExpanded ? 2 : 1000}
          ellipsis={'...'}
          basedOn={'words'}
          onReflow={({ clamped }) => setKuronekoOverflow(clamped)}
        />

        {(kuronekoOverflow || isKuronekoExpanded) && (
          <Anchor
            component="div"
            className={s.ProductComment__button}
            onClick={toggle}
            onKeyDownCapture={(e: React.KeyboardEvent) => {
              if (isKeyDownEnter(e)) {
                toggle();
              }
            }}
          >
            {isKuronekoExpanded ? 'Show less' : 'Show more'}
          </Anchor>
        )}
      </Alert>
      <Space size={ESpaceSize.PIXEL_8} />
      <FormCheckbox
        type={'checkbox'}
        name={'Use Kuroneko Yu-Packet'}
        label={intl.formatMessage({ id: 'profile.createProductKuronekoCheckbox' })}
        checked={kuronekoEnabled}
        onChange={toggleKuroneko}
      />
      <Space size={ESpaceSize.PIXEL_8} />
    </div>
  );
};
