import { UserQuery, UserQueryVariables, UserRoles, useUserQuery } from 'store/graphql';
import * as Apollo from '@apollo/client';
import { useAuth } from '../../../app/providers/auth-apollo';

export type User = Omit<UserQuery['user'], '__typename'>;

export const useUser = (options: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> = {}) => {
  const { isAuthenticated } = useAuth();
  const { data, refetch, ...query } = useUserQuery({
    skip: !isAuthenticated,
    fetchPolicy: 'cache-first',
    ...options
  });

  const user: User | null = isAuthenticated ? data?.user || null : null;

  const hasRole = (role: UserRoles) => {
    return data?.user.roles?.includes(role) ?? false;
  };

  return {
    user,
    refetch,
    hasRole,
    ...query
  };
};
