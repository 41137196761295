import { ReactFCC } from 'common/utils/helperTypes';
import { BsThreeDotsVertical } from 'react-icons/bs';
import React, { RefObject, useRef } from 'react';
import clsx from 'clsx';
import composeRefs from '@seznam/compose-react-refs/composeRefs';
import { IconButton, IconButtonSize, IconButtonVariant } from '../Button';
import { useToggle } from '../../hooks/useToggle';
import { ContextMenuContent, ContextMenuContentProps } from './ContextMenuContent';

export interface ContextMenuProps {
  /**
   * Дополнительные css-класс для кнопки контекстного меню
   */
  className?: string;
  /**
   * Дополнительные css-классы
   */
  classes?: {
    button?: string;
    menu?: string;
    item?: string;
  };
  /**
   * Элементы контекстного меню
   */
  items: ContextMenuContentProps['items'];
  /**
   * Индикатор загрузки на кнопке
   */
  loading?: boolean;
  /**
   * Состояние дизейбла на кнопке
   */
  disabled?: boolean;
  /**
   * Вариант кнопки
   */
  buttonVariant?: IconButtonVariant;
  /**
   * Размер кнопки
   */
  buttonSize?: IconButtonSize;
  /**
   * Реф на кнопку
   */
  buttonRef?: RefObject<HTMLButtonElement>;
  /**
   * Опционально другая иконка контекстного меню
   */
  icon?: React.ElementType<any>;
}

export const ContextMenu: ReactFCC<ContextMenuProps> = (props) => {
  const {
    className,
    classes,
    items,
    loading,
    disabled,
    buttonVariant = IconButtonVariant.TERTIARY,
    buttonSize = IconButtonSize.SMALL_X,
    icon = BsThreeDotsVertical,
    buttonRef
  } = props;

  const [contextMenuOpen, { unset: closeContextMenu, toggle: toggleContextMenu }] = useToggle(false);

  const referenceRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <IconButton
        className={clsx(className, classes?.button)}
        icon={icon}
        variant={buttonVariant}
        size={buttonSize}
        active={contextMenuOpen}
        disabled={disabled}
        loading={loading}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleContextMenu();
        }}
        innerRef={composeRefs(buttonRef, referenceRef)}
      />

      <ContextMenuContent
        className={classes?.menu}
        classes={{
          item: classes?.item
        }}
        referenceRef={referenceRef}
        items={items}
        isOpen={contextMenuOpen}
        onClose={closeContextMenu}
      />
    </>
  );
};
