import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { ArisoraOrderStatus, DebtType, OrderContent } from 'store/graphql';
import { useNavigate } from 'react-router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedDate } from '../../../../common/components/CustomFormatters';
import { Link } from '../../../../common/components/Link';
import { Anchor } from '../../../../common/components/Anchor';
import { ImageWithFallback } from '../../../../common/components/ImageWithFallback';
import { PathBuilder } from '../../../../common/utils/routes';
import { Button, ButtonVariant } from '../../../../common/components/Button';
import s from './OrderSimpleCard.module.scss';

type OrderCardContentRequiredProps =
  | 'id'
  | 'name'
  | 'productId'
  | 'price'
  | 'colorName'
  | 'status'
  | 'specificValues'
  | 'mainPhoto';
type OrderCardContentType = Pick<OrderContent, OrderCardContentRequiredProps> &
  Partial<Omit<OrderContent, OrderCardContentRequiredProps>>;

export interface OrderCardProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * ID заказа из Airtable
   */
  id: string;
  /**
   * ID сущности заказа
   */
  entityId: string;
  /**
   * Дата создания заказа
   */
  date: Date;
  /**
   * Статус заказа
   */
  status: ArisoraOrderStatus;
  /**
   * Наличие ошибки в заказе
   */
  isProblem?: boolean;
  /**
   * ID продавца
   */
  sellerId?: string;
  /**
   * Имя продавца
   */
  sellerNickname?: string;
  /**
   * ID покупателя
   */
  customerId?: string;
  /**
   * Имя покупателя
   */
  customerNickname?: string;
  /**
   * Содержимое заказа
   */
  contents: OrderCardContentType[];
  /**
   * Детализированное отображение товаров
   * todo возможно лучше детализированное отображение вынести в отдельный компонент
   *  ибо становится очень много логики, завязанной на этом флаге
   */
  isDetailed?: boolean;
  /**
   * Отображение заказа в списке продавца (влияет на генерацию ссылки на заказ)
   */
  isSeller?: boolean;
  /**
   * Цена доставки
   */
  deliveryCost?: number;
  /**
   * Объект задолженности
   */
  debt?: DebtType;
  /**
   * Опциональный левый блок в хедере
   */
  headerLeft?: React.ReactNode;
}

export const OrderSimpleCard: ReactFCC<OrderCardProps> = (props) => {
  const {
    className,
    id,
    entityId,
    date,
    sellerId,
    sellerNickname,
    customerId,
    customerNickname,
    contents,
    isDetailed,
    isSeller,
    deliveryCost
  } = props;

  const navigate = useNavigate();

  const productImageSize = parseFloat(s.productImageSize);
  const productImageGap = parseFloat(s.productImageGap);

  const contentRef = useRef<HTMLDivElement>(null);
  const [maxItems, setMaxItems] = useState(0);

  const processMaxItems = useCallback(() => {
    if (contentRef.current) {
      const width = contentRef.current.clientWidth - 100;
      let newMaxItems = 0;
      const p = new Array(20).fill(null);
      for (let i of p) {
        const itemsWidth = (newMaxItems + 1) * productImageSize + newMaxItems * productImageGap;
        if (itemsWidth < width) {
          newMaxItems += 1;
        } else {
          newMaxItems -= 1;
          break;
        }
      }
      setMaxItems(newMaxItems);
    }
  }, [productImageGap, productImageSize]);

  useEffect(processMaxItems, [processMaxItems]);
  useEffect(() => {
    window.addEventListener('resize', processMaxItems);

    return () => window.removeEventListener('resize', processMaxItems);
  }, [processMaxItems]);

  return (
    <div className={clsx(s.OrderSimpleCard, isDetailed && s.Order_noborder, className)}>
      {contents && (
        <div className={s.OrderSimpleCard__body}>
          <div className={s.OrderSimpleCard__productThumbnails} ref={contentRef}>
            <>
              <div className={s.OrderSimpleCard__orderContent_info}>
                <div className={s.OrderSimpleCard__headerInfoTitle}>
                  <span>
                    <FormattedMessage id="general.order" /> {id}
                  </span>
                </div>

                {sellerNickname && (
                  <div className={s.OrderSimpleCard__headerInfoSeller}>
                    <FormattedMessage id="general.seller" />{' '}
                    {sellerId ? (
                      <Link to={PathBuilder.getSellerPublicPath(sellerId)}>{sellerNickname}</Link>
                    ) : (
                      <Anchor component="span">{sellerNickname}</Anchor>
                    )}
                  </div>
                )}

                {customerNickname && (
                  <div className={s.OrderSimpleCard__headerInfoSeller}>
                    <FormattedMessage id="general.buyer" />{' '}
                    {customerId ? (
                      <Link to={PathBuilder.getCustomerPublicPath(customerId)}>{customerNickname}</Link>
                    ) : (
                      <Anchor component="span">{customerNickname}</Anchor>
                    )}
                  </div>
                )}
                <span className={s.OrderSimpleCard__headerInfoDate}>
                  {date && <FormattedDate value={new Date(date)} />}
                </span>
              </div>
              {contents.slice(0, maxItems).map((product, index) => (
                <div className={s.OrderSimpleCard__orderContent}>
                  <Link to={PathBuilder.getProductPath(product.productId)} key={index}>
                    <ImageWithFallback src={product.mainPhoto?.thumbnails?.M?.url ?? undefined} size={85} />
                  </Link>
                </div>
              ))}
              {maxItems < contents.length && (
                <div
                  className={s.OrderSimpleCard__productThumbnailMore}
                  onClick={() => navigate(PathBuilder.getOrderPath(entityId, isSeller))}
                >
                  +{contents.length - maxItems}
                </div>
              )}
            </>
          </div>
          <Button
            variant={ButtonVariant.PRIMARY_OUTLINE}
            onClick={() => navigate(PathBuilder.getOrderPath(entityId, isSeller))}
          >
            <FormattedMessage id="profile.viewOrder" />
          </Button>
        </div>
      )}
    </div>
  );
};
