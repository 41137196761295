import React from 'react';
import clsx from 'clsx';
import { Form } from 'react-bootstrap';
import { ReactFCC } from 'common/utils/helperTypes';
import { Colors } from 'common/utils/colors';
import { Text, TextVariant } from '../../Text';
import s from './FormLabel.module.scss';

export interface FormLabelProps {
  className?: string;
  disabled?: boolean;
  required?: boolean;
  description?: string | React.ReactNode;
}

export const FormLabel: ReactFCC<FormLabelProps> = (props) => {
  const { children, className, disabled, required, description } = props;

  return (
    <Form.Label className={clsx(className, disabled && s.FormLabel_disabled)}>
      {children}{' '}
      {required && <span className={clsx(s.FormLabel__required, disabled && s.FormLabel__required_disabled)}>*</span>}
      {!!description && (
        <Text className={s.FormLabel__description} variant={TextVariant.BODY_S_NORMAL} color={Colors.GRAY_600}>
          {description}
        </Text>
      )}
    </Form.Label>
  );
};
