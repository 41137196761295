import React from 'react';
import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { BsPlusLg } from 'react-icons/bs';
import { Button, ButtonVariant } from 'common/components/Button';
import s from './BankCardAdd.module.scss';

export interface BankCardAddProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Обработчик нажатия на кнопку
   */
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const BankCardAdd: ReactFCC<BankCardAddProps> = (props) => {
  const { className, onClick } = props;

  return (
    <div className={clsx(s.BankCardAdd, className)}>
      <Button
        className={s.BankCardAdd__button}
        leftIcon={BsPlusLg}
        variant={ButtonVariant.PRIMARY_OUTLINE}
        onClick={onClick}
      >
        Add new
      </Button>
    </div>
  );
};
