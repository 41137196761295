import { toast } from 'react-toastify';
import { handleDefaultError } from '../../../../common/utils/handleDefaultError';
import {
  DraftSortDirection,
  PaginationType,
  useRemoveAllProductDraftsMutation,
  useRemoveProductDraftMutation,
  useSellerProductDraftsQuery
} from '../../../../store/graphql';
import { DEFAULT_PAGE_LIMIT, usePagination } from '../../../../common/components/Pagination';
import { ProductDraftType } from '../types';

export type ProductDraftData = {
  productDrafts?: ProductDraftType[];
  paginationOptions: {
    pagination?: PaginationType;
    offset: number;
    setOffset: (value: number) => void;
  };
  dataLoading: boolean;
  removeDraftLoading: boolean;
  removeAllDraftsLoading: boolean;
  removeProductDraft: (draftId: number) => Promise<void>;
  removeAllProductDrafts: () => Promise<void>;
};

export type ProductDraftDataProps = {
  onRemoveDraftSuccess?: () => void;
};

export const useDraftData = (options: ProductDraftDataProps): ProductDraftData => {
  const { onRemoveDraftSuccess } = options;

  const { offset, setOffset } = usePagination();

  const { data, loading: draftsLoading } = useSellerProductDraftsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        sort: {
          createdAt: DraftSortDirection.Desc
        },
        pagination: {
          limit: DEFAULT_PAGE_LIMIT,
          offset
        }
      }
    }
  });

  const [removeDraftProduct, { loading: removeDraftLoading }] = useRemoveProductDraftMutation({
    refetchQueries: ['SellerProductDrafts']
  });

  const [removeAllDrafts, { loading: removeAllDraftsLoading }] = useRemoveAllProductDraftsMutation({
    refetchQueries: ['SellerProductDrafts']
  });

  const productDrafts = data?.user.seller?.drafts.entries;
  const pagination = data?.user.seller?.drafts.pagination;

  const dataLoading = draftsLoading;

  const removeProductDraft = async (draftId: number) => {
    try {
      if (draftId) {
        await removeDraftProduct({
          variables: {
            input: {
              id: draftId
            }
          }
        });
        onRemoveDraftSuccess?.();
        toast.success('Draft was successfully removed!');
      }
    } catch (e) {
      handleDefaultError('Something went wrong with draft removal');
    }
  };

  const removeAllProductDrafts = async () => {
    try {
      await removeAllDrafts();
      onRemoveDraftSuccess?.();
      toast.success('Drafts were successfully removed!');
    } catch (e) {
      handleDefaultError('Something went wrong with draft removal');
    }
  };

  return {
    dataLoading,
    removeProductDraft,
    removeDraftLoading,
    removeAllDraftsLoading,
    removeAllProductDrafts,
    productDrafts,
    paginationOptions: { pagination, setOffset, offset }
  };
};
