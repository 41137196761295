import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { Modal, ModalBody, ModalFooter } from 'common/components/Modal';
import React from 'react';
import { Button } from 'common/components/Button';
import { useBankAccountForm, UseBankAccountFormProps } from '../useBankAccountForm';
import { BaseBankAccountForm } from '../BaseBankAccountForm';
import s from './BankAccountFormModal.module.scss';

export interface BankAccountFormModalProps extends UseBankAccountFormProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Состояние открытости модального окна
   */
  isOpen: boolean;
  /**
   * Обработчик события закрытия модального окна
   */
  onClose: () => void;
}

export const BankAccountFormModal: ReactFCC<BankAccountFormModalProps> = (props) => {
  const { className, isOpen, onClose, bankAccount, onAccountSaved } = props;

  const { loading, ...formProps } = useBankAccountForm({
    bankAccount,
    onAccountSaved,
    resetOnSubmit: true,
    refetchWhenCreated: true
  });

  return (
    <Modal className={clsx(s.BankAccountFormModal, className)} title={'Bank details'} isOpen={isOpen} onClose={onClose}>
      <ModalBody>
        <BaseBankAccountForm isOpen={isOpen} {...formProps} />
      </ModalBody>

      <ModalFooter>
        <Button onClick={formProps.formik.submitForm} loading={loading}>
          Add bank details
        </Button>
      </ModalFooter>
    </Modal>
  );
};
