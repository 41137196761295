import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Pagination, usePagination } from 'common/components/Pagination';
import { useWithdrawRequestsQuery } from 'store/graphql';
import { LoaderBox } from 'common/components/Loader/LoaderBox';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { Divider, EDividerVariant } from 'common/components/Divider/Divider';
import clsx from 'clsx';
import { WalletOperationsCell, WalletOperationsGrid, WalletOperationsRow } from '../WalletOperationsGrid';
import { WalletNoItems } from '../WalletNoItems';
import { DEFAULT_PAGE_LIMIT } from '../../../../../common/components/Pagination';
import { WalletRequest } from './WalletRequest';
import s from './WalletRequests.module.scss';

export interface WalletRequestsProps {
  isMobile: boolean;
}

export const WalletRequests = ({ isMobile }: WalletRequestsProps) => {
  // const [page, { setPage, useUpdatePageCount }] = usePagination();
  const { offset, setOffset } = usePagination();

  const intl = useIntl();

  const { data, loading } = useWithdrawRequestsQuery({
    variables: {
      input: {
        offset,
        limit: DEFAULT_PAGE_LIMIT
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const withdrawRequests = data?.withdrawRequests?.data;
  const pagination = data?.withdrawRequests?.pagination;

  // useUpdatePageCount(pagination?.pagesCount);

  return loading ? (
    <LoaderBox />
  ) : withdrawRequests?.length !== 0 ? (
    <>
      <WalletOperationsGrid>
        {!isMobile && (
          <>
            <WalletOperationsRow className={s.WalletRequests__row} head>
              <WalletOperationsCell>
                <FormattedMessage id="profile.walletOpDate" />
              </WalletOperationsCell>
              <WalletOperationsCell>
                <FormattedMessage id="profile.walletOpTime" />
              </WalletOperationsCell>
              <WalletOperationsCell>
                <FormattedMessage id="profile.walletOpTransactionAmount" />
              </WalletOperationsCell>
              <WalletOperationsCell>
                <FormattedMessage id="profile.walletOpStatus" />
              </WalletOperationsCell>
            </WalletOperationsRow>
            <Divider variant={EDividerVariant.LIGHT} />
          </>
        )}

        {withdrawRequests?.map((item, iItem) => (
          <WalletRequest
            key={iItem}
            rowClassName={clsx(s.WalletRequests__row, s.WalletRequests__row_content)}
            isMobile={isMobile}
            {...item}
          />
        ))}
      </WalletOperationsGrid>

      <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_48} />

      {pagination && (
        <Pagination
          limit={pagination.limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={pagination.totalCount}
          totalCountText={intl.formatMessage(
            { id: 'profile.walletTotalRequestsCount' },
            { count: pagination.totalCount }
          )}
        />
      )}
    </>
  ) : (
    <WalletNoItems>There have been no requests yet</WalletNoItems>
  );
};
