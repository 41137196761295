import { MaskitoOptions } from '@maskito/core';

/**
 * Маски для инпутов, использует открытую библиотеку Maskito
 */
export const japanesePhoneMask = {
  mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
} as MaskitoOptions;

export const defaultPhoneMask = {
  mask: ['+', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
} as MaskitoOptions;

export const codeMask = {
  mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
} as MaskitoOptions;
