import { InferType, object, string } from 'yup';
import { validateErrors } from '../../../../../common/utils/validate';

const validateObject = {
  code: string().defined(validateErrors.required)
};

export const AuthModalEnterCodeSchema = object(validateObject);

export type AuthModalEnterCodeValues = InferType<typeof AuthModalEnterCodeSchema>;

export const AuthModalEnterCodeKeys = Object.fromEntries(
  Object.keys(validateObject).map((key) => [key, key])
) as Record<keyof AuthModalEnterCodeValues, string>;
