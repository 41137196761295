import React, { useState } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { WalletOperationsCell, WalletOperationsRow } from '../../WalletOperationsGrid';
import { TransactionAmount } from '../TransactionAmount/TransactionAmount';
import { TransactionToggle } from '../TransactionToggle/TransactionToggle';
import { TransactionItemLink } from '../TransactionItemLink/TransactionItemLink';
import { TransactionOrderContentType } from '../types';
import { PathBuilder } from '../../../../../../common/utils/routes';
import s from './TransactionRow.module.scss';

export interface TransactionRowOrderProps extends TransactionOrderContentType {
  className?: string;
}

export const TransactionRowOrder = ({ className, productId, name, price, commission }: TransactionRowOrderProps) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <>
      <WalletOperationsRow
        className={clsx(className, s.TransactionRow, s.TransactionRow_expandable, {
          [s.TransactionRow_expanded]: expanded
        })}
        onClick={toggleExpand}
      >
        <WalletOperationsCell />
        <WalletOperationsCell />
        <WalletOperationsCell />
        <WalletOperationsCell>
          <TransactionToggle className={s.TransactionRow__toggle} active={expanded} onClick={toggleExpand} />
          <TransactionItemLink to={PathBuilder.getProductPath(productId)}>{name}</TransactionItemLink>
        </WalletOperationsCell>
        <WalletOperationsCell smallPadding>
          <TransactionAmount value={price - commission} />
        </WalletOperationsCell>
        <WalletOperationsCell smallPadding />
      </WalletOperationsRow>

      {expanded && (
        <>
          <WalletOperationsRow className={className}>
            <WalletOperationsCell />
            <WalletOperationsCell />
            <WalletOperationsCell />
            <WalletOperationsCell toFlexCol>
              <span className={s.TransactionRow__order_info}>
                <FormattedMessage id={'profile.walletOrderCost'} />
              </span>
              <span className={s.TransactionRow__order_info}>
                <FormattedMessage id={'profile.walletOrderCommission'} />
              </span>
            </WalletOperationsCell>
            <WalletOperationsCell smallPadding toFlexCol>
              <TransactionAmount value={price} colorize={false} />
              <TransactionAmount value={-1 * commission} colorize={false} />
            </WalletOperationsCell>
            <WalletOperationsCell smallPadding />
          </WalletOperationsRow>
        </>
      )}
    </>
  );
};
