import React, { useEffect } from 'react';
import clsx from 'clsx';
import { BsSliders } from 'react-icons/bs';
import { useIntl } from 'react-intl';
import { usePagination } from 'common/components/Pagination/usePaginatiion';
import { Pagination } from 'common/components/Pagination/Pagination';
import { GridGap, Grid } from 'common/components/Grid/Grid';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { LinkBack } from 'common/components/LinkBack/LinkBack';
import { ReactFCC } from 'common/utils/helperTypes';
import { useToggle } from 'common/hooks/useToggle';
import { Anchor, AnchorVariant } from 'common/components/Anchor/Anchor';
import { LoaderBox } from 'common/components/Loader/LoaderBox';
import { NotFound } from 'common/components/NotFound/NotFound';
import { EBreakpoints, useBreakpoint } from 'common/hooks/useBreakpoint';
import {
  ProductConditionEnum,
  ProductStatusEnum,
  SpecificAttributeInput,
  useCatalogProductsQuery
} from 'store/graphql';
import { tryNumber } from '@proscom/ui-utils';
import { useLocation } from 'react-router-dom';
import { useNavigation } from '../hooks/useNavigation';
import { DEFAULT_PAGE_LIMIT } from '../../../common/components/Pagination';
import { FormCheckbox } from '../../../common/components/inputs';
import {
  URL_KEY_BRAND,
  URL_KEY_COLORS,
  URL_KEY_CONDITIONS,
  URL_KEY_MAX_PRICE,
  URL_KEY_MIN_PRICE,
  URL_KEY_SEARCH_QUERY,
  URL_KEY_SPECIFIC_ATTRS,
  URL_KEY_STATUS
} from '../../../store/urlKeys';
import { ProductsGrid } from '../../../widgets/product';
import { Link } from '../../../common/components/Link';
import { PathBuilder } from '../../../common/utils/routes';
import { useBatchedQueryParam } from '../../../common/hooks/queryParam/useBatchedQueryParam';
import { useBatchedQueryParamArray } from '../../../common/hooks/queryParam/useBatchedQueryParamArray';
import { useCurrency } from '../../../store/currency';
import { CatalogFiltersLayoutDesktop, CatalogFiltersLayoutMobile } from './CatalogFilters';
import s from './CatalogPageContent.module.scss';

export const CatalogPageContent: ReactFCC = () => {
  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);

  const [isMobileFiltersOpen, { set: openMobileFilters, unset: closeMobileFilters }] = useToggle();

  const { search } = useLocation();

  let restParams: string | undefined = undefined;

  if (search.includes('?q')) {
    if (search.includes('&')) {
      restParams = search.substring(search.indexOf('&') + 1);
    }
  } else if (search.includes('?') && !search.includes('?q')) {
    restParams = search.substring(search.indexOf('?') + 1);
  }

  const { categories, subCategories, subSubCategories, category, subCategory, subSubCategory, navigationLoading } =
    useNavigation();

  const [minPrice, setMinPrice] = useBatchedQueryParam<string>(URL_KEY_MIN_PRICE, { defaultValue: '' });
  const [maxPrice, setMaxPrice] = useBatchedQueryParam<string>(URL_KEY_MAX_PRICE, { defaultValue: '' });

  const [brandId, setBrandId] = useBatchedQueryParam<string | null>(URL_KEY_BRAND);
  const [colorIds, setColorIds] = useBatchedQueryParamArray(URL_KEY_COLORS);
  const [conditionIds, setConditionIds] = useBatchedQueryParamArray(URL_KEY_CONDITIONS);

  const [specificAttributeValues, setSpecificAttributeValues] =
    useBatchedQueryParamArray<SpecificAttributeInput[]>(URL_KEY_SPECIFIC_ATTRS);

  const [statusIds, setStatusIds] = useBatchedQueryParamArray(URL_KEY_STATUS);
  const [searchQuery] = useBatchedQueryParam<string>(URL_KEY_SEARCH_QUERY);

  const { offset, setOffset } = usePagination();

  // todo подумать над тем, что можно отправлять undefined, если выбраны все опции
  const inputBrandIds = brandId ? [brandId] : undefined;
  const inputColorIds = colorIds.length !== 0 ? colorIds : undefined;
  const inputConditionTypes = conditionIds.length !== 0 ? (conditionIds as ProductConditionEnum[]) : undefined;
  const [inputStatusType] = statusIds ? (statusIds.length > 1 ? [] : (statusIds as ProductStatusEnum[])) : [];
  const inputSpecificAttributes = specificAttributeValues.length !== 0 ? specificAttributeValues : undefined;

  const { data, loading: productsLoading } = useCatalogProductsQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        limit: DEFAULT_PAGE_LIMIT,
        offset,
        filters: {
          categoryId: category?.id,
          subCategoryId: subCategory?.id,
          subSubCategoryId: subSubCategory?.id,
          brandIds: inputBrandIds,
          colorIds: inputColorIds,
          conditions: inputConditionTypes,
          specificAttributes: inputSpecificAttributes,
          status: inputStatusType,
          searchQuery: searchQuery,
          price: {
            min: tryNumber(minPrice) ?? undefined,
            max: tryNumber(maxPrice) ?? undefined
          }
        }
      }
    }
  });

  const products = data?.products.entries;
  const pagination = data?.products.pagination;

  useEffect(() => {
    if (!navigationLoading && !subSubCategory) {
      setSpecificAttributeValues([]);
    }
  }, [subSubCategory, setSpecificAttributeValues, navigationLoading]);

  const { currencyLoading } = useCurrency();

  const pageLoading = productsLoading || currencyLoading;

  const intl = useIntl();

  const onChangeStatus = (value: boolean) => {
    if (value) {
      setStatusIds([ProductStatusEnum.ForSale]);
    } else {
      setStatusIds([]);
    }
  };

  const specificAttributesCount = specificAttributeValues.map((sa) => sa.variantIds)?.flat().length;

  return pageLoading ? (
    <LoaderBox />
  ) : (
    <Grid className={s.CatalogPageContent}>
      {isMobile && (
        <>
          <Grid.GridItem className={s.CatalogPageContent__header}>
            <Anchor component="button" variant={AnchorVariant.BODY} leftIcon={BsSliders} onClick={openMobileFilters}>
              {(brandId ||
                !!colorIds.length ||
                !!conditionIds.length ||
                !!statusIds.length ||
                !!specificAttributesCount) && (
                <span className={s.CatalogPageContent__filterCounter}>
                  {+!!brandId + +!!statusIds.length + colorIds.length + conditionIds.length + specificAttributesCount}
                </span>
              )}
            </Anchor>

            <div className={s.CatalogPageContent__back}>
              {subCategory && (
                <LinkBack
                  to={PathBuilder.getCatalogPath({
                    categoryId: subCategory && category?.id,
                    subCategoryId: subSubCategory && subCategory?.id,
                    subSubCategoryId: subSubCategory && subSubCategory.id,
                    searchQuery,
                    restParams
                  })}
                />
              )}

              <Anchor component="span" variant={AnchorVariant.SECONDARY} disabled>
                {(subSubCategory && subSubCategory.name) ||
                  (subCategory && subCategory.name) ||
                  (category && category.name)}
              </Anchor>
            </div>
          </Grid.GridItem>

          <CatalogFiltersLayoutMobile
            open={isMobileFiltersOpen}
            onClose={closeMobileFilters}
            minPrice={minPrice}
            maxPrice={maxPrice}
            setMinPrice={setMinPrice}
            setMaxPrice={setMaxPrice}
            brandId={brandId}
            setBrandId={setBrandId}
            colorIds={colorIds}
            setColorIds={setColorIds}
            subSubCategoryId={subSubCategory?.id}
            specificAttributeValues={specificAttributeValues}
            setSpecificAttributeValues={setSpecificAttributeValues}
            conditionIds={conditionIds}
            setConditionIds={setConditionIds}
            statusIds={statusIds}
            setStatusIds={setStatusIds}
          />
        </>
      )}

      {!isMobile && (
        <Grid.GridItem cols={{ lg: 3 }}>
          <div className={clsx(s.CatalogPageContent__filtersGroup, s.CatalogPageContent__filtersCategories)}>
            {category ? (
              <>
                <Link
                  to={PathBuilder.getCatalogPath({ categoryId: category.id, searchQuery, restParams })}
                  className={clsx(
                    s.CatalogPageContent__filtersCategory,
                    !subCategory && s.CatalogPageContent__filtersCategory_selected
                  )}
                  variant={AnchorVariant.BODY}
                >
                  {category.name}
                </Link>
                {subCategory ? (
                  <>
                    <Link
                      to={PathBuilder.getCatalogPath({
                        categoryId: category.id,
                        subCategoryId: subCategory.id,
                        searchQuery,
                        restParams
                      })}
                      className={clsx(
                        s.CatalogPageContent__filtersCategory,
                        s.CatalogPageContent__filtersCategory_level2,
                        !subSubCategory && s.CatalogPageContent__filtersCategory_selected
                      )}
                      variant={AnchorVariant.BODY}
                    >
                      {subCategory.name}
                    </Link>
                    {subSubCategory ? (
                      <Link
                        to={PathBuilder.getCatalogPath({
                          categoryId: category.id,
                          subCategoryId: subCategory.id,
                          subSubCategoryId: subSubCategory.id,
                          searchQuery,
                          restParams
                        })}
                        className={clsx(
                          s.CatalogPageContent__filtersCategory,
                          s.CatalogPageContent__filtersCategory_level3,
                          s.CatalogPageContent__filtersCategory_selected
                        )}
                        variant={AnchorVariant.BODY}
                      >
                        {subSubCategory.name}
                      </Link>
                    ) : (
                      subSubCategories?.map(({ id, name }, index) => (
                        <Link
                          to={PathBuilder.getCatalogPath({
                            categoryId: category.id,
                            subCategoryId: subCategory.id,
                            subSubCategoryId: id,
                            searchQuery,
                            restParams
                          })}
                          className={clsx(
                            s.CatalogPageContent__filtersCategory,
                            s.CatalogPageContent__filtersCategory_level3
                          )}
                          variant={AnchorVariant.BODY}
                          key={index}
                        >
                          {name}
                        </Link>
                      ))
                    )}
                  </>
                ) : (
                  subCategories?.map(({ id, name }, index) => (
                    <Link
                      to={PathBuilder.getCatalogPath({
                        categoryId: category.id,
                        subCategoryId: id,
                        searchQuery,
                        restParams
                      })}
                      className={clsx(
                        s.CatalogPageContent__filtersCategory,
                        s.CatalogPageContent__filtersCategory_level2
                      )}
                      variant={AnchorVariant.BODY}
                      key={index}
                    >
                      {name}
                    </Link>
                  ))
                )}
              </>
            ) : (
              categories?.map(({ id, name }, index) => (
                <Link
                  to={PathBuilder.getCatalogPath({
                    categoryId: id,
                    searchQuery,
                    restParams
                  })}
                  className={s.CatalogPageContent__filtersCategory}
                  variant={AnchorVariant.BODY}
                  key={index}
                >
                  {name}
                </Link>
              ))
            )}
          </div>

          <CatalogFiltersLayoutDesktop
            minPrice={minPrice}
            maxPrice={maxPrice}
            setMinPrice={setMinPrice}
            setMaxPrice={setMaxPrice}
            brandId={brandId}
            setBrandId={setBrandId}
            colorIds={colorIds}
            setColorIds={setColorIds}
            subSubCategoryId={subSubCategory?.id}
            specificAttributeValues={specificAttributeValues}
            setSpecificAttributeValues={setSpecificAttributeValues}
            conditionIds={conditionIds}
            setConditionIds={setConditionIds}
            statusIds={statusIds}
            setStatusIds={setStatusIds}
          />
        </Grid.GridItem>
      )}

      <Grid.GridItem cols={{ xs: 2, lg: 9 }}>
        {productsLoading ? (
          <LoaderBox />
        ) : (
          <div className={s.CatalogPageContent__content}>
            <div className={s.CatalogPageContent__hideSoldItems}>
              <FormCheckbox
                onChange={(e) => onChangeStatus(e.target.checked)}
                checked={inputStatusType === ProductStatusEnum.ForSale}
                label={intl.formatMessage({ id: 'search.hideSoldItems' })}
              />
            </div>

            <Grid cols={9} gap={GridGap.MEDIUM}>
              {products && products.length ? (
                <Grid.GridItem full>
                  <ProductsGrid products={products} cols={3} />
                </Grid.GridItem>
              ) : (
                <Grid.GridItem full>
                  <NotFound title={intl.formatMessage({ id: 'catalog.noResultsTitle' })} />
                </Grid.GridItem>
              )}
            </Grid>

            <Space size={ESpaceSize.PIXEL_40} />

            {pagination && (
              <Pagination
                limit={pagination.limit}
                offset={offset}
                setOffset={setOffset}
                totalCount={pagination.totalCount}
              />
            )}
          </div>
        )}
      </Grid.GridItem>
    </Grid>
  );
};
