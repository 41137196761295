import React from 'react';
import { useFormik } from 'formik';
import { useUpdateEffect } from 'react-use';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';
import { Form } from 'react-bootstrap';
import { ReactFCC } from '../../../../common/utils/helperTypes';
import { getGraphqlErrorMessage } from '../../../../common/utils/graphqlErrors';
import { handleDefaultError } from '../../../../common/utils/handleDefaultError';
import { Modal } from '../../../../common/components/Modal';
import { FormInput } from '../../../../common/components/inputs';
import { ESpaceSize, Space } from '../../../../common/components/Space/Space';
import { ButtonVariant } from '../../../../common/components/Button';
import { useUser } from '../../../../entities/user';
import { useSendFeedbackMessageMutation } from '../../../../store/graphql';
import {
  FeedbackModalKeys,
  FeedbackModalSchema,
  maxFeedbackMessageLength,
  TFeedbackModalValues
} from './feedbackModalSchema';
import s from './FeedbackModal.module.scss';

export interface TFeedbackModal {
  /**
   * Состояние открытого/закрытого модального окна
   */
  isOpen: boolean;
  /**
   * Действие при закрытии модального окна
   */
  onClose: () => void;
  /**
   * ID заказа в Arisora
   */
  orderID?: string;
  /**
   * Начальные значения в инпутах
   */
  initialValues?: TFeedbackModalValues;
  /**
   * Опциональный дополнительный класс на компонент
   */
  className?: string;
}

export const FeedbackModal: ReactFCC<TFeedbackModal> = (props: TFeedbackModal) => {
  const { isOpen, onClose, initialValues, orderID, className } = props;
  const intl = useIntl();

  const { user } = useUser();

  const [sendFeedbackMessage] = useSendFeedbackMessageMutation();

  const formik = useFormik<TFeedbackModalValues>({
    initialValues: {
      name: initialValues?.name || '',
      email: initialValues?.email || '',
      message: initialValues?.message || ''
    },
    validationSchema: FeedbackModalSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, helpers) => {
      try {
        const success = await sendFeedbackMessage({
          variables: {
            input: {
              name: values.name,
              email: values.email,
              message: values.message
            }
          }
        });
        helpers.resetForm();
        if (success) {
          toast.success(intl.formatMessage({ id: 'footer.feedbackSuccess' }));
          closeForm();
        }
      } catch (e: any) {
        const errorText = getGraphqlErrorMessage(e);
        if (errorText) {
          handleDefaultError(errorText);
        }
      }
    }
  });

  useUpdateEffect(() => {
    if (initialValues) {
      formik.setValues(initialValues);
    }
  }, [initialValues]);

  useUpdateEffect(() => {
    if (user) {
      formik.setValues({
        name: user.name || '',
        email: user.email || '',
        message: ''
      });
    }
  }, [user]);

  const closeForm = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Modal
      className={clsx(className)}
      title={intl.formatMessage({ id: 'footer.feedback' })}
      isOpen={isOpen}
      onClose={closeForm}
    >
      <Modal.Body>
        <FormInput
          className={s.FeedbackModal__textInput}
          name={FeedbackModalKeys.name}
          value={formik.values.name}
          onChange={formik.handleChange}
          label={intl.formatMessage({ id: 'auth.nameLabel' })}
          placeholder={intl.formatMessage({ id: 'auth.nameLabel' })}
          error={formik.errors.name}
          required
        />
        <Space size={ESpaceSize.PIXEL_24} />
        <FormInput
          className={s.FeedbackModal__textInput}
          name={FeedbackModalKeys.email}
          value={formik.values.email}
          onChange={formik.handleChange}
          label={intl.formatMessage({ id: 'auth.emailLabel' })}
          placeholder={intl.formatMessage({ id: 'auth.emailLabel' })}
          error={formik.errors.email}
          required
        />
        <Space size={ESpaceSize.PIXEL_24} />
        <FormInput
          as={'textarea'}
          label={intl.formatMessage({ id: 'footer.feedbackMessage' })}
          name={FeedbackModalKeys.message}
          placeholder={intl.formatMessage({ id: 'footer.feedbackPlaceholder' })}
          classes={{ input: s.FeedbackModal__textArea }}
          value={formik.values.message}
          onChange={formik.handleChange}
          error={formik.errors.message}
          space={{ bottom: ESpaceSize.PIXEL_6 }}
          maxLength={maxFeedbackMessageLength}
        />
        <Form.Text className={s.ComplainModal__formTip} muted>
          {formik.values.message?.length} / {maxFeedbackMessageLength}
        </Form.Text>
      </Modal.Body>

      <Modal.Footer className={s.FeedbackModal__footer}>
        <Modal.Button onClick={formik.submitForm} loading={formik.isSubmitting}>
          <FormattedMessage id={'general.send'} />
        </Modal.Button>
        <Modal.Button onClick={closeForm} variant={ButtonVariant.SECONDARY}>
          <FormattedMessage id={'general.back'} />
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};
