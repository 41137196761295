import { useEffect } from 'react';
import { FormikContextType } from 'formik';

function getNodeTopOffset(node?: HTMLElement | null): number {
  if (!node) return 0;

  const bounds = node.getBoundingClientRect();
  const boxTop = bounds.top || 0;
  const winScrollY = window.scrollY || 0;
  return boxTop + winScrollY;
}

function getFirstObjectValueIndexesString<T extends Record<string | number, any>>(object: T): string {
  if (!object) return '';

  const keys = Object.keys(object);
  let idxString = '';

  if (keys.length > 0) {
    const key = keys.find((k) => !!object[k]);

    if (!key) return idxString;

    const value = object[key];
    idxString = key;

    if (Array.isArray(value) || typeof value === 'object') {
      idxString += `.${getFirstObjectValueIndexesString(value)}`;
    }
  }

  return idxString.replace(/(\.\d)+/, (match) => {
    const num = match.match(/\d/)?.[0] || '';
    return `[${num}]`;
  });
}

/**
 * естесн не скроллит к элементам формы с useField() :(
 */
export const FormScrollToError = ({ formik }: { formik: FormikContextType<any> }) => {
  const { isValid, errors } = formik;

  useEffect(() => {
    if (!isValid) {
      const selectorName = getFirstObjectValueIndexesString(errors);

      if (selectorName) {
        try {
          const errorNode = (document.querySelector(`[name="${selectorName}"]`) ||
            document.getElementById(selectorName)) as HTMLElement;

          if (errorNode) {
            const nodeTop = getNodeTopOffset(errorNode);
            const middle = nodeTop - window.innerHeight / 2;
            window.scrollTo({
              top: middle,
              left: 0,
              behavior: 'smooth'
            });
          }
        } catch (err) {
          console.error(err);
        }
      }
    }
  }, [errors, isValid]);

  return null;
};
