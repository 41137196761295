import React from 'react';
import { useUi } from '../../../store/ui';
import { Link } from '../../../common/components/Link';
import { Anchor } from '../../../common/components/Anchor';
import { HOME_ROUTE } from '../../../common/utils/routes';
import { useAuth } from '../../../app/providers/auth-apollo';
import s from './CartEmpty.module.scss';

export const CartEmpty: React.FC = () => {
  const { toggleAuthModal } = useUi();
  const { isAuthenticated } = useAuth();

  return (
    <div className={s.CartEmpty}>
      <h5 className={s.CartEmpty__title}>There are no items in the cart</h5>
      {isAuthenticated ? (
        <span className={s.CartEmpty__text}>
          Start with the <Link to={HOME_ROUTE}>homepage</Link> or use the search to find something specific
        </span>
      ) : (
        <span className={s.CartEmpty__text}>
          Start with the <Link to={HOME_ROUTE}>homepage</Link> or use the search to find something specific or{' '}
          <Anchor component={'span'} onClick={() => toggleAuthModal(true)}>
            sign in
          </Anchor>{' '}
          to your account to see items added to cart on other devices
        </span>
      )}
    </div>
  );
};
