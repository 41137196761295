import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { AvailableLanguagesEnum } from 'store/graphql';
import { BsCheckCircleFill } from 'react-icons/bs';
import { ReactFCC } from '../../common/utils/helperTypes';
import { mobileAppTriggerUrl } from '../../config';
import { useLang } from '../../store/intl';
import { HOME_ROUTE } from '../../common/utils/routes';
import s from './MobileAppPage.module.scss';

type LocationStateType = {
  pathname: string;
  language?: AvailableLanguagesEnum;
};

export const MobileAppPageComp: ReactFCC<{ state: LocationStateType }> = ({ state }) => {
  const { pathname, language } = state;
  const { changeUserLanguage } = useLang();

  /**
   * Setting Language
   */
  useEffect(() => {
    const isValidLang = language && Object.values(AvailableLanguagesEnum).includes(language);
    if (isValidLang) {
      changeUserLanguage(language);
    }
  }, [changeUserLanguage, language]);

  useEffect(() => {
    setTimeout(() => window.location.replace(`${mobileAppTriggerUrl}${pathname.substring(1)}`), 1300);
  }, [pathname]);

  return (
    <div className={s.MobileAppPage}>
      <h4 className={s.MobileAppPage__title}>
        <FormattedMessage id={'app.operationComplete'} />
      </h4>
      <BsCheckCircleFill className={s.MobileAppPage__icon} size={40} />
    </div>
  );
};

export const MobileAppPage: ReactFCC = () => {
  const { state } = useLocation();
  const pathname = state?.pathname || '';

  if (!pathname) {
    return <Navigate to={HOME_ROUTE} replace />;
  }

  return <MobileAppPageComp state={state} />;
};
