import React from 'react';
import { CDNFilenameType } from './files';

export type TUiContext = {
  cdnUrl: string;
  createCDNFileLink: (filename: CDNFilenameType) => string;
  authModalActive: boolean;
  userDataModalActive: boolean;
  toggleAuthModal: (value?: boolean) => void;
  toggleUserDataModal: (value?: boolean) => void;

  showMobileNav: boolean;
  setShowMobileNav: (value: boolean) => void;
  showTopNav: boolean;
  setShowTopNav: (value: boolean) => void;
  showFooter: boolean;
  setShowFooter: (value: boolean) => void;
  catalogOpen: boolean;
  setCatalogOpen: (value: boolean) => void;
  bodyScrollPrevented: boolean;
  preventBodyScroll: (value: boolean) => void;
};

export const UiContext = React.createContext<TUiContext>({
  cdnUrl: '',
  createCDNFileLink: () => '',
  authModalActive: false,
  userDataModalActive: false,
  toggleAuthModal: () => {},
  toggleUserDataModal: () => {},

  showMobileNav: true,
  setShowMobileNav: () => {},
  showTopNav: true,
  setShowTopNav: () => {},
  showFooter: true,
  setShowFooter: () => {},
  catalogOpen: false,
  setCatalogOpen: () => {},
  bodyScrollPrevented: false,
  preventBodyScroll: () => {}
});
