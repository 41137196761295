import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import s from './ProductBadge.module.scss';

export enum ProductBadgeSize {
  MEDIUM = 'medium',
  SMALL = 'small'
}

export interface ProductBadgeProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Размер бейджика (medium, small), по умолчанию medium
   */
  size?: ProductBadgeSize;
  /**
   * Содержимое бейджика. По умолчанию выводится SOLD
   */
  children?: React.ReactNode;
}

export const ProductBadge: ReactFCC<ProductBadgeProps> = (props) => {
  const { children, className, size = ProductBadgeSize.MEDIUM } = props;

  return (
    <div className={clsx(s.ProductBadge, className, s[`ProductBadge_size_${size}`])}>
      {children || <FormattedMessage id="product.statusSold" />}
    </div>
  );
};
