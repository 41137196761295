import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BsCheck, BsCheckAll, BsCheckCircleFill, BsLock, BsPaperclip, BsSendFill, BsUnlock } from 'react-icons/bs';
import clsx from 'clsx';
import { format, Locale } from 'date-fns';
import { useEffectOnce } from 'react-use';
import { Lightbox } from 'react-modal-image';
import { useNavigate } from 'react-router';
import { debounce } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import {
  TicketMessage,
  TicketStatusEnum,
  useChangeTicketStatusMutation,
  useTicketMessagesLazyQuery
} from '../../../../../store/graphql';
import { InputUpload } from '../../../../../common/components/inputs';
import { useUser } from '../../../../../entities/user';
import { useSocket } from '../../../../../common/hooks/useSocket';
import { useUpload } from '../../../../../lib/file-uploader';
import { FileAction, ImageUploadExt } from '../../../../../lib/msfiles-client';
import { getGraphqlErrorMessage } from '../../../../../common/utils/graphqlErrors';
import { handleDefaultError } from '../../../../../common/utils/handleDefaultError';
import { Loader, LoaderSize } from '../../../../../common/components/Loader';
import { useIsMobile } from '../../../../../common/hooks/useIsMobile';
import ImageLoader from '../../../../../common/components/ImageLoader/ImageLoader';
import { useToggle } from '../../../../../common/hooks/useToggle';
import { TICKETS_ROUTE } from '../../../../../common/utils/routes';
import { LinkBack } from '../../../../../common/components/LinkBack';
import { useUi } from '../../../../../store/ui';
import { useTickets } from '../../../../../store/tickets/useTickets';
import { ESpaceSize, Space } from '../../../../../common/components/Space/Space';
import { formatArisoraLocales } from '../../../../../common/components/CustomFormatters';
import { useLang } from '../../../../../store/intl';
import { formatDateHeader } from '../../../../../common/utils/date';
import { TicketChatSkeletonSkeleton } from '../TicketChatSkeleton/TicketChatSkeleton';
import { clearCache } from '../../../../../app/providers/auth-apollo';
import { Button, ButtonSize, ButtonVariant } from '../../../../../common/components/Button';
import { useWebview } from '../../../../../store/webview/useWebview';
import { ChatInput } from './components/ChatInput/ChatInput';
import s from './TicketChat.module.scss';

type TicketChatProps = {
  ticketId: number;
  topic: string;
  initialMessages: TicketMessage[];
  status: TicketStatusEnum;
};

type TicketMessagesGroup = {
  date: string;
  messages: TicketMessage[];
};
export const TicketChat = (props: TicketChatProps) => {
  const { initialMessages, status, ticketId, topic } = props;
  const { language } = useLang();

  const audio = new Audio('/pop3.mp3');
  audio.volume = 0.2;
  const { isWebview } = useWebview();

  const ticketClosed = status === TicketStatusEnum.Closed;

  const userLocale = formatArisoraLocales[language];
  const [fetchNewMessages, { loading: newMessagesLoading }] = useTicketMessagesLazyQuery();

  const [groupedMessages, setGroupedMessage] = useState<TicketMessagesGroup[]>(
    groupMessagesByUserDate(initialMessages, userLocale)
  );
  const [message, setMessage] = useState<string>('');
  const messageContainer = useRef<HTMLDivElement>(null);
  const messagesRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  let latestMessageIdRef = useRef<string>();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [onlineUsers, setOnlineUsers] = useState<string[]>([]);
  const [isTyping, setIsTyping] = useState(false);
  const [usersTyping, setUsersTyping] = useState<{ userId: string; userName: string }[]>([]);
  const [managersOnline, setManagersOnline] = useState(false);
  const [imageUploadProgress, setImageUploadProgress] = useState(false);
  const [lightboxImage, setLightboxImage] = useState<string>();
  const [showLightbox, { toggle: toggleLightbox }] = useToggle(false);
  const { recountMessages } = useTickets();
  const [changeTicketStatusMutation, { loading: changeTicketStatusLoading }] = useChangeTicketStatusMutation({
    refetchQueries: ['Ticket', 'Tickets'],
    update: clearCache(['getTicket', 'getTickets'])
  });

  const { connectSocket, closeSocket, socketRef, socketConnected } = useSocket({
    namespace: 'tickets'
  });
  const { user } = useUser();
  const isMobile = useIsMobile();

  const [uploadMutation] = useUpload({
    allowedActions: [FileAction.UploadImage],
    params: {
      [FileAction.UploadImage]: {
        synchronously: true,
        ext: ImageUploadExt.jpeg
      }
    }
  });

  function groupMessagesByUserDate(messages: TicketMessage[], locale: Locale) {
    const grouped = messages.reduce((acc, message) => {
      const messageDate = formatDateHeader(new Date(message.createdAt), locale);
      if (!acc[messageDate]) {
        acc[messageDate] = [];
      }
      acc[messageDate].push(message);
      return acc;
    }, {} as Record<string, TicketMessage[]>);

    return Object.keys(grouped).map((date) => ({
      date,
      messages: grouped[date]
    }));
  }

  const onImageUpload = async (e?: React.ChangeEvent<HTMLInputElement>, directFile?: File) => {
    let file: File;
    if (e?.target.files) {
      const [targetFile] = Array.from(e.target.files);
      file = targetFile;
    } else if (directFile) {
      file = directFile;
    } else {
      return;
    }

    try {
      setImageUploadProgress(true);
      const attachment = await uploadMutation({
        file,
        params: { synchronously: true, ext: ImageUploadExt.jpeg }
      });
      if (attachment) {
        setImageUploadProgress(false);
        sendMessage(attachment.msfiles_uid);
      }
    } catch (e: any) {
      setImageUploadProgress(false);
      const graphqlErrorMessage = getGraphqlErrorMessage(e);
      handleDefaultError(graphqlErrorMessage || e.message || 'Error occurred while uploading image', e);
    }
    if (e?.target.value) e.target.value = '';
  };

  const initListeners = () => {
    if (document.hidden) {
      return;
    }
    if (!socketRef.current) {
      return;
    }
    socketRef.current?.off('messageReceived');
    socketRef.current?.off('messagesRead');
    socketRef.current?.off('roomUsers');
    socketRef.current?.off('connect');

    socketRef.current.on('connect', async () => {
      if (!latestMessageIdRef.current) {
        return;
      }
      const { data } = await fetchNewMessages({
        variables: {
          input: {
            ticketId,
            latestMessageId: latestMessageIdRef.current
          }
        },
        fetchPolicy: 'network-only'
      });
      const newMessages = data?.result;
      if (!newMessages?.length) {
        return;
      }
      setGroupedMessage((prevGroupedMessages) => {
        const groupedMessagesMap = new Map();
        prevGroupedMessages.forEach((group) => {
          groupedMessagesMap.set(group.date, [...group.messages]);
        });
        newMessages.forEach((message) => {
          const messageDate = formatDateHeader(new Date(message.createdAt), userLocale);
          if (groupedMessagesMap.has(messageDate)) {
            groupedMessagesMap.get(messageDate).push(message);
          } else {
            groupedMessagesMap.set(messageDate, [message]);
          }
        });
        return Array.from(groupedMessagesMap, ([date, messages]) => ({ date, messages }));
      });
    });
    socketRef.current.emit('joinRoom', { roomId: `${ticketId}`, userId: user?.id });
    socketRef.current.on('messageReceived', (newMessage) => {
      setGroupedMessage((prevGroupedMessages) => {
        const messageDate = formatDateHeader(new Date(newMessage.createdAt), userLocale);
        const groupExists = prevGroupedMessages.some((group) => group.date === messageDate);
        if (groupExists) {
          return prevGroupedMessages.map((group) => {
            if (group.date === messageDate) {
              return {
                ...group,
                messages: [...group.messages, newMessage]
              };
            }
            return group;
          });
        } else {
          return [...prevGroupedMessages, { date: messageDate, messages: [newMessage] }];
        }
      });
      audio.play();
    });
    socketRef.current.on('messagesRead', (messageIds) => {
      markMessagesAsRead(messageIds);
      debouncedMessageRecount();
    });
    socketRef.current.on('roomUsers', ({ users, managersOnline: adminsOnline }) => {
      setOnlineUsers(users);
      setUsersTyping((prevState) => prevState.filter((userTyping) => users.includes(userTyping.userId)));
      setManagersOnline(adminsOnline);
    });
    socketRef.current.on('userTyping', ({ userId, userName }) => {
      if (user?.id !== userId) {
        setUsersTyping((prevUsers) => [...prevUsers, { userId, userName }]);
      }
    });
    socketRef.current.on('userStoppedTyping', (typingUser) => {
      setUsersTyping((prevUsers) => prevUsers.filter((user) => user.userId !== typingUser.userId));
    });
  };

  const stopTyping = () => {
    if (socketRef.current) {
      socketRef.current.emit('stopTyping', { ticketId });
      setIsTyping(false);
    }
  };

  const debouncedStopTyping = useCallback(
    debounce(() => stopTyping(), 1500),
    []
  );

  const debouncedMessageRecount = debounce(() => recountMessages(), 1000);

  const initializeSocket = async () => {
    const cleanup = await connectSocket();
    initListeners();
    return () => {
      if (cleanup) {
        cleanup();
      }
    };
  };

  useEffectOnce(() => {
    initializeSocket();
    return () => {
      closeSocket(`${ticketId}`, user?.id);
    };
  });

  const sendMessage = async (imageUid?: string) => {
    if ((message || imageUid) && socketRef.current) {
      socketRef.current.emit('sendMessage', { body: message, imageUid, ticketId });
      setMessage('');
      stopTyping();
    }
  };

  useEffectOnce(() => {
    window.addEventListener('visibilitychange', initListeners);
    window.addEventListener('online', initListeners);
    return () => {
      window.removeEventListener('visibilitychange', initListeners);
      window.removeEventListener('online', initListeners);
    };
  });

  const handleReadMessages = (messageIds: string[]) => {
    if (!messageIds?.length || !socketRef.current) {
      return;
    }
    socketRef.current.emit('markMessagesAsRead', { messageIds, ticketId });
  };

  const markMessagesAsRead = (messageIds: string[]) => {
    setGroupedMessage((prevMessages) =>
      prevMessages.map((group) => ({
        ...group,
        messages: group.messages.map((message) => {
          if (messageIds.includes(message.messageId)) {
            return {
              ...message,
              read: true
            };
          }
          return message;
        })
      }))
    );
  };

  const loading = !socketConnected || !onlineUsers.length;

  useEffect(() => {
    if (loading) {
      return;
    }
    const observer = new IntersectionObserver(
      (entries) => {
        // Read messages by batches
        const unreadMessagesIds = new Set<string>();
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const messageId = entry.target.getAttribute('message-id');
            const readStatus = entry.target.getAttribute('read-status');
            const senderId = entry.target.getAttribute('sender-id');
            if (messageId && readStatus === 'false' && senderId !== user?.id) {
              unreadMessagesIds.add(messageId);
            }
          }
        });

        setTimeout(() => {
          if (unreadMessagesIds.size > 0) {
            handleReadMessages(Array.from(unreadMessagesIds));
          }
        }, 700);
      },
      { threshold: 0.1 }
    );

    if (messagesRefs.current) {
      Object.values(messagesRefs.current).forEach((ref) => {
        if (ref) {
          observer.observe(ref);
        }
      });
    }
    return () => {
      observer.disconnect();
    };
  }, [loading, handleReadMessages, user?.id]);

  const openLightbox = (messageId: string) => {
    setLightboxImage(messageId);
    toggleLightbox();
  };

  const hideLightbox = () => {
    setLightboxImage('');
    toggleLightbox();
  };

  const handleImagePaste = (file: File) => {
    if (!imageUploadProgress) {
      onImageUpload(undefined, file);
    }
  };

  const navigate = useNavigate();
  const { preventBodyScroll } = useUi();

  const handleTyping = (message: string) => {
    setMessage(message);
    if (!isTyping) {
      socketRef.current?.emit('typing', { ticketId });
      setIsTyping(true);
    }
    debouncedStopTyping();
  };

  useEffect(() => {
    if (isMobile) {
      preventBodyScroll(true);
    }

    return () => preventBodyScroll(false);
  }, [isMobile, preventBodyScroll]);

  useEffect(() => {
    const messagesEls = Object.values(messagesRefs.current);
    if (groupedMessages && messagesEls.length > 0) {
      anchorRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
    }
  }, [groupedMessages]);

  useEffect(() => {
    if (!loading) {
      anchorRef.current?.scrollIntoView({ block: 'nearest', inline: 'end' });
    }
  }, [loading]);

  useEffect(() => {
    if (groupedMessages.length > 0) {
      const lastGroup = groupedMessages[groupedMessages.length - 1];
      latestMessageIdRef.current = lastGroup.messages[lastGroup.messages.length - 1].messageId;
    }
  }, [groupedMessages]);

  const handleTicketStatusChange = async () => {
    try {
      await changeTicketStatusMutation({
        variables: {
          input: {
            ticketId,
            status: TicketStatusEnum.Closed
          }
        }
      });
    } catch (e) {
      handleDefaultError('Something went wrong with ticket close');
    }
  };

  if (loading) {
    return <TicketChatSkeletonSkeleton />;
  }

  return (
    <div className={s.TicketChat}>
      <div className={s.TicketChat__header}>
        <div className={s.TicketChat__header_left}>
          {isMobile && <LinkBack onClick={() => navigate(TICKETS_ROUTE)} />}
          {ticketClosed ? <BsLock /> : <BsUnlock />}
          <span>Support</span>
          <span
            className={clsx(
              s.TicketChat_status,
              managersOnline ? s.TicketChat_status_online : s.TicketChat_status_offline
            )}
          ></span>
          <span className={s.TicketChat_topic}>{topic}</span>
        </div>
        {!ticketClosed && (
          <Button
            variant={ButtonVariant.PRIMARY}
            leftIcon={BsCheckCircleFill}
            size={ButtonSize.SMALL}
            onClick={handleTicketStatusChange}
          >
            <FormattedMessage id={'profile.ticketsClose'} />
          </Button>
        )}
      </div>
      <div
        className={clsx({ [s.TicketChat__container]: true, [s.TicketChat__container_webview]: isWebview })}
        ref={messageContainer}
      >
        {groupedMessages.map((group, groupIndex) => (
          <div className={s.TicketChat__messagesGroup} key={groupIndex}>
            <span className={s.TicketChat__messagesGroup_date}>{group.date}</span>
            <Space size={ESpaceSize.PIXEL_8} />
            {group.messages?.map((message) => (
              <div
                className={clsx({
                  [s.TicketChat__message]: true,
                  [s.TicketChat__message_self]: message.senderId === user?.id,
                  [s.TicketChat__message_closing]: message.closing
                })}
                key={message.messageId}
                message-id={message.messageId}
                read-status={message.read.toString()}
                sender-id={message.senderId}
                ref={(el) => (messagesRefs.current[message.messageId] = el)}
              >
                {message.image ? (
                  <>
                    <div onClick={() => openLightbox(message.messageId)}>
                      <ImageLoader
                        src={message.image.imageUrl}
                        naturalWidth={message.image.width}
                        naturalHeight={message.image.height}
                        maxWidth={isMobile ? 250 : 400}
                        maxHeight={isMobile ? 300 : 400}
                      />
                    </div>
                    {showLightbox && lightboxImage === message.messageId && (
                      <Lightbox
                        large={message.image.imageUrl}
                        small={message.image.imageUrl}
                        onClose={() => hideLightbox()}
                      />
                    )}
                  </>
                ) : (
                  <div className={s.TicketChat__messageContent}>
                    {message.senderId !== user?.id && message.sequenceStart && (
                      <span className={s.TicketChat__messageContent_name}>{message.senderName}</span>
                    )}
                    <span className={s.TicketChat__messageContent_body}>{message.body}</span>
                  </div>
                )}
                <div className={s.TicketChat__message_meta}>
                  {message.senderId === user?.id ? message.read ? <BsCheckAll /> : <BsCheck /> : <></>}
                  <span className={s.TicketChat__message_date}>{format(new Date(message.createdAt), 'HH:mm')}</span>
                </div>
              </div>
            ))}
          </div>
        ))}
        <div className={s.TicketChat__container_anchor} ref={anchorRef}></div>
      </div>
      {!ticketClosed && (
        <div
          className={clsx({
            [s.TicketChat__inputContainer]: true,
            [s.TicketChat__inputContainer_webview]: isWebview
          })}
        >
          <InputUpload
            className={clsx(
              s.TicketChat__inputContainer_imageUpload,
              ticketClosed && s.TicketChat__inputContainer_imageUpload_inactive
            )}
            onChange={onImageUpload}
            multiple={false}
            disabled={ticketClosed}
            accept={'image/*'}
          >
            {imageUploadProgress ? <Loader size={LoaderSize.SMALL} /> : <BsPaperclip size={20} />}
          </InputUpload>
          <ChatInput
            type={'text'}
            onChange={(e) => handleTyping(e.target.value)}
            value={message}
            disabled={ticketClosed}
            onKeyDown={() => sendMessage()}
            maxLength={500}
            maxInputHeight={isMobile ? 150 : 200}
            pasteAction={handleImagePaste}
          />
          <div
            className={s.TicketChat__sendButton}
            onClick={(e) => {
              e.preventDefault();
              sendMessage();
            }}
          >
            <BsSendFill
              className={clsx(s.TicketChat__sendButton_icon, !message && s.TicketChat__sendButton_icon_disabled)}
              size={20}
            />
          </div>
          {usersTyping.length > 0 && (
            <span className={s.TicketChat__inputContainer_typing}>
              <div className={s.TicketChat__container_typingDots}>
                <div className={s.TicketChat__container_typingDot}></div>
                <div className={s.TicketChat__container_typingDot}></div>
                <div className={s.TicketChat__container_typingDot}></div>
              </div>
              {usersTyping[0].userName} is typing
            </span>
          )}
        </div>
      )}
    </div>
  );
};
