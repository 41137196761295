import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TicketsQuery } from '../../../../../store/graphql';
import { LoaderBox } from '../../../../../common/components/Loader';
import { Ticket } from './components/Ticket';
import s from './TicketsList.module.scss';

type TicketsListProps = {
  tickets: TicketsQuery['result']['entries'];
  loading: boolean;
};
export const TicketsList = (props: TicketsListProps) => {
  const { tickets, loading } = props;

  if (loading) {
    return <LoaderBox />;
  }

  return (
    <div className={s.TicketsList}>
      {tickets.length ? (
        tickets.map((ticket, index) => (
          <Ticket
            key={index}
            id={ticket.ticketId}
            name={ticket.name}
            topic={ticket.topic}
            status={ticket.status}
            unreadCount={ticket.unreadCount || 0}
            updatedAt={ticket.updatedAt}
          />
        ))
      ) : (
        <FormattedMessage id={'profile.noTickets'} />
      )}
    </div>
  );
};
