import React, { useEffect, useMemo } from 'react';
import { groupBy, union, without } from 'lodash-es';
import { toast } from 'react-toastify';
import { useUpdateEffect } from 'react-use';
import {
  useAddItemToCartMutation,
  useCartLengthQuery,
  useCartProductsQuery,
  useCartSellersQuery,
  useRemoveItemFromCartMutation,
  useUpdateCartMutation
} from '../graphql';
import { LOCAL_STORAGE_CART } from '../storageKeys';
import { ICartGroup } from '../../pages/cart/types';
import { handleDefaultError } from '../../common/utils/handleDefaultError';
import { clearCache, useAuth } from '../../app/providers/auth-apollo';
import { useLocalStorage } from '../../common/hooks/useLocalStorage';
import { GoogleTagEvents, googleTagSendDefaultEvent } from '../../features/analytics';
import { useUser } from '../../entities/user';
import { CartContext } from './CartContext';
import s from './CartProvider.module.scss';

const mutationOptions = {
  refetchQueries: ['Cart', 'CartLength', 'IsProductInCart'],
  update: clearCache(['getUserCart', 'getUserCartLength', 'isProductInCart'])
};

export const CartProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { user } = useUser();

  const [localProductIds, setLocalProductIds] = useLocalStorage<string[]>(LOCAL_STORAGE_CART, []);

  const {
    data: productsQuery,
    loading: cartProductsLoading,
    refetch: fetchCartProducts
  } = useCartProductsQuery({
    skip: !(localProductIds && localProductIds?.length > 0),
    variables: {
      productIds: localProductIds || [],
      limit: localProductIds?.length || 0
    }
  });
  const products = productsQuery?.products.entries;

  const { data: sellersQuery, refetch: fetchCartSellers } = useCartSellersQuery();
  const sellers = sellersQuery?.sellers;

  const localProductGroups = groupBy(products, (product) => product.sellerId);

  const localCartGroups: ICartGroup[] | undefined = useMemo(() => {
    if (!sellers) {
      return;
    }

    return Object.entries(localProductGroups).map(([sellerId, products]) => {
      return {
        sellerId: sellerId,
        sellerName: sellers.find((s) => s.id === sellerId)?.name || '',
        sellerNickname: sellers.find((s) => s.id === sellerId)?.nickname || '',
        sellerTrusted: sellers.find((s) => s.isTrusted)?.isTrusted || false,
        items: products.map((product) => ({
          productId: product.id,
          product: product
        }))
      };
    });
  }, [localProductGroups, sellers]);

  const { data: cartLengthQuery, refetch: fetchCartLength } = useCartLengthQuery({
    skip: !isAuthenticated
  });
  const cartRemoteLength = cartLengthQuery?.length;

  const cartLength = isAuthenticated ? cartRemoteLength : localProductIds?.length;

  useEffect(() => {
    if (localProductIds && localProductIds.length > 0) {
      fetchCartProducts();
    }
    if (isAuthenticated) {
      fetchCartLength();
    }
    fetchCartSellers();
  }, [localProductIds, fetchCartLength, fetchCartProducts, fetchCartSellers, isAuthenticated]);

  useUpdateEffect(() => {
    if (!isAuthenticated) {
      setLocalProductIds([]);
    }
  }, [isAuthenticated, setLocalProductIds]);

  const [addItemToCartMutation, { loading: addItemToCartLoading }] = useAddItemToCartMutation(mutationOptions);
  const addItemToCart = async (productId: string) => {
    try {
      if (isAuthenticated) {
        await addItemToCartMutation({ variables: { productId } });
      }
      setLocalProductIds(union(localProductIds, [productId]));
      googleTagSendDefaultEvent(GoogleTagEvents.add_to_cart, {
        items: [
          {
            item_id: productId
          }
        ]
      });

      toast.success('Product added to cart', {
        className: s.CartProvider__addToCartToast
      });
    } catch (e) {
      handleDefaultError('Something went wrong', e);
    }
  };

  const [removeItemFromCartMutation, { loading: removeItemFromCartLoading }] =
    useRemoveItemFromCartMutation(mutationOptions);
  const removeItemFromCart = async (productId: string) => {
    try {
      if (isAuthenticated) {
        await removeItemFromCartMutation({ variables: { productId } });
      }
      setLocalProductIds(without(localProductIds, productId));
      googleTagSendDefaultEvent(GoogleTagEvents.remove_from_cart, {
        items: [
          {
            item_id: productId
          }
        ]
      });
    } catch (e) {
      handleDefaultError('Something went wrong', e);
    }
  };

  const [updateCartMutation] = useUpdateCartMutation(mutationOptions);
  const updateCart = async () => {
    if (localProductIds?.length) {
      await updateCartMutation({
        variables: {
          input: {
            cartItems: localProductIds.map((productId) => ({ productId }))
          }
        }
      });
      setLocalProductIds([]);
    }
  };

  return (
    <CartContext.Provider
      value={{
        localCartGroups,
        sellerId: user?.seller?.id,
        cartProductsLoading,
        cartLength,
        addItemToCart,
        addItemToCartLoading,
        removeItemFromCart,
        removeItemFromCartLoading,
        updateCart,
        setLocalProductIds,
        fetchCartLength
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
