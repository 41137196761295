import React, { useState } from 'react';
import { ReactFCC } from '../../../../common/utils/helperTypes';
import { useToggle } from '../../../../common/hooks/useToggle';
import { AddressCard, AddressCardProps, AddressForm } from '../../components';
import { UserAddress } from '../../types';

export type AddressCardWithFormProps = AddressCardProps;

/**
 * Компонент-обертка над карточкой адреса AddressCard,
 * предоставляющая в дополнение модалку с формой создания/редактирования адреса.
 * Используется в форме вызова курьера OrderCombine
 */
export const AddressCardWithForm: ReactFCC<AddressCardWithFormProps> = (props) => {
  const { address, ...restProps } = props;
  const [addressModalOpen, { set: openAddressModal, unset: closeAddressModal }] = useToggle(false);
  const [editedAddress, setEditedAddress] = useState<UserAddress | null>(null);

  return (
    <>
      <AddressCard
        address={address}
        onClickEdit={() => {
          openAddressModal();
          setEditedAddress(address);
        }}
        {...restProps}
      />

      <AddressForm
        isOpen={addressModalOpen}
        address={editedAddress}
        onClose={() => {
          closeAddressModal();
          setEditedAddress(null);
        }}
      />
    </>
  );
};
