import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { BsChevronRight } from 'react-icons/bs';
import React from 'react';
import { NavLink, NavLinkProps } from '../../../../common/components/Link';
import { useIsMobile } from '../../../../common/hooks/useIsMobile';
import s from './ProfileNavLink.module.scss';

export const ProfileNavLink: ReactFCC<NavLinkProps> = (props) => {
  const { children, className, ...rest } = props;

  const isMobile = useIsMobile();

  return (
    <NavLink
      className={({ isActive }) =>
        clsx(s.ProfileNavLink, className, {
          [s.ProfileNavLink_active]: isActive
        })
      }
      {...rest}
    >
      {children}
      {isMobile && <BsChevronRight />}
    </NavLink>
  );
};
