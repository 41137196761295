import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { BsPatchQuestion } from 'react-icons/bs';
import { Grid } from '../../../common/components/Grid';
import { LinkBack } from '../../../common/components/LinkBack';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import {
  ADDITIONAL_SERVICE_ROUTE,
  DELIVERY_PROBLEMS_ROUTE,
  HELP_ROUTE,
  PURCHASE_ISSUES_ROUTE,
  SELLER_IDENTITY_ROUTE,
  SUPPORT_HELP_ROUTE,
  WITHDRAW_HELP_ROUTE
} from '../../../common/utils/routes';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { HelpNavLink } from './HelpNavLink';
import s from './HelpLayout.module.scss';

export const HelpLayout: React.FC = () => {
  const location = useLocation();

  const isMobile = useIsMobile();

  return (
    <Grid className={location.pathname === HELP_ROUTE && isMobile ? s.HelpLayout__mobile : s.HelpLayout} cols={12}>
      <Grid.GridItem
        cols={{ xs: 2, lg: 3, xl: 2 }}
        className={location.pathname === HELP_ROUTE || !isMobile ? s.HelpLayout__content : ''}
      >
        {location.pathname === HELP_ROUTE || !isMobile ? (
          <>
            <div className={s.HelpLayout__back}>
              <LinkBack>
                <FormattedMessage id="general.back" />
              </LinkBack>
            </div>
            <div className={s.HelpLayout__badge}>
              <BsPatchQuestion size={16} />
              <span className={s.HelpLayout__badge_text}>
                <FormattedMessage id={'footer.help'} />
              </span>
            </div>
            <HelpNavLink to={SUPPORT_HELP_ROUTE} end>
              <FormattedMessage id={'footer.linkSupport'} />
            </HelpNavLink>

            <HelpNavLink to={ADDITIONAL_SERVICE_ROUTE} end>
              <FormattedMessage id={'footer.linkAdditionalServices'} />
            </HelpNavLink>

            <HelpNavLink to={DELIVERY_PROBLEMS_ROUTE} end>
              <FormattedMessage id={'footer.linkDeliveryProblems'} />
            </HelpNavLink>

            <HelpNavLink to={PURCHASE_ISSUES_ROUTE} end>
              <FormattedMessage id={'footer.linkProductProblems'} />
            </HelpNavLink>

            <HelpNavLink to={SELLER_IDENTITY_ROUTE} end>
              <FormattedMessage id={'footer.linkSellerIdentification'} />
            </HelpNavLink>

            <HelpNavLink to={WITHDRAW_HELP_ROUTE} end>
              <FormattedMessage id={'footer.linkWithdrawal'} />
            </HelpNavLink>
          </>
        ) : (
          <>
            <LinkBack>
              <FormattedMessage id="general.back" />
            </LinkBack>

            <Space size={ESpaceSize.PIXEL_24} />
          </>
        )}
      </Grid.GridItem>

      <Grid.GridItem cols={{ xs: 2, lg: 9 }} start={{ xs: 1, lg: 4 }}>
        <Outlet />
      </Grid.GridItem>
    </Grid>
  );
};
