import { useState } from 'react';
import { useFactoryRef } from './useFactoryRef';

type UseToggleReturn = [
  value: boolean,
  funcs: {
    set: () => void;
    unset: () => void;
    toggle: () => void;
    change: (value: boolean) => void;
  }
];

export const useToggle = (defaultValue: boolean = false): UseToggleReturn => {
  const [value, change] = useState(defaultValue);

  const funcsRef = useFactoryRef(() => ({
    set: () => {
      change(true);
    },
    unset: () => {
      change(false);
    },
    toggle: () => {
      change((s) => !s);
    }
  }));

  return [
    value,
    {
      change,
      ...funcsRef
    }
  ];
};
