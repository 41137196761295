import { ReactFCC } from 'common/utils/helperTypes';
import { Cropper } from 'react-cropper';
import { useCallback, useEffect, useState } from 'react';
import { BsInbox } from 'react-icons/bs';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { ButtonVariant } from '../../../../../common/components/Button';
import { InputUpload } from '../../../../../common/components/inputs';
import { Modal, ModalProps } from '../../../../../common/components/Modal';
import { Text, TextVariant } from '../../../../../common/components/Text';
import { Anchor } from '../../../../../common/components/Anchor';
import { UserInfo } from '../../../../../entities/user';
import { useUpdateUserAvatar } from '../../hooks';
import { handleDefaultError } from '../../../../../common/utils/handleDefaultError';
import { useUpload, extname } from '../../../../../lib/file-uploader';
import { FileAction } from '../../../../../lib/msfiles-client';
import { DEFAULT_UPLOAD_MAX_SIZE_MB } from '../../../../../lib/file-uploader/constants';
import { useUser } from '../../../../../entities/user';
import { getMimeType } from '../../../../../common/utils/getImageMimeType';
import s from './UpdateUserAvatarModal.module.scss';

export interface UpdateUserAvatarModalProps extends Pick<ModalProps, 'isOpen' | 'onClose'> {
  /**
   * Дополнительный css-класс
   */
  className?: string;
}

export const UpdateUserAvatarModal: ReactFCC<UpdateUserAvatarModalProps> = (props) => {
  const { className, isOpen, onClose } = props;

  const { user } = useUser();

  const [file, setFile] = useState<File | undefined>(undefined);
  const [image, setImage] = useState<string | undefined>();
  const [cropper, setCropper] = useState<Cropper | undefined>();

  const onChange = async (e: any) => {
    e.preventDefault();

    let files: File[] = [];

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result as any);
    };

    let file: File = files[0];
    const ext = extname(file.name);
    if (!ext || !['png', 'jpg', 'jpeg', 'webp', 'heic'].includes(ext)) {
      e.target.value = '';
      toast.error('Incorrect file type');
      return;
    }

    const mimeType = await getMimeType(file);
    if (mimeType === 'unknown') {
      /**
       * Snippet with usage of heic2any, on Android HEIC-JPG issue, raise bundle size a lot (!)
      const blob = new Blob([file]);
      const heic: any = await new Promise((resolve) =>
        heic2any({ blob, toType: 'image/jpeg', quality: 0.8 }).then(resolve)
      );
      file = new File([heic], file.name.replace(/\.[^/.]+$/, '.jpeg'), { type: 'image/jpeg' });
      **/
      toast.error('Incorrect file type');
      return;
    }
    reader.readAsDataURL(file);
    setFile(file);

    e.target.value = '';
  };

  const [uploadMutation, { reset: resetUpload, loading: uploadLoading }] = useUpload({
    allowedActions: [FileAction.UploadImage],
    params: {
      [FileAction.UploadImage]: {
        synchronously: true
      }
    }
  });
  const [updateUserAvatar, { loading: updateUserLoading }] = useUpdateUserAvatar();

  const saveImage = async () => {
    if (typeof cropper === 'undefined' || !file) {
      return;
    }

    try {
      const canvas = cropper.getCroppedCanvas();

      canvas.toBlob(
        async (blob: Blob | null) => {
          if (!blob) {
            return;
          }

          try {
            const source = new File([blob], file.name, {
              type: file.type,
              lastModified: file.lastModified
            });

            const attachment = await uploadMutation({
              file: source,
              params: {
                synchronously: true
              }
            });

            if (!attachment) {
              return;
            }

            const thumbnailUrl = attachment.thumbnails?.S?.url;
            const fileUrl = thumbnailUrl ?? attachment?.main_file?.url;

            if (fileUrl) {
              await updateUserAvatar(attachment.msfiles_uid);
            }

            toast.success('You successfully updated your avatar photo');
            onClose?.();
            resetImage();
          } catch (e) {
            handleDefaultError('An error occurred during updating the avatar photo');
          }
        },
        file.type,
        0.8
      );
    } catch (e) {
      handleDefaultError('An error occurred during updating the avatar photo');
    }
  };

  const resetImage = useCallback(() => {
    cropper?.clear();
    cropper?.reset();
    setImage(undefined);
    setFile(undefined);
    resetUpload();
  }, [cropper, resetUpload]);

  useEffect(() => {
    if (!isOpen) {
      setImage(undefined);
      setFile(undefined);
      resetUpload();
      cropper?.destroy();
    }
  }, [cropper, isOpen, resetUpload]);

  return (
    <Modal
      className={clsx(s.UpdateUserAvatarModal, className)}
      title={'Change the avatar'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Body>
        <Text className={s.UpdateUserAvatarModal__text}>Choose a new user photo. The old photo will be replaced.</Text>

        {!image && (
          <InputUpload className={s.UpdateUserAvatarModal__label} onChange={onChange}>
            <div className={s.UpdateUserAvatarModal__button}>
              <BsInbox className={s.UpdateUserAvatarModal__buttonIcon} />
              <Text className={s.UpdateUserAvatarModal__buttonTextPrimary}>Click to upload</Text>
              <Text className={s.UpdateUserAvatarModal__buttonTextSecondary} variant={TextVariant.BODY_S_NORMAL}>
                Support for a single upload. Maximum file size {DEFAULT_UPLOAD_MAX_SIZE_MB} MB.
              </Text>
            </div>
          </InputUpload>
        )}

        {image && (
          <>
            <div className={s.UpdateUserAvatarModal__cropContainer}>
              <Cropper
                style={{ maxHeight: 400, width: '100%' }}
                zoomTo={0.5}
                aspectRatio={1}
                src={image}
                viewMode={1}
                minCropBoxHeight={120}
                minCropBoxWidth={120}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={false}
                center={false}
                zoomable={false}
                preview={`.${s.UpdateUserAvatarModal__preview}`}
              />

              <Anchor component={'button'} onClick={resetImage}>
                Remove
              </Anchor>
            </div>

            <Text className={s.UpdateUserAvatarModal__previewText} variant={TextVariant.BODY_L_BOLD}>
              Preview
            </Text>

            <div className={s.UpdateUserAvatarModal__previewContainer}>
              <div className={s.UpdateUserAvatarModal__preview} />

              <UserInfo user={user} adaptive={false} />
            </div>
          </>
        )}
      </Modal.Body>

      <Modal.Footer className={s.UpdateUserAvatarModal__footer}>
        <Modal.Button variant={ButtonVariant.SECONDARY} onClick={onClose}>
          Back
        </Modal.Button>

        <Modal.Button onClick={saveImage} loading={uploadLoading || updateUserLoading}>
          Save changes
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};
