import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { BsBriefcase } from 'react-icons/bs';
import { Grid } from '../../../common/components/Grid';
import { LinkBack } from '../../../common/components/LinkBack';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import {
  ABOUT_ROUTE,
  COMPANY_ROUTE,
  CONTACT_ROUTE,
  COOKIES_ROUTE,
  LAW_ROUTE,
  PRIVACY_ROUTE,
  TERMS_ROUTE,
  TESTIMONIALS_ROUTE
} from '../../../common/utils/routes';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { HelpNavLink } from './HelpNavLink';
import s from './AboutLayout.module.scss';

export const AboutLayout: React.FC = () => {
  const location = useLocation();

  const isMobile = useIsMobile();

  return (
    <Grid className={location.pathname === ABOUT_ROUTE && isMobile ? s.AboutLayout__mobile : s.AboutLayout} cols={12}>
      <Grid.GridItem
        cols={{ xs: 2, lg: 3, xl: 2 }}
        className={location.pathname === ABOUT_ROUTE || !isMobile ? s.AboutLayout__content : ''}
      >
        {location.pathname === ABOUT_ROUTE || !isMobile ? (
          <>
            <div className={s.AboutLayout__back}>
              <LinkBack>
                <FormattedMessage id="general.back" />
              </LinkBack>
            </div>
            <div className={s.AboutLayout__badge}>
              <BsBriefcase size={16} />
              <span className={s.AboutLayout__badge_text}>
                <FormattedMessage id={'footer.aboutUs'} />
              </span>
            </div>
            <HelpNavLink to={COMPANY_ROUTE} end>
              <FormattedMessage id={'static.layout.company'} />
            </HelpNavLink>

            <HelpNavLink to={TERMS_ROUTE} end>
              <FormattedMessage id={'footer.linkTermsOfUse'} />
            </HelpNavLink>

            <HelpNavLink to={PRIVACY_ROUTE} end>
              <FormattedMessage id={'footer.linkPrivacyPolicy'} />
            </HelpNavLink>

            <HelpNavLink to={LAW_ROUTE} end>
              <FormattedMessage id={'footer.linkLaw'} />
            </HelpNavLink>

            <HelpNavLink to={COOKIES_ROUTE} end>
              <FormattedMessage id={'footer.linkCookies'} />
            </HelpNavLink>

            <HelpNavLink to={TESTIMONIALS_ROUTE} end>
              <FormattedMessage id={'footer.linkReviews'} />
            </HelpNavLink>

            <HelpNavLink to={CONTACT_ROUTE} end>
              <FormattedMessage id={'footer.linkContacts'} />
            </HelpNavLink>
          </>
        ) : (
          <>
            <LinkBack>
              <FormattedMessage id="general.back" />
            </LinkBack>

            <Space size={ESpaceSize.PIXEL_24} />
          </>
        )}
      </Grid.GridItem>

      <Grid.GridItem cols={{ xs: 2, lg: 9 }} start={{ xs: 1, lg: 4 }}>
        <Outlet />
      </Grid.GridItem>
    </Grid>
  );
};
