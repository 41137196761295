import { useUpdateEffect } from 'react-use';
import { URL_KEY_OFFSET } from 'store/urlKeys';
import { useBatchedQueryParam } from '../../hooks/queryParam/useBatchedQueryParam';

export type UsePaginationProps = {
  scrollToTopOnPageChange?: boolean;
};
//
// export type UsePaginationReturn = [
//   number,
//   {
//     setPage: (page: number) => void;
//     setPageCount: (count: number) => void;
//     useUpdatePageCount: (count?: number | null) => void;
//   }
// ];
//
// export const usePagination = ({ scrollToTopOnPageChange = true }: UsePaginationProps = {}): UsePaginationReturn => {
//   const [pageCount, setPageCount] = useState<number | null>(null);
//   const [page, setPage] = useQueryParam<number>(URL_KEY_PAGE, 1, (v) => Number(v) || 1);
//
//   useUpdateEffect(() => {
//     if (pageCount !== null && page > pageCount) {
//       setPage(1);
//     }
//   }, [page, pageCount]);
//
//   useUpdateEffect(() => {
//     if (scrollToTopOnPageChange) {
//       window.scrollTo(0, 0);
//     }
//   }, [page, scrollToTopOnPageChange]);
//
//   const useUpdatePageCount = (pageCount?: number | null) => {
//     useUpdateEffect(() => {
//       if (pageCount) {
//         setPageCount(pageCount);
//       }
//     }, [pageCount, setPageCount]);
//   };
//
//   return [page, { setPage, setPageCount, useUpdatePageCount }];
// };

export const usePagination = ({ scrollToTopOnPageChange = true }: UsePaginationProps = {}) => {
  const [offset, setOffset] = useBatchedQueryParam<number>(URL_KEY_OFFSET, {
    defaultValue: 0,
    getter: (v) => Number(v) || 0
  });

  useUpdateEffect(() => {
    if (scrollToTopOnPageChange) {
      window.scrollTo(0, 0);
    }
  }, [offset, scrollToTopOnPageChange]);

  return {
    offset,
    setOffset
  };
};
