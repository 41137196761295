import React, { useMemo } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { BsExclamationCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { NotificationType } from '../../../store/graphql';
import { CustomIcon } from '../../../common/components/CustomIcon';
import { useGeneratePathWithQuery } from '../../../common/hooks/useGeneratePathWithQuery';
import { URL_KEY_NOTIFICATION_QUERY } from '../../../store/urlKeys';
import { ratingLabelsIntlNames, ratings } from '../../../common/constants/rating';
import { NotificationsUtils } from '../../../store/notifications/NotificationsUtils';
import { FormattedDate } from '../../../common/components/CustomFormatters';
import s from './NotificationItem.module.scss';

export interface NotificationItemProps extends Pick<NotificationType, 'id' | 'createdAt' | 'text' | 'rate'> {
  className?: string;
  isImportant?: boolean;
  isRead?: boolean;
  detailsUrl?: string | null;
  showRateLabel?: boolean;
}

/**
 * todo вынести на более абстрактный слой (возможно entities)
 */
export const NotificationItem = ({
  className,
  id,
  createdAt,
  isImportant,
  isRead,
  showRateLabel,
  text,
  detailsUrl,
  rate
}: NotificationItemProps) => {
  const generatePathWithQuery = useGeneratePathWithQuery();
  const showActions = !!detailsUrl;

  const rating = useMemo(() => {
    if (!rate) return null;

    const icon = ratings.find(
      (r) => NotificationsUtils.ratingValueToNotificationRateMap[r.variant] === rate
    )?.defaultIcon;
    const messageId = ratingLabelsIntlNames[NotificationsUtils.notificationRateToRatingValueMap[rate]];

    if (!icon || !messageId) return null;

    return {
      rate,
      icon,
      messageId
    };
  }, [rate]);

  return (
    <Link
      className={clsx(s.NotificationItem__linkWrapper, !detailsUrl && s.NotificationItem__linkWrapper_disabled)}
      to={generatePathWithQuery(detailsUrl || '', {
        [URL_KEY_NOTIFICATION_QUERY]: id.toString()
      })}
    >
      <div className={clsx(s.NotificationItem, className)}>
        <div
          className={clsx(s.NotificationItem__grid, {
            [s.NotificationItem__grid_noActions]: !showActions
          })}
        >
          <div className={s.NotificationItem__head}>
            <div className={s.NotificationItem__date}>
              <FormattedDate value={new Date(createdAt)} />
            </div>
            {isImportant && <CustomIcon className={s.NotificationItem__priorityIcon} icon={BsExclamationCircle} />}
          </div>
          <div className={s.NotificationItem__content}>
            <div
              className={clsx(s.NotificationItem__text, {
                [s.NotificationItem__text_unread]: !isRead
              })}
            >
              {text}

              {showRateLabel && rating && (
                <div
                  className={clsx(s.NotificationItem__rate, s[`NotificationItem__rate_${rating.rate.toLowerCase()}`])}
                >
                  <CustomIcon className={s.NotificationItem__rateIcon} icon={rating.icon} />
                  <FormattedMessage id={rating.messageId} />
                </div>
              )}
            </div>
          </div>
          {showActions && (
            <div className={s.NotificationItem__actions}>
              <FormattedMessage id={'notification.viewDetailsLink'} />
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};
