import { useLayoutEffect, useState } from 'react';
import QRCode from 'qrcode';
import { Heading, HeadingSize } from '../../../../../../../common/components/Heading';
import s from './PickupRequestParcelQR.module.scss';

export type PickupRequestParcelQRProps = {
  decodedQr: string;
};
export const PickupRequestParcelQR = (props: PickupRequestParcelQRProps) => {
  const { decodedQr } = props;

  const [qrDataURL, setQrDataURL] = useState<string>('');

  useLayoutEffect(() => {
    if (decodedQr) {
      QRCode.toDataURL(decodedQr, { margin: 0, scale: 10 }).then((data) => setQrDataURL(data));
    }
  }, [decodedQr]);

  return (
    <div className={s.PickupRequestParcelQR}>
      <div className={s.PickupRequestParcelQR__info}>
        <span>Shipment Reference Number</span>
        <Heading size={HeadingSize.H5}>{decodedQr}</Heading>
      </div>
      <img src={qrDataURL} className={s.PickupRequestParcelQR__qr} alt={decodedQr} />
    </div>
  );
};
