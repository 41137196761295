import React, { useCallback } from 'react';
import { useUpdateEffect } from 'react-use';
import {
  NotificationType,
  useMarkAllOrdinaryNotificationsReadMutation,
  useMarkNotificationReadMutation,
  useNotificationsCountQuery
} from '../graphql';
import { handleDefaultErrorLog } from '../../common/utils/handleDefaultError';
import { clearCache, useAuth } from '../../app/providers/auth-apollo';
import { URL_KEY_NOTIFICATION_QUERY } from '../urlKeys';
import { useBatchedQueryParam } from '../../common/hooks/queryParam/useBatchedQueryParam';
import { NotificationsContext } from './NotificationsContext';

const markAsReadMutationOptions = {
  refetchQueries: ['NotificationsCount', 'Notifications'],
  update: clearCache(['getNotificationCount', 'getNotifications'])
};

export const NotificationsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  const [notificationId, setNotificationId] = useBatchedQueryParam<string>(URL_KEY_NOTIFICATION_QUERY);

  const { data: unreadNotificationsCountData, loading: unreadNotificationsCountLoading } = useNotificationsCountQuery({
    variables: {
      input: {
        read: false
      }
    },
    skip: !isAuthenticated
  });
  const unreadNotificationsCount = unreadNotificationsCountData?.count || 0;

  const { data: importantUnreadNotificationsCountData, loading: importantUnreadNotificationsCountLoading } =
    useNotificationsCountQuery({
      variables: {
        input: {
          priority: true,
          read: false
        }
      },
      skip: !isAuthenticated
    });
  const importantUnreadNotificationsCount = importantUnreadNotificationsCountData?.count || 0;

  const [markAsReadMutation, { loading: markAsReadLoading }] =
    useMarkNotificationReadMutation(markAsReadMutationOptions);

  const [markAllOrdinaryReadMutation, { loading: readingAllOrdinaryNotifications }] =
    useMarkAllOrdinaryNotificationsReadMutation(markAsReadMutationOptions);

  const markAsRead = useCallback(
    async (notificationId: NotificationType['id']) => {
      try {
        const result = await markAsReadMutation({
          variables: {
            notificationId
          }
        });

        if (!result?.data?.markedRead) {
          throw new Error('MarkNotificationReadMutation error');
        }

        return true;
      } catch (e) {
        /**
         * Not showing toast with error so as not to confuse the user
         */
        handleDefaultErrorLog(e);
        return false;
      }
    },
    [markAsReadMutation]
  );

  const markAllOrdinaryAsRead = useCallback(async () => {
    try {
      const result = await markAllOrdinaryReadMutation();
      if (!result?.data?.markedRead) {
        throw new Error('MarkNotificationReadMutation error');
      }
      return true;
    } catch (e) {
      handleDefaultErrorLog(e);
      return false;
    }
  }, [markAllOrdinaryReadMutation]);

  /**
   * 1. Trying to mark notification as read after visiting the notification related route
   * 2. Clearing notificationId query-string key if notification marked as read
   */
  useUpdateEffect(() => {
    if (notificationId && !markAsReadLoading) {
      if (isAuthenticated) {
        markAsRead(+notificationId).then(() => {
          setNotificationId('');
        });
      } else {
        setNotificationId('');
      }
    }
  }, [isAuthenticated, markAsRead, markAsReadLoading, notificationId, setNotificationId]);

  const countLoading = unreadNotificationsCountLoading || importantUnreadNotificationsCountLoading;

  return (
    <NotificationsContext.Provider
      value={{
        unreadCount: unreadNotificationsCount,
        importantUnreadCount: importantUnreadNotificationsCount,
        countLoading,
        markAsRead,
        markAllOrdinaryAsRead
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
