import { CommonObject } from '../../common/utils/helperTypes';
import {
  GoogleTagCustomEventFnType,
  GoogleTagDefaultEventFnType,
  GoogleTagEvents,
  GoogleTagSendCustomEventFnType,
  GoogleTagSendDefaultEventFnType
} from './types';

//@ts-ignore: gtag-manager
const gtag: GoogleTagDefaultEventFnType = window.gtag || (() => {});

const gtagcustom: GoogleTagCustomEventFnType = (props) => {
  //@ts-ignore: gtag-datalayer
  const dataLayer = window.dataLayer || [];
  dataLayer.push(props);
};

export const googleTagSendDefaultEvent: GoogleTagSendDefaultEventFnType = (event, props) => {
  const argsArray = ['event', event] as [event: string, eventName: GoogleTagEvents, props?: CommonObject];

  if (props) {
    argsArray.push(props);
  }

  gtag(...argsArray);
};

export const googleTagSendCustomEvent: GoogleTagSendCustomEventFnType = (event, props) => {
  gtagcustom({
    event,
    ...props
  });
};
