import React from 'react';
import { ReactFCC } from 'common/utils/helperTypes';
import { Head } from '../../common/components/Head';
import { CatalogPageContent } from './components';

export const CatalogPage: ReactFCC = () => {
  return (
    <>
      <Head title={'Catalog'} />

      <CatalogPageContent />
    </>
  );
};
