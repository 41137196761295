import { useCallback, useMemo } from 'react';
import {
  FavoritesDocument,
  Product,
  useAddProductToFavoritesMutation,
  useRemoveProductFromFavoritesMutation
} from '../../../../store/graphql';

export interface UseToggleFavoriteProps {
  productId: Product['id'];
  onError?: (e: any) => void;
  onCompleted?: (value: boolean) => void;
  onSettled?: () => void;
}

export const useToggleFavorite = ({ productId, onError, onCompleted, onSettled }: UseToggleFavoriteProps) => {
  const [addMutation, { loading: addLoading }] = useAddProductToFavoritesMutation();
  const [removeMutation, { loading: removeLoading }] = useRemoveProductFromFavoritesMutation();

  const options = useMemo(
    () => ({
      variables: {
        productId
      }
    }),
    [productId]
  );

  const cacheObject = useMemo(
    () => ({
      __typename: 'Product',
      id: productId
    }),
    [productId]
  );

  const toggleFavorite = useCallback(
    async (value: boolean) => {
      try {
        if (value) {
          await addMutation({
            ...options,
            update(cache, { data }) {
              cache.modify({
                id: cache.identify(cacheObject),
                fields: {
                  favorite: (cachedValue) => (data?.result ? true : cachedValue)
                }
              });
            },
            refetchQueries: [FavoritesDocument],
            awaitRefetchQueries: true
          });
        } else {
          await removeMutation({
            ...options,
            update(cache, { data }) {
              cache.modify({
                id: cache.identify(cacheObject),
                fields: {
                  favorite: (cachedValue) => (data?.result ? false : cachedValue)
                }
              });
            },
            refetchQueries: [FavoritesDocument],
            awaitRefetchQueries: true
          });
        }

        onCompleted?.(value);
      } catch (e) {
        onError?.(e);
      } finally {
        onSettled?.();
      }
    },
    [addMutation, cacheObject, onCompleted, onError, onSettled, options, removeMutation]
  );

  return {
    toggleFavorite,
    loading: addLoading || removeLoading
  };
};
