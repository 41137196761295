import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Tooltip, TooltipContainer } from '../../../../../common/components/Tooltip';
import { useUi } from '../../../../../store/ui';
import { handleDefaultError } from '../../../../../common/utils/handleDefaultError';
import {
  CartDocument,
  FavoritesDocument,
  Product,
  useMoveProductToFavoritesMutation
} from '../../../../../store/graphql';
import { useAuth } from '../../../../../app/providers/auth-apollo';
import s from './MoveToFavoritesButton.module.scss';

export interface MoveToFavoritesButtonProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * ID товара из API
   */
  productId: Product['id'];
}

export const MoveToFavoritesButton: ReactFCC<MoveToFavoritesButtonProps> = (props) => {
  const { className, productId } = props;

  const { isAuthenticated } = useAuth();
  const { toggleAuthModal } = useUi();

  const [moveMutation] = useMoveProductToFavoritesMutation();

  const options = useMemo(
    () => ({
      variables: {
        productId
      }
    }),
    [productId]
  );

  const cacheObject = useMemo(
    () => ({
      __typename: 'Product',
      id: productId
    }),
    [productId]
  );

  const onClick = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();

      if (!isAuthenticated) {
        toggleAuthModal(true);
        return;
      }

      try {
        await moveMutation({
          ...options,
          update(cache, { data }) {
            cache.modify({
              id: cache.identify(cacheObject),
              fields: {
                favorite: (cachedValue) => (data?.result ? true : cachedValue)
              }
            });
          },
          refetchQueries: [FavoritesDocument, CartDocument],
          awaitRefetchQueries: true
        });

        toast.success('The product was moved to favorites');
      } catch (e) {
        handleDefaultError('Something went wrong', e);
      }
    },
    [cacheObject, isAuthenticated, moveMutation, options, toggleAuthModal]
  );

  return (
    <button className={clsx(s.MoveToFavoritesButton, className)} onClick={onClick}>
      <Tooltip text={'Move to favorites'} delay={[500, 0]}>
        <TooltipContainer>
          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              className={s.MoveToFavoritesButton__inner}
              d="M6.28498 1.84717L7.00176 2.58398L7.71854 1.84717C9.12534 0.401069 11.7246 0.891661 12.665 2.72817C13.1021 3.58184 13.2113 4.83126 12.3901 6.45132C11.6038 8.00267 9.96737 9.87256 7.00174 11.9187C5.60471 10.9549 4.50266 10.0302 3.64548 9.15948C3.54583 9.05826 3.4103 9.00001 3.26826 9.00001C2.82465 9.00001 2.59159 9.51269 2.902 9.8296C3.93478 10.884 5.2817 11.9886 7.00176 13.1251C20.4175 4.25989 11.1333 -2.66012 7.15593 0.999891C7.1036 1.04805 7.05219 1.09803 7.00176 1.14987C6.95134 1.09804 6.89993 1.04805 6.8476 0.999899C4.44734 -1.20887 0.114302 0.435883 0.0040967 4.03577C-0.00388249 4.29641 0.215506 4.50001 0.47627 4.50001C0.766582 4.50001 0.990881 4.25069 1.0087 3.96092C1.0379 3.48613 1.16124 3.07476 1.33855 2.72848C2.27902 0.891783 4.87826 0.401145 6.28498 1.84717Z"
              fill="#E35D6A"
            />
            <path
              className={s.MoveToFavoritesButton__border}
              d="M6 4.5L8 6.5001M8 6.5001L6 8.5M8 6.5001H1"
              stroke="#E35D6A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </TooltipContainer>
      </Tooltip>
    </button>
  );
};
