import React from 'react';
import clsx from 'clsx';
import { Modal as ModalBootstrap } from 'react-bootstrap';
import { useWindowSize } from 'common/hooks/useWindowSize';
import { ReactFCC } from 'common/utils/helperTypes';
import s from '../Modal.module.scss';

export interface ModalFooterProps {
  className?: string;
}

export const ModalFooter: ReactFCC<ModalFooterProps> = ({ children, className }) => {
  const { isMobile } = useWindowSize();

  return isMobile ? (
    <div className={clsx(s.ModalMobile__footer, className)}>{children}</div>
  ) : (
    <ModalBootstrap.Footer className={clsx(className)}>{children}</ModalBootstrap.Footer>
  );
};
