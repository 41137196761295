import { InferType, object, string } from 'yup';
import { validateErrors } from 'common/utils/validate';

const titleMaxLength = 50;

const validateObject = {
  title: string().max(titleMaxLength, `Must be maximum ${titleMaxLength} characters long`).optional(),
  account_type: string().required(validateErrors.required),
  organization_name: string().required(validateErrors.required),
  organization_code: string().required(validateErrors.required),
  organization_hiragana: string().required(validateErrors.required),
  branch_name: string().optional(),
  branch_code: string().optional(),
  branch_hiragana: string().optional(),
  // todo валидация?
  account_number: string().required(validateErrors.required)
};

export const BankAccountSchema = object(validateObject);

export type BankAccountValues = InferType<typeof BankAccountSchema>;

export const BankAccountSchemaKeys = Object.fromEntries(Object.keys(validateObject).map((key) => [key, key])) as Record<
  keyof BankAccountValues,
  string
>;
