import React from 'react';
import { ApolloProvider, from, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { AuthContext, IAuthContext } from '../auth/AuthContext';
import { apiUrl } from '../../../../../config';
import { LOCAL_STORAGE_LANGUAGE } from '../../../../../store/storageKeys';
import { promiseToObservable } from './utils/promiseToObservable';
import { apolloClient } from './apolloClient';

export class ApolloClientProvider extends React.Component<{ children: React.ReactNode }> {
  static contextType = AuthContext;
  context!: IAuthContext;

  apolloClient = apolloClient;

  constructor(props: { children: React.ReactNode }, context: IAuthContext) {
    super(props, context);

    const httpLink = new HttpLink({ uri: apiUrl /*, preserveHeaderCase: true */ });
    const authLink = setContext(async (_, { headers }) => {
      const accessToken = await context.getToken();
      return { headers: { ...headers, ...(accessToken && { Authorization: `Bearer ${accessToken}` }) } };
    });
    const errorLink = onError(({ graphQLErrors, operation, forward }: ErrorResponse): any => {
      if (
        graphQLErrors &&
        graphQLErrors.length > 0 &&
        graphQLErrors.some(
          (e) =>
            (e.extensions.exception as any)?.name === 'JsonWebTokenError' || e.extensions.code === 'UNAUTHENTICATED'
        )
      ) {
        return promiseToObservable(context.getToken({ force: true })).flatMap(() => forward(operation));
      }
    });
    const languageLink = setContext((_, { headers }) => {
      const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

      return {
        headers: {
          ...headers,
          'X-SENDICO-Language': language
        }
      };
    });

    apolloClient.setLink(from([errorLink, authLink, languageLink, httpLink]));
  }

  render() {
    return <ApolloProvider client={this.apolloClient}>{this.props.children}</ApolloProvider>;
  }
}
