import { InferType, number, object } from 'yup';
import { validateErrors } from 'common/utils/validate';

export const amountValidation = {
  /**
   * т.к. комиссия на вывод 200 йен
   */
  min: 201,
  max: 9999999,
  unit: '¥'
};

const validateObject = {
  amount: number()
    .positive(validateErrors.positiveNumber)
    .integer('Should be integer')
    .min(
      amountValidation.min,
      ({ min }) => `Can't be less than ${amountValidation.unit}${new Intl.NumberFormat().format(min)}`
    )
    .max(
      amountValidation.max,
      ({ max }) => `Can't be greater than ${amountValidation.unit}${new Intl.NumberFormat().format(max)}`
    )
    .defined(validateErrors.required),
  bankAccountId: number().defined(validateErrors.required)
};

export const WithdrawSchema = object(validateObject);

export type WithdrawValues = InferType<typeof WithdrawSchema>;

export const WithdrawSchemaKeys = Object.fromEntries(Object.keys(validateObject).map((key) => [key, key])) as Record<
  keyof WithdrawValues,
  string
>;
