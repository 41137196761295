import { BsLockFill, BsPencil, BsPencilFill, BsTrash } from 'react-icons/bs';
import React, { useCallback, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { IconType } from 'react-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDeleteUserAddressMutation, useUpdateUserAddressMutation } from '../../../../store/graphql';
import { Heading, HeadingSize } from '../../../../common/components/Heading';
import { ReactFCC } from '../../../../common/utils/helperTypes';
import { Badge } from '../../../../common/components/Badge';
import { Divider } from '../../../../common/components/Divider/Divider';
import { ESpaceSize } from '../../../../common/components/Space/Space';
import { clearCache } from '../../../../app/providers/auth-apollo';
import { handleDefaultError } from '../../../../common/utils/handleDefaultError';
import { IconButton } from '../../../../common/components/Button';
import { ContextMenu } from '../../../../common/components/ContextMenu';
import { Text } from '../../../../common/components/Text';
import { UserAddress } from '../../types';
import s from './AddressCard.module.scss';

export interface AddressCardProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Объектов адреса, полученный с api
   */
  address: UserAddress;
  /**
   * Обработчик нажатия кнопки редактирования
   */
  onClickEdit?: () => void;
  /**
   * Состояние интерактивности, визуальное сопровождение при наведении на карточку
   */
  interactive?: boolean;
  /**
   * Состояние выбранности
   */
  selected?: boolean;
  /**
   * Флаг отключения множественных опций редактирования
   */
  disableRichEdit?: boolean;
  /**
   * Флаг отключения всех опций редактирования
   */
  disableEdit?: boolean;
  /**
   * Флаг для дефолтного адреса склада арисоры
   */
  defaultWarehouse?: boolean;
  /**
   * Обработчик нажатия на карточку (работает только при включенном interactive)
   */
  onClick?: () => void;
}

interface ContextMenuItem {
  title: string;
  icon?: IconType;
  onClick?: (e: React.MouseEvent) => void;
  danger?: boolean;
}

const mutationOptions = {
  refetchQueries: ['User'],
  update: clearCache(['getUserById'])
};

export const AddressCard: ReactFCC<AddressCardProps> = (props) => {
  const {
    className,
    address: addressData,
    onClickEdit,
    interactive,
    selected,
    onClick,
    disableRichEdit,
    defaultWarehouse,
    disableEdit
  } = props;

  const { title, country, city, address1, fullName, zipCode, defaultBilling, defaultShipping, sellerAddress, invalid } =
    addressData;

  const intl = useIntl();

  const headerNodeRef = useRef<HTMLDivElement>(null);

  const [updateUserAddressMutation, { loading: updateUserAddressLoading }] =
    useUpdateUserAddressMutation(mutationOptions);

  const [deleteUserAddressMutation, { loading: deleteUserAddressLoading }] =
    useDeleteUserAddressMutation(mutationOptions);

  const updateAddressDefault = useCallback(
    async (data: { defaultBilling?: boolean; defaultShipping?: boolean }) => {
      try {
        await updateUserAddressMutation({
          variables: {
            input: {
              id: addressData.id,
              ...data
            }
          }
        });
      } catch (e) {
        handleDefaultError('Sorry, an error occurred while updating the address', e);
      }
    },
    [addressData, updateUserAddressMutation]
  );

  const deleteAddress = useCallback(async () => {
    try {
      await deleteUserAddressMutation({
        variables: {
          id: addressData.id
        }
      });
    } catch (e) {
      handleDefaultError('Sorry, an error occurred while deleting the address', e);
    }
  }, [addressData.id, deleteUserAddressMutation]);

  const contextMenuItems: ContextMenuItem[] = useMemo(
    () =>
      [
        !invalid &&
          !defaultShipping && {
            title: intl.formatMessage({ id: 'address.setDefaultShipping' }),
            onClick: () => updateAddressDefault({ defaultShipping: true })
          },
        !invalid &&
          !defaultBilling && {
            title: intl.formatMessage({ id: 'address.setDefaultBilling' }),
            onClick: () => updateAddressDefault({ defaultBilling: true })
          },
        {
          title: intl.formatMessage({ id: 'general.edit' }),
          icon: BsPencil,
          onClick: onClickEdit
        },
        !defaultShipping &&
          !defaultBilling && {
            title: intl.formatMessage({ id: 'general.delete' }),
            icon: BsTrash,
            danger: true,
            onClick: deleteAddress
          }
      ].filter(Boolean) as ContextMenuItem[],
    [defaultBilling, defaultShipping, deleteAddress, intl, invalid, onClickEdit, updateAddressDefault]
  );

  const editOnly = defaultBilling && defaultShipping;
  const loading = updateUserAddressLoading || deleteUserAddressLoading;

  return (
    <div
      className={clsx(s.AddressCard, className, {
        [s.AddressCard_interactive]: interactive,
        [s.AddressCard_selected]: selected
      })}
      onClick={interactive ? onClick : undefined}
    >
      <div className={s.AddressCard__header} ref={headerNodeRef}>
        {sellerAddress && (
          <Badge className={clsx(s.AddressCard__badge, s.AddressCard__badge_seller)}>
            <span>
              <FormattedMessage id={'address.badge.seller'} />
            </span>
            <BsLockFill />
          </Badge>
        )}

        {defaultBilling && (
          <Badge className={s.AddressCard__badge}>
            <FormattedMessage id={'address.badge.billing'} />
          </Badge>
        )}

        {defaultWarehouse && <Badge className={s.AddressCard__badge}>Arisora Warehouse</Badge>}

        {defaultShipping && (
          <Badge className={s.AddressCard__badge}>
            <FormattedMessage id={'address.badge.shipping'} />
          </Badge>
        )}

        {invalid && (
          <div className={s.AddressCard__badgeContainer}>
            <Badge className={clsx(s.AddressCard__badge, s.AddressCard__badge_invalid)}>Invalid</Badge>

            <Text
              tooltip={
                'Address verification error. We are working on fixing this issue. Also, you can edit this address or consult with our customer service.'
              }
            />
          </div>
        )}

        {!disableEdit &&
          !defaultWarehouse &&
          (editOnly || disableRichEdit ? (
            <IconButton
              className={s.AddressCard__button}
              icon={BsPencilFill}
              onClick={(e) => {
                e.stopPropagation();
                onClickEdit?.();
              }}
              loading={loading}
            />
          ) : (
            <ContextMenu className={s.AddressCard__button} items={contextMenuItems} loading={loading} />
          ))}
      </div>

      <Divider space={{ top: ESpaceSize.PIXEL_4, bottom: ESpaceSize.PIXEL_8 }} />

      <Heading className={s.AddressCard__title} size={HeadingSize.H6}>
        {title}
      </Heading>

      <span className={s.AddressCard__text}>{address1}</span>

      <span className={s.AddressCard__caption}>{fullName}</span>

      <div className={s.AddressCard__row}>
        <span className={s.AddressCard__caption}>
          {city}, {country}
        </span>

        <span className={s.AddressCard__caption}>{zipCode}</span>
      </div>
    </div>
  );
};
