import React from 'react';
import clsx from 'clsx';
import { Badge as BootstrapBadge, BadgeProps as BootstrapBadgeProps } from 'react-bootstrap';
import s from './Badge.module.scss';

export enum BadgeVariant {
  default = 'default',
  primary = 'primary',
  gray = 'gray',
  light = 'light',
  success = 'success',
  warning = 'warning',
  error = 'error'
}

export interface BadgeProps extends Pick<BootstrapBadgeProps, 'className' | 'onClick' | 'children'> {
  variant?: BadgeVariant;
}

export const Badge = ({ className, variant = BadgeVariant.default, ...props }: BadgeProps) => {
  return <BootstrapBadge className={clsx(s.Badge, s[`Badge_${variant}`], className)} bg={''} {...props} />;
};
