import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { BsCardImage, BsTrash, BsXLg } from 'react-icons/bs';
import { FormikErrors, useFormik } from 'formik';
import { useNavigate } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { tryNumber } from '@proscom/ui-utils';
import { useUpdateEffect } from 'react-use';
import { Form } from 'react-bootstrap';
import { ReactFCC } from 'common/utils/helperTypes';
import { Heading, HeadingSize } from 'common/components/Heading/Heading';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { BrandInput, FormInput, FormLabel, FormSelect, InputUpload } from 'common/components/inputs';
import { EBreakpoints, useBreakpoint } from 'common/hooks/useBreakpoint';
import { Grid, GridGap } from 'common/components/Grid/Grid';
import { Button, ButtonFit, ButtonVariant, IconButtonVariant } from 'common/components/Button';
import {
  DeliverySpeedDaysEnum,
  DraftSortDirection,
  KuronekoPacketEnum,
  LocalAttachmentType,
  ProductConditionEnum,
  ProductInput,
  ProductStatusEnum,
  ProductUpdateInput,
  useCreateProductMutation,
  useRemoveProductMutation,
  UserRoles,
  useSellerProductDraftsQuery,
  useUpdateProductMutation,
  useUpsertProductDraftMutation
} from 'store/graphql';
import { Anchor, AnchorVariant } from 'common/components/Anchor/Anchor';
import { Modal } from 'common/components/Modal';
import { useToggle } from 'common/hooks/useToggle';
import { handleDefaultError } from 'common/utils/handleDefaultError';
import { FormScrollToError } from 'common/components/form/FormScrollToError/FormScrollToError';
import { clearCache } from 'app/providers/auth-apollo';
import { getGraphqlErrorCode, getGraphqlErrorMessage } from 'common/utils/graphqlErrors';
import { formatMoney } from 'common/utils/format';
import { ContextMenu, ContextMenuItem } from 'common/components/ContextMenu';
import { useUser } from '../../../../entities/user';
import { DRAFTS_ROUTE, SHOWCASE_ROUTE } from '../../../../common/utils/routes';
import { useUploadDraftPhotos } from '../../drafts/hooks/useUploadDraftImage/useUploadDraftPhotos';
import { defaultProductInputDelay } from '../../../../common/constants/product';
import { DraftsModal } from '../../../../features/product/drafts';
import { Loader, LoaderBox, LoaderSize } from '../../../../common/components/Loader';
import { FileAction, ImageUploadExt } from '../../../../lib/msfiles-client';
import { useUpload } from '../../../../lib/file-uploader';
import { useYamatoFee } from '../../../../features/order/yamatoFee/hooks/useYamatoFee';
import { YuPacketBlock } from '../../../../features/order/yuPacket/YuPacketBlock';
import {
  CreateProductSchema,
  CreateProductSchemaKeys,
  descriptionMaxLength,
  dimensionsMaxSum,
  productAttributeLimits,
  productNameMaxLength,
  TCreateProductValues
} from './schema';
import { useFormData } from './useFormData';
import s from './CreateProductPageContent.module.scss';

export type TCreateProductPageContent = {
  id?: string;
  draftId?: string;
  relistId?: string;
  initialValues?: Partial<TCreateProductValues>;
  initialImages?: Array<Pick<LocalAttachmentType, 'msfiles_uid'> & { main?: boolean; url?: string | null }>;
  initialDraftImages?: Array<Pick<LocalAttachmentType, 'msfiles_uid'> & { main?: boolean; url?: string | null }>;
};

const maxProductImagesCount = 10;

const defaultValues = {
  categoryId: '',
  subCategoryId: '',
  subSubCategoryId: '',
  name: '',
  description: '',
  brandId: '',
  colorId: '',
  condition: '',
  status: ProductStatusEnum.ForSale,
  specificValues: [],
  price: '',
  deliverySpeedDays: DeliverySpeedDaysEnum.OneOrTwo,
  boxWidth: '',
  boxHeight: '',
  boxLength: '',
  weight: '',
  dimensions: '',
  mainPhotoIndex: 0
};

export const CreateProductPageContent: ReactFCC<TCreateProductPageContent> = (props) => {
  const { id, draftId, relistId, initialImages, initialDraftImages } = props;

  const initialValues = useMemo(
    () => ({
      ...defaultValues,
      ...props.initialValues
    }),
    [props]
  );

  const isEdit = !!props.initialValues && !draftId && !relistId;

  const isDraft = !!props.initialValues && !!draftId;

  const isRelist = !!props.initialValues && !!relistId;

  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);

  const intl = useIntl();

  const imagesRef = useRef<HTMLDivElement | null>(null);

  const { data: draftsQuery, loading: draftsLoading } = useSellerProductDraftsQuery({
    skip: !!id || !!draftId,
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        sort: {
          createdAt: DraftSortDirection.Desc
        }
      }
    }
  });

  const drafts = draftsQuery?.user.seller?.drafts.entries;

  const [createdDraftId, setCreatedDraftId] = useState<number | null>(draftId ? +draftId : null);

  const [isDeleteModal, { set: openDeleteModal, unset: closeDeleteModal }] = useToggle();
  const [draftsModalOpen, { set: openDraftsModal, unset: closeDraftsModal }] = useToggle();

  const [imageError, setImageError] = useState(false);

  const [uploadInProgress, setUploadInProgress] = useState(false);

  const [productDraftImages, { pushToDraftImages, removeDraftImage, removeProductDraft }, uploadDraftLoading] =
    useUploadDraftPhotos({
      initialItems: initialDraftImages?.map((image) => ({
        attachmentUid: image.msfiles_uid,
        url: image.url
      }))
    });

  const [uploadMutation] = useUpload({
    allowedActions: [FileAction.UploadImage],
    params: {
      [FileAction.UploadImage]: {
        synchronously: true,
        ext: ImageUploadExt.jpg
      }
    }
  });

  const [productImages, setProductImages] = useState<Array<{ attachment_uid: string; main: boolean; url: string }>>(
    () => {
      if (!initialImages) return [];

      return initialImages.map((image, index) => {
        const main = image.main !== undefined ? image.main : index === 0;
        return {
          attachment_uid: image.msfiles_uid,
          main,
          url: image.url ?? ''
        };
      });
    }
  );

  const navigate = useNavigate();

  const { user } = useUser();

  const seller = user?.seller;

  const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setUploadInProgress(true);
      const files = Array.from(e.target.files);
      const promises = files
        .slice(0, maxProductImagesCount - (productImages?.length ?? 0))
        .map(async (fileSource, index) => {
          try {
            const attachment = await uploadMutation({
              file: fileSource,
              params: { synchronously: true, ext: ImageUploadExt.jpg }
            });
            if (attachment) {
              setProductImages((images) => {
                const main = images.length === 0;
                return [
                  ...images,
                  { attachment_uid: attachment.msfiles_uid, main, url: attachment.thumbnails?.S?.url ?? '' }
                ];
              });

              if (!isEdit && !isRelist) {
                pushToDraftImages({ attachmentUid: attachment.msfiles_uid });
              }
            }
          } catch (e: any) {
            const graphqlErrorMessage = getGraphqlErrorMessage(e);
            handleDefaultError(graphqlErrorMessage || e.message || 'Error occurred while uploading image', e);
          }
        });
      Promise.all(promises).then(() => {
        e.target.value = '';
        setUploadInProgress(false);
      });
    }
  };

  const createProductMutationOptions = {
    refetchQueries: ['ShowcaseProducts', 'HomePage'],
    update: clearCache(['getSellerProducts', 'products'])
  };

  const [createProductMutation, { loading: createProductLoading }] =
    useCreateProductMutation(createProductMutationOptions);

  const [updateProductMutation, { loading: updateProductLoading }] =
    useUpdateProductMutation(createProductMutationOptions);

  const [removeProductMutation, { loading: removeProductLoading }] =
    useRemoveProductMutation(createProductMutationOptions);

  const [upsertDraftMutation] = useUpsertProductDraftMutation({
    update: clearCache(['user'])
  });

  const handleDraftCreation = useCallback(
    async (fieldName: string, value: any) => {
      if ((value || !isNaN(value)) && !isEdit && !isRelist) {
        if (!createdDraftId) {
          const draft = await upsertDraftMutation({
            variables: {
              input: {
                [fieldName]: value
              }
            }
          });
          if (draft.data) {
            return setCreatedDraftId(draft.data.draft.id);
          }
        }
        await upsertDraftMutation({
          variables: {
            input: {
              id: createdDraftId,
              [fieldName]: value
            }
          }
        });
      }
    },
    [createdDraftId, isEdit, isRelist, upsertDraftMutation]
  );

  const formik = useFormik<TCreateProductValues>({
    initialValues: initialValues as TCreateProductValues,
    validationSchema: CreateProductSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,
    validate: (values) => {
      const errors: FormikErrors<TCreateProductValues> = {};

      const dimensions = tryNumber(values.dimensions, undefined);

      if (dimensions && dimensions > dimensionsMaxSum) {
        errors.dimensions = 'The sum of 3 dimensions must be less than 260cm';
      }

      return errors;
    },
    onSubmit: async (values, helpers) => {
      setImageError(false);
      if (!seller) {
        return;
      }

      if (productImages.length === 0) {
        setImageError(true);
        imagesRef.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      }

      delete values.dimensions;
      delete values.mainPhotoIndex;

      const input: ProductInput = {
        ...values,
        photos: productImages.map((image) => ({ attachment_uid: image.attachment_uid, main: image.main })),
        // mainPhotoIndex: values.mainPhotoIndex || 0,
        price: tryNumber(values.price, 0),
        boxHeight: tryNumber(values.boxHeight, undefined) ?? undefined,
        boxLength: tryNumber(values.boxLength, undefined) ?? undefined,
        boxWidth: tryNumber(values.boxWidth, undefined) ?? undefined,
        weight: tryNumber(values.weight, undefined) ?? undefined,
        condition: values.condition as ProductConditionEnum,
        status: values.status as ProductStatusEnum,
        deliverySpeedDays: values.deliverySpeedDays as DeliverySpeedDaysEnum,
        specificValues: values.specificValues.map((value) => ({
          id: value.id,
          attributeId: value.attributeId,
          variantId: value.variantId
        })),
        brandId: values.brandId || undefined,
        colorId: values.colorId || undefined,
        kuroneko: (kuronekoPossibleType as unknown as KuronekoPacketEnum) || undefined,
        warehouseNumber: tryNumber(values.warehouseNumber, undefined) ?? undefined
      };

      try {
        if (isEdit && id) {
          const updateInput: ProductUpdateInput = {
            id,
            ...input
          };

          await updateProductMutation({
            variables: {
              input: updateInput
            }
          });

          toast.success('Product successfully updated');
        } else {
          await createProductMutation({
            variables: {
              input
            }
          });
          if (createdDraftId) {
            await removeProductDraft(createdDraftId);
          }

          toast.success('Product successfully created');
        }

        helpers.resetForm();

        navigate(SHOWCASE_ROUTE);
      } catch (e) {
        const errCode = getGraphqlErrorCode(e);
        switch (errCode) {
          case 'WH_NUMBER_EXISTS':
            toast.error('This warehouse number already exists. Please check carefully');
            break;
          default:
            handleDefaultError('Sorry, an error occurred while creating product', e);
        }
      }
    }
  });

  const {
    categories,
    categoriesLoading,
    subCategories,
    subCategoriesLoading,
    subSubCategories,
    subSubCategoriesLoading,
    colors,
    colorsLoading,
    conditions,
    deliverySpeedDaysVariants,
    specificAttributes,
    specificAttributesLoading
  } = useFormData({
    formik
  });

  const setSpecificValue = (attributeId: string, variantId: string) => {
    const specificValues = formik.values.specificValues;

    if (variantId) {
      const currentValue = specificValues.find((value) => value.attributeId === attributeId);

      const updatedValue = [
        ...specificValues.filter((value) => value.attributeId !== attributeId),
        {
          ...currentValue,
          attributeId,
          variantId
        }
      ];

      formik.setFieldValue('specificValues', updatedValue);
    } else {
      const updatedValue = specificValues.filter((value) => value.attributeId !== attributeId);

      formik.setFieldValue('specificValues', updatedValue);
    }
  };

  const removeProduct = async () => {
    if (!id) {
      return;
    }

    try {
      await removeProductMutation({
        variables: {
          productId: id
        }
      });

      formik.resetForm();

      navigate(SHOWCASE_ROUTE);

      toast.success('Product successfully removed');
    } catch (e) {
      handleDefaultError('Sorry, an error occurred while removing product', e);
    }
  };

  useUpdateEffect(() => {
    formik.setFieldValue(CreateProductSchemaKeys.subCategoryId, defaultValues.subCategoryId);
    formik.setFieldValue(CreateProductSchemaKeys.subSubCategoryId, defaultValues.subSubCategoryId);
  }, [formik.values.categoryId]);

  useUpdateEffect(() => {
    formik.setFieldValue(CreateProductSchemaKeys.subSubCategoryId, defaultValues.subSubCategoryId);
  }, [formik.values.subCategoryId]);

  useUpdateEffect(() => {
    const width = tryNumber(formik.values.boxWidth, undefined);
    const height = tryNumber(formik.values.boxHeight, undefined);
    const length = tryNumber(formik.values.boxLength, undefined);

    if (width && height && length) {
      formik.setFieldValue(CreateProductSchemaKeys.dimensions, width + height + length ?? undefined);
    }
  }, [formik.values.boxWidth, formik.values.boxHeight, formik.values.boxLength, formik.values.weight]);

  const { shipmentFee, shippingFeeLoading, kuronekoEnabled, kuronekoPossibleType, toggleKuroneko } = useYamatoFee({
    dimensions: {
      boxHeight: formik.values.boxHeight,
      boxWidth: formik.values.boxWidth,
      boxLength: formik.values.boxLength,
      weight: formik.values.weight
    },
    isTrusted: user?.seller?.isTrusted ?? false
  });

  const suggestKuroneko = useMemo(() => {
    return !user?.seller?.isTrusted && !!kuronekoPossibleType;
  }, [user?.seller?.isTrusted, kuronekoPossibleType]);

  const formSetValue = formik.setFieldValue;

  useEffect(() => {
    const lastIndex = productImages.length - 1;
    if (formik.values.mainPhotoIndex && formik.values.mainPhotoIndex > lastIndex) {
      formSetValue(CreateProductSchemaKeys.mainPhotoIndex, lastIndex);
      handleDraftCreation(CreateProductSchemaKeys.mainPhotoIndex, lastIndex);
    }
    setProductImages((images) => {
      return images.map((image, index) => ({
        ...image,
        main: index === formik.values.mainPhotoIndex
      }));
    });
  }, [formSetValue, formik.values.mainPhotoIndex, setProductImages, productImages.length, handleDraftCreation]);

  useEffect(() => {
    if (drafts && drafts.length > 0 && !createdDraftId && !isEdit && !isRelist) {
      openDraftsModal();
    }
  }, [drafts, createdDraftId, isEdit, isRelist, openDraftsModal]);

  useUpdateEffect(() => {
    if (productDraftImages.length > 0 && !uploadInProgress) {
      handleDraftCreation(
        'attachments',
        productDraftImages.map((draftImage) => {
          return {
            attachmentUid: draftImage.attachmentUid
          };
        })
      );
    }
  }, [productDraftImages, uploadInProgress]);

  if (draftsLoading) {
    return <LoaderBox />;
  }

  return (
    <>
      <FormScrollToError formik={formik} />
      <div className={s.CreateProductPageContent}>
        <Heading size={isMobile ? HeadingSize.H4 : HeadingSize.H3} adaptive={false}>
          {isEdit ? (
            <FormattedMessage id="profile.editProductTitle" />
          ) : isDraft ? (
            <FormattedMessage id={'profile.draftsCreate'} />
          ) : (
            <FormattedMessage id="profile.createProductTitle" />
          )}
        </Heading>
        <Space size={ESpaceSize.PIXEL_24} />
        <FormLabel required>
          <FormattedMessage id="profile.createProductPhotos" />
        </FormLabel>
        <Grid
          cols={{ xs: 2, md: 4, lg: 9, xl: 6 }}
          gap={GridGap.MEDIUM}
          className={s.CreateProductPageContent__imageRow}
          innerRef={imagesRef}
        >
          {productImages?.map((productImage, index) => (
            <Grid.GridItem cols={{ xs: 1, lg: 2 }} key={index}>
              <div className={s.CreateProductPageContent__imageContainer}>
                {productImages[index].main && (
                  <div
                    className={clsx(
                      s.CreateProductPageContent__imageBadge,
                      s.CreateProductPageContent__imageBadge_left
                    )}
                  >
                    Main
                  </div>
                )}

                <ContextMenu
                  className={s.CreateProductPageContent__imageMenu}
                  disabled={uploadInProgress}
                  items={
                    [
                      !productImages[index].main && {
                        title: 'Set as main',
                        onClick: () => {
                          setProductImages((images) => {
                            return images.map((image) => ({
                              ...image,
                              main: image.attachment_uid === productImage.attachment_uid
                            }));
                          });
                          formik.setFieldValue(CreateProductSchemaKeys.mainPhotoIndex, index);
                          handleDraftCreation(CreateProductSchemaKeys.mainPhotoIndex, index);
                        }
                      },
                      {
                        title: 'Delete',
                        icon: BsTrash,
                        danger: true,
                        onClick: () => {
                          setProductImages((images) =>
                            images.filter((image) => productImage.attachment_uid !== image.attachment_uid)
                          );
                          if (productImage.main && productImages.length > 1) {
                            setProductImages((images) => [{ ...images[0], main: true }, ...images.slice(1)]);
                          }
                          removeDraftImage(productDraftImages[index]?.attachmentUid || '');
                        }
                      }
                    ].filter(Boolean) as ContextMenuItem[]
                  }
                  buttonVariant={IconButtonVariant.SECONDARY}
                />

                <img className={s.CreateProductPageContent__imageContent} src={productImage.url} alt="" />
              </div>
            </Grid.GridItem>
          ))}

          {productImages.length < maxProductImagesCount && (
            <Grid.GridItem cols={{ xs: 1, lg: 2 }}>
              <div
                className={clsx(
                  s.CreateProductPageContent__imageContainer,
                  s.CreateProductPageContent__imageContainer_upload
                )}
              >
                <InputUpload
                  className={s.CreateProductPageContent__imageUpload}
                  onChange={onUpload}
                  multiple={true}
                  accept={'image/*'}
                >
                  {uploadInProgress ? (
                    <Loader />
                  ) : (
                    <>
                      <BsCardImage />
                      <span>
                        <FormattedMessage id="general.upload" />
                      </span>
                    </>
                  )}
                </InputUpload>
              </div>
            </Grid.GridItem>
          )}
        </Grid>
        <Space size={ESpaceSize.PIXEL_8} />
        <div className={s.CreateProductPageContent__imageHelp}>
          <FormattedMessage id="profile.createProductPhotosText" values={{ br: () => <br /> }} />
        </div>
        {imageError && <div className={s.CreateProductPageContent__imageError}>At least 1 photo is required</div>}
        <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_40} />
        <div className={s.CreateProductPageContent__type}>
          <FormattedMessage id="profile.createProductBasicParams" />
        </div>
        {!isMobile && <Space size={ESpaceSize.PIXEL_8} />}
        {user?.roles?.includes(UserRoles.Lister) && (
          <FormInput
            type={'number'}
            name={'warehouseNumber'}
            label={"Please fill warehouse number from list we've given to you"}
            placeholder={'Warehouse number'}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.warehouseNumber}
            error={formik.errors.warehouseNumber}
            space={isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16}
            required
          />
        )}
        <FormSelect
          name={CreateProductSchemaKeys.categoryId}
          label={intl.formatMessage({ id: 'catalog.category' })}
          defaultOption={{
            title: `${intl.formatMessage({ id: 'general.select' })} ${intl.formatMessage({ id: 'catalog.category' })}`
          }}
          options={categories || []}
          onItemSelect={(option) => {
            formik.setFieldValue(CreateProductSchemaKeys.categoryId, option.value);
            handleDraftCreation(CreateProductSchemaKeys.categoryId, option.value);
          }}
          value={formik.values.categoryId}
          error={formik.errors.categoryId}
          space={isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16}
          required
          loading={categoriesLoading}
        />
        <FormSelect
          name={CreateProductSchemaKeys.subCategoryId}
          label={intl.formatMessage({ id: 'catalog.subCategory' })}
          defaultOption={{
            title: `${intl.formatMessage({ id: 'general.select' })} ${intl.formatMessage({
              id: 'catalog.subCategory'
            })}`
          }}
          options={subCategories || []}
          onItemSelect={(option) => {
            formik.setFieldValue(CreateProductSchemaKeys.subCategoryId, option.value);
            handleDraftCreation(CreateProductSchemaKeys.subCategoryId, option.value);
          }}
          value={formik.values.subCategoryId}
          error={formik.errors.subCategoryId}
          space={isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16}
          disabled={!formik.values.categoryId}
          required
          loading={subCategoriesLoading}
        />
        <FormSelect
          name={CreateProductSchemaKeys.subSubCategoryId}
          label={intl.formatMessage({ id: 'catalog.subSubCategory' })}
          defaultOption={{
            title: `${intl.formatMessage({ id: 'general.select' })} ${intl.formatMessage({
              id: 'catalog.subSubCategory'
            })}`
          }}
          options={subSubCategories || []}
          onItemSelect={(option) => {
            formik.setFieldValue(CreateProductSchemaKeys.subSubCategoryId, option.value);
            handleDraftCreation(CreateProductSchemaKeys.subSubCategoryId, option.value);
          }}
          value={formik.values.subSubCategoryId}
          error={formik.errors.subSubCategoryId}
          space={isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16}
          disabled={!formik.values.subCategoryId || !subSubCategories?.length}
          required
          loading={subSubCategoriesLoading}
        />
        <FormInput
          name={CreateProductSchemaKeys.name}
          label={intl.formatMessage({ id: 'profile.createProductName' })}
          placeholder={intl.formatMessage({ id: 'profile.createProductName' })}
          onChange={(e) => {
            formik.handleChange(e);
            handleDraftCreation(CreateProductSchemaKeys.name, e.target.value);
          }}
          value={formik.values.name}
          error={formik.errors.name}
          maxLength={productNameMaxLength}
          inputDelayMs={defaultProductInputDelay}
          space={isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16}
          required
        />
        <FormInput
          as={'textarea'}
          name={CreateProductSchemaKeys.description}
          label={intl.formatMessage({ id: 'profile.createProductDescription' })}
          placeholder={intl.formatMessage({ id: 'profile.createProductDescription' })}
          onChange={(e) => {
            formik.handleChange(e);
            handleDraftCreation(CreateProductSchemaKeys.description, e.target.value);
          }}
          value={formik.values.description}
          error={formik.errors.description}
          space={{ top: isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16 }}
          help={`${formik.values.description?.length || 0} / ${descriptionMaxLength}`}
          inputDelayMs={defaultProductInputDelay}
          maxLength={descriptionMaxLength}
          required
        />
        <BrandInput
          name={CreateProductSchemaKeys.brandId}
          label={intl.formatMessage({ id: 'catalog.brand' })}
          placeholder={intl.formatMessage({ id: 'profile.createProductBrand' })}
          active={formik.values.brandId || null}
          setActive={(id) => {
            formik.setFieldValue(CreateProductSchemaKeys.brandId, id || undefined);
            handleDraftCreation(CreateProductSchemaKeys.brandId, id);
          }}
          error={formik.errors.brandId}
          inputDelayMs={defaultProductInputDelay}
          space={isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16}
        />
        {formik.values.subSubCategoryId && (
          <FormSelect
            name={CreateProductSchemaKeys.colorId}
            label={intl.formatMessage({ id: 'catalog.color' })}
            defaultOption={{
              title: `${intl.formatMessage({ id: 'general.select' })} ${intl.formatMessage({
                id: 'catalog.color'
              })}`,
              value: ''
            }}
            options={colors || []}
            onItemSelect={(option) => {
              formik.setFieldValue(CreateProductSchemaKeys.colorId, option.value);
              handleDraftCreation(CreateProductSchemaKeys.colorId, option.value);
            }}
            value={formik.values.colorId}
            error={formik.errors.colorId}
            space={isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16}
            loading={colorsLoading}
            disabled={!formik.values.subSubCategoryId}
          />
        )}
        <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />
        <div className={s.CreateProductPageContent__type}>
          <FormattedMessage id="profile.createProductAdditionalParams" />
        </div>
        {!isMobile && <Space size={ESpaceSize.PIXEL_8} />}
        {specificAttributes?.map((attr, index) => (
          <FormSelect
            key={index}
            label={attr.name}
            defaultOption={{ title: `${intl.formatMessage({ id: 'general.select' })} ${attr.name}`, value: '' }}
            options={attr.variants || []}
            onItemSelect={(option) => setSpecificValue(attr.id, option.value)}
            value={formik.values.specificValues.find(({ attributeId }) => attr.id === attributeId)?.variantId}
            error={formik.errors.specificValues && (formik.errors.specificValues[index] as string)}
            onBlurCapture={() =>
              handleDraftCreation(CreateProductSchemaKeys.specificValues, formik.values.specificValues)
            }
            space={isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16}
            loading={specificAttributesLoading}
            required={
              subSubCategories?.find(({ value }) => value === formik.values.subSubCategoryId)?.mandatoryAttribute ===
              attr.id
            }
          />
        ))}
        <FormSelect
          name={CreateProductSchemaKeys.condition}
          label={intl.formatMessage({ id: 'catalog.condition' })}
          defaultOption={{
            title: `${intl.formatMessage({ id: 'general.select' })} ${intl.formatMessage({
              id: 'catalog.condition'
            })}`,
            value: ''
          }}
          options={conditions || []}
          onItemSelect={(option) => {
            formik.setFieldValue(CreateProductSchemaKeys.condition, option.value);
            handleDraftCreation(CreateProductSchemaKeys.condition, option.value as ProductConditionEnum);
          }}
          value={formik.values.condition}
          error={formik.errors.condition}
          space={isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16}
          required
        />
        <FormInput
          classes={{
            input: s.CreateProductPageContent__inputPrice
          }}
          type={'number'}
          name={CreateProductSchemaKeys.price}
          label={`${intl.formatMessage({ id: 'profile.createProductPrice' })} (${
            productAttributeLimits.price.unit
          }${intl.formatNumber(productAttributeLimits.price.min)} ~ ${intl.formatNumber(
            productAttributeLimits.price.max
          )})`}
          placeholder={intl.formatMessage({ id: 'profile.createProductPrice' })}
          onChange={(e) => {
            formik.handleChange(e);
            handleDraftCreation(CreateProductSchemaKeys.price, tryNumber(e.target.value));
          }}
          value={formik.values.price ?? ''}
          error={formik.errors.price}
          unitLeft={productAttributeLimits.price.unit}
          inputDelayMs={defaultProductInputDelay}
          space={{ top: isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16, bottom: ESpaceSize.PIXEL_12 }}
          required
        />
        <div className={clsx(s.CreateProductPageContent__priceInfo)}>
          <span>
            <FormattedMessage id="profile.createProductCommission" />
          </span>

          <span>{formatMoney(formik.values.price * 0.1)}</span>
        </div>
        {!user?.seller?.isTrusted && (
          <div className={clsx(s.CreateProductPageContent__priceInfo)}>
            <span>
              <FormattedMessage id="profile.createProductFixedFee" />
            </span>

            {shippingFeeLoading ? <Loader size={LoaderSize.SMALL} /> : <span>{formatMoney(shipmentFee)}</span>}
          </div>
        )}
        <div className={clsx(s.CreateProductPageContent__priceInfo)}>
          <span>
            <FormattedMessage id="profile.createProductYouWillGot" />
          </span>

          <span>{formatMoney(formik.values.price - Math.round(formik.values.price * 0.1) - (shipmentFee || 0))}</span>
        </div>
        <FormInput
          type={'number'}
          precision={1}
          name={CreateProductSchemaKeys.weight}
          label={`${intl.formatMessage({ id: 'profile.createProductWeight' })} (${
            productAttributeLimits.weight.min
          } ~ ${productAttributeLimits.weight.max}${productAttributeLimits.weight.unit})`}
          placeholder={intl.formatMessage({ id: 'profile.createProductWeight' })}
          onChange={(e) => {
            formik.handleChange(e);
            handleDraftCreation(CreateProductSchemaKeys.weight, tryNumber(e.target.value));
          }}
          value={formik.values.weight ?? ''}
          error={formik.errors.weight}
          unitRight={productAttributeLimits.weight.unit}
          space={isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16}
          required
        />
        <Space size={ESpaceSize.PIXEL_16} />
        <div className={clsx(s.CreateProductPageContent__dimensions)}>
          <div className={s.CreateProductPageContent__dimensionsInputs}>
            <FormInput
              type={'number'}
              min={0}
              precision={1}
              name={CreateProductSchemaKeys.boxHeight}
              label={`${intl.formatMessage({ id: 'profile.createProductHeight' })}`}
              placeholder={intl.formatMessage({ id: 'profile.createProductHeight' })}
              onChange={(e) => {
                formik.handleChange(e);
                handleDraftCreation(CreateProductSchemaKeys.boxHeight, tryNumber(e.target.value));
              }}
              value={formik.values.boxHeight ?? ''}
              error={isMobile ? formik.errors.boxHeight : !!formik.errors.boxHeight}
              unitRight={productAttributeLimits.size.unit}
              required
            />

            {isMobile ? (
              <Space size={ESpaceSize.PIXEL_24} />
            ) : (
              <BsXLg className={s.CreateProductPageContent__dimensionsCross} />
            )}

            <FormInput
              type={'number'}
              min={0}
              precision={1}
              name={CreateProductSchemaKeys.boxWidth}
              label={`${intl.formatMessage({ id: 'profile.createProductWidth' })}`}
              placeholder={intl.formatMessage({ id: 'profile.createProductWidth' })}
              onChange={(e) => {
                formik.handleChange(e);
                handleDraftCreation(CreateProductSchemaKeys.boxWidth, tryNumber(e.target.value));
              }}
              value={formik.values.boxWidth ?? ''}
              error={isMobile ? formik.errors.boxWidth : !!formik.errors.boxWidth}
              unitRight={productAttributeLimits.size.unit}
              required
            />

            {isMobile ? (
              <Space size={ESpaceSize.PIXEL_24} />
            ) : (
              <BsXLg className={s.CreateProductPageContent__dimensionsCross} />
            )}

            <FormInput
              type={'number'}
              min={0}
              precision={1}
              name={CreateProductSchemaKeys.boxLength}
              label={`${intl.formatMessage({ id: 'profile.createProductLength' })}`}
              placeholder={intl.formatMessage({ id: 'profile.createProductLength' })}
              onChange={(e) => {
                formik.handleChange(e);
                handleDraftCreation(CreateProductSchemaKeys.boxLength, tryNumber(e.target.value));
              }}
              value={formik.values.boxLength ?? ''}
              error={isMobile ? formik.errors.boxLength : !!formik.errors.boxLength}
              unitRight={productAttributeLimits.size.unit}
              required
            />
          </div>

          <div className={s.CreateProductPageContent__dimensionsErrors}>
            {!isMobile && (
              <>
                {formik.errors.boxHeight && (
                  <Form.Text className={clsx(s.CreateProductPageContent__dimensionsError)}>
                    Height: {formik.errors.boxHeight}
                  </Form.Text>
                )}
                {formik.errors.boxWidth && (
                  <Form.Text className={clsx(s.CreateProductPageContent__dimensionsError)}>
                    Width: {formik.errors.boxWidth}
                  </Form.Text>
                )}
                {formik.errors.boxLength && (
                  <Form.Text className={clsx(s.CreateProductPageContent__dimensionsError)}>
                    Length: {formik.errors.boxLength}
                  </Form.Text>
                )}
              </>
            )}

            {formik.errors.dimensions && (
              <Form.Text className={clsx(s.CreateProductPageContent__dimensionsError)}>
                {formik.errors.dimensions}
              </Form.Text>
            )}
          </div>
        </div>
        {suggestKuroneko && <YuPacketBlock kuronekoEnabled={kuronekoEnabled} toggleKuroneko={toggleKuroneko} />}
        <Space size={ESpaceSize.PIXEL_16} />
        <FormSelect
          name={CreateProductSchemaKeys.deliverySpeedDays}
          label={intl.formatMessage({ id: 'profile.createProductDeliverySpeed' })}
          options={deliverySpeedDaysVariants || []}
          onItemSelect={(option) => {
            formik.setFieldValue(CreateProductSchemaKeys.deliverySpeedDays, option.value);
            handleDraftCreation(CreateProductSchemaKeys.deliverySpeedDays, option.value as DeliverySpeedDaysEnum);
          }}
          value={formik.values.deliverySpeedDays}
          error={formik.errors.deliverySpeedDays}
          space={isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16}
          required
        />
        <Space size={ESpaceSize.PIXEL_16} />
        {isEdit && (
          <>
            <Space size={ESpaceSize.PIXEL_16} />

            <Anchor
              component="button"
              variant={AnchorVariant.DANGER}
              leftIcon={BsTrash}
              leftIconOptions={{ size: 14 }}
              onClick={openDeleteModal}
            >
              <FormattedMessage id="profile.createProductDelete" />
            </Anchor>

            <Space size={ESpaceSize.PIXEL_32} />
          </>
        )}
        <Space size={ESpaceSize.PIXEL_16} />
        <Grid
          cols={{ xs: 2, lg: 9, xl: 6 }}
          className={s.CreateProductPageContent__actionButtonsBlock}
          gap={GridGap.SMALL}
        >
          {createdDraftId && (
            <Grid.GridItem cols={{ xs: 2, lg: 2 }}>
              <Button
                fit={ButtonFit.FILL}
                variant={ButtonVariant.SECONDARY}
                loading={uploadDraftLoading}
                onClick={() => navigate(DRAFTS_ROUTE)}
                disabled={!createdDraftId}
              >
                <FormattedMessage id={'profile.draftsSave'} />
              </Button>
            </Grid.GridItem>
          )}
          <Grid.GridItem cols={{ xs: 2, lg: 2 }}>
            <Button
              fit={ButtonFit.FILL}
              loading={createProductLoading || updateProductLoading}
              onClick={formik.submitForm}
            >
              {isEdit ? (
                <FormattedMessage id="profile.createProductSaveButton" />
              ) : (
                <FormattedMessage id="profile.createProductSubmitButton" />
              )}
            </Button>
          </Grid.GridItem>
        </Grid>
      </div>
      <Modal
        title={intl.formatMessage({ id: 'profile.createProductDelete' })}
        isOpen={isDeleteModal}
        onClose={closeDeleteModal}
      >
        <Modal.Body>
          <FormattedMessage id="profile.createProductDeleteText" />
        </Modal.Body>

        <Modal.Footer>
          <Modal.Button onClick={removeProduct} loading={removeProductLoading}>
            <FormattedMessage id="general.delete" />
          </Modal.Button>
        </Modal.Footer>
      </Modal>
      {/* Модальное окно, открывающееся в случае наличия черновиков у продавца */}
      {drafts && <DraftsModal drafts={drafts} isOpen={draftsModalOpen} onClose={closeDraftsModal} />}
    </>
  );
};
