import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { BsCheckCircleFill, BsPersonFill } from 'react-icons/bs';
import { RefObject } from 'react';
import { useNavigate } from 'react-router';
import { Text, TextVariant } from '../../../../common/components/Text';
import { TRUST_POINTS_ROUTE } from '../../../../common/utils/routes';
import s from './UserAvatar.module.scss';

export interface UserAvatarProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Ссылка на изображение
   */
  src?: string;
  /**
   * Флаг, является ли продавец доверенным
   */
  isTrusted?: boolean;
  /**
   * Текст плейсхолдера при отсутствии изображения
   */
  placeholderText?: string;
  /**
   * Обработчик нажатия на аватарку
   */
  onClick?: () => void;
  /**
   * Реф на корневой элемент
   */
  innerRef?: RefObject<HTMLDivElement>;
  /**
   * Флаг является ли пользователь продавцом
   */
  isSeller?: boolean;
  /**
   * Флаг если аватар нужно отрисовать на внутренней странице пользователя
   */
  isOwnPage?: boolean;
}

export const UserAvatar: ReactFCC<UserAvatarProps> = (props) => {
  const { className, src, placeholderText, onClick, innerRef, isTrusted, isSeller, isOwnPage } = props;
  const navigate = useNavigate();
  return (
    <div
      className={clsx(s.UserAvatar, className, {
        [s.UserAvatar_placeholder]: !src,
        [s.UserAvatar_interactive]: onClick
      })}
      onClick={onClick}
      ref={innerRef}
    >
      {src ? (
        <>
          <img className={s.UserAvatar__image} src={src} alt={''} />
          {isSeller && (
            <BsCheckCircleFill
              onClick={isOwnPage ? () => navigate(TRUST_POINTS_ROUTE) : undefined}
              className={
                isTrusted
                  ? clsx(s.UserAvatar__statusTrusted, isOwnPage && s.UserAvatar_interactive)
                  : clsx(s.UserAvatar__statusNonTrusted, isOwnPage && s.UserAvatar_interactive)
              }
            ></BsCheckCircleFill>
          )}
        </>
      ) : (
        <>
          <BsPersonFill className={s.UserAvatar__placeholderIcon} />
          <Text className={s.UserAvatar__placeholderText} variant={TextVariant.BODY_S_NORMAL}>
            {placeholderText}
          </Text>
          {isSeller && (
            <BsCheckCircleFill
              onClick={isOwnPage ? () => navigate(TRUST_POINTS_ROUTE) : undefined}
              className={
                isTrusted
                  ? clsx(s.UserAvatar__statusTrusted, isOwnPage && s.UserAvatar_interactive)
                  : clsx(s.UserAvatar__statusNonTrusted, isOwnPage && s.UserAvatar_interactive)
              }
            ></BsCheckCircleFill>
          )}
        </>
      )}
    </div>
  );
};
