import io, { Socket } from 'socket.io-client';
import { useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useAuth } from '../../app/providers/auth-apollo';
import { wsUrl } from '../../config';

type UseSocketProps = {
  namespace: string;
};

const MAX_RETRIES = 5;
export const useSocket = ({ namespace }: UseSocketProps) => {
  const { getToken } = useAuth();
  const [authRetries, setAuthRetries] = useState(0);
  const [socketConnected, setSocketConnected] = useState(false);
  const socketRef = useRef<Socket | null>(null);
  const retriesRef = useRef<number | null>(null);
  retriesRef.current = authRetries;

  const connectSocket = async () => {
    try {
      if (socketRef.current) {
        socketRef.current?.disconnect();
      }
      const newToken = await getToken();
      // Socket internal nginx
      socketRef.current = io(`${wsUrl}/${namespace}`, {
        auth: {
          token: newToken
        },
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity
      });
      socketRef.current.on('disconnect', () => {});
      socketRef.current.on('unauthorizedError', () => {
        handleSocketAuthError();
      });
      socketRef.current?.on('successConnect', () => {
        console.log();
        setSocketConnected(true);
        setAuthRetries(0);
      });
      return () => {
        if (socketRef.current) {
          socketRef.current.disconnect();
        }
      };
    } catch (error) {
      console.error('Failed to connect to socket:', error);
    }
  };

  const handleSocketAuthError = () => {
    if (retriesRef.current === null) {
      return;
    }
    if (retriesRef.current >= MAX_RETRIES) {
      return;
    }
    setAuthRetries(retriesRef.current + 1);
    if (socketRef.current) {
      socketRef.current.disconnect();
    }
    connectSocket();
  };

  const closeSocket = (roomId: string, userId?: string) => {
    socketRef.current?.emit('leaveRoom', { roomId, userId });
    socketRef.current?.disconnect();
  };

  const reconnect = async () => {
    if (socketRef.current && !socketRef.current.connected && !document.hidden) {
      const token = await getToken();
      socketRef.current.auth = {
        token: `Bearer ${token}`
      };
      socketRef.current?.connect();
    }
  };

  useEffectOnce(() => {
    document.addEventListener('visibilitychange', reconnect);
    window.addEventListener('online', reconnect);
    return () => {
      document.removeEventListener('visibilitychange', reconnect);
      window.removeEventListener('online', reconnect);
    };
  });

  return {
    connectSocket,
    closeSocket,
    socketRef,
    socketConnected
  };
};
