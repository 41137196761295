import React from 'react';
import clsx from 'clsx';
import { Link, To } from 'react-router-dom';
import { Anchor } from 'common/components/Anchor/Anchor';
import s from './TransactionItemLink.module.scss';

export interface TransactionItemLinkProps {
  className?: string;
  to: To;
  children?: React.ReactNode;
}

export const TransactionItemLink = ({ className, to, children }: TransactionItemLinkProps) => {
  return (
    <Anchor className={clsx(s.TransactionItemLink, className)} component={Link} to={to}>
      {children}
    </Anchor>
  );
};
