import React from 'react';
import { UseStripeSessionReturnType } from './usePaymentSession';

export interface IPaymentContext extends UseStripeSessionReturnType {
  hasPaymentMethod: boolean;
  paymentCardLastNumbers: string;
  intentPayment: (debtId?: string) => Promise<string | null>;
  intentPaymentLoading: boolean;
}

export const PaymentContext = React.createContext<IPaymentContext>({
  addPaymentMethod: () => {},
  addPaymentLoading: false,
  detachPaymentMethod: () => {},
  detachPaymentLoading: false,
  paymentMethodDetached: false,
  hasPaymentMethod: false,
  paymentCardLastNumbers: '',
  intentPayment: () => new Promise((res) => res(null)),
  intentPaymentLoading: false
});

export enum FromStripeQS {
  ordering = 'ordering',
  me = 'me',
  canceled = 'canceled'
}

export const fromStripeUrls = {
  [FromStripeQS.ordering]: 'arisora://ordering',
  [FromStripeQS.me]: 'arisora://me',
  [FromStripeQS.canceled]: 'arisora://canceled'
};
