import React from 'react';
import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonVariant } from 'common/components/Button';
import { Alert } from 'common/components/Alert/Alert';
import { NOTIFICATIONS_ROUTE } from '../../../../common/utils/routes';
import s from './NotificationsAlert.module.scss';

export interface NotificationsAlertProps {
  count: number;
}

export const NotificationsAlert = ({ count }: NotificationsAlertProps) => {
  const navigate = useNavigate();

  return (
    <Alert className={s.NotificationsAlert}>
      <div className={s.NotificationsAlert__text}>
        <FormattedMessage
          id={'notification.hasNotificationsAlertText'}
          values={{ count: <span className={s.NotificationsAlert__count}>{count}</span> }}
        />
      </div>
      <div className={s.NotificationsAlert__actions}>
        <Button variant={ButtonVariant.ERROR} onClick={() => navigate(NOTIFICATIONS_ROUTE)}>
          <FormattedMessage id={'notification.goToNotificationsButton'} />
        </Button>
      </div>
    </Alert>
  );
};
