import React, { useMemo, useRef, useState, JSX, useEffect } from 'react';
import clsx from 'clsx';
import { useClickOutside } from '@proscom/ui-react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { Anchor } from 'common/components/Anchor/Anchor';
import { isKeyDownEnter } from 'common/utils/isKeyDownEnter';
import { Button, ButtonFit, ButtonVariant } from 'common/components/Button';
import { InputLayout, InputLayoutProps, InputLayoutClasses } from '../InputLayout/InputLayout';
import s from './FormSelect.module.scss';

interface FormSelectClasses extends InputLayoutClasses {
  select?: string;
  option?: string;
}

export interface FormSelectOption {
  title: string | JSX.Element;
  value: string;
}

interface FormSelectSelf extends InputLayoutProps {
  className?: string;
  classes?: FormSelectClasses;
  defaultOption?: Partial<FormSelectOption>;
  options: FormSelectOption[];
  onItemSelect?: (option: FormSelectOption) => void;
  value?: string;
  loading?: boolean;
}

export type TFormSelect = React.SelectHTMLAttributes<HTMLUListElement> & FormSelectSelf;

const FormSelectCmp: React.ForwardRefRenderFunction<HTMLUListElement, TFormSelect> = (props, ref) => {
  const {
    className,
    classes,
    label,
    defaultOption,
    value,
    options,
    required,
    error,
    space,
    disabled,
    loading,
    help,
    onItemSelect,
    ...rest
  } = props;

  const [active, setActive] = useState(false);

  const innerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const title = useMemo(
    () => (value && options.find((o) => o.value === value)?.title) || defaultOption?.title,
    [value, options, defaultOption]
  );

  const onItemSelectDefault = (option: FormSelectOption) => {
    onItemSelect?.(option);

    setTimeout(() => {
      setActive(false);
    });
  };

  useClickOutside(innerRef, () => {
    setActive(false);
  });

  // Добавляет дефолтные варианты в dropdown для не required элементов
  useEffect(() => {
    if (defaultOption && !required) {
      options.unshift({ title: defaultOption?.title as string, value: defaultOption?.value as string });
    }
  }, [options, defaultOption, required]);

  useEffect(() => {
    if (active) {
      buttonRef?.current?.focus();
    }
  }, [active]);

  return (
    <InputLayout
      className={clsx(s.FormSelect, className, classes?.root)}
      classes={classes}
      label={label}
      required={required}
      error={error}
      space={space}
      help={help}
      disabled={disabled}
      innerRef={innerRef}
    >
      <Button
        className={s.FormSelect__button}
        variant={ButtonVariant.INPUT}
        fit={ButtonFit.FILL}
        disabled={!loading && disabled}
        loading={loading}
        innerRef={buttonRef}
        onClick={() => setActive(true)}
      >
        {title}
        {active ? (
          <BsChevronUp className={s.FormSelect__buttonIcon} />
        ) : (
          <BsChevronDown className={s.FormSelect__buttonIcon} />
        )}
      </Button>

      <ul
        className={clsx(
          'dropdown-menu',
          options.length !== 0 && active && 'show',
          s.FormSelect__dropdown,
          classes?.select
        )}
        ref={ref}
        {...rest}
      >
        {options.map((option, index) => (
          <li key={index}>
            <Anchor
              component="span"
              className={clsx(s.FormInputSelect__dropdownItem, 'dropdown-item', classes?.option)}
              onClick={() => onItemSelectDefault(option)}
              onKeyDownCapture={(e: React.KeyboardEvent) => {
                if (isKeyDownEnter(e)) {
                  onItemSelectDefault(option);
                }
              }}
            >
              {option.title}
            </Anchor>
          </li>
        ))}
      </ul>
    </InputLayout>
  );
};

export const FormSelect = React.forwardRef(FormSelectCmp);
