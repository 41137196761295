import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { Button } from 'common/components/Button';
import { useBankAccountForm, UseBankAccountFormProps } from '../useBankAccountForm';
import { BaseBankAccountForm } from '../BaseBankAccountForm';
import s from './BankAccountForm.module.scss';

export interface BankAccountFormProps extends UseBankAccountFormProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Состояние загрузки кнопки
   */
  loading?: boolean;
  /**
   * Функция, которая вызывается при попытке отправить форму. Используется для валидации внешних полей
   */
  validate?: () => Promise<any>;
}

export const BankAccountForm: ReactFCC<BankAccountFormProps> = (props) => {
  const { className, bankAccount, onAccountSaved, loading: loadingProp, validate } = props;

  const { loading, ...formProps } = useBankAccountForm({
    bankAccount,
    onAccountSaved
  });

  const formSubmit = formProps.formik.submitForm;
  const formValidate = formProps.formik.validateForm;

  const onClick = useCallback(async () => {
    if (validate) {
      const errors = await validate?.();

      if (Object.keys(errors).length !== 0) {
        formValidate();
        return;
      }
    }

    formSubmit();
  }, [formSubmit, formValidate, validate]);

  return (
    <div className={clsx(s.BankAccountForm, className)}>
      <div className={s.BankAccountForm__form}>
        <BaseBankAccountForm {...formProps} />
      </div>

      <Button onClick={onClick} loading={loading || loadingProp}>
        Send request
      </Button>
    </div>
  );
};
