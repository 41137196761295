import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { Button } from 'react-bootstrap';
import { useCounter, useCss } from 'react-use';
import { imageSize, thumbnailSize, thumbnailsMaxVisible, thumbnailsSpacing } from '../constants';
import { ImageViewerProps } from '../types';
import s from './ImageViewerDesktop.module.scss';

export const ImageViewerDesktop: React.FC<ImageViewerProps> = ({ className, images }) => {
  const thumbnailsHeight = thumbnailsMaxVisible * thumbnailSize + thumbnailsSpacing * (thumbnailsMaxVisible - 1);

  const thumbnailClassName = useCss({
    width: `${thumbnailSize}px`,
    height: `${thumbnailSize}px`,
    '&:not(:last-child)': {
      'margin-bottom': `${thumbnailsSpacing}px`
    }
  });
  const thumbnailsClassName = useCss({
    width: `${thumbnailSize}px`,
    'max-height': `${thumbnailsHeight}px`
  });
  const imageClassName = useCss({
    'max-width': `${imageSize}px`,
    'max-height': `${imageSize}px`
  });

  const showButtons = images.length > thumbnailsMaxVisible;

  const refs = useRef<Array<HTMLDivElement | null>>([]);
  const [imageIndex, { inc, dec, set }] = useCounter(0, images.length - 1, 0);

  useEffect(() => {
    if (refs.current[imageIndex]) {
      refs.current[imageIndex]?.scrollIntoView({
        block: 'nearest',
        behavior: 'auto'
      });
    }
  }, [imageIndex]);

  return (
    <div className={clsx(s.ImageViewerDesktop, className)}>
      {showButtons && (
        <Button
          variant="light"
          disabled={imageIndex === 0}
          className={clsx(s.ImageViewerDesktop__button, s.ImageViewerDesktop__button_prev)}
          onClick={() => dec()}
        >
          <BsChevronUp />
        </Button>
      )}

      <div className={clsx(s.ImageViewerDesktop__thumbnails, thumbnailsClassName)}>
        {images.map((image, index) => (
          <div
            className={clsx(
              s.ImageViewerDesktop__thumbnail,
              thumbnailClassName,
              imageIndex === index && s.ImageViewerDesktop__thumbnail_active
            )}
            key={index}
            ref={(el) => {
              if (refs.current) {
                return (refs.current[index] = el);
              }
            }}
            onClick={() => set(index)}
          >
            <img
              src={image}
              alt={`Thumbnail ${index}`}
              onError={(e) => (e.currentTarget.src = 'https://via.placeholder.com/85')}
            />
          </div>
        ))}
      </div>

      {showButtons && (
        <Button
          variant="light"
          className={clsx(s.ImageViewerDesktop__button, s.ImageViewerDesktop__button_next)}
          disabled={imageIndex === images.length - 1}
          onClick={() => inc()}
        >
          <BsChevronDown />
        </Button>
      )}

      <div className={clsx(s.ImageViewerDesktop__image, imageClassName)}>
        <img
          src={images[imageIndex]}
          alt={`Image ${imageIndex}`}
          onError={(e) => (e.currentTarget.src = 'https://via.placeholder.com/85')}
        />
      </div>
    </div>
  );
};
