import React from 'react';
import { ReactFCC } from '../../utils/helperTypes';

export type TConditionalWrapper = {
  condition: boolean;
  wrapper: (children: React.ReactNode) => React.ReactElement;
};

export const ConditionalWrapper: ReactFCC<TConditionalWrapper> = (props) => {
  const { condition, wrapper, children } = props;

  return <>{condition ? wrapper(children) : children}</>;
};
