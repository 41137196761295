import React, { useId, useRef } from 'react';
import clsx from 'clsx';
import { ReactFCC } from '../../../utils/helperTypes';
import { isEnterKey } from '../../../utils/isKey';
import s from './InputUpload.module.scss';

type LabelProps = Omit<React.HTMLAttributes<HTMLLabelElement>, 'onChange'>;

export type InputUploadProps = LabelProps & {
  className?: string;
  multiple?: boolean;
  accept?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

export const InputUpload: ReactFCC<InputUploadProps> = (props) => {
  const { children, className, multiple, accept, onChange, disabled, ...rest } = props;

  const id = useId();

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <label
      tabIndex={0}
      {...rest}
      className={clsx(s.InputUpload, className)}
      htmlFor={id}
      onKeyDownCapture={(e: React.KeyboardEvent) => {
        if (isEnterKey(e)) {
          inputRef.current?.click();
        }
      }}
    >
      <input
        className={s.InputUpload__input}
        type="file"
        accept={accept}
        id={id}
        onChange={onChange}
        multiple={multiple}
        ref={inputRef}
        disabled={disabled}
      />
      {children}
    </label>
  );
};
