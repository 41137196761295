import React, { useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import clsx from 'clsx';
import { Col, ColProps } from 'react-bootstrap';
import { LinksList, LinksListItem } from '../../../../common/components/LinkList';
import s from './FooterLinks.module.scss';

export interface FooterLinksItem {
  name: string;
  path: string;
  external?: boolean;
  onClick?: () => void;
}

export interface FooterLinksProps extends ColProps {
  title: string;
  items: FooterLinksItem[];
}

export const FooterLinks: React.FC<FooterLinksProps> = ({ title, items, ...props }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const IconComponent = expanded ? BsChevronUp : BsChevronDown;

  return (
    <Col className={s.FooterLinks} {...props}>
      <h6
        className={clsx(s.FooterLinks__title, expanded && s.FooterLinks__title_active)}
        onClick={() => setExpanded(!expanded)}
      >
        {title}
        <IconComponent className={s.FooterLinks__chevron} />
      </h6>
      <LinksList
        className={s.FooterLinks__items}
        classes={{ expanded: s.FooterLinks__items_expanded }}
        expanded={expanded}
      >
        {items.map(({ name, path, external, onClick }: FooterLinksItem, index) => (
          <LinksListItem
            key={index}
            name={name}
            path={path}
            external={external}
            className={s.FooterLinks__item}
            onClick={onClick}
          />
        ))}
      </LinksList>
    </Col>
  );
};
