import React, { Fragment, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { groupBy } from 'lodash-es';
import { BsEnvelopeOpen } from 'react-icons/bs';
import { Heading, HeadingSize } from '../../common/components/Heading';
import { ReactFCC } from '../../common/utils/helperTypes';
import {
  EventTypeEnum,
  NotificationSortDirection,
  useNotificationsCountQuery,
  useNotificationsQuery
} from '../../store/graphql';
import { LoaderBox } from '../../common/components/Loader';
import { ESpaceSize, Space } from '../../common/components/Space/Space';
import { Divider } from '../../common/components/Divider/Divider';
import { Alert } from '../../common/components/Alert';
import { Pagination, usePagination } from '../../common/components/Pagination';
import { Head } from '../../common/components/Head';
import { useNotifications } from '../../store/notifications/useNotifications';
import { Button, ButtonVariant } from '../../common/components/Button';
import {
  PUBLIC_PROFILE_ROUTE,
  TRUST_POINTS_ROUTE,
  VERIFICATION_ROUTE,
  WALLET_ROUTE,
  WITHDRAW_ROUTE
} from '../../common/utils/routes';
import { NotificationItem } from './NotificationItem/NotificationItem';
import { NotificationsEmpty } from './NotificationsEmpty/NotificationsEmpty';
import s from './NotificationsPage.module.scss';

export type TNotificationsPage = {};

export const NotificationsPage: ReactFCC<TNotificationsPage> = () => {
  // const [page, { setPage, useUpdatePageCount }] = usePagination();
  const { offset, setOffset } = usePagination();

  const { data: notificationsData, loading: notificationsLoading } = useNotificationsQuery({
    nextFetchPolicy: 'cache-and-network',
    variables: {
      input: {
        sort: {
          createdAt: NotificationSortDirection.Desc,
          priority: NotificationSortDirection.Desc
        },
        pagination: {
          limit: 15,
          offset
        }
      }
    }
  });

  const { data: unreadNotificationsCount, loading: unreadNotificationsCountLoading } = useNotificationsCountQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        priority: false,
        read: false
      }
    }
  });

  const { markAllOrdinaryAsRead } = useNotifications();

  const notifications = notificationsData?.notifications.entries;
  const pagination = notificationsData?.notifications.pagination;

  const groupsByPriority = useMemo(() => {
    if (!notifications?.length) return {};
    return groupBy(notifications, (n) => n.priority);
  }, [notifications]);

  const importantNotifications = groupsByPriority['true'];
  const ordinaryNotifications = groupsByPriority['false'];

  const hasImportantNotifications = !!importantNotifications?.length;
  const hasOrdinaryNotifications = !!ordinaryNotifications?.length;
  const hasNotifications = !!notifications?.length;

  const hasUnreadOrdinaryNotifications = !!unreadNotificationsCount?.count;

  const trustPointEvents = [
    EventTypeEnum.TrustPointsIncreased,
    EventTypeEnum.TrustPointsDecreased,
    EventTypeEnum.SellerBecameTrusted
  ];
  const hiddenTrustPointEvents = [
    EventTypeEnum.TrustedSellerStatusReceived,
    EventTypeEnum.TrustedSellerStatusRemoved,
    EventTypeEnum.TrustPointsAddition
  ];
  const verificationEvents = [EventTypeEnum.VerificationApproved, EventTypeEnum.VerificationRejected];
  const transactionEvents = [
    EventTypeEnum.PaymentReceived,
    EventTypeEnum.AdditionalBonus,
    EventTypeEnum.AdditionalCosts
  ];
  const withdrawEvents = [EventTypeEnum.WithdrawalApproved];
  const reviewsEvents = [EventTypeEnum.NewRate];

  // useUpdatePageCount(pagination?.pagesCount);

  return (
    <>
      <Head title={'Notifications'} />

      <div className={s.NotificationsPage}>
        <div className={s.NotificationsPage__title}>
          <Heading size={HeadingSize.H3}>
            <FormattedMessage id="notifications.pageTitle" />
          </Heading>
        </div>

        {notificationsLoading ? (
          <LoaderBox />
        ) : hasNotifications ? (
          <div className={s.NotificationsPage__list}>
            {hasUnreadOrdinaryNotifications && (
              <>
                <Button
                  onClick={markAllOrdinaryAsRead}
                  variant={ButtonVariant.PRIMARY_OUTLINE}
                  className={s.NotificationsPage__readAllButton}
                >
                  <BsEnvelopeOpen size={16} />
                  <FormattedMessage id={'notification.readAll'} />
                </Button>
              </>
            )}
            {hasImportantNotifications && (
              <Alert className={s.NotificationsPage__importantNotifications}>
                {importantNotifications.map((item, iItem) => (
                  <Fragment key={item.id}>
                    {iItem > 0 && <Divider />}
                    <NotificationItem
                      isImportant={item.priority}
                      isRead={item.read}
                      detailsUrl={item.orderUrl || item.productUrl}
                      showRateLabel
                      {...item}
                    />
                  </Fragment>
                ))}
              </Alert>
            )}

            {hasImportantNotifications && hasOrdinaryNotifications && <Divider space={ESpaceSize.PIXEL_24} />}

            {ordinaryNotifications
              ?.filter((item) => !hiddenTrustPointEvents.includes(item.event))
              .map((item, iItem) => (
                <Fragment key={item.id}>
                  <NotificationItem
                    isImportant={item.priority}
                    isRead={item.read}
                    detailsUrl={
                      (trustPointEvents.includes(item.event) ? TRUST_POINTS_ROUTE : undefined) ||
                      (verificationEvents.includes(item.event) ? VERIFICATION_ROUTE : undefined) ||
                      (transactionEvents.includes(item.event) ? WALLET_ROUTE : undefined) ||
                      (withdrawEvents.includes(item.event) ? WITHDRAW_ROUTE : undefined) ||
                      (reviewsEvents.includes(item.event) ? PUBLIC_PROFILE_ROUTE : undefined) ||
                      item.orderUrl ||
                      item.productUrl
                    }
                    showRateLabel
                    {...item}
                  />
                  {iItem < ordinaryNotifications.length && <Divider />}
                </Fragment>
              ))}

            {pagination && (
              <>
                <Space size={ESpaceSize.PIXEL_32} />
                <Pagination
                  limit={pagination.limit}
                  offset={offset}
                  setOffset={setOffset}
                  totalCount={pagination.totalCount}
                />
              </>
            )}
          </div>
        ) : (
          <NotificationsEmpty />
        )}
      </div>
    </>
  );
};
