import { tryNumber } from '@proscom/ui-utils';
import { useEffect, useMemo, useState } from 'react';

import { kuronekoPrices, kuronekoValues } from '../../../../entities/product/types';
import { OrderContentProductKuronekoEnum, useCreateProductFixedFeeLazyQuery } from '../../../../store/graphql';
import { ParcelLimitSchema } from '../../../../pages/profile/sellerOrder/components/OrderCombineFedex/schema';

export interface OrderDimensions {
  weight: number;
  boxLength: number;
  boxWidth: number;
  boxHeight: number;
}

export interface UseYamatoFeeProps {
  dimensions: OrderDimensions;
  isTrusted: boolean;
  isYuPacket?: boolean;
}

export const useYamatoFee = ({ dimensions, isTrusted, isYuPacket }: UseYamatoFeeProps) => {
  const [kuronekoEnabled, setKuronekoEnabled] = useState(isYuPacket ?? false);
  const [kuronekoPossibleType, setKuronekoPossibleType] = useState<OrderContentProductKuronekoEnum | null>(null);
  const [fee, setFee] = useState<number>(0);

  const [fetchFee, { loading: shippingFeeLoading }] = useCreateProductFixedFeeLazyQuery({
    fetchPolicy: 'cache-and-network'
  });

  const disableKuroneko = () => {
    setKuronekoEnabled(false);
    setKuronekoPossibleType(null);
  };

  const toggleKuroneko = () => {
    setKuronekoEnabled(!kuronekoEnabled);
  };

  useEffect(() => {
    if (isTrusted) {
      return;
    }
    const width = tryNumber(dimensions.boxWidth, undefined);
    const height = tryNumber(dimensions.boxHeight, undefined);
    const length = tryNumber(dimensions.boxLength, undefined);
    const weight = tryNumber(dimensions.weight, undefined);

    try {
      ParcelLimitSchema.validateSync({
        boxWidth: width,
        boxHeight: height,
        boxLength: length,
        weight
      });
      fetchFee({
        variables: {
          input: {
            dimensions: +dimensions.boxHeight + +dimensions.boxWidth + +dimensions.boxLength,
            weight: +dimensions.weight
          }
        }
      }).then((res) => setFee(res.data?.fee || 0));
    } catch (e) {
      return;
    }

    if (
      width &&
      height &&
      length &&
      weight &&
      height <= 3 &&
      length <= 34 &&
      width + height + length <= 60 &&
      weight <= 1
    ) {
      setKuronekoPossibleType(kuronekoValues(height));
    } else {
      setKuronekoPossibleType(null);
    }
  }, [
    isTrusted,
    kuronekoEnabled,
    dimensions.boxHeight,
    dimensions.boxWidth,
    dimensions.boxLength,
    dimensions.weight,
    fetchFee
  ]);

  const shipmentFee = useMemo(() => {
    const height = tryNumber(dimensions.boxHeight, undefined);
    if (kuronekoEnabled && height && height <= 3) {
      return kuronekoPrices(height);
    }
    if (!isTrusted) {
      return fee;
    }
    return 0;
  }, [fee, kuronekoEnabled, dimensions.boxHeight, isTrusted]);

  return {
    kuronekoPossibleType,
    kuronekoEnabled,
    disableKuroneko,
    toggleKuroneko,
    shipmentFee,
    shippingFeeLoading
  };
};
