import React from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal } from '../../../../../common/components/Modal';
import { FormInput } from '../../../../../common/components/inputs';
import { ReactFCC } from '../../../../../common/utils/helperTypes';
import { AuthModalBaseProps } from '../types';
import { AuthModalEnterEmailKeys, AuthModalEnterEmailSchema } from './authModalEnterEmailSchema';

export type AuthModalEnterEmailProps = AuthModalBaseProps;

export const AuthModalEnterEmail: ReactFCC<AuthModalEnterEmailProps> = (props) => {
  const { onSubmit } = props;

  const intl = useIntl();

  return (
    <Formik
      initialValues={{
        email: ''
      }}
      validationSchema={AuthModalEnterEmailSchema}
      validateOnMount={false}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={onSubmit}
    >
      {({ values, errors, isSubmitting, handleChange, submitForm }) => (
        <>
          <Form>
            <Modal.Body>
              <p>
                <FormattedMessage id="auth.emailText" />
              </p>

              <FormInput
                type="email"
                name={AuthModalEnterEmailKeys.email}
                label={intl.formatMessage({ id: 'auth.emailLabel' })}
                placeholder="example@gmail.com"
                value={values.email}
                onChange={handleChange}
                error={errors.email}
                required
              />
            </Modal.Body>
          </Form>

          <Modal.Footer>
            <Modal.Button type={'submit'} loading={isSubmitting} onClick={submitForm}>
              <FormattedMessage id="auth.emailSubmitButton" />
            </Modal.Button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};
