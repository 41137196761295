import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ReactFCC } from '../../../../../common/utils/helperTypes';
import { ContextMenu, ContextMenuItem } from '../../../../../common/components/ContextMenu';
import s from './ComplaintContextMenu.module.scss';

export interface ComplaintContextMenuProps {
  /**
   * Функция передаваемая при нажатии на кнопку в контекстном меню
   */
  onClick: () => void;
}

export const ComplaintContextMenu: ReactFCC<ComplaintContextMenuProps> = (props) => {
  const intl = useIntl();

  const { onClick } = props;
  const contextMenuItems: ContextMenuItem[] = useMemo(
    () =>
      [
        {
          title: intl.formatMessage({ id: 'product.complaintContextMenuItem' }),
          onClick
        }
      ].filter(Boolean) as ContextMenuItem[],
    [intl, onClick]
  );
  return (
    <>
      <ContextMenu
        className={s.ComplaintContextMenu__button}
        items={contextMenuItems}
        classes={{ item: s.ComplaintContextMenu__item }}
      />
    </>
  );
};
