import { tryNumber } from '@proscom/ui-utils';

import { ParcelLimitSchema } from '../../../../pages/profile/sellerOrder/components/OrderCombineFedex/schema';
import { OrderDimensions } from '../../yamatoFee/hooks/useYamatoFee';

export const useOrderDimensions = () => {
  const checkOrderDimensions = (dimensions: OrderDimensions) => {
    let yamatoAllowed = false;
    let fedexAllowed = false;
    const width = tryNumber(dimensions.boxWidth, undefined);
    const height = tryNumber(dimensions.boxHeight, undefined);
    const length = tryNumber(dimensions.boxLength, undefined);
    const weight = tryNumber(dimensions.weight, undefined);

    try {
      ParcelLimitSchema.validateSync(dimensions);
    } catch (e) {
      return {
        fedexAllowed,
        yamatoAllowed
      };
    }

    const girth = 2 * width! + 2 * height!;
    const combined = width! + height! + length!;

    yamatoAllowed = !!(weight && combined <= 200 && weight <= 30);
    fedexAllowed = !!(length && weight && length <= 274 && girth <= 330 && weight <= 68);

    return {
      fedexAllowed,
      yamatoAllowed
    };
  };

  return {
    checkOrderDimensions
  };
};
