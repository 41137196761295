import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { ArisoraOrderStatus } from 'store/graphql';
import { Badge, BadgeProps } from '../../../../common/components/Badge';
import { orderStatusIntlNames } from '../../../../common/constants/order';
import s from './OrderStatusBadge.module.scss';

export interface OrderStatusBadgeProps extends Omit<BadgeProps, 'variant'> {
  status: ArisoraOrderStatus;
  isProblem?: boolean; // todo не выводить ошибку при статусах Canceled, Destroyed
}

export const OrderStatusBadge = ({ className, status, children, isProblem, ...props }: OrderStatusBadgeProps) => {
  return (
    <Badge
      className={clsx(s.OrderStatusBadge, s[`OrderStatusBadge_${status}`], className, {
        [s.OrderStatusBadge_Problem]: isProblem
      })}
      {...props}
    >
      <FormattedMessage id={isProblem ? 'order.statusProblem' : orderStatusIntlNames[status]} />
    </Badge>
  );
};
