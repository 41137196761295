import React, { useRef } from 'react';
import clsx from 'clsx';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { ReactFCC } from '../../../../common/utils/helperTypes';
import { Grid } from '../../../../common/components/Grid';
import { Product } from '../../../../store/graphql';
import { HeadingSize, Heading } from '../../../../common/components/Heading';
import { ESpaceSize, Space } from '../../../../common/components/Space/Space';
import { EBreakpoints, useBreakpoint } from '../../../../common/hooks/useBreakpoint';
import { ProductShowcaseCard } from '../../../../entities/product';
import s from './ProductProducts.module.scss';

export type TProductProductsType = Pick<Product, 'id' | 'name' | 'price' | 'mainPhoto'>;

export type TProductProducts = {
  className?: string;
  title: string;
  products: TProductProductsType[];
  headingSize?: HeadingSize;
};

export const ProductProducts: ReactFCC<TProductProducts> = (props) => {
  const { className, title, products, headingSize } = props;

  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);
  const isDesktopSmall = useBreakpoint(EBreakpoints.XL_DOWN);

  const contentRef = useRef<HTMLDivElement>(null);

  const onClickControl = (modifier: 1 | -1) => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        behavior: 'smooth',
        left: contentRef.current.scrollLeft + (contentRef.current.scrollWidth / products.length) * modifier
      });
    }
  };

  return (
    <div className={s.ProductProducts}>
      <Heading size={headingSize ? headingSize : HeadingSize.H3}>{title}</Heading>

      <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />

      <div className={s.ProductProducts__wrapper}>
        {!isDesktopSmall && products.length > 4 && <Control onClick={() => onClickControl(-1)} left />}

        <Grid className={clsx(s.ProductProducts__content, className)} cols={12} innerRef={contentRef}>
          {products.map((product, index) => (
            <Grid.GridItem cols={3} key={index}>
              <ProductShowcaseCard product={product} />
            </Grid.GridItem>
          ))}
        </Grid>

        {!isDesktopSmall && products.length > 4 && <Control onClick={() => onClickControl(1)} right />}
      </div>
    </div>
  );
};

type TControl = {
  left?: boolean;
  right?: boolean;
  onClick: () => void;
};

const Control: ReactFCC<TControl> = ({ left, right, onClick }) => {
  return (
    <div
      className={clsx(s.ProductProducts__control, {
        [s.ProductProducts__control_left]: left,
        [s.ProductProducts__control_right]: right
      })}
      onClick={onClick}
      tabIndex={0}
    >
      {left ? (
        <BsArrowLeft className={s.ProductProducts__controlIcon} />
      ) : (
        <BsArrowRight className={s.ProductProducts__controlIcon} />
      )}
    </div>
  );
};
