import { BsChevronDown, BsChevronUp, BsQuestionCircle } from 'react-icons/bs';
import React, { useState } from 'react';
import s from './SupportQuestionAnswer.module.scss';

export type SupportQuestionAnswerProps = {
  question: string;
  answer: string;
};
export const SupportQuestionAnswer = (props: SupportQuestionAnswerProps) => {
  const { question, answer } = props;
  const [expanded, setExpanded] = useState(false);
  const IconComponent = expanded ? BsChevronUp : BsChevronDown;
  const toggleExpand = () => {
    setExpanded((prevState) => !prevState);
  };
  return (
    <>
      <div className={s.SupportQuestionAnswer__faq_item} onClick={() => toggleExpand()}>
        <div className={s.SupportQuestionAnswer__faq_question}>
          <span className={s.SupportQuestionAnswer__faq_question_text}>
            <BsQuestionCircle size={25} className={s.SupportQuestionAnswer__faq_answer_icon} />
            {question}
          </span>
          <IconComponent />
        </div>
        {expanded && <span className={s.SupportQuestionAnswer__faq_answer}>{answer}</span>}
      </div>
    </>
  );
};
