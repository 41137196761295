import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';
import { useNavigate } from 'react-router';
import { ReactFCC } from '../../../../../common/utils/helperTypes';
import { Modal } from '../../../../../common/components/Modal';
import { Grid } from '../../../../../common/components/Grid';
import { Link } from '../../../../../common/components/Link';
import { ESpaceSize, Space } from '../../../../../common/components/Space/Space';
import { formatMoney } from '../../../../../common/utils/format';
import { Divider } from '../../../../../common/components/Divider/Divider';

import { ButtonVariant } from '../../../../../common/components/Button';
import { DRAFTS_ROUTE, PathBuilder } from '../../../../../common/utils/routes';
import { ProductDraftType } from '../../../../../pages/profile/drafts/types';
import s from '../DraftsModal/DraftsModal.module.scss';

export interface DraftsModalProps {
  /**
   * Состояние открытого/закрытого модального окна
   */
  isOpen: boolean;
  /**
   * Действие при закрытии модального окна
   */
  onClose: () => void;
  /**
   * Опциональный дополнительный класс на компонент
   */
  className?: string;
  /**
   * Данные черновиков передаваемые свыше в компонент
   */
  drafts: ProductDraftType[];
}

export const DraftsModal: ReactFCC<DraftsModalProps> = (props: DraftsModalProps) => {
  const { isOpen, onClose, className, drafts } = props;

  const navigate = useNavigate();
  const placeholderImageUrl = `https://via.placeholder.com/100x100`;

  const intl = useIntl();

  const closeModal = () => {
    onClose();
  };

  return (
    <Modal
      className={clsx(className)}
      title={intl.formatMessage({ id: 'profile.draftsUnpublished' })}
      isOpen={isOpen}
      onClose={closeModal}
    >
      <Modal.Body className={s.DraftsModal__container}>
        <>
          <span>Do you want to publish any of your drafts?</span>
          <Space size={ESpaceSize.PIXEL_4} />
          {drafts &&
            drafts?.map((draft, index) => (
              <React.Fragment key={index}>
                <Divider space={ESpaceSize.PIXEL_16} />
                <Link className={s.DraftsModal__link} to={PathBuilder.getEditProductDraftPath(draft.id)}>
                  <Grid className={s.DraftsModal__product} cols={2}>
                    <Grid.GridItem cols={1} className={s.DraftsModal__imageCol}>
                      <img
                        className={s.DraftsModal__productImage}
                        src={draft?.mainPhoto?.thumbnails?.S?.url || placeholderImageUrl}
                        alt=""
                      />
                    </Grid.GridItem>

                    <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
                      <b>{draft.name || '-'}</b>
                      <Space size={ESpaceSize.PIXEL_4} />

                      <b>{formatMoney(draft.price || 0)}</b>
                    </Grid.GridItem>
                  </Grid>
                </Link>
              </React.Fragment>
            ))}
        </>
      </Modal.Body>
      <Modal.Footer className={s.DraftsModal__modalFooterButtons}>
        <Modal.Button onClick={closeModal}>
          <FormattedMessage id={'profile.draftsModalProceedCreation'} />
        </Modal.Button>
        <Modal.Button variant={ButtonVariant.SECONDARY} onClick={() => navigate(DRAFTS_ROUTE)}>
          <FormattedMessage id={'profile.draftsModalOpenPage'} />
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};
