import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactFCC } from 'common/utils/helperTypes';
import { HeadingSize, Heading } from 'common/components/Heading/Heading';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { Grid } from 'common/components/Grid/Grid';
import { LoaderBox } from 'common/components/Loader/LoaderBox';

import { Head } from '../../../common/components/Head';
import { useSellerTrustPointsQuery } from '../../../store/graphql';
import { Divider } from '../../../common/components/Divider/Divider';
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_LIMIT_MOBILE, usePagination } from '../../../common/components/Pagination';
import { TRUST_POINTS_GUIDE_ROUTE } from '../../../common/utils/routes';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { Link } from '../../../common/components/Link';
import { TrustPointsProgress } from './components/TrustPointsProgress';
import { TrustPointsRecords } from './components/TrustPointsRecords/TrustPointsRecords';
import s from './TrustPointsPage.module.scss';

export const TrustPointsPage: ReactFCC = () => {
  const isMobile = useIsMobile();

  const { offset, setOffset } = usePagination();
  const { data: trustPointsQuery, loading: trustPointsLoading } = useSellerTrustPointsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        pagination: {
          limit: isMobile ? DEFAULT_PAGE_LIMIT_MOBILE : DEFAULT_PAGE_LIMIT,
          offset
        }
      }
    }
  });

  const totalTrustPoints = trustPointsQuery?.trustPoints.seller?.totalTrustPoints || 0;
  const trustPoints = trustPointsQuery?.trustPoints.seller?.trustPoints.entries;
  const pagination = trustPointsQuery?.trustPoints.seller?.trustPoints.pagination;

  return (
    <>
      <Head title={'My trust points'} />

      <Heading size={HeadingSize.H3}>
        <FormattedMessage id="profile.trustPoints" />
      </Heading>

      <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />

      {trustPointsLoading ? (
        <LoaderBox />
      ) : (
        <Grid cols={{ xs: 6, lg: 9 }}>
          <Grid.GridItem cols={{ xs: 6, lg: 9, xl: 9 }}>
            <div className={s.TrustPointsPageContent__opener}>
              <div className={s.TrustPointsPageContent__current}>
                <span className={s.TrustPointsPageContent__currentAmountText}>
                  <FormattedMessage id={'profile.trustPointsCurrent'} />
                </span>
                <span className={s.TrustPointsPageContent__currentAmountValue}>{totalTrustPoints}</span>
              </div>
              <Link to={TRUST_POINTS_GUIDE_ROUTE}>
                <FormattedMessage id={'profile.trustPointsGuide'} />
              </Link>
            </div>

            <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 6, lg: 9, xl: 9 }}>
            <TrustPointsProgress currentAmount={totalTrustPoints} />
            {isMobile ? (
              <Divider space={ESpaceSize.PIXEL_16} />
            ) : (
              <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_32} />
            )}
          </Grid.GridItem>
          <Grid.GridItem>
            <Heading size={HeadingSize.H5}>
              <FormattedMessage id={'profile.trustPointsHistory'} />
            </Heading>
          </Grid.GridItem>
          <Space size={isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16} />
          <Grid.GridItem cols={{ xs: 6, lg: 9, xl: 9 }}>
            {trustPoints && trustPoints.length > 0 ? (
              <TrustPointsRecords
                trustPoints={trustPoints}
                offset={offset}
                setOffset={setOffset}
                pagination={pagination!}
              />
            ) : (
              <span className={s.TrustPointsPageContent__emptyRecords}>
                <FormattedMessage id={'profile.trustPointsEmpty'} />
              </span>
            )}
          </Grid.GridItem>
        </Grid>
      )}
    </>
  );
};
