import { ReactFCC } from 'common/utils/helperTypes';
import { Container } from 'react-bootstrap';
import React from 'react';
import clsx from 'clsx';
import { UserPanel } from '../UserPanel';
import s from './MobileNavbar.module.scss';

export interface MobileNavbarProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
}

export const MobileNavbar: ReactFCC<MobileNavbarProps> = (props) => {
  const { className } = props;

  return (
    <div className={clsx(s.MobileNav, className)}>
      <Container className={s.MobileNav__container}>
        <UserPanel className={s.MobileNav__userPanel} home={true} />
      </Container>
    </div>
  );
};
