import { ReactFCC } from '../../../../../common/utils/helperTypes';
import { JAPANESE_COUNTRY_CODE } from '../constants';
import { AddressFormFieldsProps } from './types';
import { AddressFormFieldsZip } from './AddressFormFieldsZip';
import { AddressFormFieldsJapan } from './AddressFormFieldsJapan';
import { AddressFormFieldsNoZip } from './AddressFormFieldsNoZip';

export const AddressFormFieldsFactory: ReactFCC<AddressFormFieldsProps> = (props) => {
  const { formik, country, zipCode, address, cityOptions, disabledSellerAddress } = props;

  if (!country) {
    return null;
  }

  if (country.alpha2 === JAPANESE_COUNTRY_CODE) {
    return (
      <AddressFormFieldsJapan
        formik={formik}
        country={country}
        zipCode={zipCode}
        address={address}
        cityOptions={cityOptions}
        disabledSellerAddress={disabledSellerAddress}
      />
    );
  } else {
    if (country.supportsZipCode) {
      return (
        <AddressFormFieldsZip
          formik={formik}
          country={country}
          zipCode={zipCode}
          address={address}
          cityOptions={cityOptions}
          disabledSellerAddress={disabledSellerAddress}
        />
      );
    } else {
      return (
        <AddressFormFieldsNoZip
          formik={formik}
          country={country}
          zipCode={zipCode}
          address={address}
          cityOptions={cityOptions}
          disabledSellerAddress={disabledSellerAddress}
        />
      );
    }
  }
};
