import { FormDataFileKey, VideoUploadParams } from './types';
import { UploadFn } from './types/uploadFn';

export type UploadVideoFn = UploadFn<VideoUploadParams>;

export const uploadVideo: UploadVideoFn = async (apiUrl, key, file, params) => {
  const query = new URLSearchParams();

  if (params) {
    if (params.s) query.set('s', params.s.toString());
    if (params.ext) query.set('ext', params.ext.toString());
    if (typeof params.convert === 'boolean') query.set('convert', params.convert ? 'true' : 'false');
    if (typeof params.synchronously === 'boolean') query.set('synchronously', params.synchronously ? 'true' : 'false');
  }

  const formData = new FormData();
  formData.set(FormDataFileKey, file);

  const response = await fetch(`${apiUrl}/uploadVideo/${key}?${query}`, {
    method: 'POST',
    body: formData
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
};
