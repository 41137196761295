import React from 'react';
import { ReactFCC } from 'common/utils/helperTypes';
import { EBreakpoints, useBreakpoint } from 'common/hooks/useBreakpoint';
import { Button, ButtonFit, ButtonSize, ButtonProps } from 'common/components/Button';

export type ModalButtonProps = ButtonProps;

export const ModalButton: ReactFCC<ModalButtonProps> = (props) => {
  const { children, ...rest } = props;
  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);

  return (
    <Button
      size={isMobile ? ButtonSize.LARGE : ButtonSize.MEDIUM}
      fit={isMobile ? ButtonFit.FILL : ButtonFit.FIT}
      {...rest}
    >
      {children}
    </Button>
  );
};
