import { ArisoraOrderStatus } from '../../store/graphql';

export const orderStatusIntlNames: Record<ArisoraOrderStatus, string> = {
  [ArisoraOrderStatus.Preparing]: 'order.statusPreparing',
  [ArisoraOrderStatus.ReadyForShipment]: 'order.statusReadyToSend',
  [ArisoraOrderStatus.OnPickup]: 'order.statusOnPickup',
  [ArisoraOrderStatus.CustomsClearance]: 'order.statusCustomsClearance',
  [ArisoraOrderStatus.DeliveringWithinJapan]: 'order.statusDeliveringWithinJapan',
  [ArisoraOrderStatus.InternationalDelivery]: 'order.statusInternationalDelivery',
  [ArisoraOrderStatus.Delivered]: 'order.statusDelivered',
  [ArisoraOrderStatus.Canceled]: 'order.statusCanceled',
  [ArisoraOrderStatus.Destroyed]: 'order.statusDestroyed'
};
