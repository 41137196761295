import React from 'react';

/**
 * @deprecated use isKey / isEnterKey instead
 */
export const isKeyDownEnter = (e: React.KeyboardEvent) =>
  e.code?.toLowerCase?.() === 'enter' ||
  (e.code && String(e.code) === '13') ||
  (e.key && String(e.key) === '13') ||
  e.key?.toLowerCase?.() === 'enter';
