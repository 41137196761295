import React from 'react';
import { useNavigate } from 'react-router';
import { BsArrowLeftShort } from 'react-icons/bs';
import clsx from 'clsx';
import { To } from 'react-router-dom';
import { Anchor, AnchorProps, AnchorVariant } from '../Anchor';
import { ReactFCC } from '../../utils/helperTypes';
import s from './LinkBack.module.scss';

export interface LinkBackPropsSelf {
  className?: string;
  to?: To;
}

export type LinkBackProps = LinkBackPropsSelf & AnchorProps<'button'>; // & React.HTMLAttributes<HTMLSpanElement>;

/**
 * Перенести куда нибудь в виджеты/лейауты
 * @param props
 * @constructor
 */
export const LinkBack: ReactFCC<LinkBackProps> = (props) => {
  const { children, className, onClick, onKeyDownCapture, to, ...rest } = props;

  const navigate = useNavigate();

  const navigateDefault = () => {
    if (to) {
      navigate(to, {
        replace: true
      });
    } else {
      navigate(-1);
    }
  };

  const onClickWithDefault = (e: React.MouseEvent) => {
    if (onClick) {
      onClick(e);
    } else {
      navigateDefault();
    }
  };

  const onKeyDownCaptureDefault = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (onKeyDownCapture) {
      onKeyDownCapture(e);
    } else {
      navigateDefault();
    }
  };

  return (
    <Anchor
      component={'button'}
      {...rest}
      className={clsx(s.LinkBack, className)}
      variant={AnchorVariant.SECONDARY}
      onClick={onClickWithDefault}
      onKeyDownCapture={onKeyDownCaptureDefault}
      leftIcon={BsArrowLeftShort}
      tabIndex={0}
    >
      {children}
    </Anchor>
  );
};
