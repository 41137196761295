import clsx from 'clsx';
import s from './OrderCombinePickupDate.module.scss';

type OrderCombinePickupDateProps = {
  value: string;
  selected: boolean;
  onClick?: () => void;
};
export const OrderCombinePickupDate = ({ value, selected, onClick }: OrderCombinePickupDateProps) => {
  return (
    <div className={clsx(s.OrderCombinePickupDate, selected && s.OrderCombinePickupDate_selected)} onClick={onClick}>
      <span>{value}</span>
    </div>
  );
};
