import { IntlProvider as IntlProviderDefault, MessageFormatElement } from 'react-intl';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ReactFCC } from '../../common/utils/helperTypes';
import { AvailableLanguagesEnum, useChangeUserLanguageMutation } from '../graphql';
import { LOCAL_STORAGE_LANGUAGE } from '../storageKeys';
import { handleDefaultError } from '../../common/utils/handleDefaultError';
import { useLocalStorage } from '../../common/hooks/useLocalStorage';
import { getGraphQLErrorInfo, STATUS_CODE_AUTH } from '../../common/utils/graphqlErrors';
import { useUser } from '../../entities/user';
import { useAuth } from '../../app/providers/auth-apollo';
import { defaultLanguage } from './constants';
import { LangContext } from './LangContext';
import { locales } from './i18n/locales';
import enJson from './i18n/en.json';

const loadMessages = (locale: AvailableLanguagesEnum) => {
  switch (locale) {
    case AvailableLanguagesEnum.English:
      return Promise.resolve({ default: enJson });
    case AvailableLanguagesEnum.Japanese:
      return import('./i18n/jp.json');
    default:
      return Promise.resolve({ default: enJson });
  }
};

export const IntlProvider: ReactFCC = ({ children }) => {
  const [language, setLanguage] = useLocalStorage<AvailableLanguagesEnum>(LOCAL_STORAGE_LANGUAGE, defaultLanguage, {
    raw: false,
    serializer: (value) => value,
    deserializer: (value) => value as AvailableLanguagesEnum
  });
  const languageRef = useRef(language);

  const [messages, setMessages] = useState<Record<string, string> | Record<string, MessageFormatElement[]>>(enJson);

  const { isAuthenticated } = useAuth();
  const { user } = useUser({
    fetchPolicy: 'cache-and-network'
  });
  const userLanguage = user?.selectedLanguage;

  const [changeUserLanguageMutation, { client, loading: languageLoading }] = useChangeUserLanguageMutation();

  const [resetLoading, setResetLoading] = useState(false);

  const resetStore = useCallback(async () => {
    if (language) {
      try {
        setResetLoading(true);
        await client.resetStore();
      } catch (err) {
        console.error(err);
        const graphqlError = getGraphQLErrorInfo(err);
        if (!graphqlError || graphqlError?.statusCode !== STATUS_CODE_AUTH) {
          handleDefaultError('Something went wrong, please reload the page', err);
        }
      } finally {
        setResetLoading(false);
      }
    }
  }, [language, client]);

  const changeUserLanguage = useCallback(
    async (value: AvailableLanguagesEnum) => {
      if (value === language) {
        return;
      }

      setLanguage(value);

      if (isAuthenticated) {
        try {
          const result = await changeUserLanguageMutation();
          if (!result?.data?.result) {
            throw new Error('Language change mutation response: false');
          }
        } catch (err) {
          console.error(err);
          handleDefaultError('Language change denied! Please try again.');
          setLanguage(languageRef.current);
          return;
        }
      }

      languageRef.current = value;
      await resetStore();
    },
    [language, setLanguage, isAuthenticated, resetStore, changeUserLanguageMutation]
  );

  useEffect(() => {
    if (userLanguage && userLanguage !== languageRef.current) {
      changeUserLanguage(userLanguage);
    }
  }, [userLanguage, changeUserLanguage]);

  const locale = locales[language as AvailableLanguagesEnum];

  useEffect(() => {
    loadMessages(language as AvailableLanguagesEnum).then((data) => setMessages(data.default));
  }, [locale, language]);

  return (
    <IntlProviderDefault locale={locale} messages={messages}>
      <LangContext.Provider
        value={{
          language: language || defaultLanguage,
          languageLoading: languageLoading || resetLoading,
          changeUserLanguage
        }}
      >
        {children}
      </LangContext.Provider>
    </IntlProviderDefault>
  );
};
