import React, { useMemo } from 'react';
import clsx, { ClassValue } from 'clsx';
import { PartialRecord, ReactFCC } from '../../utils/helperTypes';
import { ESize } from '../../utils/ui';
import { GridItem } from './GridItem';
import s from './Grid.module.scss';

export type ColType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export enum GridGap {
  MEDIUM = 'medium',
  SMALL = 'small'
}

export interface GridGapProps {
  row?: GridGap;
  column?: GridGap;
}

export interface GridProps {
  className?: string;
  cols?: ColType | PartialRecord<ESize, ColType>;
  gap?: GridGap | GridGapProps;
  innerRef?: React.RefObject<HTMLDivElement>;
}

export type TGridType = ReactFCC<GridProps> & {
  GridItem: typeof GridItem;
};

export const Grid: TGridType = (props) => {
  const { children, className, cols = 12, gap, innerRef } = props;

  const colsClasses: ClassValue[] = useMemo(() => {
    const colsObject = getColsObject(cols, 2);

    return Object.entries(colsObject).map(([key, value]) => s[`Grid_${key}_${value}`]);
  }, [cols]);

  const gapClasses: ClassValue[] = useMemo(() => {
    let gapObject: GridGapProps = {
      column: GridGap.MEDIUM
    };

    if (gap) {
      if (typeof gap === 'object') {
        gapObject = gap;
      } else {
        gapObject = {
          row: gap,
          column: gap
        };
      }
    }

    return Object.entries(gapObject).map(([key, value]) => s[`Grid_gap_${key}_${value}`]);
  }, [gap]);

  return (
    <div className={clsx(s.Grid, className, colsClasses, gapClasses)} ref={innerRef}>
      {children}
    </div>
  );
};

Grid.GridItem = GridItem;

export const getColsObject = (cols: ColType | PartialRecord<ESize, ColType>, defaultXsCols: ColType) => {
  const colsObject: PartialRecord<ESize, ColType> =
    typeof cols === 'object'
      ? {
          xs: defaultXsCols,
          ...cols
        }
      : {
          xs: defaultXsCols,
          lg: cols
        };

  return colsObject;
};
