import { FormattedMessage } from 'react-intl';
import { Grid, GridGap } from '../../../common/components/Grid';
import { Heading, HeadingSize } from '../../../common/components/Heading';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import s from './LawPage.module.scss';

export const LawPage = () => {
  return (
    <>
      <div className={s.LawPage__content}>
        <Heading size={HeadingSize.H4}>
          <FormattedMessage id={'static.laws.header'} />
        </Heading>
        <Space size={ESpaceSize.PIXEL_48} />
        <Grid cols={2} gap={GridGap.MEDIUM} className={s.LawPage__grid}>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span className={s.LawPage__textCols}>
              <FormattedMessage id={'static.laws.operator'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.arisora'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.director'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.alex'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.companyAddressTitle'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.companyAddress'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.companyPhoneTitle'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.companyPhone'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.workingHoursTitle'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.workingHours'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.companyEmailTitle'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.companyEmail'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.companyHomepageTitle'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.companyHomepage'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.descriptionTitle'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.description'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.descriptionTitle'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.description'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.serviceStartTitle'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <div className={s.LawPage__textCols}>
              <span>
                <FormattedMessage id={'static.laws.serviceStartSellers'} />
              </span>
              <span>
                <FormattedMessage id={'static.laws.serviceStartBuyers'} />
              </span>
            </div>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.compensationTitle'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <div className={s.LawPage__textCols}>
              <span>
                <FormattedMessage id={'static.laws.compensationSeller'} />
              </span>
              <span>
                <FormattedMessage id={'static.laws.compensationBuyer'} />
              </span>
            </div>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.salesTitle'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.sales'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.feesTitle'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.fees'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.deliveryTitle'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.delivery'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.paymentTitle'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.payment'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.timePaymentTitle'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <ul>
              <li>
                <FormattedMessage id={'static.laws.timePaymentLi1'} />
              </li>
              <li>
                <FormattedMessage id={'static.laws.timePaymentLi2'} />
              </li>
            </ul>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <span>
              <FormattedMessage id={'static.laws.returnTitle'} />
            </span>
          </Grid.GridItem>
          <Grid.GridItem cols={{ xs: 1, lg: 1 }}>
            <ul>
              <li>
                <FormattedMessage id={'static.laws.returnLi1'} />
              </li>
              <li>
                <FormattedMessage id={'static.laws.returnLi2'} />
              </li>
            </ul>
          </Grid.GridItem>
        </Grid>
      </div>
    </>
  );
};
