import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';
import { Modal } from '../../../../../common/components/Modal';
import { Loader, LoaderSize } from '../../../../../common/components/Loader';
import { Button, ButtonFit, ButtonVariant } from '../../../../../common/components/Button';
import { ESpaceSize, Space } from '../../../../../common/components/Space/Space';
import s from './OrderCombineRecalculationModal.module.scss';

export interface OrderCombineRecalculationModalProps {
  /**
   * Состояние открытого/закрытого модального окна
   */
  isOpen: boolean;
  /**
   * Действие при закрытии модального окна
   */
  onClose: () => void;
  /**
   * Действие если продавец уверен в размерах заказа
   */
  onForce: () => void;
  /**
   * Опциональный дополнительный класс на компонент
   */
  className?: string;
  /**
   * Флаг процесса проверки
   */
  loading: boolean;
  /**
   * Флаг об успешной проверке стоимости доставки
   */
  success: boolean | null;
  /**
   * Флаг, когда продавец уверен в размерах заказа
   */
  force: boolean | null;
}

export const OrderCombineRecalculationModal = (props: OrderCombineRecalculationModalProps) => {
  const { isOpen, onClose, className, success, loading, force, onForce } = props;

  const intl = useIntl();

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal
      className={clsx(s.OrderCombineRecalculationModal, className)}
      classes={{ mobileContainer: s.OrderCombineRecalculationModal__container }}
      title={intl.formatMessage({ id: 'orderCombine.modal.title' })}
      isOpen={isOpen}
      onClose={closeModal}
    >
      <Modal.Body className={s.OrderCombineRecalculationModal__content}>
        {loading && !force ? (
          <div className={s.OrderCombineRecalculationModal__loader}>
            <span>We are checking international delivery price for the buyer with new box sizes</span>
            <Space size={ESpaceSize.PIXEL_16} />
            <span className={s.OrderCombineRecalculationModal__loaderText}>
              <Loader size={LoaderSize.SMALL} /> <FormattedMessage id={'orderCombine.modal.wait'} />
            </span>
          </div>
        ) : success ? (
          <div className={s.OrderCombineRecalculationModal__successResult}>
            <FormattedMessage id={'orderCombine.modal.verified'} />
          </div>
        ) : success !== null ? (
          <div className={s.OrderCombineRecalculationModal__failureResult}>
            <FormattedMessage id={'orderCombine.modal.priceHigher'} />
            <div className={s.OrderCombineRecalculationModal__failureResult_controls}>
              <Button fit={ButtonFit.FILL} variant={ButtonVariant.PRIMARY} onClick={onForce} loading={loading}>
                <FormattedMessage id={'orderCombine.modal.sure'} />
              </Button>
              <Button fit={ButtonFit.FILL} variant={ButtonVariant.PRIMARY_OUTLINE} onClick={onClose}>
                <FormattedMessage id={'orderCombine.modal.changeSizes'} />
              </Button>
            </div>
          </div>
        ) : (
          <FormattedMessage id={'orderCombine.modal.defaultError'} />
        )}
      </Modal.Body>
    </Modal>
  );
};
