import { useBatchedQueryParam, UseBatchedQueryParamOptions, UseBatchedQueryParamReturn } from './useBatchedQueryParam';

export const useBatchedQueryParamArray = <T = string[]>(
  key: string,
  { defaultValue, getter, setter }: UseBatchedQueryParamOptions<T> = {}
): UseBatchedQueryParamReturn<T> => {
  return useBatchedQueryParam(key, {
    defaultValue: defaultValue ?? [],
    getter: getter || ((value) => (value ? JSON.parse(decodeURIComponent(value)) : [])),
    setter: setter || ((value) => (value.length ? encodeURIComponent(JSON.stringify(value)) : null))
  });
};
