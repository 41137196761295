import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { IconType } from 'react-icons';
import React, { RefObject, useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Tippy, { TippyProps } from '@tippyjs/react';
import { Instance } from 'tippy.js';
import s from './ContextMenu.module.scss';

export interface ContextMenuItem {
  title: string;
  icon?: IconType;
  onClick?: (e: React.MouseEvent) => void;
  danger?: boolean;
}

export interface ContextMenuContentProps extends TippyProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Дополнительные css-классы
   */
  classes?: {
    dropdown?: string;
    item?: string;
  };
  /**
   * Реф для референсного элемента
   */
  referenceRef: RefObject<HTMLElement>;
  /**
   * Элементы контекстного меню
   */
  items: ContextMenuItem[];
  /**
   * Состояние меню
   */
  isOpen: boolean;
  /**
   * Обработчик закрытия меню
   */
  onClose?: () => void;
}

export const ContextMenuContent: ReactFCC<ContextMenuContentProps> = (props) => {
  const { className, classes, items, referenceRef, isOpen, onClose, ...tippyProps } = props;

  const [isVisible, setIsVisible] = useState(false);

  const onMount = useCallback(() => {
    setIsVisible(true);
  }, []);

  const onHide = useCallback((instance: Instance) => {
    const unmountInstance = () => {
      instance.unmount();

      instance.popper.firstChild?.removeEventListener('transitionend', unmountInstance);
      setIsVisible(false);
    };

    instance.popper.firstChild?.addEventListener('transitionend', unmountInstance);
  }, []);

  return (
    <Tippy
      animation
      appendTo={document.getElementById('root') as HTMLElement}
      onMount={onMount}
      onHide={onHide}
      reference={referenceRef}
      render={(attrs) => (
        <div
          className={clsx('tippy-box', s.ContextMenuContent, className)}
          data-state={isOpen && isVisible ? 'visible' : 'hidden'}
          data-animation="scale-subtle"
          style={{ transitionDuration: isOpen ? '0.2s' : '0.15s' }}
          {...attrs}
        >
          <Dropdown.Menu
            className={clsx(s.ContextMenuContent__dropdown, classes?.dropdown, {
              [s.ContextMenuContent__dropdown_visible]: isOpen
            })}
            show
          >
            {items.map(({ icon: Icon, ...item }, index) => (
              <Dropdown.Item
                className={clsx(s.ContextMenuContent__dropdownItem, classes?.item, {
                  [s.ContextMenuContent__dropdownItem_danger]: item.danger
                })}
                as={'button'}
                role="button"
                onClick={(e) => {
                  e.preventDefault();
                  onClose?.();
                  item.onClick?.(e);
                }}
                key={index}
              >
                {Icon && <Icon size={12} />}
                {item.title}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </div>
      )}
      visible={isOpen}
      placement={'bottom-end'}
      interactive={true}
      offset={[0, 4]}
      popperOptions={{
        strategy: 'fixed',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top-start', 'right']
            }
          }
        ]
      }}
      onClickOutside={onClose}
      {...tippyProps}
    />
  );
};
