import { ReactFCC } from 'common/utils/helperTypes';
import { ProductStatusEnum } from 'store/graphql';
import { useMemo } from 'react';
import { ProductType } from '../../types';
import { BaseProductCard, BaseProductCardProps } from './BaseProductCard';

export interface ProductCardProps
  extends Pick<
    BaseProductCardProps,
    'deliveryCost' | 'showSoldBadge' | 'onClickRemove' | 'removeLoading' | 'actionsSlot'
  > {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Объект товара из API
   */
  product: ProductType;
}

/**
 * Фасад для карточки товара, позволяющий передавать объект Product напрямую
 * @param props
 * @constructor
 */
export const ProductCard: ReactFCC<ProductCardProps> = (props) => {
  const { className, product, showSoldBadge, onClickRemove, removeLoading, actionsSlot } = props;

  const sizeAttributeValue =
    product.specificValues?.find((attr) => {
      return attr.attributeName?.toLowerCase().includes('size') || attr.attributeName?.toLowerCase().includes('サイズ');
    })?.variantValue || undefined;

  const photo = useMemo(() => {
    if (product.mainPhoto) {
      return product.mainPhoto;
    }

    return product.photos ? product.photos[0] : undefined;
  }, [product.mainPhoto, product.photos]);

  return (
    <>
      <BaseProductCard
        className={className}
        id={product.id}
        name={product.name}
        price={product.price}
        showSoldBadge={showSoldBadge && product.status === ProductStatusEnum.Sold}
        colorAttributeValue={product.colorName || undefined}
        sizeAttributeValue={sizeAttributeValue}
        photoUrl={photo?.thumbnails?.M?.url ?? undefined}
        onClickRemove={onClickRemove}
        removeLoading={removeLoading}
        actionsSlot={actionsSlot}
      />
    </>
  );
};
