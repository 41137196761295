import { useNavigationQuery } from '../../../../../store/graphql';

export interface NavigationCategoryItem {
  id: string;
  name: string;
  subCategories: {
    id: string;
    name: string;
    subSubCategories: {
      id: string;
      name: string;
    }[];
  }[];
}

export const useNavigationItems = (): NavigationCategoryItem[] | undefined => {
  // todo переделать квери и логику, чтобы вложенные категории запрашивались
  //  только при выборе родительской
  const { data } = useNavigationQuery();

  const categories = data?.marketPlace.categories || [];
  const subCategories = data?.marketPlace.subCategories || [];
  const subSubCategories = data?.marketPlace.subSubCategories || [];

  return categories.map((category) => ({
    id: category.id,
    name: category.name,
    subCategories: subCategories
      .filter(({ id }) => category.subCategoryIds.includes(id))
      .map((subCategory) => ({
        id: subCategory.id,
        name: subCategory.name,
        subSubCategories: subSubCategories
          .filter(({ id }) => subCategory.subSubCategoryIds?.includes(id))
          .map((subSubCategory) => ({
            id: subSubCategory.id,
            name: subSubCategory.name
          }))
      }))
  }));
};
