import { WalletTransactionTypeEnum, WithdrawStatusEnum } from '../../../../../store/graphql';
import { BadgeVariant } from '../../../../../common/components/Badge';

export const transactionTypeIntlIds = {
  [WalletTransactionTypeEnum.BonusPayment]: 'profile.walletTransactionBonusPayment',
  [WalletTransactionTypeEnum.RevenueFromOrder]: 'profile.walletTransactionRevenueFromOrder',
  [WalletTransactionTypeEnum.Fine]: 'profile.walletTransactionFine',
  [WalletTransactionTypeEnum.PayoutFromWr]: 'profile.walletTransactionPayoutFromWr',
  [WalletTransactionTypeEnum.ArisoraTax]: 'profile.walletTransactionArisoraTax'
};

export const requestBadgeVariantByStatus = {
  [WithdrawStatusEnum.Sent]: BadgeVariant.success,
  [WithdrawStatusEnum.Denied]: BadgeVariant.error,
  [WithdrawStatusEnum.Review]: BadgeVariant.default
};

export const requestStatusIntlId: Record<WithdrawStatusEnum, string> = {
  [WithdrawStatusEnum.Review]: 'profile.withdrawStatusReview',
  [WithdrawStatusEnum.Sent]: 'profile.withdrawStatusSent',
  [WithdrawStatusEnum.Denied]: 'profile.withdrawStatusDenied'
};
