import { PropsWithChildren, startTransition, useCallback, useEffect } from 'react';
import { AuthService } from '../../service/AuthService';
import { Cache } from '../../service/Cache';
import { AUTH_CACHE_KEY } from '../../service/cacheKeys';
import { AuthContext } from './AuthContext';
import { useFactoryRef } from './internal/useFactoryRef';
import { useStateWithDeps } from './internal/stateWithDeps';

export const AuthProvider = (props: PropsWithChildren) => {
  const { children } = props;

  const service = useFactoryRef(() => new AuthService());

  // todo посмотреть по производительности, может лучше использовать обычный state
  const [stateRef, _, setState] = useStateWithDeps({
    isAuthenticated: !!service.getEntryFromCache(),
    loading: false,
    error: undefined
  });
  const state = stateRef.current;

  useEffect(() => {
    // todo потестировать с force и без
    _getToken();
    _setWindowFocusHandler();
    _setStorageChangeHandler();
    // todo подумать насчет обновления по таймеру
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _getToken = useCallback(
    async ({ force, silently }: { force?: boolean; silently?: boolean } = {}) => {
      try {
        if (!silently) {
          setState({ loading: !silently });
        }

        const data = await service.getEntry({ force });

        const isAuthenticated = !!data;
        if (!silently || isAuthenticated !== state.isAuthenticated) {
          startTransition(() => {
            setState({ loading: false, isAuthenticated });
          });
        }
      } catch (error: any) {
        startTransition(() => {
          setState({ loading: false, isAuthenticated: false, error });
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const _setWindowFocusHandler = useCallback(() => {
    window.addEventListener('focus', async () => {
      await _getToken({ silently: true });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _setStorageChangeHandler = useCallback(() => {
    window.addEventListener('storage', async (e) => {
      if (e.key === Cache.getKey(AUTH_CACHE_KEY)) {
        await _getToken({ silently: true });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestCode = useCallback(async (email: string) => {
    try {
      setState({ loading: true, isAuthenticated: false, error: undefined });
      await service.requestCode(email);
      setState({ loading: false });
    } catch (error: any) {
      setState({ loading: false, isAuthenticated: false, error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = useCallback(async (email: string, code: string) => {
    try {
      setState({ loading: true, error: undefined });
      const data = await service.login(email, code);
      startTransition(() => {
        setState({ loading: false, isAuthenticated: !!data });
      });
    } catch (error: any) {
      startTransition(() => {
        setState({ loading: false, isAuthenticated: false, error });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = useCallback(async (force?: boolean) => {
    try {
      setState({ loading: true, error: undefined });
      await service.logout(force);
    } finally {
      setState({ loading: false, isAuthenticated: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getToken = useCallback(async ({ force }: { force?: boolean } = {}) => {
    try {
      const entry = await service.getEntry({ force });
      return entry?.body.access_token || null;
    } catch (e) {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        error: state.error,
        requestCode,
        login,
        logout,
        getToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
