import React, { useState } from 'react';
import { TransactionType } from './types';
import { TransactionRow } from './TransactionRow/TransactionRow';
import { TransactionCard } from './TransactionCard/TransactionCard';

export interface TransactionProps extends TransactionType {
  rowClassName?: string;
  isMobile: boolean;
}

export const Transaction = ({ isMobile, rowClassName, ...transactionProps }: TransactionProps) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    if (!transactionProps.order) return;
    setExpanded((prevState) => !prevState);
  };

  return (
    <>
      {isMobile ? (
        <>
          <TransactionCard expanded={expanded} toggleExpand={toggleExpand} {...transactionProps} />
        </>
      ) : (
        <TransactionRow
          className={rowClassName}
          expanded={expanded}
          toggleExpand={toggleExpand}
          {...transactionProps}
        />
      )}
    </>
  );
};
