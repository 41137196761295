import { ReactFCC } from 'common/utils/helperTypes';
import { Helmet } from 'react-helmet';
import { EMDASH } from '@proscom/ui-utils';
import { siteTitle } from '../../../config';

export interface HeadProps {
  /**
   * Заголовок страницы
   */
  title?: string;
  /**
   * Описание страницы
   */
  description?: string;
}

export const Head: ReactFCC<HeadProps> = (props) => {
  const { title, description } = props;

  return (
    <Helmet title={title ? `${title} ${EMDASH} ${siteTitle}` : undefined} defaultTitle={siteTitle}>
      <meta name="description" content={description} />
    </Helmet>
  );
};
