import { InferType, object, string } from 'yup';
import { validateErrors } from '../../../../../common/utils/validate';

const validateObject = {
  email: string().email(validateErrors.wrongEmail).defined(validateErrors.required)
};

export const AuthModalEnterEmailSchema = object(validateObject);

export type AuthModalEnterEmailValues = InferType<typeof AuthModalEnterEmailSchema>;

export const AuthModalEnterEmailKeys = Object.fromEntries(
  Object.keys(validateObject).map((key) => [key, key])
) as Record<keyof AuthModalEnterEmailValues, string>;
