import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { ReactFCC } from 'common/utils/helperTypes';
import { isEnterKey } from 'common/utils/isKey';
import { Anchor, AnchorVariant } from '../Anchor/Anchor';
import s from './Tabs.module.scss';

export interface TabItem {
  name: string;
  title?: string;
  intlId?: string;
}

export interface TabsClasses {
  root?: string;
  item?: string;
  itemInner?: string;
}

export interface TabsProps {
  className?: string;
  items: TabItem[];
  classes?: TabsClasses;
  activeItem?: TabItem;
  onItemClick?: (item: TabItem) => void;
}

export const Tabs: ReactFCC<TabsProps> = (props) => {
  const { className, items, classes, activeItem, onItemClick } = props;

  return (
    <div className={clsx(s.Tabs, className, classes?.root)}>
      {items.map((tab, index) => (
        <div
          className={clsx(s.Tabs__tab, tab === activeItem && s.Tabs__tab_active, classes?.item)}
          onClick={() => onItemClick?.(tab)}
          onKeyDownCapture={(e: React.KeyboardEvent) => {
            if (isEnterKey(e)) {
              onItemClick?.(tab);
            }
          }}
          key={index}
          tabIndex={0}
        >
          <Anchor
            component="span"
            variant={AnchorVariant.DEFAULT}
            className={clsx(s.Tabs__tabInner, classes?.itemInner)}
            tabIndex={-1}
          >
            {tab.intlId ? <FormattedMessage id={tab.intlId} defaultMessage={tab.title} /> : tab.title}
          </Anchor>
        </div>
      ))}

      <div className={s.Tabs__fill} />
    </div>
  );
};
