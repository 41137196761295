import React, { useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useClickOutside } from '@proscom/ui-react';
import { useUpdateEffect } from 'react-use';
import { NavigationBaseProps } from '../../types';
import { useNavigationItems } from '../../hooks/useNavigationItems';
import { Link } from '../../../../../../common/components/Link';
import { AnchorVariant } from '../../../../../../common/components/Anchor';
import { PathBuilder } from '../../../../../../common/utils/routes';
import { ReactFCC } from '../../../../../../common/utils/helperTypes';
import s from './NavigationDesktop.module.scss';

export const NavigationDesktop: ReactFCC<NavigationBaseProps> = (props) => {
  const { className, open, onClose, desktopRef } = props;

  const { search } = useLocation();

  let restParams: string | undefined = undefined;
  if (search.includes('?q')) {
    if (search.includes('&')) {
      restParams = search.substring(search.indexOf('&') + 1);
    }
  } else if (search.includes('?') && !search.includes('?q')) {
    restParams = search.substring(search.indexOf('?') + 1);
  }

  const [activeCategoryIndex, setActiveCategoryIndex] = useState<number>(0);
  const categories = useNavigationItems();
  const activeCategory = useMemo(
    () => categories && categories[activeCategoryIndex],
    [activeCategoryIndex, categories]
  );

  const subCategories = activeCategory?.subCategories;

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => onClose?.());

  const { pathname } = useLocation();

  useUpdateEffect(() => {
    onClose?.();
  }, [pathname]);

  return open ? (
    <div className={clsx(s.NavigationDesktop, className)} ref={ref}>
      <Container className={s.NavigationDesktop__wrap}>
        <div className={s.NavigationDesktop__categories}>
          {categories?.map(({ id, name }, index) => (
            <Link
              to={PathBuilder.getCatalogPath({ categoryId: id, searchQuery: desktopRef?.current?.value, restParams })}
              className={s.NavigationDesktop__category}
              onClick={onClose}
              onMouseOver={() => setActiveCategoryIndex(index)}
              key={index}
              noStyle
            >
              {name}
            </Link>
          ))}
        </div>
        {activeCategory && (
          <div className={s.NavigationDesktop__subCategories}>
            {subCategories?.map(({ id: subCategoryId, name }, index) => (
              <div className={s.NavigationDesktop__subCategory} key={index}>
                <Link
                  to={PathBuilder.getCatalogPath({
                    categoryId: activeCategory.id,
                    subCategoryId,
                    searchQuery: desktopRef?.current?.value,
                    restParams
                  })}
                  className={s.NavigationDesktop__subCategoryTitle}
                  variant={AnchorVariant.BODY}
                  onClick={onClose}
                >
                  {name}
                </Link>
                {subCategories
                  ?.find(({ id }) => id === subCategoryId)
                  ?.subSubCategories.map(({ id, name }, index) => (
                    <Link
                      to={PathBuilder.getCatalogPath({
                        categoryId: activeCategory.id,
                        subCategoryId,
                        subSubCategoryId: id,
                        searchQuery: desktopRef?.current?.value,
                        restParams
                      })}
                      className={s.NavigationDesktop__subCategoryItem}
                      variant={AnchorVariant.SECONDARY}
                      onClick={onClose}
                      key={index}
                    >
                      {name}
                    </Link>
                  ))}
              </div>
            ))}
          </div>
        )}
      </Container>
    </div>
  ) : null;
};
